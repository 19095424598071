import { React, ReactDOM, $, ClassNames } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';

const DOCUMENTTYPE = {
  '-1': '银行卡',
  0: '身份证',
  1: '护照',
  2: '军官证',
  3: '驾驶证',
  4: '户口本',
  5: '学生证',
  6: '工作证',
  7: '出生证',
  8: '其他',
  A: '士兵证',
  B: '回乡证',
  C: '临时身份证',
  D: '警官证',
  E: '台胞证',
  '-99.1': '其他资料',
  66: '回乡证',
  69: '台胞证',
};

class DocumentsShow extends AtomicComponentThing {
  componentDidUpdate() {
    if (this.getRootComponent().getWizardComponent().state.currentIndex === 7) {
      const imgUri = this.getModel().uri;
      this.doPost('/rest/v1/policy/loadImage', { imageUri: imgUri }).done(
        (data) => {
          if (data && data.body) {
            let documentImage = $(
              ReactDOM.findDOMNode(this.refs.documentImage)
            );
            documentImage.attr('src', data.body);
          }
        }
      );
    }
  }

  renderDocumentImg() {
    return (
      <div className="sth-document-image-show">
        <img ref="documentImage" />
      </div>
    );
  }

  render() {
    const key = this.getModel().documentType
      ? this.getModel().documentType.split('.')[0]
      : this.getModel().documentType;
    let documentTitle = '';
    if (DOCUMENTTYPE.hasOwnProperty(key)) {
      if (this.getModel().front === 'N' || !this.getModel().front) {
        documentTitle = DOCUMENTTYPE[key] + '反面';
      } else {
        documentTitle = DOCUMENTTYPE[key] + '正面';
      }
      return (
        <div {...this.getPrototypes()} ref="me">
          {this.renderDocumentImg()}
          <div className="sth-document-title-show">{documentTitle}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  getDefaultClassName() {
    return 'sth-document-item-show';
  }
}

export default DocumentsShow;
