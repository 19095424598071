import AtomicMutableComponentThing from './atomic-mutable-component';
import * as SthUtils from './something-utils';

/**
 * 使用代码表的组件
 */
class AtomicCodesComponentThing extends AtomicMutableComponentThing {
  /**
   * didMount最后增加事件监听
   */
  postDidMount() {
    super.postDidMount();
    this.installCodesFilterIds();
  }
  /**
   * willUpdate最前去掉事件监听
   */
  preWillUpdate(nextProps, nextState, nextContext) {
    super.preWillUnmount(nextProps, nextState, nextContext);
    this.uninstallCodesFilterIds();
  }
  /**
   * didUpdate最后增加事件监听
   */
  postDidUpdate(prevProps, prevState, prevContext) {
    super.postDidUpdate(prevProps, prevState, prevContext);
    this.installCodesFilterIds();
  }
  /**
   * willUnmount最前去掉事件监听
   */
  preWillUnmount() {
    super.preWillUnmount();
    this.uninstallCodesFilterIds();
  }
  /**
   * 装载过滤器相关的监听
   */
  installCodesFilterIds() {
    this.getConcernedIdsForFilters(this.getCodesFilters()).forEach((id) => {
      this.installToEventEmitter({
        event: id.prop,
        model: id.model,
      });
    });
  }
  /**
   * 卸载过滤器相关的监听
   */
  uninstallCodesFilterIds() {
    this.getConcernedIdsForFilters(this.getCodesFilters()).forEach((id) => {
      this.uninstallFromEventEmitter({
        event: id.prop,
        model: id.model,
      });
    });
  }
  getCodes() {
    let filters = this.getCodesFilters();
    if (!filters) {
      return this.translateCodes(this.getLayout().codes);
    }

    let valueOptions = [];

    // 可以有多个过滤器, 但是注意任意两个过滤器不能适配到同样的条件, 也就是最终只会执行到一个过滤器.
    // 如果数据可以适配到多个过滤器, 则只有第一个生效
    // 找到合适的过滤器
    const appliedFilter = this.matchFilter(filters);
    if (appliedFilter) {
      // 找到过滤器, 返回本过滤器的代码表定义
      valueOptions = this.translateCodes(
        appliedFilter.codes || appliedFilter.do
      );
    } else {
      // 没有找到过滤器, 返回空数组
    }

    const currentValue = this.getValueFromModel();
    if (currentValue != null) {
      const valueIndex = valueOptions.findIndex((option) => {
        // eslint-disable-next-line
        return option.v == currentValue;
      });
      if (valueIndex < 0) {
        // 新的选项里面没有包含老的值, 把值清空
        // 使用timeout是避免在render方法里面触发forceUpdate
        setTimeout(() => {
          this.setValueToModel(null);
        }, 10);
      }
    }

    return valueOptions;
  }
  translateCodes(codes) {
    return SthUtils.codes(codes, this.getGlobalCodes());
  }
  getCodesFilters() {
    const codes = this.getLayout().codes;

    if (!codes) {
      return null;
    } else if (typeof codes === 'string') {
      // 字符串, 是代码表名称
      return null;
    } else if (Array.isArray(codes)) {
      // 数组, 可能是代码表本身, 或者是filter
      // 检查第一个元素, 如果有v, l属性, 认为数组是代码表本身
      if (!codes.length) {
        return codes;
      } else if (codes[0].l != null && codes[0].v != null) {
        return null;
      } else {
        return codes;
      }
    } else {
      // 对象, 一定是filter
      return [codes];
    }
  }
}

export default AtomicCodesComponentThing;
