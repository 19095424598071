let lve1 = [
  { v: '1', l: '国家机关、党群组织、企业事业单位负责人' },
  { v: '2', l: '专业技术人员' },
  { v: '3', l: '办事人员和有关人员' },
  { v: '4', l: '商业、服务业人员' },
  { v: '5', l: '农、林、牧、渔、水利业生产人员' },
  { v: '6', l: '生产、运输设备操作人员及有关人员' },
  { v: '7', l: '军人' },
  { v: '8', l: '其他从业人员' },
];

let lve2 = {
  1: [
    { v: '101', l: '中国共产党中央委员会和地方各级党组织负责人' },
    { v: '102', l: '国家机关及其工作机构负责人' },
    { v: '103', l: '民主党派和社会团体及其工作机构负责人' },
    { v: '104', l: '事业单位负责人' },
    { v: '105', l: '企业负责人' },
  ],
  2: [
    { v: '201', l: '科学研究人员' },
    { v: '202', l: '工程技术人员' },
    { v: '203', l: '农业技术人员' },
    { v: '204', l: '飞机和船舶技术人员' },
    { v: '205', l: '卫生专业技术人员' },
    { v: '206', l: '经济业务人员' },
    { v: '207', l: '金融业务人员' },
    { v: '208', l: '法律专业人员' },
    { v: '209', l: '教学人员' },
    { v: '210', l: '文学艺术工作人员' },
    { v: '211', l: '体育工作人员' },
    { v: '212', l: '新闻出版、文化工作人员' },
    { v: '213', l: '宗教职业者' },
  ],
  3: [
    { v: '301', l: '行政办公人员' },
    { v: '302', l: '安全保卫和消防人员' },
    { v: '303', l: '邮政和电信业务人员' },
    { v: '304', l: '广告从业人员' },
    { v: '305', l: '设计和策划人员' },
    { v: '306', l: '绘制人员' },
    { v: '307', l: '影视从业人员' },
    { v: '308', l: '物业管理和服务人员' },
  ],
  4: [
    { v: '401', l: '购销人员' },
    { v: '402', l: '仓储人员' },
    { v: '403', l: '餐饮服务人员' },
    { v: '404', l: '饭店、旅游及健身娱乐场所服务人员' },
    { v: '405', l: '运输服务人员' },
    { v: '406', l: '医疗卫生辅助服务人员' },
    { v: '407', l: '社会服务和居民生活服务人员' },
    { v: '499', l: '其他商业、服务业人员' },
  ],
  5: [
    { v: '501', l: '种植业生产人员' },
    { v: '502', l: '林业生产及野生动植物保护人员' },
    { v: '503', l: '畜牧业生产人员' },
    { v: '504', l: '渔业生产人员' },
    { v: '505', l: '水利设施管理养护人员' },
    { v: '599', l: '其他农、林、牧、渔、水利业生产人员' },
  ],
  6: [
    { v: '601', l: '勘测及矿物开采人员' },
    { v: '602', l: '金属冶炼、轧制人员' },
    { v: '603', l: '化工产品生产人员' },
    { v: '604', l: '机械制造加工人员' },
    { v: '605', l: '机电产品装配人员' },
    { v: '606', l: '机械设备修理人员' },
    { v: '607', l: '电力设备安装、运行、检修及供电人员' },
    { v: '608', l: '电子元器件与设备制造、装配调试及维修人员' },
    { v: '609', l: '橡胶和塑料制品生产人员' },
    { v: '610', l: '纺织、针织、印染人员' },
    { v: '611', l: '裁剪缝纫和皮革、毛皮制品加工制作人员' },
    { v: '612', l: '粮油、食品、饮料生产加工及饲料生产加工人员' },
    { v: '613', l: '烟草及其制品加工人员' },
    { v: '614', l: '药品生产人员' },
    { v: '615', l: '木材加工、人造板生产及木材制品制作人员' },
    { v: '616', l: '制浆、造纸和纸制品生产加工人员' },
    { v: '617', l: '建筑材料生产加工人员' },
    { v: '618', l: '玻璃、陶瓷、搪瓷及其制品生产加工人员' },
    { v: '619', l: '广播影视制品制作、播放及文物保护作业人员' },
    { v: '620', l: '印刷人员' },
    { v: '621', l: '工艺、美术品制作人员' },
    { v: '622', l: '文化教育、体育用品制作人员' },
    { v: '623', l: '工程施工人员' },
    { v: '624', l: '运输设备操作人员及有关人员' },
    { v: '625', l: '环境监测与废物处理人员' },
    { v: '626', l: '检验、计量人员' },
    { v: '699', l: '其他生产运输设备操作人员及有关人员' },
  ],
  7: [{ v: '700', l: '军人' }],
  8: [{ v: '800', l: '其他从业人员' }],
};
let lve3 = {
  101: [{ v: '10100', l: '中国共产党中央委员会和地方各级党组织负责人' }],
  102: [
    { v: '10201', l: '国家权力机关及其工作机构负责人' },
    { v: '10202', l: '人民政协及其工作机构负责人' },
    { v: '10203', l: '人民法院负责人' },
    { v: '10204', l: '人民检察院负责人' },
    { v: '10205', l: '国家行政机关及其工作机构负责人' },
    { v: '10299', l: '其他国家机关及其工作机构负责人' },
  ],
  103: [
    { v: '10301', l: '民主党派负责人' },
    { v: '10302', l: '工会、共青团、妇联等人民团体及其工作机构负责人' },
    { v: '10303', l: '群众自治组织负责人' },
    { v: '10399', l: '其他社会团体及其工作机构负责人' },
  ],
  104: [
    { v: '10401', l: '教育教学单位负责人' },
    { v: '10402', l: '卫生单位负责人' },
    { v: '10403', l: '科研单位负责人' },
    { v: '10499', l: '其他事业单位负责人' },
  ],
  105: [{ v: '10501', l: '企业负责人' }],
  201: [
    { v: '20101', l: '哲学研究人员' },
    { v: '20102', l: '经济学研究人员' },
    { v: '20103', l: '法学研究人员' },
    { v: '20104', l: '社会学研究人员' },
    { v: '20105', l: '教育科学研究人员' },
    { v: '20106', l: '文学、艺术研究人员' },
    { v: '20107', l: '图书馆学、情报学研究人员' },
    { v: '20108', l: '历史学研究人员' },
    { v: '20109', l: '管理科学研究人员' },
    { v: '20110', l: '数学研究人员' },
    { v: '20111', l: '物理学研究人员' },
    { v: '20112', l: '化学研究人员' },
    { v: '20113', l: '天文学研究人员' },
    { v: '20114', l: '地球科学研究人员' },
    { v: '20115', l: '生物科学研究人员' },
    { v: '20116', l: '农业科学研究人员' },
    { v: '20117', l: '医学研究人员' },
    { v: '20118', l: '体育研究人员' },
  ],
  202: [
    { v: '20201', l: '地质勘探工程技术人员' },
    { v: '20202', l: '测绘工程技术人员' },
    { v: '20203', l: '矿山工程技术人员' },
    { v: '20204', l: '石油工程技术人员' },
    { v: '20205', l: '冶金工程技术人员' },
    { v: '20206', l: '化工工程技术人员' },
    { v: '20207', l: '机械工程技术人员' },
    { v: '20208', l: '兵器工程技术人员' },
    { v: '20209', l: '航空工程技术人员' },
    { v: '20210', l: '航天工程技术人员' },
    { v: '20211', l: '电子工程技术人员' },
    { v: '20212', l: '通信工程技术人员' },
    { v: '20213', l: '计算机与应用工程技术人员' },
    { v: '20214', l: '电气工程技术人员' },
    { v: '20215', l: '电力工程技术人员' },
    { v: '20216', l: '邮政工程技术人员' },
    { v: '20217', l: '广播电影电视工程技术人员' },
    { v: '20218', l: '交通工程技术人员' },
    { v: '20219', l: '民用航空工程技术人员' },
    { v: '20220', l: '铁路工程技术人员' },
    { v: '20221', l: '建筑工程技术人员' },
    { v: '20222', l: '建材工程技术人员' },
    { v: '20223', l: '林业工程技术人员' },
    { v: '20224', l: '水利工程技术人员' },
    { v: '20225', l: '海洋工程技术人员' },
    { v: '20226', l: '水产工程技术人员' },
    { v: '20227', l: '纺织工程技术人员' },
    { v: '20228', l: '食品工程技术人员' },
    { v: '20229', l: '气象工程技术人员' },
    { v: '20230', l: '地震工程技术人员' },
    { v: '20231', l: '环境保护工程技术人员' },
    { v: '20232', l: '安全工程技术人员' },
    { v: '20233', l: '标准化、计量、质量工程技术人员' },
    { v: '20234', l: '管理（工业）工程技术人员' },
  ],
  203: [
    { v: '20301', l: '土壤肥料技术人员' },
    { v: '20302', l: '植物保护技术人员' },
    { v: '20303', l: '园艺技术人员' },
    { v: '20304', l: '作物遗传育种栽培技术人员' },
    { v: '20305', l: '兽医兽药技术人员' },
    { v: '20306', l: '畜牧与草业技术人员' },
    { v: '20399', l: '其他农业技术人员' },
  ],
  204: [
    { v: '20401', l: '旋翼式飞机飞行人员和领航人员' },
    { v: '20402', l: '船舶指挥和引航人员' },
    { v: '20403', l: '喷气式民航班机飞行人员和领航人员' },
  ],
  205: [
    { v: '20501', l: '西医医师' },
    { v: '20502', l: '中医医师' },
    { v: '20503', l: '中西医结合医师' },
    { v: '20504', l: '民族医师' },
    { v: '20505', l: '公共卫生医师' },
    { v: '20506', l: '药剂人员' },
    { v: '20507', l: '医疗技术人员' },
    { v: '20508', l: '护理人员' },
    { v: '20599', l: '其他卫生专业技术人员' },
  ],
  206: [
    { v: '20601', l: '经济计划人员' },
    { v: '20602', l: '统计人员' },
    { v: '20603', l: '会计人员' },
    { v: '20604', l: '审计人员' },
    { v: '20605', l: '国际商务人员' },
    { v: '20606', l: '品牌专业人员' },
    { v: '20607', l: '分析人员' },
  ],
  207: [
    { v: '20701', l: '银行业务人员' },
    { v: '20702', l: '保险业务人员' },
    { v: '20703', l: '证券业务人员' },
    { v: '20704', l: '信用管理人员' },
    { v: '20799', l: '其他金融业务人员' },
  ],
  208: [
    { v: '20801', l: '法官' },
    { v: '20802', l: '检察官' },
    { v: '20803', l: '律师' },
    { v: '20804', l: '公证员' },
    { v: '20805', l: '司法鉴定人员' },
    { v: '20806', l: '书记员' },
    { v: '20807', l: '商业犯罪调查处理人员' },
  ],
  209: [
    { v: '20901', l: '高等教育教师' },
    { v: '20902', l: '中等职业教育教师' },
    { v: '20903', l: '中学教师' },
    { v: '20904', l: '小学教师' },
    { v: '20905', l: '幼儿教师' },
    { v: '20906', l: '特殊教育教师' },
    { v: '20907', l: '家庭教师' },
    { v: '20999', l: '其他教学人员' },
  ],
  210: [
    { v: '21001', l: '文艺创作和评论人员' },
    { v: '21002', l: '编导和音乐指挥人员' },
    { v: '21003', l: '演员' },
    { v: '21004', l: '乐器演奏员' },
    { v: '21005', l: '电影电视制作及舞台专业人员' },
    { v: '21006', l: '美术专业人员' },
    { v: '21007', l: '工艺美术专业人员' },
  ],
  211: [
    { v: '21101', l: '高尔夫球运动' },
    { v: '21102', l: '保龄球运动' },
    { v: '21103', l: '桌球运动' },
    { v: '21104', l: '羽毛球运动' },
    { v: '21105', l: '游泳运动' },
    { v: '21106', l: '射箭运动' },
    { v: '21107', l: '网球运动' },
    { v: '21108', l: '垒球运动' },
    { v: '21109', l: '滑冰运动' },
    { v: '21110', l: '射击运动' },
    { v: '21111', l: '民族体育运动(不含竞技性)' },
    { v: '21112', l: '举重运动' },
    { v: '21113', l: '篮球运动' },
    { v: '21114', l: '排球运动' },
    { v: '21115', l: '棒球运动' },
    { v: '21116', l: '田径运动' },
    { v: '21117', l: '体操运动' },
    { v: '21118', l: '滑雪运动' },
    { v: '21119', l: '帆船运动' },
    { v: '21120', l: '划船运动' },
    { v: '21121', l: '泛舟运动' },
    { v: '21122', l: '巧固球运动' },
    { v: '21123', l: '手球运动' },
    { v: '21124', l: '风浪板运动' },
    { v: '21125', l: '水上摩托车' },
    { v: '21126', l: '足球运动' },
    { v: '21127', l: '曲棍球运动' },
    { v: '21128', l: '冰上曲棍球运动' },
    { v: '21129', l: '橄榄球' },
    { v: '21130', l: '乒乓球运动' },
    { v: '21131', l: '击剑运动' },
    { v: '21132', l: '水球运动' },
    { v: '21133', l: '马术运动' },
    { v: '21134', l: '摔跤运动' },
    { v: '21135', l: '拳击运动' },
    { v: '21136', l: '裁判人员' },
  ],
  212: [
    { v: '21201', l: '记者' },
    { v: '21202', l: '编辑' },
    { v: '21203', l: '校对员' },
    { v: '21204', l: '播音员及节目主持人' },
    { v: '21205', l: '翻译' },
    { v: '21206', l: '图书资料与档案业务人员' },
    { v: '21207', l: '考古及文物保护专业人员' },
    { v: '21299', l: '其他新闻出版、文化工作人员' },
  ],
  213: [{ v: '21300', l: '宗教职业者' }],
  301: [
    { v: '30101', l: '行政业务人员' },
    { v: '30102', l: '行政事务人员' },
  ],
  302: [
    { v: '30201', l: '人民警察' },
    { v: '30202', l: '治安保卫人员' },
  ],
  303: [
    { v: '30301', l: '邮政业务人员' },
    { v: '30302', l: '电信业务人员' },
    { v: '30303', l: '电信通信传输业务人员' },
  ],
  304: [
    { v: '30401', l: '广告内勤人员' },
    { v: '30402', l: '室外工作人员' },
  ],
  305: [
    { v: '30501', l: '展览业工作人员' },
    { v: '30502', l: '景观建设从业人员' },
  ],
  306: [
    { v: '30601', l: '模型制作人员' },
    { v: '30602', l: '制图人员' },
  ],
  307: [{ v: '30701', l: '影视拍摄制作人' }],
  308: [{ v: '30801', l: '物业管理人员' }],
  401: [
    { v: '40101', l: '营业人员' },
    { v: '40102', l: '推销、展销人员' },
    { v: '40103', l: '采购人员' },
    { v: '40104', l: '拍卖、典当及租赁业务人员' },
    { v: '40105', l: '废旧物资回收利用人员' },
    { v: '40106', l: '粮油管理人员' },
    { v: '40107', l: '商品监督和市场管理人员' },
    { v: '40199', l: '其他购销人员' },
  ],
  402: [
    { v: '40201', l: '保管人员' },
    { v: '40202', l: '储运人员' },
  ],
  403: [
    { v: '40301', l: '中餐烹饪人员' },
    { v: '40302', l: '西餐烹饪人员' },
    { v: '40303', l: '调酒和茶艺人员' },
    { v: '40304', l: '营养配餐人员' },
    { v: '40305', l: '餐厅服务人员' },
  ],
  404: [
    { v: '40401', l: '饭店服务人员' },
    { v: '40402', l: '旅游及公共游览场所服务人员' },
    { v: '40403', l: '健身和娱乐场所服务人员' },
    { v: '40404', l: '高尔夫球馆' },
    { v: '40405', l: '保龄球馆' },
    { v: '40406', l: '撞球馆' },
    { v: '40410', l: '娱乐场所' },
  ],
  405: [
    { v: '40501', l: '公路道路运输服务人员' },
    { v: '40502', l: '铁路客货运输服务人员' },
    { v: '40503', l: '航空运输服务人员' },
    { v: '40504', l: '水上运输服务人员' },
  ],
  406: [{ v: '40601', l: '医疗卫生辅助服务人员' }],
  407: [
    { v: '40701', l: '社会中介服务人员' },
    { v: '40702', l: '物业管理人员' },
    { v: '40703', l: '供水、供热、供电及生活燃料供应服务人员' },
    { v: '40704', l: '美容美发形象设计人员' },
    { v: '40705', l: '摄影服务人员' },
    { v: '40706', l: '验光配镜助听人员' },
    { v: '40707', l: '洗染织补人员' },
    { v: '40708', l: '浴池服务人员' },
    { v: '40709', l: '印章刻字人员' },
    { v: '40710', l: '日用机电产品维修人员' },
    { v: '40711', l: '办公设备维修人员' },
    { v: '40712', l: '保育、家庭服务人员' },
    { v: '40713', l: '环境卫生人员' },
    { v: '40714', l: '殡葬服务人员' },
    { v: '40715', l: '个体商业' },
    { v: '40716', l: '瓦斯器具店' },
    { v: '40717', l: '液化瓦斯零售店' },
  ],
  499: [{ v: '49900', l: '其他商业、服务人员' }],
  501: [
    { v: '50101', l: '大田作物生产人员' },
    { v: '50102', l: '农业实验人员' },
    { v: '50103', l: '园艺作物生产人员' },
    { v: '50104', l: '热带作物生产人员' },
    { v: '50105', l: '中药材生产人员' },
    { v: '50106', l: '农副林特产品加工人员' },
  ],
  502: [
    { v: '50201', l: '营造林人员' },
    { v: '50202', l: '森林资源管护人员' },
    { v: '50203', l: '野生动植物保护及自然保护区人员' },
    { v: '50204', l: '木材采运人员' },
  ],
  503: [
    { v: '50301', l: '家畜饲养人员' },
    { v: '50302', l: '家禽饲养人员' },
    { v: '50303', l: '蜜蜂饲养人员' },
    { v: '50304', l: '实验动物饲养人员' },
    { v: '50305', l: '动物疫病防治人员' },
    { v: '50306', l: '草业生产人员' },
  ],
  504: [
    { v: '50401', l: '水产养殖人员' },
    { v: '50402', l: '水产捕捞及有关人员' },
    { v: '50403', l: '水产品加工人员' },
    { v: '50499', l: '其他渔业生产人员' },
  ],
  505: [
    { v: '50501', l: '河道、水库管养人员' },
    { v: '50502', l: '农田灌排工程建设管理维护人员' },
    { v: '50503', l: '水土保持作业人员' },
    { v: '50504', l: '水文勘测作业人员' },
    { v: '50599', l: '其他水利设施管理养护人员' },
  ],
  599: [
    { v: '59901', l: '农林专用机械操作人员' },
    { v: '59902', l: '农村能源开发利用人员' },
  ],
  601: [
    { v: '60101', l: '地质勘查人员' },
    { v: '60102', l: '测绘人员' },
    { v: '60103', l: '矿物开采人员' },
    { v: '60104', l: '矿物处理人员' },
    { v: '60105', l: '钻井人员' },
    { v: '60106', l: '石油、天然气开采人员' },
    { v: '60107', l: '盐业生产人员' },
    { v: '60199', l: '其他勘测矿物开采人员' },
  ],
  602: [
    { v: '60201', l: '炼铁人员' },
    { v: '60203', l: '铁合金冶炼人员' },
    { v: '60204', l: '重有色金属冶炼人员' },
    { v: '60205', l: '轻有色金属冶炼人员' },
    { v: '60206', l: '稀贵金属冶炼人员' },
    { v: '60207', l: '半导体材料制备人员' },
    { v: '60208', l: '金属轧制人员' },
    { v: '60209', l: '铸铁管人员' },
    { v: '60210', l: '碳素制品生产人员' },
    { v: '60211', l: '硬质合金生产人员' },
    { v: '60299', l: '其他金属冶炼、轧制人员' },
  ],
  603: [
    { v: '60301', l: '化工产品生产通用工艺人员' },
    { v: '60302', l: '石油炼制生产人员' },
    { v: '60303', l: '煤化工生产人员' },
    { v: '60304', l: '化学肥料生产人员' },
    { v: '60305', l: '无机化工产品生产人员' },
    { v: '60306', l: '基本有机化工产品生产人员' },
    { v: '60307', l: '合成树脂生产人员' },
    { v: '60308', l: '合成橡胶生产人员' },
    { v: '60309', l: '化学纤维生产人员' },
    { v: '60310', l: '合成革生产人员' },
    { v: '60311', l: '精细化工产品生产人员' },
    { v: '60312', l: '信息记录材料生产人员' },
    { v: '60313', l: '火药制造人员' },
    { v: '60314', l: '炸药制造人员' },
    { v: '60315', l: '林产化工产品生产人员' },
    { v: '60316', l: '复合材料加工人员' },
    { v: '60317', l: '日用化学品生产人员' },
    { v: '60399', l: '其他化工产品生产人员' },
  ],
  604: [
    { v: '60401', l: '机械冷加工人员' },
    { v: '60402', l: '机械热加工人员' },
    { v: '60403', l: '特种加工设备操作人员' },
    { v: '60404', l: '冷作钣金加工人员' },
    { v: '60405', l: '工件表面处理加工人员' },
    { v: '60406', l: '磨料磨具制造加工人员' },
    { v: '60407', l: '航天器件加工成型人员' },
    { v: '60499', l: '其他机械制造加工人员' },
  ],
  605: [
    { v: '60501', l: '基础件、部件装配人员' },
    { v: '60502', l: '机械设备装配人员' },
    { v: '60503', l: '动力设备装配人员' },
    { v: '60504', l: '电气元件及设备装配人员' },
    { v: '60505', l: '电子专用设备装配调试人员' },
    { v: '60506', l: '仪器仪表装配人员' },
    { v: '60507', l: '运输车辆装配人员' },
    { v: '60508', l: '膜法水处理设备制造人员' },
    { v: '60509', l: '医疗器械装配及假肢与矫形器制作人员' },
    { v: '60510', l: '日用机械电器制造装配人员' },
    { v: '60511', l: '五金制品制作装配人员' },
    { v: '60512', l: '装甲车辆装试人员' },
    { v: '60513', l: '枪炮制造人员' },
    { v: '60514', l: '弹制造人员' },
    { v: '60515', l: '引信加工制造人员' },
    { v: '60516', l: '火工品制造人员' },
    { v: '60517', l: '防化器材制造人员' },
    { v: '60518', l: '船舶制造人员' },
    { v: '60519', l: '航空产品装配与调试人员' },
    { v: '60520', l: '航空产品试验人员' },
    { v: '60521', l: '导弹卫星装配测试人员' },
    { v: '60522', l: '火箭发动机装配试验人员' },
    { v: '60523', l: '航天器结构强度温度环境试验人员' },
    { v: '60524', l: '靶场试验人员' },
    { v: '60599', l: '其他机电产品装配人员' },
  ],
  606: [
    { v: '60601', l: '机械设备维修人员' },
    { v: '60602', l: '仪器仪表修理人员' },
    { v: '60603', l: '民用航空器维修人员' },
  ],
  607: [
    { v: '60701', l: '电力设备安装人员' },
    { v: '60702', l: '发电运行值班人员' },
    { v: '60703', l: '输电、配电、变电设备值班人员' },
    { v: '60704', l: '电力设备检修人员' },
    { v: '60705', l: '供用电人员' },
    { v: '60706', l: '生活生产电力设备安装、操作、修理人员' },
  ],
  608: [
    { v: '60801', l: '电子器件制造人员' },
    { v: '60802', l: '电子元件制造人员' },
    { v: '60803', l: '电池制造人员' },
    { v: '60804', l: '电子设备装配调试人员' },
    { v: '60805', l: '电子产品维修人员' },
  ],
  609: [
    { v: '60901', l: '橡胶制品生产人员' },
    { v: '60902', l: '塑料制品加工人员' },
    { v: '60999', l: '其他橡胶和塑料制品生产人员' },
  ],
  610: [
    { v: '61001', l: '纤维预处理人员' },
    { v: '61002', l: '纺纱人员' },
    { v: '61003', l: '织造人员' },
    { v: '61004', l: '针织人员' },
    { v: '61005', l: '印染人员' },
    { v: '61099', l: '其他纺织、针织、印染人员' },
  ],
  611: [
    { v: '61101', l: '裁剪缝纫人员' },
    { v: '61102', l: '鞋帽制作人员' },
    { v: '61103', l: '皮革、毛皮加工人员' },
    { v: '61104', l: '缝纫制品再加工人员' },
  ],
  612: [
    { v: '61201', l: '粮油生产加工人员' },
    { v: '61202', l: '制糖和糖制品加工人员' },
    { v: '61203', l: '乳品、冷食品及罐头、饮料制作人员' },
    { v: '61204', l: '酿酒人员' },
    { v: '61205', l: '食品添加剂及调味品制作人员' },
    { v: '61206', l: '粮油食品制作人员' },
    { v: '61207', l: '屠宰加工人员' },
    { v: '61208', l: '肉、蛋食品加工人员' },
    { v: '61209', l: '饲料生产加工人员' },
    { v: '61299', l: '其他粮油、食品、饮料生产加工及饲料生产加工人员' },
  ],
  613: [
    { v: '61301', l: '原烟复烤人员' },
    { v: '61302', l: '卷烟生产人员' },
    { v: '61303', l: '烟用醋酸纤维丝束滤棒制作人员' },
  ],
  614: [
    { v: '61401', l: '合成药物制造人员' },
    { v: '61402', l: '生物技术制药(品)人员' },
    { v: '61403', l: '药物制剂人员' },
    { v: '61404', l: '中药制药人员' },
  ],
  615: [
    { v: '61501', l: '木材加工人员' },
    { v: '61502', l: '人造板生产人员' },
    { v: '61503', l: '木材制品制作人员' },
  ],
  616: [
    { v: '61601', l: '制浆人员' },
    { v: '61602', l: '造纸人员' },
    { v: '61603', l: '纸制品制作人员' },
    { v: '61699', l: '其他制浆、造纸和纸制品生产加工人员' },
  ],
  617: [
    { v: '61701', l: '水泥及水泥制品生产加工人员' },
    { v: '61702', l: '墙体屋面材料生产人员' },
    { v: '61703', l: '建筑防水密封材料生产人员' },
    { v: '61704', l: '建筑保温及吸音材料生产人员' },
    { v: '61705', l: '装饰石材生产人员' },
    { v: '61706', l: '非金属矿及其制品生产加工人员' },
    { v: '61707', l: '耐火材料生产人员' },
  ],
  618: [
    { v: '61801', l: '玻璃熔制人员' },
    { v: '61802', l: '玻璃纤维及制品生产人员' },
    { v: '61803', l: '石英玻璃制品加工人员' },
    { v: '61804', l: '陶瓷制品生产人员' },
    { v: '61805', l: '搪瓷制品生产人员' },
  ],
  619: [
    { v: '61901', l: '影视制品制作人员' },
    { v: '61902', l: '音像制品制作复制人员' },
    { v: '61903', l: '广播影视舞台设备安装调试及运行操作人员' },
    { v: '61904', l: '电影放映人员' },
    { v: '61905', l: '文物保护作业人员' },
  ],
  620: [
    { v: '62001', l: '印前处理人员' },
    { v: '62002', l: '印刷操作人员' },
    { v: '62003', l: '印后制作人员' },
  ],
  621: [
    { v: '62101', l: '珠宝首饰加工制作人员' },
    { v: '62102', l: '地毯制作人员' },
    { v: '62103', l: '玩具制作人员' },
    { v: '62104', l: '漆器工艺品制作人员' },
    { v: '62105', l: '抽纱刺绣工艺品制作人员' },
    { v: '62106', l: '金属工艺品制作人员' },
    { v: '62107', l: '雕刻工艺品制作人员' },
    { v: '62108', l: '美术品制作人员' },
    { v: '62199', l: '其他工艺、美术品制作人员' },
  ],
  622: [
    { v: '62201', l: '文教用品制作人员' },
    { v: '62202', l: '体育用品制作人员' },
    { v: '62203', l: '乐器制作人员' },
  ],
  623: [
    { v: '62301', l: '土石方施工人员' },
    { v: '62302', l: '砌筑人员' },
    { v: '62303', l: '混凝土配制及制品加工人员' },
    { v: '62304', l: '钢筋加工人员' },
    { v: '62305', l: '施工架子搭设人员' },
    { v: '62306', l: '工程防水人员' },
    { v: '62307', l: '装饰装修人员' },
    { v: '62308', l: '古建筑修建人员' },
    { v: '62309', l: '筑路、养护、维修人员' },
    { v: '62310', l: '工程设备安装人员' },
    { v: '62399', l: '其他工程施工人员' },
  ],
  624: [
    { v: '62401', l: '公(道)路运输机械设备操作及有关人员' },
    { v: '62402', l: '铁路、地铁运输机械设备操作及有关人员' },
    { v: '62403', l: '民用航空设备操作及有关人员' },
    { v: '62404', l: '水上运输设备操作及有关人员' },
    { v: '62405', l: '起重装卸机械操作及有关人员' },
    { v: '62499', l: '其他运输设备操作人员及有关人员' },
  ],
  625: [
    { v: '62501', l: '环境监测人员' },
    { v: '62502', l: '海洋环境调查与监测人员' },
    { v: '62503', l: '废物处理人员' },
  ],
  626: [
    { v: '62601', l: '检验人员' },
    { v: '62602', l: '航空产品检验人员' },
    { v: '62603', l: '航天器检验测试人员' },
    { v: '62604', l: '计量人员' },
  ],
  699: [
    { v: '69901', l: '包装人员' },
    { v: '69902', l: '机泵操作人员' },
    { v: '69903', l: '简单体力劳动人员' },
  ],
  700: [{ v: '70000', l: '军人' }],
  800: [
    { v: '80000', l: '其他从业人员' },
    { v: '80001', l: '特殊运动从业人员' },
    { v: '80002', l: '特殊作业人员' },
  ],
};

let lve4 = {
  10100: [
    {
      v: '1010001',
      l: '中国共产党中央委员会和地方各级党组织负责人',
      life: '3',
      doc: '1',
    },
  ],
  10201: [
    { v: '1020101', l: '国家权力机关及其工作机构负责人', life: '3', doc: '1' },
  ],
  10202: [
    { v: '1020201', l: '人民政协及其工作机构负责人', life: '3', doc: '1' },
  ],
  10203: [{ v: '1020301', l: '人民法院负责人', life: '3', doc: '1' }],
  10204: [{ v: '1020401', l: '人民检察院负责人' }],
  10205: [{ v: '1020501', l: '国家行政机关及其工作机构负责人' }],
  10229: [{ v: '1029901', l: '其他国家机关及其工作机构负责人' }],
  10301: [{ v: '1030101', l: '民主党派负责人' }],
  10302: [
    { v: '1030201', l: '工会负责人' },
    { v: '1030202', l: '中国共产主义青年团负责人' },
    { v: '1030203', l: '妇女联合会负责人' },
    { v: '1030299', l: '其他人民团体及其工作机构负责人' },
  ],
  10303: [{ v: '1030301', l: '群众自治组织负责人' }],
  10399: [{ v: '1039901', l: '其他社会团体及其工作机构负责人' }],
  10401: [
    { v: '1040101', l: '高等学校校长' },
    { v: '1040102', l: '中等职业教育学校校长' },
    { v: '1040103', l: '中小学校校长' },
    { v: '1040199', l: '其他教育教学单位负责人' },
  ],
  10402: [{ v: '1040201', l: '卫生单位负责人' }],
  10403: [{ v: '1040301', l: '科研单位负责人' }],
  10499: [{ v: '1049901', l: '其他事业单位负责人' }],
  10501: [
    { v: '1050101', l: '企业董事' },
    { v: '1050102', l: '企业经理' },
    { v: '1050103', l: '企业职能部门经理或主管' },
    { v: '1050104', l: '工厂、企业负责人' },
    { v: '1050105', l: '出版商、书店、文具店负责人' },
  ],
  20101: [{ v: '2010101', l: '哲学研究人员' }],
  20102: [{ v: '2010201', l: '经济学研究人员' }],
  20103: [{ v: '2010301', l: '法学研究人员' }],
  20104: [{ v: '2010401', l: '社会学研究人员' }],
  20105: [{ v: '2010501', l: '教育科学研究人员' }],
  20106: [{ v: '2010601', l: '文学、艺术研究人员' }],
  20107: [{ v: '2010701', l: '图书馆学、情报学研究人员' }],
  20108: [{ v: '2010801', l: '历史学研究人员' }],
  20109: [{ v: '2010901', l: '管理科学研究人员' }],
  20110: [{ v: '2011001', l: '数学研究人员' }],
  20111: [{ v: '2011101', l: '物理学研究人员' }],
  201112: [{ v: '2011201', l: '化学研究人员' }],
  20113: [{ v: '2011301', l: '天文学研究人员' }],
  20114: [{ v: '2011401', l: '地球科学研究人员' }],
  20115: [{ v: '2011501', l: '生物科学研究人员' }],
  20116: [{ v: '2011601', l: '农业科学研究人员' }],
  20117: [{ v: '2011701', l: '医学研究人员' }],
  20118: [{ v: '2011801', l: '体育研究人员' }],
  20201: [{ v: '2020101', l: '地质勘探工程技术人员' }],
  20202: [
    { v: '2020201', l: '大地测量工程技术人员' },
    { v: '2020202', l: '工程测量工程技术人员' },
    { v: '2020203', l: '摄影测量与遥感工程技术人员' },
    { v: '2020204', l: '地图制图与印刷工程技术人员' },
    { v: '2020205', l: '海洋测绘工程技术人员(海上作业)' },
    { v: '2020206', l: '海洋测绘工程技术人员(非海上作业)' },
  ],
  20203: [
    { v: '2020301', l: '采矿工程技术人员' },
    { v: '2020302', l: '选矿与矿物加工工程技术人员' },
    { v: '2020303', l: '矿寻工程师、技师、领班' },
  ],
  20204: [
    { v: '2020401', l: '石油开采工程技术人员' },
    { v: '2020402', l: '石油储运工程技术人员' },
  ],
  20205: [
    { v: '2020501', l: '冶炼工程技术人员' },
    { v: '2020502', l: '轧制工程技术人员' },
    { v: '2020503', l: '焦化工程技术人员' },
    { v: '2020504', l: '金属材料工程技术人员' },
    { v: '2020505', l: '耐火材料工程技术人员' },
    { v: '2020506', l: '碳素材料工程技术人员' },
    { v: '2020507', l: '冶金热能工程技术人员' },
  ],
  20206: [
    { v: '2020601', l: '化工实验工程技术人员' },
    { v: '2020602', l: '化工设计工程技术人员' },
    { v: '2020603', l: '化工生产工程技术人员' },
  ],
  20207: [
    { v: '2020701', l: '机械设计工程技术人员' },
    { v: '2020702', l: '机械制造工程技术人员' },
    { v: '2020703', l: '仪器仪表工程技术人员' },
    { v: '2020704', l: '设备工程技术人员' },
    { v: '2020705', l: '数控程控员' },
  ],
  20208: [
    { v: '2020801', l: '装甲车辆工程技术人员' },
    { v: '2020802', l: '火炮枪械工程技术人员' },
    { v: '2020803', l: '弹箭工程技术人员' },
    { v: '2020804', l: '火炸药工程技术人员' },
    { v: '2020805', l: '光电火控工程技术人员' },
  ],
  20209: [
    { v: '2020901', l: '飞机设计工程技术人员' },
    { v: '2020902', l: '飞机制造工程技术人员' },
    { v: '2020903', l: '飞机发动机设计工程技术人员' },
    { v: '2020904', l: '飞机发动机制造工程技术人员' },
  ],
  20210: [{ v: '2021001', l: '航天工程技术人员' }],
  20211: [
    { v: '2021101', l: '电子材料工程技术人员' },
    { v: '2021102', l: '电子元器件工程技术人员' },
    { v: '2021103', l: '雷达系统工程技术人员' },
    { v: '2021104', l: '广播视听设备工程技术人员' },
    { v: '2021105', l: '电子仪器与测量工程技术人员' },
    { v: '2021106', l: '工程师' },
  ],
  20212: [{ v: '2021201', l: '通信工程技术人员' }],
  20213: [
    { v: '2021301', l: '计算机硬件技术人员' },
    { v: '2021302', l: '计算机软件技术人员' },
    { v: '2021303', l: '计算机网络技术人员' },
    { v: '2021304', l: '计算机系统分析技术人员' },
    { v: '2021305', l: '维护工程师' },
    { v: '2021306', l: '销售工程师' },
    { v: '2021307', l: '计算机乐谱制作师' },
    { v: '2021308', l: '数字视频合成师' },
    { v: '2021309', l: '计算机软件产品检验员' },
  ],
  20214: [
    { v: '2021401', l: '电机与电器工程技术人员' },
    { v: '2021402', l: '电力拖动与自动控制工程技术人员' },
    { v: '2021403', l: '电线电缆与电工材料工程技术人员' },
    { v: '2021404', l: '可编程序控制系统设计师' },
  ],
  20215: [
    { v: '2021501', l: '发电工程技术人员' },
    { v: '2021502', l: '输变电工程技术人员' },
    { v: '2021503', l: '供用电工程技术人员' },
  ],
  20216: [{ v: '2021601', l: '邮政工程技术人员' }],
  20217: [
    { v: '2021701', l: '广播电视编播工程技术人员' },
    { v: '2021702', l: '广播电视传输覆盖工程技术人员' },
    { v: '2021703', l: '电影工程技术人员' },
  ],
  20218: [
    { v: '2021801', l: '汽车运用工程技术人员' },
    { v: '2021802', l: '船舶运用工程技术人员' },
    {
      v: '2021803',
      l: '水上交通工程技术人员(进行水上安全监督管理、搜寻救助等行动)',
    },
    { v: '2021804', l: '水上交通工程技术人员(不参与水上行动)' },
    { v: '2021805', l: '海上救助打捞工程技术人员(参与海上艘救、打捞行动)' },
    {
      v: '2021806',
      l: '海上救助打捞工程技术人员(救助打捞技术、装备研究、设计)',
    },
    { v: '2021807', l: '船舶检验工程技术人员' },
  ],
  20219: [
    { v: '2021901', l: '民用航空器维修与适航审定专业技术人员' },
    { v: '2021902', l: '航行航空管理及飞行程序设计工程技术人员' },
    {
      v: '2021903',
      l: '通用航空技术人员(从事航空摄影、航空物探、航空吊挂吊装、石油航空作业、航空环境污染监测、航空护林、播种、喷施、研究航空体育运动等)',
    },
    { v: '2021904', l: '航空运输研究人员' },
  ],
  20220: [
    { v: '2022001', l: '铁道运输工程研究人员' },
    { v: '2022002', l: '铁路机务工程技术人员' },
    { v: '2022003', l: '铁路车辆工程技术人员' },
    { v: '2022004', l: '铁路电务工程技术人员' },
  ],
  20221: [
    { v: '2022101', l: '城镇规划设计工程技术人员' },
    { v: '2022102', l: '建筑设计工程技术人员' },
    { v: '2022103', l: '土木建筑工程技术人员(到工地监督检查指导)' },
    { v: '2022104', l: '土木建筑工程技术人员(不到工地)' },
    { v: '2022105', l: '风景园林工程技术人员' },
    { v: '2022106', l: '道路与桥梁工程技术人员' },
    { v: '2022107', l: '港口与航道工程技术人员' },
    { v: '2022108', l: '机场工程技术人员' },
    { v: '2022109', l: '铁路建筑工程技术人员' },
    { v: '2022110', l: '水利水电建筑工程技术人员' },
    { v: '2022111', l: '内勤工作人员' },
    { v: '2022112', l: '承包商(土木建筑)' },
    { v: '2022113', l: '建筑公司负责人、业务员' },
    { v: '2022114', l: '引导参观工地服务人员' },
  ],
  20222: [
    { v: '2022201', l: '硅酸盐工程技术人员' },
    { v: '2022202', l: '石棉、石膏、云母等非金属矿及制品工程技术人员' },
    { v: '2022203', l: '玻璃钢、复合材料等无机非金属新材料工程技术人员' },
  ],
  20223: [
    { v: '2022301', l: '林业生态环境工程技术人员' },
    { v: '2022302', l: '森林培育工程技术人员' },
    { v: '2022303', l: '园林绿化工程技术人员' },
    {
      v: '2022304',
      l: '野生动物保护与繁殖利用工程技术人员(研究调查野生动物资源及野生动物驯化、繁殖技术人员)',
    },
    {
      v: '2022305',
      l: '野生动物保护与繁殖利用工程技术人员（设计、研制猎枪、猎具，改进狩猎技、术，研究野生动物肉、毛皮产品测定、分析、储藏技术人员）',
    },
    { v: '2022306', l: '自然保护区工程技术人员' },
    { v: '2022307', l: '森林保护工程技术人员' },
    { v: '2022308', l: '木、竹材加工工程技术人员' },
    { v: '2022309', l: '森林采伐和运输工程技术人员' },
    { v: '2022310', l: '经济林和林特产品加工工程技术人员' },
    { v: '2022311', l: '森林资源管理与监测工程技术人员' },
  ],
  20224: [
    { v: '2022401', l: '水资源勘测工程技术人员' },
    { v: '2022402', l: '治河及泥沙治理工程技术人员' },
    { v: '2022403', l: '水利工程师' },
  ],
  20225: [
    { v: '2022501', l: '海洋调查与监测工程技术人员' },
    { v: '2022502', l: '海洋环境预报工程技术人员' },
    {
      v: '2022503',
      l: '海洋资源开发利用和保护工程技术人员(海洋矿产、油气资源开发利用)',
    },
    {
      v: '2022504',
      l: '海洋资源开发利用和保护工程技术人员(海水淡化、潮汐能、波浪能等能源开发、海洋生态系统保护等)',
    },
    {
      v: '2022505',
      l: '海洋工程勘察设计工程技术人员（进行海洋、海底勘察人员）',
    },
    { v: '2022506', l: '海洋工程勘察设计工程技术人员(工程设计规划人员)' },
  ],
  20226: [
    { v: '2022601', l: '水产养殖工程技术人员' },
    { v: '2022602', l: '渔业资源开发利用工程技术人员' },
  ],
  20227: [
    { v: '2022701', l: '纺纱工程技术人员' },
    { v: '2022702', l: '织造工程技术人员' },
    { v: '2022703', l: '染整工程技术人员' },
    { v: '2022704', l: '工程师' },
  ],
  20228: [
    { v: '2022801', l: '食品营养卫生研究及食品加工、储运工艺技术开发应用人员' },
  ],
  20229: [
    { v: '2022901', l: '气象观测人员' },
    { v: '2022902', l: '天气预报人员' },
    { v: '2022903', l: '气候监测预测人员' },
    { v: '2022904', l: '应用气象人员' },
    { v: '2022905', l: '气象服务人员' },
  ],
  20230: [{ v: '2023001', l: '地震工程技术人员' }],
  20231: [
    { v: '2023101', l: '环境损害控制工程技术人员' },
    { v: '2023102', l: '环境监测工程技术人员' },
    { v: '2023103', l: '环境污染治理工程技术人员' },
    { v: '2023104', l: '灾害信息员' },
    { v: '2023105', l: '紧急救助员' },
  ],
  20232: [
    {
      v: '2023201',
      l: '安全工程技术人员(安全科学技术研究、开发与推广，安全工程设计施工、安全生产运行控制，安全检测、监督、评估，事故调查分析与预测预防)',
    },
    { v: '2023202', l: '安全防范系统安装维护员' },
    { v: '2023203', l: '安全防范设计评估师' },
  ],
  20233: [
    {
      v: '2023301',
      l: '标准化工程技术人员(从事技术、服务、管理标准化的研究和标准制定、实施、监督、管理的工程技术人员)',
    },
    { v: '2023302', l: '计量工程技术人员' },
    { v: '2023303', l: '质量工程技术人员' },
  ],
  20234: [
    { v: '2023401', l: '普通工业管理工程技术人员' },
    { v: '2023402', l: '系统规划与管理工程技术人员' },
    { v: '2023403', l: '设施规划与设计工程技术人员' },
    { v: '2023404', l: '生产组织与管理工程技术人员' },
    { v: '2023405', l: '质量管理与可靠性控制工程技术人员' },
    { v: '2023406', l: '营销工程技术人员' },
    { v: '2023407', l: '人力资源开发与管理工程技术人员' },
  ],
  20301: [{ v: '2030101', l: '土壤肥料技术人员' }],
  20302: [{ v: '2030201', l: '植物保护技术人员' }],
  20303: [{ v: '2030301', l: '园艺技术人员' }],
  20304: [{ v: '2030401', l: '作物遗传育种栽培技术人员' }],
  20305: [
    { v: '2030501', l: '兽医' },
    { v: '2030502', l: '兽药技术人员' },
  ],
  20306: [
    { v: '2030601', l: '畜牧技术人员' },
    { v: '2030602', l: '草业技术人员' },
  ],
  20399: [{ v: '2039901', l: '农业技师、指导员' }],
  20401: [
    { v: '2040101', l: '飞行驾驶员' },
    { v: '2040102', l: '飞机机械员' },
    { v: '2040103', l: '飞行领航员' },
    { v: '2040104', l: '飞行通信员' },
  ],
  20402: [
    { v: '2040201', l: '甲板部技术人员' },
    { v: '2040202', l: '轮机部技术人员' },
    { v: '2040203', l: '船舶引航员' },
    { v: '2040204', l: '船长' },
    { v: '2040205', l: '轮机长' },
    { v: '2040206', l: '大副' },
    { v: '2040207', l: '二副' },
    { v: '2040208', l: '三副' },
    { v: '2040209', l: '大管轮' },
    { v: '2040210', l: '二管轮' },
    { v: '2040211', l: '三管轮' },
    { v: '2040212', l: '报务员' },
    { v: '2040213', l: '事务长' },
    { v: '2040214', l: '引水员' },
  ],
  20403: [
    { v: '2040301', l: '民航飞机驾驶员' },
    { v: '2040302', l: '民航飞机机械员' },
    { v: '2040303', l: '民航飞行领航员' },
    { v: '2040304', l: '民航飞行通信员' },
  ],
  20501: [
    { v: '2050101', l: '内科医师' },
    { v: '2050102', l: '外科医师' },
    { v: '2050103', l: '儿科医师' },
    { v: '2050104', l: '妇产科医师' },
    { v: '2050105', l: '眼科医师' },
    { v: '2050106', l: '耳鼻喉科医师' },
    { v: '2050107', l: '口腔科医师' },
    { v: '2050108', l: '皮肤科医师' },
    { v: '2050109', l: '精神科医师' },
    { v: '2050110', l: '心理医师' },
    { v: '2050111', l: '传染病科医师' },
    { v: '2050112', l: '急诊科医师' },
    { v: '2050113', l: '康复科医师' },
    { v: '2050114', l: '麻醉科医师' },
    { v: '2050115', l: '病理科医师' },
    { v: '2050116', l: '放射科医师' },
    { v: '2050117', l: '核医学医师' },
    { v: '2050118', l: '超声诊断科医师' },
    { v: '2050119', l: '放射肿瘤科医师' },
    { v: '2050120', l: '全科医师' },
    { v: '2050121', l: '乡村医师' },
    { v: '2050122', l: '妇幼保健医师' },
    { v: '2050123', l: '输（采供）血医师' },
  ],
  20502: [
    { v: '2050201', l: '中医内科医师' },
    { v: '2050202', l: '中医外科医师' },
    { v: '2050203', l: '中医妇科医师' },
    { v: '2050204', l: '中医儿科医师' },
    { v: '2050205', l: '中医眼科医师' },
    { v: '2050206', l: '中医皮肤科医师' },
    { v: '2050207', l: '中医骨伤科医师' },
    { v: '2050208', l: '中医肛肠科医师' },
    { v: '2050209', l: '中医耳鼻喉科医师' },
    { v: '2050210', l: '针炙科医师' },
    { v: '2050211', l: '推拿按摩科医师' },
  ],
  20503: [{ v: '2050301', l: '中西医结合医师' }],
  20504: [{ v: '2050401', l: '民族医师' }],
  20505: [
    { v: '2050501', l: '流行病学医师' },
    { v: '2050502', l: '营养与食品卫生医师' },
    { v: '2050503', l: '环境卫生医师' },
    { v: '2050504', l: '职业病医师' },
    { v: '2050505', l: '劳动(职业)卫生医师' },
    { v: '2050506', l: '放射卫生医师' },
    { v: '2050507', l: '少儿和学校卫生医师' },
    { v: '2050508', l: '健康管理师' },
    { v: '2050509', l: '公共营养师' },
  ],
  20506: [
    { v: '2050601', l: '西药剂师' },
    { v: '2050602', l: '中药药师' },
  ],
  20507: [
    { v: '2050701', l: '影像技师' },
    { v: '2050702', l: '麻醉技师' },
    { v: '2050703', l: '病理技师' },
    { v: '2050704', l: '临床检验技师' },
    { v: '2050705', l: '公卫检验技师' },
    { v: '2050706', l: '卫生工程技师' },
    { v: '2050707', l: '输(采供)血技师' },
    { v: '2050708', l: '放射线之技术人员' },
    { v: '2050709', l: '放射线之修理人员' },
    { v: '2050710', l: '医学设备管理师' },
  ],
  20508: [
    { v: '2050801', l: '病房护士' },
    { v: '2050802', l: '门诊护士' },
    { v: '2050803', l: '急诊护士' },
    { v: '2050804', l: '手术室护士' },
    { v: '2050805', l: '供应室护士' },
    { v: '2050806', l: '社区护士' },
    { v: '2050807', l: '助产士' },
    { v: '2050808', l: '医疗救护员' },
  ],
  20599: [
    { v: '2059901', l: '一般医务行政人员' },
    { v: '2059902', l: '监狱、看守所医生护理人员' },
  ],
  20601: [{ v: '2060101', l: '经济计划人员' }],
  20602: [{ v: '2060201', l: '统计人员' }],
  20603: [{ v: '2060301', l: '会计人员' }],
  20604: [{ v: '2060401', l: '审计人员' }],
  20605: [{ v: '2060501', l: '国际商务人员' }],
  20606: [{ v: '2060601', l: '品牌管理师' }],
  20607: [
    { v: '2060701', l: '职业信息分析师' },
    { v: '2060702', l: '调查分析师' },
    { v: '2060703', l: '黄金投资分析师' },
  ],
  20701: [
    { v: '2070101', l: '银行货币发行员' },
    { v: '2070102', l: '银行国库业务员' },
    { v: '2070103', l: '银行外汇管理员' },
    { v: '2070104', l: '银行清算员' },
    { v: '2070105', l: '银行信贷员' },
    { v: '2070106', l: '银行国际业务处理人员' },
    { v: '2070107', l: '银行信托业务处理人员' },
    { v: '2070108', l: '银行信用卡业务处理人员' },
    { v: '2070109', l: '银行储蓄员' },
    { v: '2070110', l: '现金运送车司机、点钞员、押送员' },
  ],
  20702: [
    { v: '2070201', l: '精算师' },
    { v: '2070202', l: '保险推销员' },
    { v: '2070203', l: '保险理赔员' },
    { v: '2070204', l: '保险收费员' },
    { v: '2070205', l: '保险调查员' },
    { v: '2070206', l: '征信人员' },
  ],
  20703: [
    { v: '2070301', l: '证券发行员' },
    { v: '2070302', l: '证券交易员' },
    { v: '2070303', l: '证券投资顾问' },
  ],
  20704: [{ v: '2070401', l: '信用管理师' }],
  20799: [
    { v: '2079901', l: '金融一般内勤人员' },
    { v: '2079902', l: '金融外务员' },
  ],
  20801: [{ v: '2080101', l: '法官' }],
  20802: [{ v: '2080201', l: '检察官' }],
  20803: [{ v: '2080301', l: '律师' }],
  20804: [{ v: '2080401', l: '公证员' }],
  20805: [{ v: '2080501', l: '法医' }],
  20806: [{ v: '2080601', l: '书记员' }],
  20807: [{ v: '2080701', l: '商业犯罪调查处理人员' }],
  20901: [{ v: '2090101', l: '高等教育教师' }],
  20902: [
    { v: '2090201', l: '中等职业教育理论' },
    { v: '2090202', l: '实习指导教师' },
  ],
  20903: [{ v: '2090301', l: '中学教师' }],
  20904: [{ v: '2090401', l: '小学教师' }],
  20905: [{ v: '2090501', l: '幼儿教师' }],
  20906: [{ v: '2090601', l: '特殊教育教师' }],
  20907: [{ v: '2090701', l: '家庭教师' }],
  20999: [
    { v: '2099901', l: '校工' },
    { v: '2099902', l: '军训教官、体育教师' },
    { v: '2099903', l: '汽车驾驶训练班教练' },
    { v: '2099904', l: '各项运动教练' },
    { v: '2099905', l: '飞行教官' },
    { v: '2099906', l: '飞行训练学员' },
    { v: '2099907', l: '一般学生' },
    { v: '2099908', l: '学龄前儿童' },
  ],
  21001: [
    { v: '2100101', l: '文学作家' },
    { v: '2100102', l: '曲艺作家' },
    { v: '2100103', l: '剧作家' },
    { v: '2100104', l: '作曲家' },
    { v: '2100105', l: '词作家' },
    { v: '2100106', l: '文艺评论员' },
    { v: '2100107', l: '皮影戏木偶戏作家' },
  ],
  21002: [
    { v: '2100201', l: '电影电视导演' },
    { v: '2100202', l: '戏剧导演' },
    { v: '2100203', l: '舞蹈编导' },
    { v: '2100204', l: '音乐指挥' },
    { v: '2100205', l: '武术指导' },
  ],
  21003: [
    { v: '2100301', l: '电影电视演员' },
    { v: '2100302', l: '戏剧演员' },
    { v: '2100303', l: '舞蹈演员' },
    { v: '2100304', l: '曲艺演员' },
    { v: '2100305', l: '杂技魔术演员' },
    { v: '2100306', l: '歌唱演员' },
    { v: '2100307', l: '皮影戏演员' },
    { v: '2100308', l: '木偶戏演员' },
    { v: '2100309', l: '武打演员' },
    { v: '2100310', l: '特技演员' },
    { v: '2100311', l: '高空杂技飞车飞人演员' },
    { v: '2100312', l: '配音演员' },
  ],
  21004: [
    { v: '2100401', l: '民族乐器演奏员' },
    { v: '2100402', l: '外国乐器演奏员' },
  ],
  21005: [
    { v: '2100501', l: '电影电视制片' },
    { v: '2100502', l: '电影电视场记' },
    { v: '2100503', l: '电影电视摄影师' },
    { v: '2100504', l: '照明师' },
    { v: '2100505', l: '录音师' },
    { v: '2100506', l: '剪辑师' },
    { v: '2100507', l: '美工师' },
    { v: '2100508', l: '化妆师' },
    { v: '2100509', l: '置景师' },
    { v: '2100510', l: '道具师' },
    { v: '2100511', l: '电影电视片发行人' },
    { v: '2100512', l: '舞台监督' },
    { v: '2100513', l: '戏剧制作人' },
    { v: '2100514', l: '影片商' },
    { v: '2100515', l: '灯光及音响工作人员' },
    { v: '2100516', l: '冲片人员' },
    { v: '2100517', l: '洗片人员' },
    { v: '2100518', l: '机械工电工' },
  ],
  21006: [
    { v: '2100601', l: '画家' },
    { v: '2100602', l: '篆刻家' },
    { v: '2100603', l: '雕塑家' },
    { v: '2100604', l: '书法家' },
    { v: '2100605', l: '陶艺家' },
  ],
  21007: [
    { v: '2100701', l: '特种工艺设计人员' },
    { v: '2100702', l: '实用工艺设计人员' },
    { v: '2100703', l: '现代工艺设计人员' },
    { v: '2100704', l: '装磺美术设计人员' },
    { v: '2100705', l: '服装设计人员' },
    { v: '2100706', l: '室内装饰设计人员' },
    { v: '2100707', l: '陈列展览设计人员' },
    { v: '2100708', l: '广告设计人员' },
  ],
  21101: [
    { v: '2110101', l: '教练员' },
    { v: '2110102', l: '运动员' },
    { v: '2110103', l: '球童' },
  ],
  21102: [
    { v: '2110201', l: '教练员' },
    { v: '2110202', l: '保龄球球员' },
  ],
  21103: [
    { v: '2110301', l: '教练员' },
    { v: '2110302', l: '桌球球员' },
  ],
  21104: [
    { v: '2110401', l: '教练员' },
    { v: '2110402', l: '羽毛球球员' },
  ],
  21105: [
    { v: '2110501', l: '教练员' },
    { v: '2110502', l: '游泳队员' },
  ],
  21106: [
    { v: '2110601', l: '教练员' },
    { v: '2110602', l: '射箭运动员' },
  ],
  21107: [
    { v: '2110701', l: '教练员' },
    { v: '2110702', l: '网球球员' },
  ],
  21108: [
    { v: '2110801', l: '教练员' },
    { v: '2110802', l: '垒球球员' },
  ],
  21109: [
    { v: '2110901', l: '教练员' },
    { v: '2110902', l: '滑冰运动员' },
  ],
  21110: [
    { v: '2111001', l: '教练员' },
    { v: '2111002', l: '射击人员' },
  ],
  21111: [
    { v: '2111101', l: '教练员' },
    { v: '2111102', l: '民族体育活动人员' },
  ],
  21112: [
    { v: '2111201', l: '教练员' },
    { v: '2111202', l: '举重运动员' },
  ],
  21113: [
    { v: '2111301', l: '教练员' },
    { v: '2111302', l: '篮球运动员' },
  ],
  21114: [
    { v: '2111401', l: '教练员' },
    { v: '2111402', l: '排球运动员' },
  ],
  21115: [
    { v: '2111501', l: '教练员' },
    { v: '2111502', l: '棒球运动员' },
  ],
  21116: [
    { v: '2111601', l: '教练员' },
    { v: '2111602', l: '田径运动员' },
  ],
  21117: [
    { v: '2111701', l: '教练员' },
    { v: '2111702', l: '体操运动员' },
  ],
  21118: [
    { v: '2111801', l: '教练员' },
    { v: '2111802', l: '滑雪运动员' },
  ],
  21119: [
    { v: '2111901', l: '教练员' },
    { v: '2111902', l: '驾乘人员' },
  ],
  21120: [
    { v: '2112001', l: '教练员' },
    { v: '2112002', l: '驾乘人员' },
  ],
  21121: [
    { v: '2112101', l: '教练员' },
    { v: '2112102', l: '驾乘人员' },
  ],
  21122: [
    { v: '2112201', l: '教练员' },
    { v: '2112202', l: '巧固球球员' },
  ],
  21123: [
    { v: '2112301', l: '教练员' },
    { v: '2112302', l: '手球运动员' },
  ],
  21124: [
    { v: '2112401', l: '教练员' },
    { v: '2112402', l: '驾乘人员' },
  ],
  21125: [
    { v: '2112501', l: '教练员' },
    { v: '2112502', l: '驾乘人员' },
  ],
  21126: [
    { v: '2112601', l: '教练员' },
    { v: '2112602', l: '足球运动员' },
  ],
  21127: [
    { v: '2112701', l: '教练员' },
    { v: '2112702', l: '曲棍球球员' },
  ],
  21128: [
    { v: '2112801', l: '教练员' },
    { v: '2112802', l: '冰上曲棍球球员' },
  ],
  21129: [
    { v: '2112901', l: '教练员' },
    { v: '2112902', l: '橄榄球球员' },
  ],
  21130: [
    { v: '2113001', l: '教练员' },
    { v: '2113002', l: '乒乓球球员' },
  ],
  21131: [
    { v: '2113101', l: '教练员' },
    { v: '2113102', l: '击剑运动员' },
  ],
  21132: [
    { v: '2113201', l: '教练员' },
    { v: '2113202', l: '水球球员' },
  ],
  21133: [
    { v: '2113301', l: '教练员' },
    { v: '2113302', l: '运动员' },
  ],
  21134: [
    { v: '2113401', l: '教练员' },
    { v: '2113402', l: '摔跤运动员' },
  ],
  21135: [
    { v: '2113501', l: '教练员' },
    { v: '2113502', l: '职业拳击运动员' },
    { v: '2113503', l: '业余拳击运动员' },
  ],
  21136: [{ v: '2113601', l: '裁判人员' }],
  21201: [
    { v: '2120101', l: '文字记者' },
    { v: '2120102', l: '摄影记者' },
    { v: '2120103', l: '外勤记者' },
    { v: '2120104', l: '战地记者' },
    { v: '2120105', l: '电视记者' },
  ],
  21202: [
    { v: '2120201', l: '文字编辑' },
    { v: '2120202', l: '美术编辑' },
    { v: '2120203', l: '技术编辑' },
    { v: '2120204', l: '电子出版物编辑' },
  ],
  21203: [{ v: '2120301', l: '校对员' }],
  21204: [
    { v: '2120401', l: '播音员' },
    { v: '2120402', l: '节目主持人' },
  ],
  21205: [
    { v: '2120501', l: '翻译' },
    { v: '2120502', l: '手语翻译员' },
  ],
  21206: [
    { v: '2120601', l: '图书资料业务人员' },
    { v: '2120602', l: '档案业务人员' },
    { v: '2120603', l: '缩微摄影人员' },
    { v: '2120604', l: '图书馆工作人员' },
  ],
  21207: [
    { v: '2120701', l: '考古工作者' },
    { v: '2120702', l: '文物鉴定和保管人员' },
    { v: '2120703', l: '文物保护专业人员' },
    { v: '2120704', l: '博物馆工作人员' },
  ],
  21299: [{ v: '2129901', l: '内勤人员' }],
  21300: [
    { v: '2130001', l: '宗教职业者' },
    { v: '2130002', l: '寺庙及教堂管理人员' },
    { v: '2130003', l: '宗教团体工作人员' },
    { v: '2130004', l: '僧尼道士传教人员' },
  ],
  30101: [
    { v: '3010101', l: '行政业务办公人员（内勤）' },
    { v: '3010102', l: '行政业务办公人员（外勤）' },
    { v: '3010103', l: '工商、税务、海关、城管等特定国家机关行政执法人员' },
    { v: '3010104', l: '缉私人员' },
    { v: '3010105', l: '客户服务管理师' },
  ],
  30102: [
    { v: '3010201', l: '秘书' },
    { v: '3010202', l: '公务(关）员' },
    { v: '3010203', l: '收发员' },
    { v: '3010204', l: '打字员' },
    { v: '3010205', l: '计算机操作员' },
    { v: '3010206', l: '制图员' },
  ],
  30201: [
    { v: '3020101', l: '警务行政及内勤人员' },
    { v: '3020102', l: '警察（负有巡逻任务者）' },
    { v: '3020103', l: '监狱看守所管理人员' },
    { v: '3020104', l: '交通警察' },
    { v: '3020105', l: '刑警' },
    { v: '3020106', l: '警务特勤' },
    { v: '3020107', l: '防暴警察' },
    { v: '3020108', l: '港口机场警卫及安全人员' },
    { v: '3020109', l: '警校学生' },
  ],
  30202: [
    { v: '3020201', l: '保安员' },
    { v: '3020202', l: '违禁品检查员' },
    { v: '3020203', l: '金融守押员' },
    { v: '3020204', l: '治安调查人员' },
    { v: '3020301', l: '灭火员' },
    { v: '3020302', l: '防毒防化防核抢险员' },
    { v: '3020303', l: '一般事故抢险员' },
    { v: '3020304', l: '抢险救援器材工具调配工' },
    { v: '3020305', l: '抢险救援器材维修工' },
    { v: '3020306', l: '火险监督员、防火审核员' },
    { v: '3020307', l: '可燃气体(毒气)检测员、危险物品监督员' },
    { v: '3020308', l: '建(构)筑物消防员' },
    { v: '3020309', l: '火灾瞭望观察员(瞭望塔)' },
    { v: '3020310', l: '火灾瞭望观察员(直升机)' },
  ],
  30301: [
    { v: '3030101', l: '邮政营业员' },
    { v: '3030102', l: '邮件分检、接发员' },
    { v: '3030103', l: '火车、汽车邮件押运员' },
    { v: '3030104', l: '轮船邮件押运员' },
    { v: '3030105', l: '报刊分发员' },
    { v: '3030106', l: '投递员(外勤)' },
    { v: '3030107', l: '投递员(内勤)' },
    { v: '3030108', l: '邮政储汇员' },
    { v: '3030109', l: '报刊发行、零售员' },
    { v: '3030110', l: '集邮业务员' },
    { v: '3030111', l: '邮政业务档案员' },
    { v: '3030112', l: '邮政设备安装、维护人员' },
    { v: '3030113', l: '包裹邮务人员' },
    { v: '3030114', l: '包裹搬运人员' },
  ],
  30302: [
    { v: '3030201', l: '电信业务营业员' },
    { v: '3030202', l: '话务员' },
    { v: '3030203', l: '报务员' },
  ],
  30303: [
    { v: '3030301', l: '通信设备维护人员' },
    { v: '3030302', l: '电话、通信天、馈线架(敷)设、维修人员' },
    { v: '3030303', l: '电话电报交换设备安装、维护人员' },
    { v: '3030304', l: '电话机、寻呼机、传真机等用户终端设备维修员' },
    { v: '3030305', l: '通信系统供电设备、空调设备安装维护人员' },
    { v: '3030306', l: '市话障碍处理人员' },
    { v: '3030307', l: '电信装置维护修理工' },
    { v: '3030308', l: '电信工程设施架设人员' },
  ],
  30401: [
    { v: '3040101', l: '客户主任、统筹、设计师、市场拓展主任' },
    { v: '3040102', l: '撰稿员、一般内勤' },
  ],
  30402: [
    { v: '3040201', l: '广告业务员' },
    { v: '3040202', l: '广告招牌绘制人员' },
    { v: '3040203', l: '广告片拍摄录制人员' },
    { v: '3040204', l: '广告招牌架设人员' },
    { v: '3040205', l: '霓虹光管安装及维修人员' },
  ],
  30501: [
    { v: '3050101', l: '会展设计师' },
    { v: '3050102', l: '会展策划师' },
  ],
  30502: [{ v: '3050201', l: '景观设计师' }],
  30601: [{ v: '3060101', l: '建筑模型设计制作员' }],
  30602: [{ v: '3060202', l: '动画绘制员' }],
  30701: [{ v: '3070101', l: '数字视频(DV)策划制作师' }],
  30801: [{ v: '3080101', l: '智能楼宇管理师' }],
  40101: [
    { v: '4010101', l: '营业员' },
    { v: '4010102', l: '收银员' },
  ],
  40102: [
    { v: '4010201', l: '推销员' },
    { v: '4010202', l: '出版物发行员' },
    { v: '4010203', l: '服装模特' },
  ],
  40103: [
    { v: '4010301', l: '采购员' },
    { v: '4010302', l: '粮油、农副土特产品、蔬菜等商品收购员' },
    { v: '4010303', l: '中药购销员' },
  ],
  40104: [
    { v: '4010401', l: '旧货、古玩、字画等物品鉴定估价师' },
    { v: '4010402', l: '拍卖师' },
    { v: '4010403', l: '典当业务员' },
    { v: '4010404', l: '租赁业务员' },
  ],
  40105: [
    { v: '4010501', l: '废旧物资回收挑选工' },
    { v: '4010502', l: '废旧物资加工工' },
    { v: '4010503', l: '船舶拆解工(仪表拆解)' },
    { v: '4010504', l: '船舶拆解工(机舱、船体拆解)' },
    { v: '4010505', l: '船舶拆解工(油船清洗工)' },
  ],
  40106: [{ v: '4010601', l: '粮油管理员' }],
  40107: [
    { v: '4010701', l: '商品监督员' },
    { v: '4010702', l: '市场管理员' },
  ],
  40199: [
    { v: '4019901', l: '医药商品购销员' },
    { v: '4019902', l: '中药配方、制剂调配人员' },
    { v: '4019903', l: '个体摊贩' },
  ],
  40201: [
    { v: '4020101', l: '保管员' },
    { v: '4020102', l: '理货员' },
    { v: '4020103', l: '库存商品养护员' },
    { v: '4020104', l: '保鲜员' },
    { v: '4020105', l: '冷藏工' },
  ],
  40202: [
    { v: '4020201', l: '商品储运员' },
    { v: '4020202', l: '商品护运员' },
    { v: '4020203', l: '医药商品储运员' },
    { v: '4020204', l: '出版物储运员' },
    { v: '4020205', l: '货柜场检查员(控制室内)' },
    { v: '4020206', l: '货柜场检查员(站立于货柜上)' },
    { v: '4020207', l: '起重机操作员(于控制室内)' },
    { v: '4020208', l: '起重机操作员' },
  ],
  40301: [
    { v: '4030101', l: '中式烹调师' },
    { v: '4030102', l: '中式面点师' },
  ],
  40302: [
    { v: '4030201', l: '西式烹调师' },
    { v: '4030202', l: '西式面点师' },
  ],
  40303: [
    { v: '4030301', l: '调酒师' },
    { v: '4030302', l: '茶艺师' },
    { v: '4030303', l: '咖啡师' },
  ],
  40304: [{ v: '4030401', l: '营养配餐员' }],
  40305: [
    { v: '4030501', l: '餐厅服务员' },
    { v: '4030502', l: '餐具清洗保管员' },
    { v: '4030503', l: '经理人员' },
    { v: '4030504', l: '一般内勤人员' },
    { v: '4030505', l: '收帐员' },
  ],
  40401: [
    { v: '4040101', l: '前厅服务员' },
    { v: '4040102', l: '客房服务员' },
    { v: '4040103', l: '旅店服务员' },
    { v: '4040104', l: '旅馆负责人' },
    { v: '4040105', l: '一般内勤人员' },
    { v: '4040106', l: '外务员' },
    { v: '4040107', l: '收帐员' },
    { v: '4040108', l: '技工' },
  ],
  40402: [
    { v: '4040201', l: '导游' },
    { v: '4040202', l: '公共游览场所服务员' },
    { v: '4040203', l: '展览讲解员' },
    { v: '4040204', l: '插花员' },
    { v: '4040205', l: '盆景工' },
    { v: '4040206', l: '假山工' },
    { v: '4040207', l: '园林植物保护工' },
    { v: '4040208', l: '一般内勤人员' },
    { v: '4040209', l: '外务人员' },
  ],
  40403: [
    { v: '4040301', l: '群众性体育活动指导员' },
    { v: '4040302', l: '体育场地维护、保养工' },
    { v: '4040303', l: '康乐服务员' },
    { v: '4040304', l: '保健按摩师' },
    { v: '4040305', l: '桑拿业负责人' },
    { v: '4040306', l: '桑拿业柜台人员' },
    { v: '4040307', l: '社会文化指导员' },
    { v: '4040308', l: '芳香保健师' },
  ],
  40404: [
    { v: '4040401', l: '教练' },
    { v: '4040402', l: '球场保养人员' },
    { v: '4040403', l: '维护工人' },
    { v: '4040404', l: '球童' },
  ],
  40405: [
    { v: '4040501', l: '记分员' },
    { v: '4040502', l: '柜台人员' },
    { v: '4040503', l: '机械保护员' },
  ],
  40406: [
    { v: '4040601', l: '负责人' },
    { v: '4040602', l: '记分员' },
    { v: '4040702', l: '管理员' },
    { v: '4040703', l: '教练' },
    { v: '4040704', l: '售票员' },
    { v: '4040705', l: '救生员' },
    { v: '4040802', l: '管理员' },
    { v: '4040803', l: '潜水教练' },
    { v: '4040805', l: '救生员' },
    { v: '4040903', l: '游戏机操作员' },
    { v: '4040905', l: '兽栏清洁工' },
    { v: '4040906', l: '水电机械工' },
    { v: '4040907', l: '动物园驯兽师' },
    { v: '4040908', l: '兽医(动物园)' },
    { v: '4040909', l: '观赏动物饲养工' },
    { v: '4040910', l: '猛兽饲养工(动物园)' },
  ],
  40410: [
    { v: '4041001', l: '咖啡厅工作人员' },
    { v: '4041002', l: '茶室工作人员' },
    { v: '4041003', l: '酒家工作人员' },
    { v: '4041004', l: '夜总会工作人员' },
    { v: '4041005', l: '迪斯科舞厅工作人员' },
    { v: '4041006', l: '歌厅工作人员' },
    { v: '4041007', l: '酒吧工作人员' },
    { v: '4041008', l: '娱乐餐饮业负责人' },
    { v: '4041009', l: '保安人员' },
    { v: '4041010', l: '调酒师' },
    { v: '4041011', l: '乐队人员' },
    { v: '4041012', l: '歌唱人员' },
    { v: '4041013', l: 'DJ' },
    { v: '4041014', l: '迎宾' },
    { v: '4041015', l: '舞蹈演艺人员' },
    { v: '4041016', l: '电子游戏厅工作人员' },
    { v: '4041017', l: '网吧管理人员' },
  ],
  40501: [
    { v: '4050101', l: '汽车客运服务员' },
    { v: '4050102', l: '汽车货运站务员' },
    { v: '4050103', l: '汽车运输调度员' },
    { v: '4050104', l: '公路收费及监控员' },
    { v: '4050105', l: '加油站工作人员' },
    { v: '4050106', l: '洗车工人' },
    { v: '4050107', l: '客运车稽核人员' },
    { v: '4050108', l: '搬运工人' },
    { v: '4050109', l: '出租车行、货运行之负责人' },
    { v: '4050110', l: '外务员' },
    { v: '4050111', l: '内勤工作人员' },
    { v: '4050112', l: '车辆保管人员' },
    { v: '4050113', l: '汽车加气站操作工' },
  ],
  40502: [
    { v: '4050201', l: '旅客列车乘务员' },
    { v: '4050202', l: '车站客运服务员' },
    { v: '4050203', l: '货运计划员' },
    { v: '4050204', l: '铁路行李员' },
    { v: '4050205', l: '车站货运作业组织员' },
    { v: '4050206', l: '车站货运员' },
    { v: '4050207', l: '铁路站长' },
    { v: '4050208', l: '铁路票房工作人员、车站检票员、一般内勤人员' },
    { v: '4050209', l: '铁路播音员' },
    { v: '4050210', l: '铁路服务台人员' },
    { v: '4050211', l: '铁路车站清洁工人' },
    { v: '4050212', l: '铁路货运、搬运工人' },
    { v: '4050213', l: '铁路行李搬运工人' },
  ],
  40503: [
    { v: '4050301', l: '航空运输飞行服务员' },
    { v: '4050302', l: '航空运输地面服务员' },
    { v: '4050303', l: '航空站站长' },
    { v: '4050304', l: '一般内勤人员' },
    { v: '4050305', l: '塔台工作人员' },
    { v: '4050306', l: '关务人员' },
    { v: '4050307', l: '检查人员' },
    { v: '4050308', l: '运务人员' },
    { v: '4050309', l: '缉私人员' },
    { v: '4050310', l: '清洁工人（航空大厦内、机舱内）' },
    { v: '4050311', l: '机场内交通司机' },
    { v: '4050312', l: '行李货运搬运工人' },
    { v: '4050313', l: '加添燃料人员' },
    { v: '4050314', l: '飞机外壳洗刷人员' },
    { v: '4050315', l: '清洁工（登高、高墙或天花板）' },
    { v: '4050316', l: '跑道维护工' },
    { v: '4050318', l: '航空货运外务员' },
    { v: '4050319', l: '航空货运报关人员' },
    { v: '4050320', l: '航空货运理货员' },
    { v: '4050321', l: '灯塔修理员' },
    { v: '4050322', l: '喷气式民航班机乘务员' },
    { v: '4050323', l: '机场运行指挥员' },
  ],
  40504: [
    { v: '4050401', l: '船舶业务员' },
    { v: '4050402', l: '船舶客运员' },
    { v: '4050403', l: '港口客运员' },
    { v: '4050404', l: '厨师' },
    { v: '4050405', l: '医务人员' },
    { v: '4050406', l: '救难船员' },
    { v: '4050407', l: '实习生' },
    { v: '4050408', l: '码头工人及领班' },
    { v: '4050409', l: '堆高机操作员' },
    { v: '4050410', l: '水上运输理货员' },
    { v: '4050411', l: '报关员' },
  ],
  40601: [
    { v: '4060101', l: '病案员' },
    { v: '4060102', l: '护理员' },
    { v: '4060103', l: '口腔修复工' },
    { v: '4060104', l: '配膳员' },
    { v: '4060105', l: '卫生检验员' },
    { v: '4060106', l: '医用气体工' },
    { v: '4060107', l: '药房辅助员' },
    { v: '4060108', l: '卫生防疫、妇幼保健员' },
    { v: '4060109', l: '医院炊事' },
    { v: '4060110', l: '杂工' },
  ],
  40701: [
    { v: '4070101', l: '中介代理人' },
    { v: '4070102', l: '职业指导员' },
    { v: '4070103', l: '经纪人(内勤)' },
    { v: '4070104', l: '创业咨询师' },
    { v: '4070105', l: '婚姻家庭咨询师' },
    { v: '4070106', l: '礼仪主持人' },
    { v: '4070107', l: '体育经纪人' },
    { v: '4070108', l: '商务策划师' },
    { v: '4070109', l: '房地产策划师' },
  ],
  40702: [{ v: '4070201', l: '物业管理员' }],
  40703: [
    { v: '4070301', l: '工业及饮用水净化处理人员' },
    { v: '4070302', l: '工业及饮用水生产人员(水井工)' },
    { v: '4070303', l: '供水调度员' },
    { v: '4070304', l: '水表装修工' },
    { v: '4070305', l: '成型煤生产工' },
    { v: '4070307', l: '锅炉安装、调试、操作、检修人员' },
    { v: '4070308', l: '电信电力系统内勤人员' },
    { v: '4070309', l: '抄表员、收费员' },
    { v: '4070310', l: '自来水管装修人员' },
    { v: '4070311', l: '自来水厂水质分析员' },
    { v: '4070312', l: '煤气工程师' },
    { v: '4070313', l: '煤气管线装修工' },
    { v: '4070314', l: '煤气检查员' },
    { v: '4070315', l: '煤气储气槽、分装厂之工作人员' },
    { v: '4070316', l: '瓦斯分装工' },
  ],
  40704: [
    { v: '4070401', l: '美容师' },
    { v: '4070402', l: '美发师' },
    { v: '4070403', l: '洗头工' },
    { v: '4070404', l: '形象设计师' },
    { v: '4070405', l: '调香师' },
  ],
  40705: [
    { v: '4070501', l: '摄影师' },
    { v: '4070502', l: '冲印师' },
  ],
  40706: [
    { v: '4070601', l: '眼镜验光员' },
    { v: '4070602', l: '眼镜定配工' },
    { v: '4070603', l: '助听器验配师' },
  ],
  40707: [
    { v: '4070701', l: '洗衣师、烫衣师、织补师' },
    { v: '4070702', l: '染色师' },
  ],
  40708: [
    { v: '4070801', l: '浴池服务员' },
    { v: '4070802', l: '修脚师' },
  ],
  40709: [{ v: '4070901', l: '刻制印章工' }],
  40710: [
    { v: '4071001', l: '家用电子产品维修工' },
    { v: '4071002', l: '家用电器产品维修工' },
    { v: '4071003', l: '照相器材维修工' },
    { v: '4071004', l: '钟表维修工' },
    { v: '4071005', l: '乐器维修工' },
    { v: '4071006', l: '自行车维修工' },
    { v: '4071007', l: '锁具修理工' },
  ],
  40711: [{ v: '4071101', l: '办公设备维修工' }],
  40712: [
    { v: '4071201', l: '保育员' },
    { v: '4071202', l: '家庭服务员' },
    { v: '4071203', l: '家庭主妇' },
    { v: '4071204', l: '孤残儿童护理员' },
  ],
  40713: [
    { v: '4071301', l: '垃圾清运工' },
    { v: '4071302', l: '保洁员' },
    { v: '4071303', l: '下水道清洁工' },
    { v: '4071304', l: '清洁打蜡工人' },
    { v: '4071305', l: '高楼外部清洁工、烟囱清洁工' },
  ],
  40714: [
    { v: '4071401', l: '殡仪服务员' },
    { v: '4071402', l: '尸体接运工' },
    { v: '4071403', l: '尸体防腐工' },
    { v: '4071404', l: '尸体整容工' },
    { v: '4071405', l: '尸体火化工' },
    { v: '4071406', l: '墓地管理员' },
  ],
  40715: [
    { v: '4071501', l: '橱具商' },
    { v: '4071502', l: '陶瓷器商' },
    { v: '4071503', l: '古董商' },
    { v: '4071504', l: '花卉商' },
    { v: '4071505', l: '米商' },
    { v: '4071506', l: '杂货商' },
    { v: '4071507', l: '玻璃商' },
    { v: '4071508', l: '果菜商' },
    { v: '4071509', l: '石材商' },
    { v: '4071510', l: '建材商' },
    { v: '4071511', l: '铁材商' },
    { v: '4071512', l: '木材商' },
    { v: '4071513', l: '五金商' },
    { v: '4071514', l: '电器商' },
    { v: '4071515', l: '水电卫生器材商' },
    { v: '4071516', l: '机车买卖商(不含修理)' },
    { v: '4071517', l: '汽车买卖商(不含修理)' },
    { v: '4071518', l: '车辆器材商' },
    { v: '4071519', l: '矿物油、香烛买卖商' },
    { v: '4071520', l: '眼镜商' },
    { v: '4071521', l: '食品商' },
    { v: '4071522', l: '文具商' },
    { v: '4071523', l: '布商' },
    { v: '4071524', l: '服饰买卖商' },
    { v: '4071525', l: '鱼贩' },
    { v: '4071526', l: '肉贩' },
    { v: '4071527', l: '屠宰' },
    { v: '4071528', l: '药品买卖商' },
    { v: '4071529', l: '医疗器械仪器商' },
    { v: '4071530', l: '化学原料商、农药买卖商' },
    { v: '4071531', l: '手工艺品买卖商' },
    { v: '4071532', l: '银楼珠宝、当铺负责人及工作人员' },
    { v: '4071533', l: '农具商' },
  ],
  40716: [
    { v: '4071601', l: '负责人' },
    { v: '4071602', l: '店员' },
    { v: '4071604', l: '装饰工' },
  ],
  40717: [
    { v: '4071701', l: '负责人及工作人员' },
    { v: '4071702', l: '售货商' },
  ],
  49900: [
    { v: '4990001', l: '一般物品送货员、快递员' },
    { v: '4990002', l: '特殊物品送货员、快递员' },
    { v: '4990003', l: '公证行外务员' },
    { v: '4990004', l: '报关行外务员' },
    { v: '4990005', l: '鞋匠、伞匠' },
    { v: '4990006', l: '代书(内勤)' },
    { v: '4990007', l: '勘察师' },
    { v: '4990008', l: '地磅场工作人员' },
    { v: '4990009', l: '豆制品工艺师' },
    { v: '4990010', l: '呼叫服务员' },
    { v: '4990011', l: '牛肉分级员' },
    { v: '4990012', l: '首饰设计制作员' },
    { v: '4990013', l: '酿酒师' },
    { v: '4990014', l: '照明设计师' },
    { v: '4990015', l: '模具设计师' },
    { v: '4990016', l: '纺织面料设计师' },
    { v: '4990017', l: '玩具设计师' },
    { v: '4990018', l: '花艺环境设计师' },
    { v: '4990019', l: '霓虹灯制作员' },
    { v: '4990020', l: '糖果工艺师' },
    { v: '4990021', l: '陶瓷产品设计师' },
    { v: '4990022', l: '皮具设计师' },
    { v: '4990023', l: '珠宝首饰评估师' },
    { v: '4990024', l: '家具设计师' },
    { v: '4990025', l: '地毯设计师' },
    { v: '4990026', l: '家用纺织品设计师' },
    { v: '4990027', l: '企业文化师' },
    { v: '4990028', l: '网络课件设计师' },
    { v: '4990029', l: '危险物品送货员' },
  ],
  50101: [
    { v: '5010101', l: '农夫' },
    { v: '5010102', l: '啤酒花生产工' },
    { v: '5010103', l: '作物种子繁育工' },
    { v: '5010104', l: '农作物病虫草害防治工' },
    { v: '5010105', l: '农场经营者(不亲自作业)' },
    { v: '5010107', l: '山、林承包商' },
    { v: '5010108', l: '农业技术指导人员' },
  ],
  50102: [
    { v: '5010201', l: '农业实验工' },
    { v: '5010202', l: '农情测报员' },
  ],
  50103: [
    { v: '5010301', l: '蔬菜园艺工' },
    { v: '5010302', l: '花卉园艺工' },
    { v: '5010303', l: '果、茶、桑园艺工' },
    { v: '5010304', l: '菌类园艺工' },
  ],
  50104: [
    { v: '5010401', l: '天然橡胶生产工' },
    { v: '5010402', l: '剑麻生产工' },
  ],
  50105: [
    { v: '5010501', l: '中药材种植员' },
    { v: '5010502', l: '中药材养殖员' },
    { v: '5010503', l: '中药材生产管理员' },
  ],
  50106: [
    { v: '5010601', l: '棉花加工工' },
    { v: '5010602', l: '果类产品加工工' },
    { v: '5010603', l: '茶叶加工工' },
    { v: '5010604', l: '蔬菜加工工' },
    { v: '5010605', l: '竹、藤、麻、棕、草制品加工工' },
    { v: '5010606', l: '特种植物原料加工工' },
    { v: '5010607', l: '糖厂技工' },
    { v: '5010608', l: '农作物种子加工' },
  ],
  50201: [
    { v: '5020101', l: '林木种苗工' },
    { v: '5020102', l: '造林更新工' },
    { v: '5020103', l: '抚育采伐工' },
    { v: '5020104', l: '营林试验工' },
    { v: '5020105', l: '领班' },
    { v: '5020106', l: '实验室育苗栽培人员' },
  ],
  50202: [
    { v: '5020201', l: '护林员' },
    { v: '5020202', l: '森林病虫害防治员' },
    { v: '5020203', l: '森林防火员' },
    { v: '5020204', l: '木材防腐师' },
  ],
  50203: [
    { v: '5020301', l: '野生动物保护员' },
    { v: '5020302', l: '野生植物保护员' },
    { v: '5020303', l: '自然保护区巡护监测员' },
    { v: '5020304', l: '动植物标本员' },
  ],
  50204: [
    { v: '5020401', l: '木材采伐工' },
    { v: '5020402', l: '集材作业工' },
    { v: '5020403', l: '木材水运工' },
    { v: '5020404', l: '装卸归楞工' },
    { v: '5020405', l: '锯木工人' },
    { v: '5020406', l: '运材车辆之司机及押运人员' },
    { v: '5020407', l: '起重机之操作人员' },
    { v: '5020408', l: '领班、监工' },
  ],
  50301: [
    { v: '5030101', l: '家畜饲养工' },
    { v: '5030102', l: '家畜繁殖工' },
    { v: '5030103', l: '畜牧场经营者(不亲自作业)' },
    { v: '5030104', l: '驯犬人员' },
    { v: '5030105', l: '畜牧工作人员' },
    { v: '5030106', l: '宠物训导师' },
    { v: '5030107', l: '有毒动物饲养工（蛇、蝎子、蜈蚣等）' },
  ],
  50302: [
    { v: '5030201', l: '家禽饲养工' },
    { v: '5030202', l: '家禽繁殖工' },
  ],
  50303: [
    { v: '5030301', l: '蜜蜂饲养工' },
    { v: '5030302', l: '蜂产品加工工' },
  ],
  50304: [{ v: '5030401', l: '实验动物饲养工' }],
  50305: [
    { v: '5030501', l: '动物疫病防治员' },
    { v: '5030502', l: '兽医化验员' },
    { v: '5030503', l: '动物检疫检验员' },
    { v: '5030504', l: '中兽医员' },
    { v: '5030505', l: '宠物健康护理人员' },
  ],
  50306: [
    { v: '5030601', l: '草地监护员' },
    { v: '5030602', l: '牧草培育、加工人员' },
    { v: '5030603', l: '草坪建植工' },
  ],
  50401: [
    { v: '5040101', l: '水生动物苗种繁育工' },
    { v: '5040102', l: '水生植物苗种培育工' },
    { v: '5040103', l: '水生动物饲养工' },
    { v: '5040104', l: '水生植物栽培工' },
    { v: '5040105', l: '珍珠养殖工(淡水)' },
    { v: '5040106', l: '珍珠养殖工(海水)' },
    { v: '5040107', l: '生物饵料培养工' },
    { v: '5040108', l: '水产养殖潜水工' },
    { v: '5040109', l: '渔场经营者(不亲自作业)' },
    { v: '5040110', l: '渔场经营者(亲自作业)' },
    { v: '5040111', l: '热带鱼养殖者、水族馆经营者' },
    { v: '5040112', l: '养殖工人(沿海)' },
    { v: '5040113', l: '水生哺乳动物训养师' },
  ],
  50402: [
    { v: '5040201', l: '水产捕捞工' },
    { v: '5040202', l: '渔业生产船员' },
    { v: '5040203', l: '水生动植物采集工' },
    { v: '5040204', l: '渔网具装配工' },
    { v: '5040205', l: '捕鱼人(沿海)' },
  ],
  50403: [
    { v: '5040301', l: '水产品原料处理工' },
    { v: '5040302', l: '水产品腌熏烤制工' },
    { v: '5040303', l: '鱼糜及鱼糜制品加工工' },
    { v: '5040304', l: '鱼粉加工工' },
    { v: '5040305', l: '鱼肝油及制品加工工' },
    { v: '5040306', l: '海藻制碘工' },
    { v: '5040307', l: '海藻制醇工' },
    { v: '5040308', l: '海藻制胶工' },
    { v: '5040309', l: '海藻食品加工工' },
    { v: '5040310', l: '贝类净化工' },
  ],
  50499: [
    { v: '5049901', l: '水产实验人员(室内)' },
    { v: '5049902', l: '水产养殖质量管理员' },
    { v: '5049903', l: '水产品质量检验员' },
  ],
  50501: [
    { v: '5050101', l: '河道修防工(堤、坝施工人员)' },
    { v: '5050102', l: '草皮、防浪林种植养护人员' },
    { v: '5050103', l: '河道、堤防巡护人员' },
    { v: '5050104', l: '混凝土工程维修工' },
    { v: '5050105', l: '土石工程维修工' },
    { v: '5050106', l: '水工检测工' },
    { v: '5050107', l: '建(构)筑物有害动物防治工' },
    { v: '5050108', l: '水坝水库管理人员' },
    { v: '5050109', l: '水域环境养护保洁员' },
  ],
  50502: [
    { v: '5050201', l: '灌排工程施工、运行、管护人员' },
    { v: '5050202', l: '渠道维护工' },
    { v: '5050203', l: '灌区供水工' },
    { v: '5050204', l: '灌溉试验工' },
  ],
  50503: [
    { v: '5050301', l: '水土保持防治工' },
    { v: '5050302', l: '水土保持测试工' },
    { v: '5050303', l: '水土保持勘测工' },
  ],
  50504: [
    { v: '5050401', l: '水文勘测工' },
    { v: '5050402', l: '水文勘测船工' },
  ],
  50599: [{ v: '5059901', l: '水利工程设施人员' }],
  59901: [
    { v: '5990101', l: '拖拉机驾驶员' },
    { v: '5990102', l: '联合收割机驾驶员' },
    { v: '5990103', l: '农用运输车驾驶员' },
    { v: '5990104', l: '农业机械操作或修理人员' },
  ],
  59902: [
    { v: '5990201', l: '沼气工程施工人员' },
    { v: '5990202', l: '沼气设备安装、调试、检修人员' },
    { v: '5990203', l: '沼气生产管理人员' },
    { v: '5990204', l: '农村节能员(灶、坑、炉、窑建造改造人员)' },
    { v: '5990205', l: '农用太阳能设施工' },
    { v: '5990206', l: '生物质能设备工' },
  ],
  60101: [
    { v: '6010101', l: '钻探工' },
    { v: '6010102', l: '坑探工' },
    { v: '6010103', l: '物探工' },
    { v: '6010104', l: '地震物探爆炸工' },
    { v: '6010105', l: '采样工' },
    {
      v: '6010106',
      l: '水文、矿山地质工(从事采送水样、矿样和观测获取数据的人员)',
    },
    { v: '6010107', l: '海洋地质取样工' },
    { v: '6010108', l: '海洋土质试验工' },
    { v: '6010109', l: '固体岩矿样碎样工' },
    { v: '6010110', l: '岩矿和古生物样品磨片工' },
    { v: '6010111', l: '重沙样品淘洗工' },
    { v: '6010112', l: '劈岩与保管工' },
    { v: '6010113', l: '地质探测员' },
  ],
  60102: [
    { v: '6010201', l: '大地测量工' },
    { v: '6010202', l: '摄影测量工' },
    { v: '6010203', l: '地图制图工' },
    { v: '6010204', l: '工程测量工' },
    { v: '6010205', l: '地籍测绘工' },
  ],
  60103: [
    { v: '6010301', l: '露天采矿挖掘机司机' },
    { v: '6010302', l: '钻孔机司机' },
    { v: '6010303', l: '井筒冻结工' },
    { v: '6010304', l: '矿井开掘工' },
    { v: '6010305', l: '井下采矿工' },
    { v: '6010306', l: '支护工' },
    { v: '6010307', l: '矿山提升机、架空索道及绞车等操作工' },
    { v: '6010308', l: '矿井机车运输工' },
    { v: '6010309', l: '矿井通风工' },
    { v: '6010310', l: '矿山安全监测工' },
    { v: '6010311', l: '矿山检查验收工' },
    { v: '6010312', l: '矿灯、自救器管理工' },
    { v: '6010313', l: '火药、雷管等火工品管理工' },
    { v: '6010314', l: '矿山井下抢险、救灾人员' },
    { v: '6010315', l: '矿物开采辅助工' },
    { v: '6010316', l: '经营者(不到现场)' },
    { v: '6010317', l: '经营者(现场监督者)' },
    { v: '6010318', l: '经理人员' },
    { v: '6010319', l: '海上所有作业人员(潜水人员拒保)' },
  ],
  60104: [
    { v: '6010401', l: '筛选破碎工' },
    { v: '6010402', l: '重力选矿工' },
    { v: '6010403', l: '浮选工' },
    { v: '6010404', l: '磁选工' },
    { v: '6010405', l: '选矿脱水工' },
    { v: '6010406', l: '尾矿处理工' },
    { v: '6010407', l: '磨矿工' },
    { v: '6010408', l: '水煤浆制备工' },
    { v: '6010409', l: '动力配煤工' },
    { v: '6010410', l: '工业型煤加工制作人员' },
  ],
  60105: [
    { v: '6010501', l: '钻井设备、井架安装工' },
    { v: '6010502', l: '钻井工' },
    { v: '6010503', l: '固井工' },
    { v: '6010504', l: '平台水手' },
    { v: '6010505', l: '水下设备操作工' },
    { v: '6010506', l: '钻勘设备安装换修保养工人' },
  ],
  60106: [
    { v: '6010601', l: '油、气井测试工' },
    { v: '6010602', l: '采油工' },
    { v: '6010603', l: '采气工' },
    { v: '6010604', l: '井下作业工' },
    { v: '6010605', l: '天然气净化工' },
    { v: '6010606', l: '油气输送工' },
    { v: '6010607', l: '油气管道保护工' },
    { v: '6010608', l: '行政人员' },
    { v: '6010609', l: '技术员' },
  ],
  60107: [
    { v: '6010701', l: '海盐晒制工' },
    { v: '6010702', l: '海盐采收工' },
    { v: '6010703', l: '湖盐采掘船工' },
    { v: '6010704', l: '湖盐采掘爆破工' },
    { v: '6010705', l: '湖盐脱水工' },
    { v: '6010706', l: '驳筑、集拆坨盐工' },
    { v: '6010707', l: '井矿盐采卤工' },
    { v: '6010708', l: '井矿盐卤水净化工' },
    { v: '6010709', l: '真空制盐工' },
    { v: '6010710', l: '冷冻提硝工' },
    { v: '6010711', l: '苦卤综合利用工' },
    { v: '6010712', l: '精制盐工' },
  ],
  60199: [
    { v: '6019901', l: '采石业工人' },
    { v: '6019902', l: '采砂业工人' },
  ],
  60201: [
    { v: '6020101', l: '烧结球团原料工' },
    { v: '6020102', l: '烧结工' },
    { v: '6020103', l: '球团焙烧工' },
    { v: '6020104', l: '烧结成品工' },
    { v: '6020105', l: '高炉原料工' },
    { v: '6020106', l: '高炉炉前工' },
    { v: '6020107', l: '高炉运转工' },
    { v: '6020201', l: '炼钢原料工' },
    { v: '6020202', l: '平炉炼钢工' },
    { v: '6020203', l: '转炉炼钢工' },
    { v: '6020204', l: '电炉炼钢工' },
    { v: '6020205', l: '炼钢浇铸工' },
    { v: '6020206', l: '炼钢准备工' },
    { v: '6020207', l: '整脱模工' },
    { v: '6020208', l: '技师' },
    { v: '6020209', l: '工程师' },
    { v: '6020210', l: '领班、监工' },
  ],
  60203: [
    { v: '6020301', l: '铁合金原料工' },
    { v: '6020302', l: '铁合金电炉冶炼工' },
    { v: '6020303', l: '铁合金焙烧工' },
    { v: '6020304', l: '铁合金湿法冶炼工' },
    { v: '6020305', l: '铁合金炉外法冶炼工' },
  ],
  60204: [
    { v: '6020401', l: '重冶备料工' },
    { v: '6020402', l: '焙烧工' },
    { v: '6020403', l: '火法冶炼工' },
    { v: '6020404', l: '湿法冶炼工' },
    { v: '6020405', l: '电解精炼工' },
    { v: '6020406', l: '烟气制酸工' },
  ],
  60205: [
    { v: '6020501', l: '氧化铝制取工' },
    { v: '6020502', l: '铝电解工' },
    { v: '6020503', l: '镁冶炼工' },
    { v: '6020504', l: '硅冶炼工' },
  ],
  60206: [
    { v: '6020601', l: '钨钼冶炼工' },
    { v: '6020602', l: '钽铌冶炼工' },
    { v: '6020603', l: '钛冶炼工' },
    { v: '6020604', l: '稀土冶炼工' },
    { v: '6020605', l: '贵金属冶炼工' },
    { v: '6020606', l: '锂冶炼工' },
  ],
  60207: [
    { v: '6020701', l: '半导体原料制备工' },
    { v: '6020702', l: '多晶制取工' },
  ],
  60208: [
    { v: '6020801', l: '轧制原料工' },
    { v: '6020802', l: '金属轧制工' },
    { v: '6020803', l: '酸洗工' },
    { v: '6020804', l: '金属材涂层工' },
    { v: '6020805', l: '金属材热处理工' },
    { v: '6020806', l: '焊管工' },
    { v: '6020807', l: '精整工' },
    { v: '6020808', l: '金属材丝拉拔工' },
    { v: '6020809', l: '金属挤压工' },
    { v: '6020810', l: '铸轧工' },
    { v: '6020811', l: '钢丝绳制造工' },
  ],
  60209: [
    { v: '6020901', l: '铸管备品工' },
    { v: '6020902', l: '铸管工' },
    { v: '6020903', l: '铸管精整工' },
  ],
  60210: [
    { v: '6021001', l: '碳素煅烧工' },
    { v: '6021002', l: '碳素成型工' },
    { v: '6021003', l: '碳素焙烧工' },
    { v: '6021004', l: '碳素浸渍工' },
    { v: '6021005', l: '碳素石墨化工' },
    { v: '6021006', l: '碳素石墨加工工' },
    { v: '6021007', l: '碳素纤维工' },
  ],
  60211: [
    { v: '6021101', l: '硬质合金混合料制备工' },
    { v: '6021102', l: '硬质合金成型工' },
    { v: '6021103', l: '硬质合金烧结工' },
    { v: '6021104', l: '硬质合金精加工工' },
  ],
  60299: [{ v: '6029901', l: '冶炼风机工' }],
  60301: [
    { v: '6030101', l: '化工原料准备工' },
    { v: '6030102', l: '压缩机工' },
    { v: '6030103', l: '气体净化工' },
    { v: '6030104', l: '过滤工' },
    { v: '6030105', l: '油加热工' },
    { v: '6030106', l: '制冷工' },
    { v: '6030107', l: '蒸发工' },
    { v: '6030108', l: '蒸馏工' },
    { v: '6030109', l: '萃取工' },
    { v: '6030110', l: '吸收工' },
    { v: '6030111', l: '吸附工' },
    { v: '6030112', l: '干燥工' },
    { v: '6030113', l: '结晶工' },
    { v: '6030114', l: '造粒工' },
    { v: '6030115', l: '防腐蚀工' },
    { v: '6030116', l: '化工工艺试验工' },
    { v: '6030117', l: '化工总控工' },
  ],
  60302: [
    { v: '6030201', l: '燃料油生产工' },
    { v: '6030202', l: '润滑油、脂生产工' },
    { v: '6030203', l: '石油产品精制工' },
    { v: '6030204', l: '油制气工' },
  ],
  60303: [
    { v: '6030301', l: '备煤筛焦工' },
    { v: '6030302', l: '焦炉调温工' },
    { v: '6030303', l: '炼焦工、焦炉机车司机' },
    { v: '6030304', l: '煤制气工' },
    { v: '6030305', l: '煤气储运工' },
  ],
  60304: [
    { v: '6030401', l: '合成氨生产工' },
    { v: '6030402', l: '尿素生产工' },
    { v: '6030403', l: '硝酸铵生产工' },
    { v: '6030404', l: '碳酸氢铵生产工' },
    { v: '6030405', l: '硫酸铵生产工' },
    { v: '6030406', l: '过磷酸钙生产工' },
    { v: '6030407', l: '复合磷肥生产工' },
    { v: '6030408', l: '钙镁磷肥生产工' },
    { v: '6030409', l: '氯化钾生产工' },
    { v: '6030410', l: '微量元素混肥生产工' },
  ],
  60305: [
    { v: '6030501', l: '硫酸生产工' },
    { v: '6030502', l: '硝酸生产工' },
    { v: '6030503', l: '盐酸生产工' },
    { v: '6030504', l: '磷酸生产工' },
    { v: '6030505', l: '纯碱生产工' },
    { v: '6030506', l: '烧碱生产工' },
    { v: '6030507', l: '氟化盐生产工' },
    { v: '6030508', l: '缩聚磷酸盐生产工' },
    { v: '6030509', l: '无机化学反应工' },
    { v: '6030510', l: '高频等离子工' },
    { v: '6030511', l: '气体深冷分离工、制氧工' },
    { v: '6030512', l: '工业气体液化工' },
    { v: '6030513', l: '炭黑制造工' },
    { v: '6030514', l: '二硫化碳制造工' },
    { v: '6030515', l: '工程师' },
    { v: '6030516', l: '技师' },
    { v: '6030517', l: '一般工人' },
  ],
  60306: [
    { v: '6030601', l: '脂肪烃生产工' },
    { v: '6030602', l: '环烃生产工' },
    { v: '6030603', l: '烃类衍生物生产工' },
  ],
  60307: [
    { v: '6030701', l: '聚乙烯生产工' },
    { v: '6030702', l: '聚丙烯生产工' },
    { v: '6030703', l: '聚苯乙烯生产工' },
    { v: '6030704', l: '聚丁二烯生产工' },
    { v: '6030705', l: '聚氯乙烯生产工' },
    { v: '6030706', l: '酚醛树脂生产工' },
    { v: '6030707', l: '环氧树脂生产工' },
    { v: '6030708', l: '丙烯腈-丁二烯-苯乙烯共聚物(ABS)' },
  ],
  60308: [
    { v: '6030801', l: '顺丁橡胶生产工' },
    { v: '6030802', l: '乙丙橡胶生产工' },
    { v: '6030803', l: '异戊橡胶生产工' },
    { v: '6030804', l: '丁腈橡胶生产工' },
    { v: '6030805', l: '丁苯橡胶生产工' },
    { v: '6030806', l: '氯丁橡胶生产工' },
  ],
  60309: [
    { v: '6030901', l: '化纤聚合工' },
    { v: '6030902', l: '湿纺原液制造工' },
    { v: '6030903', l: '纺丝工' },
    { v: '6030904', l: '化纤后处理工' },
    { v: '6030905', l: '纺丝凝固浴液配制工' },
    { v: '6030906', l: '无纺布制造工' },
    { v: '6030907', l: '化纤纺丝精密组件工' },
  ],
  60310: [{ v: '6031001', l: '合成革制造工' }],
  60311: [
    { v: '6031101', l: '有机合成工' },
    { v: '6031102', l: '农药生物测试试验工' },
    { v: '6031103', l: '染料标准工' },
    { v: '6031104', l: '染料应用试验工' },
    { v: '6031105', l: '染料拼混工' },
    { v: '6031106', l: '研磨分散工' },
    { v: '6031107', l: '催化剂制造工' },
    { v: '6031108', l: '催化剂试验工' },
    { v: '6031109', l: '涂料合成树脂工' },
    { v: '6031110', l: '制漆配色调制工' },
    { v: '6031111', l: '溶剂制造工' },
    { v: '6031112', l: '化学试剂制造工' },
    { v: '6031113', l: '化工添加剂制造工' },
  ],
  60312: [
    { v: '6031201', l: '片基制造工' },
    { v: '6031202', l: '感光材料制造工' },
    { v: '6031203', l: '感光材料试验工' },
    { v: '6031204', l: '暗盒制造工' },
    { v: '6031205', l: '废片、白银回收工' },
    { v: '6031206', l: '磁粉制造工' },
    { v: '6031207', l: '磁记录材料制造工' },
    { v: '6031208', l: '磁记录材料试验工' },
    { v: '6031209', l: '感光鼓涂敷工' },
  ],
  60313: [
    { v: '6031301', l: '单基火药制造工' },
    { v: '6031302', l: '双基火药制造工' },
    { v: '6031303', l: '多基火药制造工' },
    { v: '6031304', l: '黑火药制造工' },
    { v: '6031305', l: '混合火药制造工' },
  ],
  60314: [
    { v: '6031401', l: '单质炸药制造工' },
    { v: '6031402', l: '混合炸药制造工' },
    { v: '6031403', l: '起爆药制造工' },
    { v: '6031404', l: '含水炸药制造工' },
    { v: '6031405', l: '火药爆竹制造及处理人(包括爆竹、烟火制造工)' },
  ],
  60315: [
    { v: '6031501', l: '松香工' },
    { v: '6031502', l: '松节油制品工' },
    { v: '6031503', l: '活性炭生产工' },
    { v: '6031504', l: '栲胶生产工' },
    { v: '6031505', l: '紫胶生产工' },
    { v: '6031506', l: '栓皮制品工' },
    { v: '6031507', l: '木材水解工' },
  ],
  60316: [
    { v: '6031601', l: '树脂基复合材料工' },
    { v: '6031602', l: '橡胶基复合材料工' },
    { v: '6031603', l: '碳基复合材料工' },
    { v: '6031604', l: '陶瓷基复合材料工' },
    { v: '6031605', l: '复合固体推进剂成型工' },
    { v: '6031606', l: '复合固体发动机装药工' },
    { v: '6031607', l: '飞机复合材料制品工' },
  ],
  60317: [
    { v: '6031701', l: '制皂工' },
    { v: '6031702', l: '甘油工' },
    { v: '6031703', l: '脂肪酸工' },
    { v: '6031704', l: '洗衣粉成型工' },
    { v: '6031705', l: '合成洗涤剂制造工' },
    { v: '6031706', l: '香料制造工' },
    { v: '6031707', l: '香精制造工' },
    { v: '6031708', l: '化妆品配制工' },
    { v: '6031709', l: '牙膏制造工' },
    { v: '6031710', l: '油墨制造工' },
    { v: '6031711', l: '制胶工' },
    { v: '6031712', l: '火柴制造工' },
    { v: '6031713', l: '蜡烛制造工' },
  ],
  60399: [{ v: '6039901', l: '电子绝缘与介质材料制造工' }],
  60401: [
    { v: '6040101', l: '车工' },
    { v: '6040102', l: '铣工' },
    { v: '6040103', l: '刨工' },
    { v: '6040104', l: '磨工' },
    { v: '6040105', l: '镗工' },
    { v: '6040106', l: '钻床工' },
    { v: '6040107', l: '组合机床操作工' },
    { v: '6040108', l: '加工中心操作工' },
    { v: '6040109', l: '制齿工' },
    { v: '6040110', l: '螺丝纹挤形工' },
    { v: '6040111', l: '抛磨光工' },
    { v: '6040112', l: '拉床工' },
    { v: '6040113', l: '锯床工' },
    { v: '6040114', l: '刃具扭制工' },
    { v: '6040115', l: '弹性元件制造工' },
    { v: '6040116', l: '技师' },
    { v: '6040117', l: '领班、监工' },
    { v: '6040118', l: '技工' },
    { v: '6040119', l: '车床工(全自动)' },
  ],
  60402: [
    { v: '6040201', l: '铸造工' },
    { v: '6040202', l: '锻造工' },
    { v: '6040203', l: '冲压工' },
    { v: '6040204', l: '剪切工' },
    { v: '6040205', l: '焊工' },
    { v: '6040206', l: '金属热处理工' },
    { v: '6040207', l: '粉末冶金制造工' },
  ],
  60403: [{ v: '6040301', l: '电切削工' }],
  60404: [{ v: '6040401', l: '冷作钣金工' }],
  60405: [
    { v: '6040501', l: '镀层工' },
    { v: '6040502', l: '涂装工' },
  ],
  60406: [
    { v: '6040601', l: '磨料制造工' },
    { v: '6040602', l: '磨具制造工' },
  ],
  60407: [
    { v: '6040701', l: '金属软管、波纹管工' },
    { v: '6040702', l: '卫星光学冷加工工' },
    { v: '6040703', l: '航天器件高温处理工' },
  ],
  60499: [
    { v: '6049901', l: '电焊条制造工' },
    { v: '6049902', l: '仪器仪表元件制造工' },
    { v: '6049903', l: '真空干燥处理工' },
    { v: '6049904', l: '人造宝石制造工' },
    { v: '6049905', l: '装配工、品管人员' },
  ],
  60501: [
    { v: '6050101', l: '基础件装配工' },
    { v: '6050102', l: '部件装配工' },
  ],
  60502: [
    { v: '6050201', l: '装配钳工' },
    { v: '6050202', l: '工具钳工' },
  ],
  60503: [
    { v: '6050301', l: '汽轮机装配工' },
    { v: '6050302', l: '内燃机装配工' },
    { v: '6050303', l: '锅炉设备装配厂' },
    { v: '6050304', l: '电机装配工' },
    { v: '6050305', l: '有关高压电之工作人员' },
    { v: '6050306', l: '数控机床装调维修工' },
  ],
  60504: [
    { v: '6050401', l: '铁心叠装工' },
    { v: '6050402', l: '绝缘制品件装配工' },
    { v: '6050403', l: '线圈绕制工' },
    { v: '6050404', l: '绝缘处理浸渍工' },
    { v: '6050405', l: '变压器、互感器装配工' },
    { v: '6050406', l: '高低压电器装配工' },
    { v: '6050407', l: '电焊机装配工' },
    { v: '6050408', l: '电炉装配工' },
    { v: '6050409', l: '电线电缆制造工' },
    { v: '6050410', l: '技师' },
  ],
  60505: [
    { v: '6050501', l: '电子专用设备装调工' },
    { v: '6050502', l: '真空测试工' },
  ],
  60506: [
    { v: '6050601', l: '仪器仪表元器件装调工' },
    { v: '6050602', l: '力学仪器仪表装配工' },
    { v: '6050603', l: '电子仪器仪表装配工' },
    { v: '6050604', l: '光电仪器仪表装调工' },
    { v: '6050605', l: '分析仪器仪表装配工' },
    { v: '6050606', l: '计时仪器仪表装配工' },
    { v: '6050607', l: '工业自动化仪器仪表与装置装配工' },
    { v: '6050608', l: '电工仪器仪表装配工' },
  ],
  60507: [
    { v: '6050701', l: '汽车(拖拉机)装配工' },
    { v: '6050702', l: '铁路机车机械制修工' },
    { v: '6050703', l: '铁路车辆机械制修工' },
    { v: '6050704', l: '铁路机车电气装修工' },
    { v: '6050705', l: '铁路车辆电气装修工' },
    { v: '6050706', l: '铁路机车车辆制动修造工' },
    { v: '6050707', l: '电机车装配工' },
    { v: '6050708', l: '摩托车装配工' },
    { v: '6050709', l: '助动车、自行车装配工' },
    { v: '6050710', l: '工程师' },
    { v: '6050711', l: '技师' },
    { v: '6050712', l: '领班、监工' },
    { v: '6050713', l: '试车人员' },
    { v: '6050714', l: '汽车模型工' },
    { v: '6050715', l: '城轨接触网检修工' },
  ],
  60508: [
    { v: '6050801', l: '功能膜工' },
    { v: '6050802', l: '电渗析器工' },
  ],
  60509: [
    { v: '6050901', l: '医疗器械装配工' },
    { v: '6050902', l: '假肢制作装配工' },
    { v: '6050903', l: '矫形器制作装配工' },
  ],
  60510: [
    { v: '6051001', l: '空调机装配工' },
    { v: '6051002', l: '电冰箱、电冰柜制造装配工' },
    { v: '6051003', l: '洗衣机制造装配工' },
    { v: '6051004', l: '小型家用电器装配工' },
    { v: '6051005', l: '缝纫机制造装配工' },
    { v: '6051006', l: '办公小机械制造装配工' },
    { v: '6051007', l: '技师' },
    { v: '6051008', l: '一般制造工人' },
    { v: '6051009', l: '包装工' },
    { v: '6051010', l: '瓦斯器具制造工' },
  ],
  60511: [
    { v: '6051101', l: '工具五金制作工' },
    { v: '6051102', l: '建筑五金制品制作工' },
    { v: '6051103', l: '制锁工' },
    { v: '6051104', l: '铝制品制作工' },
    { v: '6051105', l: '日用五金制品制作工' },
    { v: '6051106', l: '金属家具制造工人' },
    { v: '6051107', l: '金属家具修理工人' },
  ],
  60512: [
    { v: '6051201', l: '装甲车辆装配工' },
    { v: '6051202', l: '装甲车辆装配检验工' },
    { v: '6051203', l: '装甲车辆驾驶试验工' },
    { v: '6051204', l: '装甲车辆发动机装试工' },
  ],
  60513: [
    { v: '6051301', l: '火炮装试工' },
    { v: '6051302', l: '火炮装试检验工' },
    { v: '6051303', l: '火炮随动系统装试工' },
    { v: '6051304', l: '火炮随动系统装试检验工' },
    { v: '6051305', l: '火炮膛线制作工' },
    { v: '6051306', l: '枪支装配工' },
    { v: '6051307', l: '枪管校直工' },
    { v: '6051308', l: '枪管膛线制作工' },
  ],
  60514: [
    { v: '6051401', l: '炮弹装配工' },
    { v: '6051402', l: '枪弹装配工' },
    { v: '6051403', l: '火工装药工' },
  ],
  60515: [{ v: '6051501', l: '引信装试工' }],
  60516: [
    { v: '6051601', l: '雷管制造工' },
    { v: '6051602', l: '索状爆破器材制造工' },
    { v: '6051603', l: '火工品装配工' },
    { v: '6051604', l: '爆破器材试验工' },
  ],
  60517: [
    { v: '6051701', l: '滤毒材料制造工' },
    { v: '6051702', l: '防毒器材装配工' },
    { v: '6051703', l: '防毒器材试验工' },
  ],
  60518: [
    { v: '6051801', l: '船体制造工' },
    { v: '6051802', l: '船舶轮机装配工' },
    { v: '6051803', l: '船舶电气装配工' },
    { v: '6051804', l: '船舶附件制造工' },
    { v: '6051805', l: '船舶木塑帆缆工' },
    { v: '6051806', l: '船模制造试验工' },
    { v: '6051807', l: '工程师' },
    { v: '6051808', l: '领班' },
    { v: '6051809', l: '拆船工人' },
  ],
  60519: [
    { v: '6051901', l: '飞机装配工' },
    { v: '6051902', l: '飞机系统安装调试工' },
    { v: '6051903', l: '机载导弹装配工' },
    { v: '6051904', l: '航空发动机装配工' },
    { v: '6051905', l: '飞机螺旋桨装配工' },
    { v: '6051906', l: '飞机军械安装调试工' },
    { v: '6051907', l: '航空电气安装调试工' },
    { v: '6051908', l: '飞机发动机附件装配工' },
    { v: '6051909', l: '航空仪表装配工' },
    { v: '6051910', l: '飞机仪表安装试验工' },
    { v: '6051911', l: '航空装配平衡工' },
    { v: '6051912', l: '飞机无线电设备安装调试工' },
    { v: '6051913', l: '飞机雷达安装调试工' },
    { v: '6051914', l: '飞机特种设备检测与修理工' },
    { v: '6051915', l: '飞机透明件制造胶接装配工' },
    { v: '6051916', l: '飞机外场调试与维护工' },
  ],
  60520: [
    { v: '6052001', l: '飞机试验工' },
    { v: '6052002', l: '机载导弹例行试验工' },
    { v: '6052003', l: '航空发动机试车工' },
    { v: '6052004', l: '飞机螺旋桨试验工' },
    { v: '6052005', l: '飞机、发动机附件试验工' },
    { v: '6052006', l: '航空环控救生装备试验工(地面试验)' },
    { v: '6052007', l: '航空环控救生装备试验工(空中试验)' },
    { v: '6052008', l: '航空仪表试验工' },
    { v: '6052009', l: '航空电机电器试验设备调试工' },
  ],
  60521: [
    { v: '6052101', l: '惯性器件装配厂' },
    { v: '6052102', l: '伺服机构装配调试工' },
    { v: '6052103', l: '导弹部段装配工' },
    { v: '6052104', l: '航天器引信装配工' },
    { v: '6052105', l: '弹头装配工' },
    { v: '6052106', l: '导弹总体装配工' },
    { v: '6052107', l: '卫星总体装配工' },
  ],
  60522: [
    { v: '6052201', l: '液体火箭发动机装配试验工' },
    { v: '6052202', l: '固体火箭发动机装配工' },
    { v: '6052203', l: '固体火箭发动机试验工' },
    { v: '6052204', l: '固体火箭发动机检测工' },
  ],
  60523: [
    { v: '6052301', l: '航天器环境试验工' },
    { v: '6052302', l: '航天器结构强度环境试验工' },
    { v: '6052303', l: '航天器结构高低温环境试验工' },
    { v: '6052304', l: '火箭发动机介质试验工' },
    { v: '6052305', l: '航天器系统试验工' },
    { v: '6052306', l: '空间环境模拟光学装测工' },
    { v: '6052307', l: '空间环境模拟温度试验工' },
    { v: '6052308', l: '空间环境模拟真空试验工' },
  ],
  60524: [
    { v: '6052401', l: '靶场试射工' },
    { v: '6052402', l: '靶场测试工' },
  ],
  60599: [
    { v: '6059901', l: '技师' },
    { v: '6059902', l: '领班、监工' },
  ],
  60601: [
    { v: '6060101', l: '机修钳工' },
    { v: '6060102', l: '汽车修理工' },
    { v: '6060103', l: '船舶修理工' },
    { v: '6060104', l: '修理保养工人(自行车)' },
    { v: '6060105', l: '装配修理工、冷冻修理厂工人' },
    { v: '6060106', l: '汽车玻璃维修工' },
    { v: '6060107', l: '工程机械修理工' },
  ],
  60602: [
    { v: '6060201', l: '工业自动化仪器仪表与装置修理工' },
    { v: '6060202', l: '电工仪器仪表修理工' },
    { v: '6060203', l: '精密仪器仪表修理工' },
  ],
  60603: [
    { v: '6060301', l: '民用航空器维护人员' },
    { v: '6060302', l: '民用航空器修理人员' },
    { v: '6060303', l: '机械员' },
  ],
  60701: [
    { v: '6070101', l: '水轮机设备安装工' },
    { v: '6070102', l: '锅炉设备安装工' },
    { v: '6070103', l: '汽轮机设备安装工' },
    { v: '6070104', l: '发电机设备安装工' },
    { v: '6070105', l: '热工仪表及控制装置安装试验工' },
    { v: '6070106', l: '发电厂电气设备安装工' },
    { v: '6070107', l: '电力电缆安装工' },
    { v: '6070108', l: '高压线路架设工' },
    { v: '6070109', l: '电力工程内线安装工' },
    { v: '6070110', l: '小风电利用工' },
    { v: '6070111', l: '微水电利用工' },
  ],
  60702: [
    { v: '6070201', l: '水轮发电机值班员' },
    { v: '6070202', l: '燃料值班员' },
    { v: '6070203', l: '锅炉运行值班员' },
    { v: '6070204', l: '锅炉辅机值班员' },
    { v: '6070205', l: '汽轮机运行值班员' },
    { v: '6070206', l: '热力网值班员' },
    { v: '6070207', l: '电气值班员' },
    { v: '6070208', l: '集控值班员' },
    { v: '6070209', l: '发电机氢冷值班员' },
    { v: '6070210', l: '电厂水处理值班员' },
  ],
  60703: [
    { v: '6070301', l: '送电、配电线路工' },
    { v: '6070302', l: '变电站值班员' },
    { v: '6070303', l: '调相机值班员' },
    { v: '6070304', l: '换流站值班员' },
  ],
  60704: [
    { v: '6070401', l: '锅炉本体设备检修工' },
    { v: '6070402', l: '锅炉附属设备检修工' },
    { v: '6070403', l: '汽轮机本体设备检修工' },
    { v: '6070404', l: '汽轮机附属设备检修工' },
    { v: '6070405', l: '发电厂电动机检修工' },
    { v: '6070406', l: '水轮机检修工' },
    { v: '6070407', l: '水电站水力机械试验工' },
    { v: '6070408', l: '水电自动装置检修工' },
    { v: '6070409', l: '高压线路带电检修工' },
    { v: '6070410', l: '变压器检修工' },
    { v: '6070411', l: '变电设备检修工' },
    { v: '6070412', l: '电气试验员' },
    { v: '6070413', l: '继电保护员' },
    { v: '6070414', l: '电力装置维护修理工' },
  ],
  60705: [
    { v: '6070501', l: '电力负荷控制员' },
    { v: '6070502', l: '用电监察员' },
    { v: '6070503', l: '装表核算收费员' },
    { v: '6070504', l: '装表接电工' },
    { v: '6070505', l: '电能计量装置检修工' },
  ],
  60706: [
    { v: '6070601', l: '变电设备安装工' },
    { v: '6070602', l: '变配电室值班电工' },
    { v: '6070603', l: '常用电机检修工' },
    { v: '6070604', l: '牵引电力线路安装维护工' },
    { v: '6070605', l: '维修电工' },
  ],
  60801: [
    { v: '6080101', l: '真空电子器件化学零件制造工' },
    { v: '6080102', l: '电极丝制造工' },
    { v: '6080103', l: '真空电子器件金属零件制造工' },
    { v: '6080104', l: '电子真空镀膜工' },
    { v: '6080105', l: '真空电子器件装配工' },
    { v: '6080106', l: '真空电子器件装调工' },
    { v: '6080107', l: '液晶显示器件制造工' },
    { v: '6080108', l: '单晶片加工工' },
    { v: '6080109', l: '半导体芯片制造工' },
    { v: '6080110', l: '半导体分立器件、集成电路装调工' },
    { v: '6080111', l: '电子用水制备工' },
    { v: '6080112', l: '技师' },
    { v: '6080113', l: '领班、监工' },
    { v: '6080114', l: '制造工' },
  ],
  60802: [
    { v: '6080201', l: '电阻器制造工' },
    { v: '6080202', l: '电容器制造工' },
    { v: '6080203', l: '微波铁氧体元器件制造工' },
    { v: '6080204', l: '石英晶体生长设备操作工' },
    { v: '6080205', l: '压电石英晶片加工工' },
    { v: '6080206', l: '石英晶体元器件制造工' },
    { v: '6080207', l: '电声器件制造工' },
    { v: '6080208', l: '水声换能器制造工' },
    { v: '6080209', l: '专用继电器制造工' },
    { v: '6080210', l: '高频电感器件制造工' },
    { v: '6080211', l: '接插件制造工' },
    { v: '6080212', l: '磁头制造工' },
    { v: '6080213', l: '电子产品制版工' },
    { v: '6080214', l: '印制电路制作工' },
    { v: '6080215', l: '薄膜加热器制造工' },
    { v: '6080216', l: '激光头制造工' },
  ],
  60803: [
    { v: '6080301', l: '铅酸蓄电池制造工' },
    { v: '6080302', l: '碱性蓄电池制造工' },
    { v: '6080303', l: '原电池制造工' },
    { v: '6080304', l: '热电池制造工' },
    { v: '6080305', l: '太阳电池制造工' },
    { v: '6080306', l: '电池制造(技师)' },
    { v: '6080307', l: '电池制造(工人)' },
  ],
  60804: [
    { v: '6080401', l: '无线电设备机械装校工' },
    { v: '6080402', l: '电子设备装接工' },
    { v: '6080403', l: '无线电调试工' },
    { v: '6080404', l: '雷达装配工' },
    { v: '6080405', l: '雷达调试工' },
    { v: '6080406', l: '电子精密机械装调工' },
    { v: '6080407', l: '电子计算机(微机)调试工' },
    { v: '6080408', l: '有线通信传输设备调试工' },
    { v: '6080409', l: '通讯交换设备调试工' },
    { v: '6080410', l: '电源调试工' },
    { v: '6080411', l: '激光机装调工' },
    { v: '6080412', l: '激光全息工' },
    { v: '6080413', l: '铁路通信组调工' },
    { v: '6080414', l: '铁路信号组调工' },
    { v: '6080415', l: '铁路电控组调工' },
    { v: '6080416', l: '包装工人' },
    { v: '6080417', l: '集成电路测试员' },
  ],
  60805: [
    { v: '6080501', l: '电子计算机(微机)维修工' },
    { v: '6080502', l: '修理工' },
    { v: '6080503', l: '家电用品维修人员' },
  ],
  60901: [
    { v: '6090101', l: '橡胶制品配料工' },
    { v: '6090102', l: '橡胶炼胶工' },
    { v: '6090103', l: '橡胶半成品制造工' },
    { v: '6090104', l: '橡胶成型工' },
    { v: '6090105', l: '橡胶硫化工' },
    { v: '6090106', l: '废胶再生工' },
    { v: '6090107', l: '轮胎翻修工' },
  ],
  60902: [
    { v: '6090201', l: '塑料制品配料工' },
    { v: '6090202', l: '塑料制品成型制作工(自动)' },
    { v: '6090203', l: '塑胶射出成型人员(其它)' },
  ],
  60999: [
    { v: '6099901', l: '工程师' },
    { v: '6099902', l: '技师' },
    { v: '6099903', l: '领班、监工' },
    { v: '6099904', l: '一般工人' },
  ],
  61001: [
    { v: '6100101', l: '纤维验配工' },
    { v: '6100102', l: '开清棉工' },
    { v: '6100103', l: '纤维染色工' },
    { v: '6100104', l: '加湿软麻工' },
    { v: '6100105', l: '选剥煮茧工' },
    { v: '6100106', l: '纤维梳理工' },
    { v: '6100107', l: '并条工' },
    { v: '6100108', l: '粗纱工' },
    { v: '6100109', l: '绢纺精炼工' },
  ],
  61002: [
    { v: '6100201', l: '细纱工' },
    { v: '6100202', l: '简并摇工' },
    { v: '6100203', l: '捻线工' },
    { v: '6100204', l: '制线工' },
    { v: '6100205', l: '缫丝工' },
  ],
  61003: [
    { v: '6100301', l: '整经工' },
    { v: '6100302', l: '浆纱工' },
    { v: '6100303', l: '穿经工' },
    { v: '6100304', l: '织布工' },
    { v: '6100305', l: '织物验修工' },
    { v: '6100306', l: '意匠纹版工' },
    { v: '6100307', l: '织造工人' },
  ],
  61004: [
    { v: '6100401', l: '纬编工' },
    { v: '6100402', l: '经编工' },
    { v: '6100403', l: '横机工' },
    { v: '6100404', l: '织袜工' },
    { v: '6100405', l: '铸、钳针工' },
  ],
  61005: [
    { v: '6100501', l: '坯布检查处理工' },
    { v: '6100502', l: '印染烧毛工' },
    { v: '6100503', l: '煮炼漂工' },
    { v: '6100504', l: '印染洗涤工' },
    { v: '6100505', l: '印染烘干工' },
    { v: '6100506', l: '印染丝光工' },
    { v: '6100507', l: '印染定型工' },
    { v: '6100508', l: '纺织针织染色工' },
    { v: '6100509', l: '印花工' },
    { v: '6100510', l: '印染雕刻制版工' },
    { v: '6100511', l: '印染后整理工' },
    { v: '6100512', l: '印染成品定等装潢工' },
    { v: '6100513', l: '印染染化料配制工' },
    { v: '6100514', l: '工艺染织制作工' },
    { v: '6100515', l: '染整工人' },
  ],
  61099: [{ v: '6109901', l: '技师' }],
  61101: [
    { v: '6110101', l: '裁剪工' },
    { v: '6110102', l: '缝纫工' },
    { v: '6110103', l: '缝纫品整型工' },
    { v: '6110104', l: '裁缝' },
    { v: '6110105', l: '剧装工' },
    { v: '6110106', l: '设计师' },
  ],
  61102: [
    { v: '6110201', l: '制鞋工' },
    { v: '6110202', l: '制帽工' },
  ],
  61103: [
    { v: '6110301', l: '皮革加工工' },
    { v: '6110302', l: '毛皮加工工' },
  ],
  61104: [
    { v: '6110401', l: '缝纫制品充填处理工' },
    { v: '6110402', l: '胶制服装上胶工' },
    { v: '6110403', l: '服装水洗工' },
  ],
  61201: [
    { v: '6120101', l: '制米工' },
    { v: '6120102', l: '制粉工' },
    { v: '6120103', l: '制油工' },
  ],
  61202: [
    { v: '6120201', l: '食糖制造工' },
    { v: '6120202', l: '糖果制造工' },
    { v: '6120203', l: '巧克力制造工' },
  ],
  61203: [
    { v: '6120301', l: '乳品预处理工' },
    { v: '6120302', l: '乳品加工工' },
    { v: '6120303', l: '冷食品制作工' },
    { v: '6120304', l: '速冻食品制作工' },
    { v: '6120305', l: '食品罐头加工工' },
    { v: '6120306', l: '饮料制作工' },
  ],
  61204: [
    { v: '6120401', l: '白酒酿造工' },
    { v: '6120402', l: '啤酒酿造工' },
    { v: '6120403', l: '黄酒酿造工' },
    { v: '6120404', l: '果露酒酿造工' },
    { v: '6120405', l: '洒精制造工' },
  ],
  61205: [
    { v: '6120501', l: '酶制剂制造工' },
    { v: '6120502', l: '柠檬酸制造工' },
    { v: '6120503', l: '酱油酱类制作工' },
    { v: '6120504', l: '食醋制作工' },
    { v: '6120505', l: '酱腌菜制作工' },
    { v: '6120506', l: '食用调料制作工' },
    { v: '6120507', l: '味精制作工' },
  ],
  61206: [
    { v: '6120601', l: '糕点、面包烘焙工' },
    { v: '6120602', l: '糕点装饰工' },
    { v: '6120603', l: '米面主食制作工' },
    { v: '6120604', l: '油脂制品工' },
    { v: '6120605', l: '植物蛋白制作工' },
    { v: '6120606', l: '豆制品制作工' },
  ],
  61207: [
    { v: '6120701', l: '猪屠宰加工工' },
    { v: '6120702', l: '牛羊屠宰加工工' },
    { v: '6120703', l: '肠衣工' },
    { v: '6120704', l: '禽类屠宰加工工' },
  ],
  61208: [
    { v: '6120801', l: '熟肉制品加工工' },
    { v: '6120802', l: '蛋品及再制蛋品加工工' },
  ],
  61209: [
    { v: '6120901', l: '饲料原料清理上料工' },
    { v: '6120902', l: '饲料粉碎工' },
    { v: '6120903', l: '饲料配料混合工' },
    { v: '6120904', l: '饲料制粒工' },
    { v: '6120905', l: '饲料添加剂预混工' },
    { v: '6120906', l: '饲料厂中央控制室操作工' },
  ],
  61299: [
    { v: '6129901', l: '制造工人' },
    { v: '6129902', l: '冰块制造工人' },
    { v: '6129903', l: '技师' },
    { v: '6129904', l: '领班、监工' },
    { v: '6129905', l: '装罐工人' },
  ],
  61301: [
    { v: '6130101', l: '烟叶调制工' },
    { v: '6130102', l: '烟叶分级工' },
    { v: '6130103', l: '挂杆复烤工' },
    { v: '6130104', l: '打叶复烤工' },
    { v: '6130105', l: '烟叶回潮工' },
    { v: '6130106', l: '烟叶发酵工' },
  ],
  61302: [
    { v: '6130201', l: '烟叶制丝工' },
    { v: '6130202', l: '膨胀烟丝工' },
    { v: '6130203', l: '白肋烟处理工' },
    { v: '6130204', l: '烟草薄片工' },
    { v: '6130205', l: '卷烟卷接工' },
  ],
  61303: [
    { v: '6130301', l: '烟用二醋片制造工' },
    { v: '6130302', l: '烟用丝束制造工' },
    { v: '6130303', l: '滤棒工' },
  ],
  61401: [{ v: '6140101', l: '化学合成制药工' }],
  61402: [
    { v: '6140201', l: '生化药品制造工' },
    { v: '6140202', l: '发酵工程制药工' },
    { v: '6140203', l: '疫苗制品工' },
    { v: '6140204', l: '血液制品工' },
    { v: '6140205', l: '基因工程产品工' },
  ],
  61403: [
    { v: '6140301', l: '药物制剂工' },
    { v: '6140302', l: '淀粉葡萄糖制造工' },
  ],
  61404: [
    { v: '6140401', l: '中药炮制与配制工' },
    { v: '6140402', l: '中药液体制剂工' },
    { v: '6140403', l: '中药固体制剂工' },
  ],
  61501: [
    { v: '6150101', l: '锯木工人' },
    { v: '6150102', l: '木材干燥工' },
    { v: '6150103', l: '木材工厂现场之职员' },
    { v: '6150104', l: '领班' },
    { v: '6150105', l: '分级员' },
    { v: '6150106', l: '检查员' },
    { v: '6150107', l: '标记员' },
    { v: '6150108', l: '磅秤员' },
    { v: '6150109', l: '木材供应站管理人员' },
    { v: '6150110', l: '木材供应站营业员' },
    { v: '6150111', l: '仓库管理员' },
    { v: '6150112', l: '防腐剂工人' },
    { v: '6150113', l: '木材储藏槽工人' },
    { v: '6150114', l: '木材搬运工人' },
    { v: '6150115', l: '吊车操作人员' },
  ],
  61502: [
    { v: '6150201', l: '胶合板工' },
    { v: '6150202', l: '纤维板工' },
    { v: '6150203', l: '刨花板工' },
    { v: '6150204', l: '人造板制胶工' },
    { v: '6150205', l: '装饰层压板工' },
    { v: '6150206', l: '人造板饰面工' },
  ],
  61503: [
    { v: '6150301', l: '手工木工' },
    { v: '6150302', l: '机械木工' },
    { v: '6150303', l: '精细木工' },
    { v: '6150304', l: '技师' },
    { v: '6150305', l: '领班、监工' },
    { v: '6150306', l: '木制家具制造工人' },
    { v: '6150307', l: '木制家具修理工人' },
  ],
  61601: [
    { v: '6160101', l: '制浆备料工' },
    { v: '6160102', l: '制浆设备操作工' },
    { v: '6160103', l: '制浆废液回收利用工' },
  ],
  61602: [
    { v: '6160201', l: '造纸工' },
    { v: '6160202', l: '纸张整饰工' },
    { v: '6160203', l: '宣纸书画纸制作工' },
  ],
  61603: [
    { v: '6160301', l: '瓦楞纸箱制作工' },
    { v: '6160302', l: '纸盒制作工' },
  ],
  61699: [
    { v: '6169901', l: '技师' },
    { v: '6169902', l: '领班、监工' },
  ],
  61701: [
    { v: '6170101', l: '水泥生产制造工' },
    { v: '6170102', l: '水泥制品工' },
    { v: '6170103', l: '石灰焙烧工' },
    { v: '6170104', l: '工程师' },
    { v: '6170105', l: '技师' },
    { v: '6170106', l: '领班' },
    { v: '6170107', l: '一般工人' },
    { v: '6170108', l: '采掘工' },
    { v: '6170109', l: '爆破工' },
    { v: '6170110', l: '陶瓷、木炭、砖块制造工' },
  ],
  61702: [
    { v: '6170201', l: '砖、瓦生产工' },
    { v: '6170202', l: '加气混凝土制品工' },
    { v: '6170203', l: '纸面石膏板生产工' },
    { v: '6170204', l: '石膏浮雕板工' },
    { v: '6170205', l: '监工(不参与制造过程)' },
  ],
  61703: [
    { v: '6170301', l: '油毡生产工' },
    { v: '6170302', l: '高分子防水卷材生产工' },
  ],
  61704: [
    { v: '6170401', l: '保温材料制造工' },
    { v: '6170402', l: '吸音材料生产工' },
    { v: '6170403', l: '珍珠岩制造工' },
  ],
  61705: [{ v: '6170501', l: '装饰石材生产工' }],
  61706: [
    { v: '6170601', l: '云母制品加工工' },
    { v: '6170602', l: '石棉制品工' },
    { v: '6170603', l: '高岭土制品工' },
    { v: '6170604', l: '金刚石制品工' },
    { v: '6170605', l: '人工合成晶体工' },
  ],
  61707: [
    { v: '6170701', l: '耐火原料加工工' },
    { v: '6170702', l: '耐火材料成型工' },
    { v: '6170703', l: '耐火材料烧成工' },
    { v: '6170704', l: '耐火制品浸油工' },
    { v: '6170705', l: '耐火纤维制品工' },
  ],
  61801: [
    { v: '6180101', l: '玻璃配料工' },
    { v: '6180102', l: '玻璃熔化工' },
    { v: '6180103', l: '玻璃制板及玻璃成型工' },
    { v: '6180104', l: '玻璃加工工' },
    { v: '6180105', l: '玻璃制品装饰加工工' },
    { v: '6180106', l: '技师' },
    { v: '6180107', l: '监工' },
  ],
  61802: [
    { v: '6180201', l: '玻璃纤维制品工' },
    { v: '6180202', l: '玻璃钢制品工' },
  ],
  61803: [{ v: '6180301', l: '石英玻璃制品加工工' }],
  61804: [
    { v: '6180401', l: '陶瓷原料准备工' },
    { v: '6180402', l: '陶瓷成型工' },
    { v: '6180403', l: '陶瓷烧成工' },
    { v: '6180404', l: '陶瓷装饰工' },
    { v: '6180405', l: '匣钵、模型制作工' },
    { v: '6180406', l: '古建琉璃工' },
  ],
  61805: [
    { v: '6180501', l: '搪瓷釉浆熔制工' },
    { v: '6180502', l: '搪瓷坯体制作工' },
    { v: '6180503', l: '搪瓷涂搪烧成工' },
    { v: '6180504', l: '搪瓷花版饰花工' },
  ],
  61901: [
    { v: '6190101', l: '影视置景制作员' },
    { v: '6190102', l: '影视服装员' },
    { v: '6190103', l: '影视舞台烟火特效员' },
    { v: '6190104', l: '影视动画制作员' },
    { v: '6190105', l: '影视木偶制作员' },
    { v: '6190106', l: '电影洗印员' },
  ],
  61902: [
    { v: '6190201', l: '唱片制作工' },
    { v: '6190202', l: '唱片检听工' },
    { v: '6190203', l: '音像带复制工' },
    { v: '6190204', l: '光盘复制工' },
  ],
  61903: [
    { v: '6190301', l: '照明设备操作员' },
    { v: '6190302', l: '影视设备机械员' },
    { v: '6190303', l: '广播电视天线工' },
    { v: '6190304', l: '有线广播电视机线员' },
    { v: '6190305', l: '音响调音员' },
    { v: '6190306', l: '舞台音响效果工' },
  ],
  61904: [
    { v: '6190401', l: '电影放映员' },
    { v: '6190402', l: '拷贝检片员' },
    { v: '6190403', l: '拷贝字幕员' },
    { v: '6190404', l: '电影院售票员' },
    { v: '6190405', l: '跑片员' },
  ],
  61905: [
    { v: '6190501', l: '考古发掘工' },
    { v: '6190502', l: '文物修复工' },
    { v: '6190503', l: '文物拓印工' },
    { v: '6190504', l: '古旧书画修复工' },
  ],
  62001: [
    { v: '6200101', l: '平版制版工' },
    { v: '6200102', l: '凸版制版工' },
    { v: '6200103', l: '凹版制版工' },
    { v: '6200104', l: '孔版制版工' },
    { v: '6200105', l: '印前制作员' },
  ],
  62002: [
    { v: '6200201', l: '平版印刷工' },
    { v: '6200202', l: '凸版印刷工' },
    { v: '6200203', l: '凹版印刷工' },
    { v: '6200204', l: '孔版印刷工' },
    { v: '6200205', l: '木刻水印印制工' },
    { v: '6200206', l: '珂罗版印制工' },
    { v: '6200207', l: '盲文印制工' },
  ],
  62003: [
    { v: '6200301', l: '装订工' },
    { v: '6200302', l: '印品整饰工' },
  ],
  62101: [
    { v: '6210101', l: '宝石琢磨工' },
    { v: '6210102', l: '贵金属首饰制作工' },
  ],
  62102: [{ v: '6210201', l: '地毯制作工' }],
  62103: [
    { v: '6210301', l: '金属、塑料、木制玩具装配工' },
    { v: '6210302', l: '布绒玩具制作工' },
    { v: '6210303', l: '搪塑玩具制作工' },
  ],
  62104: [
    { v: '6210401', l: '漆器制胎工' },
    { v: '6210402', l: '彩绘雕填制作工' },
    { v: '6210403', l: '漆器镶嵌工' },
  ],
  62105: [
    { v: '6210501', l: '机绣工' },
    { v: '6210502', l: '手绣制作工' },
    { v: '6210503', l: '抽纱挑编工' },
  ],
  62106: [
    { v: '6210601', l: '景泰蓝制作工' },
    { v: '6210602', l: '金属摆件工' },
    { v: '6210603', l: '金属手工艺品加工工人' },
  ],
  62107: [
    { v: '6210701', l: '金属工艺品雕刻工' },
    { v: '6210702', l: '竹木制手工艺品雕刻工' },
  ],
  62108: [
    { v: '6210801', l: '装饰美工' },
    { v: '6210802', l: '雕塑翻制工' },
    { v: '6210803', l: '壁画制作工' },
    { v: '6210804', l: '油画外框制作工' },
    { v: '6210805', l: '装裱工' },
    { v: '6210806', l: '版画制作工' },
  ],
  62199: [
    { v: '6219901', l: '民间工艺品制作工' },
    { v: '6219902', l: '人造花制作工' },
    { v: '6219903', l: '工艺画制作工' },
    { v: '6219904', l: '烟花爆竹制作工' },
    { v: '6219905', l: '竹木制手工艺品加工工人' },
    { v: '6219906', l: '布类纸品工艺品加工工人' },
    { v: '6219907', l: '矿石手工艺品加工人员' },
  ],
  62201: [
    { v: '6220101', l: '墨制作工' },
    { v: '6220102', l: '墨水制造工' },
    { v: '6220103', l: '墨汁制造工' },
    { v: '6220104', l: '绘图仪器制作工' },
    { v: '6220105', l: '静电复印机消耗材制造工' },
    { v: '6220106', l: '毛笔制作工' },
    { v: '6220107', l: '自来水笔制作工' },
    { v: '6220108', l: '圆珠笔制作工' },
    { v: '6220109', l: '铅笔制造工' },
    { v: '6220110', l: '印泥制作工' },
  ],
  62202: [
    { v: '6220201', l: '制球工' },
    { v: '6220202', l: '球拍、球网制作工' },
    { v: '6220203', l: '健身器材制作工' },
  ],
  62203: [
    { v: '6220301', l: '钢琴及键盘乐器制作工' },
    { v: '6220302', l: '提琴制作工' },
    { v: '6220303', l: '管乐器制作工' },
    { v: '6220304', l: '民族拉弦、弹拨乐器制作工' },
    { v: '6220305', l: '吹奏乐器制作工' },
    { v: '6220306', l: '打击乐器制作工' },
    { v: '6220307', l: '电声乐器制作工' },
  ],
  62301: [
    { v: '6230101', l: '凿岩工' },
    { v: '6230102', l: '爆破工' },
    {
      v: '6230103',
      l: '土石方机械操作工(推土、铲运机驾驶员、挖掘机驾驶员、打桩工、铲运机操作',
    },
  ],
  62302: [
    { v: '6230201', l: '砌筑工' },
    { v: '6230202', l: '洗石工人' },
    { v: '6230203', l: '砌砖匠' },
    { v: '6230204', l: '泥水匠' },
    { v: '6230205', l: '磨石工人' },
  ],
  62303: [
    { v: '6230301', l: '混凝土工' },
    { v: '6230302', l: '混凝土制品模具工' },
    { v: '6230303', l: '混凝土搅拌机械操作工' },
  ],
  62304: [{ v: '6230401', l: '钢筋工' }],
  62305: [{ v: '6230501', l: '架子工' }],
  62306: [
    { v: '6230601', l: '防水工' },
    { v: '6230602', l: '防渗墙工' },
    { v: '6230603', l: '排水工程人员' },
  ],
  62307: [
    { v: '6230701', l: '装饰装修工' },
    { v: '6230702', l: '室内成套设施装饰工' },
    { v: '6230703', l: '油漆工、喷漆工' },
    { v: '6230704', l: '铝门窗安装工人' },
    { v: '6230705', l: '石棉瓦或浪板安装工人' },
    { v: '6230706', l: '设计制图人员' },
    { v: '6230707', l: '地毯之装设人员' },
    { v: '6230708', l: '室内装潢人员(不含木工、油漆工)' },
    { v: '6230709', l: '室外装潢人员' },
    { v: '6230710', l: '承包商、监工' },
    { v: '6230711', l: '铁门窗制造安装工人' },
    { v: '6230712', l: '木工' },
    { v: '6230713', l: '安装玻璃幕墙工人' },
  ],
  62308: [
    { v: '6230801', l: '古建筑结构施工工' },
    { v: '6230802', l: '古建筑装饰工' },
  ],
  62309: [
    { v: '6230901', l: '筑路机械操作工' },
    { v: '6230902', l: '筑路、养护工' },
    { v: '6230903', l: '线桥专用机械操作工' },
    { v: '6230904', l: '铁道线路工' },
    { v: '6230905', l: '桥梁工' },
    { v: '6230906', l: '隧道工' },
    { v: '6230907', l: '铁路舟桥工' },
    { v: '6230908', l: '道岔制修工' },
    { v: '6230909', l: '枕木处理工' },
    { v: '6230910', l: '铁路平交道看守人员' },
    { v: '6230911', l: '铁路修护厂技工' },
    { v: '6230912', l: '铁路铺设领班' },
    { v: '6230913', l: '管道铺设及维护工人' },
    { v: '6230914', l: '高速公路工程人员' },
    { v: '6230915', l: '电线架设及维护工人' },
  ],
  62310: [
    { v: '6231001', l: '机械设备安装工' },
    { v: '6231002', l: '电气设备安装工' },
    { v: '6231003', l: '管工' },
    { v: '6231004', l: '防火系统、警报器安装人员' },
    { v: '6231005', l: '电梯、升降机安装工人(非高空作业)' },
    { v: '6231006', l: '电梯、升降机安装工人(高空作业)' },
    { v: '6231007', l: '电梯、升降机修理及维护工人' },
  ],
  62399: [
    { v: '6239901', l: '中小型施工机械操作工' },
    { v: '6239902', l: '建筑工程车辆驾驶员' },
    { v: '6239903', l: '建筑工程车辆机械操作员' },
    { v: '6239904', l: '监工' },
    { v: '6239905', l: '测量员' },
    { v: '6239906', l: '拆屋、迁屋工人' },
    { v: '6239907', l: '道路工程机械操作员' },
    { v: '6239908', l: '道路工程车辆驾驶员' },
    { v: '6239909', l: '工地看守员' },
    { v: '6239910', l: '海湾港口工程人员' },
    { v: '6239911', l: '水坝工程人员、挖井工程人员' },
    { v: '6239912', l: '潜水工作人员' },
    { v: '6239913', l: '挖泥船工人' },
    { v: '6239914', l: '工地推车工' },
  ],
  62401: [
    { v: '6240103', l: '出租车、救护车司机' },
    { v: '6240104', l: '游览车司机及服务员' },
    { v: '6240105', l: '客运车司机及服务员' },
    { v: '6240106', l: '小型客货两用车司机' },
    { v: '6240108', l: '人力三轮车夫' },
    { v: '6240109', l: '机动三轮车夫' },
    { v: '6240110', l: '营业用货车司机、随车工人' },
    { v: '6240111', l: '砂石车司机、随车工人' },
    { v: '6240112', l: '工程卡车司机、随车人员' },
    { v: '6240113', l: '液化、氧化油罐车司机、随车工人' },
    { v: '6240114', l: '货柜车司机、随车人员' },
    { v: '6240115', l: '有摩托车驾照人员' },
  ],
  62402: [
    { v: '6240201', l: '车站行车作业员' },
    { v: '6240202', l: '车站运转作业计划员' },
    { v: '6240203', l: '车号员' },
    { v: '6240204', l: '驼峰设备操作员' },
    { v: '6240205', l: '车站调车作业员' },
    { v: '6240206', l: '列车运转乘务员' },
    { v: '6240207', l: '机车驾驶员' },
    { v: '6240208', l: '机车调度员' },
    { v: '6240209', l: '发电车乘务员' },
    { v: '6240210', l: '机车燃料填充员' },
    { v: '6240211', l: '救援机械操作员' },
    { v: '6240212', l: '列车轴温检测员' },
    { v: '6240213', l: '铁路通信工' },
    { v: '6240214', l: '铁路电源工' },
    { v: '6240215', l: '铁路信号工' },
    { v: '6240216', l: '铁路机工' },
  ],
  62403: [
    { v: '6240301', l: '航空通信雷达导航员' },
    { v: '6240302', l: '航空通信雷达设备维护保养员' },
    { v: '6240303', l: '航空油料员' },
    { v: '6240304', l: '航空摄影员' },
    { v: '6240305', l: '航空器材员' },
    { v: '6240306', l: '航空气象员' },
  ],
  62404: [
    { v: '6240401', l: '水手长、水手' },
    { v: '6240402', l: '木匠' },
    { v: '6240403', l: '铜匠' },
    { v: '6240404', l: '船舶机工' },
    { v: '6240405', l: '船舶轮机员' },
    { v: '6240406', l: '船舶加油' },
    { v: '6240407', l: '无线电航标操作工' },
    { v: '6240408', l: '潜水员' },
    { v: '6240409', l: '视觉航标工(灯塔、航标维护保养)' },
    { v: '6240410', l: '港口维护工(码头维修、水面防污、港口除尘)' },
    { v: '6240411', l: '航道航务施工工' },
    { v: '6240412', l: '泵匠' },
    { v: '6240413', l: '电机师' },
    { v: '6240414', l: '游览船之驾驶及工作人员' },
    { v: '6240415', l: '小汽艇之驾驶及工作人员' },
    { v: '6240416', l: '拖船驾驶员及工作人员' },
    { v: '6240417', l: '渡船驾驶员及工作人员' },
  ],
  62405: [
    { v: '6240501', l: '起重装卸机械操作工' },
    { v: '6240502', l: '起重工' },
    { v: '6240503', l: '输送机操作工' },
    { v: '6240504', l: '闸门运行工' },
    { v: '6240505', l: '索道运输机械操作工' },
  ],
  62499: [{ v: '6249901', l: '电梯、升降机操作员(不含矿场使用者)' }],
  62501: [
    { v: '6250101', l: '大气环境监测工' },
    { v: '6250102', l: '水环境监测工' },
    { v: '6250103', l: '土壤环境监测工' },
    { v: '6250104', l: '环境生物监测工' },
    { v: '6250105', l: '环境噪声及振动监测工' },
    { v: '6250106', l: '固体废物监测工' },
    { v: '6250107', l: '环境辐射监测工' },
    { v: '6250108', l: '室内装饰装修质量检验员' },
    { v: '6250109', l: '室内环境治理员' },
  ],
  62502: [
    { v: '6250201', l: '海洋调查与监测工' },
    { v: '6250202', l: '海洋浮标工' },
    { v: '6250203', l: '海洋水文气象观测员' },
  ],
  62503: [
    { v: '6250301', l: '固体废物处理工' },
    { v: '6250302', l: '废水处理工' },
    { v: '6250303', l: '废气处理工' },
    { v: '6250304', l: '除尘设备运行工' },
  ],
  62601: [
    { v: '6260101', l: '化学检验工' },
    { v: '6260102', l: '材料成分检验工' },
    { v: '6260103', l: '材料物理性能检验工' },
    { v: '6260104', l: '无损检测员' },
    { v: '6260105', l: '产品环境适应性能检验工' },
    { v: '6260106', l: '产品可靠性能检验工' },
    { v: '6260107', l: '产品安全性能检验工' },
    { v: '6260108', l: '食品检验工' },
    { v: '6260109', l: '饲料检验工' },
    { v: '6260110', l: '畜禽产品检验工' },
    { v: '6260111', l: '烟草检验工' },
    { v: '6260112', l: '纺织纤维检验工' },
    { v: '6260113', l: '针纺织品检验工' },
    { v: '6260114', l: '印染工艺检验工' },
    { v: '6260115', l: '服装鞋帽检验工' },
    { v: '6260116', l: '木材及家具检验工' },
    { v: '6260117', l: '包装材料检验工' },
    { v: '6260118', l: '文体用品及出版物品检验工' },
    { v: '6260119', l: '燃料检验工' },
    { v: '6260120', l: '感光材料检验工' },
    { v: '6260121', l: '药物检验工' },
    { v: '6260122', l: '中药检验工' },
    { v: '6260123', l: '五金制品检验工' },
    { v: '6260124', l: '机械产品检验工' },
    { v: '6260125', l: '医疗器械检验工' },
    { v: '6260126', l: '机动车检验工' },
    { v: '6260127', l: '电器产品检验工' },
    { v: '6260128', l: '电工器材检验工' },
    { v: '6260129', l: '照明电器检验工' },
    { v: '6260130', l: '通信设备检验工' },
    { v: '6260131', l: '广播影视设备检验工' },
    { v: '6260132', l: '计算机检验工' },
    { v: '6260133', l: '电子器件检验工' },
    { v: '6260134', l: '仪器仪表检验工' },
    { v: '6260135', l: '贵金属首饰、钻石、宝玉石检验员' },
    { v: '6260136', l: '管道检验工' },
    { v: '6260137', l: '合成材料测试员' },
  ],
  62602: [
    { v: '6260201', l: '飞机检验工' },
    { v: '6260202', l: '机载导弹检验工' },
    { v: '6260203', l: '航空发动机检验工' },
    { v: '6260204', l: '飞机螺旋桨检验工' },
    { v: '6260205', l: '飞机、发动机附检验工' },
    { v: '6260206', l: '航空环控救生装备检验工' },
    { v: '6260207', l: '航空仪表检验工' },
  ],
  62603: [
    { v: '6260301', l: '航天器无损检测试验工' },
    { v: '6260302', l: '航天器材料性能测试试验工' },
    { v: '6260303', l: '试车台测量工' },
    { v: '6260304', l: '试车台液、气系统操作工' },
    { v: '6260305', l: '试车台控制工' },
    { v: '6260306', l: '液体推进剂性能试验工' },
    { v: '6260307', l: '固体推进剂性能试验工' },
    { v: '6260308', l: '试车台测力计量检定工' },
  ],
  62604: [
    { v: '6260401', l: '长度计量工' },
    { v: '6260402', l: '热工计量工' },
    { v: '6260403', l: '衡器计量工' },
    { v: '6260404', l: '硬度测力计量工' },
    { v: '6260405', l: '容量计量工' },
    { v: '6260406', l: '电器计量工' },
    { v: '6260407', l: '化学计量工' },
    { v: '6260408', l: '声学计量工' },
    { v: '6260409', l: '光学计量工' },
    { v: '6260410', l: '电离辐射计量工' },
    { v: '6260411', l: '专用计量器具计量工' },
  ],
  69901: [{ v: '6990101', l: '包装人员' }],
  69902: [{ v: '6990201', l: '机泵操作人员' }],
  69903: [{ v: '6990301', l: '简单体力劳动人员' }],
  70000: [
    {
      v: '7000001',
      l: '一般地面部队人员(含陆军野战、机械修护、土木工程、飞弹、战车及空军炮、飞机修护等）',
    },
    {
      v: '7000002',
      l: '特种兵(海军陆战队、伞兵、水兵、爆破兵、蛙人、化学兵、负有布雷爆破任务之工兵、情报单位负有特殊任务者)',
    },
    { v: '7000003', l: '行政及内勤人员(国防部、三军总部、军校教官等)' },
    { v: '7000004', l: '宪兵' },
    { v: '7000005', l: '后勤补给及通讯地勤人员' },
    { v: '7000006', l: '军事研究单位纸上设计人员' },
    { v: '7000007', l: '军事单位武器、弹药研究及管理人员' },
    { v: '7000008', l: '空军飞行官兵、空军海洋巡弋舰艇及潜艇官兵' },
    { v: '7000009', l: '前线军人' },
    { v: '7000010', l: '军校学生及入伍受训新兵' },
    { v: '7000011', l: '军医院官兵' },
  ],
  80000: [
    { v: '8000001', l: '无业人员' },
    { v: '8000002', l: '退休人员' },
    { v: '8000003', l: '离休人员' },
    { v: '8888888', l: '其他职业人员' },
  ],
  80001: [
    { v: '8000101', l: '特殊运动班学生（拳击、摔跤、跆拳道等）' },
    { v: '8000102', l: '武术学校学生' },
  ],
  80002: [{ v: '8000201', l: '高处作业人员' }],
};

export { lve1, lve2, lve3, lve4 };
