import { React, ClassNames, $ } from '../../../../common/3rd';
import AtomicCodesComponentThing from '../atomic-codes-component';
import { Label } from './label';

/**
 * 单选框
 */
class Radio extends AtomicCodesComponentThing {
  renderCode(code, index) {
    // eslint-disable-next-line
    return (
      <span
        className={ClassNames({ checked: code.v == this.getValueFromModel() })}
        onClick={this.onCodeClick.bind(this, code)}
        key={index}
      >
        <span className="sth-radio-icon" />
        <Label model={this.getModel} layout={{ label: code.l }} parent={this} />
      </span>
    );
  }
  render() {
    return (
      <div {...this.getPrototypes()} ref="me">
        {this.getCodes().map((code, codeIndex) => {
          return this.renderCode(code, codeIndex);
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-radio';
  }
  onCodeClick(code) {
    // this.setValueToModel(code.v);
    // this.forceUpdate();
    this.setValueToDOM(code.v);
    this.me().trigger($.Event('change', { value: code.v }));
  }
  setValueToDOM(value) {
    this.me().attr({ 'data-value': value });
  }
  getValueFromDOM() {
    return this.me().attr('data-value');
  }
  setValueToModel(value, callback, force, id) {
    if (super.setValueToModel(value, callback, force, id) !== false) {
      this.forceUpdate();
    }
  }
}

class VerticalRadio extends Radio {
  getDefaultClassName() {
    return 'sth-radio sth-radio-vertical';
  }
}
class RadioButton extends Radio {
  getDefaultClassName() {
    return 'sth-radio sth-radio-button';
  }
  renderCode(code, index) {
    // eslint-disable-next-line
    return (
      <span
        className={ClassNames({ checked: code.v == this.getValueFromModel() })}
        onClick={this.onCodeClick.bind(this, code)}
        key={index}
      >
        <Label model={this.getModel} layout={{ label: code.l }} parent={this} />
      </span>
    );
  }
}

export { Radio, VerticalRadio, RadioButton };
