import { React, ClassNames } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';

import './bottom-menu.css';
import messages from './messages.json';

Lang.installMessages(messages, 'bottom-menu');

/**
 * bottom menu
 */
class BottomMenu extends ReactRouterContextWidget {
  renderItem(item, index) {
    const className = ClassNames('item', item.class, {
      active: item.class === this.getActiveKey(),
    });
    return (
      <div
        className={className}
        key={`item-${index}`}
        onClick={this.onItemClicked.bind(this, item, index)}
      >
        <div className={`icon ${item.icon}`} />
        <div className="label">
          <span>{item.label}</span>
        </div>
      </div>
    );
  }
  renderSalientButton(salient) {
    return (
      <div className="item salient">
        <span
          className={`fa fa-fw fa-${salient.class}`}
          onClick={this.onSalientButtonClicked}
        />
      </div>
    );
  }
  renderItems() {
    const items = Lang.messages['bottom-menu'].items;
    const count = items.length;
    // only even buttons, can show the salient button
    let salientButtonVisible = this.isSalientButtonVisible();
    let salientIndex = count / 2 - 1;
    let salientButton = this.getSalientButton();
    return items.map((item, index) => {
      let dom = this.renderItem(item, index);
      if (index === salientIndex && salientButtonVisible && salientButton) {
        let salient = this.renderSalientButton(salientButton);
        return [dom, salient];
      } else {
        return dom;
      }
    });
  }
  render() {
    const className = ClassNames(this.getWidgetClassName('bottom-menu'), {
      'salient-shown': this.isSalientButtonVisible(),
    });
    return <div className={className}>{this.renderItems()}</div>;
  }
  getActiveKey() {
    return this.getPropsValue('active');
  }
  onItemClicked(item, index) {
    const to = item.to;
    this.pushRedirect(to);
  }
  isSalientButtonVisible() {
    const items = Lang.messages['bottom-menu'].items;
    const count = items.length;
    // only even buttons, can show the salient button
    return count % 2 === 0;
  }
  getSalientButton() {
    return this.getPropsValue('salient');
  }
  onSalientButtonClicked = (evt) => {
    this.getSalientButton().action.call(this);
  };
}

export default BottomMenu;
