import { React, ReactDOM, PropTypes, ClassNames, $ } from '../../common/3rd';
import { appHistory } from '@/router';
import { Ajax, Lang, Validation, Envs, Utils } from '../../common/common';

import Alert from '../../component/both/alert/alert';
import PageLoading from '../../component/both/loading/page-loading';
import { isIIMP, isJingBaoTong } from '@/util/precondition';

import messages from './messages.json';

Lang.installMessages(messages, 'widget');

/**
 * widget, default render a div
 */
class Widget extends React.Component {
  state = {};
  UNSAFE_componentWillMount() {}
  componentDidMount() {}
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {}
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {}
  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {}
  render() {
    return <div />;
  }
  /**
   * get value from this.props, return defaultValue when it is null.
   * if value is a function, run it.
   */
  getPropsValue(key, defaultValue) {
    let value = this.props[key];
    if (value == null) {
      return defaultValue != null ? defaultValue : null;
    } else if (typeof value === 'function') {
      value = value.call(this);
      return value == null
        ? defaultValue != null
          ? defaultValue
          : null
        : value;
    } else {
      return value;
    }
  }
  getWidgetClassName(className) {
    return ClassNames(className, this.getPropsValue('className'));
  }
}

/**
 * @type {
 *  React.Context<{
 *    init: {
 *      value: boolean,
 *      update: boolean,
 *    }
 *  }>
 * }
 */
const CheckableContext = React.createContext({
  init: undefined,
});
CheckableContext.displayName = 'CheckableContext';

/**
 * checkable widget.
 * it's a very complex widget
 */
class CheckableWidget extends Widget {
  static contextType = CheckableContext;

  isInitValueOnMount() {
    // 如果init参数没有指定
    // 或者init.value参数不是false
    // 则需要在didMount进行初始化数据
    return !this.context.init || this.context.init.value !== false;
  }
  initValue() {
    if (this.context.init && this.context.init.update) {
      return this.setValue(this.getValueFromModel());
    }
    if (this.hasModel() && !this.state.valueInitialized) {
      this.state.valueInitialized = true;
      return this.setValue(this.getValueFromModel());
    }
  }
  componentDidMount() {
    super.componentDidMount();
    if (this.isInitValueOnMount()) {
      this.initValue();
    }
  }
  clearHoldingChecker() {
    if (this.state.checkLater) {
      clearTimeout(this.state.checkLater);
      delete this.state.checkLater;
    }
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    this.clearHoldingChecker();
  }
  componentDidUpdate() {
    super.componentDidUpdate();
    if (!this.isInitValueOnMount()) {
      this.initValue();
    }
  }
  renderValidationFailure() {
    const propName = this.getPropertyName();
    const failures = this.getValidationResult().get(propName);
    if (failures.length > 0) {
      // only show the first failure
      return (
        <span className="validation-failure shown">{failures[0].message}</span>
      );
      /*return failures.map((failure, index) => {
				return <span className='validation-failure'
					key={`failure-${propName}-${failure.code}`}>
					{failure.message}
				</span>;
			});*/
    } else {
      return <span className="validation-failure"></span>;
    }
  }
  render() {
    const props = this.getProps();
    const fail = this.getValidationResult().hasFailure(this.getPropertyName());
    let className = ClassNames('validate', props.className, {
      success: !fail,
      fail: fail,
      disabled: this.isDisabled(),
    });
    props.disabled = this.isDisabled();
    return (
      <div className={className} ref="wrapper">
        {this.renderRealWidget(props)}
        {this.renderValidationFailure()}
      </div>
    );
  }
  getAdditionalPropValue(key, defaultValue) {
    return this.getPropsValue(`a-${key}`, defaultValue);
  }
  getUnwrappedAdditionalPropValue(key) {
    return this.props[`a-${key}`];
  }
  getProps() {
    let props = $.extend({}, this.props);
    this.wrapOnChange(props).wrapOthers(props);
    Object.keys(props).forEach((key) => {
      if (key.startsWith('a-')) {
        delete props[key];
      }
    });
    return props;
  }
  wrapOthers(props) {
    return this;
  }
  wrapOnChange(props) {
    const onChange = props.onChange;
    props.onChange = (evt) => {
      this.onChange(evt);
      if (onChange) {
        onChange.call(this, evt);
      }
    };
    return this;
  }

  onChange(evt) {
    const value = this.getValue();
    this.setValueIntoModel(value);
    this.doValidate();
  }
  getValue() {
    return $(ReactDOM.findDOMNode(this.refs.widget)).val();
  }
  setValue(value) {
    $(ReactDOM.findDOMNode(this.refs.widget)).val(value);
    this.setValueIntoModel(value);
    return this;
  }
  hasModel() {
    return this.getModel() != null;
  }
  getValueFromModel() {
    let model = this.getModel();
    if (model) {
      let propName = this.getPropertyName();
      return model[propName];
    } else {
      return null;
    }
  }
  setValueIntoModel(value) {
    let model = this.getModel();
    if (model) {
      const currentValue = this.getValueFromModel();
      if (currentValue !== value) {
        let propName = this.getPropertyName();
        model[propName] = value;
      }
    }
  }
  /**
   * do validate
   * @param {boolean} immediately do it immediately
   */
  doValidate(immediately) {
    const value = this.getValue();
    const delay = this.getValidateDelay();
    if (!immediately && delay) {
      this.clearHoldingChecker();
      this.state.checkLater = setTimeout(() => {
        this.validateInput(value);
      }, delay);
    } else {
      this.validateInput(value);
    }
    return this;
  }
  validateInput(value) {
    this.clearValidationFailures();

    const hasValue = value != null && (value + '').trim().length !== 0;
    if (this.isRequired() && !hasValue) {
      // has no value assigned, but is required
      this.raiseValidationFailure('empty');
    } else {
      const validator = this.getValidator();
      if (validator) {
        Utils.toArray(validator).forEach((validator) => {
          validator.call(this, value, this.getModel(), this);
        });
      }
    }

    this.forceUpdate();
  }
  isDefaultValidatorDisabled() {
    return this.getAdditionalPropValue('default-validator-disabled') === true;
  }
  getValidator() {
    if (!this.isDefaultValidatorDisabled()) {
      return Utils.appendToArray(
        this.props['a-validator'],
        this.getDefaultValidators()
      );
    } else {
      return this.props['a-validator'];
    }
  }
  /**
   * override this method if there are default validators.
   * return object when only one, or an array when multiple.
   */
  getDefaultValidators() {
    return [];
  }
  getValidateDelay() {
    return this.getPropsValue('a-validate-delay');
  }
  getModel() {
    return this.getPropsValue('a-model');
  }
  getPropertyName() {
    return this.getPropsValue('a-propName', '--self');
  }
  getValidationResult() {
    if (!this.state.validationResult) {
      this.state.validationResult = new Validation();
    }
    return this.state.validationResult;
  }
  /**
   * raise validation failure
   * @param {string} code
   */
  raiseValidationFailure(code) {
    const validation = this.getValidationResult();
    if (validation) {
      validation.append({
        key: this.getPropertyName(),
        code: code,
        message: Lang.messages.widget[code],
      });
    }
  }
  /**
   * clear all validation failures
   */
  clearValidationFailures() {
    const validation = this.getValidationResult();
    if (validation) {
      validation.remove({
        key: this.getPropertyName(),
        code: Validation.ALL,
      });
    }
  }
  isRequired() {
    return this.getPropsValue('a-required');
  }
  disable() {
    this.setState({
      disabled: true,
    });
    return this;
  }
  enable() {
    this.setState({
      disabled: false,
    });
    return this;
  }
  isDisabled() {
    if (this.state.disabled != null) {
      return this.state.disabled;
    } else {
      return this.props.disabled != null ? this.props.disabled : false;
    }
  }
  focus() {
    $(ReactDOM.findDOMNode(this.refs.widget)).focus();
    return this;
  }
}

/**
 * fail fast promise
 */
class FailFastPromise {
  static resolved() {
    return new FailFastPromise(
      $.Deferred(function (dfd) {
        dfd.resolve();
      }).promise()
    );
  }
  constructor(promise) {
    this.promise = promise;
    this.failChain = [];

    let chain = this.failChain;
    this.promise.fail(function () {
      let params = Array.prototype.slice.call(arguments);
      chain.find((fail) => {
        try {
          let ret = fail.apply(this, params);
          if (ret === false) {
            // break chain exactly when fail callback returns false
            return true;
          }
        } catch (e) {
          // do not break the chain
          console.error(e);
        }
        return false;
      });
    });
  }
  done() {
    if (arguments.length > 0) {
      this.promise.done(arguments[0]);
    }
    return this;
  }
  fail() {
    Array.prototype.push.apply(
      this.failChain,
      Array.prototype.slice.call(arguments)
    );
    return this;
  }
  always() {
    if (arguments.length > 0) {
      return this.done(arguments[0]).fail(arguments[0]);
    } else {
      return this;
    }
  }
  catch(fn) {
    return this.then(null, fn);
  }
  progress() {
    this.promise.progress(arguments);
    return this;
  }
  promise(obj) {
    return new FailFastPromise(this.promise.promise(obj));
  }
  state() {
    return this.promise.state();
  }
  /**
   * keep behavior
   */
  then(onFulfilled, onRejected, onProgress) {
    return new FailFastPromise(
      this.promise.then(onFulfilled, onRejected, onProgress)
    );
  }
}

/**
 * add promise methods into SuperClass
 *
 * @param {Class} SuperClass
 */
const PromisedWidget = (SuperClass) =>
  class extends SuperClass {
    reLogin = () => {
      if (isJingBaoTong()) {
        window.location.href = `bjlife://login`;
      } else if (isIIMP()) {
        window.location.href = `bjlifeiimp://login`;
      } else {
        this.replaceRedirect(Envs.PATH.SIGN_IN);
      }
    };
    doPost(url, data, options) {
      return new FailFastPromise(Ajax.doPost(url, data, options))
        .fail(this.on4xx)
        .fail(this.on5xx);
    }
    doGet(url, data, options) {
      return new FailFastPromise(Ajax.doGet(url, data, options))
        .fail(this.on4xx)
        .fail(this.on5xx);
    }
    /**
     * arguments are instances of FailFastPromise
     */
    doAll() {
      return new FailFastPromise(
        Ajax.doAll.apply(
          Ajax,
          Array.prototype.slice.call(arguments).map((argument) => {
            return argument.promise;
          })
        )
      );
    }
    ajax(url, data, options) {
      return new FailFastPromise(Ajax.ajax(url, data, options))
        .fail(this.on4xx)
        .fail(this.on5xx);
    }
    on4xx = (jqXHR, textStatus, errorThrown) => {
      const status = jqXHR.status;
      const params = Utils.getUrlProps();
      if (status === 401) {
        if (isJingBaoTong()) {
          window.location.href = `bjlife://login`;
        } else if (isIIMP()) {
          window.location.href = `bjlifeiimp://login`;
        } else if (window.location.href.indexOf('/mobile/') > -1) {
          if (!params.tenantCode) {
            this.replaceRedirect(Envs.PATH.SIGN_IN);
          }
        } else if (window.location.href.indexOf('/wechat/') > -1) {
          //新增回调，覆盖方法
          if (this.on401Cover && typeof this.on401Cover == 'function') {
            this.on401Cover();
            return;
          }
          //end
          this.replaceRedirect(Envs.PATH.WECHAT_SIGN + window.location.search);
          return;
        } else {
          this.replaceRedirect(Envs.PATH.SIGN_IN);
        }
        Alert.message([Lang.messages.common.requestError.FORBIDDEN]);
        PageLoading.end();
        // this.replaceRedirect(Envs.PATH.SIGN_IN);
        // TODO how to break promise chain?
        console.log(
          '%c401 %cBreak ajax chain on 401 returned and redirect to sign page.',
          'color: chocolate',
          'color: normal'
        );
        return false;
      } else if (status === 403) {
        if (isJingBaoTong()) {
          window.location.href = `bjlife://login`;
        } else if (isIIMP()) {
          window.location.href = `bjlifeiimp://login`;
        } else if (window.location.href.indexOf('/mobile/') > -1) {
          if (!params.tenantCode) {
            this.replaceRedirect(Envs.PATH.SIGN_IN);
          }
        } else if (window.location.href.indexOf('/wechat/') > -1) {
          //新增回调，覆盖方法
          if (this.on403Cover && typeof this.on403Cover == 'function') {
            this.on403Cover();
            return;
          }
          //end
          this.replaceRedirect(Envs.PATH.WECHAT_SIGN);
        } else {
          this.replaceRedirect(Envs.PATH.SIGN_IN);
        }
        PageLoading.end();
        // TODO how to deal with 403, no access permitted exception
        // this.replaceRedirect(Envs.PATH.SIGN_IN);
        console.error(jqXHR.responseJSON);
        if (!params.tenantCode) {
          Alert.message([Lang.messages.common.requestError.FORBIDDEN]);
        }
      } else if (status === 406) {
        // TODO how to deal with 406, validation exception
        PageLoading.end();
        let isAlert = true;
        let actionAlert = false;
        let errors = (jqXHR.responseJSON.errors || []).map((v, i) => {
          if (
            v.code == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
            v.code.includes('REAL_NAME_FAIL_COUNT_') ||
            v.code.includes('CAN_CODE_FAIL_COUNT_') ||
            v.code.includes('VISIT_FACE_FAIL_COUNT_') ||
            v.code == 'VISIT_FACE_PAST' ||
            v.code.includes('VISIT_FACE_FAIL_TEN_LEFT_SECONDS_') ||
            v.code == 'VISIT_TEN_MINUTE_EXPIRED' ||
            v.code == 'FACE_ORDER_NO_NULL' ||
            v.code == 'FACE_TX_RESPONSE_NULL' ||
            v.code == 'FACE_TX_RESPONSE_CODE_ERROR' ||
            v.code == 'FACE_TX_RESPONSE_IDNO_ERROR'
          ) {
            isAlert = false;
          } else if (
            v.code == 'INSURE_IS_NOT_ALLOW_CHANGE_BN' ||
            v.code == 'INSURE_IS_NOT_ALLOW_CHANGE_RELATION'
          ) {
            actionAlert = true;
            isAlert = false;
          } else {
            isAlert = true;
          }
          let msg = Lang.messages.common.requestError[v.code];
          return msg || v.code;
        });
        if (actionAlert) {
          Alert.message(errors, () => {
            this.pushRedirect(Envs.PATH.WECHAT_MY_GUARANTEE);
          });
          return;
        }
        if (isAlert) {
          Alert.message(errors);
        }
      } else {
        Alert.message('请求错误！');
      }
    };
    on5xx = (jqXHR, textStatus, errorThrown) => {
      const status = jqXHR.status;
      if (status === 500) {
        console.error(jqXHR.responseJSON);
        Alert.message([Lang.messages.common.requestError.RC_99999]);
      } else if (status === 502) {
        console.error(jqXHR.responseJSON);
        Alert.message([Lang.messages.common.requestError.RC_99999]);
        //增加状态码 0 时 无网络 2019.10.11
      } else if (status === 0) {
        console.error(jqXHR.responseJSON);
        Alert.message([Lang.messages.common.requestError.RC_99999]);
      }
    };
  };

/**
 * component with react router context
 */
class ReactRouterContextWidget extends PromisedWidget(Widget) {
  /**
   * redirect to given 'to'
   * @see https://github.com/remix-run/history/tree/v4.10.1/docs
   * @param {Path | LocationDescriptor<HistoryLocationState>} to
   * @param {boolean=} push true: pushState; false: replaceState
   * @param {any=} state 通过state传参在页面刷新或回退时参数不会丢失，使用方法：this.props.location.state
   */
  redirect(to, push, state) {
    if (push) {
      appHistory.push(to, state);
    } else {
      appHistory.replace(to, state);
    }
  }

  /**
   * @see https://github.com/remix-run/history/tree/v4.10.1/docs
   * @param {Path | LocationDescriptor<HistoryLocationState>} to
   * @param {any=} state 通过state传参在页面刷新或回退时参数不会丢失，使用方法：this.props.location.state
   */
  pushRedirect(to, state) {
    if (to.comeIn && to.pathname == Envs.PATH.WECHAT_SIGN) {
      let wSearch = window.location.search ? window.location.search + '&' : '?';
      to.pathname = `${Envs.PATH.WECHAT_SIGN}${wSearch}comeIn=true`;
    }
    this.redirect(to, true, state);
  }

  /**
   * @see https://github.com/remix-run/history/tree/v4.10.1/docs
   * @param {Path | LocationDescriptor<HistoryLocationState>} to
   * @param {boolean=} push true: pushState; false: replaceState
   * @param {any=} state 通过state传参在页面刷新或回退时参数不会丢失，使用方法：this.props.location.state
   */
  replaceRedirect(to, state) {
    this.redirect(to, false, state);
  }
  historyBack() {
    appHistory.goBack();
  }
  historyForward() {
    appHistory.goForward();
  }
  historyGo(step) {
    appHistory.go(step);
  }
  /**
   * call this method very carefully, only when the component is rendered by react-router.
   * otherwise throw exception
   */
  getCurrentLocation() {
    return this.props.location.pathname;
  }
  /**
   * call this method very carefully, only when the component is rendered by react-router.
   * otherwise throw exception
   */
  getMatchUrl() {
    return this.props.match.url;
  }
  /**
   * call this method very carefully, only when the component is rendered by react-router.
   * otherwise throw exception
   * @replaceLast replace the last segment of match url when true
   */
  getRelativeUrl(uri, replaceLast) {
    let match = this.getMatchUrl();
    if (replaceLast) {
      match = match.substring(0, match.lastIndexOf('/'));
    }
    return `${match}${uri}`;
  }
}

export {
  Widget,
  CheckableContext,
  CheckableWidget,
  ReactRouterContextWidget,
  FailFastPromise,
  PromisedWidget,
};
