import { React, ReactDOM, $ } from '../../common/3rd';

import { Widget } from '@/component/components';

import './widgets.css';

/**
 * 页面标题下的搜索栏
 */
class SearchBar extends Widget {
  render() {
    return (
      <div className="search-bar">
        <div className="search-content">
          <input
            type="text"
            className="keyword"
            ref="text"
            onChange={this.onChanged}
            placeholder={this.getPlaceholder()}
          />
          <div className="serach-icon" onClick={this.onSearchClicked}>
            <i className="my-icon icon-search-red"></i>
          </div>
        </div>
      </div>
    );
  }
  onSearchClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.search();
  };
  search() {
    let search = this.props.search;
    let value = $(ReactDOM.findDOMNode(this.refs.text)).val();
    if (search) {
      search.call(this, value);
    }
  }
  onChanged = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.change();
  };
  change() {
    let value = $(ReactDOM.findDOMNode(this.refs.text)).val();
    let change = this.props.change;
    if (change) {
      change.call(this, value);
    }
  }
  getPlaceholder() {
    return this.props.placeholder;
  }
}

export default SearchBar;
