import { React, ReactDOM, $ } from '../../../common/3rd';
import Lang from '../../../common/lang';
import './Popup.css';

import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'alert');
let hasLocked = '0';
class Alert extends React.Component {
  /*static propTypes = {
	  open: PropTypes.bool,
	  title: PropTypes.string,
	  message: PropTypes.node,
	  onRequestClose: PropTypes.func,
	}*/

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: '',
      message: '',
      type: '',
      callback: null,
      lockCallBack: null,
      lockState: false,
    };

    let sAccount = localStorage.getItem('CSMS-Account');
    let oAccount = JSON.parse(sAccount);
    let account = oAccount.Signin;
    if (account && account.accountId) {
      let identityCard = account.idNo;
      let strBirthday = '';
      if (!identityCard) {
        return;
      }
      if (identityCard.length == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          '/' +
          identityCard.substr(10, 2) +
          '/' +
          identityCard.substr(12, 2);
      }
      if (identityCard.length == 15) {
        strBirthday =
          '19' +
          identityCard.substr(6, 2) +
          '/' +
          identityCard.substr(8, 2) +
          '/' +
          identityCard.substr(10, 2);
      }
      //时间字符串里，必须是“/”
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      if (age > 60) {
        import('./Popup-old.css');
      }
      /**
       * 增加测试帐号适老化逻辑判断
       * */
      let accountName = account.accountName;
      if (accountName === 'B2C') {
        if (age > 20) {
          import('./Popup-old.css');
        }
      }
    }
  }

  open() {
    this.setState({ open: true });
  }
  saveLockStatus() {
    // console.log("alert",window.XBack.lockPage,window.XBack.lockState,window.XBack.callback)
    //偌当前页面存在lockRoute 记录当前locakRpute状态
    let lockCallBack = window.XBack.callback;
    let lockState = window.XBack.lockState;
    let lockUrl = window.XBack.lockPage;
    //第一次调用存储lock参数
    if (lockState && lockUrl === window.location.href && hasLocked == '0') {
      lockCallBack = window.XBack.callback;
      lockState = window.XBack.lockState;
      lockUrl = window.XBack.lockPage;
      this.setState({ lockCallBack, lockState });
    } else if (
      hasLocked == '0' &&
      (!lockState || lockUrl === window.location.href)
    ) {
      this.setState({
        lockCallBack: null,
        lockState: false,
      });
    }
  }
  hide() {
    // 08/01/2018 - TAG: by yanzhi.mo - 原本设计不合理，即使点击遮罩也是取消的行为，也是要走回调
    this.state.callback && this.state.callback();
    this.setState(
      {
        open: false,
      },
      () => {
        this.state.promise.resolve();
        delete this.state.promise;
      }
    );
  }

  message(messages, callback) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return (
          <p key={`alert${i}`}>
            {messages.length > 1 && Array.isArray(messages) && i + 1 + '.'}
            {v}
          </p>
        );
      }),
      open: true,
      promise: promise,
      callback,
      type: '1',
    });
    return promise.promise();
  }

  messageDo(messages, _callBack) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return (
          <p key={`alert${i}`}>
            {messages.length > 1 && Array.isArray(messages) && i + 1 + '.'}
            {v}
          </p>
        );
      }),
      open: true,
      promise: promise,
      callback: _callBack,
      type: '4',
    });
    return promise.promise();
  }

  ask(messages, _callBack) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return <p key={`alert${i}`}>{v}</p>;
      }),
      open: true,
      promise: promise,
      type: '2',
      callback: _callBack,
    });
    return promise.promise();
  }

  askInsure(messages, _callBack) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return (
          <p key={`alert${i}`}>
            {v}
            {',可能是以下问题：'}
            <br />
            {'1、检查输入信息的准确性；'}
            <br />
            {'2、预留手机号是否为银行卡预留的手机号；'}
            <br />
            {'3、请确认是否开通了银联无卡支付业务'}
          </p>
        );
      }),
      open: true,
      promise: promise,
      type: '5',
      callback: _callBack,
    });
    return promise.promise();
  }

  askSurrender(messages, _callBack) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return <p key={`alert${i}`}>{v}</p>;
      }),
      open: true,
      promise: promise,
      type: '3',
      callback: _callBack,
    });
    return promise.promise();
  }

  code(messages, _callBack) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return (
          <p key={`alert${i}`}>
            <input
              type="number"
              className="alert-code"
              placeholder="请输入验证码"
            />
          </p>
        );
      }),
      open: true,
      promise: promise,
      type: '2',
      callback: _callBack,
    });
    return promise.promise();
  }

  componentDidMount() {}

  check(param) {
    this.setState(
      {
        open: false,
      },
      () => {
        this.state.promise.resolve();
        delete this.state.promise;
      }
    );
    let callback = this.state.callback;
    if (callback) {
      callback(param);
      this.setState({
        callback: null,
      });
    }
  }

  //自定义 按钮文本

  defined(messages, _callBack, otherOptions) {
    const promise = $.Deferred();
    this.saveLockStatus();
    this.setState({
      message: (Array.isArray(messages) ? messages : [messages]).map((v, i) => {
        return <p key={`alert${i}`}>{v}</p>;
      }),
      open: true,
      cancel: (otherOptions && otherOptions.cancel) || '否',
      confirm: (otherOptions && otherOptions.confirm) || '是',
      promise: promise,
      type: '6',
      callback: _callBack,
      definedClassName: (otherOptions && otherOptions.definedClassName) || '',
      definedTitle: (otherOptions && otherOptions.title) || '',
      alertImg: (otherOptions && otherOptions.alertImg) || '',
    });
    return promise.promise();
  }
  renderButton() {
    if (this.state.type === '1') {
      return (
        <div className="alert-btn">
          <div
            className="button button-default"
            onClick={() => {
              this.hide();
            }}
          >
            {Lang.messages && Lang.messages.alert.ok}
          </div>
        </div>
      );
    } else if (this.state.type === '2') {
      return (
        <div className="alert-btn">
          <div
            className="button-no"
            onClick={() => {
              this.check('0');
            }}
          >
            {Lang.messages && Lang.messages.alert.cancel}
          </div>
          <div
            className="button-yes"
            onClick={() => {
              this.check('2');
            }}
          >
            {Lang.messages && Lang.messages.alert.confirm}
          </div>
        </div>
      );
    } else if (this.state.type === '3') {
      return (
        <div className="alert-btn">
          <div
            className="button-no"
            onClick={() => {
              this.check('0');
            }}
          >
            我再想想
          </div>
          <div
            className="button-yes"
            onClick={() => {
              this.check('2');
            }}
          >
            去意已决
          </div>
        </div>
      );
    } else if (this.state.type === '4') {
      return (
        <div className="alert-btn">
          <div
            className="button button-default"
            onClick={() => {
              this.check('2');
            }}
          >
            {Lang.messages && Lang.messages.alert.ok}
          </div>
        </div>
      );
    } else if (this.state.type === '5') {
      return (
        <div className="alert-btn">
          <div
            className="button-no"
            onClick={() => {
              this.check('0');
            }}
          >
            更换卡片
          </div>
          <div
            className="button-yes"
            onClick={() => {
              this.check('2');
            }}
          >
            继续投保
          </div>
        </div>
      );
    } else if (this.state.type === '6') {
      return (
        <div className={`alert-btn ${this.state.definedClassName}`}>
          <div
            className="button-no"
            onClick={() => {
              this.check('0');
            }}
          >
            {this.state.cancel}
          </div>
          <div
            className="button-yes"
            onClick={() => {
              this.check('2');
            }}
          >
            {this.state.confirm}
          </div>
        </div>
      );
    }
  }
  render() {
    let { open, message, title } = this.state;
    //当窗口开启时 禁止回退前进操作
    if (open) {
      if (hasLocked == '0') {
        window.XBack.lockRoute();
        hasLocked = '1';
      }
    } else {
      if (hasLocked == '1') {
        if (this.state.lockState) {
          window.XBack.lockRoute(this.state.lockCallBack);
        } else {
          window.XBack.unLockRoute();
        }
        hasLocked = '0';
      }
    }
    //end by Sang 2019-12-19
    return (
      <div
        className={
          this.state.type == '6' && this.state.alertImg ? 'hasImg-style' : ''
        }
        style={{ display: open ? 'block' : 'none' }}
      >
        <div
          id="mask"
          onClick={() => {
            this.hide();
          }}
        ></div>
        <div className="popup-alert" ref="alert">
          {this.state.type == '6' && this.state.alertImg && (
            <div className="icon">
              <img src={this.state.alertImg} />
            </div>
          )}
          <h2
            className="alert-title"
            style={{
              display:
                title || (this.state.definedTitle && this.state.type == '6')
                  ? 'block'
                  : 'none',
            }}
          >
            {title || this.state.definedTitle}
          </h2>
          <div className="alert-content">
            <div>{message}</div>
          </div>
          {this.renderButton()}
        </div>
      </div>
    );
  }
}

let container = $('#alert-container');
if (container.length === 0) {
  container = $('<div id="alert-container">').appendTo(document.body);
}
let alert = ReactDOM.render(
  <Alert />,
  document.getElementById('alert-container')
);

export default alert;
