import { React } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';

const declarations = [
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
  '仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲仲',
];

const styles = {
  declarations: {
    padding: '0.125rem',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
  },
};

/**
 * 投保声明
 *
 * @class Declarations
 * @extends {AtomicComponentThing}
 */
class Declarations extends AtomicComponentThing {
  render() {
    return (
      <div className="declarations" style={styles.declarations}>
        {declarations &&
          declarations.map((decl, key) => {
            return (
              <p>
                {key + 1}、{decl}
              </p>
            );
          })}
      </div>
    );
  }
}

export default Declarations;
