// jquery needed
import { React, $ } from './3rd';
import { CURRENCY_SIGNS } from './consts';
import alert from '../component/both/alert/alert';
import Envs from './envs';
import toMd5 from './md5';
import markImg from '../images/wechat/mark.png';
import * as rasterizeHTML from 'rasterizehtml';
// The following source code are copied from Modernizr.

// Run through HTML5's new input types to see if the UA understands any.
//   This is put behind the tests runloop because it doesn't return a
//   true/false like all the other tests; instead, it returns an object
//   containing each input type with its corresponding true/false value

// Big thanks to @miketaylr for the html5 forms expertise. miketaylr.com/
const INPUT_TYPES =
  // prettier-ignore
  'search tel url email datetime date month week time datetime-local number range color'.split(' ');
const INPUT_SUPPORT = {};

/**
 * 开发环境微信公众号链接
 * @type {string}
 */
const DEV_WECHAT_OFFICIAL_URL =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4OTc3OTU1OA==&scene=126&bizpsid=0#wechat_redirect';

/**
 * UAT环境微信公众号链接
 * @type {string}
 */
const UAT_WECHAT_OFFICIAL_URL =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzUzNjcxMTEyMA==&scene=126&bizpsid=0#wechat_redirect';

/**
 * STA环境微信公众号链接
 * @type {string}
 */
const STA_WECHAT_OFFICIAL_URL =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4NjUzMjIyNw==&scene=126&bizpsid=0#wechat_redirect';

/**
 * STA，生产环境微信公众号链接
 * @type {string}
 */
const PROD_WECHAT_OFFICIAL_URL =
  'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU1MTcwODI3NA==&scene=126&bizpsid=0#wechat_redirect';

const DataURLsRegExp =
  /^(data:){1}[a-zA-Z]+(\/){1}[a-zA-Z0-9+-.]+(;base64,){1}.*/;

const createElement = function () {
  if (typeof document.createElement !== 'function') {
    // This is the case in IE7, where the type of createElement is "object".
    // For this reason, we cannot call apply() as Object is not a Function.
    return document.createElement(arguments[0]);
  } else {
    return document.createElement.apply(document, arguments);
  }
};

/**
 * 侦测浏览器是否支持给定的input type
 *
 * @param {[string]} types
 */
const detectInputTypes = function (types) {
  const docElement = document.documentElement;
  const inputElement = createElement('input');

  const smile = '1)';
  let defaultView = null;

  types.forEach((type) => {
    let inputType = type;
    inputElement.setAttribute('type', inputType);
    let bool = inputType.type !== 'text' && 'style' in inputElement;

    if (bool) {
      inputElement.value = smile;
      inputElement.style.cssText = 'position:absolute;visibility:hidden;';

      if (
        /^range$/.test(inputType) &&
        inputElement.style.WebkitAppearance !== undefined
      ) {
        docElement.appendChild(inputElement);
        defaultView = document.defaultView;

        // Safari 2-4 allows the smiley as a value, despite making a slider
        bool =
          defaultView.getComputedStyle &&
          defaultView.getComputedStyle(inputElement, null).WebkitAppearance !==
            'textfield' &&
          // Mobile android web browser has false positive, so must
          // check the height to see if the widget is actually there.
          inputElement.offsetHeight !== 0;

        docElement.removeChild(inputElement);
      } else if (/^(search|tel)$/.test(inputType)) {
        // Spec doesn't define any special parsing or detectable UI
        //   behaviors so we pass these through as true
        // Interestingly, opera fails the earlier test, so it doesn't
        //  even make it here.
      } else if (/^(url|email)$/.test(inputType)) {
        // Real url and email support comes with prebaked validation.
        bool =
          inputElement.checkValidity && inputElement.checkValidity() === false;
      } else {
        // If the upgraded input compontent rejects the :) text, we got a winner
        // eslint-disable-next-line
        bool = inputElement.value != smile;
      }
    }

    INPUT_SUPPORT[type] = !!bool;
  });
};
detectInputTypes(INPUT_TYPES);

// Modernizr input types detective ends here
let isClickLock = false;
class Utils {
  //登录用
  openId = '';
  //从哪儿进入我的保单用
  toGuarantee = '1';
  checkTransitionSupported() {
    if (typeof document === 'undefined') {
      console.error('Make sure document object existed!');
      return;
    }
    let TransitionEndEvent = {
      transition: 'transitionend',
      WebkitTransition: 'webkitTransitionEnd',
      MozTransition: 'transitionend',
      OTransition: 'oTransitionEnd otransitionend',
    };
    this.isTransitionSupported = false;
    let el = document.createElement('test');
    for (let name in TransitionEndEvent) {
      if (el.style[name] !== undefined) {
        this.isTransitionSupported = true;
        break;
      }
    }
  }
  // delegate for jquery transition end
  // for those browsers which doesn't support transition, invoke handler immediately or follow the delay properties
  // for those browsers which supports transition, add transitionend listener
  // options format as below,
  // {
  //		target: jquery object
  //		handler: event handler, pass target if transition not supported
  //		repeat: use 'on' when repeat is true, otherwise use 'one'
  //		delay: milliseconds when not support transition and invoke handler
  // }
  transitionend(options) {
    if (this.isTransitionSupported) {
      if (options.repeat) {
        options.target.on('transitionend', options.handler);
      } else {
        options.target.one('transitionend', options.handler);
      }
    } else if (options.delay) {
      setTimeout(() => {
        options.handler.call(
          options.target,
          $.Event('transitionend', {
            target: options.target,
          })
        );
      }, options.delay);
    } else {
      options.handler.call(
        options.target,
        $.Event('transitionend', {
          target: options.target,
        })
      );
    }
    return options.target;
  }
  formatAmount(currency) {
    return currency.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  getCurrencySign(currency) {
    return CURRENCY_SIGNS[currency];
  }
  hasOrientalChar(s) {
    if (!s) {
      return false;
    }
    return (
      -1 !==
      s.split('').findIndex((char) => {
        return char.charCodeAt(0) > 0x3040;
      })
    );
  }
  isBlank(s) {
    if (!s) {
      return true;
    } else {
      return s.trim().length === 0;
    }
  }
  checkDateOrder(startDate, endDate) {
    if (startDate && endDate) {
      startDate = startDate
        .replace(/\//g, '')
        .replace(' ', '')
        .replace(/:/g, '');
      endDate = endDate.replace(/\//g, '').replace(' ', '').replace(/:/g, '');
      return startDate * 1 < endDate * 1;
    } else {
      return false;
    }
  }
  //正确的身份证号截取0生日，1性别，2年龄
  getBirthAndGander(paper) {
    paper = paper.trim();
    var birthAndGander = [];
    if (paper != '') {
      var paperArr = paper.split('');
      if (paperArr.length == '18') {
        birthAndGander[0] =
          paperArr[6] +
          paperArr[7] +
          paperArr[8] +
          paperArr[9] +
          '-' +
          paperArr[10] +
          paperArr[11] +
          '-' +
          paperArr[12] +
          paperArr[13];
        if (paperArr[16] % 2 == '1') {
          birthAndGander[1] = 'M';
        } else {
          birthAndGander[1] = 'F';
        }
        //2018-02-05 add by susan  修改由身份证号获取年龄
        // birthAndGander[2] = paperArr[6]+paperArr[7]+paperArr[8]+paperArr[9];
        // var year = (new Date()).getFullYear();
        // birthAndGander[2] = parseInt(year) - parseInt(birthAndGander[2]);
        birthAndGander[2] =
          paperArr[6] +
          paperArr[7] +
          paperArr[8] +
          paperArr[9] +
          '-' +
          paperArr[10] +
          paperArr[11] +
          '-' +
          paperArr[12] +
          paperArr[13];
        birthAndGander[2] = this.age(birthAndGander[2]);
      } else if (paperArr.length == '15') {
        birthAndGander[0] =
          '19' +
          paperArr[6] +
          paperArr[7] +
          '-' +
          paperArr[8] +
          paperArr[9] +
          '-' +
          paperArr[10] +
          paperArr[11];
        if (paperArr[14] % 2 == '1') {
          birthAndGander[1] = 'M';
        } else {
          birthAndGander[1] = 'F';
        }
        //2018-02-05 add by susan  修改由身份证号获取年龄
        // birthAndGander[2] = "19"+paperArr[6]+paperArr[7];
        // var year = (new Date()).getFullYear();
        // birthAndGander[2] = parseInt(year) - parseInt(birthAndGander[2]);
        birthAndGander[2] =
          '19' +
          paperArr[6] +
          paperArr[7] +
          '-' +
          paperArr[8] +
          paperArr[9] +
          '-' +
          paperArr[10] +
          paperArr[11];
        birthAndGander[2] = this.age(birthAndGander[2]);
      }
    }
    return birthAndGander;
  }
  //检查email
  checkEmail(email) {
    if (!email) email = '';
    email = email.trim();
    let reg = /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/;
    return reg.test(email);
  }
  //检查手机号码
  checkPhone(number) {
    if (!number) number = '';
    number = number.trim();
    //2019-11-26 增加92 98号段
    //const REG = /^1[3|4|5|6|7|8|9]\d{9}$/;
    const REG = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^(92)\d{9}$)|(^(98)\d{9}$)/;
    return REG.test(number);
  }
  //add by wendy.gu 2018 Jan.6
  checkBank(number) {
    if (!number) number = '';
    number = number.replace(/\s/g, '');
    if (number.length < 15) {
      return false;
    } else {
      return true;
    }
  }
  //2019-12-16 15位转18位身份证号
  changeFivteenToEighteen = function (obj) {
    if (obj.length == '15') {
      // prettier-ignore
      let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      // prettier-ignore
      let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      // prettier-ignore
      let cardTemp = 0, i;
      obj = obj.substr(0, 6) + '19' + obj.substr(6, obj.length - 6);
      for (i = 0; i < 17; i++) {
        cardTemp += obj.substr(i, 1) * arrInt[i];
      }
      obj += arrCh[cardTemp % 11];
      return obj;
    }
    return obj;
  };
  //2019-12-16 校验位的检测
  isCardNo = function (obj) {
    //15位转18位
    obj = this.changeFivteenToEighteen(obj);
    let len = obj.length;
    if (len == '18') {
      // prettier-ignore
      let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      // prettier-ignore
      let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      // prettier-ignore
      let cardTemp = 0, i, valnum;
      for (i = 0; i < 17; i++) {
        cardTemp += obj.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[cardTemp % 11];
      if (valnum == obj.substr(17, 1)) {
        return true;
      }
      return false;
    }
    return false;
  };
  validateIDCard(number, type) {
    if (!number) number = '';
    var card = number.trim();
    var str = '身份证';
    //是否为空
    if (card === '') {
      return '请输入' + str + '号';
    }
    //校验长度，类型 校验位
    //2019-12-16身份证不验证大写X 小写 card.toUpperCase()
    if (this.isCardNo(card.toUpperCase()) === false) {
      console.log('校验位或长度不正确');
      return '您输入的' + str + '号码不正确，请重新输入';
    }
    //检查省份
    if (this.checkProvince(card.toUpperCase()) === false) {
      return '您输入的' + str + '号码不正确,请重新输入';
    }
    //校验生日
    if (this.checkBirthday(card.toUpperCase()) === false) {
      return '您输入的' + str + '号码生日不正确,请重新输入';
    }
  }
  //检查号码是否符合规范，包括长度，类型
  // isCardNo (card) {
  //     //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  //     var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/;
  //     if (reg.test(card) === false) {
  //         return false;
  //     }

  //     return true;
  // };
  //取身份证前两位,校验省份
  checkProvince(card) {
    // prettier-ignore
    var vcity = {
      11: '北京', 12: '天津', 13: '河北', 14: '山西', 15: '内蒙古',
      21: '辽宁', 22: '吉林', 23: '黑龙江', 31: '上海', 32: '江苏',
      33: '浙江', 34: '安徽', 35: '福建', 36: '江西', 37: '山东', 41: '河南',
      42: '湖北', 43: '湖南', 44: '广东', 45: '广西', 46: '海南', 50: '重庆',
      51: '四川', 52: '贵州', 53: '云南', 54: '西藏', 61: '陕西', 62: '甘肃',
      63: '青海', 64: '宁夏', 65: '新疆',
      71: '台湾',
      81: '香港', 82: '澳门',
      91: '国外',
    };
    var province = card.substr(0, 2);
    if (vcity[province] == undefined) {
      return false;
    }
    return true;
  }
  //检查生日是否正确
  checkBirthday(card) {
    let len = card.length;
    //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
    if (len == '15') {
      let re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
      let arr_data = card.match(re_fifteen);
      let year = arr_data[2];
      let month = arr_data[3];
      let day = arr_data[4];
      let birthday = new Date('19' + year + '/' + month + '/' + day);
      return this.verifyBirthday('19' + year, month, day, birthday);
    }
    //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
    if (len == '18') {
      let re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
      let arr_data = card.match(re_eighteen);
      let year = arr_data[2];
      let month = arr_data[3];
      let day = arr_data[4];
      let birthday = new Date(year + '/' + month + '/' + day);
      return this.verifyBirthday(year, month, day, birthday);
    }
    return false;
  }
  //校验日期
  verifyBirthday(year, month, day, birthday) {
    var now = new Date();
    var now_year = now.getFullYear();
    //年月日是否合理
    if (
      birthday.getFullYear() == year &&
      birthday.getMonth() + 1 == month &&
      birthday.getDate() == day
    ) {
      //判断年份的范围（0岁到105岁之间)
      var time = now_year - year;
      if (time <= 105 && now > birthday) {
        return true;
      }
      return false;
    }
    return false;
  }

  // logging
  debug() {
    if (process.env.REACT_APP_DEBUG_DISABLED === 'false') {
      console.log.apply(console, Array.prototype.slice.call(arguments));
    }
  }
  error() {
    console.error.apply(console, Array.prototype.slice.call(arguments));
  }

  // array
  toArray(value) {
    // eslint-disable-next-line
    return value == null ? [] : Array.isArray(value) ? value : [value];
  }
  appendToArray(value, target) {
    if (target == null) {
      return this.toArray(value);
    } else if (value == null) {
      return this.toArray(target);
    } else {
      const targetArray = this.toArray(target);
      const targetValue = this.toArray(value);
      return targetArray.concat(targetValue);
    }
  }

  // url
  encodeCurrentURI(queryString) {
    if (queryString) {
      return this.encodeURI(`${window.location}?${queryString}`);
    } else {
      return this.encodeURI(window.location);
    }
  }
  encodeURI(uri) {
    return window.encodeURIComponent(uri);
  }
  //add by wendy.gu 2017 Dec.21
  decodeURI(uri) {
    return window.decodeURIComponent(uri);
  }
  //微信公众号的固定Code，目前可以配置为wxfac0fe1385f3ef25 add by wendy.gu 2018 Jan.4
  getAppId() {
    return 'wxfac0fe1385f3ef25';
  }
  getAppIdByDev() {
    return 'wxe8f2b0c950e90bff';
  }
  getAppIdByUAT() {
    //北京人寿公众号迁移 update by wendy.gu 2018 Mar.21
    // return 'wx16c09fbe260da8eb'
    return 'wx34a674c56515ed9a';
  }
  getAppIdByProd() {
    //北京人寿公众号迁移 update by wendy.gu 2018 Mar.21
    // return 'wx16c09fbe260da8eb'
    return 'wx5d3d9534d9b0321d';
  }

  getAppIdBySta() {
    return 'wx385f9f15642709dd';
  }

  /**
   * 获取微信应用id
   */
  getWeChatAppId() {
    if (Envs.is_b2c_dev_test()) {
      // 开发环境
      return this.getAppIdByDev();
    } else if (Envs.is_b2c_uat()) {
      // UAT环境
      return this.getAppIdByUAT();
    } else if (Envs.is_b2c_sta_test()) {
      // STA环境
      return this.getAppIdBySta();
    } else {
      // 默认生产环境
      return this.getAppIdByProd();
    }
  }

  /**
   * 获取微信公众号链接
   */
  getWeChatLink() {
    if (Envs.is_b2c_dev_test()) {
      // 开发环境
      return DEV_WECHAT_OFFICIAL_URL;
    } else if (Envs.is_b2c_uat()) {
      // UAT环境
      return UAT_WECHAT_OFFICIAL_URL;
    } else if (Envs.is_b2c_sta_test()) {
      // STA环境
      return STA_WECHAT_OFFICIAL_URL;
    } else {
      // 默认生产环境
      return PROD_WECHAT_OFFICIAL_URL;
    }
  }

  /**
   * 获取微信公众号二维码
   */
  getWeChatQRCode() {
    if (Envs.is_b2c_dev_test()) {
      // 开发环境
      return require('../images/wechat/qrcode_dev.jpg');
    } else if (Envs.is_b2c_uat()) {
      // UAT环境
      return require('../images/wechat/qrcode_uat.jpg');
    } else if (Envs.is_b2c_sta_test()) {
      // STA环境
      return require('../images/wechat/qrcode_sta.jpg');
    } else {
      // 默认生产环境
      return require('../images/wechat/qrcode_prod.jpg');
    }
  }

  getQueryStringObject() {
    const search = window.location.search;
    if (search && search !== '?') {
      return $.deparam(window.decodeURIComponent(search.substring(1)));
    } else {
      return {};
    }
  }

  //获取自动关注biz的code  -dev
  getBiz() {
    return 'MzUzOTU0MTg2MQ==';
  }
  getBizByUAT() {
    return 'MzU2NTMwODAxNQ==';
  }
  /**
   * 生成文件
   * TODO: 目前支持csv及txt格式，后续如果需要生成其他格式文件，例如图片，再进行丰富及扩展
   *
   * @param fileName：文件名称
   * @param fileType：文件类型，例如'text/csv'
   * @param fileContent: 符合文件类型的文件内容
   */
  downloadFile(fileName, fileType, fileContent) {
    if (window.navigator.msSaveOrOpenBlob) {
      // if browser is IE
      fileContent = '\ufeff' + fileContent;
      var IEBlob = new Blob([decodeURIComponent(encodeURI(fileContent))], {
        type: fileType + ';charset=utf-8;',
      });
      navigator.msSaveBlob(IEBlob, fileName);
    } else {
      var link = document.createElement('a');
      // 解决大文件下载失败
      var blob = new Blob(['\ufeff' + fileContent], { type: fileType });
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
    }
  }

  // 通知上层组件, 即将跳到详细页面
  notifyParentShouldGoToDetail(who) {
    if (!who.props.parent) {
      console.warn('No parent found when should goto detail.', who);
      return;
    }
    if (!who.props.parent.shouldGoToDetail) {
      console.warn(
        'Parent has no #shouldGoToDetail function.',
        who,
        who.props.parent
      );
      return;
    }

    who.props.parent.shouldGoToDetail(who);
  }
  /**
   * 计算年龄
   * @param {string} date
   */
  age(date) {
    var today = new Date();
    var birth = new Date(date);
    var age = today.getFullYear() - birth.getFullYear();
    var m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }

  // 环境侦测
  /**
   * 是否支持触摸
   */
  touchable() {
    // copied from Modernizr
    return (
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch)
    );
  }
  /**
   * 是否支持指定的input类型
   *
   * @param {string} type
   */
  isInputSupported(type) {
    return INPUT_SUPPORT[type];
  }
  /*格式化日期*/
  formatDate(date, fmt = 'yyyy/MM/dd hh:mm:ss') {
    //author: meizz
    let o = {
      'M+': date.getMonth() + 1, //月份
      'd+': date.getDate(), //日
      'h+': date.getHours(), //小时
      'm+': date.getMinutes(), //分
      's+': date.getSeconds(), //秒
      'q+': Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp('(' + k + ')').test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
    return fmt;
  }
  /**
   * 下划线转驼峰
   * @param {string} str
   */
  toCamelCase(str) {
    // eslint-disable-next-line
    return str.replace(/\_+[a-zA-Z]/g, (str, index) =>
      index ? str.substr(-1).toUpperCase() : str
    );
  }
  //图片(base64) 转canvas压缩
  compressImg(camera, callBack) {
    let self = this;
    let maxSize = 200 * 1024; //200K
    if (camera.length < maxSize) {
      //如果图片小于200K 直接上传
      if (callBack) {
        callBack(camera);
        return;
      }
    }
    let img = new Image();
    img.src = camera;
    //img.crossOrigin = "Anonymous";
    if (img.complete) {
      // alert('from:complete : width:'+img.width+',height:'+img.height);
      let data = self.compress(img); //图片首先进行压缩
      if (callBack) {
        callBack(data);
        img = null;
      }
    } else {
      // 加载完成执行
      img.onload = function () {
        // alert('from:onload : width:'+img.width+',height:'+img.height);
        let data = self.compress(img); //图片首先进行压缩
        if (callBack) {
          callBack(data);
          img = null;
        }
      };
    }
  }
  compress(img, multi) {
    try {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let height = (canvas.height = img.height); //该值影响缩放后图片的大小
      let width = (canvas.width = img.width);

      let ratio = (width * height) / 500000;
      if (ratio > 1) {
        // alert.message("500000");
        ratio = Math.sqrt(ratio);
        width /= ratio;
        height /= ratio;
      } else {
        ratio = 1;
      }
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = '#000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, width, height);
      //将原来图片的质量压缩到原先的0.5倍。
      let data = canvas.toDataURL('image/jpeg', 0.5); //data url的形式
      if (multi) {
        data = canvas.toDataURL('image/jpeg', multi); //data url的形式
      }
      canvas.width = canvas.height = 0;
      return data;
    } catch (e) {
      return '';
    }
  }

  compress_new(img, multiple, width, height) {
    if (!multiple) {
      multiple = 0.1;
    }
    try {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.height = height; //该值影响缩放后图片的大小
      canvas.width = width;
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);
      //将原来图片的质量压缩到原先的0.1倍。
      let data = canvas.toDataURL('image/jpeg', multiple); //data url的形式
      console.log(data);
      ctx = null;
      canvas = null;
      return data;
    } catch (e) {
      return '';
    }
  }

  /**
   * 计算文件大小
   * @param data Data URLs 或 base64编码二进制数据
   * @returns {number} 文件大小，单位：字节
   */
  calculateSize(data) {
    let base64str;
    if (data) {
      if (data.startsWith('data')) {
        base64str = data.split(',')[1];
      } else {
        base64str = data;
      }
      let equalIndex = base64str.indexOf('=');
      if (equalIndex > 0) {
        base64str = base64str.substring(0, equalIndex);
      }
      let strLength = base64str.length;
      return strLength - (strLength / 8) * 2;
    }
    return -1;
  }

  /**
   * 将Data URLs转换为Blob对象
   * @param data Data URLs
   * @returns {Blob}
   */
  dataURLsToBlob(data) {
    if (!DataURLsRegExp.test(data)) {
      return null;
    }
    let temp = data.split(',');
    // 获取MIME类型
    let startIndex = temp[0].indexOf(':') + 1;
    let endIndex = temp[0].indexOf(';');
    let mimeType = temp[0].substring(startIndex, endIndex);
    // 将base64字符串转换为byte
    let bytes = window.atob(temp[1]);
    // 处理异常，将ASCII码小于0的转换为大于0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i);
    }
    // 返回Blob对象
    return new Blob([ab], { type: mimeType });
  }

  // 身份证校验是否合法
  verifyChineseIDCard(code) {
    var city = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外 ',
    };
    let tip;
    var pass = true;
    if (
      !code ||
      // prettier-ignore
      !/(^\d{6}(\d{2})?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$)/i.test(code)
    ) {
      tip = '身份证号格式错误';
      pass = false;
    } else if (!city[code.substr(0, 2)]) {
      tip = '地址编码错误';
      pass = false;
    } else {
      // 18位身份证需要验证最后一位校验位
      if (code.length === 18) {
        code = code.split('');
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        // 校验位
        var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        var sum = 0;
        var ai = 0;
        var wi = 0;
        for (var i = 0; i < 17; i++) {
          ai = code[i];
          wi = factor[i];
          sum += ai * wi;
        }
        // var last = parity[sum % 11]
        if (parity[sum % 11] !== code[17]) {
          tip = '校验位错误';
          pass = false;
        }
      }
    }
    // if(!pass) console.log(tip);
    return pass;
  }

  // 通过身份证号获取生日
  getBirth(str) {
    let birth;
    if (str.length === 18) {
      birth =
        str.substr(6, 4) + '-' + str.substr(10, 2) + '-' + str.substr(12, 2);
    } else {
      birth =
        '19' +
        str.substr(6, 2) +
        '-' +
        str.substr(8, 2) +
        '-' +
        str.substr(10, 2);
    }
    return birth;
  }

  // 通过身份证号获取生日
  getBirthFromChineseIDCard(str) {
    if (str.length === 18) {
      let birth =
        str.substr(6, 4) + '-' + str.substr(10, 2) + '-' + str.substr(12, 2);
      return birth;
    } else {
      let birth =
        '19' +
        str.substr(6, 2) +
        '-' +
        str.substr(8, 2) +
        '-' +
        str.substr(10, 2);
      return birth;
    }
  }

  // 通过身份证号获取性别
  getGenderFromChineseIDCard(str) {
    var GenderCode = str.length === 18 ? str[16] : str[14];
    if (GenderCode % 2) {
      return 'M'; // 男性
    } else {
      return 'F'; // 女性
    }
  }
  //通过生日计算出生天数
  getDaysFromBirthday(date) {
    var today = new Date();
    var birth = new Date(date);
    var diffDays = parseInt((today - birth) / (1000 * 60 * 60 * 24), 10);
    return diffDays;
  }
  getOriginFormatToday(sTime) {
    if (!sTime) {
      sTime = 0;
    }
    let seconds = parseInt(new Date() - sTime);
    let date = new Date(seconds);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    return `${year}-${month}-${day}`;
  }
  getOriginFormatFrom(long) {
    let date = new Date();
    let year = date.getFullYear() - long;
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (day < 10) day = '0' + day;
    if (month < 10) day = '0' + month;
    return `${year}-${month}-${day}`;
  }
  //传入两个日期，比较时间先后,date1为选择的日期，date2为当前日期,若date1时间在date2之前，则返回false
  getCompareDate(date1, date2) {
    let year1 = date1.substr(0, 4);
    let year2 = date2.substr(0, 4);
    let month1 = date1.substr(5, 2);
    let month2 = date2.substr(5, 2);
    let day1 = date1.substr(8, 2);
    let day2 = date2.substr(8, 2);
    if (year1 < year2) {
      return false;
    } else if (year1 == year2) {
      if (month1 < month2) {
        return false;
      } else if (month1 == month2) {
        if (day1 < day2) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  //验证密码
  checkPassword(str) {
    var reg =
      /^(?![A-Za-z]+$)(?![A-Z\\d]+$)(?![A-Z\\W]+$)(?![a-z\\d]+$)(?![a-z\\W]+$)(?![\\d\\W]+$)\\S{8,20}$/;
    if (reg.test(str)) {
      return true;
    }
    return false;
  }
  doEncode(str) {
    var destStr = this.base64encode(this.utf16to8(str));
    return destStr;
  }
  doDecode(str) {
    var destStr = this.utf8to16(this.base64decode(str));
    return destStr;
  }
  /**
   * base64加密解密
   *
   */
  base64EncodeChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  // prettier-ignore
  base64DecodeChars = new Array(
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 62, -1, -1, -1, 63,
    52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1, -1, -1, -1, -1, -1,
    -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
    15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, -1, -1, -1, -1, -1,
    -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, -1, -1, -1, -1, -1);

  base64encode(str) {
    var out, i, len;
    var c1, c2, c3;

    len = str.length;
    i = 0;
    out = '';
    while (i < len) {
      c1 = str.charCodeAt(i++) & 0xff;
      if (i === len) {
        out += this.base64EncodeChars.charAt(c1 >> 2);
        out += this.base64EncodeChars.charAt((c1 & 0x3) << 4);
        out += '==';
        break;
      }
      c2 = str.charCodeAt(i++);
      if (i === len) {
        out += this.base64EncodeChars.charAt(c1 >> 2);
        out += this.base64EncodeChars.charAt(
          ((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4)
        );
        out += this.base64EncodeChars.charAt((c2 & 0xf) << 2);
        out += '=';
        break;
      }
      c3 = str.charCodeAt(i++);
      out += this.base64EncodeChars.charAt(c1 >> 2);
      out += this.base64EncodeChars.charAt(
        ((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4)
      );
      out += this.base64EncodeChars.charAt(
        ((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6)
      );
      out += this.base64EncodeChars.charAt(c3 & 0x3f);
    }
    return out;
  }

  base64decode(str) {
    var c1, c2, c3, c4;
    var i, len, out;

    len = str.length;
    i = 0;
    out = '';
    while (i < len) {
      /* c1 */
      do {
        c1 = this.base64DecodeChars[str.charCodeAt(i++) & 0xff];
      } while (i < len && c1 === -1);
      if (c1 === -1) break;

      /* c2 */
      do {
        c2 = this.base64DecodeChars[str.charCodeAt(i++) & 0xff];
      } while (i < len && c2 === -1);
      if (c2 === -1) break;

      out += String.fromCharCode((c1 << 2) | ((c2 & 0x30) >> 4));

      /* c3 */
      do {
        c3 = str.charCodeAt(i++) & 0xff;
        if (c3 === 61) return out;
        c3 = utils.base64DecodeChars[c3];
      } while (i < len && c3 === -1);
      if (c3 === -1) break;

      out += String.fromCharCode(((c2 & 0xf) << 4) | ((c3 & 0x3c) >> 2));

      /* c4 */
      do {
        c4 = str.charCodeAt(i++) & 0xff;
        if (c4 === 61) return out;
        c4 = utils.base64DecodeChars[c4];
      } while (i < len && c4 === -1);
      if (c4 === -1) break;
      out += String.fromCharCode(((c3 & 0x03) << 6) | c4);
    }
    return out;
  }

  utf16to8(str) {
    if (!str) str = '';
    var out, i, len, c;

    out = '';
    len = str.length;
    for (i = 0; i < len; i++) {
      c = str.charCodeAt(i);
      if (c >= 0x0001 && c <= 0x007f) {
        out += str.charAt(i);
      } else if (c > 0x07ff) {
        out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
        out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
        out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
      } else {
        out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
        out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
      }
    }
    return out;
  }

  utf8to16(str) {
    var out, i, len, c;
    var char2, char3;

    out = '';
    len = str.length;
    i = 0;
    while (i < len) {
      c = str.charCodeAt(i++);
      switch (c >> 4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += str.charAt(i - 1);
          break;
        case 12:
        case 13:
          // 110x xxxx   10xx xxxx
          char2 = str.charCodeAt(i++);
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = str.charCodeAt(i++);
          char3 = str.charCodeAt(i++);
          out += String.fromCharCode(
            ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
          );
          break;
        default:
          break;
      }
    }

    return out;
  }
  changeCertiType(certiType) {
    let newCertiType = null;
    if (certiType === 1) {
      //身份证
      newCertiType = '0';
    } else if (certiType === 2) {
      //户口本
      newCertiType = '3';
    } else if (certiType === 4) {
      //军官证
      newCertiType = '1';
    } else if (certiType === 7) {
      //中国护照
      newCertiType = '2';
    } else if (certiType === 13) {
      //出生证明 naiting 20170529
      newCertiType = '0';
    }
    return newCertiType;
  }
  clickLock() {
    setTimeout(function () {
      isClickLock = false;
    }, 300);
    if (isClickLock) {
      return true;
    } else {
      isClickLock = true;
      return false;
    }
  }
  //获取locaton传入属性
  getUrlProps() {
    let args = {};
    let query = window.location.search.substring(1);
    let pairs = query.split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pos = pairs[i].indexOf('=');
      if (pos === -1) continue;
      let name = pairs[i].substring(0, pos);
      let value = pairs[i].substring(pos + 1);
      value = decodeURIComponent(value);
      args[name] = value;
    }
    return args;
  }
  //获取+号后的数据
  getSpecialProps() {
    let args = {};
    let query = window.location.search.substring(1);
    let pairs = query.split('+');
    for (let i = 0; i < pairs.length; i++) {
      let pos = pairs[i].indexOf('=');
      if (pos === -1) continue;
      let name = pairs[i].substring(0, pos);
      let value = pairs[i].substring(pos + 1);
      value = decodeURIComponent(value);
      args[name] = value;
    }
    return args;
  }
  //是否第三方对接
  isThirdParty() {
    let { thirdPartyReturnUrl } = this.getUrlProps();
    if (thirdPartyReturnUrl) return true;
    else return false;
  }
  getParameter(url, param) {
    let iLen = param.length;
    let iStart = url.indexOf(param);
    if (iStart === -1) {
      return '';
    }
    iStart += iLen + 1;
    let iEnd = url.indexOf('&', iStart);
    let isEnd2 = url.indexOf('#', iStart);
    if (iEnd === -1 && isEnd2 === -1) {
      return url.substring(iStart);
    } else if (iEnd !== -1) {
      return url.substring(iStart, iEnd);
    } else if (isEnd2 !== -1) {
      return url.substring(iStart, isEnd2);
    }
    return url.substring(iStart, iEnd);
  }
  findEvent(eventName) {
    if (window.eventEmitter._events[eventName]) {
      return true;
    } else {
      return false;
    }
  }
  //监听指定的事件
  installToEventEmitter(options) {
    if (options && options.event && !this.findEvent([options.event])) {
      // console.log("监听指定的事件"+options.event);
      window.eventEmitter.on(options.event, options.func);
      // console.log(window.eventEmitter);
    }
  }
  // 取消监听指定的事件
  uninstallFromEventEmitter(options) {
    if (options && options.event && this.findEvent([options.event])) {
      // console.log("取消监听指定的事件"+options.event);
      window.eventEmitter.removeListener(options.event, options.func);
      // console.log(window.eventEmitter);
    }
  }
  /**
   * 格式化显示时间
   * time
   * format  如"yyyy.MM.dd HH:mm"   "yyyy.MM.dd"
   */
  formattime(time, format) {
    if ((!time || isNaN(time)) && time != 0) return '';
    let t = new Date(time);
    let tf = function (i) {
      return (i < 10 ? '0' : '') + i;
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
      switch (a) {
        case 'yyyy':
          return tf(t.getFullYear());
        case 'MM':
          return tf(t.getMonth() + 1);
        case 'mm':
          return tf(t.getMinutes());
        case 'dd':
          return tf(t.getDate());
        case 'HH':
          return tf(t.getHours());
        case 'ss':
          return tf(t.getSeconds());
      }
    });
  }
  /**
   * 华创原生分享
   * obj{
   * 	title: 分享标题
   *  desc:  分享描述
   *  shareUrl: 分享链接
   * }
   */
  weixinClick(obj) {
    // let { title, desc, shareUrl } = obj;
    // let success = (result) => {
    //   alert('分享成功');
    // };
    // let error = (result) => {
    //   alert(result);
    // };
    // //todo del
    // navigator.weixin.share(
    //   {
    //     message: {
    //       title: title,
    //       description: desc,
    //       mediaTagName: 'Media Tag Name(optional)',
    //       thumb: 'http://www.izhb.com/img/libaoliuxue.jpg',
    //       media: {
    //         //type:navigator.weixin.Type.WEBPAGE,
    //         webpageUrl: shareUrl,
    //       },
    //     },
    //     scene: navigator.weixin.Scene.SESSION,
    //   },
    //   success,
    //   error
    // );
  }
  //json排序方法
  jsonSort(jsonObj) {
    let arr = [];
    for (let key in jsonObj) {
      arr.push(key);
    }
    arr.sort();
    let str = '';
    let new_json = {};
    for (let i in arr) {
      new_json[arr[i]] = jsonObj[arr[i]];
    }
    return new_json;
  }
  //spk  安全接口   转base 64
  makeSafeTokenUrl(json) {
    //md5 加
    json = this.jsonSort(json);
    //let timestamp = Date.parse(new Date());
    let token = localStorage.getItem('safeToken');
    let arr_json = [];
    $.each(json, function (k, v) {
      arr_json.push(v);
    });
    let Md5Token = toMd5.init(arr_json.join('') + token);
    json['token'] = Md5Token;
    let _keyStr =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let input = json; //{ "timestamp": timestamp, "token": Md5Token };
    let string = JSON.stringify(input);
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    // let string = JSON.stringify(input);
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    input = utftext;
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4);
    }
    return output;
  }
  //end

  toBase64(input) {
    let _keyStr =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    let string = JSON.stringify(input);
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    input = utftext;
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4);
    }
    return output;
  }

  //回溯开始
  recallStart(recallJson) {
    if (!window.btm) {
      return;
    }
    let sKey = 'bjrs123456'; //测试密钥
    let host = window.location.host;
    if (host == 'b2c.beijinglife.com.cn') {
      sKey = 'bjrs@77!09';
    } else {
      sKey = 'bjrs123456';
    }
    let trace = Date.parse(new Date());
    if (localStorage.getItem('trace')) {
      trace = localStorage.getItem('trace');
    }
    if (recallJson['trace']) {
      trace = recallJson['trace'];
    }
    localStorage.setItem('trace', trace);
    window.btm
      .init({
        key: sKey, //秘钥
        companyCode: '0007', //公司代码
        crgCode: recallJson['crgCode'] || '', //组织代码
        trace: trace, //回溯码
        camera: recallJson['camera'] || 1, //镜头号
        channelCode: recallJson['channelCode'] || '', //渠道
        platformCode: recallJson['platformCode'] || '', //出单平台
        insurPeriod: recallJson['insurPeriod'] || '', //保险期间开始时间
        insurPeriodEnd: recallJson['insurPeriodEnd'] || '', //保险期间结束时间
        traceBusinessType: recallJson['traceBusinessType'] || '003', //回溯业务类型
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //回溯结束
  recallEnd(self, path) {
    if (!window.btm) {
      return;
    }
    window.btm
      .stop()
      .then(() => {
        if (path) {
          localStorage.removeItem('trace');
          self.pushRedirect(path);
        }
        // 结束成功
        console.log('结束成功');
        window.btm.trace
          .complete()
          .then(() => {
            console.log('回溯码======>>>>>');
          })
          .catch((error) => {
            console.log('回溯码失败======>>>>>');
          });
      })
      .catch((error) => {
        // 结束失败
        console.log('结束失败');
      });
  }

  refreshPage(self, securityType) {
    let reload = sessionStorage.getItem('reload');
    if (reload && reload == window.location.href) {
      alert.message('您刷新当前了页面，请重新操作', () => {
        sessionStorage.removeItem('reload');
        let listFrom = localStorage.getItem('list-from');
        if (listFrom == 'my-guarantee') {
          self.pushRedirect(Envs.PATH.WECHAT_MY_GUARANTEE);
        } else {
          if (securityType) {
            self.pushRedirect(
              Envs.PATH.WECHAT_MY_INTELLIGENT_SECURITY_LIST.replace(
                ':securityType',
                securityType
              )
            );
          } else {
            self.pushRedirect(Envs.PATH.WECHAT_MY);
          }
        }
        //window.location.replace(url+toPage)
      });
    } else {
      sessionStorage.setItem('reload', window.location.href);
    }
  }

  /**
   * 去除空格方法
   */
  removeSpace(data) {
    return data.replace(/^\s+|\s+$/g, '');
  }
  //字符长度
  getLen(val) {
    var len = 0;
    for (var i = 0; i < val.length; i++) {
      if (val[i].match(/[^\x00-\xff]/gi) != null)
        //全角
        len += 2;
      else len += 1;
    }
    return len;
  }

  //书字转中文

  sectionToChinese(section) {
    // prettier-ignore
    var chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    // prettier-ignore
    var chnUnitChar = ['', '十', '百', '千', '万', '亿', '万亿', '亿亿'];
    // prettier-ignore
    var strIns = '', chnStr = '';
    var unitPos = 0;
    var zero = true;
    while (section > 0) {
      var v = section % 10;
      if (v === 0) {
        if (!zero) {
          zero = true;
          chnStr = chnNumChar[v] + chnStr;
        }
      } else {
        zero = false;
        strIns = chnNumChar[v];
        strIns += chnUnitChar[unitPos];
        chnStr = strIns + chnStr;
      }
      unitPos++;
      section = Math.floor(section / 10);
    }
    console.log(chnStr);
    return chnStr;
  }

  //判断页面从哪个列表跳转
  listFrom(self, securityType) {
    let listFrom = localStorage.getItem('list-from');
    if (listFrom == 'my-guarantee') {
      self.pushRedirect(Envs.PATH.WECHAT_MY_GUARANTEE);
    } else {
      self.replaceRedirect(
        Envs.PATH.WECHAT_MY_INTELLIGENT_SECURITY_LIST.replace(
          ':securityType',
          securityType
        )
      );
    }
  }
  //截图
  getSnapShot(cssId, callBack) {
    let dom = document.getElementById('root').innerHTML;
    let cssAT = '';
    let AlertDOM = document.getElementById('alert-container').innerHTML;
    cssId.forEach((item) => {
      cssAT += item;
    });
    // $("style").each(()=>{
    // 	$(this).html("");
    // })

    // $("head").append(`<style>${cssAT}</style>`);
    // if(callBack && typeof callBack =='function' ) callBack("111") ;
    // return
    let DOM = `<style>${cssAT}</style>${dom}${AlertDOM}`;
    rasterizeHTML
      .drawHTML(DOM, { width: document.body.clientWidth, height: 812 })
      .then((res) => {
        // document.body.innerHTML = '页面截图已生成：<br/>' + res.image.outerHTML
        // return;
        //转base64
        let svgData = this.doEncode(res.svg);
        // let img = res.image;
        // let canvas = document.createElement("canvas");
        // canvas.width = img.width;
        // canvas.height = img.height;
        // let ctx = canvas.getContext("2d");
        // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        // let dataURL = canvas.toDataURL();
        if (callBack && typeof callBack == 'function') callBack(svgData);
      });
  }

  //创建底层画布
  makeBaseMark(data, wData, callBack) {
    let imgB = new Image();
    imgB.src = data;
    let imgW = new Image();
    imgW.src = wData;
    let pro1 = new Promise((resolve, reject) => {
      imgW.onload = function () {
        resolve();
      };
    });
    let pro2 = new Promise((resolve, reject) => {
      imgB.onload = function () {
        resolve();
      };
    });
    Promise.all([pro1, pro2]).then(() => {
      let imgBW = imgB.width;
      let imgBH = imgB.height;
      let imgWW = imgB.width / 1.5;
      let imgWH = (imgBW * imgW.height) / imgW.width / 1.5;
      let canvasB = document.createElement('canvas');
      let context = canvasB.getContext('2d');
      canvasB.width = imgBW;
      canvasB.height = imgBH;
      //身份证画布：
      context.drawImage(imgB, 0, 0, imgBW, imgBH);
      context.drawImage(
        imgW,
        (imgBW - imgWW) / 2,
        (imgBH - imgWH) / 2,
        imgWW,
        imgWH
      );
      let fData = canvasB.toDataURL('image/png', 0.8);
      // console.log(fData)
      callBack && typeof callBack == 'function' && callBack(fData);
    });
  }
  //水印
  waterMark(data, callBack, b) {
    b = b ? b : 1;
    let img = new Image();
    img.src = markImg;
    let self = this;
    img.onload = function () {
      let imgW = img.width / b;
      let imgH = img.height / b;
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      canvas.width = imgW; // 设置宽度
      canvas.height = imgH; // 设置高度
      //身份证画布：
      context.drawImage(img, 0, 0, imgW, imgH);
      // 绘制水印
      let date = new Date();
      let mon = date.getMonth() * 1 + 1;
      let day = date.getDate();
      let year = date.getFullYear();
      context.font = `italic ${20 / b}px microsoft yahei`;
      context.fillStyle = '#af3626'; // text color
      context.fillText(
        `有效期：${year}-${mon}-${day}`,
        imgW / 2 - 110 / b,
        imgH / 2 + 58 / b
      );
      let wData = canvas.toDataURL('image/png', 1); //data url的形式
      self.makeBaseMark(data, wData, callBack);
    };
  }
  //上传
  uploadRedirect(p1, p2, callBack, parent) {
    //上传节点接口
    if (!p2) {
      this.uploadP1(p1, p2, parent, callBack);
    } else {
      this.uploadP1(p1, p2, parent);
      this.uploadP2(p1, p2, parent, callBack);
    }
  }
  //上传p1
  uploadP1(p1, p2, parent, callBack) {
    let mobile = '';
    if (p1.visitType == 'SV') {
      mobile = JSON.parse(sessionStorage.getItem('OR_USER')).mobile;
    } else {
      let account = Envs.findAccount();
      mobile = account.mobile;
    }

    let url1 = '/rest/v1/visit/createOperateLocus';
    //FF FS
    let orderNo = '';
    if (p1.operateType == 'FF' || p1.operateType == 'FS') {
      orderNo = sessionStorage.getItem('OvFaceOrderNo');
    }
    let opt1 = {
      visitType: p1.visitType,
      operateType: p1.operateType,
      listNo: sessionStorage.getItem('OVlistNo'),
      detailNo: sessionStorage.getItem('OVdetailNo'),
      policyNo: p1.policyNo,
      phoneNo: mobile,
      orderNo,
    };
    parent
      .doPost(url1, opt1, { ignoreAuth: true })
      .done((res) => {
        if (callBack && typeof callBack == 'function') callBack();
      })
      .fail((e) => {
        if (callBack && typeof callBack == 'function') callBack();
      });
  }
  //上传p2
  uploadP2(p1, p2, parent, callBack) {
    this.safeToken.call(parent);
    if (p2 && p2.document) {
      //获取token
      let timestamp = Date.parse(new Date());
      let json = { timestamp: timestamp };
      let tokenBase64 = this.makeSafeTokenUrl(json);

      //上传图像
      let url2 = `/rest/v1/document/file/uploadFile?bjlifeToken=${tokenBase64}`;
      // if(!isRepeat){
      // 	if (p2.document.indexOf("image/jpeg") > 0) {
      // 		p2.document = p2.document.substring(23, p2.document.length);
      // 	} else {
      // 		p2.document = p2.document.substring(22, p2.document.length);
      // 	}
      // }

      let opt2 = {
        vendorCode: 'BJLIFE',
        documentType: '-27.3',
        document: p2.document,
        isFront: 'Y',
        customerType: 0,
        businessNo: `${p1.visitType}_${p1.operateType}_${sessionStorage.getItem(
          'OVlistNo'
        )}_${sessionStorage.getItem('OVdetailNo')}`,
      };
      parent
        .doPost(url2, opt2, { ignoreAuth: true })
        .done((res) => {
          if (callBack && typeof callBack == 'function') callBack();
        })
        .fail((e) => {
          if (e.responseText) {
            let eRes = JSON.parse(e.responseText).errors[0].code;
            if (eRes === 'BJLIFE_TOKEN_IS_NOT_VALID') {
              localStorage.removeItem('safeToken');
              localStorage.removeItem('tokenLife');
              this.uploadP2(p1, p2, parent);
            }
          }
        });
    }
  }
  safeToken() {
    let time = Date.parse(new Date());
    let tokenTime = localStorage.getItem('tokenLife');
    if (
      !tokenTime ||
      (time - tokenTime) / 1000 > 1500 ||
      (tokenTime - time) / 1000 > 1500
    ) {
      // PageLoading.start()
      let data = '';
      this.doPost('/rest/v1/token/document', data, {
        async: false,
        ignoreAuth: true,
      }).done((res) => {
        if (res.returnCode == 'RC-00001') {
          let token = res.body.accessToken;
          localStorage.setItem('safeToken', token);
          let timeNow = Date.parse(new Date());
          localStorage.setItem('tokenLife', timeNow);
          // if (callBack && typeof callBack == "function") callBack();
        }
        // PageLoading.end()
      });
    } else {
      // if (callBack && typeof callBack == "function") callBack();
    }
  }
  caNo() {
    if (Envs.is_b2c_dev_test()) {
      return '999999';
    } else if (Envs.is_b2c_uat()) {
      // UAT环境
      return '999999';
    } else if (Envs.is_b2c_sta_test()) {
      // STA环境
      return '999999';
    } else {
      // 默认生产环境
      return '30010371';
    }
  }
  //cos对象地址
  getCosHost() {
    if (Envs.is_b2c_dev_test()) {
      // 开发环境
      return 'https://apptest01-1253531169.cos.ap-shanghai.myqcloud.com';
    } else if (Envs.is_b2c_uat()) {
      // UAT环境
      return 'https://apptest01-1253531169.cos.ap-shanghai.myqcloud.com';
    } else if (Envs.is_b2c_sta_test()) {
      // STA环境
      return 'https://apptest01-1253531169.cos.ap-shanghai.myqcloud.com';
    } else {
      // 默认生产环境
      return 'https://app-1253531169.cos.ap-shanghai.myqcloud.com';
    }
  }
}

let utils = new Utils();
utils.checkTransitionSupported();

export default utils;
