import { $ } from './3rd';

import Envs from './envs';
import Storage from './storage';

/**
 * TODO: - Deprecated and will be removed in a future release
 * @deprecated
 * ajax, use jquery
 */
class Ajax {
  doAll() {
    const requests = Array.prototype.slice.call(arguments);
    return $.when.apply($, requests);
  }

  doPost(url, data, options) {
    options = options || {};
    options.method = 'POST';
    options.data = data ? JSON.stringify(data) : undefined;
    return this.ajax(url, data, options);
  }

  doGet(url, data, options) {
    options = options || {};
    options.method = 'GET';
    return this.ajax(url, data, options);
  }

  // any jquery ajax options are acceptable
  ajax(url, data, options) {
    let deferred = $.Deferred();
    options = options || {};
    options.async = options.async == null ? true : options.async;
    options.dataType = options.dataType ? options.dataType : 'json';
    options.contentType =
      options.contentType != null ? options.contentType : 'application/json';
    this.appendAuth(options);
    this.appendCode(options);
    $.ajax(Envs.getServiceLocation(url), options)
      .done((data, textStatus, jqXHR) => {
        this.setAuth(jqXHR);
        if (data.returnCode === 'RC-99999') {
          // the standard error code
          // TODO process the error response
          deferred.reject(jqXHR, textStatus, data);
        } else if (data.returnCode === 'RC-99998') {
          // the standard validation fail code
          // TODO process the error response
          deferred.reject(jqXHR, textStatus, data);
        } else {
          deferred.resolve(data, textStatus, jqXHR);
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.setAuth(jqXHR);
        deferred.reject(jqXHR, textStatus, errorThrown);
      });
    return deferred.promise();
  }

  setAuth(jqXHR) {
    let authString = jqXHR.getResponseHeader('Authorization');
    if (authString) {
      Storage.Auth.writeToSession('Authorization', authString);
      //spk 新增：存入local中 避免 session丢失
      localStorage.setItem('Authorization', authString);
      // 更新Authorization
      let options = {
        action: 'updateAuthorization',
        auth: authString,
      };
      Envs.pushMsgToApp(options);
    }
    return this;
  }

  isAuthorized() {
    return this.getAuth() != null;
  }

  getAuth() {
    let authInlocal =
      localStorage.getItem('Authorization') ||
      Storage.Auth.getFromSession('Authorization');
    return authInlocal;
  }

  appendAuth(options) {
    // explicitly said authorization header is ignored
    if (options.ignoreAuth === true) {
      return this;
    }
    options.headers = options.headers || {};
    if (!options.headers.Authorization) {
      options.headers.Authorization = this.getAuth();
    }
    return this;
  }

  appendCode(options) {
    // explicitly said authorization header is ignored
    if (options.hasCode === true) {
      options.headers = options.headers || {};
      options.headers['x-header-code'] = options.code;
    }
    return this;
  }
}

let ajax = new Ajax();
export default ajax;
