import {
  findEmittingId,
  findClosestIndexedEmittingId,
  findParentModel,
} from './something-utils';

const ALL = '--all';

class EventEmitter {
  static ALL = ALL;
  constructor() {
    this.events = {};
  }
  /**
   * 注册事件监听
   *
   * @param {string} event
   * @param {function} func
   * @param {any} model
   * @param {any} context
   */
  on(event, func, model, context) {
    if (!event) {
      // 没有指定事件名称, 忽略
      return this;
    }

    const realEvent = findEmittingId(event);

    let listeners = this.events[realEvent];
    if (listeners) {
      const index = listeners.findIndex((l) => {
        return l.func === func && l.model === model && l.context === context;
      });
      if (index === -1) {
        // 没找到
        listeners.push({
          func: func,
          model: model,
          context: context,
        });
        // console.log(`%c [${realEvent}] %c is registered on %c ${context.constructor.name}`, 'color: orange', 'color: green', 'color: orange');
      }
    } else {
      listeners = [
        {
          func: func,
          model: model,
          context: context,
        },
      ];
      this.events[realEvent] = listeners;
      // console.log(`%c [${realEvent}] %c is registered on %c ${context.constructor.name}`, 'color: orange', 'color: green', 'color: orange');
    }
    return this;
  }
  /**
   * 取消事件监听
   *
   * @param {string} event
   * @param {function} func
   * @param {any} model
   * @param {any} context
   */
  off(event, func, model, context) {
    if (!event) {
      // 没有指定事件名称, 忽略
      return this;
    }

    const realEvent = findEmittingId(event);

    let listeners = this.events[realEvent];
    if (listeners) {
      const index = listeners.findIndex((l) => {
        // 三个都相等, 认为相等
        return l.func === func && l.model === model && l.context === context;
      });
      if (index !== -1) {
        listeners.splice(index, 1);
        // console.log(`%c [${realEvent}] %c is unregistered from %c ${context.constructor.name}`, 'color: orange', 'color: red', 'color: orange');
      }
    }
    return this;
  }
  /**
   * 不指定事件名称则清除所有事件监听
   *
   * @param {string} event
   */
  clear(event) {
    if (event) {
      delete this.events[findEmittingId(event)];
    } else {
      this.events = {};
    }
    return this;
  }
  /**
   * 触发事件.
   * 需要指定事件属性所在的数据模型, 以及发送事件的实际UI组件.
   * 只有数据模型相同, 并且UI组件不相同, 才会被传播. 否则不予传播.
   * 加上限制的原因在于:
   * 1. 不同的数据模型可能会有相同的属性, 因此不能造成错误触发
   * 2. 自己触发的事件, 自己应该已经处理过, 因此不会再次传播给自己
   *
   * @param {string} event
   * @param {{model: object, from: ComponentThing}} options
   */
  emit(event, options) {
    if (!event) {
      // 没有传入事件名, 不传播
      return this;
    }

    this._emit(event, options);

    // 获取最近的需要被监听的事件, 主动再发送一次.
    // 这次不会再往上找.
    const closestIndexedId = findClosestIndexedEmittingId(event);
    if (closestIndexedId) {
      this._emit(closestIndexedId, {
        model: findParentModel(options.from.getRootModel(), closestIndexedId),
        from: options.from,
      });
    }

    return this;
  }
  /**
   * 发送事件, 内部方法
   *
   * @param {string} event
   * @param {{model: object, from: ComponentThing}} options
   */
  _emit(event, options) {
    // console.log(`[${event}] fired`);
    // 获取监听该属性的监听器, 以及监听所有属性的监听器
    [this.events[findEmittingId(event)], this.events[ALL]].forEach(
      (listeners) => {
        if (listeners) {
          listeners
            .filter((l) => {
              // 只有模型是同一个才会触发事件
              return l.model === options.model && l.context !== options.from;
            })
            .forEach((l) => {
              // 触发事件
              l.func.call(l.context, options);
            });
        }
      }
    );
  }
  /**
   * 触发事件, 参考emit
   */
  trigger() {
    return this.emit.apply(this, Array.prototype.slice(arguments, 0));
  }
  /**
   * 触发事件, 参考emit
   */
  fire() {
    return this.emit.apply(this, Array.prototype.slice(arguments, 0));
  }
}

export default EventEmitter;
