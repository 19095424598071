import { React, ReactDOM } from '../../../../common/3rd';
// import AtomicComponentThing from '../atomic-component';
// import {provsData} from '../../../../common/larea-data';
import AtomicMutableComponentThing from '../atomic-mutable-component';

// import Text from '../component/text';

class Radio extends React.Component {
  constructor() {
    super();
    this.state = {
      remarkValue: '',
    };
  }

  componentDidMount() {
    if (this.props.selectedValue === this.getRadioValue()) {
      this.state.remarkValue = this.props.remarkValue;
      setTimeout(() => {
        this.onRadioClicked();
      }, 10);
      this.setState({
        remarkValue: this.props.remarkValue,
      });
    }
  }

  onRadioClicked = () => {
    // $(ReactDOM.findDOMNode(this.refs.radio)).click();
    ReactDOM.findDOMNode(this.refs.radio).checked = true;
  };
  onRadioRemarkChanged = (e) => {
    const targetValue = e.target.value;
    this.setState({
      remarkValue: targetValue,
    });
    let policyDeclaraModel = this.props.parent.getValueFromModel();
    policyDeclaraModel.remark = this.getRadioValue();
    if (targetValue) {
      policyDeclaraModel.remark += ':' + targetValue;
    }
  };

  renderRemark() {
    if (!this.props.model.remarkName) {
      return null;
    }
    let remarkClass = 'sth-radio-remark';
    if (this.props.selectedValue === this.getRadioValue()) {
      remarkClass += ' sth-radio-remark-show';
    } else {
      remarkClass += ' sth-radio-remark-hide';
    }
    return (
      <div className={remarkClass}>
        <div className="sth-radio-remark-name">
          {this.props.model.remarkName}
        </div>
        <input
          className="sth-radio-remark-input"
          ref="radioRemark"
          type="text"
          value={this.state.remarkValue}
          onChange={this.onRadioRemarkChanged}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="sth-declara-radio-warp" onClick={this.onRadioClicked}>
        <input
          className="sth-declara-radio-point"
          onClick={this.onInputClicked}
          ref="radio"
          type="radio"
          name={this.props.name}
          value={this.props.model.radioValue}
        />
        <div className="sth-declara-radio-label">
          {this.props.model.radioValue}
        </div>
        {this.renderRemark()}
      </div>
    );
  }

  isSelected() {
    return ReactDOM.findDOMNode(this.refs.radio).checked;
  }

  getRadioValue() {
    return this.props.model.radioValue;
  }

  getRadioRemark() {
    return this.state.remarkValue;
  }
}

class DeclaraRadio extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.selectedValue = '';
    this.state.remarkValue = '';
  }

  UNSAFE_componentWillMount() {
    //默认选中 仅为中国税收居民  update by 2018 Jan.24
    if (
      this.getValueFromModel().code === '_0002' &&
      this.getValueFromModel().remark == ''
    ) {
      this.getValueFromModel().remark = '仅为中国税收居民';
    }
    let declaraInput = this.getValueFromModel().remark.split(':');
    this.setState({
      selectedValue: declaraInput[0] || '',
      remarkValue: declaraInput[1] || '',
    });
  }

  renderRadio() {
    const radios = this.getLayout().radios;
    const radioName = this.getLayout().code;
    // let policyDeclaraModel = this.getValueFromModel();
    if (!radios) {
      return null;
    }
    const target = this.getLayout().target;
    return radios.map((radio, index) => {
      return (
        <Radio
          key={`${radioName}-${index}`}
          ref={`${radioName}-${index}`}
          model={radio}
          selectedValue={this.state.selectedValue}
          remarkValue={this.state.remarkValue}
          parent={this}
          name={`${radioName}-${target}`}
        />
      );
    });
  }

  render() {
    return (
      <div {...this.getPrototypes()} ref="me">
        <div className="sth-label-name">{this.getLayout().label}</div>
        <div className="sth-radio-warp" onClick={this.onRaidoWarpClicked}>
          {this.renderRadio()}
        </div>
      </div>
    );
  }

  onRaidoWarpClicked = () => {
    const radios = this.getLayout().radios;
    const radioName = this.getLayout().code;
    if (!radios) {
      return;
    }
    for (let i = 0; i < radios.length; i++) {
      const radioComponent = this.refs[`${radioName}-${i}`];
      if (radioComponent.isSelected()) {
        this.setState({
          selectedValue: radioComponent.getRadioValue(),
          remarkValue: radioComponent.getRadioRemark(),
        });
        this.getValueFromModel().remark = radioComponent.getRadioValue();
        if (radioComponent.getRadioRemark()) {
          this.getValueFromModel().remark +=
            ':' + radioComponent.getRadioRemark();
        }
        break;
      }
    }
  };

  getDefaultClassName() {
    return 'sth-declara-radio';
  }
}

export default DeclaraRadio;
