import { ReactDOM, $ } from '../../../common/3rd';
import Alert from '../alert/alert';

import * as SthUtils from './something-utils';
import AtomicComponentThing from './atomic-component';

/**
 * 可变组件, 必须有this.refs.me
 */
class AtomicMutableComponentThing extends AtomicComponentThing {
  doDidMount() {
    this.setValueToDOM(this.getValueFromModel());
  }
  postDidMount() {
    super.postDidMount();
    this.me().on('change', this.onChange);
    this.me().on('blur', this.onBlur);
    this.me().on('input', this.onInput);
  }
  preWillUpdate(nextProps, nextState, nextContext) {
    super.preWillUpdate(nextProps, nextState, nextContext);
    this.me().off('change', this.onChange);
    this.me().off('blur', this.onBlur);
    this.me().off('input', this.onInput);
  }
  doDidUpdate(prevProps, prevState, prevContext) {
    this.setValueToDOM(this.getValueFromModel());
  }
  postDidUpdate(prevProps, prevState, prevContext) {
    super.postDidUpdate(prevProps, prevState, prevContext);
    this.me().on('change', this.onChange);
    this.me().on('blur', this.onBlur);
    this.me().on('input', this.onInput);
  }
  preWillUnmount() {
    super.preWillUnmount();
    this.me().off('change', this.onChange);
    this.me().off('blur', this.onBlur);
    this.me().off('input', this.onInput);
  }
  /**
   * 获取jQuery的组件对象
   */
  me() {
    return $(ReactDOM.findDOMNode(this.refs.me));
  }
  setValueToDOM(value) {
    this.me().val(value);
  }
  getValueFromDOM() {
    let val = this.me().val();
    if (typeof val === 'string') {
      val = val.replace(/[\r\n]/g, ' ');
    }
    const node = Array.from(this.refs.me.children).find(
      (node) => node.value === val
    );
    if (node && node.dataset.valueType === 'number') {
      return val * 1;
    } else {
      return val;
    }
  }
  // 事件
  /**
   * 值变化, 需要同步值到模型中
   */
  onChange = (evt) => {
    const delay = this.getChangeHandlingDelay();
    if (delay > 0) {
      if (this.state.changeDelay) {
        clearTimeout(this.state.changeDelay);
        delete this.state.changeDelay;
      }

      this.state.changeDelay = setTimeout(() => {
        this.setValueToModel(this.getValueFromDOM());
        this.fireEvent('change');
      }, delay);
    } else {
      this.setValueToModel(this.getValueFromDOM());
      this.fireEvent('change');
    }
  };
  // 事件
  /**
   * 失去焦点事件
   */
  onBlur = (evt) => {
    const delay = this.getChangeHandlingDelay();
    if (delay > 0) {
      if (this.state.changeDelay) {
        clearTimeout(this.state.changeDelay);
        delete this.state.changeDelay;
      }

      this.state.changeDelay = setTimeout(() => {
        this.setValueToModel(this.getValueFromDOM());
        this.fireEvent('blur');
      }, delay);
    } else {
      this.setValueToModel(this.getValueFromDOM());
      this.fireEvent('blur');
    }
  };
  //输入框架输入事件
  onInput = (evt) => {
    const delay = this.getChangeHandlingDelay();
    if (delay > 0) {
      if (this.state.inputDelay) {
        clearTimeout(this.state.inputDelay);
        delete this.state.inputDelay;
      }
      this.state.inputDelay = setTimeout(() => {
        this.setValueToModel(this.getValueFromDOM());
        this.fireEvent('input');
      }, delay);
    } else {
      this.setValueToModel(this.getValueFromDOM());
      this.fireEvent('input');
    }
  };
  /**
   * 当值发生变化的时候, 响应的延迟时间
   */
  getChangeHandlingDelay() {
    return 0;
  }
  getCheckRules() {
    return this.getFiltersBy('check');
  }
  /**
   * 校验输入值, 一旦某个校验规则没有通过, 则不会继续.
   *
   * @return {string} 校验未通过的信息
   */
  doCheck() {
    const rules = this.getCheckRules();

    const rule = rules.find((rule) => {
      if (rule.on) {
        // 有前置条件, 可以当做一个filter来处理
        const matchedFilter = this.matchFilter(rule);
        if (matchedFilter && !this.doCheckWithRule(rule)) {
          return true;
        }
      } else {
        if (!this.doCheckWithRule(rule)) {
          return true;
        }
      }
      return false;
    });
    if (rule) {
      return rule.msg || rule.m;
    } else {
      return null;
    }
  }
  /**
   * 校验指定的规则
   *
   * @param {rule} rule
   * @return {boolean} false表示校验没有通过, true表是校验通过
   */
  doCheckWithRule(rule) {
    return SthUtils.checkRule({
      rule: rule,
      value: this.getValueFromModel(),
      model: this.getModel(),
      root: this.getRootModel(),
      comp: this,
    });
  }
  /**
   * 校验并弹框
   */
  doCheckAndAlert = (evt) => {
    const message = this.doCheck();
    if (message) {
      Alert.message(message);
    }
  };
}

export default AtomicMutableComponentThing;
