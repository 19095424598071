import { ReactDOM, $ } from '../../../../common/3rd';
import { Envs, Utils } from '../../../../common/common';
// import { format } from '../something-utils'
// import { PageLoading,Alert } from '../../../components'
import BaseSignatureOneToMoreView from './baseSignatureOneToMoreView';
import tpl from '../../signatureHTML/personalInsuranceTipsHtml';
/**
 * 人身投保提示书 签名单证
 * add by wendy.gu 2017 Nov.3
 *
 */
class PersonalInsuranceTips extends BaseSignatureOneToMoreView {
  constructor(props, context) {
    super(props, context);
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.currentIndex = 0;
    this.state.myIndex = 17;
    this.documentListIndex = 23;
  }
  UNSAFE_componentWillMount() {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.policyHolderMsg = [
      self.policyHolderType,
      '-4.1',
      true,
      '0',
      '投保人签字:',
      '3',
      '10',
      '',
      ['-4.5'],
    ];
    self.agentMsg = [
      self.agentType,
      '-4.1',
      true,
      '-1',
      '代理人员签字:',
      '3',
      '10',
      '',
      ['-4.4', '-4.5'],
    ];
    self.caContent = [
      {
        keyWord: '投保人签字:',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '1',
      },
      {
        keyWord: '代理人员签字:',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '0',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.policyHolderMsg = [
      self.policyHolderType,
      '-4.1',
      true,
      '0',
      '投保人签字:',
      '3',
      '10',
      '',
      ['-4.5'],
    ];
    self.agentMsg = [
      self.agentType,
      '-4.1',
      true,
      '-1',
      '代理人员签字:',
      '3',
      '10',
      '',
      ['-4.4', '-4.5'],
    ];
    self.caContent = [
      {
        keyWord: '投保人签字:',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '1',
      },
      {
        keyWord: '代理人员签字:',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '0',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }
  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidUpdate() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidUpdate();
  }
  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidMount() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidMount();
  }
  //重置数据
  resetData() {
    let self = this;
    super.resetData(self.model);
  }
  //初始化页面数据
  resetUI() {
    let self = this;
    self.parent = $(ReactDOM.findDOMNode(self.refs['signatureHtml']));
    self.policyHolderImageApp = self.parent.find('#imageApp1');
    self.agentImageApp = self.parent.find('#imageApp2');
    self.parent
      .find('#logImage')
      .attr(
        'src',
        this.props.parent.props.parent.props.parent.state.policyLayout._logImage
          .bjlife
      );
    window
      .jQuery('#personalInsuranceTips_policy_id')
      .barcode(self.proposalCode, 'code128', {
        barWidth: 1,
        barHeight: 50,
        showHRI: true,
      });
    //设置单证日期
    let timeSpan = self.parent.find('#nian');
    let date = new Date();
    let month = parseInt(date.getMonth(), 10) + 1;
    let time = date.getFullYear() + '年' + month + '月' + date.getDate() + '日';
    timeSpan.html(time);
    //设置代理人代码
    let agentCode = '';
    let accountName = Envs.findAccount().accountName;
    if (accountName) {
      let index = accountName.indexOf('.');
      agentCode = accountName.substring(index + 1, accountName.length);
    }
    if (
      Utils.getParameter(window.location.href, 'isShare') &&
      Utils.getParameter(window.location.href, 'agentNo')
    ) {
      agentCode = Utils.getParameter(window.location.href, 'agentNo');
    }
    self.parent.find('#agent_code').html(agentCode);
    //设置代理人电话
    let agentMobile = Envs.findAccount().mobile || '';
    if (
      Utils.getParameter(window.location.href, 'isShare') &&
      Utils.getParameter(window.location.href, 'agentMobile')
    ) {
      agentMobile = Utils.getParameter(window.location.href, 'agentMobile');
    }
    self.parent.find('#agent_mobile').html(agentMobile);
    self.resetBtn();
    super.resetUI();
  }
  resetBtn() {
    let self = this;
    self.parent.find('#sig-button').attr('class', 'onlyOneButton');
    self.policySigBtn = self.parent.find('.sig-button1');
    self.agentSigBtn = self.parent.find('.sig-button3');
    if (self.currentSignaturePerson === self.policyHolderType) {
      self.agentSigBtn.css('display', 'none');
    } else if (self.currentSignaturePerson === self.agentType) {
      self.policySigBtn.css('display', 'none');
    }
  }
  addEvent() {
    let self = this;
    self.parent.find('.sig-button1').on('click', self.clickPolicySigBtnHandler);
    self.parent.find('.sig-button3').on('click', self.clickAgentSigBtnHandler);
  }
  //各单证根据各自签名完成情况自定义 docType
  getPdfType() {
    let self = this;
    let pdfType = null;
    if (self.currentSignaturePerson === self.agentType) {
      pdfType = '-4.4';
    }
    if (self.agentSaveSiged && self.policyHolderSaveSiged) {
      pdfType = '-4.5';
    }
    //todo delete
    // if(self.currentSignaturePerson === self.policyHolderType) {
    // 	pdfType = "-4.5";
    // }
    return pdfType;
  }
  getDefaultClassName() {
    return '';
  }
}

export default PersonalInsuranceTips;
