import AtomicComponentThing from './atomic-component';
import EventEmitter from './event-emitter';

import * as SthUtils from './something-utils';

/**
 * 数组组件
 */
class AtomicArrayComponentThing extends AtomicComponentThing {
  /**
   * didMount最后增加事件监听
   */
  postDidMount() {
    super.postDidMount();
    this.installFilterIds('filter');
  }
  /**
   * willUpdate最前去掉事件监听
   */
  preWillUpdate(nextProps, nextState, nextContext) {
    super.preWillUnmount(nextProps, nextState, nextContext);
    this.uninstallFilterIds('filter');
  }
  /**
   * didUpdate最后增加事件监听
   */
  postDidUpdate(prevProps, prevState, prevContext) {
    super.postDidUpdate(prevProps, prevState, prevContext);
    this.installFilterIds('filter');
  }
  /**
   * willUnmount最前去掉事件监听
   */
  preWillUnmount() {
    super.preWillUnmount();
    this.uninstallFilterIds('filter');
  }
  /**
   * 从模型中获取值
   * @param {string} id 可以不指定, 则使用getId()的返回值
   */
  getValueFromModel(id) {
    const data = super.getValueFromModel(id) || [];
    if (id != null && id !== this.getId()) {
      // 不是获取本身的数据, 直接返回
      return data;
    }

    // 过滤数据
    const filters = this.getFiltersBy('filter');
    if (filters.length === 0) {
      // 没有过滤器
      return data;
    }

    // 可以有多个过滤器, 但是注意任意两个过滤器不能适配到同样的条件, 也就是最终只会执行到一个过滤器.
    // 如果数据可以适配到多个过滤器, 则只有第一个生效
    // 找到合适的过滤器
    const appliedFilter = this.matchFilter(filters);
    if (appliedFilter) {
      // 找到过滤器, 使用过滤器过滤
      if (appliedFilter.do) {
        // 定义了过滤数据的方式, 执行
        return SthUtils.filterArray(data, appliedFilter.do);
      } else {
        // 没有定义过滤数据的方式, 返回所有数据
        return data;
      }
    } else if (this.isShowAllWhenNoFilterApplied()) {
      // 没有适配到过滤器, 如果设置为没有过滤器显示全部, 返回数组本身
      return data;
    } else {
      // 没有适配到过滤器, 如果没有设置为没有过滤器显示全部, 返回空数组
      return [];
    }
  }
  /**
   * 当有过滤器定义, 但是没有过滤器适配的时候, 是否显示所有内容. 默认false
   *
   * @return {boolean}
   */
  isShowAllWhenNoFilterApplied() {
    return this.getLayout().allForNoFilter;
  }
}

/**
 * 数组item组件
 * @param {*} SuperThing
 */
const AtomicArrayItemThing = (SuperThing) =>
  class extends SuperThing {
    /**
     * didMount最后增加事件监听
     */
    postDidMount() {
      super.postDidMount();
      this.installAllPropsToEmitter();
    }
    /**
     * willUpdate最前去掉事件监听
     */
    preWillUpdate(nextProps, nextState, nextContext) {
      super.preWillUpdate(nextProps, nextState, nextContext);
      this.uninstallAllPropsToEmitter();
    }
    /**
     * didUpdate最后增加事件监听
     */
    postDidUpdate(prevProps, prevState, prevContext) {
      super.postDidUpdate(prevProps, prevState, prevContext);
      this.installAllPropsToEmitter();
    }
    /**
     * willUnmount最前去掉事件监听
     */
    preWillUnmount() {
      super.preWillUnmount();
      this.uninstallAllPropsToEmitter();
    }
    /**
     * 安装监听所有属性的监听器
     */
    installAllPropsToEmitter() {
      this.installToEventEmitter({
        event: EventEmitter.ALL,
        func: this.onAnyPropEventEmitted,
      });
    }
    /**
     * 卸载监听所有属性的监听器
     */
    uninstallAllPropsToEmitter() {
      this.uninstallFromEventEmitter({
        event: EventEmitter.ALL,
        func: this.onAnyPropEventEmitted,
      });
    }
    /**
     * 任意属性有事件触发, 调用本方法
     * 默认调用父组件, 直接发送父组件对应模型的事件
     */
    onAnyPropEventEmitted() {
      const parent = this.getParent();
      this.getEventEmitter().emit(parent.getId(), {
        model: parent.getModel(),
        from: this,
      });
    }
  };

export { AtomicArrayComponentThing, AtomicArrayItemThing };
