import { React, ReactDOM, $ } from '../../../../common/3rd';
// import { Lang } from '../../../../common/common';

import { Toggle } from '../component/toggle';
// import TextArea from '../component/text-area';
// import AtomicComponentThing from '../atomic-component';
import AtomicMutableComponentThing from '../atomic-mutable-component';
// import Text from '../component/text';
import { provsData, citysData } from '../../../../common/larea-data';

class DeclaraChildren extends AtomicMutableComponentThing {
  // constructor(props, context) {
  // 	super(props, context);
  // }

  getLabelById(val, mapCodes) {
    if (!val || !mapCodes) {
      return '';
    }
    let code = mapCodes.find(function (value, index, arr) {
      return value.v === val;
    });
    return code ? code.l : '';
  }

  /**
   * didMount最后增加事件监听
   */
  postDidMount() {
    super.postDidMount();
    // 自动填写投保人姓名 update by susan 2018-02-01
    if (
      this.getValueFromModel().code === '_0001' &&
      this.getValueFromModel().remark == '' &&
      this.getModel().policyHolder &&
      this.getModel().policyHolder.fullName
    ) {
      this.getValueFromModel().remark = this.getModel().policyHolder.fullName;
    }
    // 自动填写投保人出生日期，现居住地址 update by susan 2018-02-01
    if (
      this.getValueFromModel().code === '_0003' &&
      this.getValueFromModel().remark == ''
    ) {
      let dateOfBirth = this.getModel().policyHolder.dateOfBirth
        ? this.getModel()
            .policyHolder.dateOfBirth.substring(0, 10)
            .replace(/\//g, '-')
        : '';
      let country = this.getLabelById(
        parseInt(this.getModel().policyHolder.provinceCode, 10),
        provsData
      );
      let city = this.getLabelById(
        parseInt(this.getModel().policyHolder.cityCode, 10),
        citysData[this.getModel().policyHolder.provinceCode]
      );
      if (dateOfBirth && country && city) {
        this.getValueFromModel().remark = `//${dateOfBirth}/中国/${country}/${city}////////////`;
      }
    }

    if (this.getLayout().noToggle === true || !this.getLabel()) {
      this.getValueFromModel().confirm = true;
    }
    if (this.getLayout().notConfirm) {
      this.getValueFromModel().confirm = false;
    }
    this.installInforToEmitter();
    if (this.getValueFromModel().confirm || this.getLayout().notConfirm) {
      $(ReactDOM.findDOMNode(this.refs.inputWrap)).css({ display: 'block' });
    } else {
      $(ReactDOM.findDOMNode(this.refs.inputWrap)).css({ display: 'none' });
    }
    if (this.getLayout().declaraInput) {
      let remark = this.getValueFromModel().remark.split('/');
      for (let i = 0; i < remark.length; i++) {
        $(ReactDOM.findDOMNode(this.refs['input-content-' + i])).val(remark[i]);
      }
    }
  }

  /**
   * willUpdate最前去掉事件监听
   */
  preWillUpdate(nextProps, nextState, nextContext) {
    super.preWillUpdate(nextProps, nextState, nextContext);
    this.uninstallInforFromEmitter();
  }

  /**
   * didUpdate最后增加事件监听
   */
  postDidUpdate(prevProps, prevState, prevContext) {
    super.postDidUpdate(prevProps, prevState, prevContext);
    this.installInforToEmitter();
    // 自动填写投保人姓名 update by susan 2018-02-01
    if (
      this.getValueFromModel().code === '_0001' &&
      this.getValueFromModel().remark == '' &&
      this.getModel().policyHolder &&
      this.getModel().policyHolder.fullName
    ) {
      this.getValueFromModel().remark = this.getModel().policyHolder.fullName;
    }
    // 自动填写投保人出生日期，现居住地址 update by susan 2018-02-01
    if (
      this.getValueFromModel().code === '_0003' &&
      this.getValueFromModel().remark == ''
    ) {
      let dateOfBirth = this.getModel().policyHolder.dateOfBirth
        ? this.getModel()
            .policyHolder.dateOfBirth.substring(0, 10)
            .replace(/\//g, '-')
        : '';
      let country = this.getLabelById(
        parseInt(this.getModel().policyHolder.provinceCode, 10),
        provsData
      );
      let city = this.getLabelById(
        parseInt(this.getModel().policyHolder.cityCode, 10),
        citysData[this.getModel().policyHolder.provinceCode]
      );
      if (dateOfBirth && country && city) {
        this.getValueFromModel().remark = `//${dateOfBirth}/中国/${country}/${city}////////////`;
      }
    }
    if (this.getValueFromModel().confirm || this.getLayout().notConfirm) {
      $(ReactDOM.findDOMNode(this.refs.inputWrap)).css({ display: 'block' });
    } else {
      if (!this.getLayout().noToggle) {
        $(ReactDOM.findDOMNode(this.refs.inputWrap)).css({ display: 'none' });
      }
    }
    if (this.getLayout().declaraInput) {
      let remark = this.getValueFromModel().remark.split('/');
      for (let i = 0; i < remark.length; i++) {
        $(ReactDOM.findDOMNode(this.refs['input-content-' + i])).val(remark[i]);
      }
    }
  }

  /**
   * willUnmount最前去掉事件监听
   */
  preWillUnmount() {
    super.preWillUnmount();
    this.uninstallInforFromEmitter();
  }

  /**
   * 添加对于标题栏toggleId和rightId的监听
   */
  installInforToEmitter() {
    this.installToEventEmitter({
      event: this.getDeclaraToggleId(),
      func: this.onDeclaraToggleChange,
    });
  }

  /**
   * 取消对于标题栏toggleId和rightId的监听
   */
  uninstallInforFromEmitter() {
    this.uninstallFromEventEmitter({
      event: this.getDeclaraToggleId(),
      func: this.onDeclaraToggleChange,
    });
  }

  getDeclaraToggleId() {
    return this.getId() + '.confirm';
  }

  renderDeclaraToggle() {
    if (this.getLayout().noToggle === true) {
      return null;
    }
    const toggleId = this.getDeclaraToggleId();
    if (toggleId) {
      return (
        <Toggle
          model={this.getModel()}
          layout={{
            id: toggleId,
            class: 'sth-declara-toggle',
          }}
          parent={this}
        />
      );
    }
  }

  renderDeclaraLabel() {
    const label = this.getLabel();
    if (!label) {
      return null;
    }
    return (
      <div className="sth-delara-warp">
        <div className="sth-declara-label">{this.getLabel()}</div>
        {this.renderDeclaraToggle()}
      </div>
    );
  }

  renderDeclaraInput() {
    const declaraInput = this.getLayout().declaraInput;
    if (Array.isArray(declaraInput) && declaraInput.length > 0) {
      return (
        <div className="sth-declara-input-wrap" ref="inputWrap">
          {declaraInput.map((declaraInputItem, index) => {
            return declaraInputItem.type === 'checkBox' ? (
              <div key={index} className="sth-declara-input-item">
                <div className="sth-declara-input-name2">
                  {declaraInputItem.declaraInputName}
                </div>
                <input
                  type="checkbox"
                  className={'sth-declara-radio-point'}
                  onChange={(e) => this.onCheckBoxChange(e)}
                  checked={this.getValueFromModel().confirm}
                  ref={`input-content-${index}`}
                />
              </div>
            ) : (
              <div key={index} className="sth-declara-input-item">
                <div className="sth-declara-input-name">
                  {declaraInputItem.declaraInputName}
                </div>
                <input
                  className="sth-declara-input-content"
                  onChange={this.onInputChange}
                  ref={`input-content-${index}`}
                />
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    return (
      <div {...this.getPrototypes()}>
        {this.renderDeclaraLabel()}
        {this.renderDeclaraInput()}
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-cell sth-declara-toggle-remark';
  }

  onDeclaraToggleChange = () => {
    this.getValueFromModel().parameters = this.getValueFromModel().confirm
      ? '是'
      : '否';
    const inputWrap = $(ReactDOM.findDOMNode(this.refs.inputWrap));
    inputWrap.slideToggle();
  };
  onInputChange = () => {
    let value = '';
    for (let i = 0; i < this.getLayout().declaraInput.length; i++) {
      if (i === 0) {
        value = $(ReactDOM.findDOMNode(this.refs['input-content-' + i])).val();
      } else {
        value +=
          '/' + $(ReactDOM.findDOMNode(this.refs['input-content-' + i])).val();
      }
    }
    this.getValueFromModel().remark = value;
  };

  onCheckBoxChange(e) {
    let checked = e.target.checked;
    this.getValueFromModel().parameters = checked ? '是' : '否';
    this.getValueFromModel().confirm = checked ? true : false;
    this.getValueFromModel().remark = this.getValueFromModel().remark.replace(
      /on|是|否/,
      checked ? '是' : '否'
    );
    this.getRootComponent().forceUpdate();
  }
}

export default DeclaraChildren;
