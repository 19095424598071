/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 加/解密、脱敏工具
 * @author LiuH
 */
import CryptoJS from 'crypto-js';
import { isEmptyString } from '@/util/precondition';
import LogUtil from '@/util/log';

/** 电商在线 */
const DS_KEY = '9876543210ABCDEF';
const DS_IV = 'ABCDEF9876543210';

/**
 * 加密
 * @param {string} source 待加密字符串
 * @param {string} [originKey='TS4W8BX0OPJW6JJ5']
 * @param {string} [originIV='1234567890abcdef']
 * @return {string} 加密后字符串
 */
function encrypt(
  source,
  originKey = 'TS4W8BX0OPJW6JJ5',
  originIV = '1234567890abcdef'
) {
  const key = CryptoJS.enc.Utf8.parse(originKey);
  const iv = CryptoJS.enc.Utf8.parse(originIV);

  let wordArray = CryptoJS.enc.Utf8.parse(source);
  // 对称加密
  let encrypted = CryptoJS.AES.encrypt(wordArray, key, {
    iv: iv,
    // 分组密码操作模式：ECB分组模式不安全，至少应该使用CBC分组模式。
    mode: CryptoJS.mode.CBC,
    // 填充方式
    padding: CryptoJS.pad.Pkcs7,
  });

  // 返回base64
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

/**
 * 解密
 * @param {string} word 加密后字符串
 * @param {string} [originKey='TS4W8BX0OPJW6JJ5']
 * @param {string} [originIV='1234567890abcdef']
 * @return {string} 解密后字符串
 */
function decrypt(
  word,
  originKey = 'TS4W8BX0OPJW6JJ5',
  originIV = '1234567890abcdef'
) {
  const key = CryptoJS.enc.Utf8.parse(originKey);
  const iv = CryptoJS.enc.Utf8.parse(originIV);

  let wordArray = CryptoJS.enc.Base64.parse(word);

  let src = CryptoJS.enc.Base64.stringify(wordArray);

  let decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    // 分组密码操作模式：ECB分组模式不安全，至少应该使用CBC分组模式。
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

/**
 * Base64编码
 * @param {string} source UTF-8编码字符串
 * @return {string} Base64编码字符串
 */
function base64Encode(source) {
  // return window.btoa(unescape(encodeURIComponent(source)));
  // 将UTF-8编码字符串转换成WordArray
  const wordArray = CryptoJS.enc.Utf8.parse(source);
  const result = CryptoJS.enc.Base64.stringify(wordArray);
  LogUtil.log('Base64 encode', `"${source}" => "${result}"`);
  return result;
}

/**
 * Base64解码
 * @param {string} source Base64编码字符串
 * @return {string} UTF-8编码字符串
 */
function base64Decode(source) {
  // return decodeURIComponent(escape(window.atob(source)));
  const wordArray = CryptoJS.enc.Base64.parse(source);
  const result = CryptoJS.enc.Utf8.stringify(wordArray);
  LogUtil.log('Base64 decode', `"${source}" => "${result}"`);
  return result;
}

/**
 * 参照[RFC 3986](https://datatracker.ietf.org/doc/html/rfc3986)规范进行编码
 * @param {string|number|boolean} str
 * @return {string}
 */
function encodeURIComponentRFC3986(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
}

/**
 * 手机号脱敏
 * @param {string} str 手机号
 * @return {string} 脱敏后的新字符串
 */
function encryptPhone(str) {
  // const len = str.length - 7;
  // const reg = new RegExp('(\\d{3})\\d{' + len + '}(\\d{4})');
  const reg = /(\d{3})\d{4}(\d{4})/; //正则表达式
  return str.replace(reg, '$1****$2');
}

/**
 * 证件号脱敏
 * @param {string} 证件号
 * @return {string} 脱敏后的新字符串
 */
function encryptIDNo(str) {
  if (isEmptyString(str) || str.length != 18) {
    return str;
  }
  const reg = /(\d{5})\d{9}(\d{4})/; //正则表达式
  return str.replace(reg, '$1****$2');
}

export {
  DS_KEY,
  DS_IV,
  encrypt,
  decrypt,
  base64Encode,
  base64Decode,
  encodeURIComponentRFC3986,
  encryptPhone,
  encryptIDNo,
};
