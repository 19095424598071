/**
 * base source code from https://github.com/mydea/PikadayResponsive
 */
import { React, ReactDOM, ClassNames, $ } from '../../../../common/3rd';
import { Utils } from '../../../../common/common';

import AtomicMutableComponentThing from '../atomic-mutable-component';
import ChoseDate from '../../../widgets/chose-date';

class Date extends AtomicMutableComponentThing {
  UNSAFE_componentWillMount() {
    this.id = 'datePicker' + parseInt(Math.random() * 1000, 10);
  }
  renderNative() {
    return (
      <input
        type="text"
        readOnly
        className="sth-date-display sth-date-display-native"
        placeholder={this.getPlaceholder()}
        onBlur={this.doCheckAndAlert}
        ref="display"
      />
    );
  }
  render() {
    const className = ClassNames('sth-date-invisible');
    return (
      <span {...this.getPrototypes()}>
        <input type="hidden" onChange={this.onChange} readOnly ref="me" />
        {/* 显示用的输入框 */}
        <ChoseDate
          id={this.id}
          value={this.getValueFromModel() || '请选择日期'}
          className={'sth-date-picker'}
          max={this.getMaxDate()}
          min={this.getMinDate()}
          onSubmit={this.onDateChange}
          placeholder={this.getPlaceholder()}
          parent={this}
          ref="real"
        />
        {/* {this.renderNative()} */}
      </span>
    );
  }
  getMaxDate() {
    if (this.getLayout().MaxDate === 'today') {
      return Utils.getOriginFormatToday();
    } else {
      return this.getLayout().MaxDate;
    }
  }
  getMinDate() {
    if (this.getLayout().minDate === 'today') {
      return Utils.getOriginFormatToday();
    } else {
      return this.getLayout().minDate;
    }
  }
  getDefaultClassName() {
    return 'sth-date';
  }
  getPlaceholder() {
    return this.getLayout().placeholder;
  }
  onDateChange = (value) => {
    // const val = $(evt.target).val();
    const val = value;

    // 设置到hidden里面
    this.setValueToDOM(val);

    // 触发change事件
    this.me().trigger('change');
  };
  setValueToDOM(value) {
    super.setValueToDOM(value);
    const displayInput = $(ReactDOM.findDOMNode(this.refs.display));
    if (value) {
      displayInput.val(value);
    } else {
      displayInput.val(null);
    }
  }
  getValueFromModel(id) {
    const value = super.getValueFromModel(id);

    if (id == null || id === this.getId()) {
      if (value) {
        // 去掉时分秒, 换成横杠
        return value.split(' ')[0].replace(/\//g, '-');
      } else {
        return null;
      }
    }
  }
  setValueToModel(value, callback, force, id) {
    if (id == null || id === this.getId()) {
      if (value && value.length === 10) {
        value = value.replace(/-/g, '/') + ' 00:00:00';
      }
    }
    super.setValueToModel(value, callback, force, id);
  }
}

export default Date;
