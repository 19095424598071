//职业查询带层级
let occupationJSON_LEVEL = [
  {
    code: '1',
    descr: '国家机关、党群组织、企业事业单位负责人',
    isopen: false,
    item: [
      {
        code: '101',
        descr: '中国共产党中央委员会和地方各级党组织负责人',
        isopen: false,
        item: [
          {
            code: '10100',
            descr: '中国共产党中央委员会和地方各级党组织负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1010001',
                descr: '中国共产党中央委员会和地方各级党组织负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '102',
        descr: '国家机关及其工作机构负责人',
        isopen: false,
        item: [
          {
            code: '10202',
            descr: '人民政协及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1020201',
                descr: '人民政协及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10203',
            descr: '人民法院负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1020301',
                descr: '人民法院负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10204',
            descr: '人民检察院负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1020401',
                descr: '人民检察院负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10205',
            descr: '国家行政机关及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1020501',
                descr: '国家行政机关及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10299',
            descr: '其他国家机关及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1029901',
                descr: '其他国家机关及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10201',
            descr: '国家权力机关及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1020101',
                descr: '国家权力机关及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '103',
        descr: '民主党派和社会团体及其工作机构负责人',
        isopen: false,
        item: [
          {
            code: '10301',
            descr: '民主党派负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1030101',
                descr: '民主党派负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10302',
            descr: '工会、共青团、妇联等人民团体及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1030201',
                descr: '工会负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1030202',
                descr: '中国共产主义青年团负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1030203',
                descr: '妇女联合会负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1030299',
                descr: '其他人民团体及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10303',
            descr: '群众自治组织负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1030301',
                descr: '群众自治组织负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10399',
            descr: '其他社会团体及其工作机构负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1039901',
                descr: '其他社会团体及其工作机构负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '104',
        descr: '事业单位负责人',
        isopen: false,
        item: [
          {
            code: '10401',
            descr: '教育教学单位负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1040101',
                descr: '高等学校校长',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1040102',
                descr: '中等职业教育学校校长',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1040103',
                descr: '中小学校校长',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1040199',
                descr: '其他教育教学单位负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10403',
            descr: '科研单位负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1040301',
                descr: '科研单位负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10499',
            descr: '其他事业单位负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1049901',
                descr: '其他事业单位负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '10402',
            descr: '卫生单位负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1040201',
                descr: '卫生单位负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '105',
        descr: '企业负责人',
        isopen: false,
        item: [
          {
            code: '10501',
            descr: '企业负责人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '1050101',
                descr: '企业董事',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1050102',
                descr: '企业经理',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1050103',
                descr: '企业职能部门经理或主管',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1050104',
                descr: '工厂、企业负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '1050105',
                descr: '出版商、书店、文具店负责人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '2',
    descr: '专业技术人员',
    isopen: false,
    item: [
      {
        code: '201',
        descr: '科学研究人员',
        isopen: false,
        item: [
          {
            code: '20101',
            descr: '哲学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010101',
                descr: '哲学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20103',
            descr: '法学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010301',
                descr: '法学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20104',
            descr: '社会学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010401',
                descr: '社会学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20105',
            descr: '教育科学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010501',
                descr: '教育科学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20106',
            descr: '文学、艺术研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010601',
                descr: '文学、艺术研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20108',
            descr: '历史学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010801',
                descr: '历史学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20109',
            descr: '管理科学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010901',
                descr: '管理科学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20110',
            descr: '数学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011001',
                descr: '数学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20111',
            descr: '物理学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011101',
                descr: '物理学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20112',
            descr: '化学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011201',
                descr: '化学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20113',
            descr: '天文学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011301',
                descr: '天文学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20114',
            descr: '地球科学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011401',
                descr: '地球科学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20115',
            descr: '生物科学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011501',
                descr: '生物科学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20116',
            descr: '农业科学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011601',
                descr: '农业科学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20117',
            descr: '医学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011701',
                descr: '医学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20118',
            descr: '体育研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2011801',
                descr: '体育研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20107',
            descr: '图书馆学、情报学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010701',
                descr: '图书馆学、情报学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20102',
            descr: '经济学研究人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2010201',
                descr: '经济学研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '202',
        descr: '工程技术人员',
        isopen: false,
        item: [
          {
            code: '20201',
            descr: '地质勘探工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020101',
                descr: '地质勘探工程技术人员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '20202',
            descr: '测绘工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020201',
                descr: '大地测量工程技术人员',
                level: '2',
                medical_level: '2',
              },
              {
                code: '2020202',
                descr: '工程测量工程技术人员',
                level: '2',
                medical_level: '2',
              },
              {
                code: '2020203',
                descr: '摄影测量与遥感工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020204',
                descr: '地图制图与印刷工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2020205',
                descr: '海洋测绘工程技术人员(海上作业)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '2020206',
                descr: '海洋测绘工程技术人员(非海上作业)',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20204',
            descr: '石油工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020401',
                descr: '石油开采工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2020402',
                descr: '石油储运工程技术人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20203',
            descr: '矿山工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020301',
                descr: '采矿工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2020302',
                descr: '选矿与矿物加工工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020303',
                descr: '矿寻工程师、技师、领班',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '20205',
            descr: '冶金工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020501',
                descr: '冶炼工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020502',
                descr: '轧制工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020503',
                descr: '焦化工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020504',
                descr: '金属材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020505',
                descr: '耐火材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020506',
                descr: '碳素材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020507',
                descr: '冶金热能工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20206',
            descr: '化工工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020601',
                descr: '化工实验工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2020602',
                descr: '化工设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2020603',
                descr: '化工生产工程技术人员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '20207',
            descr: '机械工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020701',
                descr: '机械设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2020702',
                descr: '机械制造工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020703',
                descr: '仪器仪表工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020704',
                descr: '设备工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020705',
                descr: '数控程控员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20208',
            descr: '兵器工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020801',
                descr: '装甲车辆工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020802',
                descr: '火炮枪械工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2020803',
                descr: '弹箭工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2020804',
                descr: '火炸药工程技术人员',
                level: '6',
                medical_level: '3',
              },
              {
                code: '2020805',
                descr: '光电火控工程技术人员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '20209',
            descr: '航空工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2020901',
                descr: '飞机设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2020902',
                descr: '飞机制造工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2020903',
                descr: '飞机发动机设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2020904',
                descr: '飞机发动机制造工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20210',
            descr: '航天工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021001',
                descr: '航天工程技术人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20211',
            descr: '电子工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021101',
                descr: '电子材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021102',
                descr: '电子元器件工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021103',
                descr: '雷达系统工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021104',
                descr: '广播视听设备工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021105',
                descr: '电子仪器与测量工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021106',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20212',
            descr: '通信工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021201',
                descr: '通信工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20213',
            descr: '计算机与应用工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021301',
                descr: '计算机硬件技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021302',
                descr: '计算机软件技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021303',
                descr: '计算机网络技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021304',
                descr: '计算机系统分析技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021305',
                descr: '维护工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021306',
                descr: '销售工程师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021307',
                descr: '计算机乐谱制作师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021308',
                descr: '数字视频合成师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021309',
                descr: '计算机软件产品检验员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20214',
            descr: '电气工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021401',
                descr: '电机与电器工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2021402',
                descr: '电力拖动与自动控制工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021403',
                descr: '电线电缆与电工材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021404',
                descr: '可编程序控制系统设计师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20215',
            descr: '电力工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021501',
                descr: '发电工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2021502',
                descr: '输变电工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2021503',
                descr: '供用电工程技术人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20216',
            descr: '邮政工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021601',
                descr: '邮政工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20218',
            descr: '交通工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021801',
                descr: '汽车运用工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021802',
                descr: '船舶运用工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021803',
                descr:
                  '水上交通工程技术人员(进行水上安全监督管理、搜寻救助等行动)',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2021804',
                descr: '水上交通工程技术人员(不参与水上行动)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021805',
                descr: '海上救助打捞工程技术人员(参与海上艘救、打捞行动)',
                level: '7',
                medical_level: '3',
              },
              {
                code: '2021806',
                descr: '海上救助打捞工程技术人员(救助打捞技术、装备研究、设计)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021807',
                descr: '船舶检验工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20217',
            descr: '广播电影电视工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021701',
                descr: '广播电视编播工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021702',
                descr: '广播电视传输覆盖工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2021703',
                descr: '电影工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20219',
            descr: '民用航空工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2021901',
                descr: '民用航空器维修与适航审定专业技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2021902',
                descr: '航行航空管理及飞行程序设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2021903',
                descr:
                  '通用航空技术人员(从事航空摄影、航空物探、航空吊挂吊装、石油航空作业、航空环境污染监测、航空护林、播种、喷施、研究航空体育运动等)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '2021904',
                descr: '航空运输研究人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20220',
            descr: '铁路工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022001',
                descr: '铁道运输工程研究人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022002',
                descr: '铁路机务工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022003',
                descr: '铁路车辆工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022004',
                descr: '铁路电务工程技术人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20221',
            descr: '建筑工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022101',
                descr: '城镇规划设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022102',
                descr: '建筑设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022103',
                descr: '土木建筑工程技术人员(到工地监督检查指导)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022104',
                descr: '土木建筑工程技术人员(不到工地)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022105',
                descr: '风景园林工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022106',
                descr: '道路与桥梁工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022107',
                descr: '港口与航道工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022108',
                descr: '机场工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022109',
                descr: '铁路建筑工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022110',
                descr: '水利水电建筑工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022111',
                descr: '内勤工作人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022112',
                descr: '承包商(土木建筑)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022113',
                descr: '建筑公司负责人、业务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022114',
                descr: '引导参观工地服务人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20222',
            descr: '建材工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022201',
                descr: '硅酸盐工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022202',
                descr: '石棉、石膏、云母等非金属矿及制品工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022203',
                descr: '玻璃钢、复合材料等无机非金属新材料工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20223',
            descr: '林业工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022301',
                descr: '林业生态环境工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022302',
                descr: '森林培育工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022303',
                descr: '园林绿化工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022304',
                descr:
                  '野生动物保护与繁殖利用工程技术人员(研究调查野生动物资源及野生动物驯化、繁殖技术人员)',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2022305',
                descr:
                  '野生动物保护与繁殖利用工程技术人员(设计、研制猎枪、猎具，改进狩猎技、术，研究野生动物肉、毛皮产品测定、分析、储藏技术人员)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022306',
                descr: '自然保护区工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2022307',
                descr: '森林保护工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2022308',
                descr: '木、竹材加工工程技术人员',
                level: '3',
                medical_level: '2',
              },
              {
                code: '2022309',
                descr: '森林采伐和运输工程技术人员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2022310',
                descr: '经济林和林特产品加工工程技术人员',
                level: '2',
                medical_level: '2',
              },
              {
                code: '2022311',
                descr: '森林资源管理与监测工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20224',
            descr: '水利工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022401',
                descr: '水资源勘测工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022402',
                descr: '治河及泥沙治理工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022403',
                descr: '水利工程师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20225',
            descr: '海洋工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022501',
                descr: '海洋调查与监测工程技术人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022502',
                descr: '海洋环境预报工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022503',
                descr:
                  '海洋资源开发利用和保护工程技术人员(海洋矿产、油气资源开发利用)',
                level: '6',
                medical_level: '3',
              },
              {
                code: '2022504',
                descr:
                  '海洋资源开发利用和保护工程技术人员(海水淡化、潮汐能、波浪能等能源开发、海洋生态系统保护等)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2022505',
                descr: '海洋工程勘察设计工程技术人员(进行海洋、海底勘察人员)',
                level: '6',
                medical_level: '3',
              },
              {
                code: '2022506',
                descr: '海洋工程勘察设计工程技术人员(工程设计规划人员 )',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20226',
            descr: '水产工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022601',
                descr: '水产养殖工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022602',
                descr: '渔业资源开发利用工程技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20228',
            descr: '食品工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022801',
                descr: '食品营养卫生研究及食品加工、储运工艺技术开发应用人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20227',
            descr: '纺织工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022701',
                descr: '纺纱工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022702',
                descr: '织造工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022703',
                descr: '染整工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022704',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20229',
            descr: '气象工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2022901',
                descr: '气象观测人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2022902',
                descr: '天气预报人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022903',
                descr: '气候监测预测人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022904',
                descr: '应用气象人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2022905',
                descr: '气象服务人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20230',
            descr: '地震工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2023001',
                descr: '地震工程技术人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20231',
            descr: '环境保护工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2023101',
                descr: '环境损害控制工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023102',
                descr: '环境监测工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023103',
                descr: '环境污染治理工程技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2023104',
                descr: '灾害信息员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023105',
                descr: '紧急救助员',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '20233',
            descr: '标准化、计量、质量工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2023301',
                descr:
                  '标准化工程技术人员(从事技术、服务、管理标准化的研究和标准制定、实施、监督、管理的工程技术人员)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023302',
                descr: '计量工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023303',
                descr: '质量工程技术人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20232',
            descr: '安全工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2023201',
                descr:
                  '安全工程技术人员(安全科学技术研究、开发与推广，安全工程设计施工、安全生产运行控制，安全检测、监督、评估，事故调查分析与预测预防)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2023202',
                descr: '安全防范系统安装维护员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2023203',
                descr: '安全防范设计评估师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20234',
            descr: '管理(工业)工程技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2023401',
                descr: '普通工业管理工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023402',
                descr: '系统规划与管理工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023403',
                descr: '设施规划与设计工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023404',
                descr: '生产组织与管理工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023405',
                descr: '质量管理与可靠性控制工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023406',
                descr: '营销工程技术人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2023407',
                descr: '人力资源开发与管理工程技术人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '203',
        descr: '农业技术人员',
        isopen: false,
        item: [
          {
            code: '20301',
            descr: '土壤肥料技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030101',
                descr: '土壤肥料技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20302',
            descr: '植物保护技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030201',
                descr: '植物保护技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20303',
            descr: '园艺技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030301',
                descr: '园艺技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20304',
            descr: '作物遗传育种栽培技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030401',
                descr: '作物遗传育种栽培技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20306',
            descr: '畜牧与草业技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030601',
                descr: '畜牧技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2030602',
                descr: '草业技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20399',
            descr: '其他农业技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2039901',
                descr: '农业技师、指导员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20305',
            descr: '兽医兽药 技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2030501',
                descr: '兽医',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2030502',
                descr: '兽药技术人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '204',
        descr: '飞机和船舶技术人员',
        isopen: false,
        item: [
          {
            code: '20401',
            descr: '旋翼式飞机飞行人员和领航人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2040101',
                descr: '飞行驾驶员',
                level: '6',
                medical_level: '2',
              },
              {
                code: '2040102',
                descr: '飞机机械员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '2040103',
                descr: '飞行领航员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '2040104',
                descr: '飞行通信员',
                level: '6',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20402',
            descr: '船舶指挥和引航人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2040201',
                descr: '甲板部技术人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040202',
                descr: '轮机部技术人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040203',
                descr: '船舶引航员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040204',
                descr: '船长',
                level: '6',
                medical_level: '1',
              },
              {
                code: '2040205',
                descr: '轮机长',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040206',
                descr: '大副',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040207',
                descr: '二副',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040208',
                descr: '三副',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040209',
                descr: '大管轮',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040210',
                descr: '二管轮',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040211',
                descr: '三管轮',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040212',
                descr: '报务员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040213',
                descr: '事务长',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040214',
                descr: '引水员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20403',
            descr: '喷气式民航班机飞行人员和领航人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2040301',
                descr: '民航飞机驾驶员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040302',
                descr: '民航飞机机械员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040303',
                descr: '民航飞行领航员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2040304',
                descr: '民航飞行通信员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '205',
        descr: '卫生专业技术人员',
        isopen: false,
        item: [
          {
            code: '20501',
            descr: '西医医师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050101',
                descr: '内科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050102',
                descr: '外科医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050103',
                descr: '儿科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050104',
                descr: '妇产科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050105',
                descr: '眼科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050106',
                descr: '耳鼻喉科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050107',
                descr: '口腔科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050108',
                descr: '皮肤科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050109',
                descr: '精神科医师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2050110',
                descr: '心理医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050111',
                descr: '传染病科医师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2050112',
                descr: '急诊科医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050113',
                descr: '康复科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050114',
                descr: '麻醉科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050115',
                descr: '病理科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050116',
                descr: '放射科医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050117',
                descr: '核医学医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050118',
                descr: '超声诊断科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050119',
                descr: '放射肿瘤科医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050120',
                descr: '全科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050121',
                descr: '乡村医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050122',
                descr: '妇幼保健医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050123',
                descr: '输(采供)血医师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20503',
            descr: '中西医结合医师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050301',
                descr: '中西医结合医师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20502',
            descr: '中医医师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050201',
                descr: '中医内科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050202',
                descr: '中医外科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050203',
                descr: '中医妇科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050204',
                descr: '中医儿科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050205',
                descr: '中医眼科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050206',
                descr: '中医皮肤科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050207',
                descr: '中医骨伤科医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050208',
                descr: '中医肛肠科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050209',
                descr: '中医耳鼻喉科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050210',
                descr: '针炙科医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050211',
                descr: '推拿按摩科医师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20504',
            descr: '民族医师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050401',
                descr: '民族医师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20505',
            descr: '公共卫生医师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050501',
                descr: '流行病学医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050502',
                descr: '营养与食品卫生医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050503',
                descr: '环境卫生医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050504',
                descr: '职业病医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050505',
                descr: '劳动(职业)卫生医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050506',
                descr: '放射卫生医师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050507',
                descr: '少儿和学校卫生医师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050508',
                descr: '健康管理师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050509',
                descr: '公共营养师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20506',
            descr: '药剂人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050601',
                descr: '西药剂师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050602',
                descr: '中药药师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20507',
            descr: '医疗技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050701',
                descr: '影像技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050702',
                descr: '麻醉技师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050703',
                descr: '病理技师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050704',
                descr: '临床检验技师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050705',
                descr: '公卫检验技师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050706',
                descr: '卫生工程技师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050707',
                descr: '输(采供)血技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050708',
                descr: '放射线之技术人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050709',
                descr: '放射线之修理人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2050710',
                descr: '医学设备管理师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20508',
            descr: '护理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2050801',
                descr: '病房护士',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050802',
                descr: '门诊护士',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050803',
                descr: '急诊护士',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050804',
                descr: '手术室护士',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050805',
                descr: '供应室护士',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050806',
                descr: '社区护士',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2050807',
                descr: '助产士',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2050808',
                descr: '医疗救护员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20599',
            descr: '其他卫生专业技术人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2059901',
                descr: '一般医务行政人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2059902',
                descr: '监狱、看守所医生护理人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '206',
        descr: '经济业务人员',
        isopen: false,
        item: [
          {
            code: '20601',
            descr: '经济计划人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060101',
                descr: '经济计划人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20602',
            descr: '统计人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060201',
                descr: '统计人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20604',
            descr: '审计人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060401',
                descr: '审计人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20603',
            descr: '会计人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060301',
                descr: '会计人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20606',
            descr: '品牌专业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060601',
                descr: '品牌管理师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20607',
            descr: '分析人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060701',
                descr: '职业信息分析师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2060702',
                descr: '调查分析师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2060703',
                descr: '黄金投资分析师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20605',
            descr: '国际商务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2060501',
                descr: '国际商务人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '207',
        descr: '金融业务人员',
        isopen: false,
        item: [
          {
            code: '20701',
            descr: '银行业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2070101',
                descr: '银行货币发行员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070102',
                descr: '银行国库业务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070103',
                descr: '银行外汇管理员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070104',
                descr: '银行清算员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070105',
                descr: '银行信贷员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2070106',
                descr: '银行国际业务处理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070107',
                descr: '银行信托业务处理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070108',
                descr: '银行信用卡业务处理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070109',
                descr: '银行储蓄员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070110',
                descr: '现金运送车司机、点钞员、押送员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20702',
            descr: '保险业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2070201',
                descr: '精算师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070202',
                descr: '保险推销员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2070203',
                descr: '保险理赔员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070204',
                descr: '保险收费员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2070205',
                descr: '保险调查员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2070206',
                descr: '征信人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20703',
            descr: '证券业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2070301',
                descr: '证券发行员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070302',
                descr: '证券交易员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2070303',
                descr: '证券投资顾问',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20704',
            descr: '信用管理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2070401',
                descr: '信用管理师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20799',
            descr: '其他金融业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2079901',
                descr: '金融一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2079902',
                descr: '金融外务员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '208',
        descr: '法律专业人员',
        isopen: false,
        item: [
          {
            code: '20801',
            descr: '法官',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080101',
                descr: '法官',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20802',
            descr: '检察官',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080201',
                descr: '检察官',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20804',
            descr: '公证员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080401',
                descr: '公证员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20805',
            descr: '司法鉴定人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080501',
                descr: '法医',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20806',
            descr: '书记员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080601',
                descr: '书记员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20807',
            descr: '商业犯罪调查处理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080701',
                descr: '商业犯罪调查处理人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20803',
            descr: '律师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2080301',
                descr: '律师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '209',
        descr: '教学人员',
        isopen: false,
        item: [
          {
            code: '20901',
            descr: '高等教育教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090101',
                descr: '高等教育教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20902',
            descr: '中等职业教育教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090201',
                descr: '中等职业教育理论',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2090202',
                descr: '实习指导教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20903',
            descr: '中学教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090301',
                descr: '中学教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20904',
            descr: '小学教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090401',
                descr: '小学教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20905',
            descr: '幼儿教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090501',
                descr: '幼儿教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20906',
            descr: '特殊教育教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090601',
                descr: '特殊教育教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20907',
            descr: '家庭教师',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2090701',
                descr: '家庭教师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '20999',
            descr: '其他教学人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2099901',
                descr: '校工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2099902',
                descr: '军训教官、体育教师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2099903',
                descr: '汽车驾驶训练班教练',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2099904',
                descr: '各项运动教练',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2099905',
                descr: '飞行教官',
                level: '6',
                medical_level: '2',
              },
              {
                code: '2099906',
                descr: '飞行训练学员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '2099907',
                descr: '一般学生',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2099908',
                descr: '学龄前儿童',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '210',
        descr: '文学艺术工作人员',
        isopen: false,
        item: [
          {
            code: '21001',
            descr: '文艺创作和评论人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100101',
                descr: '文学作家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100102',
                descr: '曲艺作家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100103',
                descr: '剧作家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100104',
                descr: '作曲家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100105',
                descr: '词作家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100106',
                descr: '文艺评论员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100107',
                descr: '皮影戏木偶戏作家',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21002',
            descr: '编导和音乐指挥人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100201',
                descr: '电影电视导演',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100202',
                descr: '戏剧导演',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100203',
                descr: '舞蹈编导',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100204',
                descr: '音乐指挥',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100205',
                descr: '武术指导',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21003',
            descr: '演员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100301',
                descr: '电影电视演员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100302',
                descr: '戏剧演员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100303',
                descr: '舞蹈演员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100304',
                descr: '曲艺演员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100305',
                descr: '杂技魔术演员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2100306',
                descr: '歌唱演员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100307',
                descr: '皮影戏演员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100308',
                descr: '木偶戏演员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100309',
                descr: '武打演员',
                level: '5',
                medical_level: '2',
              },
              {
                code: '2100310',
                descr: '特技演员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '2100311',
                descr: '高空杂技飞车飞人演员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '2100312',
                descr: '配音演员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21004',
            descr: '乐器演奏员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100401',
                descr: '民族乐器演奏员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100402',
                descr: '外国乐器演奏员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21005',
            descr: '电影电视制作及舞台专业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100501',
                descr: '电影电视制片',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100502',
                descr: '电影电视场记',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100503',
                descr: '电影电视摄影师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100504',
                descr: '照明师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100505',
                descr: '录音师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100506',
                descr: '剪辑师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100507',
                descr: '美工师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100508',
                descr: '化妆师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100509',
                descr: '置景师',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2100510',
                descr: '道具师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100511',
                descr: '电影电视片发行人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100512',
                descr: '舞台监督',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100513',
                descr: '戏剧制作人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100514',
                descr: '影片商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100515',
                descr: '灯光及音响工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100516',
                descr: '冲片人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100517',
                descr: '洗片人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100518',
                descr: '机械工电工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21006',
            descr: '美术专业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100601',
                descr: '画家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100602',
                descr: '篆刻家',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100603',
                descr: '雕塑家',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2100604',
                descr: '书法家',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100605',
                descr: '陶艺家',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21007',
            descr: '工艺美术专业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2100701',
                descr: '特种工艺设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100702',
                descr: '实用工艺设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100703',
                descr: '现代工艺设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100704',
                descr: '装磺美术设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100705',
                descr: '服装设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100706',
                descr: '室内装饰设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100707',
                descr: '陈列展览设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2100708',
                descr: '广告设计人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '211',
        descr: '体育工作人员',
        isopen: false,
        item: [
          {
            code: '21101',
            descr: '高尔夫球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110101',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110102',
                descr: '运动员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110103',
                descr: '球童',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21102',
            descr: '保龄球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110201',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110202',
                descr: '保龄球球员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21103',
            descr: '桌球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110301',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110302',
                descr: '桌球球员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21104',
            descr: '羽毛球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110401',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110402',
                descr: '羽毛球球员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21105',
            descr: '游泳运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110501',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110502',
                descr: '游泳队员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21106',
            descr: '射箭运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110601',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110602',
                descr: '射箭运动员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21107',
            descr: '网球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110701',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110702',
                descr: '网球球员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21108',
            descr: '垒球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110801',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110802',
                descr: '垒球球员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21110',
            descr: '射击运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111001',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111002',
                descr: '射击人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21109',
            descr: '滑冰运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2110901',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2110902',
                descr: '滑冰运动员',
                level: '2',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21111',
            descr: '民族体育运动(不含竞技性)',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111101',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111102',
                descr: '民族体育活动人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21112',
            descr: '举重运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111201',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111202',
                descr: '举重运动员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21113',
            descr: '篮球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111301',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111302',
                descr: '篮球运动员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21114',
            descr: '排球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111401',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111402',
                descr: '排球运动员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21115',
            descr: '棒球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111501',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111502',
                descr: '棒球运动员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21116',
            descr: '田径运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111601',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2111602',
                descr: '田径运动员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21117',
            descr: '体操运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111701',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2111702',
                descr: '体操运动员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21118',
            descr: '滑雪运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111801',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2111802',
                descr: '滑雪运动员',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21119',
            descr: '帆船运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2111901',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2111902',
                descr: '驾乘人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21120',
            descr: '划船运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112001',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2112002',
                descr: '驾乘人员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21121',
            descr: '泛舟运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112101',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2112102',
                descr: '驾乘人员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21122',
            descr: '巧固球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112201',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2112202',
                descr: '巧固球球员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21123',
            descr: '手球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112301',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2112302',
                descr: '手球运动员',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21124',
            descr: '风浪板运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112401',
                descr: '教练员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2112402',
                descr: '驾乘人员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21126',
            descr: '足球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112601',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2112602',
                descr: '足球运动员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21125',
            descr: '水上摩托车',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112501',
                descr: '教练员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2112502',
                descr: '驾乘人员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21127',
            descr: '曲棍球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112701',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2112702',
                descr: '曲棍球球员',
                level: '5',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21128',
            descr: '冰上曲棍球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112801',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2112802',
                descr: '冰上曲棍球球员',
                level: '6',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21130',
            descr: '乒乓球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113001',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2113002',
                descr: '乒乓球球员',
                level: '2',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21129',
            descr: '橄榄球',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2112901',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2112902',
                descr: '橄榄球球员',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21131',
            descr: '击剑运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113101',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2113102',
                descr: '击剑运动员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21132',
            descr: '水球运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113201',
                descr: '教练员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2113202',
                descr: '水球球员',
                level: '2',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21133',
            descr: '马术运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113301',
                descr: '教练员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2113302',
                descr: '运动员',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21134',
            descr: '摔跤运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113401',
                descr: '教练员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '2113402',
                descr: '摔跤运动员',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21135',
            descr: '拳击运动',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113501',
                descr: '教练员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '2113502',
                descr: '职业拳击运动员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '2113503',
                descr: '业余拳击运动员',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '21136',
            descr: '裁判人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2113601',
                descr: '裁判人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '212',
        descr: '新闻出版、文化工作人员',
        isopen: false,
        item: [
          {
            code: '21201',
            descr: '记者',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120101',
                descr: '文字记者',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120102',
                descr: '摄影记者',
                level: '4',
                medical_level: '1',
              },
              {
                code: '2120103',
                descr: '外勤记者',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2120104',
                descr: '战地记者',
                level: '7',
                medical_level: '3',
              },
              {
                code: '2120105',
                descr: '电视记者',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21202',
            descr: '编辑',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120201',
                descr: '文字编辑',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120202',
                descr: '美术编辑',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120203',
                descr: '技术编辑',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120204',
                descr: '电子出版物编辑',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21204',
            descr: '播音员及节目主持人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120401',
                descr: '播音员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120402',
                descr: '节目主持人',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21203',
            descr: '校对员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120301',
                descr: '校对员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21205',
            descr: '翻译',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120501',
                descr: '翻译',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120502',
                descr: '手语翻译员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21206',
            descr: '图书资料与档案业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120601',
                descr: '图书资料业务人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120602',
                descr: '档案业务人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120603',
                descr: '缩微摄影人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120604',
                descr: '图书馆工作人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21207',
            descr: '考古及文物保护专业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2120701',
                descr: '考古工作者',
                level: '2',
                medical_level: '2',
              },
              {
                code: '2120702',
                descr: '文物鉴定和保管人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2120703',
                descr: '文物保护专业人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '2120704',
                descr: '博物馆工作人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '21299',
            descr: '其他新闻出版、文化工作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2129901',
                descr: '内勤人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '213',
        descr: '宗教职业者',
        isopen: false,
        item: [
          {
            code: '21300',
            descr: '宗教职业者',
            level: '',
            medical_level: '',
            item: [
              {
                code: '2130001',
                descr: '宗教职业者',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2130002',
                descr: '寺庙及教堂管理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2130003',
                descr: '宗教团体工作人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '2130004',
                descr: '僧尼道士传教人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '3',
    descr: '办事人员和有关人员',
    isopen: false,
    item: [
      {
        code: '301',
        descr: '行政办公人员',
        isopen: false,
        item: [
          {
            code: '30101',
            descr: '行政业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3010101',
                descr: '行政业务办公人员(内勤)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010102',
                descr: '行政业务办公人员(外勤)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3010103',
                descr: '工商、税务、海关、城管等特定国家机关行政执法人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3010104',
                descr: '缉私人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3010105',
                descr: '客户服务管理师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30102',
            descr: '行政事务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3010201',
                descr: '秘书',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010202',
                descr: '公务(关)员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010203',
                descr: '收发员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010204',
                descr: '打字员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010205',
                descr: '计算机操作员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3010206',
                descr: '制图员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '302',
        descr: '安全保卫和消防人员',
        isopen: false,
        item: [
          {
            code: '30201',
            descr: '人民警察',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3020101',
                descr: '警务行政及内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3020102',
                descr: '警察(负有巡逻任务者)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3020103',
                descr: '监狱看守所管理人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3020104',
                descr: '交通警察',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3020105',
                descr: '刑警',
                level: '5',
                medical_level: '1',
              },
              {
                code: '3020106',
                descr: '警务特勤',
                level: '6',
                medical_level: '2',
              },
              {
                code: '3020107',
                descr: '防暴警察',
                level: '7',
                medical_level: '2',
              },
              {
                code: '3020108',
                descr: '港口机场警卫及安全人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3020109',
                descr: '警校学生',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '30202',
            descr: '治安保卫人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3020201',
                descr: '保安员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3020202',
                descr: '违禁品检查员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3020203',
                descr: '金融守押员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3020204',
                descr: '治安调查人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '303',
        descr: '邮政和电信业务人员',
        isopen: false,
        item: [
          {
            code: '30301',
            descr: '邮政业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3030101',
                descr: '邮政营业员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030102',
                descr: '邮件分检、接发员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030103',
                descr: '火车、汽车邮件押运员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030104',
                descr: '轮船邮件押运员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3030105',
                descr: '报刊分发员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030106',
                descr: '投递员(外勤)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3030107',
                descr: '投递员(内勤)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030108',
                descr: '邮政储汇员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030109',
                descr: '报刊发行、零售员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030110',
                descr: '集邮业务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030111',
                descr: '邮政业务档案员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030112',
                descr: '邮政设备安装、维护人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030113',
                descr: '包裹邮务人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030114',
                descr: '包裹搬运人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30302',
            descr: '电信业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3030201',
                descr: '电信业务营业员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030202',
                descr: '话务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3030203',
                descr: '报务员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30303',
            descr: '电信通信传输业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3030301',
                descr: '通信设备维护人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030302',
                descr: '电话、通信天、馈线架(敷)设、维修人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3030303',
                descr: '电话电报交换设备安装、维护人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030304',
                descr: '电话机、寻呼机、传真机等用户终端设备维修员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3030305',
                descr: '通信系统供电设备、空调设备安装维护人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3030306',
                descr: '市话障碍处理人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3030307',
                descr: '电信装置维护修理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '3030308',
                descr: '电信工程设施架设人员',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '304',
        descr: '广告从业人员',
        isopen: false,
        item: [
          {
            code: '30401',
            descr: '广告内勤人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3040101',
                descr: '客户主任、统筹、设计师、市场拓展主任',
                level: '1',
                medical_level: '1',
              },
              {
                code: '3040102',
                descr: '撰稿员、一般内勤',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30402',
            descr: '室外工作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3040201',
                descr: '广告业务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3040202',
                descr: '广告招牌绘制人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '3040203',
                descr: '广告片拍摄录制人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3040204',
                descr: '广告招牌架设人员',
                level: '5',
                medical_level: '2',
              },
              {
                code: '3040205',
                descr: '霓虹光管安装及维修人员',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '305',
        descr: '设计和策划人员',
        isopen: false,
        item: [
          {
            code: '30501',
            descr: '展览业工作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3050101',
                descr: '会展设计师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '3050102',
                descr: '会展策划师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30502',
            descr: '景观建设从业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3050201',
                descr: '景观设计师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '306',
        descr: '绘制人员',
        isopen: false,
        item: [
          {
            code: '30601',
            descr: '模型制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3060101',
                descr: '建筑模型设计制作员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '30602',
            descr: '制图人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3060202',
                descr: '动画绘制员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '307',
        descr: '影视从业人员',
        isopen: false,
        item: [
          {
            code: '30701',
            descr: '影视拍摄制作人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3070101',
                descr: '数字视频(DV)策划制作师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '308',
        descr: '物业管理和服务人员',
        isopen: false,
        item: [
          {
            code: '30801',
            descr: '物业管理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '3080101',
                descr: '智能楼宇管理师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '4',
    descr: '商业、服务业人员',
    isopen: false,
    item: [
      {
        code: '401',
        descr: '购销人员',
        isopen: false,
        item: [
          {
            code: '40101',
            descr: '营业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010101',
                descr: '营业员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010102',
                descr: '收银员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40102',
            descr: '推销、展销人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010201',
                descr: '推销员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4010202',
                descr: '出版物发行员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010203',
                descr: '服装模特',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40104',
            descr: '拍卖、典当及租赁业务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010401',
                descr: '旧货、古玩、字画等物品鉴定估价师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010402',
                descr: '拍卖师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010403',
                descr: '典当业务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010404',
                descr: '租赁业务员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40105',
            descr: '废旧物资回收利用人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010501',
                descr: '废旧物资回收挑选工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4010502',
                descr: '废旧物资加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4010503',
                descr: '船舶拆解工(仪表拆解)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4010504',
                descr: '船舶拆解工(机舱、船体拆解)',
                level: '5',
                medical_level: '2',
              },
              {
                code: '4010505',
                descr: '船舶拆解工(油船清洗工)',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '40106',
            descr: '粮油管理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010601',
                descr: '粮油管理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40107',
            descr: '商品监督和市场管理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010701',
                descr: '商品监督员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4010702',
                descr: '市场管理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40199',
            descr: '其他购销人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4019901',
                descr: '医药商品购销员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4019902',
                descr: '中药配方、制剂调配人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4019903',
                descr: '个体摊贩',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40103',
            descr: '采购人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4010301',
                descr: '采购员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4010302',
                descr: '粮油、农副土特产品、蔬菜等商品收购员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4010303',
                descr: '中药购销员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '402',
        descr: '仓储人员',
        isopen: false,
        item: [
          {
            code: '40201',
            descr: '保管人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4020101',
                descr: '保管员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4020102',
                descr: '理货员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4020103',
                descr: '库存商品养护员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020104',
                descr: '保鲜员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020105',
                descr: '冷藏工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40202',
            descr: '储运人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4020201',
                descr: '商品储运员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020202',
                descr: '商品护运员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4020203',
                descr: '医药商品储运员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020204',
                descr: '出版物储运员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020205',
                descr: '货柜场检查员(控制室内)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020206',
                descr: '货柜场检查员(站立于货柜上)',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4020207',
                descr: '起重机操作员(于控制室内)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4020208',
                descr: '起重机操作员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '403',
        descr: '餐饮服务人员',
        isopen: false,
        item: [
          {
            code: '40301',
            descr: '中餐烹饪人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4030101',
                descr: '中式烹调师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4030102',
                descr: '中式面点师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40302',
            descr: '西餐烹饪人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4030201',
                descr: '西式烹调师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4030202',
                descr: '西式面点师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40303',
            descr: '调酒和茶艺人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4030301',
                descr: '调酒师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4030302',
                descr: '茶艺师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4030303',
                descr: '咖啡师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40305',
            descr: '餐厅服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4030501',
                descr: '餐厅服务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4030502',
                descr: '餐具清洗保管员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4030503',
                descr: '经理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4030504',
                descr: '一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4030505',
                descr: '收帐员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40304',
            descr: '营养配餐人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4030401',
                descr: '营养配餐员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '404',
        descr: '饭店、旅游及健身娱乐场所服务人员',
        isopen: false,
        item: [
          {
            code: '40401',
            descr: '饭店服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040101',
                descr: '前厅服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040102',
                descr: '客房服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040103',
                descr: '旅店服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040104',
                descr: '旅馆负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040105',
                descr: '一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040106',
                descr: '外务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040107',
                descr: '收帐员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040108',
                descr: '技工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40402',
            descr: '旅游及公共游览场所服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040201',
                descr: '导游',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040202',
                descr: '公共游览场所服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040203',
                descr: '展览讲解员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040204',
                descr: '插花员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040205',
                descr: '盆景工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040206',
                descr: '假山工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '4040207',
                descr: '园林植物保护工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040208',
                descr: '一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040209',
                descr: '外务人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40403',
            descr: '健身和娱乐场所服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040301',
                descr: '群众性体育活动指导员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040302',
                descr: '体育场地维护、保养工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040303',
                descr: '康乐服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040304',
                descr: '保健按摩师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040305',
                descr: '桑拿业负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040306',
                descr: '桑拿业柜台人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040307',
                descr: '社会文化指导员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040308',
                descr: '芳香保健师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40404',
            descr: '高尔夫球馆',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040401',
                descr: '教练',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040402',
                descr: '球场保养人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040403',
                descr: '维护工人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040404',
                descr: '球童',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40405',
            descr: '保龄球馆',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040501',
                descr: '记分员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040502',
                descr: '柜台人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4040503',
                descr: '机械保护员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40406',
            descr: '撞球馆',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4040601',
                descr: '负责人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4040602',
                descr: '记分员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40410',
            descr: '娱乐场所',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4041001',
                descr: '咖啡厅工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4041002',
                descr: '茶室工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4041003',
                descr: '酒家工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4041004',
                descr: '夜总会工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041005',
                descr: '迪斯科舞厅工作人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4041006',
                descr: '歌厅工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041007',
                descr: '酒吧工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041008',
                descr: '娱乐餐饮业负责人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4041009',
                descr: '保安人员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '4041010',
                descr: '调酒师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041011',
                descr: '乐队人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041012',
                descr: '歌唱人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041013',
                descr: 'DJ',
                level: '3',
                medical_level: '2',
              },
              {
                code: '4041014',
                descr: '迎宾',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041015',
                descr: '舞蹈演艺人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4041016',
                descr: '电子游戏厅工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4041017',
                descr: '网吧管理人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '405',
        descr: '运输服务人员',
        isopen: false,
        item: [
          {
            code: '40501',
            descr: '公路道路运输服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4050101',
                descr: '汽车客运服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050102',
                descr: '汽车货运站务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050103',
                descr: '汽车运输调度员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050104',
                descr: '公路收费及监控员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050105',
                descr: '加油站工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050106',
                descr: '洗车工人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050107',
                descr: '客运车稽核人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050108',
                descr: '搬运工人',
                level: '6',
                medical_level: '2',
              },
              {
                code: '4050109',
                descr: '出租车行、货运行之负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050110',
                descr: '外务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050111',
                descr: '内勤工作人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050112',
                descr: '车辆保管人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050113',
                descr: '汽车加气站操作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40502',
            descr: '铁路客货运输服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4050201',
                descr: '旅客列车乘务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050202',
                descr: '车站客运服务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050203',
                descr: '货运计划员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050204',
                descr: '铁路行李员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050205',
                descr: '车站货运作业组织员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050206',
                descr: '车站货运员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4050207',
                descr: '铁路站长',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050208',
                descr: '铁路票房工作人员、车站检票员、一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050209',
                descr: '铁路播音员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050210',
                descr: '铁路服务台人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050211',
                descr: '铁路车站清洁工人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050212',
                descr: '铁路货运、搬运工人',
                level: '4',
                medical_level: '2',
              },
              {
                code: '4050213',
                descr: '铁路行李搬运工人',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '40503',
            descr: '航空运输服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4050301',
                descr: '航空运输飞行服务员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '4050302',
                descr: '航空运输地面服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050303',
                descr: '航空站站长',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050304',
                descr: '一般内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050305',
                descr: '塔台工作人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050306',
                descr: '关务人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050307',
                descr: '检查人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050308',
                descr: '运务人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4050309',
                descr: '缉私人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050310',
                descr: '清洁工人(航空大厦内、机舱内)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050311',
                descr: '机场内交通司机',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4050312',
                descr: '行李货运搬运工人',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4050313',
                descr: '加添燃料人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050314',
                descr: '飞机外壳洗刷人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050315',
                descr: '清洁工(登高、高墙或天花板)',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050316',
                descr: '跑道维护工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050318',
                descr: '航空货运外务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050319',
                descr: '航空货运报关人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050320',
                descr: '航空货运理货员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4050321',
                descr: '灯塔修理员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050322',
                descr: '喷气式民航班机乘务员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050323',
                descr: '机场运行指挥员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40504',
            descr: '水上运输服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4050401',
                descr: '船舶业务员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050402',
                descr: '船舶客运员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '4050403',
                descr: '港口客运员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4050404',
                descr: '厨师',
                level: '6',
                medical_level: '1',
              },
              {
                code: '4050405',
                descr: '医务人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050406',
                descr: '救难船员',
                level: '6',
                medical_level: '2',
              },
              {
                code: '4050407',
                descr: '实习生',
                level: '6',
                medical_level: '1',
              },
              {
                code: '4050408',
                descr: '码头工人及领班',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050409',
                descr: '堆高机操作员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050410',
                descr: '水上运输理货员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4050411',
                descr: '报关员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '406',
        descr: '医疗卫生辅助服务人员',
        isopen: false,
        item: [
          {
            code: '40601',
            descr: '医疗卫生辅助服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4060101',
                descr: '病案员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4060102',
                descr: '护理员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060103',
                descr: '口腔修复工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060104',
                descr: '配膳员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060105',
                descr: '卫生检验员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060106',
                descr: '医用气体工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060107',
                descr: '药房辅助员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4060108',
                descr: '卫生防疫、妇幼保健员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4060109',
                descr: '医院炊事',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4060110',
                descr: '杂工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '407',
        descr: '社会服务和居民生活服务人员',
        isopen: false,
        item: [
          {
            code: '40701',
            descr: '社会中介服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070101',
                descr: '中介代理人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070102',
                descr: '职业指导员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070103',
                descr: '经纪人(内勤)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070104',
                descr: '创业咨询师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070105',
                descr: '婚姻家庭咨询师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070106',
                descr: '礼仪主持人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070107',
                descr: '体育经纪人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070108',
                descr: '商务策划师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070109',
                descr: '房地产策划师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40702',
            descr: '物业管理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070201',
                descr: '物业管理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40703',
            descr: '供水、供热、供电及生活燃料供应服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070301',
                descr: '工业及饮用水净化处理人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070302',
                descr: '工业及饮用水生产人员(水井工)',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4070303',
                descr: '供水调度员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070304',
                descr: '水表装修工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070305',
                descr: '成型煤生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4070307',
                descr: '锅炉安装、调试、操作、检修人员',
                level: '4',
                medical_level: '2',
              },
              {
                code: '4070308',
                descr: '电信电力系统内勤人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070309',
                descr: '抄表员、收费员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070310',
                descr: '自来水管装修人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4070311',
                descr: '自来水厂水质分析员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4070312',
                descr: '煤气工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070313',
                descr: '煤气管线装修工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4070314',
                descr: '煤气检查员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070315',
                descr: '煤气储气槽、分装厂之工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4070316',
                descr: '瓦斯分装工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '40704',
            descr: '美容美发形象设计人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070401',
                descr: '美容师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070402',
                descr: '美发师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070403',
                descr: '洗头工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070404',
                descr: '形象设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070405',
                descr: '调香师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40705',
            descr: '摄影服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070501',
                descr: '摄影师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070502',
                descr: '冲印师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40706',
            descr: '验光配镜助听人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070601',
                descr: '眼镜验光员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070602',
                descr: '眼镜定配工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4070603',
                descr: '助听器验配师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40707',
            descr: '洗染织补人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070701',
                descr: '洗衣师、烫衣师、织补师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070702',
                descr: '染色师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40708',
            descr: '浴池服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070801',
                descr: '浴池服务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4070802',
                descr: '修脚师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40709',
            descr: '印章刻字人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4070901',
                descr: '刻制印章工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40710',
            descr: '日用机电产品维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071001',
                descr: '家用电子产品维修工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071002',
                descr: '家用电器产品维修工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071003',
                descr: '照相器材维修工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071004',
                descr: '钟表维修工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071005',
                descr: '乐器维修工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071006',
                descr: '自行车维修工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071007',
                descr: '锁具修理工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40711',
            descr: '办公设备维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071101',
                descr: '办公设备维修工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40712',
            descr: '保育、家庭服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071201',
                descr: '保育员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071202',
                descr: '家庭服务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071203',
                descr: '家庭主妇',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071204',
                descr: '孤残儿童护理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40713',
            descr: '环境卫生人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071301',
                descr: '垃圾清运工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071302',
                descr: '保洁员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071303',
                descr: '下水道清洁工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4071304',
                descr: '清洁打蜡工人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071305',
                descr: '高楼外部清洁工、烟囱清洁工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '40714',
            descr: '殡葬服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071401',
                descr: '殡仪服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071402',
                descr: '尸体接运工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4071403',
                descr: '尸体防腐工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4071404',
                descr: '尸体整容工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071405',
                descr: '尸体火化工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071406',
                descr: '墓地管理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40715',
            descr: '个体商业',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071501',
                descr: '橱具商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071502',
                descr: '陶瓷器商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071503',
                descr: '古董商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071504',
                descr: '花卉商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071505',
                descr: '米商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071506',
                descr: '杂货商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071507',
                descr: '玻璃商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071508',
                descr: '果菜商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071509',
                descr: '石材商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071510',
                descr: '建材商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071511',
                descr: '铁材商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071512',
                descr: '木材商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071513',
                descr: '五金商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071514',
                descr: '电器商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071515',
                descr: '水电卫生器材商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071516',
                descr: '机车买卖商(不含修理)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071517',
                descr: '汽车买卖商(不含修理)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071518',
                descr: '车辆器材商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071519',
                descr: '矿物油、香烛买卖商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071520',
                descr: '眼镜商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071521',
                descr: '食品商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071522',
                descr: '文具商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071523',
                descr: '布商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071524',
                descr: '服饰买卖商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071525',
                descr: '鱼贩',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071526',
                descr: '肉贩',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4071527',
                descr: '屠宰',
                level: '4',
                medical_level: '1',
              },
              {
                code: '4071528',
                descr: '药品买卖商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071529',
                descr: '医疗器械仪器商',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071530',
                descr: '化学原料商、农药买卖商',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071531',
                descr: '手工艺品买卖商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071532',
                descr: '银楼珠宝、当铺负责人及工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4071533',
                descr: '农具商',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40716',
            descr: '瓦斯器具店',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071601',
                descr: '负责人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071602',
                descr: '店员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4071604',
                descr: '装饰工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '40717',
            descr: '液化瓦斯零售店',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4071701',
                descr: '负责人及工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4071702',
                descr: '售货商',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '499',
        descr: '其他商业、服务业人员',
        isopen: false,
        item: [
          {
            code: '49900',
            descr: '其他商业、服务人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '4990001',
                descr: '一般物品送货员、快递员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990002',
                descr: '特殊物品送货员、快递员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '4990003',
                descr: '公证行外务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990004',
                descr: '报关行外务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990005',
                descr: '鞋匠、伞匠',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990006',
                descr: '代书(内勤)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990007',
                descr: '勘察师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990008',
                descr: '地磅场工作人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990009',
                descr: '豆制品工艺师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990010',
                descr: '呼叫服务员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990011',
                descr: '牛肉分级员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990012',
                descr: '首饰设计制作员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990013',
                descr: '酿酒师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990014',
                descr: '照明设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990015',
                descr: '模具设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990016',
                descr: '纺织面料设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990017',
                descr: '玩具设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990018',
                descr: '花艺环境设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990019',
                descr: '霓虹灯制作员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '4990020',
                descr: '糖果工艺师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990021',
                descr: '陶瓷产品设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990022',
                descr: '皮具设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990023',
                descr: '珠宝首饰评估师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990024',
                descr: '家具设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990025',
                descr: '地毯设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990026',
                descr: '家用纺织品设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990027',
                descr: '企业文化师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990028',
                descr: '网络课件设计师',
                level: '1',
                medical_level: '1',
              },
              {
                code: '4990029',
                descr: '危险物品送货员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '5',
    descr: '农、林、牧、渔、水利业生产人员',
    isopen: false,
    item: [
      {
        code: '501',
        descr: '种植业生产人员',
        isopen: false,
        item: [
          {
            code: '50102',
            descr: '农业实验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010201',
                descr: '农业实验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010202',
                descr: '农情测报员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50103',
            descr: '园艺作物生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010301',
                descr: '蔬菜园艺工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010302',
                descr: '花卉园艺工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010303',
                descr: '果、茶、桑园艺工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '5010304',
                descr: '菌类园艺工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50104',
            descr: '热带作物生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010401',
                descr: '天然橡胶生产工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010402',
                descr: '剑麻生产工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50105',
            descr: '中药材生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010501',
                descr: '中药材种植员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010502',
                descr: '中药材养殖员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010503',
                descr: '中药材生产管理员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50106',
            descr: '农副林特产品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010601',
                descr: '棉花加工工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5010602',
                descr: '果类产品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5010603',
                descr: '茶叶加工工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010604',
                descr: '蔬菜加工工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010605',
                descr: '竹、藤、麻、棕、草制品加工工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '5010606',
                descr: '特种植物原料加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5010607',
                descr: '糖厂技工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5010608',
                descr: '农作物种子加工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50101',
            descr: '大田作物生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5010101',
                descr: '农夫',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010102',
                descr: '啤酒花生产工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010103',
                descr: '作物种子繁育工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010104',
                descr: '农作物病虫草害防治工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5010105',
                descr: '农场经营者(不亲自作业)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5010107',
                descr: '山、林承包商',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5010108',
                descr: '农业技术指导人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '502',
        descr: '林业生产及野生动植物保护人员',
        isopen: false,
        item: [
          {
            code: '50201',
            descr: '营造林人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5020101',
                descr: '林木种苗工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5020102',
                descr: '造林更新工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5020103',
                descr: '抚育采伐工',
                level: '6',
                medical_level: '1',
              },
              {
                code: '5020104',
                descr: '营林试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5020105',
                descr: '领班',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5020106',
                descr: '实验室育苗栽培人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50203',
            descr: '野生动植物保护及自然保护区人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5020301',
                descr: '野生动物保护员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '5020302',
                descr: '野生植物保护员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5020303',
                descr: '自然保护区巡护监测员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5020304',
                descr: '动植物标本员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50204',
            descr: '木材采运人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5020401',
                descr: '木材采伐工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5020402',
                descr: '集材作业工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5020403',
                descr: '木材水运工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5020404',
                descr: '装卸归楞工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5020405',
                descr: '锯木工人',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5020406',
                descr: '运材车辆之司机及押运人员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '5020407',
                descr: '起重机之操作人员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '5020408',
                descr: '领班、监工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50202',
            descr: '森林资源管护人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5020201',
                descr: '护林员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5020202',
                descr: '森林病虫害防治员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5020203',
                descr: '森林防火员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '5020204',
                descr: '木材防腐师',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '503',
        descr: '畜牧业生产人员',
        isopen: false,
        item: [
          {
            code: '50303',
            descr: '蜜蜂饲养人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030301',
                descr: '蜜蜂饲养工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030302',
                descr: '蜂产品加工工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50304',
            descr: '实验动物饲养人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030401',
                descr: '实验动物饲养工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50305',
            descr: '动物疫病防治人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030501',
                descr: '动物疫病防治员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030502',
                descr: '兽医化验员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5030503',
                descr: '动物检疫检验员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5030504',
                descr: '中兽医员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030505',
                descr: '宠物健康护理人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50306',
            descr: '草业生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030601',
                descr: '草地监护员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5030602',
                descr: '牧草培育、加工人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030603',
                descr: '草坪建植工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50302',
            descr: '家禽饲养人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030201',
                descr: '家禽饲养工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5030202',
                descr: '家禽繁殖工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50301',
            descr: '家畜饲养人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5030101',
                descr: '家畜饲养工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030102',
                descr: '家畜繁殖工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030103',
                descr: '畜牧场经营者(不亲自作业)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5030104',
                descr: '驯犬人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5030105',
                descr: '畜牧工作人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030106',
                descr: '宠物训导师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5030107',
                descr: '有毒动物饲养工(蛇、蝎子、蜈蚣等)',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '504',
        descr: '渔业生产人员',
        isopen: false,
        item: [
          {
            code: '50401',
            descr: '水产养殖人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5040101',
                descr: '水生动物苗种繁育工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040102',
                descr: '水生植物苗种培育工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040103',
                descr: '水生动物饲养工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040104',
                descr: '水生植物栽培工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '5040105',
                descr: '珍珠养殖工(淡水)',
                level: '3',
                medical_level: '2',
              },
              {
                code: '5040106',
                descr: '珍珠养殖工(海水)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5040107',
                descr: '生物饵料培养工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040108',
                descr: '水产养殖潜水工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5040109',
                descr: '渔场经营者(不亲自作业)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5040110',
                descr: '渔场经营者(亲自作业)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040111',
                descr: '热带鱼养殖者、水族馆经营者',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5040112',
                descr: '养殖工人(沿海)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '5040113',
                descr: '水生哺乳动物训养师',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50403',
            descr: '水产品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5040301',
                descr: '水产品原料处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040302',
                descr: '水产品腌熏烤制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5040303',
                descr: '鱼糜及鱼糜制品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040304',
                descr: '鱼粉加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040305',
                descr: '鱼肝油及制品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040306',
                descr: '海藻制碘工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5040307',
                descr: '海藻制醇工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5040308',
                descr: '海藻制胶工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5040309',
                descr: '海藻食品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040310',
                descr: '贝类净化工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50499',
            descr: '其他渔业生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5049901',
                descr: '水产实验人员(室内)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5049902',
                descr: '水产养殖质量管理员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '5049903',
                descr: '水产品质量检验员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50402',
            descr: '水产捕捞及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5040201',
                descr: '水产捕捞工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5040202',
                descr: '渔业生产船员',
                level: '7',
                medical_level: '2',
              },
              {
                code: '5040203',
                descr: '水生动植物采集工',
                level: '3',
                medical_level: '3',
              },
              {
                code: '5040204',
                descr: '渔网具装配工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5040205',
                descr: '捕鱼人(沿海)',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '505',
        descr: '水利设施管理养护人员',
        isopen: false,
        item: [
          {
            code: '50501',
            descr: '河道、水库管养人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5050101',
                descr: '河道修防工(堤、坝施工人员)',
                level: '5',
                medical_level: '1',
              },
              {
                code: '5050102',
                descr: '草皮、防浪林种植养护人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5050103',
                descr: '河道、堤防巡护人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5050104',
                descr: '混凝土工程维修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5050105',
                descr: '土石工程维修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5050106',
                descr: '水工检测工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5050107',
                descr: '建(构)筑物有害动物防治工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5050108',
                descr: '水坝水库管理人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5050109',
                descr: '水域环境养护保洁员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50502',
            descr: '农田灌排工程建设管理维护人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5050201',
                descr: '灌排工程施工、运行、管护人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5050202',
                descr: '渠道维护工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5050203',
                descr: '灌区供水工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5050204',
                descr: '灌溉试验工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50503',
            descr: '水土保持作业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5050301',
                descr: '水土保持防治工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5050302',
                descr: '水土保持测试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5050303',
                descr: '水土保持勘测工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50504',
            descr: '水文勘测作业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5050401',
                descr: '水文勘测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5050402',
                descr: '水文勘测船工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '50599',
            descr: '其他水利设施管理养护人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5059901',
                descr: '水利工程设施人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '599',
        descr: '其他农、林、牧、渔、水利业生产人员',
        isopen: false,
        item: [
          {
            code: '59901',
            descr: '农林专用机械操作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5990101',
                descr: '拖拉机驾驶员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '5990102',
                descr: '联合收割机驾驶员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '5990103',
                descr: '农用运输车驾驶员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5990104',
                descr: '农业机械操作或修理人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '59902',
            descr: '农村能源开发利用人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '5990201',
                descr: '沼气工程施工人员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '5990202',
                descr: '沼气设备安装、调试、检修人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5990203',
                descr: '沼气生产管理人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '5990204',
                descr: '农村节能员(灶、坑、炉、窑建造改造人员)',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5990205',
                descr: '农用太阳能设施工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '5990206',
                descr: '生物质能设备工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '6',
    descr: '生产、运输设备操作人员及有关人员',
    isopen: false,
    item: [
      {
        code: '601',
        descr: '勘测及矿物开采人员',
        isopen: false,
        item: [
          {
            code: '60101',
            descr: '地质勘查人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010101',
                descr: '钻探工',
                level: '6',
                medical_level: '3',
              },
              {
                code: '6010102',
                descr: '坑探工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010103',
                descr: '物探工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010104',
                descr: '地震物探爆炸工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010105',
                descr: '采样工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010106',
                descr:
                  '水文、矿山地质工(从事采送水样、矿样和观测获取数据的人员)',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010107',
                descr: '海洋地质取样工',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6010108',
                descr: '海洋土质试验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6010109',
                descr: '固体岩矿样碎样工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010110',
                descr: '岩矿和古生物样品磨片工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010111',
                descr: '重沙样品淘洗工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010112',
                descr: '劈岩与保管工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010113',
                descr: '地质探测员',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60104',
            descr: '矿物处理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010401',
                descr: '筛选破碎工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010402',
                descr: '重力选矿工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010403',
                descr: '浮选工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010404',
                descr: '磁选工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010405',
                descr: '选矿脱水工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010406',
                descr: '尾矿处理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010407',
                descr: '磨矿工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010408',
                descr: '水煤浆制备工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010409',
                descr: '动力配煤工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010410',
                descr: '工业型煤加工制作人员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60105',
            descr: '钻井人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010501',
                descr: '钻井设备、井架安装工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010502',
                descr: '钻井工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010503',
                descr: '固井工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010504',
                descr: '平台水手',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010505',
                descr: '水下设备操作工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010506',
                descr: '钻勘设备安装换修保养工人',
                level: '5',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60106',
            descr: '石油、天然气开采人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010601',
                descr: '油、气井测试工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010602',
                descr: '采油工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010603',
                descr: '采气工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010604',
                descr: '井下作业工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010605',
                descr: '天然气净化工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010606',
                descr: '油气输送工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010607',
                descr: '油气管道保护工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6010608',
                descr: '行政人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010609',
                descr: '技术员',
                level: '5',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60107',
            descr: '盐业生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010701',
                descr: '海盐晒制工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010702',
                descr: '海盐采收工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010703',
                descr: '湖盐采掘船工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6010704',
                descr: '湖盐采掘爆破工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010705',
                descr: '湖盐脱水工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010706',
                descr: '驳筑、集拆坨盐工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010707',
                descr: '井矿盐采卤工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010708',
                descr: '井矿盐卤水净化工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010709',
                descr: '真空制盐工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010710',
                descr: '冷冻提硝工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010711',
                descr: '苦卤综合利用工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010712',
                descr: '精制盐工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60199',
            descr: '其他勘测矿物开采人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6019901',
                descr: '采石业工人',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6019902',
                descr: '采砂业工人',
                level: '7',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60103',
            descr: '矿物开采人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010301',
                descr: '露天采矿挖掘机司机',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6010302',
                descr: '钻孔机司机',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6010303',
                descr: '井筒冻结工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6010304',
                descr: '矿井开掘工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010305',
                descr: '井下采矿工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010306',
                descr: '支护工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010307',
                descr: '矿山提升机、架空索道及绞车等操作工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010308',
                descr: '矿井机车运输工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010309',
                descr: '矿井通风工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6010310',
                descr: '矿山安全监测工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010311',
                descr: '矿山检查验收工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010312',
                descr: '矿灯、自救器管理工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6010313',
                descr: '火药、雷管等火工品管理工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010314',
                descr: '矿山井下抢险、救灾人员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6010315',
                descr: '矿物开采辅助工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6010316',
                descr: '经营者(不到现场)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6010317',
                descr: '经营者(现场监督者)',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6010318',
                descr: '经理人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010319',
                descr: '海上所有作业人员(潜水人员拒保)',
                level: '6',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '60102',
            descr: '测绘人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6010201',
                descr: '大地测量工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010202',
                descr: '摄影测量工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6010203',
                descr: '地图制图工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6010204',
                descr: '工程测量工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6010205',
                descr: '地籍测绘工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '602',
        descr: '金属冶炼、轧制人员',
        isopen: false,
        item: [
          {
            code: '60201',
            descr: '炼铁人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020101',
                descr: '烧结球团原料工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020102',
                descr: '烧结工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020103',
                descr: '球团焙烧工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020104',
                descr: '烧结成品工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020105',
                descr: '高炉原料工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020106',
                descr: '高炉炉前工',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6020107',
                descr: '高炉运转工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60202',
            descr: '炼钢人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020201',
                descr: '炼钢原料工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020202',
                descr: '平炉炼钢工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020203',
                descr: '转炉炼钢工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020204',
                descr: '电炉炼钢工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020205',
                descr: '炼钢浇铸工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020206',
                descr: '炼钢准备工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020207',
                descr: '整脱模工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020208',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6020209',
                descr: '工程师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6020210',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60203',
            descr: '铁合金冶炼人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020301',
                descr: '铁合金原料工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6020302',
                descr: '铁合金电炉冶炼工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020303',
                descr: '铁合金焙烧工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020304',
                descr: '铁合金湿法冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020305',
                descr: '铁合金炉外法冶炼工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60204',
            descr: '重有色金属冶炼人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020401',
                descr: '重冶备料工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020402',
                descr: '焙烧工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020403',
                descr: '火法冶炼工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020404',
                descr: '湿法冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020405',
                descr: '电解精炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020406',
                descr: '烟气制酸工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60205',
            descr: '轻有色金属冶炼人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020501',
                descr: '氧化铝制取工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020502',
                descr: '铝电解工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020503',
                descr: '镁冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020504',
                descr: '硅冶炼工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60206',
            descr: '稀贵金属冶炼人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020601',
                descr: '钨钼冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020602',
                descr: '钽铌冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020603',
                descr: '钛冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020604',
                descr: '稀土冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020605',
                descr: '贵金属冶炼工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020606',
                descr: '锂冶炼工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60207',
            descr: '半导体材料制备人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020701',
                descr: '半导体原料制备工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020702',
                descr: '多晶制取工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60208',
            descr: '金属轧制人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020801',
                descr: '轧制原料工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020802',
                descr: '金属轧制工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020803',
                descr: '酸洗工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020804',
                descr: '金属材涂层工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6020805',
                descr: '金属材热处理工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020806',
                descr: '焊管工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6020807',
                descr: '精整工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020808',
                descr: '金属材丝拉拔工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020809',
                descr: '金属挤压工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020810',
                descr: '铸轧工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020811',
                descr: '钢丝绳制造工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60209',
            descr: '铸铁管人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6020901',
                descr: '铸管备品工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6020902',
                descr: '铸管工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6020903',
                descr: '铸管精整工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60210',
            descr: '碳素制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6021001',
                descr: '碳素煅烧工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021002',
                descr: '碳素成型工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021003',
                descr: '碳素焙烧工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021004',
                descr: '碳素浸渍工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021005',
                descr: '碳素石墨化工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021006',
                descr: '碳素石墨加工工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021007',
                descr: '碳素纤维工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60211',
            descr: '硬质合金生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6021101',
                descr: '硬质合金混合料制备工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021102',
                descr: '硬质合金成型工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6021103',
                descr: '硬质合金烧结工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6021104',
                descr: '硬质合金精加工工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60299',
            descr: '其他金属冶炼、轧制人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6029901',
                descr: '冶炼风机工',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '603',
        descr: '化工产品生产人员',
        isopen: false,
        item: [
          {
            code: '60303',
            descr: '煤化工生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030301',
                descr: '备煤筛焦工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6030302',
                descr: '焦炉调温工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6030303',
                descr: '炼焦工、焦炉机车司机',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6030304',
                descr: '煤制气工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6030305',
                descr: '煤气储运工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60304',
            descr: '化学肥料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030401',
                descr: '合成氨生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030402',
                descr: '尿素生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030403',
                descr: '硝酸铵生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030404',
                descr: '碳酸氢铵生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030405',
                descr: '硫酸铵生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030406',
                descr: '过磷酸钙生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030407',
                descr: '复合磷肥生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030408',
                descr: '钙镁磷肥生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030409',
                descr: '氯化钾生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030410',
                descr: '微量元素混肥生产工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60305',
            descr: '无机化工产品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030501',
                descr: '硫酸生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030502',
                descr: '硝酸生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030503',
                descr: '盐酸生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030504',
                descr: '磷酸生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030505',
                descr: '纯碱生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030506',
                descr: '烧碱生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030507',
                descr: '氟化盐生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030508',
                descr: '缩聚磷酸盐生产工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6030509',
                descr: '无机化学反应工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030510',
                descr: '高频等离子工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6030511',
                descr: '气体深冷分离工、制氧工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030512',
                descr: '工业气体液化工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030513',
                descr: '炭黑制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6030514',
                descr: '二硫化碳制造工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030515',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6030516',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6030517',
                descr: '一般工人',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60306',
            descr: '基本有机化工产品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030601',
                descr: '脂肪烃生产工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6030602',
                descr: '环烃生产工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6030603',
                descr: '烃类衍生物生产工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60307',
            descr: '合成树脂生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030701',
                descr: '聚乙烯生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030702',
                descr: '聚丙烯生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030703',
                descr: '聚苯乙烯生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030704',
                descr: '聚丁二烯生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030705',
                descr: '聚氯乙烯生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030706',
                descr: '酚醛树脂生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030707',
                descr: '环氧树脂生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030708',
                descr: '丙烯腈-丁二烯-苯乙烯共聚物(ABS)',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60308',
            descr: '合成橡胶生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030801',
                descr: '顺丁橡胶生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030802',
                descr: '乙丙橡胶生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030803',
                descr: '异戊橡胶生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030804',
                descr: '丁腈橡胶生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030805',
                descr: '丁苯橡胶生产工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030806',
                descr: '氯丁橡胶生产工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60302',
            descr: '石油炼制生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030201',
                descr: '燃料油生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030202',
                descr: '润滑油、脂生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030203',
                descr: '石油产品精制工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030204',
                descr: '油制气工',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60309',
            descr: '化学纤维生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030901',
                descr: '化纤聚合工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030902',
                descr: '湿纺原液制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030903',
                descr: '纺丝工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030904',
                descr: '化纤后处理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030905',
                descr: '纺丝凝固浴液配制工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030906',
                descr: '无纺布制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030907',
                descr: '化纤纺丝精密组件工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60312',
            descr: '信息记录材料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031201',
                descr: '片基制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031202',
                descr: '感光材料制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031203',
                descr: '感光材料试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6031204',
                descr: '暗盒制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031205',
                descr: '废片、白银回收工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031206',
                descr: '磁粉制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031207',
                descr: '磁记录材料制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031208',
                descr: '磁记录材料试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031209',
                descr: '感光鼓涂敷工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60311',
            descr: '精细化工产品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031101',
                descr: '有机合成工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031102',
                descr: '农药生物测试试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031103',
                descr: '染料标准工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6031104',
                descr: '染料应用试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6031105',
                descr: '染料拼混工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031106',
                descr: '研磨分散工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031107',
                descr: '催化剂制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031108',
                descr: '催化剂试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6031109',
                descr: '涂料合成树脂工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031110',
                descr: '制漆配色调制工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031111',
                descr: '溶剂制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031112',
                descr: '化学试剂制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031113',
                descr: '化工添加剂制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60313',
            descr: '火药制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031301',
                descr: '单基火药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031302',
                descr: '双基火药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031303',
                descr: '多基火药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031304',
                descr: '黑火药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031305',
                descr: '混合火药制造工',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '60314',
            descr: '炸药制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031401',
                descr: '单质炸药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031402',
                descr: '混合炸药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031403',
                descr: '起爆药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031404',
                descr: '含水炸药制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6031405',
                descr: '火药爆竹制造及处理人(包括爆竹、烟火制造工)',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '60315',
            descr: '林产化工产品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031501',
                descr: '松香工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031502',
                descr: '松节油制品工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031503',
                descr: '活性炭生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031504',
                descr: '栲胶生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6031505',
                descr: '紫胶生产工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031506',
                descr: '栓皮制品工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031507',
                descr: '木材水解工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60316',
            descr: '复合材料加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031601',
                descr: '树脂基复合材料工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031602',
                descr: '橡胶基复合材料工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031603',
                descr: '碳基复合材料工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031604',
                descr: '陶瓷基复合材料工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031605',
                descr: '复合固体推进剂成型工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031606',
                descr: '复合固体发动机装药工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031607',
                descr: '飞机复合材料制品工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60317',
            descr: '日用化学品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031701',
                descr: '制皂工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031702',
                descr: '甘油工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031703',
                descr: '脂肪酸工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031704',
                descr: '洗衣粉成型工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031705',
                descr: '合成洗涤剂制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031706',
                descr: '香料制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031707',
                descr: '香精制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031708',
                descr: '化妆品配制工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031709',
                descr: '牙膏制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031710',
                descr: '油墨制造工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6031711',
                descr: '制胶工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6031712',
                descr: '火柴制造工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6031713',
                descr: '蜡烛制造工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60399',
            descr: '其他化工产品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6039901',
                descr: '电子绝缘与介质材料制造工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60310',
            descr: '合成革生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6031001',
                descr: '合成革制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60301',
            descr: '化工产品生产通用工艺人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6030101',
                descr: '化工原料准备工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6030102',
                descr: '压缩机工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6030103',
                descr: '气体净化工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030104',
                descr: '过滤工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030105',
                descr: '油加热工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6030106',
                descr: '制冷工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030107',
                descr: '蒸发工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030108',
                descr: '蒸馏工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030109',
                descr: '萃取工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030110',
                descr: '吸收工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030111',
                descr: '吸附工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030112',
                descr: '干燥工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030113',
                descr: '结晶工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030114',
                descr: '造粒工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6030115',
                descr: '防腐蚀工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6030116',
                descr: '化工工艺试验工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6030117',
                descr: '化工总控工',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '604',
        descr: '机械制造加工人员',
        isopen: false,
        item: [
          {
            code: '60401',
            descr: '机械冷加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040101',
                descr: '车工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040102',
                descr: '铣工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040103',
                descr: '刨工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040104',
                descr: '磨工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040105',
                descr: '镗工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040106',
                descr: '钻床工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040107',
                descr: '组合机床操作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040108',
                descr: '加工中心操作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040109',
                descr: '制齿工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040110',
                descr: '螺丝纹挤形工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040111',
                descr: '抛磨光工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6040112',
                descr: '拉床工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040113',
                descr: '锯床工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040114',
                descr: '刃具扭制工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040115',
                descr: '弹性元件制造工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6040116',
                descr: '技师',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6040117',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6040118',
                descr: '技工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6040119',
                descr: '车床工(全自动)',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60402',
            descr: '机械热加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040201',
                descr: '铸造工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040202',
                descr: '锻造工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040203',
                descr: '冲压工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040204',
                descr: '剪切工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040205',
                descr: '焊工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040206',
                descr: '金属热处理工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6040207',
                descr: '粉末冶金制造工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60404',
            descr: '冷作钣金加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040401',
                descr: '冷作钣金工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60405',
            descr: '工件表面处理加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040501',
                descr: '镀层工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6040502',
                descr: '涂装工',
                level: '5',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60406',
            descr: '磨料磨具制造加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040601',
                descr: '磨料制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6040602',
                descr: '磨具制造工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60499',
            descr: '其他机械制造加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6049901',
                descr: '电焊条制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6049902',
                descr: '仪器仪表元件制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6049903',
                descr: '真空干燥处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6049904',
                descr: '人造宝石制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6049905',
                descr: '装配工、品管人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60407',
            descr: '航天器件加工成型人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040701',
                descr: '金属软管、波纹管工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6040702',
                descr: '卫星光学冷加工工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6040703',
                descr: '航天器件高温处理工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60403',
            descr: '特种加工设备操作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6040301',
                descr: '电切削工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '605',
        descr: '机电产品装配人员',
        isopen: false,
        item: [
          {
            code: '60501',
            descr: '基础件、部件装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050101',
                descr: '基础件装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050102',
                descr: '部件装配工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60504',
            descr: '电气元件及设备装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050401',
                descr: '铁心叠装工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050402',
                descr: '绝缘制品件装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050403',
                descr: '线圈绕制工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050404',
                descr: '绝缘处理浸渍工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050405',
                descr: '变压器、互感器装配工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6050406',
                descr: '高低压电器装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050407',
                descr: '电焊机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050408',
                descr: '电炉装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050409',
                descr: '电线电缆制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050410',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60505',
            descr: '电子专用设备装配调试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050501',
                descr: '电子专用设备装调工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050502',
                descr: '真空测试工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60507',
            descr: '运输车辆装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050701',
                descr: '汽车(拖拉机)装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050702',
                descr: '铁路机车机械制修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050703',
                descr: '铁路车辆机械制修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050704',
                descr: '铁路机车电气装修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050705',
                descr: '铁路车辆电气装修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050706',
                descr: '铁路机车车辆制动修造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050707',
                descr: '电机车装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050708',
                descr: '摩托车装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050709',
                descr: '助动车、自行车装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050710',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6050711',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6050712',
                descr: '领班、监工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6050713',
                descr: '试车人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050714',
                descr: '汽车模型工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6050715',
                descr: '城轨接触网检修工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60506',
            descr: '仪器仪表装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050601',
                descr: '仪器仪表元器件装调工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050602',
                descr: '力学仪器仪表装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050603',
                descr: '电子仪器仪表装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050604',
                descr: '光电仪器仪表装调工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050605',
                descr: '分析仪器仪表装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050606',
                descr: '计时仪器仪表装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050607',
                descr: '工业自动化仪器仪表与装置装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050608',
                descr: '电工仪器仪表装配工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60508',
            descr: '膜法水处理设备制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050801',
                descr: '功能膜工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050802',
                descr: '电渗析器工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60509',
            descr: '医疗器械装配及假肢与矫形器制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050901',
                descr: '医疗器械装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6050902',
                descr: '假肢制作装配工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6050903',
                descr: '矫形器制作装配工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60510',
            descr: '日用机械电器制造装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051001',
                descr: '空调机装配工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6051002',
                descr: '电冰箱、电冰柜制造装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051003',
                descr: '洗衣机制造装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051004',
                descr: '小型家用电器装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051005',
                descr: '缝纫机制造装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051006',
                descr: '办公小机械制造装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051007',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6051008',
                descr: '一般制造工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051009',
                descr: '包装工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051010',
                descr: '瓦斯器具制造工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60511',
            descr: '五金制品制作装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051101',
                descr: '工具五金制作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051102',
                descr: '建筑五金制品制作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051103',
                descr: '制锁工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051104',
                descr: '铝制品制作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051105',
                descr: '日用五金制品制作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051106',
                descr: '金属家具制造工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051107',
                descr: '金属家具修理工人',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60512',
            descr: '装甲车辆装试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051201',
                descr: '装甲车辆装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051202',
                descr: '装甲车辆装配检验工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051203',
                descr: '装甲车辆驾驶试验工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051204',
                descr: '装甲车辆发动机装试工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60513',
            descr: '枪炮制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051301',
                descr: '火炮装试工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6051302',
                descr: '火炮装试检验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051303',
                descr: '火炮随动系统装试工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051304',
                descr: '火炮随动系统装试检验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051305',
                descr: '火炮膛线制作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051306',
                descr: '枪支装配工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051307',
                descr: '枪管校直工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051308',
                descr: '枪管膛线制作工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60514',
            descr: '弹制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051401',
                descr: '炮弹装配工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6051402',
                descr: '枪弹装配工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6051403',
                descr: '火工装药工',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '60515',
            descr: '引信加工制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051501',
                descr: '引信装试工',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60517',
            descr: '防化器材制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051701',
                descr: '滤毒材料制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051702',
                descr: '防毒器材装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051703',
                descr: '防毒器材试验工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60516',
            descr: '火工品制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051601',
                descr: '雷管制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6051602',
                descr: '索状爆破器材制造工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6051603',
                descr: '火工品装配工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6051604',
                descr: '爆破器材试验工',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '60518',
            descr: '船舶制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051801',
                descr: '船体制造工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6051802',
                descr: '船舶轮机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051803',
                descr: '船舶电气装配工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051804',
                descr: '船舶附件制造工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6051805',
                descr: '船舶木塑帆缆工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051806',
                descr: '船模制造试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051807',
                descr: '工程师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051808',
                descr: '领班',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051809',
                descr: '拆船工人',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60519',
            descr: '航空产品装配与调试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6051901',
                descr: '飞机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051902',
                descr: '飞机系统安装调试工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051903',
                descr: '机载导弹装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051904',
                descr: '航空发动机装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051905',
                descr: '飞机螺旋桨装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051906',
                descr: '飞机军械安装调试工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051907',
                descr: '航空电气安装调试工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051908',
                descr: '飞机发动机附件装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051909',
                descr: '航空仪表装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051910',
                descr: '飞机仪表安装试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051911',
                descr: '航空装配平衡工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051912',
                descr: '飞机无线电设备安装调试工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051913',
                descr: '飞机雷达安装调试工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6051914',
                descr: '飞机特种设备检测与修理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051915',
                descr: '飞机透明件制造胶接装配工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6051916',
                descr: '飞机外场调试与维护工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60520',
            descr: '航空产品试验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6052001',
                descr: '飞机试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052002',
                descr: '机载导弹例行试验工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6052003',
                descr: '航空发动机试车工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052004',
                descr: '飞机螺旋桨试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052005',
                descr: '飞机、发动机附件试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052006',
                descr: '航空环控救生装备试验工(地面试验)',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052007',
                descr: '航空环控救生装备试验工(空中试验)',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6052008',
                descr: '航空仪表试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052009',
                descr: '航空电机电器试验设备调试工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60521',
            descr: '导弹卫星装配测试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6052101',
                descr: '惯性器件装配厂',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6052102',
                descr: '伺服机构装配调试工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6052103',
                descr: '导弹部段装配工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6052104',
                descr: '航天器引信装配工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6052105',
                descr: '弹头装配工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6052106',
                descr: '导弹总体装配工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6052107',
                descr: '卫星总体装配工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60522',
            descr: '火箭发动机装配试验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6052201',
                descr: '液体火箭发动机装配试验工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6052202',
                descr: '固体火箭发动机装配工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6052203',
                descr: '固体火箭发动机试验工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6052204',
                descr: '固体火箭发动机检测工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60523',
            descr: '航天器结构强度温度环境试验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6052301',
                descr: '航天器环境试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6052302',
                descr: '航天器结构强度环境试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6052303',
                descr: '航天器结构高低温环境试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6052304',
                descr: '火箭发动机介质试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6052305',
                descr: '航天器系统试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6052306',
                descr: '空间环境模拟光学装测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052307',
                descr: '空间环境模拟温度试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6052308',
                descr: '空间环境模拟真空试验工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60524',
            descr: '靶场试验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6052401',
                descr: '靶场试射工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6052402',
                descr: '靶场测试工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60599',
            descr: '其他机电产品装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6059901',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6059902',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60502',
            descr: '机械设备装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050201',
                descr: '装配钳工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6050202',
                descr: '工具钳工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60503',
            descr: '动力设备装配人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6050301',
                descr: '汽轮机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050302',
                descr: '内燃机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050303',
                descr: '锅炉设备装配厂',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6050304',
                descr: '电机装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6050305',
                descr: '有关高压电之工作人员',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6050306',
                descr: '数控机床装调维修工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '606',
        descr: '机械设备修理人员',
        isopen: false,
        item: [
          {
            code: '60601',
            descr: '机械设备维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6060101',
                descr: '机修钳工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060102',
                descr: '汽车修理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060103',
                descr: '船舶修理工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6060104',
                descr: '修理保养工人(自行车)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6060105',
                descr: '装配修理工、冷冻修理厂工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060106',
                descr: '汽车玻璃维修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060107',
                descr: '工程机械修理工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60602',
            descr: '仪器仪表修理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6060201',
                descr: '工业自动化仪器仪表与装置修理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6060202',
                descr: '电工仪器仪表修理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060203',
                descr: '精密仪器仪表修理工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60603',
            descr: '民用航空器维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6060301',
                descr: '民用航空器维护人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060302',
                descr: '民用航空器修理人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6060303',
                descr: '机械员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '607',
        descr: '电力设备安装、运行、检修及供电人员',
        isopen: false,
        item: [
          {
            code: '60701',
            descr: '电力设备安装人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070101',
                descr: '水轮机设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070102',
                descr: '锅炉设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070103',
                descr: '汽轮机设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070104',
                descr: '发电机设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070105',
                descr: '热工仪表及控制装置安装试验工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070106',
                descr: '发电厂电气设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070107',
                descr: '电力电缆安装工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6070108',
                descr: '高压线路架设工',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6070109',
                descr: '电力工程内线安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070110',
                descr: '小风电利用工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6070111',
                descr: '微水电利用工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60702',
            descr: '发电运行值班人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070201',
                descr: '水轮发电机值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070202',
                descr: '燃料值班员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6070203',
                descr: '锅炉运行值班员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070204',
                descr: '锅炉辅机值班员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070205',
                descr: '汽轮机运行值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070206',
                descr: '热力网值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070207',
                descr: '电气值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070208',
                descr: '集控值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070209',
                descr: '发电机氢冷值班员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070210',
                descr: '电厂水处理值班员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60703',
            descr: '输电、配电、变电设备值班人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070301',
                descr: '送电、配电线路工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6070302',
                descr: '变电站值班员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6070303',
                descr: '调相机值班员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070304',
                descr: '换流站值班员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60704',
            descr: '电力设备检修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070401',
                descr: '锅炉本体设备检修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6070402',
                descr: '锅炉附属设备检修工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6070403',
                descr: '汽轮机本体设备检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070404',
                descr: '汽轮机附属设备检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070405',
                descr: '发电厂电动机检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070406',
                descr: '水轮机检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070407',
                descr: '水电站水力机械试验工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070408',
                descr: '水电自动装置检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070409',
                descr: '高压线路带电检修工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6070410',
                descr: '变压器检修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6070411',
                descr: '变电设备检修工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6070412',
                descr: '电气试验员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070413',
                descr: '继电保护员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070414',
                descr: '电力装置维护修理工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60705',
            descr: '供用电人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070501',
                descr: '电力负荷控制员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070502',
                descr: '用电监察员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6070503',
                descr: '装表核算收费员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6070504',
                descr: '装表接电工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070505',
                descr: '电能计量装置检修工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60706',
            descr: '生活生产电力设备安装、操作、修理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6070601',
                descr: '变电设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070602',
                descr: '变配电室值班电工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070603',
                descr: '常用电机检修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6070604',
                descr: '牵引电力线路安装维护工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6070605',
                descr: '维修电工',
                level: '5',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '608',
        descr: '电子元器件与设备制造、装配调试及维修人员',
        isopen: false,
        item: [
          {
            code: '60801',
            descr: '电子器件制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6080101',
                descr: '真空电子器件化学零件制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080102',
                descr: '电极丝制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080103',
                descr: '真空电子器件金属零件制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080104',
                descr: '电子真空镀膜工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080105',
                descr: '真空电子器件装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080106',
                descr: '真空电子器件装调工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080107',
                descr: '液晶显示器件制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080108',
                descr: '单晶片加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080109',
                descr: '半导体芯片制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080110',
                descr: '半导体分立器件、集成电路装调工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080111',
                descr: '电子用水制备工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080112',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080113',
                descr: '领班、监工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080114',
                descr: '制造工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60802',
            descr: '电子元件制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6080201',
                descr: '电阻器制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080202',
                descr: '电容器制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080203',
                descr: '微波铁氧体元器件制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080204',
                descr: '石英晶体生长设备操作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080205',
                descr: '压电石英晶片加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080206',
                descr: '石英晶体元器件制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080207',
                descr: '电声器件制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080208',
                descr: '水声换能器制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080209',
                descr: '专用继电器制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080210',
                descr: '高频电感器件制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080211',
                descr: '接插件制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080212',
                descr: '磁头制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080213',
                descr: '电子产品制版工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080214',
                descr: '印制电路制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080215',
                descr: '薄膜加热器制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080216',
                descr: '激光头制造工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60803',
            descr: '电池制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6080301',
                descr: '铅酸蓄电池制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6080302',
                descr: '碱性蓄电池制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6080303',
                descr: '原电池制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080304',
                descr: '热电池制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080305',
                descr: '太阳电池制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080306',
                descr: '电池制造(技师)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080307',
                descr: '电池制造(工人)',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60804',
            descr: '电子设备装配调试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6080401',
                descr: '无线电设备机械装校工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080402',
                descr: '电子设备装接工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080403',
                descr: '无线电调试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080404',
                descr: '雷达装配工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080405',
                descr: '雷达调试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080406',
                descr: '电子精密机械装调工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080407',
                descr: '电子计算机(微机)调试工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6080408',
                descr: '有线通信传输设备调试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080409',
                descr: '通讯交换设备调试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080410',
                descr: '电源调试工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080411',
                descr: '激光机装调工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6080412',
                descr: '激光全息工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6080413',
                descr: '铁路通信组调工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080414',
                descr: '铁路信号组调工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080415',
                descr: '铁路电控组调工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080416',
                descr: '包装工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6080417',
                descr: '集成电路测试员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60805',
            descr: '电子产品维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6080501',
                descr: '电子计算机(微机)维修工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6080502',
                descr: '修理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6080503',
                descr: '家电用品维修人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '609',
        descr: '橡胶和塑料制品生产人员',
        isopen: false,
        item: [
          {
            code: '60901',
            descr: '橡胶制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6090101',
                descr: '橡胶制品配料工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6090102',
                descr: '橡胶炼胶工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6090103',
                descr: '橡胶半成品制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6090104',
                descr: '橡胶成型工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6090105',
                descr: '橡胶硫化工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6090106',
                descr: '废胶再生工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6090107',
                descr: '轮胎翻修工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '60902',
            descr: '塑料制品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6090201',
                descr: '塑料制品配料工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6090202',
                descr: '塑料制品成型制作工(自动)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6090203',
                descr: '塑胶射出成型人员(其它)',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '60999',
            descr: '其他橡胶和塑料制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6099901',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6099902',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6099903',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6099904',
                descr: '一般工人',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '610',
        descr: '纺织、针织、印染人员',
        isopen: false,
        item: [
          {
            code: '61001',
            descr: '纤维预处理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6100101',
                descr: '纤维验配工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6100102',
                descr: '开清棉工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100103',
                descr: '纤维染色工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100104',
                descr: '加湿软麻工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100105',
                descr: '选剥煮茧工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100106',
                descr: '纤维梳理工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100107',
                descr: '并条工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100108',
                descr: '粗纱工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100109',
                descr: '绢纺精炼工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61003',
            descr: '织造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6100301',
                descr: '整经工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100302',
                descr: '浆纱工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100303',
                descr: '穿经工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100304',
                descr: '织布工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100305',
                descr: '织物验修工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100306',
                descr: '意匠纹版工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6100307',
                descr: '织造工人',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61002',
            descr: '纺纱人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6100201',
                descr: '细纱工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100202',
                descr: '简并摇工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100203',
                descr: '捻线工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100204',
                descr: '制线工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100205',
                descr: '缫丝工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61004',
            descr: '针织人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6100401',
                descr: '纬编工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100402',
                descr: '经编工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100403',
                descr: '横机工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100404',
                descr: '织袜工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100405',
                descr: '铸、钳针工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61005',
            descr: '印染人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6100501',
                descr: '坯布检查处理工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6100502',
                descr: '印染烧毛工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6100503',
                descr: '煮炼漂工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100504',
                descr: '印染洗涤工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6100505',
                descr: '印染烘干工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100506',
                descr: '印染丝光工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100507',
                descr: '印染定型工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100508',
                descr: '纺织针织染色工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100509',
                descr: '印花工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100510',
                descr: '印染雕刻制版工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100511',
                descr: '印染后整理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100512',
                descr: '印染成品定等装潢工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6100513',
                descr: '印染染化料配制工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6100514',
                descr: '工艺染织制作工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6100515',
                descr: '染整工人',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61099',
            descr: '其他纺织、针织、印染人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6109901',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '611',
        descr: '裁剪缝纫和皮革、毛皮制品加工制作人员',
        isopen: false,
        item: [
          {
            code: '61101',
            descr: '裁剪缝纫人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6110101',
                descr: '裁剪工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110102',
                descr: '缝纫工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110103',
                descr: '缝纫品整型工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110104',
                descr: '裁缝',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6110105',
                descr: '剧装工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110106',
                descr: '设计师',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61102',
            descr: '鞋帽制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6110201',
                descr: '制鞋工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6110202',
                descr: '制帽工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61103',
            descr: '皮革、毛皮加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6110301',
                descr: '皮革加工工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6110302',
                descr: '毛皮加工工',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61104',
            descr: '缝纫制品再加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6110401',
                descr: '缝纫制品充填处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110402',
                descr: '胶制服装上胶工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6110403',
                descr: '服装水洗工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '612',
        descr: '粮油、食品、饮料生产加工及饲料生产加工人员',
        isopen: false,
        item: [
          {
            code: '61201',
            descr: '粮油生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120101',
                descr: '制米工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120102',
                descr: '制粉工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120103',
                descr: '制油工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61202',
            descr: '制糖和糖制品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120201',
                descr: '食糖制造工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6120202',
                descr: '糖果制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120203',
                descr: '巧克力制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61203',
            descr: '乳品、冷食品及罐头、饮料制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120301',
                descr: '乳品预处理工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120302',
                descr: '乳品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120303',
                descr: '冷食品制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120304',
                descr: '速冻食品制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120305',
                descr: '食品罐头加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120306',
                descr: '饮料制作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61204',
            descr: '酿酒人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120401',
                descr: '白酒酿造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120402',
                descr: '啤酒酿造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120403',
                descr: '黄酒酿造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120404',
                descr: '果露酒酿造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120405',
                descr: '洒精制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61205',
            descr: '食品添加剂及调味品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120501',
                descr: '酶制剂制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120502',
                descr: '柠檬酸制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120503',
                descr: '酱油酱类制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120504',
                descr: '食醋制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120505',
                descr: '酱腌菜制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120506',
                descr: '食用调料制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120507',
                descr: '味精制作工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61207',
            descr: '屠宰加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120701',
                descr: '猪屠宰加工工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6120702',
                descr: '牛羊屠宰加工工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6120703',
                descr: '肠衣工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120704',
                descr: '禽类屠宰加工工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61206',
            descr: '粮油食品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120601',
                descr: '糕点、面包烘焙工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120602',
                descr: '糕点装饰工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6120603',
                descr: '米面主食制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120604',
                descr: '油脂制品工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120605',
                descr: '植物蛋白制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120606',
                descr: '豆制品制作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61208',
            descr: '肉、蛋食品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120801',
                descr: '熟肉制品加工工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120802',
                descr: '蛋品及再制蛋品加工工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61209',
            descr: '饲料生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6120901',
                descr: '饲料原料清理上料工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120902',
                descr: '饲料粉碎工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120903',
                descr: '饲料配料混合工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120904',
                descr: '饲料制粒工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6120905',
                descr: '饲料添加剂预混工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6120906',
                descr: '饲料厂中央控制室操作工',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61299',
            descr: '其他粮油、食品、饮料生产加工及饲料生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6129901',
                descr: '制造工人',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6129902',
                descr: '冰块制造工人',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6129903',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6129904',
                descr: '领班、监工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6129905',
                descr: '装罐工人',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '613',
        descr: '烟草及其制品加工人员',
        isopen: false,
        item: [
          {
            code: '61301',
            descr: '原烟复烤人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6130101',
                descr: '烟叶调制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6130102',
                descr: '烟叶分级工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6130103',
                descr: '挂杆复烤工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6130104',
                descr: '打叶复烤工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6130105',
                descr: '烟叶回潮工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6130106',
                descr: '烟叶发酵工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61302',
            descr: '卷烟生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6130201',
                descr: '烟叶制丝工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6130202',
                descr: '膨胀烟丝工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6130203',
                descr: '白肋烟处理工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6130204',
                descr: '烟草薄片工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6130205',
                descr: '卷烟卷接工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61303',
            descr: '烟用醋酸纤维丝束滤棒制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6130301',
                descr: '烟用二醋片制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6130302',
                descr: '烟用丝束制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6130303',
                descr: '滤棒工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '614',
        descr: '药品生产人员',
        isopen: false,
        item: [
          {
            code: '61401',
            descr: '合成药物制造人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6140101',
                descr: '化学合成制药工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61402',
            descr: '生物技术制药(品)人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6140201',
                descr: '生化药品制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6140202',
                descr: '发酵工程制药工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6140203',
                descr: '疫苗制品工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6140204',
                descr: '血液制品工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6140205',
                descr: '基因工程产品工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61403',
            descr: '药物制剂人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6140301',
                descr: '药物制剂工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6140302',
                descr: '淀粉葡萄糖制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61404',
            descr: '中药制药人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6140401',
                descr: '中药炮制与配制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6140402',
                descr: '中药液体制剂工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6140403',
                descr: '中药固体制剂工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '615',
        descr: '木材加工、人造板生产及木材制品制作人员',
        isopen: false,
        item: [
          {
            code: '61502',
            descr: '人造板生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6150201',
                descr: '胶合板工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6150202',
                descr: '纤维板工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6150203',
                descr: '刨花板工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6150204',
                descr: '人造板制胶工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6150205',
                descr: '装饰层压板工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6150206',
                descr: '人造板饰面工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61501',
            descr: '木材加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6150101',
                descr: '锯木工人',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6150102',
                descr: '木材干燥工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6150103',
                descr: '木材工厂现场之职员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6150104',
                descr: '领班',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150105',
                descr: '分级员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150106',
                descr: '检查员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150107',
                descr: '标记员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150108',
                descr: '磅秤员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150109',
                descr: '木材供应站管理人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6150110',
                descr: '木材供应站营业员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6150111',
                descr: '仓库管理员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150112',
                descr: '防腐剂工人',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6150113',
                descr: '木材储藏槽工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6150114',
                descr: '木材搬运工人',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6150115',
                descr: '吊车操作人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61503',
            descr: '木材制品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6150301',
                descr: '手工木工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6150302',
                descr: '机械木工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6150303',
                descr: '精细木工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150304',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150305',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6150306',
                descr: '木制家具制造工人',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6150307',
                descr: '木制家具修理工人',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '616',
        descr: '制浆、造纸和纸制品生产加工人员',
        isopen: false,
        item: [
          {
            code: '61601',
            descr: '制浆人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6160101',
                descr: '制浆备料工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6160102',
                descr: '制浆设备操作工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6160103',
                descr: '制浆废液回收利用工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61602',
            descr: '造纸人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6160201',
                descr: '造纸工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6160202',
                descr: '纸张整饰工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6160203',
                descr: '宣纸书画纸制作工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61603',
            descr: '纸制品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6160301',
                descr: '瓦楞纸箱制作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6160302',
                descr: '纸盒制作工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61699',
            descr: '其他制浆、造纸和纸制品生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6169901',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6169902',
                descr: '领班、监工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '617',
        descr: '建筑材料生产加工人员',
        isopen: false,
        item: [
          {
            code: '61701',
            descr: '水泥及水泥制品生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170101',
                descr: '水泥生产制造工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6170102',
                descr: '水泥制品工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6170103',
                descr: '石灰焙烧工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6170104',
                descr: '工程师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6170105',
                descr: '技师',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6170106',
                descr: '领班',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6170107',
                descr: '一般工人',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6170108',
                descr: '采掘工',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6170109',
                descr: '爆破工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6170110',
                descr: '陶瓷、木炭、砖块制造工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61702',
            descr: '墙体屋面材料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170201',
                descr: '砖、瓦生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6170202',
                descr: '加气混凝土制品工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6170203',
                descr: '纸面石膏板生产工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6170204',
                descr: '石膏浮雕板工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6170205',
                descr: '监工(不参与制造过程)',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61703',
            descr: '建筑防水密封材料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170301',
                descr: '油毡生产工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6170302',
                descr: '高分子防水卷材生产工',
                level: '3',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61704',
            descr: '建筑保温及吸音材料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170401',
                descr: '保温材料制造工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170402',
                descr: '吸音材料生产工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170403',
                descr: '珍珠岩制造工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61706',
            descr: '非金属矿及其制品生产加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170601',
                descr: '云母制品加工工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170602',
                descr: '石棉制品工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6170603',
                descr: '高岭土制品工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170604',
                descr: '金刚石制品工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6170605',
                descr: '人工合成晶体工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61705',
            descr: '装饰石材生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170501',
                descr: '装饰石材生产工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61707',
            descr: '耐火材料生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6170701',
                descr: '耐火原料加工工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6170702',
                descr: '耐火材料成型工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6170703',
                descr: '耐火材料烧成工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170704',
                descr: '耐火制品浸油工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6170705',
                descr: '耐火纤维制品工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '618',
        descr: '玻璃、陶瓷、搪瓷及其制品生产加工人员',
        isopen: false,
        item: [
          {
            code: '61801',
            descr: '玻璃熔制人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6180101',
                descr: '玻璃配料工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180102',
                descr: '玻璃熔化工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6180103',
                descr: '玻璃制板及玻璃成型工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6180104',
                descr: '玻璃加工工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6180105',
                descr: '玻璃制品装饰加工工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6180106',
                descr: '技师',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180107',
                descr: '监工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61803',
            descr: '石英玻璃制品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6180301',
                descr: '石英玻璃制品加工工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61802',
            descr: '玻璃纤维及制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6180201',
                descr: '玻璃纤维制品工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6180202',
                descr: '玻璃钢制品工',
                level: '4',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '61804',
            descr: '陶瓷制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6180401',
                descr: '陶瓷原料准备工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180402',
                descr: '陶瓷成型工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180403',
                descr: '陶瓷烧成工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180404',
                descr: '陶瓷装饰工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180405',
                descr: '匣钵、模型制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180406',
                descr: '古建琉璃工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61805',
            descr: '搪瓷制品生产人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6180501',
                descr: '搪瓷釉浆熔制工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180502',
                descr: '搪瓷坯体制作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6180503',
                descr: '搪瓷涂搪烧成工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6180504',
                descr: '搪瓷花版饰花工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '619',
        descr: '广播影视制品制作、播放及文物保护作业人员',
        isopen: false,
        item: [
          {
            code: '61901',
            descr: '影视制品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6190101',
                descr: '影视置景制作员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6190102',
                descr: '影视服装员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6190103',
                descr: '影视舞台烟火特效员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6190104',
                descr: '影视动画制作员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6190105',
                descr: '影视木偶制作员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190106',
                descr: '电影洗印员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61902',
            descr: '音像制品制作复制人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6190201',
                descr: '唱片制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6190202',
                descr: '唱片检听工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190203',
                descr: '音像带复制工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6190204',
                descr: '光盘复制工',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61903',
            descr: '广播影视舞台设备安装调试及运行操作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6190301',
                descr: '照明设备操作员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190302',
                descr: '影视设备机械员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190303',
                descr: '广播电视天线工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6190304',
                descr: '有线广播电视机线员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6190305',
                descr: '音响调音员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6190306',
                descr: '舞台音响效果工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61904',
            descr: '电影放映人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6190401',
                descr: '电影放映员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190402',
                descr: '拷贝检片员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190403',
                descr: '拷贝字幕员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190404',
                descr: '电影院售票员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6190405',
                descr: '跑片员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '61905',
            descr: '文物保护作业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6190501',
                descr: '考古发掘工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6190502',
                descr: '文物修复工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6190503',
                descr: '文物拓印工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6190504',
                descr: '古旧书画修复工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '620',
        descr: '印刷人员',
        isopen: false,
        item: [
          {
            code: '62001',
            descr: '印前处理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6200101',
                descr: '平版制版工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200102',
                descr: '凸版制版工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6200103',
                descr: '凹版制版工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6200104',
                descr: '孔版制版工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200105',
                descr: '印前制作员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62002',
            descr: '印刷操作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6200201',
                descr: '平版印刷工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200202',
                descr: '凸版印刷工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200203',
                descr: '凹版印刷工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200204',
                descr: '孔版 印刷工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200205',
                descr: '木刻水印印制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200206',
                descr: '珂罗版印制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200207',
                descr: '盲文印制工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62003',
            descr: '印后制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6200301',
                descr: '装订工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6200302',
                descr: '印品整饰工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '621',
        descr: '工艺、美术品制作人员',
        isopen: false,
        item: [
          {
            code: '62101',
            descr: '珠宝首饰加工制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210101',
                descr: '宝石琢磨工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210102',
                descr: '贵金属首饰制作工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62102',
            descr: '地毯制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210201',
                descr: '地毯制作工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62104',
            descr: '漆器工艺品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210401',
                descr: '漆器制胎工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6210402',
                descr: '彩绘雕填制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210403',
                descr: '漆器镶嵌工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62103',
            descr: '玩具制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210301',
                descr: '金属、塑料、木制玩具装配工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210302',
                descr: '布绒玩具制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210303',
                descr: '搪塑玩具制作工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62105',
            descr: '抽纱刺绣工艺品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210501',
                descr: '机绣工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210502',
                descr: '手绣制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210503',
                descr: '抽纱挑编工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62106',
            descr: '金属工艺品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210601',
                descr: '景泰蓝制作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6210602',
                descr: '金属摆件工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6210603',
                descr: '金属手工艺品加工工人',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62107',
            descr: '雕刻工艺品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210701',
                descr: '金属工艺品雕刻工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6210702',
                descr: '竹木制手工艺品雕刻工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62199',
            descr: '其他工艺、美术品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6219901',
                descr: '民间工艺品制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6219902',
                descr: '人造花制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6219903',
                descr: '工艺画制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6219904',
                descr: '烟花爆竹制作工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6219905',
                descr: '竹木制手工艺品加工工人',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6219906',
                descr: '布类纸品工艺品加工工人',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6219907',
                descr: '矿石手工艺品加工人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62108',
            descr: '美术品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6210801',
                descr: '装饰美工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210802',
                descr: '雕塑翻制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210803',
                descr: '壁画制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210804',
                descr: '油画外框制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210805',
                descr: '装裱工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6210806',
                descr: '版画制作工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '622',
        descr: '文化教育、体育用品制作人员',
        isopen: false,
        item: [
          {
            code: '62201',
            descr: '文教用品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6220101',
                descr: '墨制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220102',
                descr: '墨水制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220103',
                descr: '墨汁制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220104',
                descr: '绘图仪器制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220105',
                descr: '静电复印机消耗材制造工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220106',
                descr: '毛笔制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220107',
                descr: '自来水笔制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220108',
                descr: '圆珠笔制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220109',
                descr: '铅笔制造工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220110',
                descr: '印泥制作工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62202',
            descr: '体育用品制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6220201',
                descr: '制球工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220202',
                descr: '球拍、球网制作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6220203',
                descr: '健身器材制作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62203',
            descr: '乐器制作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6220301',
                descr: '钢琴及键盘乐器制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220302',
                descr: '提琴制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220303',
                descr: '管乐器制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220304',
                descr: '民族拉弦、弹拨乐器制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220305',
                descr: '吹奏乐器制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220306',
                descr: '打击乐器制作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6220307',
                descr: '电声乐器制作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '623',
        descr: '工程施工人员',
        isopen: false,
        item: [
          {
            code: '62302',
            descr: '砌筑人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230201',
                descr: '砌筑工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230202',
                descr: '洗石工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230203',
                descr: '砌砖匠',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230204',
                descr: '泥水匠',
                level: '',
                medical_level: '1',
              },
              {
                code: '6230205',
                descr: '磨石工人',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62301',
            descr: '土石方施工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230101',
                descr: '凿岩工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6230102',
                descr: '爆破工',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6230103',
                descr:
                  '土石方机械操作工(推土、铲运机驾驶员、挖掘机驾驶员、打桩工、铲运机操作',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '62303',
            descr: '混凝土配制及制品加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230301',
                descr: '混凝土工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230302',
                descr: '混凝土制品模具工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230303',
                descr: '混凝土搅拌机械操作工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62304',
            descr: '钢筋加工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230401',
                descr: '钢筋工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '62305',
            descr: '施工架子搭设人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230501',
                descr: '架子工',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '62307',
            descr: '装饰装修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230701',
                descr: '装饰装修工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230702',
                descr: '室内成套设施装饰工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6230703',
                descr: '油漆工、喷漆工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6230704',
                descr: '铝门窗安装工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230705',
                descr: '石棉瓦或浪板安装工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230706',
                descr: '设计制图人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6230707',
                descr: '地毯之装设人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6230708',
                descr: '室内装潢人员(不含木工、油漆工)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6230709',
                descr: '室外装潢人员',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6230710',
                descr: '承包商、监工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6230711',
                descr: '铁门窗制造安装工人',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6230712',
                descr: '木工',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6230713',
                descr: '安装玻璃幕墙工人',
                level: '7',
                medical_level: '3',
              },
            ],
            isopen: false,
          },
          {
            code: '62306',
            descr: '工程防水人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230601',
                descr: '防水工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230602',
                descr: '防渗墙工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230603',
                descr: '排水工程人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62308',
            descr: '古建筑修建人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230801',
                descr: '古建筑结构施工工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6230802',
                descr: '古建筑装饰工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62309',
            descr: '筑路、养护、维修人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6230901',
                descr: '筑路机械操作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6230902',
                descr: '筑路、养护工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6230903',
                descr: '线桥专用机械操作工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6230904',
                descr: '铁道线路工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6230905',
                descr: '桥梁工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6230906',
                descr: '隧道工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6230907',
                descr: '铁路舟桥工',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6230908',
                descr: '道岔制修工',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6230909',
                descr: '枕木处理工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230910',
                descr: '铁路平交道看守人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6230911',
                descr: '铁路修护厂技工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6230912',
                descr: '铁路铺设领班',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6230913',
                descr: '管道铺设及维护工人',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6230914',
                descr: '高速公路工程人员',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6230915',
                descr: '电线架设及维护工人',
                level: '5',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '62310',
            descr: '工程设备安装人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6231001',
                descr: '机械设备安装工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6231002',
                descr: '电气设备安装工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6231003',
                descr: '管工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6231004',
                descr: '防火系统、警报器安装人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6231005',
                descr: '电梯、升降机安装工人(非高空作业)',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6231006',
                descr: '电梯、升降机安装工人(高空作业)',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6231007',
                descr: '电梯、升降机修理及维护工人',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62399',
            descr: '其他工程施工人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6239901',
                descr: '中小型施工机械操作工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6239902',
                descr: '建筑工程车辆驾驶员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6239903',
                descr: '建筑工程车辆机械操作员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6239904',
                descr: '监工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6239905',
                descr: '测量员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6239906',
                descr: '拆屋、迁屋工人',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6239907',
                descr: '道路工程机械操作员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6239908',
                descr: '道路工程车辆驾驶员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6239909',
                descr: '工地看守员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6239910',
                descr: '海湾港口工程人员',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6239911',
                descr: '水坝工程人员、挖井工程人员',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6239912',
                descr: '潜水工作人员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6239913',
                descr: '挖泥船工人',
                level: '5',
                medical_level: '2',
              },
              {
                code: '6239914',
                descr: '工地推车工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '624',
        descr: '运输设备操作人员及有关人员',
        isopen: false,
        item: [
          {
            code: '62401',
            descr: '公(道)路运输机械设备操作及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6240103',
                descr: '出租车、救护车司机',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240104',
                descr: '游览车司机及服务员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240105',
                descr: '客运车司机及服务员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240106',
                descr: '小型客货两用车司机',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240108',
                descr: '人力三轮车夫',
                level: '3',
                medical_level: '2',
              },
              {
                code: '6240109',
                descr: '机动三轮车夫',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6240110',
                descr: '营业用货车司机、随车工人',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240111',
                descr: '砂石车司机、随车工人',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240112',
                descr: '工程卡车司机、随车人员',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6240113',
                descr: '液化、氧化油罐车司机、随车工人',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240114',
                descr: '货柜车司机、随车人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240115',
                descr: '有摩托车驾照人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62402',
            descr: '铁路、地铁运输机械设备操作及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6240201',
                descr: '车站行车作业员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6240202',
                descr: '车站运转作业计划员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6240203',
                descr: '车号员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6240204',
                descr: '驼峰设备操作员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6240205',
                descr: '车站调车作业员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240206',
                descr: '列车运转乘务员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6240207',
                descr: '机车驾驶员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240208',
                descr: '机车调度员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6240209',
                descr: '发电车乘务员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240210',
                descr: '机车燃料填充员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240211',
                descr: '救援机械操作员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240212',
                descr: '列车轴温检测员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6240213',
                descr: '铁路通信工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240214',
                descr: '铁路电源工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240215',
                descr: '铁路信号工',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240216',
                descr: '铁路机工',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62403',
            descr: '民用航空设备操作及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6240301',
                descr: '航空通信雷达导航员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6240302',
                descr: '航空通信雷达设备维护保养员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240303',
                descr: '航空油料员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240304',
                descr: '航空摄影员',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6240305',
                descr: '航空器材员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240306',
                descr: '航空气象员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62404',
            descr: '水上运输设备操作及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6240401',
                descr: '水手长、水手',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240402',
                descr: '木匠',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240403',
                descr: '铜匠',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240404',
                descr: '船舶机工',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240405',
                descr: '船舶轮机员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240406',
                descr: '船舶加油',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240407',
                descr: '无线电航标操作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240408',
                descr: '潜水员',
                level: '7',
                medical_level: '3',
              },
              {
                code: '6240409',
                descr: '视觉航标工(灯塔、航标维护保养)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6240410',
                descr: '港口维护工(码头维修、水面防污、港口除尘)',
                level: '5',
                medical_level: '1',
              },
              {
                code: '6240411',
                descr: '航道航务施工工',
                level: '7',
                medical_level: '2',
              },
              {
                code: '6240412',
                descr: '泵匠',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240413',
                descr: '电机师',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240414',
                descr: '游览船之驾驶及工作人员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240415',
                descr: '小汽艇之驾驶及工作人员',
                level: '6',
                medical_level: '1',
              },
              {
                code: '6240416',
                descr: '拖船驾驶员及工作人员',
                level: '4',
                medical_level: '1',
              },
              {
                code: '6240417',
                descr: '渡船驾驶员及工作人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62405',
            descr: '起重装卸机械操作及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6240501',
                descr: '起重装卸机械操作工',
                level: '4',
                medical_level: '2',
              },
              {
                code: '6240502',
                descr: '起重工',
                level: '6',
                medical_level: '2',
              },
              {
                code: '6240503',
                descr: '输送机操作工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240504',
                descr: '闸门运行工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6240505',
                descr: '索道运输机械操作工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62499',
            descr: '其他运输设备操作人员及有关人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6249901',
                descr: '电梯、升降机操作员(不含矿场使用者)',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '625',
        descr: '环境监测与废物处理人员',
        isopen: false,
        item: [
          {
            code: '62501',
            descr: '环境监测人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6250101',
                descr: '大气环境监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250102',
                descr: '水环境监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250103',
                descr: '土壤环境监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250104',
                descr: '环境生物监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250105',
                descr: '环境噪声及振动监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250106',
                descr: '固体废物监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250107',
                descr: '环境辐射监测工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250108',
                descr: '室内装饰装修质量检验员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250109',
                descr: '室内环境治理员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62502',
            descr: '海洋环境调查与监测人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6250201',
                descr: '海洋调查与监测工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6250202',
                descr: '海洋浮标工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6250203',
                descr: '海洋水文气象观测员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62503',
            descr: '废物处理人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6250301',
                descr: '固体废物处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6250302',
                descr: '废水处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6250303',
                descr: '废气处理工',
                level: '3',
                medical_level: '1',
              },
              {
                code: '6250304',
                descr: '除尘设备运行工',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '626',
        descr: '检验、计量人员',
        isopen: false,
        item: [
          {
            code: '62601',
            descr: '检验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6260101',
                descr: '化学检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260102',
                descr: '材料成分检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260103',
                descr: '材料物理性能检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260104',
                descr: '无损检测员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260105',
                descr: '产品环境适应性能检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260106',
                descr: '产品可靠性能检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260107',
                descr: '产品安全性能检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260108',
                descr: '食品检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260109',
                descr: '饲料检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260110',
                descr: '畜禽产品检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260111',
                descr: '烟草检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260112',
                descr: '纺织纤维检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260113',
                descr: '针纺织品检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260114',
                descr: '印染工艺检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260115',
                descr: '服装鞋帽检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260116',
                descr: '木材及家具检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260117',
                descr: '包装材料检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260118',
                descr: '文体用品及出版物品检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260119',
                descr: '燃料检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260120',
                descr: '感光材料检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260121',
                descr: '药物检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260122',
                descr: '中药检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260123',
                descr: '五金制品检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260124',
                descr: '机械产品检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260125',
                descr: '医疗器械检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260126',
                descr: '机动车检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260127',
                descr: '电器产品检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260128',
                descr: '电工器材检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260129',
                descr: '照明电器检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260130',
                descr: '通信设备检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260131',
                descr: '广播影视设备检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260132',
                descr: '计算机检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260133',
                descr: '电子器件检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260134',
                descr: '仪器仪表检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260135',
                descr: '贵金属首饰、钻石、宝玉石检验员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260136',
                descr: '管道检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260137',
                descr: '合成材料测试员',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62602',
            descr: '航空产品检验人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6260201',
                descr: '飞机检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260202',
                descr: '机载导弹检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260203',
                descr: '航空发动机检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260204',
                descr: '飞机螺旋桨检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260205',
                descr: '飞机、发动机附检验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260206',
                descr: '航空环控救生装备检验工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260207',
                descr: '航空仪表检验工',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62603',
            descr: '航天器检验测试人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6260301',
                descr: '航天器无损检测试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260302',
                descr: '航天器材料性能测试试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260303',
                descr: '试车台测量工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260304',
                descr: '试车台液、气系统操作工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260305',
                descr: '试车台控制工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260306',
                descr: '液体推进剂性能试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260307',
                descr: '固体推进剂性能试验工',
                level: '2',
                medical_level: '1',
              },
              {
                code: '6260308',
                descr: '试车台测力计量检定工',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '62604',
            descr: '计量人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6260401',
                descr: '长度计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260402',
                descr: '热工计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260403',
                descr: '衡器计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260404',
                descr: '硬度测力计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260405',
                descr: '容量计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260406',
                descr: '电器计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260407',
                descr: '化学计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260408',
                descr: '声学计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260409',
                descr: '光学计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260410',
                descr: '电离辐射计量工',
                level: '1',
                medical_level: '1',
              },
              {
                code: '6260411',
                descr: '专用计量器具计量工',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
      {
        code: '699',
        descr: '其他生产运输设备操作人员及有关人员',
        isopen: false,
        item: [
          {
            code: '69901',
            descr: '包装人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6990101',
                descr: '包装人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '69902',
            descr: '机泵操作人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6990201',
                descr: '机泵操作人员',
                level: '3',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '69903',
            descr: '简单体力劳动人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '6990301',
                descr: '简单体力劳动人员',
                level: '4',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '7',
    descr: '军人',
    isopen: false,
    item: [
      {
        code: '700',
        descr: '军人',
        isopen: false,
        item: [
          {
            code: '70000',
            descr: '军人',
            level: '',
            medical_level: '',
            item: [
              {
                code: '7000001',
                descr:
                  '一般地面部队人员(含陆军野战、机械修护、土木工程、飞弹、战车及空军炮、飞机修护等)',
                level: '3',
                medical_level: '1',
              },
              {
                code: '7000002',
                descr:
                  '特种兵(海军陆战队、伞兵、水兵、爆破兵、蛙人、化学兵、负有布雷爆破任务之工兵、情报单位负有特殊任务者)',
                level: '7',
                medical_level: '3',
              },
              {
                code: '7000003',
                descr: '行政及内勤人员(国防部、三军总部、军校教官等)',
                level: '1',
                medical_level: '1',
              },
              {
                code: '7000004',
                descr: '宪兵',
                level: '4',
                medical_level: '1',
              },
              {
                code: '7000005',
                descr: '后勤补给及通讯地勤人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '7000006',
                descr: '军事研究单位纸上设计人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '7000007',
                descr: '军事单位武器、弹药研究及管理人员',
                level: '3',
                medical_level: '1',
              },
              {
                code: '7000008',
                descr: '空军飞行官兵、空军海洋巡弋舰艇及潜艇官兵',
                level: '7',
                medical_level: '3',
              },
              {
                code: '7000009',
                descr: '前线军人',
                level: '7',
                medical_level: '3',
              },
              {
                code: '7000010',
                descr: '军校学生及入伍受训新兵',
                level: '7',
                medical_level: '2',
              },
              {
                code: '7000011',
                descr: '军医院官兵',
                level: '2',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
  {
    code: '8',
    descr: '其他从业人员',
    isopen: false,
    item: [
      {
        code: '800',
        descr: '其他从业人员',
        isopen: false,
        item: [
          {
            code: '80001',
            descr: '特殊运动从业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '8000101',
                descr: '特殊运动班学生(拳击、摔跤、跆拳道等)',
                level: '6',
                medical_level: '2',
              },
              {
                code: '8000102',
                descr: '武术学校学生',
                level: '6',
                medical_level: '2',
              },
            ],
            isopen: false,
          },
          {
            code: '80000',
            descr: '其他从业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '8000001',
                descr: '无业人员',
                level: '2',
                medical_level: '1',
              },
              {
                code: '8000002',
                descr: '退休人员',
                level: '1',
                medical_level: '1',
              },
              {
                code: '8000003',
                descr: '离休人员',
                level: '1',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
          {
            code: '80002',
            descr: '特殊作业人员',
            level: '',
            medical_level: '',
            item: [
              {
                code: '8000201',
                descr: '高处作业人员',
                level: '6',
                medical_level: '1',
              },
            ],
            isopen: false,
          },
        ],
      },
    ],
  },
];

//无层级关系
let occupationJSON = [
  {
    id: '1010001',
    name: '中国共产党中央委员会和地方各级党组织负责人',
    level: '1',
  },
  {
    id: '1020201',
    name: '人民政协及其工作机构负责人',
    level: '1',
  },
  {
    id: '1020301',
    name: '人民法院负责人',
    level: '1',
  },
  {
    id: '1020401',
    name: '人民检察院负责人',
    level: '1',
  },
  {
    id: '1020501',
    name: '国家行政机关及其工作机构负责人',
    level: '1',
  },
  {
    id: '1029901',
    name: '其他国家机关及其工作机构负责人',
    level: '1',
  },
  {
    id: '1020101',
    name: '国家权力机关及其工作机构负责人',
    level: '1',
  },
  {
    id: '1030101',
    name: '民主党派负责人',
    level: '1',
  },
  {
    id: '1030201',
    name: '工会负责人',
    level: '1',
  },
  {
    id: '1030202',
    name: '中国共产主义青年团负责人',
    level: '1',
  },
  {
    id: '1030203',
    name: '妇女联合会负责人',
    level: '1',
  },
  {
    id: '1030299',
    name: '其他人民团体及其工作机构负责人',
    level: '1',
  },
  {
    id: '1030301',
    name: '群众自治组织负责人',
    level: '1',
  },
  {
    id: '1039901',
    name: '其他社会团体及其工作机构负责人',
    level: '1',
  },
  {
    id: '1040101',
    name: '高等学校校长',
    level: '1',
  },
  {
    id: '1040102',
    name: '中等职业教育学校校长',
    level: '1',
  },
  {
    id: '1040103',
    name: '中小学校校长',
    level: '1',
  },
  {
    id: '1040199',
    name: '其他教育教学单位负责人',
    level: '1',
  },
  {
    id: '1040301',
    name: '科研单位负责人',
    level: '1',
  },
  {
    id: '1049901',
    name: '其他事业单位负责人',
    level: '1',
  },
  {
    id: '1040201',
    name: '卫生单位负责人',
    level: '1',
  },
  {
    id: '1050101',
    name: '企业董事',
    level: '1',
  },
  {
    id: '1050102',
    name: '企业经理',
    level: '1',
  },
  {
    id: '1050103',
    name: '企业职能部门经理或主管',
    level: '1',
  },
  {
    id: '1050104',
    name: '工厂、企业负责人',
    level: '1',
  },
  {
    id: '1050105',
    name: '出版商、书店、文具店负责人',
    level: '1',
  },
  {
    id: '2010101',
    name: '哲学研究人员',
    level: '1',
  },
  {
    id: '2010301',
    name: '法学研究人员',
    level: '1',
  },
  {
    id: '2010401',
    name: '社会学研究人员',
    level: '1',
  },
  {
    id: '2010501',
    name: '教育科学研究人员',
    level: '1',
  },
  {
    id: '2010601',
    name: '文学、艺术研究人员',
    level: '1',
  },
  {
    id: '2010801',
    name: '历史学研究人员',
    level: '1',
  },
  {
    id: '2010901',
    name: '管理科学研究人员',
    level: '1',
  },
  {
    id: '2011001',
    name: '数学研究人员',
    level: '1',
  },
  {
    id: '2011101',
    name: '物理学研究人员',
    level: '1',
  },
  {
    id: '2011201',
    name: '化学研究人员',
    level: '1',
  },
  {
    id: '2011301',
    name: '天文学研究人员',
    level: '1',
  },
  {
    id: '2011401',
    name: '地球科学研究人员',
    level: '1',
  },
  {
    id: '2011501',
    name: '生物科学研究人员',
    level: '1',
  },
  {
    id: '2011601',
    name: '农业科学研究人员',
    level: '1',
  },
  {
    id: '2011701',
    name: '医学研究人员',
    level: '1',
  },
  {
    id: '2011801',
    name: '体育研究人员',
    level: '1',
  },
  {
    id: '2010701',
    name: '图书馆学、情报学研究人员',
    level: '1',
  },
  {
    id: '2010201',
    name: '经济学研究人员',
    level: '1',
  },
  {
    id: '2020101',
    name: '地质勘探工程技术人员',
    level: '4',
  },
  {
    id: '2020201',
    name: '大地测量工程技术人员',
    level: '2',
  },
  {
    id: '2020202',
    name: '工程测量工程技术人员',
    level: '2',
  },
  {
    id: '2020203',
    name: '摄影测量与遥感工程技术人员',
    level: '2',
  },
  {
    id: '2020204',
    name: '地图制图与印刷工程技术人员',
    level: '1',
  },
  {
    id: '2020205',
    name: '海洋测绘工程技术人员(海上作业)',
    level: '6',
  },
  {
    id: '2020206',
    name: '海洋测绘工程技术人员(非海上作业)',
    level: '2',
  },
  {
    id: '2020401',
    name: '石油开采工程技术人员',
    level: '3',
  },
  {
    id: '2020402',
    name: '石油储运工程技术人员',
    level: '3',
  },
  {
    id: '2020301',
    name: '采矿工程技术人员',
    level: '3',
  },
  {
    id: '2020302',
    name: '选矿与矿物加工工程技术人员',
    level: '2',
  },
  {
    id: '2020303',
    name: '矿寻工程师、技师、领班',
    level: '4',
  },
  {
    id: '2020501',
    name: '冶炼工程技术人员',
    level: '2',
  },
  {
    id: '2020502',
    name: '轧制工程技术人员',
    level: '2',
  },
  {
    id: '2020503',
    name: '焦化工程技术人员',
    level: '2',
  },
  {
    id: '2020504',
    name: '金属材料工程技术人员',
    level: '2',
  },
  {
    id: '2020505',
    name: '耐火材料工程技术人员',
    level: '2',
  },
  {
    id: '2020506',
    name: '碳素材料工程技术人员',
    level: '2',
  },
  {
    id: '2020507',
    name: '冶金热能工程技术人员',
    level: '2',
  },
  {
    id: '2020601',
    name: '化工实验工程技术人员',
    level: '3',
  },
  {
    id: '2020602',
    name: '化工设计工程技术人员',
    level: '1',
  },
  {
    id: '2020603',
    name: '化工生产工程技术人员',
    level: '3',
  },
  {
    id: '2020701',
    name: '机械设计工程技术人员',
    level: '1',
  },
  {
    id: '2020702',
    name: '机械制造工程技术人员',
    level: '2',
  },
  {
    id: '2020703',
    name: '仪器仪表工程技术人员',
    level: '2',
  },
  {
    id: '2020704',
    name: '设备工程技术人员',
    level: '2',
  },
  {
    id: '2020705',
    name: '数控程控员',
    level: '1',
  },
  {
    id: '2020801',
    name: '装甲车辆工程技术人员',
    level: '2',
  },
  {
    id: '2020802',
    name: '火炮枪械工程技术人员',
    level: '3',
  },
  {
    id: '2020803',
    name: '弹箭工程技术人员',
    level: '3',
  },
  {
    id: '2020804',
    name: '火炸药工程技术人员',
    level: '6',
  },
  {
    id: '2020805',
    name: '光电火控工程技术人员',
    level: '3',
  },
  {
    id: '2020901',
    name: '飞机设计工程技术人员',
    level: '1',
  },
  {
    id: '2020902',
    name: '飞机制造工程技术人员',
    level: '2',
  },
  {
    id: '2020903',
    name: '飞机发动机设计工程技术人员',
    level: '1',
  },
  {
    id: '2020904',
    name: '飞机发动机制造工程技术人员',
    level: '2',
  },
  {
    id: '2021001',
    name: '航天工程技术人员',
    level: '3',
  },
  {
    id: '2021101',
    name: '电子材料工程技术人员',
    level: '2',
  },
  {
    id: '2021102',
    name: '电子元器件工程技术人员',
    level: '2',
  },
  {
    id: '2021103',
    name: '雷达系统工程技术人员',
    level: '2',
  },
  {
    id: '2021104',
    name: '广播视听设备工程技术人员',
    level: '2',
  },
  {
    id: '2021105',
    name: '电子仪器与测量工程技术人员',
    level: '2',
  },
  {
    id: '2021106',
    name: '工程师',
    level: '2',
  },
  {
    id: '2021201',
    name: '通信工程技术人员',
    level: '2',
  },
  {
    id: '2021301',
    name: '计算机硬件技术人员',
    level: '2',
  },
  {
    id: '2021302',
    name: '计算机软件技术人员',
    level: '1',
  },
  {
    id: '2021303',
    name: '计算机网络技术人员',
    level: '1',
  },
  {
    id: '2021304',
    name: '计算机系统分析技术人员',
    level: '1',
  },
  {
    id: '2021305',
    name: '维护工程师',
    level: '2',
  },
  {
    id: '2021306',
    name: '销售工程师',
    level: '1',
  },
  {
    id: '2021307',
    name: '计算机乐谱制作师',
    level: '1',
  },
  {
    id: '2021308',
    name: '数字视频合成师',
    level: '1',
  },
  {
    id: '2021309',
    name: '计算机软件产品检验员',
    level: '1',
  },
  {
    id: '2021401',
    name: '电机与电器工程技术人员',
    level: '3',
  },
  {
    id: '2021402',
    name: '电力拖动与自动控制工程技术人员',
    level: '2',
  },
  {
    id: '2021403',
    name: '电线电缆与电工材料工程技术人员',
    level: '2',
  },
  {
    id: '2021404',
    name: '可编程序控制系统设计师',
    level: '2',
  },
  {
    id: '2021501',
    name: '发电工程技术人员',
    level: '3',
  },
  {
    id: '2021502',
    name: '输变电工程技术人员',
    level: '3',
  },
  {
    id: '2021503',
    name: '供用电工程技术人员',
    level: '3',
  },
  {
    id: '2021601',
    name: '邮政工程技术人员',
    level: '2',
  },
  {
    id: '2021801',
    name: '汽车运用工程技术人员',
    level: '2',
  },
  {
    id: '2021802',
    name: '船舶运用工程技术人员',
    level: '2',
  },
  {
    id: '2021803',
    name: '水上交通工程技术人员(进行水上安全监督管理、搜寻救助等行动)',
    level: '4',
  },
  {
    id: '2021804',
    name: '水上交通工程技术人员(不参与水上行动)',
    level: '2',
  },
  {
    id: '2021805',
    name: '海上救助打捞工程技术人员(参与海上艘救、打捞行动)',
    level: '7',
  },
  {
    id: '2021806',
    name: '海上救助打捞工程技术人员(救助打捞技术、装备研究、设计)',
    level: '2',
  },
  {
    id: '2021807',
    name: '船舶检验工程技术人员',
    level: '2',
  },
  {
    id: '2021701',
    name: '广播电视编播工程技术人员',
    level: '2',
  },
  {
    id: '2021702',
    name: '广播电视传输覆盖工程技术人员',
    level: '2',
  },
  {
    id: '2021703',
    name: '电影工程技术人员',
    level: '2',
  },
  {
    id: '2021901',
    name: '民用航空器维修与适航审定专业技术人员',
    level: '3',
  },
  {
    id: '2021902',
    name: '航行航空管理及飞行程序设计工程技术人员',
    level: '1',
  },
  {
    id: '2021903',
    name: '通用航空技术人员(从事航空摄影、航空物探、航空吊挂吊装、石油航空作业、航空环境污染监测、航空护林、播种、喷施、研究航空体育运动等)',
    level: '6',
  },
  {
    id: '2021904',
    name: '航空运输研究人员',
    level: '1',
  },
  {
    id: '2022001',
    name: '铁道运输工程研究人员',
    level: '1',
  },
  {
    id: '2022002',
    name: '铁路机务工程技术人员',
    level: '2',
  },
  {
    id: '2022003',
    name: '铁路车辆工程技术人员',
    level: '2',
  },
  {
    id: '2022004',
    name: '铁路电务工程技术人员',
    level: '3',
  },
  {
    id: '2022101',
    name: '城镇规划设计工程技术人员',
    level: '1',
  },
  {
    id: '2022102',
    name: '建筑设计工程技术人员',
    level: '1',
  },
  {
    id: '2022103',
    name: '土木建筑工程技术人员(到工地监督检查指导)',
    level: '3',
  },
  {
    id: '2022104',
    name: '土木建筑工程技术人员(不到工地)',
    level: '1',
  },
  {
    id: '2022105',
    name: '风景园林工程技术人员',
    level: '2',
  },
  {
    id: '2022106',
    name: '道路与桥梁工程技术人员',
    level: '3',
  },
  {
    id: '2022107',
    name: '港口与航道工程技术人员',
    level: '3',
  },
  {
    id: '2022108',
    name: '机场工程技术人员',
    level: '3',
  },
  {
    id: '2022109',
    name: '铁路建筑工程技术人员',
    level: '2',
  },
  {
    id: '2022110',
    name: '水利水电建筑工程技术人员',
    level: '3',
  },
  {
    id: '2022111',
    name: '内勤工作人员',
    level: '1',
  },
  {
    id: '2022112',
    name: '承包商(土木建筑)',
    level: '3',
  },
  {
    id: '2022113',
    name: '建筑公司负责人、业务员',
    level: '2',
  },
  {
    id: '2022114',
    name: '引导参观工地服务人员',
    level: '2',
  },
  {
    id: '2022201',
    name: '硅酸盐工程技术人员',
    level: '2',
  },
  {
    id: '2022202',
    name: '石棉、石膏、云母等非金属矿及制品工程技术人员',
    level: '2',
  },
  {
    id: '2022203',
    name: '玻璃钢、复合材料等无机非金属新材料工程技术人员',
    level: '2',
  },
  {
    id: '2022301',
    name: '林业生态环境工程技术人员',
    level: '3',
  },
  {
    id: '2022302',
    name: '森林培育工程技术人员',
    level: '2',
  },
  {
    id: '2022303',
    name: '园林绿化工程技术人员',
    level: '2',
  },
  {
    id: '2022304',
    name: '野生动物保护与繁殖利用工程技术人员(研究调查野生动物资源及野生动物驯化、繁殖技术人员)',
    level: '4',
  },
  {
    id: '2022305',
    name: '野生动物保护与繁殖利用工程技术人员(设计、研制猎枪、猎具，改进狩猎技、术，研究野生动物肉、毛皮产品测定、分析、储藏技术人员)',
    level: '2',
  },
  {
    id: '2022306',
    name: '自然保护区工程技术人员',
    level: '3',
  },
  {
    id: '2022307',
    name: '森林保护工程技术人员',
    level: '3',
  },
  {
    id: '2022308',
    name: '木、竹材加工工程技术人员',
    level: '3',
  },
  {
    id: '2022309',
    name: '森林采伐和运输工程技术人员',
    level: '4',
  },
  {
    id: '2022310',
    name: '经济林和林特产品加工工程技术人员',
    level: '2',
  },
  {
    id: '2022311',
    name: '森林资源管理与监测工程技术人员',
    level: '2',
  },
  {
    id: '2022401',
    name: '水资源勘测工程技术人员',
    level: '3',
  },
  {
    id: '2022402',
    name: '治河及泥沙治理工程技术人员',
    level: '3',
  },
  {
    id: '2022403',
    name: '水利工程师',
    level: '2',
  },
  {
    id: '2022501',
    name: '海洋调查与监测工程技术人员',
    level: '3',
  },
  {
    id: '2022502',
    name: '海洋环境预报工程技术人员',
    level: '2',
  },
  {
    id: '2022503',
    name: '海洋资源开发利用和保护工程技术人员(海洋矿产、油气资源开发利用)',
    level: '6',
  },
  {
    id: '2022504',
    name: '海洋资源开发利用和保护工程技术人员(海水淡化、潮汐能、波浪能等能源开发、海洋生态系统保护等)',
    level: '3',
  },
  {
    id: '2022505',
    name: '海洋工程勘察设计工程技术人员(进行海洋、海底勘察人员)',
    level: '6',
  },
  {
    id: '2022506',
    name: '海洋工程勘察设计工程技术人员(工程设计规划人员 )',
    level: '2',
  },
  {
    id: '2022601',
    name: '水产养殖工程技术人员',
    level: '2',
  },
  {
    id: '2022602',
    name: '渔业资源开发利用工程技术人员',
    level: '2',
  },
  {
    id: '2022801',
    name: '食品营养卫生研究及食品加工、储运工艺技术开发应用人员',
    level: '1',
  },
  {
    id: '2022701',
    name: '纺纱工程技术人员',
    level: '2',
  },
  {
    id: '2022702',
    name: '织造工程技术人员',
    level: '2',
  },
  {
    id: '2022703',
    name: '染整工程技术人员',
    level: '2',
  },
  {
    id: '2022704',
    name: '工程师',
    level: '2',
  },
  {
    id: '2022901',
    name: '气象观测人员',
    level: '2',
  },
  {
    id: '2022902',
    name: '天气预报人员',
    level: '1',
  },
  {
    id: '2022903',
    name: '气候监测预测人员',
    level: '1',
  },
  {
    id: '2022904',
    name: '应用气象人员',
    level: '1',
  },
  {
    id: '2022905',
    name: '气象服务人员',
    level: '1',
  },
  {
    id: '2023001',
    name: '地震工程技术人员',
    level: '1',
  },
  {
    id: '2023101',
    name: '环境损害控制工程技术人员',
    level: '1',
  },
  {
    id: '2023102',
    name: '环境监测工程技术人员',
    level: '1',
  },
  {
    id: '2023103',
    name: '环境污染治理工程技术人员',
    level: '2',
  },
  {
    id: '2023104',
    name: '灾害信息员',
    level: '1',
  },
  {
    id: '2023105',
    name: '紧急救助员',
    level: '7',
  },
  {
    id: '2023301',
    name: '标准化工程技术人员(从事技术、服务、管理标准化的研究和标准制定、实施、监督、管理的工程技术人员)',
    level: '1',
  },
  {
    id: '2023302',
    name: '计量工程技术人员',
    level: '1',
  },
  {
    id: '2023303',
    name: '质量工程技术人员',
    level: '1',
  },
  {
    id: '2023201',
    name: '安全工程技术人员(安全科学技术研究、开发与推广，安全工程设计施工、安全生产运行控制，安全检测、监督、评估，事故调查分析与预测预防)',
    level: '2',
  },
  {
    id: '2023202',
    name: '安全防范系统安装维护员',
    level: '2',
  },
  {
    id: '2023203',
    name: '安全防范设计评估师',
    level: '2',
  },
  {
    id: '2023401',
    name: '普通工业管理工程技术人员',
    level: '1',
  },
  {
    id: '2023402',
    name: '系统规划与管理工程技术人员',
    level: '1',
  },
  {
    id: '2023403',
    name: '设施规划与设计工程技术人员',
    level: '1',
  },
  {
    id: '2023404',
    name: '生产组织与管理工程技术人员',
    level: '1',
  },
  {
    id: '2023405',
    name: '质量管理与可靠性控制工程技术人员',
    level: '1',
  },
  {
    id: '2023406',
    name: '营销工程技术人员',
    level: '1',
  },
  {
    id: '2023407',
    name: '人力资源开发与管理工程技术人员',
    level: '1',
  },
  {
    id: '2030101',
    name: '土壤肥料技术人员',
    level: '2',
  },
  {
    id: '2030201',
    name: '植物保护技术人员',
    level: '2',
  },
  {
    id: '2030301',
    name: '园艺技术人员',
    level: '2',
  },
  {
    id: '2030401',
    name: '作物遗传育种栽培技术人员',
    level: '2',
  },
  {
    id: '2030601',
    name: '畜牧技术人员',
    level: '2',
  },
  {
    id: '2030602',
    name: '草业技术人员',
    level: '2',
  },
  {
    id: '2039901',
    name: '农业技师、指导员',
    level: '2',
  },
  {
    id: '2030501',
    name: '兽医',
    level: '2',
  },
  {
    id: '2030502',
    name: '兽药技术人员',
    level: '2',
  },
  {
    id: '2040101',
    name: '飞行驾驶员',
    level: '6',
  },
  {
    id: '2040102',
    name: '飞机机械员',
    level: '6',
  },
  {
    id: '2040103',
    name: '飞行领航员',
    level: '6',
  },
  {
    id: '2040104',
    name: '飞行通信员',
    level: '6',
  },
  {
    id: '2040201',
    name: '甲板部技术人员',
    level: '4',
  },
  {
    id: '2040202',
    name: '轮机部技术人员',
    level: '4',
  },
  {
    id: '2040203',
    name: '船舶引航员',
    level: '4',
  },
  {
    id: '2040204',
    name: '船长',
    level: '6',
  },
  {
    id: '2040205',
    name: '轮机长',
    level: '4',
  },
  {
    id: '2040206',
    name: '大副',
    level: '4',
  },
  {
    id: '2040207',
    name: '二副',
    level: '4',
  },
  {
    id: '2040208',
    name: '三副',
    level: '4',
  },
  {
    id: '2040209',
    name: '大管轮',
    level: '4',
  },
  {
    id: '2040210',
    name: '二管轮',
    level: '4',
  },
  {
    id: '2040211',
    name: '三管轮',
    level: '4',
  },
  {
    id: '2040212',
    name: '报务员',
    level: '4',
  },
  {
    id: '2040213',
    name: '事务长',
    level: '4',
  },
  {
    id: '2040214',
    name: '引水员',
    level: '4',
  },
  {
    id: '2040301',
    name: '民航飞机驾驶员',
    level: '4',
  },
  {
    id: '2040302',
    name: '民航飞机机械员',
    level: '4',
  },
  {
    id: '2040303',
    name: '民航飞行领航员',
    level: '4',
  },
  {
    id: '2040304',
    name: '民航飞行通信员',
    level: '4',
  },
  {
    id: '2050101',
    name: '内科医师',
    level: '1',
  },
  {
    id: '2050102',
    name: '外科医师',
    level: '2',
  },
  {
    id: '2050103',
    name: '儿科医师',
    level: '1',
  },
  {
    id: '2050104',
    name: '妇产科医师',
    level: '1',
  },
  {
    id: '2050105',
    name: '眼科医师',
    level: '1',
  },
  {
    id: '2050106',
    name: '耳鼻喉科医师',
    level: '1',
  },
  {
    id: '2050107',
    name: '口腔科医师',
    level: '1',
  },
  {
    id: '2050108',
    name: '皮肤科医师',
    level: '1',
  },
  {
    id: '2050109',
    name: '精神科医师',
    level: '3',
  },
  {
    id: '2050110',
    name: '心理医师',
    level: '1',
  },
  {
    id: '2050111',
    name: '传染病科医师',
    level: '3',
  },
  {
    id: '2050112',
    name: '急诊科医师',
    level: '2',
  },
  {
    id: '2050113',
    name: '康复科医师',
    level: '1',
  },
  {
    id: '2050114',
    name: '麻醉科医师',
    level: '1',
  },
  {
    id: '2050115',
    name: '病理科医师',
    level: '1',
  },
  {
    id: '2050116',
    name: '放射科医师',
    level: '2',
  },
  {
    id: '2050117',
    name: '核医学医师',
    level: '2',
  },
  {
    id: '2050118',
    name: '超声诊断科医师',
    level: '1',
  },
  {
    id: '2050119',
    name: '放射肿瘤科医师',
    level: '2',
  },
  {
    id: '2050120',
    name: '全科医师',
    level: '1',
  },
  {
    id: '2050121',
    name: '乡村医师',
    level: '2',
  },
  {
    id: '2050122',
    name: '妇幼保健医师',
    level: '1',
  },
  {
    id: '2050123',
    name: '输(采供)血医师',
    level: '2',
  },
  {
    id: '2050301',
    name: '中西医结合医师',
    level: '1',
  },
  {
    id: '2050201',
    name: '中医内科医师',
    level: '1',
  },
  {
    id: '2050202',
    name: '中医外科医师',
    level: '1',
  },
  {
    id: '2050203',
    name: '中医妇科医师',
    level: '1',
  },
  {
    id: '2050204',
    name: '中医儿科医师',
    level: '1',
  },
  {
    id: '2050205',
    name: '中医眼科医师',
    level: '1',
  },
  {
    id: '2050206',
    name: '中医皮肤科医师',
    level: '1',
  },
  {
    id: '2050207',
    name: '中医骨伤科医师',
    level: '2',
  },
  {
    id: '2050208',
    name: '中医肛肠科医师',
    level: '1',
  },
  {
    id: '2050209',
    name: '中医耳鼻喉科医师',
    level: '1',
  },
  {
    id: '2050210',
    name: '针炙科医师',
    level: '1',
  },
  {
    id: '2050211',
    name: '推拿按摩科医师',
    level: '2',
  },
  {
    id: '2050401',
    name: '民族医师',
    level: '1',
  },
  {
    id: '2050501',
    name: '流行病学医师',
    level: '1',
  },
  {
    id: '2050502',
    name: '营养与食品卫生医师',
    level: '1',
  },
  {
    id: '2050503',
    name: '环境卫生医师',
    level: '1',
  },
  {
    id: '2050504',
    name: '职业病医师',
    level: '1',
  },
  {
    id: '2050505',
    name: '劳动(职业)卫生医师',
    level: '1',
  },
  {
    id: '2050506',
    name: '放射卫生医师',
    level: '2',
  },
  {
    id: '2050507',
    name: '少儿和学校卫生医师',
    level: '1',
  },
  {
    id: '2050508',
    name: '健康管理师',
    level: '1',
  },
  {
    id: '2050509',
    name: '公共营养师',
    level: '1',
  },
  {
    id: '2050601',
    name: '西药剂师',
    level: '1',
  },
  {
    id: '2050602',
    name: '中药药师',
    level: '1',
  },
  {
    id: '2050701',
    name: '影像技师',
    level: '2',
  },
  {
    id: '2050702',
    name: '麻醉技师',
    level: '1',
  },
  {
    id: '2050703',
    name: '病理技师',
    level: '1',
  },
  {
    id: '2050704',
    name: '临床检验技师',
    level: '1',
  },
  {
    id: '2050705',
    name: '公卫检验技师',
    level: '1',
  },
  {
    id: '2050706',
    name: '卫生工程技师',
    level: '1',
  },
  {
    id: '2050707',
    name: '输(采供)血技师',
    level: '2',
  },
  {
    id: '2050708',
    name: '放射线之技术人员',
    level: '2',
  },
  {
    id: '2050709',
    name: '放射线之修理人员',
    level: '4',
  },
  {
    id: '2050710',
    name: '医学设备管理师',
    level: '2',
  },
  {
    id: '2050801',
    name: '病房护士',
    level: '1',
  },
  {
    id: '2050802',
    name: '门诊护士',
    level: '1',
  },
  {
    id: '2050803',
    name: '急诊护士',
    level: '2',
  },
  {
    id: '2050804',
    name: '手术室护士',
    level: '2',
  },
  {
    id: '2050805',
    name: '供应室护士',
    level: '1',
  },
  {
    id: '2050806',
    name: '社区护士',
    level: '1',
  },
  {
    id: '2050807',
    name: '助产士',
    level: '2',
  },
  {
    id: '2050808',
    name: '医疗救护员',
    level: '1',
  },
  {
    id: '2059901',
    name: '一般医务行政人员',
    level: '1',
  },
  {
    id: '2059902',
    name: '监狱、看守所医生护理人员',
    level: '4',
  },
  {
    id: '2060101',
    name: '经济计划人员',
    level: '1',
  },
  {
    id: '2060201',
    name: '统计人员',
    level: '1',
  },
  {
    id: '2060401',
    name: '审计人员',
    level: '1',
  },
  {
    id: '2060301',
    name: '会计人员',
    level: '1',
  },
  {
    id: '2060601',
    name: '品牌管理师',
    level: '1',
  },
  {
    id: '2060701',
    name: '职业信息分析师',
    level: '1',
  },
  {
    id: '2060702',
    name: '调查分析师',
    level: '1',
  },
  {
    id: '2060703',
    name: '黄金投资分析师',
    level: '1',
  },
  {
    id: '2060501',
    name: '国际商务人员',
    level: '1',
  },
  {
    id: '2070101',
    name: '银行货币发行员',
    level: '1',
  },
  {
    id: '2070102',
    name: '银行国库业务员',
    level: '1',
  },
  {
    id: '2070103',
    name: '银行外汇管理员',
    level: '1',
  },
  {
    id: '2070104',
    name: '银行清算员',
    level: '1',
  },
  {
    id: '2070105',
    name: '银行信贷员',
    level: '2',
  },
  {
    id: '2070106',
    name: '银行国际业务处理人员',
    level: '1',
  },
  {
    id: '2070107',
    name: '银行信托业务处理人员',
    level: '1',
  },
  {
    id: '2070108',
    name: '银行信用卡业务处理人员',
    level: '1',
  },
  {
    id: '2070109',
    name: '银行储蓄员',
    level: '1',
  },
  {
    id: '2070110',
    name: '现金运送车司机、点钞员、押送员',
    level: '3',
  },
  {
    id: '2070201',
    name: '精算师',
    level: '1',
  },
  {
    id: '2070202',
    name: '保险推销员',
    level: '2',
  },
  {
    id: '2070203',
    name: '保险理赔员',
    level: '1',
  },
  {
    id: '2070204',
    name: '保险收费员',
    level: '2',
  },
  {
    id: '2070205',
    name: '保险调查员',
    level: '2',
  },
  {
    id: '2070206',
    name: '征信人员',
    level: '2',
  },
  {
    id: '2070301',
    name: '证券发行员',
    level: '1',
  },
  {
    id: '2070302',
    name: '证券交易员',
    level: '1',
  },
  {
    id: '2070303',
    name: '证券投资顾问',
    level: '1',
  },
  {
    id: '2070401',
    name: '信用管理师',
    level: '1',
  },
  {
    id: '2079901',
    name: '金融一般内勤人员',
    level: '1',
  },
  {
    id: '2079902',
    name: '金融外务员',
    level: '2',
  },
  {
    id: '2080101',
    name: '法官',
    level: '1',
  },
  {
    id: '2080201',
    name: '检察官',
    level: '1',
  },
  {
    id: '2080401',
    name: '公证员',
    level: '1',
  },
  {
    id: '2080501',
    name: '法医',
    level: '2',
  },
  {
    id: '2080601',
    name: '书记员',
    level: '1',
  },
  {
    id: '2080701',
    name: '商业犯罪调查处理人员',
    level: '3',
  },
  {
    id: '2080301',
    name: '律师',
    level: '1',
  },
  {
    id: '2090101',
    name: '高等教育教师',
    level: '1',
  },
  {
    id: '2090201',
    name: '中等职业教育理论',
    level: '1',
  },
  {
    id: '2090202',
    name: '实习指导教师',
    level: '1',
  },
  {
    id: '2090301',
    name: '中学教师',
    level: '1',
  },
  {
    id: '2090401',
    name: '小学教师',
    level: '1',
  },
  {
    id: '2090501',
    name: '幼儿教师',
    level: '1',
  },
  {
    id: '2090601',
    name: '特殊教育教师',
    level: '1',
  },
  {
    id: '2090701',
    name: '家庭教师',
    level: '1',
  },
  {
    id: '2099901',
    name: '校工',
    level: '2',
  },
  {
    id: '2099902',
    name: '军训教官、体育教师',
    level: '2',
  },
  {
    id: '2099903',
    name: '汽车驾驶训练班教练',
    level: '3',
  },
  {
    id: '2099904',
    name: '各项运动教练',
    level: '2',
  },
  {
    id: '2099905',
    name: '飞行教官',
    level: '6',
  },
  {
    id: '2099906',
    name: '飞行训练学员',
    level: '6',
  },
  {
    id: '2099907',
    name: '一般学生',
    level: '1',
  },
  {
    id: '2099908',
    name: '学龄前儿童',
    level: '1',
  },
  {
    id: '2100101',
    name: '文学作家',
    level: '1',
  },
  {
    id: '2100102',
    name: '曲艺作家',
    level: '1',
  },
  {
    id: '2100103',
    name: '剧作家',
    level: '1',
  },
  {
    id: '2100104',
    name: '作曲家',
    level: '1',
  },
  {
    id: '2100105',
    name: '词作家',
    level: '1',
  },
  {
    id: '2100106',
    name: '文艺评论员',
    level: '1',
  },
  {
    id: '2100107',
    name: '皮影戏木偶戏作家',
    level: '1',
  },
  {
    id: '2100201',
    name: '电影电视导演',
    level: '2',
  },
  {
    id: '2100202',
    name: '戏剧导演',
    level: '1',
  },
  {
    id: '2100203',
    name: '舞蹈编导',
    level: '1',
  },
  {
    id: '2100204',
    name: '音乐指挥',
    level: '1',
  },
  {
    id: '2100205',
    name: '武术指导',
    level: '3',
  },
  {
    id: '2100301',
    name: '电影电视演员',
    level: '2',
  },
  {
    id: '2100302',
    name: '戏剧演员',
    level: '2',
  },
  {
    id: '2100303',
    name: '舞蹈演员',
    level: '2',
  },
  {
    id: '2100304',
    name: '曲艺演员',
    level: '1',
  },
  {
    id: '2100305',
    name: '杂技魔术演员',
    level: '3',
  },
  {
    id: '2100306',
    name: '歌唱演员',
    level: '2',
  },
  {
    id: '2100307',
    name: '皮影戏演员',
    level: '1',
  },
  {
    id: '2100308',
    name: '木偶戏演员',
    level: '1',
  },
  {
    id: '2100309',
    name: '武打演员',
    level: '5',
  },
  {
    id: '2100310',
    name: '特技演员',
    level: '7',
  },
  {
    id: '2100311',
    name: '高空杂技飞车飞人演员',
    level: '7',
  },
  {
    id: '2100312',
    name: '配音演员',
    level: '1',
  },
  {
    id: '2100401',
    name: '民族乐器演奏员',
    level: '1',
  },
  {
    id: '2100402',
    name: '外国乐器演奏员',
    level: '1',
  },
  {
    id: '2100501',
    name: '电影电视制片',
    level: '1',
  },
  {
    id: '2100502',
    name: '电影电视场记',
    level: '2',
  },
  {
    id: '2100503',
    name: '电影电视摄影师',
    level: '2',
  },
  {
    id: '2100504',
    name: '照明师',
    level: '2',
  },
  {
    id: '2100505',
    name: '录音师',
    level: '1',
  },
  {
    id: '2100506',
    name: '剪辑师',
    level: '1',
  },
  {
    id: '2100507',
    name: '美工师',
    level: '1',
  },
  {
    id: '2100508',
    name: '化妆师',
    level: '1',
  },
  {
    id: '2100509',
    name: '置景师',
    level: '4',
  },
  {
    id: '2100510',
    name: '道具师',
    level: '2',
  },
  {
    id: '2100511',
    name: '电影电视片发行人',
    level: '1',
  },
  {
    id: '2100512',
    name: '舞台监督',
    level: '1',
  },
  {
    id: '2100513',
    name: '戏剧制作人',
    level: '1',
  },
  {
    id: '2100514',
    name: '影片商',
    level: '1',
  },
  {
    id: '2100515',
    name: '灯光及音响工作人员',
    level: '2',
  },
  {
    id: '2100516',
    name: '冲片人员',
    level: '2',
  },
  {
    id: '2100517',
    name: '洗片人员',
    level: '2',
  },
  {
    id: '2100518',
    name: '机械工电工',
    level: '4',
  },
  {
    id: '2100601',
    name: '画家',
    level: '1',
  },
  {
    id: '2100602',
    name: '篆刻家',
    level: '2',
  },
  {
    id: '2100603',
    name: '雕塑家',
    level: '2',
  },
  {
    id: '2100604',
    name: '书法家',
    level: '1',
  },
  {
    id: '2100605',
    name: '陶艺家',
    level: '2',
  },
  {
    id: '2100701',
    name: '特种工艺设计人员',
    level: '1',
  },
  {
    id: '2100702',
    name: '实用工艺设计人员',
    level: '1',
  },
  {
    id: '2100703',
    name: '现代工艺设计人员',
    level: '1',
  },
  {
    id: '2100704',
    name: '装磺美术设计人员',
    level: '1',
  },
  {
    id: '2100705',
    name: '服装设计人员',
    level: '1',
  },
  {
    id: '2100706',
    name: '室内装饰设计人员',
    level: '1',
  },
  {
    id: '2100707',
    name: '陈列展览设计人员',
    level: '1',
  },
  {
    id: '2100708',
    name: '广告设计人员',
    level: '1',
  },
  {
    id: '2110101',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110102',
    name: '运动员',
    level: '2',
  },
  {
    id: '2110103',
    name: '球童',
    level: '2',
  },
  {
    id: '2110201',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110202',
    name: '保龄球球员',
    level: '2',
  },
  {
    id: '2110301',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110302',
    name: '桌球球员',
    level: '2',
  },
  {
    id: '2110401',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110402',
    name: '羽毛球球员',
    level: '2',
  },
  {
    id: '2110501',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110502',
    name: '游泳队员',
    level: '2',
  },
  {
    id: '2110601',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110602',
    name: '射箭运动员',
    level: '2',
  },
  {
    id: '2110701',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110702',
    name: '网球球员',
    level: '2',
  },
  {
    id: '2110801',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110802',
    name: '垒球球员',
    level: '2',
  },
  {
    id: '2111001',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111002',
    name: '射击人员',
    level: '2',
  },
  {
    id: '2110901',
    name: '教练员',
    level: '2',
  },
  {
    id: '2110902',
    name: '滑冰运动员',
    level: '2',
  },
  {
    id: '2111101',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111102',
    name: '民族体育活动人员',
    level: '2',
  },
  {
    id: '2111201',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111202',
    name: '举重运动员',
    level: '3',
  },
  {
    id: '2111301',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111302',
    name: '篮球运动员',
    level: '3',
  },
  {
    id: '2111401',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111402',
    name: '排球运动员',
    level: '3',
  },
  {
    id: '2111501',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111502',
    name: '棒球运动员',
    level: '3',
  },
  {
    id: '2111601',
    name: '教练员',
    level: '2',
  },
  {
    id: '2111602',
    name: '田径运动员',
    level: '3',
  },
  {
    id: '2111701',
    name: '教练员',
    level: '3',
  },
  {
    id: '2111702',
    name: '体操运动员',
    level: '3',
  },
  {
    id: '2111801',
    name: '教练员',
    level: '3',
  },
  {
    id: '2111802',
    name: '滑雪运动员',
    level: '6',
  },
  {
    id: '2111901',
    name: '教练员',
    level: '3',
  },
  {
    id: '2111902',
    name: '驾乘人员',
    level: '3',
  },
  {
    id: '2112001',
    name: '教练员',
    level: '3',
  },
  {
    id: '2112002',
    name: '驾乘人员',
    level: '3',
  },
  {
    id: '2112101',
    name: '教练员',
    level: '3',
  },
  {
    id: '2112102',
    name: '驾乘人员',
    level: '3',
  },
  {
    id: '2112201',
    name: '教练员',
    level: '2',
  },
  {
    id: '2112202',
    name: '巧固球球员',
    level: '3',
  },
  {
    id: '2112301',
    name: '教练员',
    level: '2',
  },
  {
    id: '2112302',
    name: '手球运动员',
    level: '3',
  },
  {
    id: '2112401',
    name: '教练员',
    level: '4',
  },
  {
    id: '2112402',
    name: '驾乘人员',
    level: '4',
  },
  {
    id: '2112601',
    name: '教练员',
    level: '2',
  },
  {
    id: '2112602',
    name: '足球运动员',
    level: '4',
  },
  {
    id: '2112501',
    name: '教练员',
    level: '4',
  },
  {
    id: '2112502',
    name: '驾乘人员',
    level: '4',
  },
  {
    id: '2112701',
    name: '教练员',
    level: '2',
  },
  {
    id: '2112702',
    name: '曲棍球球员',
    level: '5',
  },
  {
    id: '2112801',
    name: '教练员',
    level: '3',
  },
  {
    id: '2112802',
    name: '冰上曲棍球球员',
    level: '6',
  },
  {
    id: '2113001',
    name: '教练员',
    level: '2',
  },
  {
    id: '2113002',
    name: '乒乓球球员',
    level: '2',
  },
  {
    id: '2112901',
    name: '教练员',
    level: '2',
  },
  {
    id: '2112902',
    name: '橄榄球球员',
    level: '5',
  },
  {
    id: '2113101',
    name: '教练员',
    level: '3',
  },
  {
    id: '2113102',
    name: '击剑运动员',
    level: '4',
  },
  {
    id: '2113201',
    name: '教练员',
    level: '2',
  },
  {
    id: '2113202',
    name: '水球球员',
    level: '2',
  },
  {
    id: '2113301',
    name: '教练员',
    level: '4',
  },
  {
    id: '2113302',
    name: '运动员',
    level: '6',
  },
  {
    id: '2113401',
    name: '教练员',
    level: '3',
  },
  {
    id: '2113402',
    name: '摔跤运动员',
    level: '5',
  },
  {
    id: '2113501',
    name: '教练员',
    level: '4',
  },
  {
    id: '2113502',
    name: '职业拳击运动员',
    level: '7',
  },
  {
    id: '2113503',
    name: '业余拳击运动员',
    level: '6',
  },
  {
    id: '2113601',
    name: '裁判人员',
    level: '2',
  },
  {
    id: '2120101',
    name: '文字记者',
    level: '1',
  },
  {
    id: '2120102',
    name: '摄影记者',
    level: '4',
  },
  {
    id: '2120103',
    name: '外勤记者',
    level: '2',
  },
  {
    id: '2120104',
    name: '战地记者',
    level: '7',
  },
  {
    id: '2120105',
    name: '电视记者',
    level: '2',
  },
  {
    id: '2120201',
    name: '文字编辑',
    level: '1',
  },
  {
    id: '2120202',
    name: '美术编辑',
    level: '1',
  },
  {
    id: '2120203',
    name: '技术编辑',
    level: '1',
  },
  {
    id: '2120204',
    name: '电子出版物编辑',
    level: '1',
  },
  {
    id: '2120401',
    name: '播音员',
    level: '1',
  },
  {
    id: '2120402',
    name: '节目主持人',
    level: '1',
  },
  {
    id: '2120301',
    name: '校对员',
    level: '1',
  },
  {
    id: '2120501',
    name: '翻译',
    level: '1',
  },
  {
    id: '2120502',
    name: '手语翻译员',
    level: '1',
  },
  {
    id: '2120601',
    name: '图书资料业务人员',
    level: '1',
  },
  {
    id: '2120602',
    name: '档案业务人员',
    level: '1',
  },
  {
    id: '2120603',
    name: '缩微摄影人员',
    level: '1',
  },
  {
    id: '2120604',
    name: '图书馆工作人员',
    level: '1',
  },
  {
    id: '2120701',
    name: '考古工作者',
    level: '2',
  },
  {
    id: '2120702',
    name: '文物鉴定和保管人员',
    level: '1',
  },
  {
    id: '2120703',
    name: '文物保护专业人员',
    level: '2',
  },
  {
    id: '2120704',
    name: '博物馆工作人员',
    level: '1',
  },
  {
    id: '2129901',
    name: '内勤人员',
    level: '1',
  },
  {
    id: '2130001',
    name: '宗教职业者',
    level: '1',
  },
  {
    id: '2130002',
    name: '寺庙及教堂管理人员',
    level: '1',
  },
  {
    id: '2130003',
    name: '宗教团体工作人员',
    level: '1',
  },
  {
    id: '2130004',
    name: '僧尼道士传教人员',
    level: '1',
  },
  {
    id: '3010101',
    name: '行政业务办公人员(内勤)',
    level: '1',
  },
  {
    id: '3010102',
    name: '行政业务办公人员(外勤)',
    level: '2',
  },
  {
    id: '3010103',
    name: '工商、税务、海关、城管等特定国家机关行政执法人员',
    level: '3',
  },
  {
    id: '3010104',
    name: '缉私人员',
    level: '4',
  },
  {
    id: '3010105',
    name: '客户服务管理师',
    level: '2',
  },
  {
    id: '3010201',
    name: '秘书',
    level: '1',
  },
  {
    id: '3010202',
    name: '公务(关)员',
    level: '1',
  },
  {
    id: '3010203',
    name: '收发员',
    level: '1',
  },
  {
    id: '3010204',
    name: '打字员',
    level: '1',
  },
  {
    id: '3010205',
    name: '计算机操作员',
    level: '1',
  },
  {
    id: '3010206',
    name: '制图员',
    level: '1',
  },
  {
    id: '3020101',
    name: '警务行政及内勤人员',
    level: '1',
  },
  {
    id: '3020102',
    name: '警察(负有巡逻任务者)',
    level: '3',
  },
  {
    id: '3020103',
    name: '监狱看守所管理人员',
    level: '3',
  },
  {
    id: '3020104',
    name: '交通警察',
    level: '4',
  },
  {
    id: '3020105',
    name: '刑警',
    level: '5',
  },
  {
    id: '3020106',
    name: '警务特勤',
    level: '6',
  },
  {
    id: '3020107',
    name: '防暴警察',
    level: '7',
  },
  {
    id: '3020108',
    name: '港口机场警卫及安全人员',
    level: '4',
  },
  {
    id: '3020109',
    name: '警校学生',
    level: '4',
  },
  {
    id: '3020201',
    name: '保安员',
    level: '4',
  },
  {
    id: '3020202',
    name: '违禁品检查员',
    level: '3',
  },
  {
    id: '3020203',
    name: '金融守押员',
    level: '3',
  },
  {
    id: '3020204',
    name: '治安调查人员',
    level: '3',
  },
  {
    id: '3030101',
    name: '邮政营业员',
    level: '1',
  },
  {
    id: '3030102',
    name: '邮件分检、接发员',
    level: '1',
  },
  {
    id: '3030103',
    name: '火车、汽车邮件押运员',
    level: '3',
  },
  {
    id: '3030104',
    name: '轮船邮件押运员',
    level: '4',
  },
  {
    id: '3030105',
    name: '报刊分发员',
    level: '1',
  },
  {
    id: '3030106',
    name: '投递员(外勤)',
    level: '2',
  },
  {
    id: '3030107',
    name: '投递员(内勤)',
    level: '1',
  },
  {
    id: '3030108',
    name: '邮政储汇员',
    level: '1',
  },
  {
    id: '3030109',
    name: '报刊发行、零售员',
    level: '1',
  },
  {
    id: '3030110',
    name: '集邮业务员',
    level: '1',
  },
  {
    id: '3030111',
    name: '邮政业务档案员',
    level: '1',
  },
  {
    id: '3030112',
    name: '邮政设备安装、维护人员',
    level: '3',
  },
  {
    id: '3030113',
    name: '包裹邮务人员',
    level: '3',
  },
  {
    id: '3030114',
    name: '包裹搬运人员',
    level: '4',
  },
  {
    id: '3030201',
    name: '电信业务营业员',
    level: '1',
  },
  {
    id: '3030202',
    name: '话务员',
    level: '1',
  },
  {
    id: '3030203',
    name: '报务员',
    level: '1',
  },
  {
    id: '3030301',
    name: '通信设备维护人员',
    level: '3',
  },
  {
    id: '3030302',
    name: '电话、通信天、馈线架(敷)设、维修人员',
    level: '4',
  },
  {
    id: '3030303',
    name: '电话电报交换设备安装、维护人员',
    level: '3',
  },
  {
    id: '3030304',
    name: '电话机、寻呼机、传真机等用户终端设备维修员',
    level: '2',
  },
  {
    id: '3030305',
    name: '通信系统供电设备、空调设备安装维护人员',
    level: '4',
  },
  {
    id: '3030306',
    name: '市话障碍处理人员',
    level: '2',
  },
  {
    id: '3030307',
    name: '电信装置维护修理工',
    level: '3',
  },
  {
    id: '3030308',
    name: '电信工程设施架设人员',
    level: '4',
  },
  {
    id: '3040101',
    name: '客户主任、统筹、设计师、市场拓展主任',
    level: '1',
  },
  {
    id: '3040102',
    name: '撰稿员、一般内勤',
    level: '1',
  },
  {
    id: '3040201',
    name: '广告业务员',
    level: '2',
  },
  {
    id: '3040202',
    name: '广告招牌绘制人员',
    level: '4',
  },
  {
    id: '3040203',
    name: '广告片拍摄录制人员',
    level: '2',
  },
  {
    id: '3040204',
    name: '广告招牌架设人员',
    level: '5',
  },
  {
    id: '3040205',
    name: '霓虹光管安装及维修人员',
    level: '5',
  },
  {
    id: '3050101',
    name: '会展设计师',
    level: '2',
  },
  {
    id: '3050102',
    name: '会展策划师',
    level: '1',
  },
  {
    id: '3050201',
    name: '景观设计师',
    level: '2',
  },
  {
    id: '3060101',
    name: '建筑模型设计制作员',
    level: '2',
  },
  {
    id: '3060202',
    name: '动画绘制员',
    level: '1',
  },
  {
    id: '3070101',
    name: '数字视频(DV)策划制作师',
    level: '2',
  },
  {
    id: '3080101',
    name: '智能楼宇管理师',
    level: '2',
  },
  {
    id: '4010101',
    name: '营业员',
    level: '1',
  },
  {
    id: '4010102',
    name: '收银员',
    level: '1',
  },
  {
    id: '4010201',
    name: '推销员',
    level: '2',
  },
  {
    id: '4010202',
    name: '出版物发行员',
    level: '1',
  },
  {
    id: '4010203',
    name: '服装模特',
    level: '1',
  },
  {
    id: '4010401',
    name: '旧货、古玩、字画等物品鉴定估价师',
    level: '1',
  },
  {
    id: '4010402',
    name: '拍卖师',
    level: '1',
  },
  {
    id: '4010403',
    name: '典当业务员',
    level: '1',
  },
  {
    id: '4010404',
    name: '租赁业务员',
    level: '1',
  },
  {
    id: '4010501',
    name: '废旧物资回收挑选工',
    level: '2',
  },
  {
    id: '4010502',
    name: '废旧物资加工工',
    level: '3',
  },
  {
    id: '4010503',
    name: '船舶拆解工(仪表拆解)',
    level: '3',
  },
  {
    id: '4010504',
    name: '船舶拆解工(机舱、船体拆解)',
    level: '5',
  },
  {
    id: '4010505',
    name: '船舶拆解工(油船清洗工)',
    level: '5',
  },
  {
    id: '4010601',
    name: '粮油管理员',
    level: '2',
  },
  {
    id: '4010701',
    name: '商品监督员',
    level: '1',
  },
  {
    id: '4010702',
    name: '市场管理员',
    level: '2',
  },
  {
    id: '4019901',
    name: '医药商品购销员',
    level: '2',
  },
  {
    id: '4019902',
    name: '中药配方、制剂调配人员',
    level: '1',
  },
  {
    id: '4019903',
    name: '个体摊贩',
    level: '2',
  },
  {
    id: '4010301',
    name: '采购员',
    level: '2',
  },
  {
    id: '4010302',
    name: '粮油、农副土特产品、蔬菜等商品收购员',
    level: '2',
  },
  {
    id: '4010303',
    name: '中药购销员',
    level: '2',
  },
  {
    id: '4020101',
    name: '保管员',
    level: '3',
  },
  {
    id: '4020102',
    name: '理货员',
    level: '3',
  },
  {
    id: '4020103',
    name: '库存商品养护员',
    level: '2',
  },
  {
    id: '4020104',
    name: '保鲜员',
    level: '2',
  },
  {
    id: '4020105',
    name: '冷藏工',
    level: '3',
  },
  {
    id: '4020201',
    name: '商品储运员',
    level: '2',
  },
  {
    id: '4020202',
    name: '商品护运员',
    level: '3',
  },
  {
    id: '4020203',
    name: '医药商品储运员',
    level: '2',
  },
  {
    id: '4020204',
    name: '出版物储运员',
    level: '2',
  },
  {
    id: '4020205',
    name: '货柜场检查员(控制室内)',
    level: '2',
  },
  {
    id: '4020206',
    name: '货柜场检查员(站立于货柜上)',
    level: '4',
  },
  {
    id: '4020207',
    name: '起重机操作员(于控制室内)',
    level: '2',
  },
  {
    id: '4020208',
    name: '起重机操作员',
    level: '4',
  },
  {
    id: '4030101',
    name: '中式烹调师',
    level: '2',
  },
  {
    id: '4030102',
    name: '中式面点师',
    level: '2',
  },
  {
    id: '4030201',
    name: '西式烹调师',
    level: '2',
  },
  {
    id: '4030202',
    name: '西式面点师',
    level: '2',
  },
  {
    id: '4030301',
    name: '调酒师',
    level: '1',
  },
  {
    id: '4030302',
    name: '茶艺师',
    level: '1',
  },
  {
    id: '4030303',
    name: '咖啡师',
    level: '1',
  },
  {
    id: '4030501',
    name: '餐厅服务员',
    level: '2',
  },
  {
    id: '4030502',
    name: '餐具清洗保管员',
    level: '2',
  },
  {
    id: '4030503',
    name: '经理人员',
    level: '1',
  },
  {
    id: '4030504',
    name: '一般内勤人员',
    level: '1',
  },
  {
    id: '4030505',
    name: '收帐员',
    level: '2',
  },
  {
    id: '4030401',
    name: '营养配餐员',
    level: '1',
  },
  {
    id: '4040101',
    name: '前厅服务员',
    level: '1',
  },
  {
    id: '4040102',
    name: '客房服务员',
    level: '1',
  },
  {
    id: '4040103',
    name: '旅店服务员',
    level: '1',
  },
  {
    id: '4040104',
    name: '旅馆负责人',
    level: '1',
  },
  {
    id: '4040105',
    name: '一般内勤人员',
    level: '1',
  },
  {
    id: '4040106',
    name: '外务员',
    level: '2',
  },
  {
    id: '4040107',
    name: '收帐员',
    level: '2',
  },
  {
    id: '4040108',
    name: '技工',
    level: '3',
  },
  {
    id: '4040201',
    name: '导游',
    level: '2',
  },
  {
    id: '4040202',
    name: '公共游览场所服务员',
    level: '1',
  },
  {
    id: '4040203',
    name: '展览讲解员',
    level: '1',
  },
  {
    id: '4040204',
    name: '插花员',
    level: '1',
  },
  {
    id: '4040205',
    name: '盆景工',
    level: '2',
  },
  {
    id: '4040206',
    name: '假山工',
    level: '5',
  },
  {
    id: '4040207',
    name: '园林植物保护工',
    level: '2',
  },
  {
    id: '4040208',
    name: '一般内勤人员',
    level: '1',
  },
  {
    id: '4040209',
    name: '外务人员',
    level: '2',
  },
  {
    id: '4040301',
    name: '群众性体育活动指导员',
    level: '1',
  },
  {
    id: '4040302',
    name: '体育场地维护、保养工',
    level: '2',
  },
  {
    id: '4040303',
    name: '康乐服务员',
    level: '1',
  },
  {
    id: '4040304',
    name: '保健按摩师',
    level: '2',
  },
  {
    id: '4040305',
    name: '桑拿业负责人',
    level: '1',
  },
  {
    id: '4040306',
    name: '桑拿业柜台人员',
    level: '1',
  },
  {
    id: '4040307',
    name: '社会文化指导员',
    level: '1',
  },
  {
    id: '4040308',
    name: '芳香保健师',
    level: '1',
  },
  {
    id: '4040401',
    name: '教练',
    level: '2',
  },
  {
    id: '4040402',
    name: '球场保养人员',
    level: '2',
  },
  {
    id: '4040403',
    name: '维护工人',
    level: '2',
  },
  {
    id: '4040404',
    name: '球童',
    level: '2',
  },
  {
    id: '4040501',
    name: '记分员',
    level: '1',
  },
  {
    id: '4040502',
    name: '柜台人员',
    level: '1',
  },
  {
    id: '4040503',
    name: '机械保护员',
    level: '3',
  },
  {
    id: '4040601',
    name: '负责人',
    level: '2',
  },
  {
    id: '4040602',
    name: '记分员',
    level: '2',
  },
  {
    id: '4041001',
    name: '咖啡厅工作人员',
    level: '2',
  },
  {
    id: '4041002',
    name: '茶室工作人员',
    level: '2',
  },
  {
    id: '4041003',
    name: '酒家工作人员',
    level: '2',
  },
  {
    id: '4041004',
    name: '夜总会工作人员',
    level: '3',
  },
  {
    id: '4041005',
    name: '迪斯科舞厅工作人员',
    level: '4',
  },
  {
    id: '4041006',
    name: '歌厅工作人员',
    level: '3',
  },
  {
    id: '4041007',
    name: '酒吧工作人员',
    level: '3',
  },
  {
    id: '4041008',
    name: '娱乐餐饮业负责人',
    level: '2',
  },
  {
    id: '4041009',
    name: '保安人员',
    level: '4',
  },
  {
    id: '4041010',
    name: '调酒师',
    level: '3',
  },
  {
    id: '4041011',
    name: '乐队人员',
    level: '3',
  },
  {
    id: '4041012',
    name: '歌唱人员',
    level: '3',
  },
  {
    id: '4041013',
    name: 'DJ',
    level: '3',
  },
  {
    id: '4041014',
    name: '迎宾',
    level: '3',
  },
  {
    id: '4041015',
    name: '舞蹈演艺人员',
    level: '4',
  },
  {
    id: '4041016',
    name: '电子游戏厅工作人员',
    level: '3',
  },
  {
    id: '4041017',
    name: '网吧管理人员',
    level: '3',
  },
  {
    id: '4050101',
    name: '汽车客运服务员',
    level: '1',
  },
  {
    id: '4050102',
    name: '汽车货运站务员',
    level: '2',
  },
  {
    id: '4050103',
    name: '汽车运输调度员',
    level: '1',
  },
  {
    id: '4050104',
    name: '公路收费及监控员',
    level: '2',
  },
  {
    id: '4050105',
    name: '加油站工作人员',
    level: '2',
  },
  {
    id: '4050106',
    name: '洗车工人',
    level: '2',
  },
  {
    id: '4050107',
    name: '客运车稽核人员',
    level: '2',
  },
  {
    id: '4050108',
    name: '搬运工人',
    level: '6',
  },
  {
    id: '4050109',
    name: '出租车行、货运行之负责人',
    level: '1',
  },
  {
    id: '4050110',
    name: '外务员',
    level: '2',
  },
  {
    id: '4050111',
    name: '内勤工作人员',
    level: '1',
  },
  {
    id: '4050112',
    name: '车辆保管人员',
    level: '2',
  },
  {
    id: '4050113',
    name: '汽车加气站操作工',
    level: '3',
  },
  {
    id: '4050201',
    name: '旅客列车乘务员',
    level: '2',
  },
  {
    id: '4050202',
    name: '车站客运服务员',
    level: '2',
  },
  {
    id: '4050203',
    name: '货运计划员',
    level: '1',
  },
  {
    id: '4050204',
    name: '铁路行李员',
    level: '2',
  },
  {
    id: '4050205',
    name: '车站货运作业组织员',
    level: '1',
  },
  {
    id: '4050206',
    name: '车站货运员',
    level: '3',
  },
  {
    id: '4050207',
    name: '铁路站长',
    level: '1',
  },
  {
    id: '4050208',
    name: '铁路票房工作人员、车站检票员、一般内勤人员',
    level: '1',
  },
  {
    id: '4050209',
    name: '铁路播音员',
    level: '1',
  },
  {
    id: '4050210',
    name: '铁路服务台人员',
    level: '1',
  },
  {
    id: '4050211',
    name: '铁路车站清洁工人',
    level: '2',
  },
  {
    id: '4050212',
    name: '铁路货运、搬运工人',
    level: '4',
  },
  {
    id: '4050213',
    name: '铁路行李搬运工人',
    level: '3',
  },
  {
    id: '4050301',
    name: '航空运输飞行服务员',
    level: '6',
  },
  {
    id: '4050302',
    name: '航空运输地面服务员',
    level: '1',
  },
  {
    id: '4050303',
    name: '航空站站长',
    level: '1',
  },
  {
    id: '4050304',
    name: '一般内勤人员',
    level: '1',
  },
  {
    id: '4050305',
    name: '塔台工作人员',
    level: '1',
  },
  {
    id: '4050306',
    name: '关务人员',
    level: '1',
  },
  {
    id: '4050307',
    name: '检查人员',
    level: '1',
  },
  {
    id: '4050308',
    name: '运务人员',
    level: '1',
  },
  {
    id: '4050309',
    name: '缉私人员',
    level: '2',
  },
  {
    id: '4050310',
    name: '清洁工人(航空大厦内、机舱内)',
    level: '2',
  },
  {
    id: '4050311',
    name: '机场内交通司机',
    level: '3',
  },
  {
    id: '4050312',
    name: '行李货运搬运工人',
    level: '3',
  },
  {
    id: '4050313',
    name: '加添燃料人员',
    level: '4',
  },
  {
    id: '4050314',
    name: '飞机外壳洗刷人员',
    level: '4',
  },
  {
    id: '4050315',
    name: '清洁工(登高、高墙或天花板)',
    level: '4',
  },
  {
    id: '4050316',
    name: '跑道维护工',
    level: '4',
  },
  {
    id: '4050318',
    name: '航空货运外务员',
    level: '2',
  },
  {
    id: '4050319',
    name: '航空货运报关人员',
    level: '2',
  },
  {
    id: '4050320',
    name: '航空货运理货员',
    level: '3',
  },
  {
    id: '4050321',
    name: '灯塔修理员',
    level: '4',
  },
  {
    id: '4050322',
    name: '喷气式民航班机乘务员',
    level: '4',
  },
  {
    id: '4050323',
    name: '机场运行指挥员',
    level: '1',
  },
  {
    id: '4050401',
    name: '船舶业务员',
    level: '4',
  },
  {
    id: '4050402',
    name: '船舶客运员',
    level: '6',
  },
  {
    id: '4050403',
    name: '港口客运员',
    level: '2',
  },
  {
    id: '4050404',
    name: '厨师',
    level: '6',
  },
  {
    id: '4050405',
    name: '医务人员',
    level: '4',
  },
  {
    id: '4050406',
    name: '救难船员',
    level: '6',
  },
  {
    id: '4050407',
    name: '实习生',
    level: '6',
  },
  {
    id: '4050408',
    name: '码头工人及领班',
    level: '4',
  },
  {
    id: '4050409',
    name: '堆高机操作员',
    level: '4',
  },
  {
    id: '4050410',
    name: '水上运输理货员',
    level: '4',
  },
  {
    id: '4050411',
    name: '报关员',
    level: '2',
  },
  {
    id: '4060101',
    name: '病案员',
    level: '1',
  },
  {
    id: '4060102',
    name: '护理员',
    level: '2',
  },
  {
    id: '4060103',
    name: '口腔修复工',
    level: '2',
  },
  {
    id: '4060104',
    name: '配膳员',
    level: '2',
  },
  {
    id: '4060105',
    name: '卫生检验员',
    level: '2',
  },
  {
    id: '4060106',
    name: '医用气体工',
    level: '2',
  },
  {
    id: '4060107',
    name: '药房辅助员',
    level: '1',
  },
  {
    id: '4060108',
    name: '卫生防疫、妇幼保健员',
    level: '1',
  },
  {
    id: '4060109',
    name: '医院炊事',
    level: '2',
  },
  {
    id: '4060110',
    name: '杂工',
    level: '2',
  },
  {
    id: '4070101',
    name: '中介代理人',
    level: '2',
  },
  {
    id: '4070102',
    name: '职业指导员',
    level: '1',
  },
  {
    id: '4070103',
    name: '经纪人(内勤)',
    level: '1',
  },
  {
    id: '4070104',
    name: '创业咨询师',
    level: '1',
  },
  {
    id: '4070105',
    name: '婚姻家庭咨询师',
    level: '1',
  },
  {
    id: '4070106',
    name: '礼仪主持人',
    level: '1',
  },
  {
    id: '4070107',
    name: '体育经纪人',
    level: '1',
  },
  {
    id: '4070108',
    name: '商务策划师',
    level: '1',
  },
  {
    id: '4070109',
    name: '房地产策划师',
    level: '1',
  },
  {
    id: '4070201',
    name: '物业管理员',
    level: '2',
  },
  {
    id: '4070301',
    name: '工业及饮用水净化处理人员',
    level: '2',
  },
  {
    id: '4070302',
    name: '工业及饮用水生产人员(水井工)',
    level: '4',
  },
  {
    id: '4070303',
    name: '供水调度员',
    level: '1',
  },
  {
    id: '4070304',
    name: '水表装修工',
    level: '2',
  },
  {
    id: '4070305',
    name: '成型煤生产工',
    level: '3',
  },
  {
    id: '4070307',
    name: '锅炉安装、调试、操作、检修人员',
    level: '4',
  },
  {
    id: '4070308',
    name: '电信电力系统内勤人员',
    level: '1',
  },
  {
    id: '4070309',
    name: '抄表员、收费员',
    level: '2',
  },
  {
    id: '4070310',
    name: '自来水管装修人员',
    level: '3',
  },
  {
    id: '4070311',
    name: '自来水厂水质分析员',
    level: '3',
  },
  {
    id: '4070312',
    name: '煤气工程师',
    level: '2',
  },
  {
    id: '4070313',
    name: '煤气管线装修工',
    level: '3',
  },
  {
    id: '4070314',
    name: '煤气检查员',
    level: '2',
  },
  {
    id: '4070315',
    name: '煤气储气槽、分装厂之工作人员',
    level: '3',
  },
  {
    id: '4070316',
    name: '瓦斯分装工',
    level: '5',
  },
  {
    id: '4070401',
    name: '美容师',
    level: '1',
  },
  {
    id: '4070402',
    name: '美发师',
    level: '1',
  },
  {
    id: '4070403',
    name: '洗头工',
    level: '1',
  },
  {
    id: '4070404',
    name: '形象设计师',
    level: '1',
  },
  {
    id: '4070405',
    name: '调香师',
    level: '1',
  },
  {
    id: '4070501',
    name: '摄影师',
    level: '1',
  },
  {
    id: '4070502',
    name: '冲印师',
    level: '1',
  },
  {
    id: '4070601',
    name: '眼镜验光员',
    level: '1',
  },
  {
    id: '4070602',
    name: '眼镜定配工',
    level: '1',
  },
  {
    id: '4070603',
    name: '助听器验配师',
    level: '1',
  },
  {
    id: '4070701',
    name: '洗衣师、烫衣师、织补师',
    level: '2',
  },
  {
    id: '4070702',
    name: '染色师',
    level: '2',
  },
  {
    id: '4070801',
    name: '浴池服务员',
    level: '2',
  },
  {
    id: '4070802',
    name: '修脚师',
    level: '2',
  },
  {
    id: '4070901',
    name: '刻制印章工',
    level: '2',
  },
  {
    id: '4071001',
    name: '家用电子产品维修工',
    level: '2',
  },
  {
    id: '4071002',
    name: '家用电器产品维修工',
    level: '3',
  },
  {
    id: '4071003',
    name: '照相器材维修工',
    level: '2',
  },
  {
    id: '4071004',
    name: '钟表维修工',
    level: '1',
  },
  {
    id: '4071005',
    name: '乐器维修工',
    level: '1',
  },
  {
    id: '4071006',
    name: '自行车维修工',
    level: '2',
  },
  {
    id: '4071007',
    name: '锁具修理工',
    level: '2',
  },
  {
    id: '4071101',
    name: '办公设备维修工',
    level: '2',
  },
  {
    id: '4071201',
    name: '保育员',
    level: '1',
  },
  {
    id: '4071202',
    name: '家庭服务员',
    level: '2',
  },
  {
    id: '4071203',
    name: '家庭主妇',
    level: '1',
  },
  {
    id: '4071204',
    name: '孤残儿童护理员',
    level: '2',
  },
  {
    id: '4071301',
    name: '垃圾清运工',
    level: '3',
  },
  {
    id: '4071302',
    name: '保洁员',
    level: '2',
  },
  {
    id: '4071303',
    name: '下水道清洁工',
    level: '4',
  },
  {
    id: '4071304',
    name: '清洁打蜡工人',
    level: '2',
  },
  {
    id: '4071305',
    name: '高楼外部清洁工、烟囱清洁工',
    level: '5',
  },
  {
    id: '4071401',
    name: '殡仪服务员',
    level: '1',
  },
  {
    id: '4071402',
    name: '尸体接运工',
    level: '4',
  },
  {
    id: '4071403',
    name: '尸体防腐工',
    level: '4',
  },
  {
    id: '4071404',
    name: '尸体整容工',
    level: '2',
  },
  {
    id: '4071405',
    name: '尸体火化工',
    level: '3',
  },
  {
    id: '4071406',
    name: '墓地管理员',
    level: '2',
  },
  {
    id: '4071501',
    name: '橱具商',
    level: '1',
  },
  {
    id: '4071502',
    name: '陶瓷器商',
    level: '1',
  },
  {
    id: '4071503',
    name: '古董商',
    level: '1',
  },
  {
    id: '4071504',
    name: '花卉商',
    level: '1',
  },
  {
    id: '4071505',
    name: '米商',
    level: '1',
  },
  {
    id: '4071506',
    name: '杂货商',
    level: '1',
  },
  {
    id: '4071507',
    name: '玻璃商',
    level: '2',
  },
  {
    id: '4071508',
    name: '果菜商',
    level: '1',
  },
  {
    id: '4071509',
    name: '石材商',
    level: '2',
  },
  {
    id: '4071510',
    name: '建材商',
    level: '2',
  },
  {
    id: '4071511',
    name: '铁材商',
    level: '2',
  },
  {
    id: '4071512',
    name: '木材商',
    level: '2',
  },
  {
    id: '4071513',
    name: '五金商',
    level: '2',
  },
  {
    id: '4071514',
    name: '电器商',
    level: '2',
  },
  {
    id: '4071515',
    name: '水电卫生器材商',
    level: '2',
  },
  {
    id: '4071516',
    name: '机车买卖商(不含修理)',
    level: '1',
  },
  {
    id: '4071517',
    name: '汽车买卖商(不含修理)',
    level: '1',
  },
  {
    id: '4071518',
    name: '车辆器材商',
    level: '1',
  },
  {
    id: '4071519',
    name: '矿物油、香烛买卖商',
    level: '2',
  },
  {
    id: '4071520',
    name: '眼镜商',
    level: '2',
  },
  {
    id: '4071521',
    name: '食品商',
    level: '1',
  },
  {
    id: '4071522',
    name: '文具商',
    level: '1',
  },
  {
    id: '4071523',
    name: '布商',
    level: '1',
  },
  {
    id: '4071524',
    name: '服饰买卖商',
    level: '1',
  },
  {
    id: '4071525',
    name: '鱼贩',
    level: '3',
  },
  {
    id: '4071526',
    name: '肉贩',
    level: '4',
  },
  {
    id: '4071527',
    name: '屠宰',
    level: '4',
  },
  {
    id: '4071528',
    name: '药品买卖商',
    level: '1',
  },
  {
    id: '4071529',
    name: '医疗器械仪器商',
    level: '2',
  },
  {
    id: '4071530',
    name: '化学原料商、农药买卖商',
    level: '3',
  },
  {
    id: '4071531',
    name: '手工艺品买卖商',
    level: '1',
  },
  {
    id: '4071532',
    name: '银楼珠宝、当铺负责人及工作人员',
    level: '3',
  },
  {
    id: '4071533',
    name: '农具商',
    level: '2',
  },
  {
    id: '4071601',
    name: '负责人',
    level: '1',
  },
  {
    id: '4071602',
    name: '店员',
    level: '1',
  },
  {
    id: '4071604',
    name: '装饰工',
    level: '3',
  },
  {
    id: '4071701',
    name: '负责人及工作人员',
    level: '2',
  },
  {
    id: '4071702',
    name: '售货商',
    level: '3',
  },
  {
    id: '4990001',
    name: '一般物品送货员、快递员',
    level: '2',
  },
  {
    id: '4990002',
    name: '特殊物品送货员、快递员',
    level: '3',
  },
  {
    id: '4990003',
    name: '公证行外务员',
    level: '2',
  },
  {
    id: '4990004',
    name: '报关行外务员',
    level: '2',
  },
  {
    id: '4990005',
    name: '鞋匠、伞匠',
    level: '2',
  },
  {
    id: '4990006',
    name: '代书(内勤)',
    level: '1',
  },
  {
    id: '4990007',
    name: '勘察师',
    level: '2',
  },
  {
    id: '4990008',
    name: '地磅场工作人员',
    level: '2',
  },
  {
    id: '4990009',
    name: '豆制品工艺师',
    level: '1',
  },
  {
    id: '4990010',
    name: '呼叫服务员',
    level: '1',
  },
  {
    id: '4990011',
    name: '牛肉分级员',
    level: '2',
  },
  {
    id: '4990012',
    name: '首饰设计制作员',
    level: '1',
  },
  {
    id: '4990013',
    name: '酿酒师',
    level: '1',
  },
  {
    id: '4990014',
    name: '照明设计师',
    level: '1',
  },
  {
    id: '4990015',
    name: '模具设计师',
    level: '1',
  },
  {
    id: '4990016',
    name: '纺织面料设计师',
    level: '1',
  },
  {
    id: '4990017',
    name: '玩具设计师',
    level: '1',
  },
  {
    id: '4990018',
    name: '花艺环境设计师',
    level: '1',
  },
  {
    id: '4990019',
    name: '霓虹灯制作员',
    level: '2',
  },
  {
    id: '4990020',
    name: '糖果工艺师',
    level: '1',
  },
  {
    id: '4990021',
    name: '陶瓷产品设计师',
    level: '1',
  },
  {
    id: '4990022',
    name: '皮具设计师',
    level: '1',
  },
  {
    id: '4990023',
    name: '珠宝首饰评估师',
    level: '1',
  },
  {
    id: '4990024',
    name: '家具设计师',
    level: '1',
  },
  {
    id: '4990025',
    name: '地毯设计师',
    level: '1',
  },
  {
    id: '4990026',
    name: '家用纺织品设计师',
    level: '1',
  },
  {
    id: '4990027',
    name: '企业文化师',
    level: '1',
  },
  {
    id: '4990028',
    name: '网络课件设计师',
    level: '1',
  },
  {
    id: '4990029',
    name: '危险物品送货员',
    level: '4',
  },
  {
    id: '5010201',
    name: '农业实验工',
    level: '2',
  },
  {
    id: '5010202',
    name: '农情测报员',
    level: '2',
  },
  {
    id: '5010301',
    name: '蔬菜园艺工',
    level: '2',
  },
  {
    id: '5010302',
    name: '花卉园艺工',
    level: '2',
  },
  {
    id: '5010303',
    name: '果、茶、桑园艺工',
    level: '3',
  },
  {
    id: '5010304',
    name: '菌类园艺工',
    level: '2',
  },
  {
    id: '5010401',
    name: '天然橡胶生产工',
    level: '2',
  },
  {
    id: '5010402',
    name: '剑麻生产工',
    level: '2',
  },
  {
    id: '5010501',
    name: '中药材种植员',
    level: '2',
  },
  {
    id: '5010502',
    name: '中药材养殖员',
    level: '2',
  },
  {
    id: '5010503',
    name: '中药材生产管理员',
    level: '2',
  },
  {
    id: '5010601',
    name: '棉花加工工',
    level: '4',
  },
  {
    id: '5010602',
    name: '果类产品加工工',
    level: '3',
  },
  {
    id: '5010603',
    name: '茶叶加工工',
    level: '2',
  },
  {
    id: '5010604',
    name: '蔬菜加工工',
    level: '2',
  },
  {
    id: '5010605',
    name: '竹、藤、麻、棕、草制品加工工',
    level: '3',
  },
  {
    id: '5010606',
    name: '特种植物原料加工工',
    level: '3',
  },
  {
    id: '5010607',
    name: '糖厂技工',
    level: '4',
  },
  {
    id: '5010608',
    name: '农作物种子加工',
    level: '2',
  },
  {
    id: '5010101',
    name: '农夫',
    level: '2',
  },
  {
    id: '5010102',
    name: '啤酒花生产工',
    level: '2',
  },
  {
    id: '5010103',
    name: '作物种子繁育工',
    level: '2',
  },
  {
    id: '5010104',
    name: '农作物病虫草害防治工',
    level: '2',
  },
  {
    id: '5010105',
    name: '农场经营者(不亲自作业)',
    level: '1',
  },
  {
    id: '5010107',
    name: '山、林承包商',
    level: '1',
  },
  {
    id: '5010108',
    name: '农业技术指导人员',
    level: '2',
  },
  {
    id: '5020101',
    name: '林木种苗工',
    level: '2',
  },
  {
    id: '5020102',
    name: '造林更新工',
    level: '4',
  },
  {
    id: '5020103',
    name: '抚育采伐工',
    level: '6',
  },
  {
    id: '5020104',
    name: '营林试验工',
    level: '2',
  },
  {
    id: '5020105',
    name: '领班',
    level: '3',
  },
  {
    id: '5020106',
    name: '实验室育苗栽培人员',
    level: '1',
  },
  {
    id: '5020301',
    name: '野生动物保护员',
    level: '6',
  },
  {
    id: '5020302',
    name: '野生植物保护员',
    level: '3',
  },
  {
    id: '5020303',
    name: '自然保护区巡护监测员',
    level: '3',
  },
  {
    id: '5020304',
    name: '动植物标本员',
    level: '3',
  },
  {
    id: '5020401',
    name: '木材采伐工',
    level: '6',
  },
  {
    id: '5020402',
    name: '集材作业工',
    level: '6',
  },
  {
    id: '5020403',
    name: '木材水运工',
    level: '6',
  },
  {
    id: '5020404',
    name: '装卸归楞工',
    level: '6',
  },
  {
    id: '5020405',
    name: '锯木工人',
    level: '6',
  },
  {
    id: '5020406',
    name: '运材车辆之司机及押运人员',
    level: '6',
  },
  {
    id: '5020407',
    name: '起重机之操作人员',
    level: '6',
  },
  {
    id: '5020408',
    name: '领班、监工',
    level: '4',
  },
  {
    id: '5020201',
    name: '护林员',
    level: '4',
  },
  {
    id: '5020202',
    name: '森林病虫害防治员',
    level: '2',
  },
  {
    id: '5020203',
    name: '森林防火员',
    level: '6',
  },
  {
    id: '5020204',
    name: '木材防腐师',
    level: '3',
  },
  {
    id: '5030301',
    name: '蜜蜂饲养工',
    level: '3',
  },
  {
    id: '5030302',
    name: '蜂产品加工工',
    level: '2',
  },
  {
    id: '5030401',
    name: '实验动物饲养工',
    level: '2',
  },
  {
    id: '5030501',
    name: '动物疫病防治员',
    level: '3',
  },
  {
    id: '5030502',
    name: '兽医化验员',
    level: '2',
  },
  {
    id: '5030503',
    name: '动物检疫检验员',
    level: '2',
  },
  {
    id: '5030504',
    name: '中兽医员',
    level: '3',
  },
  {
    id: '5030505',
    name: '宠物健康护理人员',
    level: '3',
  },
  {
    id: '5030601',
    name: '草地监护员',
    level: '4',
  },
  {
    id: '5030602',
    name: '牧草培育、加工人员',
    level: '3',
  },
  {
    id: '5030603',
    name: '草坪建植工',
    level: '2',
  },
  {
    id: '5030201',
    name: '家禽饲养工',
    level: '2',
  },
  {
    id: '5030202',
    name: '家禽繁殖工',
    level: '2',
  },
  {
    id: '5030101',
    name: '家畜饲养工',
    level: '3',
  },
  {
    id: '5030102',
    name: '家畜繁殖工',
    level: '3',
  },
  {
    id: '5030103',
    name: '畜牧场经营者(不亲自作业)',
    level: '1',
  },
  {
    id: '5030104',
    name: '驯犬人员',
    level: '4',
  },
  {
    id: '5030105',
    name: '畜牧工作人员',
    level: '3',
  },
  {
    id: '5030106',
    name: '宠物训导师',
    level: '3',
  },
  {
    id: '5030107',
    name: '有毒动物饲养工(蛇、蝎子、蜈蚣等)',
    level: '6',
  },
  {
    id: '5040101',
    name: '水生动物苗种繁育工',
    level: '3',
  },
  {
    id: '5040102',
    name: '水生植物苗种培育工',
    level: '3',
  },
  {
    id: '5040103',
    name: '水生动物饲养工',
    level: '3',
  },
  {
    id: '5040104',
    name: '水生植物栽培工',
    level: '3',
  },
  {
    id: '5040105',
    name: '珍珠养殖工(淡水)',
    level: '3',
  },
  {
    id: '5040106',
    name: '珍珠养殖工(海水)',
    level: '6',
  },
  {
    id: '5040107',
    name: '生物饵料培养工',
    level: '3',
  },
  {
    id: '5040108',
    name: '水产养殖潜水工',
    level: '6',
  },
  {
    id: '5040109',
    name: '渔场经营者(不亲自作业)',
    level: '1',
  },
  {
    id: '5040110',
    name: '渔场经营者(亲自作业)',
    level: '3',
  },
  {
    id: '5040111',
    name: '热带鱼养殖者、水族馆经营者',
    level: '2',
  },
  {
    id: '5040112',
    name: '养殖工人(沿海)',
    level: '6',
  },
  {
    id: '5040113',
    name: '水生哺乳动物训养师',
    level: '4',
  },
  {
    id: '5040301',
    name: '水产品原料处理工',
    level: '3',
  },
  {
    id: '5040302',
    name: '水产品腌熏烤制工',
    level: '2',
  },
  {
    id: '5040303',
    name: '鱼糜及鱼糜制品加工工',
    level: '3',
  },
  {
    id: '5040304',
    name: '鱼粉加工工',
    level: '3',
  },
  {
    id: '5040305',
    name: '鱼肝油及制品加工工',
    level: '3',
  },
  {
    id: '5040306',
    name: '海藻制碘工',
    level: '2',
  },
  {
    id: '5040307',
    name: '海藻制醇工',
    level: '2',
  },
  {
    id: '5040308',
    name: '海藻制胶工',
    level: '4',
  },
  {
    id: '5040309',
    name: '海藻食品加工工',
    level: '3',
  },
  {
    id: '5040310',
    name: '贝类净化工',
    level: '2',
  },
  {
    id: '5049901',
    name: '水产实验人员(室内)',
    level: '1',
  },
  {
    id: '5049902',
    name: '水产养殖质量管理员',
    level: '1',
  },
  {
    id: '5049903',
    name: '水产品质量检验员',
    level: '1',
  },
  {
    id: '5040201',
    name: '水产捕捞工',
    level: '3',
  },
  {
    id: '5040202',
    name: '渔业生产船员',
    level: '7',
  },
  {
    id: '5040203',
    name: '水生动植物采集工',
    level: '3',
  },
  {
    id: '5040204',
    name: '渔网具装配工',
    level: '2',
  },
  {
    id: '5040205',
    name: '捕鱼人(沿海)',
    level: '6',
  },
  {
    id: '5050101',
    name: '河道修防工(堤、坝施工人员)',
    level: '5',
  },
  {
    id: '5050102',
    name: '草皮、防浪林种植养护人员',
    level: '2',
  },
  {
    id: '5050103',
    name: '河道、堤防巡护人员',
    level: '4',
  },
  {
    id: '5050104',
    name: '混凝土工程维修工',
    level: '4',
  },
  {
    id: '5050105',
    name: '土石工程维修工',
    level: '4',
  },
  {
    id: '5050106',
    name: '水工检测工',
    level: '3',
  },
  {
    id: '5050107',
    name: '建(构)筑物有害动物防治工',
    level: '3',
  },
  {
    id: '5050108',
    name: '水坝水库管理人员',
    level: '3',
  },
  {
    id: '5050109',
    name: '水域环境养护保洁员',
    level: '4',
  },
  {
    id: '5050201',
    name: '灌排工程施工、运行、管护人员',
    level: '3',
  },
  {
    id: '5050202',
    name: '渠道维护工',
    level: '4',
  },
  {
    id: '5050203',
    name: '灌区供水工',
    level: '2',
  },
  {
    id: '5050204',
    name: '灌溉试验工',
    level: '2',
  },
  {
    id: '5050301',
    name: '水土保持防治工',
    level: '2',
  },
  {
    id: '5050302',
    name: '水土保持测试工',
    level: '2',
  },
  {
    id: '5050303',
    name: '水土保持勘测工',
    level: '2',
  },
  {
    id: '5050401',
    name: '水文勘测工',
    level: '2',
  },
  {
    id: '5050402',
    name: '水文勘测船工',
    level: '4',
  },
  {
    id: '5059901',
    name: '水利工程设施人员',
    level: '4',
  },
  {
    id: '5990101',
    name: '拖拉机驾驶员',
    level: '5',
  },
  {
    id: '5990102',
    name: '联合收割机驾驶员',
    level: '3',
  },
  {
    id: '5990103',
    name: '农用运输车驾驶员',
    level: '4',
  },
  {
    id: '5990104',
    name: '农业机械操作或修理人员',
    level: '3',
  },
  {
    id: '5990201',
    name: '沼气工程施工人员',
    level: '5',
  },
  {
    id: '5990202',
    name: '沼气设备安装、调试、检修人员',
    level: '4',
  },
  {
    id: '5990203',
    name: '沼气生产管理人员',
    level: '2',
  },
  {
    id: '5990204',
    name: '农村节能员(灶、坑、炉、窑建造改造人员)',
    level: '4',
  },
  {
    id: '5990205',
    name: '农用太阳能设施工',
    level: '4',
  },
  {
    id: '5990206',
    name: '生物质能设备工',
    level: '4',
  },
  {
    id: '6010101',
    name: '钻探工',
    level: '6',
  },
  {
    id: '6010102',
    name: '坑探工',
    level: '7',
  },
  {
    id: '6010103',
    name: '物探工',
    level: '4',
  },
  {
    id: '6010104',
    name: '地震物探爆炸工',
    level: '7',
  },
  {
    id: '6010105',
    name: '采样工',
    level: '4',
  },
  {
    id: '6010106',
    name: '水文、矿山地质工(从事采送水样、矿样和观测获取数据的人员)',
    level: '4',
  },
  {
    id: '6010107',
    name: '海洋地质取样工',
    level: '7',
  },
  {
    id: '6010108',
    name: '海洋土质试验工',
    level: '1',
  },
  {
    id: '6010109',
    name: '固体岩矿样碎样工',
    level: '2',
  },
  {
    id: '6010110',
    name: '岩矿和古生物样品磨片工',
    level: '3',
  },
  {
    id: '6010111',
    name: '重沙样品淘洗工',
    level: '2',
  },
  {
    id: '6010112',
    name: '劈岩与保管工',
    level: '3',
  },
  {
    id: '6010113',
    name: '地质探测员',
    level: '6',
  },
  {
    id: '6010401',
    name: '筛选破碎工',
    level: '3',
  },
  {
    id: '6010402',
    name: '重力选矿工',
    level: '3',
  },
  {
    id: '6010403',
    name: '浮选工',
    level: '2',
  },
  {
    id: '6010404',
    name: '磁选工',
    level: '2',
  },
  {
    id: '6010405',
    name: '选矿脱水工',
    level: '2',
  },
  {
    id: '6010406',
    name: '尾矿处理工',
    level: '4',
  },
  {
    id: '6010407',
    name: '磨矿工',
    level: '4',
  },
  {
    id: '6010408',
    name: '水煤浆制备工',
    level: '4',
  },
  {
    id: '6010409',
    name: '动力配煤工',
    level: '2',
  },
  {
    id: '6010410',
    name: '工业型煤加工制作人员',
    level: '2',
  },
  {
    id: '6010501',
    name: '钻井设备、井架安装工',
    level: '5',
  },
  {
    id: '6010502',
    name: '钻井工',
    level: '5',
  },
  {
    id: '6010503',
    name: '固井工',
    level: '4',
  },
  {
    id: '6010504',
    name: '平台水手',
    level: '4',
  },
  {
    id: '6010505',
    name: '水下设备操作工',
    level: '7',
  },
  {
    id: '6010506',
    name: '钻勘设备安装换修保养工人',
    level: '5',
  },
  {
    id: '6010601',
    name: '油、气井测试工',
    level: '5',
  },
  {
    id: '6010602',
    name: '采油工',
    level: '5',
  },
  {
    id: '6010603',
    name: '采气工',
    level: '5',
  },
  {
    id: '6010604',
    name: '井下作业工',
    level: '7',
  },
  {
    id: '6010605',
    name: '天然气净化工',
    level: '3',
  },
  {
    id: '6010606',
    name: '油气输送工',
    level: '3',
  },
  {
    id: '6010607',
    name: '油气管道保护工',
    level: '5',
  },
  {
    id: '6010608',
    name: '行政人员',
    level: '2',
  },
  {
    id: '6010609',
    name: '技术员',
    level: '5',
  },
  {
    id: '6010701',
    name: '海盐晒制工',
    level: '3',
  },
  {
    id: '6010702',
    name: '海盐采收工',
    level: '2',
  },
  {
    id: '6010703',
    name: '湖盐采掘船工',
    level: '6',
  },
  {
    id: '6010704',
    name: '湖盐采掘爆破工',
    level: '7',
  },
  {
    id: '6010705',
    name: '湖盐脱水工',
    level: '3',
  },
  {
    id: '6010706',
    name: '驳筑、集拆坨盐工',
    level: '3',
  },
  {
    id: '6010707',
    name: '井矿盐采卤工',
    level: '3',
  },
  {
    id: '6010708',
    name: '井矿盐卤水净化工',
    level: '3',
  },
  {
    id: '6010709',
    name: '真空制盐工',
    level: '3',
  },
  {
    id: '6010710',
    name: '冷冻提硝工',
    level: '3',
  },
  {
    id: '6010711',
    name: '苦卤综合利用工',
    level: '3',
  },
  {
    id: '6010712',
    name: '精制盐工',
    level: '3',
  },
  {
    id: '6019901',
    name: '采石业工人',
    level: '7',
  },
  {
    id: '6019902',
    name: '采砂业工人',
    level: '7',
  },
  {
    id: '6010301',
    name: '露天采矿挖掘机司机',
    level: '6',
  },
  {
    id: '6010302',
    name: '钻孔机司机',
    level: '6',
  },
  {
    id: '6010303',
    name: '井筒冻结工',
    level: '6',
  },
  {
    id: '6010304',
    name: '矿井开掘工',
    level: '7',
  },
  {
    id: '6010305',
    name: '井下采矿工',
    level: '7',
  },
  {
    id: '6010306',
    name: '支护工',
    level: '7',
  },
  {
    id: '6010307',
    name: '矿山提升机、架空索道及绞车等操作工',
    level: '4',
  },
  {
    id: '6010308',
    name: '矿井机车运输工',
    level: '7',
  },
  {
    id: '6010309',
    name: '矿井通风工',
    level: '4',
  },
  {
    id: '6010310',
    name: '矿山安全监测工',
    level: '4',
  },
  {
    id: '6010311',
    name: '矿山检查验收工',
    level: '3',
  },
  {
    id: '6010312',
    name: '矿灯、自救器管理工',
    level: '1',
  },
  {
    id: '6010313',
    name: '火药、雷管等火工品管理工',
    level: '5',
  },
  {
    id: '6010314',
    name: '矿山井下抢险、救灾人员',
    level: '7',
  },
  {
    id: '6010315',
    name: '矿物开采辅助工',
    level: '5',
  },
  {
    id: '6010316',
    name: '经营者(不到现场)',
    level: '1',
  },
  {
    id: '6010317',
    name: '经营者(现场监督者)',
    level: '4',
  },
  {
    id: '6010318',
    name: '经理人员',
    level: '2',
  },
  {
    id: '6010319',
    name: '海上所有作业人员(潜水人员拒保)',
    level: '6',
  },
  {
    id: '6010201',
    name: '大地测量工',
    level: '3',
  },
  {
    id: '6010202',
    name: '摄影测量工',
    level: '2',
  },
  {
    id: '6010203',
    name: '地图制图工',
    level: '1',
  },
  {
    id: '6010204',
    name: '工程测量工',
    level: '3',
  },
  {
    id: '6010205',
    name: '地籍测绘工',
    level: '2',
  },
  {
    id: '6020101',
    name: '烧结球团原料工',
    level: '4',
  },
  {
    id: '6020102',
    name: '烧结工',
    level: '4',
  },
  {
    id: '6020103',
    name: '球团焙烧工',
    level: '4',
  },
  {
    id: '6020104',
    name: '烧结成品工',
    level: '4',
  },
  {
    id: '6020105',
    name: '高炉原料工',
    level: '5',
  },
  {
    id: '6020106',
    name: '高炉炉前工',
    level: '7',
  },
  {
    id: '6020107',
    name: '高炉运转工',
    level: '5',
  },
  {
    id: '6020201',
    name: '炼钢原料工',
    level: '6',
  },
  {
    id: '6020202',
    name: '平炉炼钢工',
    level: '6',
  },
  {
    id: '6020203',
    name: '转炉炼钢工',
    level: '6',
  },
  {
    id: '6020204',
    name: '电炉炼钢工',
    level: '6',
  },
  {
    id: '6020205',
    name: '炼钢浇铸工',
    level: '6',
  },
  {
    id: '6020206',
    name: '炼钢准备工',
    level: '5',
  },
  {
    id: '6020207',
    name: '整脱模工',
    level: '5',
  },
  {
    id: '6020208',
    name: '技师',
    level: '3',
  },
  {
    id: '6020209',
    name: '工程师',
    level: '3',
  },
  {
    id: '6020210',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6020301',
    name: '铁合金原料工',
    level: '3',
  },
  {
    id: '6020302',
    name: '铁合金电炉冶炼工',
    level: '5',
  },
  {
    id: '6020303',
    name: '铁合金焙烧工',
    level: '4',
  },
  {
    id: '6020304',
    name: '铁合金湿法冶炼工',
    level: '4',
  },
  {
    id: '6020305',
    name: '铁合金炉外法冶炼工',
    level: '4',
  },
  {
    id: '6020401',
    name: '重冶备料工',
    level: '4',
  },
  {
    id: '6020402',
    name: '焙烧工',
    level: '4',
  },
  {
    id: '6020403',
    name: '火法冶炼工',
    level: '5',
  },
  {
    id: '6020404',
    name: '湿法冶炼工',
    level: '4',
  },
  {
    id: '6020405',
    name: '电解精炼工',
    level: '4',
  },
  {
    id: '6020406',
    name: '烟气制酸工',
    level: '5',
  },
  {
    id: '6020501',
    name: '氧化铝制取工',
    level: '4',
  },
  {
    id: '6020502',
    name: '铝电解工',
    level: '4',
  },
  {
    id: '6020503',
    name: '镁冶炼工',
    level: '4',
  },
  {
    id: '6020504',
    name: '硅冶炼工',
    level: '4',
  },
  {
    id: '6020601',
    name: '钨钼冶炼工',
    level: '4',
  },
  {
    id: '6020602',
    name: '钽铌冶炼工',
    level: '4',
  },
  {
    id: '6020603',
    name: '钛冶炼工',
    level: '4',
  },
  {
    id: '6020604',
    name: '稀土冶炼工',
    level: '4',
  },
  {
    id: '6020605',
    name: '贵金属冶炼工',
    level: '4',
  },
  {
    id: '6020606',
    name: '锂冶炼工',
    level: '4',
  },
  {
    id: '6020701',
    name: '半导体原料制备工',
    level: '4',
  },
  {
    id: '6020702',
    name: '多晶制取工',
    level: '4',
  },
  {
    id: '6020801',
    name: '轧制原料工',
    level: '4',
  },
  {
    id: '6020802',
    name: '金属轧制工',
    level: '5',
  },
  {
    id: '6020803',
    name: '酸洗工',
    level: '5',
  },
  {
    id: '6020804',
    name: '金属材涂层工',
    level: '4',
  },
  {
    id: '6020805',
    name: '金属材热处理工',
    level: '5',
  },
  {
    id: '6020806',
    name: '焊管工',
    level: '6',
  },
  {
    id: '6020807',
    name: '精整工',
    level: '5',
  },
  {
    id: '6020808',
    name: '金属材丝拉拔工',
    level: '5',
  },
  {
    id: '6020809',
    name: '金属挤压工',
    level: '5',
  },
  {
    id: '6020810',
    name: '铸轧工',
    level: '5',
  },
  {
    id: '6020811',
    name: '钢丝绳制造工',
    level: '4',
  },
  {
    id: '6020901',
    name: '铸管备品工',
    level: '3',
  },
  {
    id: '6020902',
    name: '铸管工',
    level: '5',
  },
  {
    id: '6020903',
    name: '铸管精整工',
    level: '5',
  },
  {
    id: '6021001',
    name: '碳素煅烧工',
    level: '4',
  },
  {
    id: '6021002',
    name: '碳素成型工',
    level: '4',
  },
  {
    id: '6021003',
    name: '碳素焙烧工',
    level: '4',
  },
  {
    id: '6021004',
    name: '碳素浸渍工',
    level: '4',
  },
  {
    id: '6021005',
    name: '碳素石墨化工',
    level: '4',
  },
  {
    id: '6021006',
    name: '碳素石墨加工工',
    level: '4',
  },
  {
    id: '6021007',
    name: '碳素纤维工',
    level: '4',
  },
  {
    id: '6021101',
    name: '硬质合金混合料制备工',
    level: '4',
  },
  {
    id: '6021102',
    name: '硬质合金成型工',
    level: '5',
  },
  {
    id: '6021103',
    name: '硬质合金烧结工',
    level: '4',
  },
  {
    id: '6021104',
    name: '硬质合金精加工工',
    level: '4',
  },
  {
    id: '6029901',
    name: '冶炼风机工',
    level: '3',
  },
  {
    id: '6030301',
    name: '备煤筛焦工',
    level: '4',
  },
  {
    id: '6030302',
    name: '焦炉调温工',
    level: '4',
  },
  {
    id: '6030303',
    name: '炼焦工、焦炉机车司机',
    level: '6',
  },
  {
    id: '6030304',
    name: '煤制气工',
    level: '6',
  },
  {
    id: '6030305',
    name: '煤气储运工',
    level: '5',
  },
  {
    id: '6030401',
    name: '合成氨生产工',
    level: '4',
  },
  {
    id: '6030402',
    name: '尿素生产工',
    level: '4',
  },
  {
    id: '6030403',
    name: '硝酸铵生产工',
    level: '4',
  },
  {
    id: '6030404',
    name: '碳酸氢铵生产工',
    level: '4',
  },
  {
    id: '6030405',
    name: '硫酸铵生产工',
    level: '5',
  },
  {
    id: '6030406',
    name: '过磷酸钙生产工',
    level: '5',
  },
  {
    id: '6030407',
    name: '复合磷肥生产工',
    level: '4',
  },
  {
    id: '6030408',
    name: '钙镁磷肥生产工',
    level: '4',
  },
  {
    id: '6030409',
    name: '氯化钾生产工',
    level: '3',
  },
  {
    id: '6030410',
    name: '微量元素混肥生产工',
    level: '4',
  },
  {
    id: '6030501',
    name: '硫酸生产工',
    level: '7',
  },
  {
    id: '6030502',
    name: '硝酸生产工',
    level: '7',
  },
  {
    id: '6030503',
    name: '盐酸生产工',
    level: '7',
  },
  {
    id: '6030504',
    name: '磷酸生产工',
    level: '7',
  },
  {
    id: '6030505',
    name: '纯碱生产工',
    level: '7',
  },
  {
    id: '6030506',
    name: '烧碱生产工',
    level: '7',
  },
  {
    id: '6030507',
    name: '氟化盐生产工',
    level: '7',
  },
  {
    id: '6030508',
    name: '缩聚磷酸盐生产工',
    level: '7',
  },
  {
    id: '6030509',
    name: '无机化学反应工',
    level: '4',
  },
  {
    id: '6030510',
    name: '高频等离子工',
    level: '5',
  },
  {
    id: '6030511',
    name: '气体深冷分离工、制氧工',
    level: '5',
  },
  {
    id: '6030512',
    name: '工业气体液化工',
    level: '5',
  },
  {
    id: '6030513',
    name: '炭黑制造工',
    level: '4',
  },
  {
    id: '6030514',
    name: '二硫化碳制造工',
    level: '5',
  },
  {
    id: '6030515',
    name: '工程师',
    level: '2',
  },
  {
    id: '6030516',
    name: '技师',
    level: '2',
  },
  {
    id: '6030517',
    name: '一般工人',
    level: '3',
  },
  {
    id: '6030601',
    name: '脂肪烃生产工',
    level: '6',
  },
  {
    id: '6030602',
    name: '环烃生产工',
    level: '4',
  },
  {
    id: '6030603',
    name: '烃类衍生物生产工',
    level: '4',
  },
  {
    id: '6030701',
    name: '聚乙烯生产工',
    level: '5',
  },
  {
    id: '6030702',
    name: '聚丙烯生产工',
    level: '5',
  },
  {
    id: '6030703',
    name: '聚苯乙烯生产工',
    level: '5',
  },
  {
    id: '6030704',
    name: '聚丁二烯生产工',
    level: '5',
  },
  {
    id: '6030705',
    name: '聚氯乙烯生产工',
    level: '5',
  },
  {
    id: '6030706',
    name: '酚醛树脂生产工',
    level: '5',
  },
  {
    id: '6030707',
    name: '环氧树脂生产工',
    level: '5',
  },
  {
    id: '6030708',
    name: '丙烯腈-丁二烯-苯乙烯共聚物(ABS)',
    level: '5',
  },
  {
    id: '6030801',
    name: '顺丁橡胶生产工',
    level: '5',
  },
  {
    id: '6030802',
    name: '乙丙橡胶生产工',
    level: '5',
  },
  {
    id: '6030803',
    name: '异戊橡胶生产工',
    level: '5',
  },
  {
    id: '6030804',
    name: '丁腈橡胶生产工',
    level: '5',
  },
  {
    id: '6030805',
    name: '丁苯橡胶生产工',
    level: '5',
  },
  {
    id: '6030806',
    name: '氯丁橡胶生产工',
    level: '5',
  },
  {
    id: '6030201',
    name: '燃料油生产工',
    level: '3',
  },
  {
    id: '6030202',
    name: '润滑油、脂生产工',
    level: '3',
  },
  {
    id: '6030203',
    name: '石油产品精制工',
    level: '3',
  },
  {
    id: '6030204',
    name: '油制气工',
    level: '6',
  },
  {
    id: '6030901',
    name: '化纤聚合工',
    level: '5',
  },
  {
    id: '6030902',
    name: '湿纺原液制造工',
    level: '4',
  },
  {
    id: '6030903',
    name: '纺丝工',
    level: '4',
  },
  {
    id: '6030904',
    name: '化纤后处理工',
    level: '4',
  },
  {
    id: '6030905',
    name: '纺丝凝固浴液配制工',
    level: '3',
  },
  {
    id: '6030906',
    name: '无纺布制造工',
    level: '3',
  },
  {
    id: '6030907',
    name: '化纤纺丝精密组件工',
    level: '3',
  },
  {
    id: '6031201',
    name: '片基制造工',
    level: '4',
  },
  {
    id: '6031202',
    name: '感光材料制造工',
    level: '4',
  },
  {
    id: '6031203',
    name: '感光材料试验工',
    level: '2',
  },
  {
    id: '6031204',
    name: '暗盒制造工',
    level: '4',
  },
  {
    id: '6031205',
    name: '废片、白银回收工',
    level: '4',
  },
  {
    id: '6031206',
    name: '磁粉制造工',
    level: '3',
  },
  {
    id: '6031207',
    name: '磁记录材料制造工',
    level: '3',
  },
  {
    id: '6031208',
    name: '磁记录材料试验工',
    level: '3',
  },
  {
    id: '6031209',
    name: '感光鼓涂敷工',
    level: '3',
  },
  {
    id: '6031101',
    name: '有机合成工',
    level: '3',
  },
  {
    id: '6031102',
    name: '农药生物测试试验工',
    level: '3',
  },
  {
    id: '6031103',
    name: '染料标准工',
    level: '2',
  },
  {
    id: '6031104',
    name: '染料应用试验工',
    level: '2',
  },
  {
    id: '6031105',
    name: '染料拼混工',
    level: '3',
  },
  {
    id: '6031106',
    name: '研磨分散工',
    level: '3',
  },
  {
    id: '6031107',
    name: '催化剂制造工',
    level: '3',
  },
  {
    id: '6031108',
    name: '催化剂试验工',
    level: '2',
  },
  {
    id: '6031109',
    name: '涂料合成树脂工',
    level: '4',
  },
  {
    id: '6031110',
    name: '制漆配色调制工',
    level: '4',
  },
  {
    id: '6031111',
    name: '溶剂制造工',
    level: '3',
  },
  {
    id: '6031112',
    name: '化学试剂制造工',
    level: '3',
  },
  {
    id: '6031113',
    name: '化工添加剂制造工',
    level: '3',
  },
  {
    id: '6031301',
    name: '单基火药制造工',
    level: '7',
  },
  {
    id: '6031302',
    name: '双基火药制造工',
    level: '7',
  },
  {
    id: '6031303',
    name: '多基火药制造工',
    level: '7',
  },
  {
    id: '6031304',
    name: '黑火药制造工',
    level: '7',
  },
  {
    id: '6031305',
    name: '混合火药制造工',
    level: '7',
  },
  {
    id: '6031401',
    name: '单质炸药制造工',
    level: '7',
  },
  {
    id: '6031402',
    name: '混合炸药制造工',
    level: '7',
  },
  {
    id: '6031403',
    name: '起爆药制造工',
    level: '7',
  },
  {
    id: '6031404',
    name: '含水炸药制造工',
    level: '7',
  },
  {
    id: '6031405',
    name: '火药爆竹制造及处理人(包括爆竹、烟火制造工)',
    level: '7',
  },
  {
    id: '6031501',
    name: '松香工',
    level: '3',
  },
  {
    id: '6031502',
    name: '松节油制品工',
    level: '3',
  },
  {
    id: '6031503',
    name: '活性炭生产工',
    level: '3',
  },
  {
    id: '6031504',
    name: '栲胶生产工',
    level: '3',
  },
  {
    id: '6031505',
    name: '紫胶生产工',
    level: '4',
  },
  {
    id: '6031506',
    name: '栓皮制品工',
    level: '4',
  },
  {
    id: '6031507',
    name: '木材水解工',
    level: '4',
  },
  {
    id: '6031601',
    name: '树脂基复合材料工',
    level: '4',
  },
  {
    id: '6031602',
    name: '橡胶基复合材料工',
    level: '4',
  },
  {
    id: '6031603',
    name: '碳基复合材料工',
    level: '4',
  },
  {
    id: '6031604',
    name: '陶瓷基复合材料工',
    level: '4',
  },
  {
    id: '6031605',
    name: '复合固体推进剂成型工',
    level: '4',
  },
  {
    id: '6031606',
    name: '复合固体发动机装药工',
    level: '4',
  },
  {
    id: '6031607',
    name: '飞机复合材料制品工',
    level: '4',
  },
  {
    id: '6031701',
    name: '制皂工',
    level: '4',
  },
  {
    id: '6031702',
    name: '甘油工',
    level: '4',
  },
  {
    id: '6031703',
    name: '脂肪酸工',
    level: '4',
  },
  {
    id: '6031704',
    name: '洗衣粉成型工',
    level: '4',
  },
  {
    id: '6031705',
    name: '合成洗涤剂制造工',
    level: '4',
  },
  {
    id: '6031706',
    name: '香料制造工',
    level: '4',
  },
  {
    id: '6031707',
    name: '香精制造工',
    level: '4',
  },
  {
    id: '6031708',
    name: '化妆品配制工',
    level: '4',
  },
  {
    id: '6031709',
    name: '牙膏制造工',
    level: '4',
  },
  {
    id: '6031710',
    name: '油墨制造工',
    level: '5',
  },
  {
    id: '6031711',
    name: '制胶工',
    level: '4',
  },
  {
    id: '6031712',
    name: '火柴制造工',
    level: '6',
  },
  {
    id: '6031713',
    name: '蜡烛制造工',
    level: '4',
  },
  {
    id: '6039901',
    name: '电子绝缘与介质材料制造工',
    level: '4',
  },
  {
    id: '6031001',
    name: '合成革制造工',
    level: '3',
  },
  {
    id: '6030101',
    name: '化工原料准备工',
    level: '4',
  },
  {
    id: '6030102',
    name: '压缩机工',
    level: '3',
  },
  {
    id: '6030103',
    name: '气体净化工',
    level: '3',
  },
  {
    id: '6030104',
    name: '过滤工',
    level: '3',
  },
  {
    id: '6030105',
    name: '油加热工',
    level: '4',
  },
  {
    id: '6030106',
    name: '制冷工',
    level: '3',
  },
  {
    id: '6030107',
    name: '蒸发工',
    level: '3',
  },
  {
    id: '6030108',
    name: '蒸馏工',
    level: '3',
  },
  {
    id: '6030109',
    name: '萃取工',
    level: '3',
  },
  {
    id: '6030110',
    name: '吸收工',
    level: '3',
  },
  {
    id: '6030111',
    name: '吸附工',
    level: '3',
  },
  {
    id: '6030112',
    name: '干燥工',
    level: '3',
  },
  {
    id: '6030113',
    name: '结晶工',
    level: '3',
  },
  {
    id: '6030114',
    name: '造粒工',
    level: '3',
  },
  {
    id: '6030115',
    name: '防腐蚀工',
    level: '5',
  },
  {
    id: '6030116',
    name: '化工工艺试验工',
    level: '3',
  },
  {
    id: '6030117',
    name: '化工总控工',
    level: '1',
  },
  {
    id: '6040101',
    name: '车工',
    level: '5',
  },
  {
    id: '6040102',
    name: '铣工',
    level: '5',
  },
  {
    id: '6040103',
    name: '刨工',
    level: '5',
  },
  {
    id: '6040104',
    name: '磨工',
    level: '5',
  },
  {
    id: '6040105',
    name: '镗工',
    level: '5',
  },
  {
    id: '6040106',
    name: '钻床工',
    level: '5',
  },
  {
    id: '6040107',
    name: '组合机床操作工',
    level: '5',
  },
  {
    id: '6040108',
    name: '加工中心操作工',
    level: '5',
  },
  {
    id: '6040109',
    name: '制齿工',
    level: '5',
  },
  {
    id: '6040110',
    name: '螺丝纹挤形工',
    level: '5',
  },
  {
    id: '6040111',
    name: '抛磨光工',
    level: '4',
  },
  {
    id: '6040112',
    name: '拉床工',
    level: '5',
  },
  {
    id: '6040113',
    name: '锯床工',
    level: '5',
  },
  {
    id: '6040114',
    name: '刃具扭制工',
    level: '5',
  },
  {
    id: '6040115',
    name: '弹性元件制造工',
    level: '5',
  },
  {
    id: '6040116',
    name: '技师',
    level: '4',
  },
  {
    id: '6040117',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6040118',
    name: '技工',
    level: '4',
  },
  {
    id: '6040119',
    name: '车床工(全自动)',
    level: '4',
  },
  {
    id: '6040201',
    name: '铸造工',
    level: '5',
  },
  {
    id: '6040202',
    name: '锻造工',
    level: '5',
  },
  {
    id: '6040203',
    name: '冲压工',
    level: '5',
  },
  {
    id: '6040204',
    name: '剪切工',
    level: '5',
  },
  {
    id: '6040205',
    name: '焊工',
    level: '5',
  },
  {
    id: '6040206',
    name: '金属热处理工',
    level: '5',
  },
  {
    id: '6040207',
    name: '粉末冶金制造工',
    level: '5',
  },
  {
    id: '6040401',
    name: '冷作钣金工',
    level: '4',
  },
  {
    id: '6040501',
    name: '镀层工',
    level: '4',
  },
  {
    id: '6040502',
    name: '涂装工',
    level: '5',
  },
  {
    id: '6040601',
    name: '磨料制造工',
    level: '4',
  },
  {
    id: '6040602',
    name: '磨具制造工',
    level: '4',
  },
  {
    id: '6049901',
    name: '电焊条制造工',
    level: '4',
  },
  {
    id: '6049902',
    name: '仪器仪表元件制造工',
    level: '4',
  },
  {
    id: '6049903',
    name: '真空干燥处理工',
    level: '3',
  },
  {
    id: '6049904',
    name: '人造宝石制造工',
    level: '3',
  },
  {
    id: '6049905',
    name: '装配工、品管人员',
    level: '4',
  },
  {
    id: '6040701',
    name: '金属软管、波纹管工',
    level: '4',
  },
  {
    id: '6040702',
    name: '卫星光学冷加工工',
    level: '4',
  },
  {
    id: '6040703',
    name: '航天器件高温处理工',
    level: '4',
  },
  {
    id: '6040301',
    name: '电切削工',
    level: '5',
  },
  {
    id: '6050101',
    name: '基础件装配工',
    level: '4',
  },
  {
    id: '6050102',
    name: '部件装配工',
    level: '4',
  },
  {
    id: '6050401',
    name: '铁心叠装工',
    level: '5',
  },
  {
    id: '6050402',
    name: '绝缘制品件装配工',
    level: '4',
  },
  {
    id: '6050403',
    name: '线圈绕制工',
    level: '4',
  },
  {
    id: '6050404',
    name: '绝缘处理浸渍工',
    level: '3',
  },
  {
    id: '6050405',
    name: '变压器、互感器装配工',
    level: '4',
  },
  {
    id: '6050406',
    name: '高低压电器装配工',
    level: '4',
  },
  {
    id: '6050407',
    name: '电焊机装配工',
    level: '4',
  },
  {
    id: '6050408',
    name: '电炉装配工',
    level: '4',
  },
  {
    id: '6050409',
    name: '电线电缆制造工',
    level: '4',
  },
  {
    id: '6050410',
    name: '技师',
    level: '3',
  },
  {
    id: '6050501',
    name: '电子专用设备装调工',
    level: '3',
  },
  {
    id: '6050502',
    name: '真空测试工',
    level: '2',
  },
  {
    id: '6050701',
    name: '汽车(拖拉机)装配工',
    level: '4',
  },
  {
    id: '6050702',
    name: '铁路机车机械制修工',
    level: '5',
  },
  {
    id: '6050703',
    name: '铁路车辆机械制修工',
    level: '5',
  },
  {
    id: '6050704',
    name: '铁路机车电气装修工',
    level: '5',
  },
  {
    id: '6050705',
    name: '铁路车辆电气装修工',
    level: '5',
  },
  {
    id: '6050706',
    name: '铁路机车车辆制动修造工',
    level: '4',
  },
  {
    id: '6050707',
    name: '电机车装配工',
    level: '4',
  },
  {
    id: '6050708',
    name: '摩托车装配工',
    level: '4',
  },
  {
    id: '6050709',
    name: '助动车、自行车装配工',
    level: '3',
  },
  {
    id: '6050710',
    name: '工程师',
    level: '2',
  },
  {
    id: '6050711',
    name: '技师',
    level: '2',
  },
  {
    id: '6050712',
    name: '领班、监工',
    level: '2',
  },
  {
    id: '6050713',
    name: '试车人员',
    level: '4',
  },
  {
    id: '6050714',
    name: '汽车模型工',
    level: '2',
  },
  {
    id: '6050715',
    name: '城轨接触网检修工',
    level: '5',
  },
  {
    id: '6050601',
    name: '仪器仪表元器件装调工',
    level: '4',
  },
  {
    id: '6050602',
    name: '力学仪器仪表装配工',
    level: '3',
  },
  {
    id: '6050603',
    name: '电子仪器仪表装配工',
    level: '3',
  },
  {
    id: '6050604',
    name: '光电仪器仪表装调工',
    level: '4',
  },
  {
    id: '6050605',
    name: '分析仪器仪表装配工',
    level: '3',
  },
  {
    id: '6050606',
    name: '计时仪器仪表装配工',
    level: '3',
  },
  {
    id: '6050607',
    name: '工业自动化仪器仪表与装置装配工',
    level: '3',
  },
  {
    id: '6050608',
    name: '电工仪器仪表装配工',
    level: '3',
  },
  {
    id: '6050801',
    name: '功能膜工',
    level: '4',
  },
  {
    id: '6050802',
    name: '电渗析器工',
    level: '4',
  },
  {
    id: '6050901',
    name: '医疗器械装配工',
    level: '3',
  },
  {
    id: '6050902',
    name: '假肢制作装配工',
    level: '2',
  },
  {
    id: '6050903',
    name: '矫形器制作装配工',
    level: '2',
  },
  {
    id: '6051001',
    name: '空调机装配工',
    level: '4',
  },
  {
    id: '6051002',
    name: '电冰箱、电冰柜制造装配工',
    level: '4',
  },
  {
    id: '6051003',
    name: '洗衣机制造装配工',
    level: '4',
  },
  {
    id: '6051004',
    name: '小型家用电器装配工',
    level: '3',
  },
  {
    id: '6051005',
    name: '缝纫机制造装配工',
    level: '4',
  },
  {
    id: '6051006',
    name: '办公小机械制造装配工',
    level: '3',
  },
  {
    id: '6051007',
    name: '技师',
    level: '2',
  },
  {
    id: '6051008',
    name: '一般制造工人',
    level: '4',
  },
  {
    id: '6051009',
    name: '包装工',
    level: '3',
  },
  {
    id: '6051010',
    name: '瓦斯器具制造工',
    level: '4',
  },
  {
    id: '6051101',
    name: '工具五金制作工',
    level: '5',
  },
  {
    id: '6051102',
    name: '建筑五金制品制作工',
    level: '5',
  },
  {
    id: '6051103',
    name: '制锁工',
    level: '5',
  },
  {
    id: '6051104',
    name: '铝制品制作工',
    level: '5',
  },
  {
    id: '6051105',
    name: '日用五金制品制作工',
    level: '4',
  },
  {
    id: '6051106',
    name: '金属家具制造工人',
    level: '4',
  },
  {
    id: '6051107',
    name: '金属家具修理工人',
    level: '3',
  },
  {
    id: '6051201',
    name: '装甲车辆装配工',
    level: '4',
  },
  {
    id: '6051202',
    name: '装甲车辆装配检验工',
    level: '4',
  },
  {
    id: '6051203',
    name: '装甲车辆驾驶试验工',
    level: '4',
  },
  {
    id: '6051204',
    name: '装甲车辆发动机装试工',
    level: '3',
  },
  {
    id: '6051301',
    name: '火炮装试工',
    level: '3',
  },
  {
    id: '6051302',
    name: '火炮装试检验工',
    level: '3',
  },
  {
    id: '6051303',
    name: '火炮随动系统装试工',
    level: '3',
  },
  {
    id: '6051304',
    name: '火炮随动系统装试检验工',
    level: '3',
  },
  {
    id: '6051305',
    name: '火炮膛线制作工',
    level: '4',
  },
  {
    id: '6051306',
    name: '枪支装配工',
    level: '5',
  },
  {
    id: '6051307',
    name: '枪管校直工',
    level: '4',
  },
  {
    id: '6051308',
    name: '枪管膛线制作工',
    level: '4',
  },
  {
    id: '6051401',
    name: '炮弹装配工',
    level: '4',
  },
  {
    id: '6051402',
    name: '枪弹装配工',
    level: '4',
  },
  {
    id: '6051403',
    name: '火工装药工',
    level: '7',
  },
  {
    id: '6051501',
    name: '引信装试工',
    level: '3',
  },
  {
    id: '6051701',
    name: '滤毒材料制造工',
    level: '3',
  },
  {
    id: '6051702',
    name: '防毒器材装配工',
    level: '3',
  },
  {
    id: '6051703',
    name: '防毒器材试验工',
    level: '4',
  },
  {
    id: '6051601',
    name: '雷管制造工',
    level: '7',
  },
  {
    id: '6051602',
    name: '索状爆破器材制造工',
    level: '7',
  },
  {
    id: '6051603',
    name: '火工品装配工',
    level: '7',
  },
  {
    id: '6051604',
    name: '爆破器材试验工',
    level: '7',
  },
  {
    id: '6051801',
    name: '船体制造工',
    level: '5',
  },
  {
    id: '6051802',
    name: '船舶轮机装配工',
    level: '4',
  },
  {
    id: '6051803',
    name: '船舶电气装配工',
    level: '5',
  },
  {
    id: '6051804',
    name: '船舶附件制造工',
    level: '5',
  },
  {
    id: '6051805',
    name: '船舶木塑帆缆工',
    level: '4',
  },
  {
    id: '6051806',
    name: '船模制造试验工',
    level: '3',
  },
  {
    id: '6051807',
    name: '工程师',
    level: '3',
  },
  {
    id: '6051808',
    name: '领班',
    level: '4',
  },
  {
    id: '6051809',
    name: '拆船工人',
    level: '6',
  },
  {
    id: '6051901',
    name: '飞机装配工',
    level: '4',
  },
  {
    id: '6051902',
    name: '飞机系统安装调试工',
    level: '4',
  },
  {
    id: '6051903',
    name: '机载导弹装配工',
    level: '4',
  },
  {
    id: '6051904',
    name: '航空发动机装配工',
    level: '3',
  },
  {
    id: '6051905',
    name: '飞机螺旋桨装配工',
    level: '4',
  },
  {
    id: '6051906',
    name: '飞机军械安装调试工',
    level: '4',
  },
  {
    id: '6051907',
    name: '航空电气安装调试工',
    level: '4',
  },
  {
    id: '6051908',
    name: '飞机发动机附件装配工',
    level: '4',
  },
  {
    id: '6051909',
    name: '航空仪表装配工',
    level: '3',
  },
  {
    id: '6051910',
    name: '飞机仪表安装试验工',
    level: '3',
  },
  {
    id: '6051911',
    name: '航空装配平衡工',
    level: '3',
  },
  {
    id: '6051912',
    name: '飞机无线电设备安装调试工',
    level: '3',
  },
  {
    id: '6051913',
    name: '飞机雷达安装调试工',
    level: '4',
  },
  {
    id: '6051914',
    name: '飞机特种设备检测与修理工',
    level: '3',
  },
  {
    id: '6051915',
    name: '飞机透明件制造胶接装配工',
    level: '3',
  },
  {
    id: '6051916',
    name: '飞机外场调试与维护工',
    level: '4',
  },
  {
    id: '6052001',
    name: '飞机试验工',
    level: '2',
  },
  {
    id: '6052002',
    name: '机载导弹例行试验工',
    level: '4',
  },
  {
    id: '6052003',
    name: '航空发动机试车工',
    level: '2',
  },
  {
    id: '6052004',
    name: '飞机螺旋桨试验工',
    level: '2',
  },
  {
    id: '6052005',
    name: '飞机、发动机附件试验工',
    level: '2',
  },
  {
    id: '6052006',
    name: '航空环控救生装备试验工(地面试验)',
    level: '2',
  },
  {
    id: '6052007',
    name: '航空环控救生装备试验工(空中试验)',
    level: '7',
  },
  {
    id: '6052008',
    name: '航空仪表试验工',
    level: '2',
  },
  {
    id: '6052009',
    name: '航空电机电器试验设备调试工',
    level: '2',
  },
  {
    id: '6052101',
    name: '惯性器件装配厂',
    level: '4',
  },
  {
    id: '6052102',
    name: '伺服机构装配调试工',
    level: '4',
  },
  {
    id: '6052103',
    name: '导弹部段装配工',
    level: '5',
  },
  {
    id: '6052104',
    name: '航天器引信装配工',
    level: '6',
  },
  {
    id: '6052105',
    name: '弹头装配工',
    level: '5',
  },
  {
    id: '6052106',
    name: '导弹总体装配工',
    level: '5',
  },
  {
    id: '6052107',
    name: '卫星总体装配工',
    level: '4',
  },
  {
    id: '6052201',
    name: '液体火箭发动机装配试验工',
    level: '4',
  },
  {
    id: '6052202',
    name: '固体火箭发动机装配工',
    level: '4',
  },
  {
    id: '6052203',
    name: '固体火箭发动机试验工',
    level: '4',
  },
  {
    id: '6052204',
    name: '固体火箭发动机检测工',
    level: '4',
  },
  {
    id: '6052301',
    name: '航天器环境试验工',
    level: '3',
  },
  {
    id: '6052302',
    name: '航天器结构强度环境试验工',
    level: '3',
  },
  {
    id: '6052303',
    name: '航天器结构高低温环境试验工',
    level: '3',
  },
  {
    id: '6052304',
    name: '火箭发动机介质试验工',
    level: '3',
  },
  {
    id: '6052305',
    name: '航天器系统试验工',
    level: '3',
  },
  {
    id: '6052306',
    name: '空间环境模拟光学装测工',
    level: '2',
  },
  {
    id: '6052307',
    name: '空间环境模拟温度试验工',
    level: '2',
  },
  {
    id: '6052308',
    name: '空间环境模拟真空试验工',
    level: '2',
  },
  {
    id: '6052401',
    name: '靶场试射工',
    level: '6',
  },
  {
    id: '6052402',
    name: '靶场测试工',
    level: '2',
  },
  {
    id: '6059901',
    name: '技师',
    level: '3',
  },
  {
    id: '6059902',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6050201',
    name: '装配钳工',
    level: '4',
  },
  {
    id: '6050202',
    name: '工具钳工',
    level: '5',
  },
  {
    id: '6050301',
    name: '汽轮机装配工',
    level: '4',
  },
  {
    id: '6050302',
    name: '内燃机装配工',
    level: '4',
  },
  {
    id: '6050303',
    name: '锅炉设备装配厂',
    level: '5',
  },
  {
    id: '6050304',
    name: '电机装配工',
    level: '4',
  },
  {
    id: '6050305',
    name: '有关高压电之工作人员',
    level: '6',
  },
  {
    id: '6050306',
    name: '数控机床装调维修工',
    level: '4',
  },
  {
    id: '6060101',
    name: '机修钳工',
    level: '4',
  },
  {
    id: '6060102',
    name: '汽车修理工',
    level: '4',
  },
  {
    id: '6060103',
    name: '船舶修理工',
    level: '5',
  },
  {
    id: '6060104',
    name: '修理保养工人(自行车)',
    level: '3',
  },
  {
    id: '6060105',
    name: '装配修理工、冷冻修理厂工人',
    level: '4',
  },
  {
    id: '6060106',
    name: '汽车玻璃维修工',
    level: '4',
  },
  {
    id: '6060107',
    name: '工程机械修理工',
    level: '4',
  },
  {
    id: '6060201',
    name: '工业自动化仪器仪表与装置修理工',
    level: '3',
  },
  {
    id: '6060202',
    name: '电工仪器仪表修理工',
    level: '4',
  },
  {
    id: '6060203',
    name: '精密仪器仪表修理工',
    level: '3',
  },
  {
    id: '6060301',
    name: '民用航空器维护人员',
    level: '4',
  },
  {
    id: '6060302',
    name: '民用航空器修理人员',
    level: '4',
  },
  {
    id: '6060303',
    name: '机械员',
    level: '4',
  },
  {
    id: '6070101',
    name: '水轮机设备安装工',
    level: '4',
  },
  {
    id: '6070102',
    name: '锅炉设备安装工',
    level: '4',
  },
  {
    id: '6070103',
    name: '汽轮机设备安装工',
    level: '4',
  },
  {
    id: '6070104',
    name: '发电机设备安装工',
    level: '4',
  },
  {
    id: '6070105',
    name: '热工仪表及控制装置安装试验工',
    level: '4',
  },
  {
    id: '6070106',
    name: '发电厂电气设备安装工',
    level: '4',
  },
  {
    id: '6070107',
    name: '电力电缆安装工',
    level: '5',
  },
  {
    id: '6070108',
    name: '高压线路架设工',
    level: '7',
  },
  {
    id: '6070109',
    name: '电力工程内线安装工',
    level: '4',
  },
  {
    id: '6070110',
    name: '小风电利用工',
    level: '5',
  },
  {
    id: '6070111',
    name: '微水电利用工',
    level: '5',
  },
  {
    id: '6070201',
    name: '水轮发电机值班员',
    level: '3',
  },
  {
    id: '6070202',
    name: '燃料值班员',
    level: '2',
  },
  {
    id: '6070203',
    name: '锅炉运行值班员',
    level: '4',
  },
  {
    id: '6070204',
    name: '锅炉辅机值班员',
    level: '4',
  },
  {
    id: '6070205',
    name: '汽轮机运行值班员',
    level: '3',
  },
  {
    id: '6070206',
    name: '热力网值班员',
    level: '3',
  },
  {
    id: '6070207',
    name: '电气值班员',
    level: '3',
  },
  {
    id: '6070208',
    name: '集控值班员',
    level: '3',
  },
  {
    id: '6070209',
    name: '发电机氢冷值班员',
    level: '3',
  },
  {
    id: '6070210',
    name: '电厂水处理值班员',
    level: '3',
  },
  {
    id: '6070301',
    name: '送电、配电线路工',
    level: '5',
  },
  {
    id: '6070302',
    name: '变电站值班员',
    level: '5',
  },
  {
    id: '6070303',
    name: '调相机值班员',
    level: '4',
  },
  {
    id: '6070304',
    name: '换流站值班员',
    level: '4',
  },
  {
    id: '6070401',
    name: '锅炉本体设备检修工',
    level: '5',
  },
  {
    id: '6070402',
    name: '锅炉附属设备检修工',
    level: '5',
  },
  {
    id: '6070403',
    name: '汽轮机本体设备检修工',
    level: '4',
  },
  {
    id: '6070404',
    name: '汽轮机附属设备检修工',
    level: '4',
  },
  {
    id: '6070405',
    name: '发电厂电动机检修工',
    level: '4',
  },
  {
    id: '6070406',
    name: '水轮机检修工',
    level: '4',
  },
  {
    id: '6070407',
    name: '水电站水力机械试验工',
    level: '3',
  },
  {
    id: '6070408',
    name: '水电自动装置检修工',
    level: '4',
  },
  {
    id: '6070409',
    name: '高压线路带电检修工',
    level: '7',
  },
  {
    id: '6070410',
    name: '变压器检修工',
    level: '5',
  },
  {
    id: '6070411',
    name: '变电设备检修工',
    level: '5',
  },
  {
    id: '6070412',
    name: '电气试验员',
    level: '4',
  },
  {
    id: '6070413',
    name: '继电保护员',
    level: '4',
  },
  {
    id: '6070414',
    name: '电力装置维护修理工',
    level: '4',
  },
  {
    id: '6070501',
    name: '电力负荷控制员',
    level: '3',
  },
  {
    id: '6070502',
    name: '用电监察员',
    level: '3',
  },
  {
    id: '6070503',
    name: '装表核算收费员',
    level: '2',
  },
  {
    id: '6070504',
    name: '装表接电工',
    level: '4',
  },
  {
    id: '6070505',
    name: '电能计量装置检修工',
    level: '3',
  },
  {
    id: '6070601',
    name: '变电设备安装工',
    level: '4',
  },
  {
    id: '6070602',
    name: '变配电室值班电工',
    level: '4',
  },
  {
    id: '6070603',
    name: '常用电机检修工',
    level: '4',
  },
  {
    id: '6070604',
    name: '牵引电力线路安装维护工',
    level: '6',
  },
  {
    id: '6070605',
    name: '维修电工',
    level: '5',
  },
  {
    id: '6080101',
    name: '真空电子器件化学零件制造工',
    level: '4',
  },
  {
    id: '6080102',
    name: '电极丝制造工',
    level: '3',
  },
  {
    id: '6080103',
    name: '真空电子器件金属零件制造工',
    level: '4',
  },
  {
    id: '6080104',
    name: '电子真空镀膜工',
    level: '4',
  },
  {
    id: '6080105',
    name: '真空电子器件装配工',
    level: '4',
  },
  {
    id: '6080106',
    name: '真空电子器件装调工',
    level: '3',
  },
  {
    id: '6080107',
    name: '液晶显示器件制造工',
    level: '3',
  },
  {
    id: '6080108',
    name: '单晶片加工工',
    level: '3',
  },
  {
    id: '6080109',
    name: '半导体芯片制造工',
    level: '3',
  },
  {
    id: '6080110',
    name: '半导体分立器件、集成电路装调工',
    level: '2',
  },
  {
    id: '6080111',
    name: '电子用水制备工',
    level: '2',
  },
  {
    id: '6080112',
    name: '技师',
    level: '2',
  },
  {
    id: '6080113',
    name: '领班、监工',
    level: '2',
  },
  {
    id: '6080114',
    name: '制造工',
    level: '4',
  },
  {
    id: '6080201',
    name: '电阻器制造工',
    level: '4',
  },
  {
    id: '6080202',
    name: '电容器制造工',
    level: '4',
  },
  {
    id: '6080203',
    name: '微波铁氧体元器件制造工',
    level: '4',
  },
  {
    id: '6080204',
    name: '石英晶体生长设备操作工',
    level: '2',
  },
  {
    id: '6080205',
    name: '压电石英晶片加工工',
    level: '3',
  },
  {
    id: '6080206',
    name: '石英晶体元器件制造工',
    level: '3',
  },
  {
    id: '6080207',
    name: '电声器件制造工',
    level: '3',
  },
  {
    id: '6080208',
    name: '水声换能器制造工',
    level: '3',
  },
  {
    id: '6080209',
    name: '专用继电器制造工',
    level: '3',
  },
  {
    id: '6080210',
    name: '高频电感器件制造工',
    level: '2',
  },
  {
    id: '6080211',
    name: '接插件制造工',
    level: '4',
  },
  {
    id: '6080212',
    name: '磁头制造工',
    level: '2',
  },
  {
    id: '6080213',
    name: '电子产品制版工',
    level: '2',
  },
  {
    id: '6080214',
    name: '印制电路制作工',
    level: '3',
  },
  {
    id: '6080215',
    name: '薄膜加热器制造工',
    level: '3',
  },
  {
    id: '6080216',
    name: '激光头制造工',
    level: '2',
  },
  {
    id: '6080301',
    name: '铅酸蓄电池制造工',
    level: '4',
  },
  {
    id: '6080302',
    name: '碱性蓄电池制造工',
    level: '4',
  },
  {
    id: '6080303',
    name: '原电池制造工',
    level: '4',
  },
  {
    id: '6080304',
    name: '热电池制造工',
    level: '4',
  },
  {
    id: '6080305',
    name: '太阳电池制造工',
    level: '4',
  },
  {
    id: '6080306',
    name: '电池制造(技师)',
    level: '3',
  },
  {
    id: '6080307',
    name: '电池制造(工人)',
    level: '4',
  },
  {
    id: '6080401',
    name: '无线电设备机械装校工',
    level: '4',
  },
  {
    id: '6080402',
    name: '电子设备装接工',
    level: '4',
  },
  {
    id: '6080403',
    name: '无线电调试工',
    level: '2',
  },
  {
    id: '6080404',
    name: '雷达装配工',
    level: '4',
  },
  {
    id: '6080405',
    name: '雷达调试工',
    level: '2',
  },
  {
    id: '6080406',
    name: '电子精密机械装调工',
    level: '2',
  },
  {
    id: '6080407',
    name: '电子计算机(微机)调试工',
    level: '1',
  },
  {
    id: '6080408',
    name: '有线通信传输设备调试工',
    level: '2',
  },
  {
    id: '6080409',
    name: '通讯交换设备调试工',
    level: '2',
  },
  {
    id: '6080410',
    name: '电源调试工',
    level: '2',
  },
  {
    id: '6080411',
    name: '激光机装调工',
    level: '2',
  },
  {
    id: '6080412',
    name: '激光全息工',
    level: '1',
  },
  {
    id: '6080413',
    name: '铁路通信组调工',
    level: '3',
  },
  {
    id: '6080414',
    name: '铁路信号组调工',
    level: '3',
  },
  {
    id: '6080415',
    name: '铁路电控组调工',
    level: '4',
  },
  {
    id: '6080416',
    name: '包装工人',
    level: '4',
  },
  {
    id: '6080417',
    name: '集成电路测试员',
    level: '2',
  },
  {
    id: '6080501',
    name: '电子计算机(微机)维修工',
    level: '1',
  },
  {
    id: '6080502',
    name: '修理工',
    level: '3',
  },
  {
    id: '6080503',
    name: '家电用品维修人员',
    level: '3',
  },
  {
    id: '6090101',
    name: '橡胶制品配料工',
    level: '2',
  },
  {
    id: '6090102',
    name: '橡胶炼胶工',
    level: '4',
  },
  {
    id: '6090103',
    name: '橡胶半成品制造工',
    level: '4',
  },
  {
    id: '6090104',
    name: '橡胶成型工',
    level: '4',
  },
  {
    id: '6090105',
    name: '橡胶硫化工',
    level: '4',
  },
  {
    id: '6090106',
    name: '废胶再生工',
    level: '4',
  },
  {
    id: '6090107',
    name: '轮胎翻修工',
    level: '4',
  },
  {
    id: '6090201',
    name: '塑料制品配料工',
    level: '3',
  },
  {
    id: '6090202',
    name: '塑料制品成型制作工(自动)',
    level: '3',
  },
  {
    id: '6090203',
    name: '塑胶射出成型人员(其它)',
    level: '4',
  },
  {
    id: '6099901',
    name: '工程师',
    level: '2',
  },
  {
    id: '6099902',
    name: '技师',
    level: '2',
  },
  {
    id: '6099903',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6099904',
    name: '一般工人',
    level: '3',
  },
  {
    id: '6100101',
    name: '纤维验配工',
    level: '1',
  },
  {
    id: '6100102',
    name: '开清棉工',
    level: '2',
  },
  {
    id: '6100103',
    name: '纤维染色工',
    level: '2',
  },
  {
    id: '6100104',
    name: '加湿软麻工',
    level: '2',
  },
  {
    id: '6100105',
    name: '选剥煮茧工',
    level: '2',
  },
  {
    id: '6100106',
    name: '纤维梳理工',
    level: '2',
  },
  {
    id: '6100107',
    name: '并条工',
    level: '2',
  },
  {
    id: '6100108',
    name: '粗纱工',
    level: '2',
  },
  {
    id: '6100109',
    name: '绢纺精炼工',
    level: '3',
  },
  {
    id: '6100301',
    name: '整经工',
    level: '2',
  },
  {
    id: '6100302',
    name: '浆纱工',
    level: '2',
  },
  {
    id: '6100303',
    name: '穿经工',
    level: '2',
  },
  {
    id: '6100304',
    name: '织布工',
    level: '2',
  },
  {
    id: '6100305',
    name: '织物验修工',
    level: '2',
  },
  {
    id: '6100306',
    name: '意匠纹版工',
    level: '1',
  },
  {
    id: '6100307',
    name: '织造工人',
    level: '2',
  },
  {
    id: '6100201',
    name: '细纱工',
    level: '2',
  },
  {
    id: '6100202',
    name: '简并摇工',
    level: '2',
  },
  {
    id: '6100203',
    name: '捻线工',
    level: '2',
  },
  {
    id: '6100204',
    name: '制线工',
    level: '2',
  },
  {
    id: '6100205',
    name: '缫丝工',
    level: '2',
  },
  {
    id: '6100401',
    name: '纬编工',
    level: '2',
  },
  {
    id: '6100402',
    name: '经编工',
    level: '2',
  },
  {
    id: '6100403',
    name: '横机工',
    level: '2',
  },
  {
    id: '6100404',
    name: '织袜工',
    level: '2',
  },
  {
    id: '6100405',
    name: '铸、钳针工',
    level: '2',
  },
  {
    id: '6100501',
    name: '坯布检查处理工',
    level: '2',
  },
  {
    id: '6100502',
    name: '印染烧毛工',
    level: '3',
  },
  {
    id: '6100503',
    name: '煮炼漂工',
    level: '4',
  },
  {
    id: '6100504',
    name: '印染洗涤工',
    level: '3',
  },
  {
    id: '6100505',
    name: '印染烘干工',
    level: '4',
  },
  {
    id: '6100506',
    name: '印染丝光工',
    level: '4',
  },
  {
    id: '6100507',
    name: '印染定型工',
    level: '4',
  },
  {
    id: '6100508',
    name: '纺织针织染色工',
    level: '4',
  },
  {
    id: '6100509',
    name: '印花工',
    level: '4',
  },
  {
    id: '6100510',
    name: '印染雕刻制版工',
    level: '4',
  },
  {
    id: '6100511',
    name: '印染后整理工',
    level: '4',
  },
  {
    id: '6100512',
    name: '印染成品定等装潢工',
    level: '4',
  },
  {
    id: '6100513',
    name: '印染染化料配制工',
    level: '3',
  },
  {
    id: '6100514',
    name: '工艺染织制作工',
    level: '1',
  },
  {
    id: '6100515',
    name: '染整工人',
    level: '4',
  },
  {
    id: '6109901',
    name: '技师',
    level: '2',
  },
  {
    id: '6110101',
    name: '裁剪工',
    level: '3',
  },
  {
    id: '6110102',
    name: '缝纫工',
    level: '3',
  },
  {
    id: '6110103',
    name: '缝纫品整型工',
    level: '3',
  },
  {
    id: '6110104',
    name: '裁缝',
    level: '2',
  },
  {
    id: '6110105',
    name: '剧装工',
    level: '3',
  },
  {
    id: '6110106',
    name: '设计师',
    level: '1',
  },
  {
    id: '6110201',
    name: '制鞋工',
    level: '4',
  },
  {
    id: '6110202',
    name: '制帽工',
    level: '3',
  },
  {
    id: '6110301',
    name: '皮革加工工',
    level: '4',
  },
  {
    id: '6110302',
    name: '毛皮加工工',
    level: '3',
  },
  {
    id: '6110401',
    name: '缝纫制品充填处理工',
    level: '3',
  },
  {
    id: '6110402',
    name: '胶制服装上胶工',
    level: '3',
  },
  {
    id: '6110403',
    name: '服装水洗工',
    level: '2',
  },
  {
    id: '6120101',
    name: '制米工',
    level: '3',
  },
  {
    id: '6120102',
    name: '制粉工',
    level: '3',
  },
  {
    id: '6120103',
    name: '制油工',
    level: '3',
  },
  {
    id: '6120201',
    name: '食糖制造工',
    level: '4',
  },
  {
    id: '6120202',
    name: '糖果制造工',
    level: '3',
  },
  {
    id: '6120203',
    name: '巧克力制造工',
    level: '3',
  },
  {
    id: '6120301',
    name: '乳品预处理工',
    level: '2',
  },
  {
    id: '6120302',
    name: '乳品加工工',
    level: '3',
  },
  {
    id: '6120303',
    name: '冷食品制作工',
    level: '3',
  },
  {
    id: '6120304',
    name: '速冻食品制作工',
    level: '3',
  },
  {
    id: '6120305',
    name: '食品罐头加工工',
    level: '3',
  },
  {
    id: '6120306',
    name: '饮料制作工',
    level: '3',
  },
  {
    id: '6120401',
    name: '白酒酿造工',
    level: '3',
  },
  {
    id: '6120402',
    name: '啤酒酿造工',
    level: '3',
  },
  {
    id: '6120403',
    name: '黄酒酿造工',
    level: '3',
  },
  {
    id: '6120404',
    name: '果露酒酿造工',
    level: '3',
  },
  {
    id: '6120405',
    name: '洒精制造工',
    level: '3',
  },
  {
    id: '6120501',
    name: '酶制剂制造工',
    level: '3',
  },
  {
    id: '6120502',
    name: '柠檬酸制造工',
    level: '3',
  },
  {
    id: '6120503',
    name: '酱油酱类制作工',
    level: '3',
  },
  {
    id: '6120504',
    name: '食醋制作工',
    level: '3',
  },
  {
    id: '6120505',
    name: '酱腌菜制作工',
    level: '3',
  },
  {
    id: '6120506',
    name: '食用调料制作工',
    level: '3',
  },
  {
    id: '6120507',
    name: '味精制作工',
    level: '4',
  },
  {
    id: '6120701',
    name: '猪屠宰加工工',
    level: '4',
  },
  {
    id: '6120702',
    name: '牛羊屠宰加工工',
    level: '4',
  },
  {
    id: '6120703',
    name: '肠衣工',
    level: '3',
  },
  {
    id: '6120704',
    name: '禽类屠宰加工工',
    level: '3',
  },
  {
    id: '6120601',
    name: '糕点、面包烘焙工',
    level: '2',
  },
  {
    id: '6120602',
    name: '糕点装饰工',
    level: '1',
  },
  {
    id: '6120603',
    name: '米面主食制作工',
    level: '3',
  },
  {
    id: '6120604',
    name: '油脂制品工',
    level: '2',
  },
  {
    id: '6120605',
    name: '植物蛋白制作工',
    level: '2',
  },
  {
    id: '6120606',
    name: '豆制品制作工',
    level: '3',
  },
  {
    id: '6120801',
    name: '熟肉制品加工工',
    level: '3',
  },
  {
    id: '6120802',
    name: '蛋品及再制蛋品加工工',
    level: '2',
  },
  {
    id: '6120901',
    name: '饲料原料清理上料工',
    level: '2',
  },
  {
    id: '6120902',
    name: '饲料粉碎工',
    level: '3',
  },
  {
    id: '6120903',
    name: '饲料配料混合工',
    level: '2',
  },
  {
    id: '6120904',
    name: '饲料制粒工',
    level: '3',
  },
  {
    id: '6120905',
    name: '饲料添加剂预混工',
    level: '2',
  },
  {
    id: '6120906',
    name: '饲料厂中央控制室操作工',
    level: '1',
  },
  {
    id: '6129901',
    name: '制造工人',
    level: '3',
  },
  {
    id: '6129902',
    name: '冰块制造工人',
    level: '3',
  },
  {
    id: '6129903',
    name: '技师',
    level: '2',
  },
  {
    id: '6129904',
    name: '领班、监工',
    level: '2',
  },
  {
    id: '6129905',
    name: '装罐工人',
    level: '4',
  },
  {
    id: '6130101',
    name: '烟叶调制工',
    level: '2',
  },
  {
    id: '6130102',
    name: '烟叶分级工',
    level: '1',
  },
  {
    id: '6130103',
    name: '挂杆复烤工',
    level: '2',
  },
  {
    id: '6130104',
    name: '打叶复烤工',
    level: '2',
  },
  {
    id: '6130105',
    name: '烟叶回潮工',
    level: '2',
  },
  {
    id: '6130106',
    name: '烟叶发酵工',
    level: '2',
  },
  {
    id: '6130201',
    name: '烟叶制丝工',
    level: '3',
  },
  {
    id: '6130202',
    name: '膨胀烟丝工',
    level: '3',
  },
  {
    id: '6130203',
    name: '白肋烟处理工',
    level: '2',
  },
  {
    id: '6130204',
    name: '烟草薄片工',
    level: '3',
  },
  {
    id: '6130205',
    name: '卷烟卷接工',
    level: '2',
  },
  {
    id: '6130301',
    name: '烟用二醋片制造工',
    level: '3',
  },
  {
    id: '6130302',
    name: '烟用丝束制造工',
    level: '3',
  },
  {
    id: '6130303',
    name: '滤棒工',
    level: '3',
  },
  {
    id: '6140101',
    name: '化学合成制药工',
    level: '3',
  },
  {
    id: '6140201',
    name: '生化药品制造工',
    level: '2',
  },
  {
    id: '6140202',
    name: '发酵工程制药工',
    level: '2',
  },
  {
    id: '6140203',
    name: '疫苗制品工',
    level: '3',
  },
  {
    id: '6140204',
    name: '血液制品工',
    level: '3',
  },
  {
    id: '6140205',
    name: '基因工程产品工',
    level: '2',
  },
  {
    id: '6140301',
    name: '药物制剂工',
    level: '2',
  },
  {
    id: '6140302',
    name: '淀粉葡萄糖制造工',
    level: '3',
  },
  {
    id: '6140401',
    name: '中药炮制与配制工',
    level: '2',
  },
  {
    id: '6140402',
    name: '中药液体制剂工',
    level: '2',
  },
  {
    id: '6140403',
    name: '中药固体制剂工',
    level: '3',
  },
  {
    id: '6150201',
    name: '胶合板工',
    level: '4',
  },
  {
    id: '6150202',
    name: '纤维板工',
    level: '4',
  },
  {
    id: '6150203',
    name: '刨花板工',
    level: '4',
  },
  {
    id: '6150204',
    name: '人造板制胶工',
    level: '3',
  },
  {
    id: '6150205',
    name: '装饰层压板工',
    level: '4',
  },
  {
    id: '6150206',
    name: '人造板饰面工',
    level: '4',
  },
  {
    id: '6150101',
    name: '锯木工人',
    level: '5',
  },
  {
    id: '6150102',
    name: '木材干燥工',
    level: '4',
  },
  {
    id: '6150103',
    name: '木材工厂现场之职员',
    level: '2',
  },
  {
    id: '6150104',
    name: '领班',
    level: '3',
  },
  {
    id: '6150105',
    name: '分级员',
    level: '3',
  },
  {
    id: '6150106',
    name: '检查员',
    level: '3',
  },
  {
    id: '6150107',
    name: '标记员',
    level: '3',
  },
  {
    id: '6150108',
    name: '磅秤员',
    level: '3',
  },
  {
    id: '6150109',
    name: '木材供应站管理人员',
    level: '1',
  },
  {
    id: '6150110',
    name: '木材供应站营业员',
    level: '2',
  },
  {
    id: '6150111',
    name: '仓库管理员',
    level: '3',
  },
  {
    id: '6150112',
    name: '防腐剂工人',
    level: '4',
  },
  {
    id: '6150113',
    name: '木材储藏槽工人',
    level: '4',
  },
  {
    id: '6150114',
    name: '木材搬运工人',
    level: '5',
  },
  {
    id: '6150115',
    name: '吊车操作人员',
    level: '3',
  },
  {
    id: '6150301',
    name: '手工木工',
    level: '3',
  },
  {
    id: '6150302',
    name: '机械木工',
    level: '3',
  },
  {
    id: '6150303',
    name: '精细木工',
    level: '3',
  },
  {
    id: '6150304',
    name: '技师',
    level: '3',
  },
  {
    id: '6150305',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6150306',
    name: '木制家具制造工人',
    level: '3',
  },
  {
    id: '6150307',
    name: '木制家具修理工人',
    level: '3',
  },
  {
    id: '6160101',
    name: '制浆备料工',
    level: '5',
  },
  {
    id: '6160102',
    name: '制浆设备操作工',
    level: '5',
  },
  {
    id: '6160103',
    name: '制浆废液回收利用工',
    level: '5',
  },
  {
    id: '6160201',
    name: '造纸工',
    level: '4',
  },
  {
    id: '6160202',
    name: '纸张整饰工',
    level: '3',
  },
  {
    id: '6160203',
    name: '宣纸书画纸制作工',
    level: '4',
  },
  {
    id: '6160301',
    name: '瓦楞纸箱制作工',
    level: '4',
  },
  {
    id: '6160302',
    name: '纸盒制作工',
    level: '4',
  },
  {
    id: '6169901',
    name: '技师',
    level: '3',
  },
  {
    id: '6169902',
    name: '领班、监工',
    level: '3',
  },
  {
    id: '6170101',
    name: '水泥生产制造工',
    level: '6',
  },
  {
    id: '6170102',
    name: '水泥制品工',
    level: '6',
  },
  {
    id: '6170103',
    name: '石灰焙烧工',
    level: '6',
  },
  {
    id: '6170104',
    name: '工程师',
    level: '2',
  },
  {
    id: '6170105',
    name: '技师',
    level: '2',
  },
  {
    id: '6170106',
    name: '领班',
    level: '3',
  },
  {
    id: '6170107',
    name: '一般工人',
    level: '6',
  },
  {
    id: '6170108',
    name: '采掘工',
    level: '7',
  },
  {
    id: '6170109',
    name: '爆破工',
    level: '7',
  },
  {
    id: '6170110',
    name: '陶瓷、木炭、砖块制造工',
    level: '3',
  },
  {
    id: '6170201',
    name: '砖、瓦生产工',
    level: '3',
  },
  {
    id: '6170202',
    name: '加气混凝土制品工',
    level: '6',
  },
  {
    id: '6170203',
    name: '纸面石膏板生产工',
    level: '3',
  },
  {
    id: '6170204',
    name: '石膏浮雕板工',
    level: '3',
  },
  {
    id: '6170205',
    name: '监工(不参与制造过程)',
    level: '2',
  },
  {
    id: '6170301',
    name: '油毡生产工',
    level: '3',
  },
  {
    id: '6170302',
    name: '高分子防水卷材生产工',
    level: '3',
  },
  {
    id: '6170401',
    name: '保温材料制造工',
    level: '4',
  },
  {
    id: '6170402',
    name: '吸音材料生产工',
    level: '4',
  },
  {
    id: '6170403',
    name: '珍珠岩制造工',
    level: '4',
  },
  {
    id: '6170601',
    name: '云母制品加工工',
    level: '4',
  },
  {
    id: '6170602',
    name: '石棉制品工',
    level: '7',
  },
  {
    id: '6170603',
    name: '高岭土制品工',
    level: '4',
  },
  {
    id: '6170604',
    name: '金刚石制品工',
    level: '5',
  },
  {
    id: '6170605',
    name: '人工合成晶体工',
    level: '4',
  },
  {
    id: '6170501',
    name: '装饰石材生产工',
    level: '5',
  },
  {
    id: '6170701',
    name: '耐火原料加工工',
    level: '3',
  },
  {
    id: '6170702',
    name: '耐火材料成型工',
    level: '3',
  },
  {
    id: '6170703',
    name: '耐火材料烧成工',
    level: '4',
  },
  {
    id: '6170704',
    name: '耐火制品浸油工',
    level: '4',
  },
  {
    id: '6170705',
    name: '耐火纤维制品工',
    level: '4',
  },
  {
    id: '6180101',
    name: '玻璃配料工',
    level: '3',
  },
  {
    id: '6180102',
    name: '玻璃熔化工',
    level: '4',
  },
  {
    id: '6180103',
    name: '玻璃制板及玻璃成型工',
    level: '4',
  },
  {
    id: '6180104',
    name: '玻璃加工工',
    level: '4',
  },
  {
    id: '6180105',
    name: '玻璃制品装饰加工工',
    level: '3',
  },
  {
    id: '6180106',
    name: '技师',
    level: '3',
  },
  {
    id: '6180107',
    name: '监工',
    level: '3',
  },
  {
    id: '6180301',
    name: '石英玻璃制品加工工',
    level: '4',
  },
  {
    id: '6180201',
    name: '玻璃纤维制品工',
    level: '4',
  },
  {
    id: '6180202',
    name: '玻璃钢制品工',
    level: '4',
  },
  {
    id: '6180401',
    name: '陶瓷原料准备工',
    level: '3',
  },
  {
    id: '6180402',
    name: '陶瓷成型工',
    level: '3',
  },
  {
    id: '6180403',
    name: '陶瓷烧成工',
    level: '3',
  },
  {
    id: '6180404',
    name: '陶瓷装饰工',
    level: '3',
  },
  {
    id: '6180405',
    name: '匣钵、模型制作工',
    level: '3',
  },
  {
    id: '6180406',
    name: '古建琉璃工',
    level: '3',
  },
  {
    id: '6180501',
    name: '搪瓷釉浆熔制工',
    level: '3',
  },
  {
    id: '6180502',
    name: '搪瓷坯体制作工',
    level: '4',
  },
  {
    id: '6180503',
    name: '搪瓷涂搪烧成工',
    level: '3',
  },
  {
    id: '6180504',
    name: '搪瓷花版饰花工',
    level: '3',
  },
  {
    id: '6190101',
    name: '影视置景制作员',
    level: '3',
  },
  {
    id: '6190102',
    name: '影视服装员',
    level: '1',
  },
  {
    id: '6190103',
    name: '影视舞台烟火特效员',
    level: '3',
  },
  {
    id: '6190104',
    name: '影视动画制作员',
    level: '1',
  },
  {
    id: '6190105',
    name: '影视木偶制作员',
    level: '2',
  },
  {
    id: '6190106',
    name: '电影洗印员',
    level: '2',
  },
  {
    id: '6190201',
    name: '唱片制作工',
    level: '3',
  },
  {
    id: '6190202',
    name: '唱片检听工',
    level: '2',
  },
  {
    id: '6190203',
    name: '音像带复制工',
    level: '1',
  },
  {
    id: '6190204',
    name: '光盘复制工',
    level: '1',
  },
  {
    id: '6190301',
    name: '照明设备操作员',
    level: '2',
  },
  {
    id: '6190302',
    name: '影视设备机械员',
    level: '2',
  },
  {
    id: '6190303',
    name: '广播电视天线工',
    level: '5',
  },
  {
    id: '6190304',
    name: '有线广播电视机线员',
    level: '4',
  },
  {
    id: '6190305',
    name: '音响调音员',
    level: '1',
  },
  {
    id: '6190306',
    name: '舞台音响效果工',
    level: '2',
  },
  {
    id: '6190401',
    name: '电影放映员',
    level: '2',
  },
  {
    id: '6190402',
    name: '拷贝检片员',
    level: '2',
  },
  {
    id: '6190403',
    name: '拷贝字幕员',
    level: '2',
  },
  {
    id: '6190404',
    name: '电影院售票员',
    level: '1',
  },
  {
    id: '6190405',
    name: '跑片员',
    level: '4',
  },
  {
    id: '6190501',
    name: '考古发掘工',
    level: '4',
  },
  {
    id: '6190502',
    name: '文物修复工',
    level: '3',
  },
  {
    id: '6190503',
    name: '文物拓印工',
    level: '2',
  },
  {
    id: '6190504',
    name: '古旧书画修复工',
    level: '2',
  },
  {
    id: '6200101',
    name: '平版制版工',
    level: '2',
  },
  {
    id: '6200102',
    name: '凸版制版工',
    level: '3',
  },
  {
    id: '6200103',
    name: '凹版制版工',
    level: '3',
  },
  {
    id: '6200104',
    name: '孔版制版工',
    level: '2',
  },
  {
    id: '6200105',
    name: '印前制作员',
    level: '2',
  },
  {
    id: '6200201',
    name: '平版印刷工',
    level: '2',
  },
  {
    id: '6200202',
    name: '凸版印刷工',
    level: '2',
  },
  {
    id: '6200203',
    name: '凹版印刷工',
    level: '2',
  },
  {
    id: '6200204',
    name: '孔版 印刷工',
    level: '2',
  },
  {
    id: '6200205',
    name: '木刻水印印制工',
    level: '2',
  },
  {
    id: '6200206',
    name: '珂罗版印制工',
    level: '2',
  },
  {
    id: '6200207',
    name: '盲文印制工',
    level: '2',
  },
  {
    id: '6200301',
    name: '装订工',
    level: '2',
  },
  {
    id: '6200302',
    name: '印品整饰工',
    level: '2',
  },
  {
    id: '6210101',
    name: '宝石琢磨工',
    level: '2',
  },
  {
    id: '6210102',
    name: '贵金属首饰制作工',
    level: '2',
  },
  {
    id: '6210201',
    name: '地毯制作工',
    level: '2',
  },
  {
    id: '6210401',
    name: '漆器制胎工',
    level: '3',
  },
  {
    id: '6210402',
    name: '彩绘雕填制作工',
    level: '2',
  },
  {
    id: '6210403',
    name: '漆器镶嵌工',
    level: '2',
  },
  {
    id: '6210301',
    name: '金属、塑料、木制玩具装配工',
    level: '2',
  },
  {
    id: '6210302',
    name: '布绒玩具制作工',
    level: '2',
  },
  {
    id: '6210303',
    name: '搪塑玩具制作工',
    level: '2',
  },
  {
    id: '6210501',
    name: '机绣工',
    level: '2',
  },
  {
    id: '6210502',
    name: '手绣制作工',
    level: '2',
  },
  {
    id: '6210503',
    name: '抽纱挑编工',
    level: '2',
  },
  {
    id: '6210601',
    name: '景泰蓝制作工',
    level: '4',
  },
  {
    id: '6210602',
    name: '金属摆件工',
    level: '3',
  },
  {
    id: '6210603',
    name: '金属手工艺品加工工人',
    level: '3',
  },
  {
    id: '6210701',
    name: '金属工艺品雕刻工',
    level: '3',
  },
  {
    id: '6210702',
    name: '竹木制手工艺品雕刻工',
    level: '2',
  },
  {
    id: '6219901',
    name: '民间工艺品制作工',
    level: '2',
  },
  {
    id: '6219902',
    name: '人造花制作工',
    level: '2',
  },
  {
    id: '6219903',
    name: '工艺画制作工',
    level: '2',
  },
  {
    id: '6219904',
    name: '烟花爆竹制作工',
    level: '7',
  },
  {
    id: '6219905',
    name: '竹木制手工艺品加工工人',
    level: '2',
  },
  {
    id: '6219906',
    name: '布类纸品工艺品加工工人',
    level: '1',
  },
  {
    id: '6219907',
    name: '矿石手工艺品加工人员',
    level: '3',
  },
  {
    id: '6210801',
    name: '装饰美工',
    level: '2',
  },
  {
    id: '6210802',
    name: '雕塑翻制工',
    level: '2',
  },
  {
    id: '6210803',
    name: '壁画制作工',
    level: '2',
  },
  {
    id: '6210804',
    name: '油画外框制作工',
    level: '2',
  },
  {
    id: '6210805',
    name: '装裱工',
    level: '2',
  },
  {
    id: '6210806',
    name: '版画制作工',
    level: '2',
  },
  {
    id: '6220101',
    name: '墨制作工',
    level: '3',
  },
  {
    id: '6220102',
    name: '墨水制造工',
    level: '2',
  },
  {
    id: '6220103',
    name: '墨汁制造工',
    level: '2',
  },
  {
    id: '6220104',
    name: '绘图仪器制作工',
    level: '2',
  },
  {
    id: '6220105',
    name: '静电复印机消耗材制造工',
    level: '2',
  },
  {
    id: '6220106',
    name: '毛笔制作工',
    level: '2',
  },
  {
    id: '6220107',
    name: '自来水笔制作工',
    level: '2',
  },
  {
    id: '6220108',
    name: '圆珠笔制作工',
    level: '2',
  },
  {
    id: '6220109',
    name: '铅笔制造工',
    level: '3',
  },
  {
    id: '6220110',
    name: '印泥制作工',
    level: '2',
  },
  {
    id: '6220201',
    name: '制球工',
    level: '2',
  },
  {
    id: '6220202',
    name: '球拍、球网制作工',
    level: '2',
  },
  {
    id: '6220203',
    name: '健身器材制作工',
    level: '3',
  },
  {
    id: '6220301',
    name: '钢琴及键盘乐器制作工',
    level: '3',
  },
  {
    id: '6220302',
    name: '提琴制作工',
    level: '3',
  },
  {
    id: '6220303',
    name: '管乐器制作工',
    level: '3',
  },
  {
    id: '6220304',
    name: '民族拉弦、弹拨乐器制作工',
    level: '3',
  },
  {
    id: '6220305',
    name: '吹奏乐器制作工',
    level: '3',
  },
  {
    id: '6220306',
    name: '打击乐器制作工',
    level: '3',
  },
  {
    id: '6220307',
    name: '电声乐器制作工',
    level: '3',
  },
  {
    id: '6230201',
    name: '砌筑工',
    level: '4',
  },
  {
    id: '6230202',
    name: '洗石工人',
    level: '4',
  },
  {
    id: '6230203',
    name: '砌砖匠',
    level: '4',
  },
  {
    id: '6230204',
    name: '泥水匠',
    level: '',
  },
  {
    id: '6230205',
    name: '磨石工人',
    level: '3',
  },
  {
    id: '6230101',
    name: '凿岩工',
    level: '7',
  },
  {
    id: '6230102',
    name: '爆破工',
    level: '7',
  },
  {
    id: '6230103',
    name: '土石方机械操作工(推土、铲运机驾驶员、挖掘机驾驶员、打桩工、铲运机操作',
    level: '5',
  },
  {
    id: '6230301',
    name: '混凝土工',
    level: '4',
  },
  {
    id: '6230302',
    name: '混凝土制品模具工',
    level: '4',
  },
  {
    id: '6230303',
    name: '混凝土搅拌机械操作工',
    level: '4',
  },
  {
    id: '6230401',
    name: '钢筋工',
    level: '5',
  },
  {
    id: '6230501',
    name: '架子工',
    level: '5',
  },
  {
    id: '6230701',
    name: '装饰装修工',
    level: '4',
  },
  {
    id: '6230702',
    name: '室内成套设施装饰工',
    level: '3',
  },
  {
    id: '6230703',
    name: '油漆工、喷漆工',
    level: '4',
  },
  {
    id: '6230704',
    name: '铝门窗安装工人',
    level: '4',
  },
  {
    id: '6230705',
    name: '石棉瓦或浪板安装工人',
    level: '4',
  },
  {
    id: '6230706',
    name: '设计制图人员',
    level: '1',
  },
  {
    id: '6230707',
    name: '地毯之装设人员',
    level: '2',
  },
  {
    id: '6230708',
    name: '室内装潢人员(不含木工、油漆工)',
    level: '3',
  },
  {
    id: '6230709',
    name: '室外装潢人员',
    level: '6',
  },
  {
    id: '6230710',
    name: '承包商、监工',
    level: '2',
  },
  {
    id: '6230711',
    name: '铁门窗制造安装工人',
    level: '5',
  },
  {
    id: '6230712',
    name: '木工',
    level: '3',
  },
  {
    id: '6230713',
    name: '安装玻璃幕墙工人',
    level: '7',
  },
  {
    id: '6230601',
    name: '防水工',
    level: '4',
  },
  {
    id: '6230602',
    name: '防渗墙工',
    level: '4',
  },
  {
    id: '6230603',
    name: '排水工程人员',
    level: '4',
  },
  {
    id: '6230801',
    name: '古建筑结构施工工',
    level: '4',
  },
  {
    id: '6230802',
    name: '古建筑装饰工',
    level: '4',
  },
  {
    id: '6230901',
    name: '筑路机械操作工',
    level: '5',
  },
  {
    id: '6230902',
    name: '筑路、养护工',
    level: '5',
  },
  {
    id: '6230903',
    name: '线桥专用机械操作工',
    level: '5',
  },
  {
    id: '6230904',
    name: '铁道线路工',
    level: '4',
  },
  {
    id: '6230905',
    name: '桥梁工',
    level: '5',
  },
  {
    id: '6230906',
    name: '隧道工',
    level: '6',
  },
  {
    id: '6230907',
    name: '铁路舟桥工',
    level: '5',
  },
  {
    id: '6230908',
    name: '道岔制修工',
    level: '5',
  },
  {
    id: '6230909',
    name: '枕木处理工',
    level: '4',
  },
  {
    id: '6230910',
    name: '铁路平交道看守人员',
    level: '2',
  },
  {
    id: '6230911',
    name: '铁路修护厂技工',
    level: '3',
  },
  {
    id: '6230912',
    name: '铁路铺设领班',
    level: '3',
  },
  {
    id: '6230913',
    name: '管道铺设及维护工人',
    level: '4',
  },
  {
    id: '6230914',
    name: '高速公路工程人员',
    level: '5',
  },
  {
    id: '6230915',
    name: '电线架设及维护工人',
    level: '5',
  },
  {
    id: '6231001',
    name: '机械设备安装工',
    level: '3',
  },
  {
    id: '6231002',
    name: '电气设备安装工',
    level: '4',
  },
  {
    id: '6231003',
    name: '管工',
    level: '4',
  },
  {
    id: '6231004',
    name: '防火系统、警报器安装人员',
    level: '4',
  },
  {
    id: '6231005',
    name: '电梯、升降机安装工人(非高空作业)',
    level: '4',
  },
  {
    id: '6231006',
    name: '电梯、升降机安装工人(高空作业)',
    level: '7',
  },
  {
    id: '6231007',
    name: '电梯、升降机修理及维护工人',
    level: '4',
  },
  {
    id: '6239901',
    name: '中小型施工机械操作工',
    level: '4',
  },
  {
    id: '6239902',
    name: '建筑工程车辆驾驶员',
    level: '5',
  },
  {
    id: '6239903',
    name: '建筑工程车辆机械操作员',
    level: '5',
  },
  {
    id: '6239904',
    name: '监工',
    level: '3',
  },
  {
    id: '6239905',
    name: '测量员',
    level: '3',
  },
  {
    id: '6239906',
    name: '拆屋、迁屋工人',
    level: '4',
  },
  {
    id: '6239907',
    name: '道路工程机械操作员',
    level: '4',
  },
  {
    id: '6239908',
    name: '道路工程车辆驾驶员',
    level: '4',
  },
  {
    id: '6239909',
    name: '工地看守员',
    level: '4',
  },
  {
    id: '6239910',
    name: '海湾港口工程人员',
    level: '5',
  },
  {
    id: '6239911',
    name: '水坝工程人员、挖井工程人员',
    level: '5',
  },
  {
    id: '6239912',
    name: '潜水工作人员',
    level: '7',
  },
  {
    id: '6239913',
    name: '挖泥船工人',
    level: '5',
  },
  {
    id: '6239914',
    name: '工地推车工',
    level: '4',
  },
  {
    id: '6240103',
    name: '出租车、救护车司机',
    level: '4',
  },
  {
    id: '6240104',
    name: '游览车司机及服务员',
    level: '3',
  },
  {
    id: '6240105',
    name: '客运车司机及服务员',
    level: '3',
  },
  {
    id: '6240106',
    name: '小型客货两用车司机',
    level: '3',
  },
  {
    id: '6240108',
    name: '人力三轮车夫',
    level: '3',
  },
  {
    id: '6240109',
    name: '机动三轮车夫',
    level: '5',
  },
  {
    id: '6240110',
    name: '营业用货车司机、随车工人',
    level: '6',
  },
  {
    id: '6240111',
    name: '砂石车司机、随车工人',
    level: '6',
  },
  {
    id: '6240112',
    name: '工程卡车司机、随车人员',
    level: '5',
  },
  {
    id: '6240113',
    name: '液化、氧化油罐车司机、随车工人',
    level: '6',
  },
  {
    id: '6240114',
    name: '货柜车司机、随车人员',
    level: '4',
  },
  {
    id: '6240115',
    name: '有摩托车驾照人员',
    level: '3',
  },
  {
    id: '6240201',
    name: '车站行车作业员',
    level: '2',
  },
  {
    id: '6240202',
    name: '车站运转作业计划员',
    level: '1',
  },
  {
    id: '6240203',
    name: '车号员',
    level: '2',
  },
  {
    id: '6240204',
    name: '驼峰设备操作员',
    level: '2',
  },
  {
    id: '6240205',
    name: '车站调车作业员',
    level: '3',
  },
  {
    id: '6240206',
    name: '列车运转乘务员',
    level: '2',
  },
  {
    id: '6240207',
    name: '机车驾驶员',
    level: '3',
  },
  {
    id: '6240208',
    name: '机车调度员',
    level: '1',
  },
  {
    id: '6240209',
    name: '发电车乘务员',
    level: '3',
  },
  {
    id: '6240210',
    name: '机车燃料填充员',
    level: '3',
  },
  {
    id: '6240211',
    name: '救援机械操作员',
    level: '4',
  },
  {
    id: '6240212',
    name: '列车轴温检测员',
    level: '2',
  },
  {
    id: '6240213',
    name: '铁路通信工',
    level: '4',
  },
  {
    id: '6240214',
    name: '铁路电源工',
    level: '4',
  },
  {
    id: '6240215',
    name: '铁路信号工',
    level: '4',
  },
  {
    id: '6240216',
    name: '铁路机工',
    level: '4',
  },
  {
    id: '6240301',
    name: '航空通信雷达导航员',
    level: '1',
  },
  {
    id: '6240302',
    name: '航空通信雷达设备维护保养员',
    level: '3',
  },
  {
    id: '6240303',
    name: '航空油料员',
    level: '3',
  },
  {
    id: '6240304',
    name: '航空摄影员',
    level: '6',
  },
  {
    id: '6240305',
    name: '航空器材员',
    level: '3',
  },
  {
    id: '6240306',
    name: '航空气象员',
    level: '1',
  },
  {
    id: '6240401',
    name: '水手长、水手',
    level: '6',
  },
  {
    id: '6240402',
    name: '木匠',
    level: '6',
  },
  {
    id: '6240403',
    name: '铜匠',
    level: '6',
  },
  {
    id: '6240404',
    name: '船舶机工',
    level: '6',
  },
  {
    id: '6240405',
    name: '船舶轮机员',
    level: '6',
  },
  {
    id: '6240406',
    name: '船舶加油',
    level: '3',
  },
  {
    id: '6240407',
    name: '无线电航标操作工',
    level: '3',
  },
  {
    id: '6240408',
    name: '潜水员',
    level: '7',
  },
  {
    id: '6240409',
    name: '视觉航标工(灯塔、航标维护保养)',
    level: '6',
  },
  {
    id: '6240410',
    name: '港口维护工(码头维修、水面防污、港口除尘)',
    level: '5',
  },
  {
    id: '6240411',
    name: '航道航务施工工',
    level: '7',
  },
  {
    id: '6240412',
    name: '泵匠',
    level: '6',
  },
  {
    id: '6240413',
    name: '电机师',
    level: '6',
  },
  {
    id: '6240414',
    name: '游览船之驾驶及工作人员',
    level: '6',
  },
  {
    id: '6240415',
    name: '小汽艇之驾驶及工作人员',
    level: '6',
  },
  {
    id: '6240416',
    name: '拖船驾驶员及工作人员',
    level: '4',
  },
  {
    id: '6240417',
    name: '渡船驾驶员及工作人员',
    level: '4',
  },
  {
    id: '6240501',
    name: '起重装卸机械操作工',
    level: '4',
  },
  {
    id: '6240502',
    name: '起重工',
    level: '6',
  },
  {
    id: '6240503',
    name: '输送机操作工',
    level: '3',
  },
  {
    id: '6240504',
    name: '闸门运行工',
    level: '3',
  },
  {
    id: '6240505',
    name: '索道运输机械操作工',
    level: '3',
  },
  {
    id: '6249901',
    name: '电梯、升降机操作员(不含矿场使用者)',
    level: '2',
  },
  {
    id: '6250101',
    name: '大气环境监测工',
    level: '2',
  },
  {
    id: '6250102',
    name: '水环境监测工',
    level: '2',
  },
  {
    id: '6250103',
    name: '土壤环境监测工',
    level: '2',
  },
  {
    id: '6250104',
    name: '环境生物监测工',
    level: '2',
  },
  {
    id: '6250105',
    name: '环境噪声及振动监测工',
    level: '2',
  },
  {
    id: '6250106',
    name: '固体废物监测工',
    level: '2',
  },
  {
    id: '6250107',
    name: '环境辐射监测工',
    level: '2',
  },
  {
    id: '6250108',
    name: '室内装饰装修质量检验员',
    level: '2',
  },
  {
    id: '6250109',
    name: '室内环境治理员',
    level: '3',
  },
  {
    id: '6250201',
    name: '海洋调查与监测工',
    level: '3',
  },
  {
    id: '6250202',
    name: '海洋浮标工',
    level: '2',
  },
  {
    id: '6250203',
    name: '海洋水文气象观测员',
    level: '1',
  },
  {
    id: '6250301',
    name: '固体废物处理工',
    level: '3',
  },
  {
    id: '6250302',
    name: '废水处理工',
    level: '3',
  },
  {
    id: '6250303',
    name: '废气处理工',
    level: '3',
  },
  {
    id: '6250304',
    name: '除尘设备运行工',
    level: '3',
  },
  {
    id: '6260101',
    name: '化学检验工',
    level: '2',
  },
  {
    id: '6260102',
    name: '材料成分检验工',
    level: '1',
  },
  {
    id: '6260103',
    name: '材料物理性能检验工',
    level: '1',
  },
  {
    id: '6260104',
    name: '无损检测员',
    level: '1',
  },
  {
    id: '6260105',
    name: '产品环境适应性能检验工',
    level: '1',
  },
  {
    id: '6260106',
    name: '产品可靠性能检验工',
    level: '1',
  },
  {
    id: '6260107',
    name: '产品安全性能检验工',
    level: '1',
  },
  {
    id: '6260108',
    name: '食品检验工',
    level: '1',
  },
  {
    id: '6260109',
    name: '饲料检验工',
    level: '1',
  },
  {
    id: '6260110',
    name: '畜禽产品检验工',
    level: '1',
  },
  {
    id: '6260111',
    name: '烟草检验工',
    level: '1',
  },
  {
    id: '6260112',
    name: '纺织纤维检验工',
    level: '1',
  },
  {
    id: '6260113',
    name: '针纺织品检验工',
    level: '1',
  },
  {
    id: '6260114',
    name: '印染工艺检验工',
    level: '1',
  },
  {
    id: '6260115',
    name: '服装鞋帽检验工',
    level: '1',
  },
  {
    id: '6260116',
    name: '木材及家具检验工',
    level: '1',
  },
  {
    id: '6260117',
    name: '包装材料检验工',
    level: '1',
  },
  {
    id: '6260118',
    name: '文体用品及出版物品检验工',
    level: '1',
  },
  {
    id: '6260119',
    name: '燃料检验工',
    level: '2',
  },
  {
    id: '6260120',
    name: '感光材料检验工',
    level: '1',
  },
  {
    id: '6260121',
    name: '药物检验工',
    level: '1',
  },
  {
    id: '6260122',
    name: '中药检验工',
    level: '1',
  },
  {
    id: '6260123',
    name: '五金制品检验工',
    level: '1',
  },
  {
    id: '6260124',
    name: '机械产品检验工',
    level: '2',
  },
  {
    id: '6260125',
    name: '医疗器械检验工',
    level: '1',
  },
  {
    id: '6260126',
    name: '机动车检验工',
    level: '2',
  },
  {
    id: '6260127',
    name: '电器产品检验工',
    level: '2',
  },
  {
    id: '6260128',
    name: '电工器材检验工',
    level: '2',
  },
  {
    id: '6260129',
    name: '照明电器检验工',
    level: '2',
  },
  {
    id: '6260130',
    name: '通信设备检验工',
    level: '2',
  },
  {
    id: '6260131',
    name: '广播影视设备检验工',
    level: '2',
  },
  {
    id: '6260132',
    name: '计算机检验工',
    level: '1',
  },
  {
    id: '6260133',
    name: '电子器件检验工',
    level: '1',
  },
  {
    id: '6260134',
    name: '仪器仪表检验工',
    level: '2',
  },
  {
    id: '6260135',
    name: '贵金属首饰、钻石、宝玉石检验员',
    level: '1',
  },
  {
    id: '6260136',
    name: '管道检验工',
    level: '2',
  },
  {
    id: '6260137',
    name: '合成材料测试员',
    level: '2',
  },
  {
    id: '6260201',
    name: '飞机检验工',
    level: '2',
  },
  {
    id: '6260202',
    name: '机载导弹检验工',
    level: '2',
  },
  {
    id: '6260203',
    name: '航空发动机检验工',
    level: '2',
  },
  {
    id: '6260204',
    name: '飞机螺旋桨检验工',
    level: '2',
  },
  {
    id: '6260205',
    name: '飞机、发动机附检验工',
    level: '2',
  },
  {
    id: '6260206',
    name: '航空环控救生装备检验工',
    level: '1',
  },
  {
    id: '6260207',
    name: '航空仪表检验工',
    level: '1',
  },
  {
    id: '6260301',
    name: '航天器无损检测试验工',
    level: '2',
  },
  {
    id: '6260302',
    name: '航天器材料性能测试试验工',
    level: '2',
  },
  {
    id: '6260303',
    name: '试车台测量工',
    level: '2',
  },
  {
    id: '6260304',
    name: '试车台液、气系统操作工',
    level: '2',
  },
  {
    id: '6260305',
    name: '试车台控制工',
    level: '2',
  },
  {
    id: '6260306',
    name: '液体推进剂性能试验工',
    level: '2',
  },
  {
    id: '6260307',
    name: '固体推进剂性能试验工',
    level: '2',
  },
  {
    id: '6260308',
    name: '试车台测力计量检定工',
    level: '2',
  },
  {
    id: '6260401',
    name: '长度计量工',
    level: '1',
  },
  {
    id: '6260402',
    name: '热工计量工',
    level: '1',
  },
  {
    id: '6260403',
    name: '衡器计量工',
    level: '1',
  },
  {
    id: '6260404',
    name: '硬度测力计量工',
    level: '1',
  },
  {
    id: '6260405',
    name: '容量计量工',
    level: '1',
  },
  {
    id: '6260406',
    name: '电器计量工',
    level: '1',
  },
  {
    id: '6260407',
    name: '化学计量工',
    level: '1',
  },
  {
    id: '6260408',
    name: '声学计量工',
    level: '1',
  },
  {
    id: '6260409',
    name: '光学计量工',
    level: '1',
  },
  {
    id: '6260410',
    name: '电离辐射计量工',
    level: '1',
  },
  {
    id: '6260411',
    name: '专用计量器具计量工',
    level: '1',
  },
  {
    id: '6990101',
    name: '包装人员',
    level: '3',
  },
  {
    id: '6990201',
    name: '机泵操作人员',
    level: '3',
  },
  {
    id: '6990301',
    name: '简单体力劳动人员',
    level: '4',
  },
  {
    id: '7000001',
    name: '一般地面部队人员(含陆军野战、机械修护、土木工程、飞弹、战车及空军炮、飞机修护等)',
    level: '3',
  },
  {
    id: '7000002',
    name: '特种兵(海军陆战队、伞兵、水兵、爆破兵、蛙人、化学兵、负有布雷爆破任务之工兵、情报单位负有特殊任务者)',
    level: '7',
  },
  {
    id: '7000003',
    name: '行政及内勤人员(国防部、三军总部、军校教官等)',
    level: '1',
  },
  {
    id: '7000004',
    name: '宪兵',
    level: '4',
  },
  {
    id: '7000005',
    name: '后勤补给及通讯地勤人员',
    level: '3',
  },
  {
    id: '7000006',
    name: '军事研究单位纸上设计人员',
    level: '1',
  },
  {
    id: '7000007',
    name: '军事单位武器、弹药研究及管理人员',
    level: '3',
  },
  {
    id: '7000008',
    name: '空军飞行官兵、空军海洋巡弋舰艇及潜艇官兵',
    level: '7',
  },
  {
    id: '7000009',
    name: '前线军人',
    level: '7',
  },
  {
    id: '7000010',
    name: '军校学生及入伍受训新兵',
    level: '7',
  },
  {
    id: '7000011',
    name: '军医院官兵',
    level: '2',
  },
  {
    id: '8000101',
    name: '特殊运动班学生(拳击、摔跤、跆拳道等)',
    level: '6',
  },
  {
    id: '8000102',
    name: '武术学校学生',
    level: '6',
  },
  {
    id: '8000001',
    name: '无业人员',
    level: '2',
  },
  {
    id: '8000002',
    name: '退休人员',
    level: '1',
  },
  {
    id: '8000003',
    name: '离休人员',
    level: '1',
  },
  {
    id: '8000201',
    name: '高处作业人员',
    level: '6',
  },
];

export { occupationJSON, occupationJSON_LEVEL };
