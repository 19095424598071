import { React, ReactDOM, ClassNames, $ } from '../../../common/3rd';
import { Lang, Ajax, Utils } from '../../../common/common';
import Product from './product';

import './product.css';
import plainImage from '../../../images/plain-product.png';

/**
 * plain product
 */
class PlainProduct extends Product {
  getImageUrl(key) {
    let image = super.getImageUrl(key);
    return image ? image : plainImage;
  }
  render() {
    // let likeClassName = ClassNames('fa fa-fw fa-heart-o', {
    // 	'liked': this.isLiked()
    // });
    //B2C取第三张图做为列表图
    const IMG_TYPE = window.location.href.includes('/wechat/')
      ? 'image3'
      : 'image2';
    return (
      <div
        className={this.getWidgetClassName('product plain')}
        onClick={this.onProductClicked}
        ref="me"
      >
        <span data-id={this.getProductUniqueKey()} />
        <div className="img">
          <img src={this.getImageUrl(IMG_TYPE)} alt="" />
        </div>
        <div className="content">
          <div className="title">
            <span>{this.getProductName()}</span>
          </div>
          <div className="slogan">
            <span>
              {this.getSlogan1()} {this.getSlogan2()}
            </span>
          </div>
          {/*<div className='like'
					onClick={this.onLikeClicked}
					ref='effect'>
					<i ref='likeIcon' className='icon icon-dislike'></i>
					<span>{this.getLikeCount()}</span>
				</div>*/}
          <div className="price">
            <span>{this.getCurrencySign()}</span>
            <span>{this.getMinPrice()}</span>
            <span>{Lang.messages.product.priceStarts}</span>
          </div>
        </div>
      </div>
    );
  }
  onLikeClicked = (evt) => {
    if (Ajax.isAuthorized()) {
      evt.preventDefault();
      evt.stopPropagation();
      let productId = this.getProductId();
      let action = this.isLiked() ? 'unlike' : 'like';
      let url = `/rest/v1/product/favorite/${action}/${productId}`;
      this.doPost(url).done((data, textStatus, jqXHR) => {
        let likeCount = this.getLikeCount();
        likeCount = likeCount == null ? 0 : likeCount;
        if ('unlike' === action) {
          likeCount--;
          this.setLiked(false);
          $(ReactDOM.findDOMNode(this.refs.effect)).removeClass(
            'like-effect-shown'
          );
          this.refs.likeIcon.className = 'icon icon-dislike';
        } else if ('like' === action) {
          likeCount++;
          this.setLiked(true);
          Utils.transitionend({
            target: $(ReactDOM.findDOMNode(this.refs.effect)).addClass(
              'like-effect-shown'
            ),
            handler: () => {
              $(ReactDOM.findDOMNode(this.refs.effect)).removeClass(
                'like-effect-shown'
              );
            },
          });
          this.refs.likeIcon.className = 'icon icon-like';
        }
        this.setLikeCount(likeCount < 0 ? 0 : likeCount);
        this.forceUpdate();
      });
    } else {
      // not signed in, do nothing
    }
  };
}

export default PlainProduct;
