import { React } from '../common/3rd';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }
    componentDidMount() {
      importComponent().then((cmp) => {
        this.setState({ component: cmp.default });
      });
    }
    render() {
      // let appLoading = document.getElementById("app-loading");
      // if (appLoading) {
      // 	appLoading.remove();
      // }
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
      // return C ? <C {...this.props} /> : <div className="app-loading" />;
    }
  }
  return AsyncComponent;
}
