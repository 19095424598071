/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description App错误
 * @author LiuH
 */
import { isNotEmptyString } from '@/util/precondition';

class AppError extends Error {
  constructor(code, message) {
    super(message);
    this.name = 'AppError';
    this.code = code;
    this.message = message;
  }

  toString() {
    return `AppError: [${this.code}], ${this.message}\n`;
  }

  /**
   * 判断是否为用户取消操作
   * @param {string} errorMsg
   */
  static isUserCancel(errorMsg) {
    return (
      isNotEmptyString(errorMsg) &&
      (errorMsg.includes('用户取消') || errorMsg.includes('User cancel'))
    );
  }
}

export default AppError;
