import { React } from '../../../common/3rd';
import { Lang, Utils, Consts } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';
import './policy.css';
import messages from './messages.json';
Lang.installMessages(messages, 'policy');

const Icons = {
  flight: 'plane',
  vehicle: 'car',
  accident: 'umbrella',
  home: 'home',
  person: 'male',
  health: 'heartbeat',
  property: 'key',
};
const QUOTE = Consts.POLICY_STATUS.QUOTE;
const ISSUED = Consts.POLICY_STATUS.ISSUED;
const WAIT_PAY = Consts.POLICY_STATUS.WAIT_FOR_PAYMENT;
const ADVISE = Consts.POLICY_STATUS.ADVISE;
const CANCELLED = Consts.POLICY_STATUS.CANCELLED;
const TERMINATED = Consts.POLICY_STATUS.TERMINATED;
const NOT_ISSUED = [QUOTE, WAIT_PAY, ADVISE];
const POLICYSTATUS = [
  { v: '0', l: '投保中' },
  { v: '1', l: '承保生效' },
  { v: '4', l: '终止' },
];

/**
 * policy
 */
class Policy extends ReactRouterContextWidget {
  render() {
    return (
      <div
        className={this.getWidgetClassName('policy')}
        onClick={this.onPolicyClicked}
        ref="me"
      >
        <span id={this.getPolicyNo()}></span>
        <div className="content">
          <div className="title">
            <i className="icon-policy"></i>
            <span className="label-2">{this.getPolicyNoLabel()}：</span>
            <span className="text-2">{this.getPolicyNo()}</span>
            <span className="indicator right red">
              {this.getPolicyStatus()}
            </span>
            {/*<span className='indicator fa fa-angle-right' />*/}
          </div>
          <div className="main">
            <div className="item">
              {/*<span className='label-2'>{Lang.messages.policy.status}</span>				*/}
              <span className="label">{Lang.messages.policy.date}：</span>
              <span className="text">{this.getQuoteDate()}</span>
            </div>
            <div className="item">
              <span className="label">{Lang.messages.policy.product}：</span>
              <span className="text">{this.getProductName()}</span>
            </div>
            <div className="item">
              <span className="label-2">
                {Lang.messages.policy.policyHolder}：
              </span>
              <span className="text-2">{this.getPolicyHolderName()}</span>
            </div>
            <div className="item">
              <span className="label-2">{Lang.messages.policy.insured}：</span>
              <span className="text-2">{this.getCustomerName()}</span>
            </div>
            <div className="item">
              <span className="label">{Lang.messages.policy.premium}：</span>
              <span className="text red">{this.getPremium()}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  getPolicyNoLabel() {
    return this.isIssued()
      ? Lang.messages.policy.policyNo
      : Lang.messages.policy.quoteNo;
  }
  getCustomerLabel() {
    return Lang.messages.policy.customer;
  }
  getPolicyNo() {
    const policy = this.getPolicy();
    return policy.policyNo;
    // return policy.policyNo || policy.quoteNo || policy.quotationNo;
  }
  getPolicyPartyNames() {
    let policyHolderName = this.getPolicyHolderName();
    let customerName = this.getCustomerName();
    if (policyHolderName == customerName || !customerName) {
      return policyHolderName;
    } else {
      return [policyHolderName, customerName].join(', ');
    }
  }
  getPolicyHolderName() {
    // const policy = this.getPolicy();
    // return this.asName(policy.holderFirstName, policy.holderLastName).trim();
    return this.getPolicy().policyHolderName;
  }
  getCustomerName() {
    // const policy = this.getPolicy();
    // return this.asName(policy.customerFirstName, policy.customerLastName).trim();
    return this.getPolicy().insuredName;
  }
  asName(firstName, lastName) {
    if (Utils.hasOrientalChar(firstName) || Utils.hasOrientalChar(lastName)) {
      return (lastName || '') + (firstName || '');
    } else {
      return (firstName || '') + ' ' + (lastName || '');
    }
  }
  getPolicy() {
    return this.getPropsValue('policy');
  }
  isIssued() {
    let status = this.getPolicy().policyStatus + 0;
    // 1: quote; 3: waiting for payment
    return NOT_ISSUED.indexOf(status) === -1;
  }
  getPolicyId() {
    return this.getPolicy().policyId;
  }
  getVendorCode() {
    return this.getPolicy().vendorCode;
  }
  getProductCode() {
    return this.getPolicy().productCode;
  }
  getProductVersion() {
    return this.getPolicy().productVersion;
  }
  getViewUrl() {
    return this.getPolicy().viewUrl;
  }
  getProductName() {
    return this.getPolicy().coverageName;
  }
  getIssueDate() {
    return this.getPolicy().issueDate;
  }
  getQuoteDate() {
    return this.getPolicy().effectiveDate;
  }
  getPremium() {
    return this.getPolicy().premium;
  }
  getPolicyStatus() {
    let status = this.getPolicy().policyStatus;
    return this.getLabelById(status, POLICYSTATUS);
    // const statusKey = Object.keys(Consts.POLICY_STATUS).find((key) => {
    // 	return Consts.POLICY_STATUS[key] == status;
    // });
    // if (statusKey) {
    // 	return Lang.messages.common.policyStatus[statusKey];
    // } else {
    // 	return null;
    // }
  }
  //根据值返回对应label
  getLabelById(val, mapCodes) {
    if (!val || !mapCodes) {
      return '';
    }
    var code = mapCodes.find(function (value, index, arr) {
      return value.v === val;
    });
    return code ? code.l : '';
  }
  onPolicyClicked = (evt) => {
    // let url = this.getViewUrl();
    // if (!url) {
    // let url = Consts.PATH.MOBILE_ME_MY_ORDER_DETAIL //
    // .replace(':policyNo', this.getPolicyNo()) //
    // .replace(':vendorCode', this.getVendorCode()) //
    // .replace(':productCode', this.getProductCode()) //
    // .replace(':productVersion', this.getProductVersion());
    // url += `?from=${Utils.encodeCurrentURI()}`
    // } else {
    // there is a view url defined for this product
    // then use it
    // }

    // TODO 删掉下面这段, 测试用
    // url = Consts.PATH.MOBILE_POLICY_VIEW //
    // 	.replace(':vendorCode', this.getVendorCode()) //
    // 	.replace(':productCode', this.getProductCode()) //
    // 	.replace(':productVersion', this.getProductVersion());
    // url += `?from=${Utils.encodeCurrentURI()}`

    // 记录跳转的操作
    // Storage.ToDetail.lastAction({
    // 	vendor: this.getVendorCode(),
    // 	product: this.getProductCode(),
    // 	version: this.getProductVersion(),
    // 	action: 'view'
    // });

    // // 记录跳转的明细数据ID
    // Storage.ToDetail.lastDetailId(this.getPolicyId());
    // // 向上播送跳转
    // Utils.notifyParentShouldGoToDetail(this);
    // // console.log(url)
    // if (url.startsWith('http://') || url.startsWith('https://')) {
    // 	window.location.replace(url);
    // } else {
    this.pushRedirect({
      pathname: Consts.PATH.MOBILE_ME_MY_POLICY_DETAIL,
      policyNo: this.getPolicyNo(),
    });
    // this.pushRedirect({pathname: Envs.PATH.MOBILE_ME_MY_ORDER_DETAIL, policyId: model.policyId})

    // }
  };
}

export default Policy;
