import { React, ClassNames } from '../../../common/3rd';
import { PromisedWidget } from '../../widgets/widget';
import Alert from '../alert/alert';
import * as SthUtils from './something-utils';

/**
 * @type {
 *  React.Context<{
 *    emitter: object,
 *    root: object,
 *    codes: object,
 *    phases: object,
 *    product: object,
 *    rootComponent: object,
 *    declarations: object,
 *  }>
 * }
 */
const ComponentThingContext = React.createContext({
  /** 事件发射器 */
  emitter: undefined,
  /** 根模型 */
  root: undefined,
  /** 代码表 */
  codes: undefined,
  /** 不同阶段的组件列表 */
  phases: undefined,
  /** 产品模型 */
  product: undefined,
  /** 最外层父组件 */
  rootComponent: undefined,
  /** 告知表 */
  declarations: undefined,
});
ComponentThingContext.displayName = 'ComponentThingContext';
// 代码表关键字
const CODES_KEY = '_codes';
// 告知表关键字
const DECLARATIONS_KEY = '_declarations';
// 产品模型关键字
const PRODUCT_KEY = '_product';
//产品logo
const LOG_IMAGE = '_logImage';
//产品规则配置
const LIMIT_KEY = '_limit';

/**
 * 组件
 */
class ComponentThing extends PromisedWidget(React.Component) {
  static contextType = ComponentThingContext;

  // 初始化state对象
  state = {};
  constructor(props, context) {
    super(props, context);
    if (this.isParentRequired() && props.parent == null) {
      // eslint-disable-next-line
      throw {
        component: this,
        message: '[parent] in props cannot be null.',
      };
    }
  }
  isParentRequired() {
    return true;
  }
  // 实现lifecycle方法集
  UNSAFE_componentWillMount() {
    this.dismantleLifecycleStep('willMount');
  }
  componentDidMount() {
    this.dismantleLifecycleStep('didMount');
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.dismantleLifecycleStep('willReceiveProps', nextProps);
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    this.dismantleLifecycleStep(
      'shouldUpdate',
      nextProps,
      nextState,
      nextContext
    );
    return true;
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.dismantleLifecycleStep('willUpdate', nextProps, nextState);
  }
  componentDidUpdate(prevProps, prevState) {
    this.dismantleLifecycleStep('didUpdate', prevProps, prevState);
  }
  componentWillUnmount() {
    this.dismantleLifecycleStep('willUnmount');
  }
  /**
   * 将生命周期方法拆解为指定的步骤. 如果指定步骤对应的方法存在, 则执行, 否则忽略.
   * 方法参数使用后续的参数指定.
   * @param {*} stepName 步骤方法名
   */
  dismantleLifecycleStep(stepName) {
    let params = null;
    if (arguments.length !== 1) {
      params = Array.prototype.slice.call(arguments, 1);
    }

    const funcName = stepName.charAt(0).toUpperCase() + stepName.slice(1);
    ['pre' + funcName, 'do' + funcName, 'post' + funcName].forEach((name) => {
      const func = this[name];
      if (func) {
        if (params) {
          func.apply(this, params);
        } else {
          func.call(this);
        }
      }
    });
  }
  /**
   * didMount最后增加事件监听
   */
  postDidMount() {
    this.installDefaultIdToEventEmitter();
    this.installThisToPhase();
  }
  /**
   * willUpdate最前去掉事件监听
   */
  preWillUpdate(nextProps, nextState, nextContext) {
    this.uninstallFromEventEmitter({ event: this.getId() });
    this.uninstallThisFromPhase();
  }
  /**
   * didUpdate最后增加事件监听
   */
  postDidUpdate(prevProps, prevState, prevContext) {
    this.installDefaultIdToEventEmitter();
    this.installThisToPhase();
  }
  /**
   * willUnmount最前去掉事件监听
   */
  preWillUnmount() {
    this.uninstallFromEventEmitter({ event: this.getId() });
    this.uninstallThisFromPhase();
  }
  /**
   * 是否应该监听当前的id, 默认为true.
   * 有些组件因为本身仅负责非数据部分, 而数据部分交由内部其他组件处理, 此时应当重写此方法, 返回false
   */
  shouldInstallDefaultIdToEventEmitter() {
    return true;
  }
  /**
   * 注册监听当前的id
   * @param {function} func
   */
  installDefaultIdToEventEmitter(func) {
    if (this.shouldInstallDefaultIdToEventEmitter()) {
      this.installToEventEmitter({ event: this.getId(), func: func });
    }
  }
  /**
   * 监听指定的事件, 默认调用onEventEmitted方法
   *
   * @param {{event: string, func: function, model: any}} options
   */
  installToEventEmitter(options) {
    if (options && options.event) {
      this.getEventEmitter().on(
        options.event,
        options.func || this.onEventEmitted,
        options.model || this.getModel(),
        this
      );
    }
  }
  /**
   * 取消监听指定的事件. 默认取消onEventEmitted方法
   *
   * @param {{event: string, func: function, model: any}} options
   */
  uninstallFromEventEmitter(options) {
    if (options && options.event) {
      this.getEventEmitter().off(
        options.event,
        options.func || this.onEventEmitted,
        options.model || this.getModel(),
        this
      );
    }
  }
  installThisToPhase() {
    this.getPhases().forEach((phase) => {
      let array = this.getPhaseComponents()[phase];
      if (!array) {
        array = [];
        this.getPhaseComponents()[phase] = array;
      }
      if (array.indexOf(this) === -1) {
        array.push(this);
      }
    });
  }
  uninstallThisFromPhase() {
    this.getPhases().forEach((phase) => {
      if (this.getPhaseComponents()) {
        let array = this.getPhaseComponents()[phase];
        if (array) {
          const index = array.indexOf(this);
          if (index !== -1) {
            array.splice(index, 1);
          }
        }
      }
    });
  }
  /**
   * 事件触发, 默认刷新组件
   */
  onEventEmitted() {
    this.forceUpdate();
  }

  getModel() {
    let oModel = this.props.model;
    if (oModel && oModel.policyCustomers) {
      let oPolicyCustomers = oModel.policyCustomers;
      oPolicyCustomers.map((value, key) => {
        if (value.customerType == 2) {
          oModel.policyCustomers.splice(key, 1);
          oModel.policyCustomers.unshift(value);
        }
      });
    }
    return oModel;
  }
  getLayout() {
    return this.props.layout || {};
  }
  getClause() {
    return this.props.layout.clauseInfo || {};
  }
  getParent() {
    return this.props.parent;
  }
  getId() {
    return this.getLayout().id;
  }
  getLabel() {
    return this.getLayout().label;
  }
  /**
   * 获取横向占的列数
   */
  getColumnsGrabbed() {
    return this.getComponentPosition().grab || 1;
  }
  /**
   * 是否独占行剩下的所有空间.
   * 无论组件是否真的会占用这个空间. 如果返回true, 则下一个组件换行.
   */
  isRowGrabbed() {
    return this.getComponentPosition().grabRow;
  }
  /**
   * 获取组件位置
   *
   * @return {{row: numeric, col: numeric, grab: numeric, grabRow: boolean}}
   */
  getComponentPosition() {
    return this.getLayout().pos || {};
  }
  isVisible() {
    const visible = this.getLayout().visible;
    if (visible === false) {
      // 指定为false
      return false;
    } else if (visible === true) {
      return true;
    } else {
      const filters = visible
        ? Array.isArray(visible)
          ? visible
          : [visible]
        : [];
      if (filters.length === 0) {
        return true;
      } else {
        const appliedFilter = this.matchFilter(filters);
        if (appliedFilter) {
          // return appliedFilter.visible || appliedFilter.do;
          return true;
        } else {
          // 如果没有过滤器被匹配到, 不显示
          return false;
        }
      }
    }
  }
  getPhases() {
    const phase = this.getLayout().phase;
    const phases = phase || ['--all'];
    return Array.isArray(phases) ? phases : [phases];
  }
  /**
   * 从模型中获取值
   * @param {string} id 可以不指定, 则使用getId()的返回值
   */
  getValueFromModel(id) {
    return SthUtils.getValueFromModel(this.getModel(), id || this.getId());
  }
  /**
   * 设置指定值到模型中. 如果指定force, 则一定会设置; 否则会比较模型值与被设置的值, 相等则忽略此操作
   * 如果操作被忽略, 返回false
   *
   * @param {any} value
   * @param {function} callback
   * @param {boolean} force
   * @param {string} id 可选项, 如果没有, 则使用getId()
   */
  setValueToModel(value, callback, force, id) {
    const ret = SthUtils.setValueToModel(
      this.getModel(),
      id || this.getId(),
      value,
      force
    );
    if (ret !== false) {
      // 如果成功设置, 触发事件
      this.getEventEmitter().emit(id || this.getId(), {
        model: this.getModel(),
        from: this,
      });
    }
    if (callback) {
      // 回调
      callback.call(this);
    }
    return ret;
  }
  /**
   * 获取原生的属性, 这些属性将被直接放置到DOM上.
   * 不包括事件.
   * 属性class会被转换为className
   */
  getPrototypes() {
    const layout = this.getLayout();
    const prototypes = layout.prototypes || {};
    this.getPrototypeNames().forEach((name) => {
      prototypes[name] = layout[name];
    });
    prototypes.className = ClassNames(this.getDefaultClassName(), layout.class);
    return prototypes;
  }
  /**
   * 获取支持的原生属性名称, 不包含事件.
   * 指定的名称可以直接被定义到layout JSON下, 作为直接属性存在
   */
  getPrototypeNames() {
    return [];
  }
  /**
   * 获取默认的class
   */
  getDefaultClassName() {
    return null;
  }
  /**
   * 获取事件发射器
   */
  getEventEmitter() {
    return this.context.emitter;
  }
  /**
   * 获取根模型
   */
  getRootModel() {
    return this.context.root;
  }
  getProductModel() {
    return this.context.product;
  }
  /**
   * 获取最外层父组件
   */
  getRootComponent() {
    return this.context.rootComponent;
  }
  /**
   * 获取全局代码表
   *
   * @param {string} name
   */
  getGlobalCodes(name) {
    return name
      ? (this.context.codes || {})[name.toUpperCase()]
      : this.context.codes || {};
  }
  /**
   * 获取全局告知表
   */
  getGlobalDeclarations(name) {
    return name
      ? (this.context.declarations || {})[name.toUpperCase()]
      : this.context.declarations || {};
  }
  getPhaseComponents() {
    return this.context.phases;
  }
  /**
   * 获取指定phase的组件, 没有指定认为是"--all"
   *
   * @param {string|[string]} phase
   */
  getComponentsByPhase(phase) {
    const phases = phase ? (Array.isArray(phase) ? phase : [phase]) : ['--all'];
    return phases.reduce((components, phase) => {
      const phaseComponents = this.getPhaseComponents()[phase];
      if (phaseComponents) {
        phaseComponents.forEach((comp) => {
          if (components.indexOf(comp) === -1) {
            components.push(comp);
          }
        });
      }
      return components;
    }, []);
  }
  /**
   *
   * @param {string|[string]} phases
   * @return {promise|boolean} true when pass check, promise object when fail
   */
  checkByPhase(phases) {
    const components = this.getComponentsByPhase(phases);
    if (components) {
      const messages = components
        .map((comp) => {
          if (comp.doCheck) {
            return comp.doCheck.call(comp);
          } else {
            return null;
          }
        })
        .filter((msg) => {
          return msg != null;
        });
      if (messages.length !== 0) {
        return Alert.message(messages);
      }
    }
    return true;
  }
  /**
   * 获取过滤器. 如果没有定义, 返回空数组.
   * filter like属性的值, 一定是filter对象, 或者filter对象数组.
   * filter对象一定会包含
   * 		一个on属性作为条件
   * 		一个do属性
   *
   * @param propName
   * @return {[filter]}
   */
  getFiltersBy(propName) {
    const filter = this.getLayout()[propName];
    return filter ? (Array.isArray(filter) ? filter : [filter]) : [];
  }
  /**
   * 获取所有需要监听的过滤器的属性集合
   *
   * @param {[filter]} filters
   * @return {[string]}
   */
  getConcernedIdsForFilters(filters) {
    return filters
      ? SthUtils.getConcernedIdsOfFilters({
          filters: filters,
          root: this.getRootModel(),
          model: this.getModel(),
        })
      : [];
  }
  /**
   * 找到适配的过滤器
   *
   * @param {[filter]} filters
   * @return {filter} a matched filter or null when not found
   */
  matchFilter(filters) {
    return SthUtils.findMatchedFilter({
      filters: filters,
      root: this.getRootModel(),
      model: this.getModel(),
    });
  }
  /**
   * 装载过滤器相关的监听
   *
   * @param {string|[string]} filterPropNames
   */
  installFilterIds(filterPropNames) {
    this.withFilterIds(filterPropNames, this.installToEventEmitter);
  }
  /**
   * 卸载过滤器相关的监听
   *
   * @param {string|[string]} filterPropNames
   */
  uninstallFilterIds(filterPropNames) {
    this.withFilterIds(filterPropNames, this.uninstallFromEventEmitter);
  }
  withFilterIds(filterPropNames, func) {
    (Array.isArray(filterPropNames)
      ? filterPropNames
      : [filterPropNames]
    ).forEach((name) => {
      this.getConcernedIdsForFilters(this.getFiltersBy(name)).forEach((id) => {
        func.call(this, {
          event: id.prop,
          model: id.model,
        });
      });
    });
  }
  fireEvent(event, id) {
    if (!this.getLayout().evt) {
      return;
    }
    const eventName = this.getLayout().evt[event];
    if (eventName) {
      SthUtils.eventRule({
        event: eventName,
        value: this.getValueFromModel(id),
        model: this.getModel(),
        root: this.getRootModel(),
        comp: this,
      });
    }
  }
}

export {
  ComponentThingContext,
  CODES_KEY,
  DECLARATIONS_KEY,
  PRODUCT_KEY,
  LOG_IMAGE,
  ComponentThing,
  LIMIT_KEY,
};
