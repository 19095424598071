import { React, ClassNames, $ } from '../../../common/3rd';
import { Lang, Consts } from '../../../common/common';
import { Widget, ReactRouterContextWidget } from '@/component/components';
import { Button } from '../../components';

import './policy.css';
import layout from './layout.json';
import messages from './messages.json';
Lang.installMessages(messages, 'desk-policy');

/**
 * sort layout by keys, return array
 * @param {*} layout
 */
const sortKeys = function (layout) {
  return Object.keys(layout)
    .sort((key1, key2) => {
      const pos1 = layout[key1].position;
      const pos2 = layout[key2].position;
      const diff = pos1.row - pos2.row;
      return diff < 0 ? -1 : diff > 0 ? 1 : pos1.column - pos2.column;
    })
    .reduce((array, key) => {
      array.push(key);
      return array;
    }, []);
};

const formatFunctions = {
  left: function (value, params, model) {
    return value.substr(0, params);
  },
};

/**
 * render value
 * @param {*} value
 * @param {*} format
 * @param {*} model
 */
const renderValue = function (value, format, model, layout) {
  if (value == null) {
    return null;
  } else if (format) {
    return formatFunctions[format.by].call(this, value, format.params, model);
  } else if (layout.codes) {
    // TODO how to get from object when codes defines it?
    const from = Consts;
    const key = Object.keys(from[layout.codes.set]).find((key) => {
      // eslint-disable-next-line
      return Consts[layout.codes.set][key] == value;
    });

    return Lang.messages.common[layout.codes.lang][key];
  } else {
    return value;
  }
};

/**
 * render by layout
 * @param {*} model
 * @param {*} layout
 */
const renderByLayout = function (model, layout) {
  const keys = sortKeys(layout);
  let lastRow = 0;
  return keys.map((key, index) => {
    const config = layout[key];
    const width = config.position.width;
    const row = config.position.row;
    let className = ClassNames('form-row', width ? `grab-${width}` : null, {
      'new-row': row !== lastRow,
    });
    lastRow = row;
    return (
      <div className={className} key={`cell-${index}`}>
        {renderCell.call(this, model, config)}
      </div>
    );
  });
};

const filterValues = function (filter, values) {
  if (!filter) {
    return values;
  }
  return values.filter((item) => {
    const notMatchPropName = Object.keys(filter).find((propName) => {
      // eslint-disable-next-line
      return filter[propName] != item[propName];
    });
    if (notMatchPropName) {
      return false;
    } else {
      return true;
    }
  });
};

/**
 * render cell
 * @param {*} model
 * @param {*} config
 */
const renderCell = function (model, config) {
  const type = config.type;
  const isArray = config.array === true;
  switch (type) {
    case 'panel':
      const values = model[config.id] || model;
      if (isArray) {
        const filter = config.filter;
        return values.length
          ? filterValues.call(this, filter, values).map((item, index) => {
              return renderPanel.call(this, item, config.options, index);
            })
          : null;
      } else {
        return renderPanel.call(this, values, config.options, 0);
      }
    default:
      return renderLabel.call(this, model, config.options);
  }
};

/**
 * render panel
 * @param {*} model
 * @param {*} layout
 */
const renderPanel = function (model, layout, index) {
  return <Panel key={`panel-${index}`} model={model} layout={layout} />;
};

/**
 * render label for cell input
 * @param {*} model
 * @param {*} layout
 */
const renderInputLabel = function (model, layout) {
  return (
    <div className="form-label" key="label">
      <span>{Lang.messages['desk-policy'][layout.title]}</span>
    </div>
  );
};

/**
 * render cell as label
 * @param {*} model
 * @param {*} layout
 */
const renderLabel = function (model, layout) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <span>
        {renderValue.call(
          this,
          getValue(model, layout.id),
          layout.format,
          model,
          layout
        )}
      </span>
    </div>,
  ];
};

const getValue = function (model, id) {
  return id.split('.').reduce((model, id) => {
    if (model) {
      return model[id];
    } else {
      return null;
    }
  }, model);
};

/**
 * panel, 容器
 */
class Panel extends Widget {
  renderBody() {
    const layout = this.getLayout().body;
    if (layout) {
      return (
        <div className="policy-section-body">
          {renderByLayout.call(this, this.getModel(), layout)}
        </div>
      );
    }
  }
  render() {
    return (
      <div className="policy-section">
        <div className="policy-section-header">
          <div className="policy-section-header-title">
            <span>{this.getTitle()}</span>
          </div>
        </div>
        {this.renderBody()}
        <div className="policy-section-footer"></div>
      </div>
    );
  }
  getModel() {
    return this.props.model;
  }
  getLayout() {
    return this.props.layout;
  }
  getTitle() {
    return Lang.messages['desk-policy'][this.getLayout().title];
  }
}

/**
 * 保单
 */
class Policy extends ReactRouterContextWidget {
  constructor(props, context) {
    super(props, context);
    this.state.layout = layout;
    const productLayout = props.layout;
    if (productLayout) {
      this.state.layout = $.extend({}, layout, productLayout);
    }
  }
  render() {
    return (
      <div className="desk-policy desk-form four-cols no-border">
        <div className="form-body">
          {renderByLayout.call(this, this.getPolicy(), this.getLayout())}
        </div>
        <div className="form-footer">
          <div
            className="form-row product-form-button"
            style={{ float: 'right' }}
          >
            <div className="form-button">
              <Button className="form-btn waive" onClick={this.onCancelClicked}>
                {Lang.messages.common.back}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  onCancelClicked = () => {
    this.historyBack();
  };
  getPolicy() {
    return this.props.model;
  }
  getLayout() {
    return this.state.layout;
  }
}

export default Policy;
