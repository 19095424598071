import { React } from '../../../../common/3rd';
import { Envs, Ajax, Utils } from '../../../../common/common';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import Share from '../../../../mobile/share/share';
import { Alert, PageLoading, Something } from '../../../components';

/**
 * 电子签名页分享签名按钮
 * 例：电子签名页
 * add by susan.wu 2017 Nov.03
 *
 */
class ShareSignatureButton extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.agentSignStatus = false;
    this.policySignStatus = false;
    this.insuredSignStatus = false;
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.state.shareStatusChanged = 0;
    this.state.policyShareDate = null;
    this.state.insuredShareDate = null;
    this.sameWithProposer = false;
  }
  render() {
    this.currentSignaturePerson =
      this.props.parent.props.parent.state.currentSignaturePerson || 3;
    //判断投被保人是否为同一人
    this.sameWithProposer = false;
    this.getModel().policyCustomers.map((item, index, arr) => {
      if (item.customerType === '2') {
        if (item.extensionData.relationWithPH === '00') {
          this.sameWithProposer = true;
        }
      }
      return null;
    });
    let buttonClass = '';
    let shareType = this.getLayout().shareType;
    this.agentSignStatus = this.getSignStatus(3);
    this.policySignStatus = this.getSignStatus(1);
    this.insuredSignStatus = this.getSignStatus(2);
    if (shareType === 3) {
      //业务员签名按钮点亮条件 代理人进入并且代理人单证未签完
      buttonClass =
        !this.agentSignStatus && this.currentSignaturePerson === 3
          ? 'share-button share-ok'
          : 'share-button';
    } else if (shareType === 1) {
      //投保人分享签名按钮点亮条件 代理人进入并且代理人单证签完
      buttonClass =
        this.agentSignStatus &&
        !this.policySignStatus &&
        this.currentSignaturePerson === 3
          ? 'share-button share-ok'
          : 'share-button';
    } else if (shareType === 2) {
      if (this.sameWithProposer) {
        buttonClass = 'share-button button-hidden';
      } else {
        //投保人分享签名按钮点亮条件 代理人进入并且代理人单证，投保人单证签完
        buttonClass =
          this.agentSignStatus &&
          this.policySignStatus &&
          !this.insuredSignStatus &&
          this.currentSignaturePerson === 3
            ? 'share-button share-ok'
            : 'share-button';
      }
    }

    return (
      <div {...this.getPrototypes()} ref="me">
        <div className={buttonClass} onClick={this.shareSignature}>
          {this.getLayout().label}
        </div>
      </div>
    );
  }
  //判断是否签完所有单证
  getSignStatus(person) {
    let needSignedDocumentTypes = [];
    if (person === 3) {
      if (this.getModel().productId === 327) {
        needSignedDocumentTypes = this.getLayout().agentSignDocumentsToJAS;
      } else {
        needSignedDocumentTypes = this.getLayout().agentSignDocuments;
      }
    } else if (person === 1) {
      if (this.getModel().productId === 327) {
        needSignedDocumentTypes = this.getLayout().policSignDocumentsToJAS;
      } else {
        needSignedDocumentTypes = this.getLayout().policSignDocuments;
      }
    } else if (person === 2) {
      if (this.getModel().productId === 327) {
        needSignedDocumentTypes = this.getLayout().insuredSignDocumentsToJAS;
      } else {
        needSignedDocumentTypes = this.getLayout().insuredSignDocuments;
      }
    }
    let signedDocuments = this.getModel().extensionData.documents;
    let signedLength = 0;
    needSignedDocumentTypes.map((needSignedDocumentType) => {
      signedDocuments.map((signedItem) => {
        if (
          signedItem.documentType &&
          signedItem.documentType === needSignedDocumentType
        ) {
          signedLength = signedLength + 1;
        }
        return null;
      });
      return null;
    });
    // if(person === 1 && this.sameWithProposer) {
    // 	signedLength = signedLength + this.getLayout().insuredSignDocuments.length;
    // }
    if (signedLength >= needSignedDocumentTypes.length) {
      return true;
    } else {
      return false;
    }
  }
  shareSignature = () => {
    if (this.getLayout().shareType === 3 && this.agentSignStatus) {
      Alert.message('业务员签名已完成');
      return;
    }
    if (this.getLayout().shareType === 1 && this.policySignStatus) {
      Alert.message('投保人签名分享已完成');
      return;
    }
    if (this.getLayout().shareType === 2 && this.insuredSignStatus) {
      Alert.message('被保人签名分享已完成');
      return;
    }
    let accountId = Envs.findAccount().accountId;
    let policyId = this.getModel().policyId;
    const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
    if (this.getLayout().shareType === 1 || this.getLayout().shareType === 2) {
      PageLoading && PageLoading.start();
      this.doGet(url)
        .done((data) => {
          let { quoteInitData, templateInitData } = data.body;
          let policyModel = Something.merge(templateInitData, quoteInitData);
          this.props.parent.props.parent.setModel(policyModel);
          this.judgeStatus();
        })
        .always(() => {
          PageLoading.end();
        });
    } else {
      this.judgeStatus();
    }
  };
  judgeStatus() {
    //若代理人已签完名，则提示
    if (this.getLayout().shareType === 3 && this.agentSignStatus) {
      Alert.message('业务员签名已完成');
      return;
    }
    if (this.getLayout().shareType === 1 && !this.agentSignStatus) {
      Alert.message('请先完成业务员签名');
      return;
    }
    if (this.getLayout().shareType === 1 && this.policySignStatus) {
      Alert.message('投保人签名分享已完成');
      return;
    }
    if (this.getLayout().shareType === 2 && !this.agentSignStatus) {
      Alert.message('请先完成业务员签名');
      return;
    }
    if (this.getLayout().shareType === 2 && !this.policySignStatus) {
      Alert.message('请先完成投保人签名分享');
      return;
    }
    if (this.getLayout().shareType === 2 && this.insuredSignStatus) {
      Alert.message('被保人签名分享已完成');
      return;
    }
    if (this.getLayout().shareType === 3) {
      this.getRootComponent().jumpTo('documentList');
    } else if (
      this.getLayout().shareType === 1 ||
      this.getLayout().shareType === 2
    ) {
      // let authorization = Utils.doEncode(Ajax.getAuth());
      let title = '邀您完成投保签名';
      let thumbUrl = '';
      let proposerName = this.getModel().policyHolder.fullName;
      let insuredName = '';
      this.getModel().policyCustomers.map((customer, index) => {
        if (customer.customerType === '2') {
          insuredName = customer.fullName;
        }
        return null;
      });
      let targetName =
        this.getLayout().shareType === 1 ? proposerName : insuredName;
      let agentName = Envs.findAccount().nickname || '';
      let desc =
        '尊敬的客户' +
        targetName +
        '您好，' +
        agentName +
        '邀请您完成投保签名动作，感谢您的配合。';
      let accountId = Envs.findAccount().accountId;
      let policyId = this.getModel().policyId;
      // let productId = this.getModel().productId;
      let agentCode = Envs.findAccount().idNo || '';
      let agentMobile = Envs.findAccount().mobile || '';
      let agentNo = '';
      let accountName = Envs.findAccount().accountName;
      if (accountName) {
        let index = accountName.indexOf('.');
        agentNo = accountName.substring(index + 1, accountName.length);
      }
      let iStart = window.location.href.lastIndexOf('quote');
      let beforeUrl =
        window.location.href.substring(0, iStart) +
        'quote/edit/' +
        policyId +
        '/23';
      let shareUrl = `${beforeUrl}?isShare=1&accountId=${accountId}&shareType=${
        this.getLayout().shareType
      }&agentName=${accountName}&agentCode=${agentCode}&agentMobile=${agentMobile}&agentNo=${agentNo}`;
      let nowDate = new Date();
      let limitDate = new Date(nowDate.getTime() + 24 * 60 * 60 * 1000);
      let limitTime =
        limitDate.getFullYear() +
        '-' +
        (limitDate.getMonth() + 1) +
        '-' +
        limitDate.getDate() +
        ' 00:00:00';
      let param = {
        shareUrl: shareUrl,
        expire: limitTime,
        accountId: accountId,
        shareId: 'shareSignature' + this.getLayout().shareType + policyId,
        shareType: '0',
        authorization: Ajax.getAuth(),
      };
      //调用后端接口获取分享路径
      PageLoading && PageLoading.start();
      this.doPost('/rest/v1/share/sign', param)
        .done((data) => {
          if (data.resultCode === '0' && data.body) {
            let sign = data.body;
            let targetUrl = `${shareUrl}&sign=${sign}`;
            //添加微信分享
            let { thirdPartyReturnUrl } = Utils.getUrlProps();
            try {
              // if (thirdPartyReturnUrl && thirdPartyReturnUrl !== '') {
              //   let obj = {
              //     title: '测试分享',
              //     desc: '测试分享描述',
              //     shareUrl: targetUrl,
              //   };
              //   Utils.weixinClick(obj);
              // } else {
              Share.share(title, desc, targetUrl, thumbUrl);
              // }
            } catch (e) {
              //Alert.message(e);
            }
            // try {
            // 	Share.share(title, desc, targetUrl, thumbUrl);
            // } catch (e) {
            // 	console.error(e);
            // }
          } else if (data.resultCode === '1') {
            Alert.message('分享签名链接未过期，请继续完成签名！');
          }
        })
        .always(() => {
          PageLoading.end();
        });
    }
  }
  getDefaultClassName() {
    let className = 'sth-share-button-container';
    if (this.currentSignaturePerson !== 3) {
      className = className + ' share-button-container-hidden';
    }
    return className;
  }
}

export default ShareSignatureButton;
