// const wx = window.wx //
const WeixinJSBridge = window.WeixinJSBridge; // Bast regard, WeixinJSBridge what is build-in object has no effects on other browsers.

// @see http://www.cnblogs.com/Wayou/p/es6_new_features.html
// const CONFIG = Symbol('_config') // privatisation

/**
 * 微信支付管理
 *
 * @class WxPayManager
 */
export default class WxPayManager {
  // constructor(config) {
  //   if (!config) throw new Error('缺少权限验证配置，请注入。')
  //   this[CONFIG] = config
  //   this.initConfig(config)
  // }

  /**
   * 权限验证配置初始化
   *
   * @param {any} config
   * @memberof WxPayManager
   */
  // initConfig(config) {
  //   if (!wx) { throw new Error('缺少微信SDK，请导入。') }
  //   wx.config(config);
  // }

  /**
   * 发起支付
   *
   * @param {any} payArgs
   * @returns
   * @memberof WxPayManager
   */
  static async pay(config) {
    const res = await WeixinJSBridge.invoke('getBrandWCPayRequest', config);
    if (res.err_msg === 'get_brand_wcpay_request:ok') {
      return { hasError: false, message: '支付成功' };
    } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
      return { hasError: true, message: '支付取消' };
    } else {
      return { hasError: true, message: '支付失败' };
    }
  }
}
