import { Envs, Lang } from '@/common/common';
// const { supplement } = require('./FieldsSupplemented')
const ENV_WECHAT = 'wechat';
const ENV_MOBILE = 'mobile';

/**
 * 异步请求管理
 * // 08/01/2018 - TAG: by yanzhi.mo
 *
 * @class AsyncManager
 */
class AsyncManager {
  constructor(ctx) {
    this.ctx = ctx;
  }

  /**
   * 加载保单模型
   *
   * @memberof AsyncManager
   */
  loadQuote(options) {
    const { loading, something } = options;
    const accountId = Envs.findAccount().accountId;
    const policyId = this.ctx.props.parent.getModel().policyId;
    const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
    loading && loading.start();
    this.ctx
      .doGet(url)
      .done((data) => {
        let {
          quoteInitData,
          quoteJson,
          styleName,
          templateInitData,
          templateJson,
        } = data.body;
        let policyModel = something.merge(templateInitData, quoteInitData);

        policyModel.policyStatus = '80'; // 80-待支付 87-支付失败

        // 08/01/2018 - TAG: by yanzhi.mo - TODO: 模拟拉模型（含有expirationTime）
        // now.setMinutes(now.getMinutes() + 60) // 设置超时时间为60分钟
        // now.setSeconds(now.getSeconds() + 5) // 5秒钟，方便测试
        //edit by cheng.fan on 2018-04-24
        const checkDate = new Date(policyModel.autoCheckPassDate);
        const expirationTime = checkDate.setMinutes(
          checkDate.getMinutes() + 60
        ); // 设置超时时间为60分钟
        // const expirationTime = checkDate.setSeconds(checkDate.getSeconds() + 5) // 5秒钟，方便测试
        loading && loading.start();
        policyModel.extensionData.expirationTime = expirationTime;
        this.ctx
          .doPost('/rest/v1/policy/update', policyModel)
          .done((data) => {
            this.ctx.props.parent.props.parent.setModel(policyModel);
            this.ctx.props.parent.jumpTo('paymentEntries');
          })
          .always(() => {
            loading.end();
          });
      })
      .always(() => {
        loading.end();
      });
  }

  /**
   * 自动核保
   *
   * @param {any} model
   * @param {any} options
   * @memberof AsyncManager
   */
  automatic(policyModel, options) {
    // const model = supplement(policyModel)
    const model = policyModel;
    const { loading, something, alert, currEnv } = options;

    // 30mins expiration time
    /* const now = new Date()
    now.setMinutes(now.getMinutes() + 60) // 设置超时时间为60分钟
    model.extensionData.expirationTime = now.getTime() */

    loading && loading.start();
    this.ctx
      .doPost('/rest/v1/uw/automatic', model)
      .done((data) => {
        // data.body.resultCode = '11111' // 08/01/2018 - TAG: by yanzhi.mo - TODO: 模拟自核通过

        // 自核失败
        if (data.body && data.body.resultCode === '99999') {
          model.policyStatus = '82';
          alert.message(`${data.body.reasons[0]}`);
        } else if (data.body && data.body.resultCode === '11111') {
          //自核成功
          this.loadQuote({ loading, something });
        } else {
          alert.message('数据异常');
        }
      })
      .always(() => {
        loading.end();
      });
  }

  /**
   * 出单
   *
   * @param {any} model
   * @param {any} options
   * @memberof AsyncManager
   */
  issue(policyModel, options) {
    const { parent } = this.ctx.props;
    const { loading } = options;

    loading && loading.start();
    this.ctx
      .doPost('/rest/v1/policy/issue', policyModel)
      .done((data) => {
        // data.resultCode = "1" // 08/01/2018 - TAG: by yanzhi.mo - TODO: 模拟投保成功

        if (data.resultCode == '1') {
          //成功
          parent.setModel(data.body);
          parent.jumpTo('successfulInsurance'); //投保成功
        } else {
          //赋值投保失败原因
          parent.props.model.extensionData.paymentInfo.insuranceMsg =
            data.resultMsg;
          parent.jumpTo('failureForInsurance'); //投保失败
        }
      })
      .always(() => {
        loading && loading.end();
      });
  }

  /**
   * 银行卡支付
   *
   * @param {any} model
   * @param {any} options
   * @memberof AsyncManager
   */
  paidByBankCard(model, options) {
    const { parent } = this.ctx.props;
    const { loading } = options;
    loading.start();
    //初始设置支付状态为‘待支付’
    if (!model.policyStatus) {
      model.policyStatus = '3';
    }
    this.ctx
      .doPost('/rest/v1/payment/pay', model)
      .done((data) => {
        //paymentStatus 0支付失败 2支付中  统一指向收费失败页
        if (data.paymentStatus == 0) {
          var msg = Lang.messages.payment[data.paymentCode];
          parent.props.model.extensionData.paymentInfo.paymentMsg = msg;
          parent.jumpTo('paymentFailure');
        } else if (data.paymentStatus == 2) {
          parent.jumpTo('paymentIng');
        } else if (data.paymentStatus == 1) {
          //支付成功调用出单接口
          this.issue(model);
        }
      })
      .always(() => {
        loading.end();
      });
  }

  /**
   * 微信支付
   *
   * @param {any} model
   * @param {any} options
   * @memberof AsyncManager
   */
  vbaoWeixinPayConfig(model, openid, options) {
    const { parent } = this.ctx.props;
    const { loading } = options;
    loading.start();
    this.ctx
      .doPost(`/rest/v1/payment/vbaoWeixinPayConfig?openId=${openid}`, model)
      .done((data) => {
        if (data.code == '00000') {
          // 16/01/2018 - TAG: by yanzhi.mo - 获取成功
          let config = { ...data.body, package: data.body.packageStr };
          delete config.packageStr;
          return { hasError: false, message: null, content: { config } };
        } else {
          // 16/01/2018 - TAG: by yanzhi.mo - 获取失败
          return { hasError: true, message: '获取微信支付配置信息失败 ' };
        }
      })
      .always(() => {
        loading.end();
      });
  }

  /**
   * 获取验证码（银行卡支付）
   *
   * @param {any} reqBody
   * @param {any} options
   * @memberof AsyncManager
   */
  paymentSmsCalled(reqBody, options) {
    const { loading, alert } = options;

    loading.start();
    //发送验证码
    this.doPost('/rest/v1/sms/send', reqBody)
      .done((data) => {
        if (data && data.resultCode == 'suc') {
          // 11/01/2018 - TAG: by yanzhi.mo - 获取成功
        } else if (data && data.resultCode === 'manytimes') {
          // 11/01/2018 - TAG: by yanzhi.mo - 获取超时
        } else if (data && data.resultCode === 'often') {
          alert.message('您操作过于频繁，请稍后再试！');
          return;
        } else {
          // 11/01/2018 - TAG: by yanzhi.mo - 获取失败
        }
      })
      .always(() => {
        loading.end();
      });
  }
}

export default AsyncManager;
