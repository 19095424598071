import { React, ClassNames } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import Product from './product';

import './product.css';
import plainImage from '../../../images/plain-product.png';

/**
 * fav product
 */
class FavProduct extends Product {
  getImageUrl(key) {
    let image = super.getImageUrl(key);
    return image ? image : plainImage;
  }
  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.state.selected = false;
  }
  render() {
    let selected = this.isSelected();
    let className = ClassNames('left-edit-region', {
      selected: selected,
    });
    return (
      <div
        className={this.getWidgetClassName('product fav')}
        onClick={this.onFavProductClicked}
      >
        <div className={className} />
        <div className="right-product-region">
          <span data-id={this.getProductUniqueKey()} />
          <div className="img">
            <img src={this.getImageUrl('image3')} alt="" />
          </div>
          <div className="title">
            <span>{this.getProductName()}</span>
          </div>
          <div className="slogan">
            <span>{this.getSlogan1()}</span>
            <span>{this.getSlogan2()}</span>
          </div>
          <div className="price">
            <span>{this.getCurrencySign()}</span>
            <span>{this.getMinPrice()}</span>
            <span>{Lang.messages.product.priceStarts}</span>
          </div>
        </div>
      </div>
    );
  }
  onFavProductClicked = () => {
    if (this.isSelectable()) {
      this.setSelected(!this.isSelected());
      return;
    }

    this.onProductClicked();
  };
}

export default FavProduct;
