/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 工具
 * @author LiuH
 */

/**
 * 解析query string
 * @param {string} query
 * @returns {null|object}
 */
function parseQueryString(query) {
  const parts = query.substring(1).split('&');
  const params = Object.create(null);
  for (let i = 0, ii = parts.length; i < ii; ++i) {
    // 仅在 = 第一次出现时拆分
    const param = parts[i].split(/=(.*)/);
    const key = param[0] ? decodeURIComponent(param[0]) : param[0];
    let value = null;
    if (param.length > 1) {
      value = param[1] ? decodeURIComponent(param[1]) : param[1];
    }
    params[key] = value;
  }
  return params;
}

/**
 * object转url query string
 * @param {object} obj
 * @param {boolean} [ignore=true] 是否忽略undefined、null、falsy值，默认true
 */
function obj2query(obj, ignore = true) {
  return obj
    ? Object.entries(obj).reduce((queryString, [key, val], index) => {
        const symbol = queryString.length === 0 ? '?' : '&';
        if (ignore) {
          queryString += val
            ? `${symbol}${encodeURIComponent(key)}=${encodeURIComponent(
                String(val)
              )}`
            : '';
        } else {
          queryString += `${symbol}${encodeURIComponent(
            key
          )}=${encodeURIComponent(String(val))}`;
        }
        return queryString;
      }, '')
    : '';
}

/**
 * 加载js
 * @param {string} src
 * @returns {Promise<any>}
 */
function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function (res) {
      resolve(res);
    };
    script.onerror = function (error) {
      reject(error);
    };
    script.src = src;
    // document.getElementById('root').insertAdjacentElement('beforebegin', script);
    const heads = document.getElementsByTagName('head');
    heads?.length > 0
      ? heads[0].appendChild(script)
      : document.documentElement.appendChild(script);
  });
}

/**
 * 深冻结函数
 * @param {object} obj
 * @return {object}
 */
function deepFreeze(obj) {
  // 取回定义在obj上的属性名
  let propNames = Object.getOwnPropertyNames(obj);
  // 在冻结自身之前冻结属性
  propNames.forEach(function (name) {
    let prop = obj[name];
    // 如果prop是个对象，冻结它
    if (typeof prop == 'object' && prop !== null) deepFreeze(prop);
  });
  // 冻结自身(no-op if already frozen)
  return Object.freeze(obj);
}

/**
 * 防抖
 * @param {function} callback
 * @param {number} delay
 * @returns
 */
function debounce(callback, delay) {
  return function (...args) {
    if (Object.prototype.hasOwnProperty.call(callback, 'timeId')) {
      clearTimeout(callback.timeId);
    }

    callback.timeId = setTimeout(() => {
      callback.call(this, ...args);
      delete callback.timeId;
    }, delay);
  };
}

/**
 * 节流
 * @param {function} callback
 * @param {number} delay
 */
function throttle(callback, delay) {
  let pre = 0;
  return function (event) {
    const current = Date.now();
    if (current - pre > delay) {
      callback.call(this, event);
      pre = current;
    }
  };
}

/**
 * 米转千米
 * @param {string|number} meter
 * @returns
 */
function meter2KM(meter, fraction = 3) {
  let m = String(meter);
  m = Number(m);
  return (m / 1000).toFixed(fraction);
}

export {
  parseQueryString,
  obj2query,
  loadScript,
  deepFreeze,
  debounce,
  throttle,
  meter2KM,
};
