import { React, $, ClassNames } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';
import { provsData, citysData, distsData } from '../../../../common/larea-data';
import { occupationMapClass } from '../../../../common/occupation-map-class';

class LabelText extends AtomicComponentThing {
  getValueFromCodes(codes, value) {
    if (!codes) {
      return { l: '未知' };
    }
    codes = codes.toUpperCase();
    const globalCodes = this.getGlobalCodes();
    return globalCodes[codes].find((item) => {
      return item.v + '' === value + '';
    });
  }

  renderValue() {
    const unit = this.getLayout().unit;
    let value = this.getValueFromModel();
    const codes = this.getLayout().codes;
    const isToggle = this.getLayout().isToggle;
    const isAddress = this.getLayout().isAddress;
    const isJobCategory = this.getLayout().isJobCategory;
    if (isToggle) {
      return <div className="sth-label-value">{value ? '是' : '否'}</div>;
    }
    if (isAddress) {
      if (typeof value == 'object') {
        //naiting 2018/2/27
        if (value.provinceCode && value.cityCode && value.regionCode) {
          let prov = provsData.find((prov) => {
            return prov.v == value.provinceCode;
          });
          let value1 = prov && prov.l;

          let city = citysData[value.provinceCode].find((city) => {
            return city.v == value.cityCode;
          });
          let value2 = city && city.l;

          let dist = distsData[value.cityCode].find((dist) => {
            return dist.v == value.regionCode;
          });
          let value3 = dist && dist.l;
          if (
            value.provinceCode == '110000' ||
            value.provinceCode == '120000' ||
            value.provinceCode == '310000' ||
            value.provinceCode == '500000'
          ) {
            value = value1 + value3;
          } else {
            value = value1 + value2 + value3;
          }
        } else {
          value = '未知';
        }
      } else if (this.getModel().customerType == 2) {
        value = this.getModel();
        if (value.provinceCode && value.cityCode && value.regionCode) {
          let prov = provsData.find((prov) => {
            return prov.v == value.provinceCode;
          });
          let value1 = prov && prov.l;

          let city = citysData[value.provinceCode].find((city) => {
            return city.v == value.cityCode;
          });
          let value2 = city && city.l;

          let dist = distsData[value.cityCode].find((dist) => {
            return dist.v == value.regionCode;
          });
          let value3 = dist && dist.l;
          if (
            value.provinceCode == '110000' ||
            value.provinceCode == '120000' ||
            value.provinceCode == '310000' ||
            value.provinceCode == '500000'
          ) {
            value = value1 + value3;
          } else {
            value = value1 + value2 + value3;
          }
        } else {
          value = '未知';
        }
      } else {
        if (value) {
          let prov = provsData.find((prov) => {
            return prov.v == value;
          });
          value = prov && prov.l;
        }
      }
      return <div className="sth-label-value">{value ? value : '未知'}</div>;
    }
    if (isJobCategory) {
      if (value) {
        value = occupationMapClass[value] && occupationMapClass[value].label;
      }
      return <div className="sth-label-value">{value ? value : '未知'}</div>;
    }
    if (value === undefined || value === null || value === -1 || value === '') {
      return <div className="sth-label-value">未知</div>;
    } else if (!codes) {
      if (unit == '%' && /^\d\.\d\d$/.test(value)) {
        value = parseInt(value * 100);
      }
      if (typeof value === 'string') {
        value = value.replace(/\s\d\d\:\d\d\:\d\d/, '');
      }
      return (
        <div className="sth-label-value">
          {value}
          {unit}
        </div>
      );
    } else {
      let codeValue = this.getValueFromCodes(codes, value);
      codeValue = (codeValue && codeValue.l) || '未知';
      return <div className="sth-label-value">{codeValue}</div>;
    }
  }

  render() {
    return (
      <div {...this.getPrototypes()} ref="me">
        <div className="sth-label-name">{this.getLayout().label}：</div>
        {this.renderValue()}
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-label-text';
  }

  getPanelLayout() {
    return this.getLayout();
  }
}

export default LabelText;
