import { React, ClassNames, $ } from '../../../../common/3rd';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import AtomicCodesComponentThing from '../atomic-codes-component';

/**
 * toggle
 */
class Toggle extends AtomicMutableComponentThing {
  render() {
    return (
      <span {...this.getPrototypes()} onClick={this.onClick} ref="me">
        <div className="sth-toggle-true">是</div>
        <div className="sth-toggle-false">否</div>
      </span>
    );
  }
  getDefaultClassName() {
    return ClassNames('sth-toggle', {
      'sth-toggle-checked': this.getValueFromModel(),
    });
  }
  setValueToDOM(value) {
    this.me().attr({ 'data-value': value });
  }
  getValueFromDOM() {
    const value = this.me().attr('data-value');
    return value === 'true' ? true : false;
  }
  setValueToModel(value, callback, force, id) {
    if (super.setValueToModel(value, callback, force, id) !== false) {
      this.forceUpdate();
    }
  }
  onClick = (evt) => {
    if (this.getValueFromModel()) {
      this.setValueToDOM(null);
      this.me().trigger($.Event('change', { value: false }));
    } else {
      this.setValueToDOM(true);
      this.me().trigger($.Event('change', { value: true }));
    }
    this.getRootComponent().forceUpdate();
  };
}

/**
 * 有标签的toggle, 只能有两个标签.
 * 第一个标签代表false, 第二个标签代表true. 实际设置到模型中的值是给定代码表的值
 */
class LabelToggle extends AtomicCodesComponentThing {
  doDidMount() {
    const value = this.getValueFromModel();
    if (!value) {
      super.setValueToModel(this.getCodes()[0].v);
    }
    super.doDidMount();
    this.forceUpdate();
  }
  render() {
    return (
      <span {...this.getPrototypes()} onClick={this.onClick} ref="me">
        {this.getCodes().map((code, codeIndex) => {
          return <span key={codeIndex}>{code.l}</span>;
        })}
      </span>
    );
  }
  getDefaultClassName() {
    return ClassNames('sth-toggle sth-label-toggle', {
      // eslint-disable-next-line
      'sth-toggle-checked': this.getValueFromModel() == this.getCodes()[0].v,
    });
  }
  setValueToDOM(value) {
    this.me().attr({ 'data-value': value });
  }
  getValueFromDOM() {
    return this.me().attr('data-value');
  }
  setValueToModel(value, callback, force, id) {
    if (super.setValueToModel(value, callback, force, id) !== false) {
      this.forceUpdate();
    }
  }
  onClick = (evt) => {
    const currentValue = this.getValueFromModel();
    let nextValue = null;
    if (currentValue) {
      nextValue = this.getCodes().filter((code) => {
        // eslint-disable-next-line
        return code.v != currentValue;
      })[0].v;
    } else {
      nextValue = this.getCodes()[1].v;
    }
    this.setValueToDOM(nextValue);
    this.me().trigger($.Event('change', { value: nextValue }));
    this.getRootComponent().forceUpdate();
  };
}

export { Toggle, LabelToggle };
