import { React } from '../../../common/3rd';
import utils from '../../../common/utils';
import { Alert } from '../../components';
import './anysign/anysign.css';
import './anysign/canvas_css.css';
import './anysign/mw480Portrait.css';
import './anysign/sp.css';
// let apiInstance = null;
// let prop = null;
/**
 * Created by wendy.gu on 2017 Nov.3
 * web签名
 */
class AnysignView extends React.Component {
  // constructor(props) {
  // 	super(props);
  // }

  resetPro = () => {
    this.apiInstance = null;
  };
  componentDidMount = () => {
    this.resetPro();
  };
  render = () => {
    return (
      <div id="anysign-container">
        {this.renderDialog()}
        {this.renderCommentDialog()}
      </div>
    );
  };
  renderDialog = () => {
    let dialogStr =
      '<div id="dialog" style=" display:none;">' +
      '<div id="anysign_title" style="color:#333333;" width="100%" height="10%"></div>' +
      '<div id="container" onmousedown="return false;">' +
      '<canvas id="anysignCanvas" width="2"></canvas>' +
      '</div>' +
      '<div id="single_scrollbar" style="text-align: center;  vertical-align:middle; margin-top: 10px;" width="100%">' +
      '<span id="single_scroll_text" class="underButtonNew" style="display: inline-block; margin-top: 10px; font-size:15px;"> *滑动操作：</span>' +
      '<input id="single_scrollbar_up" type="button" class="button orange underButtonNew" value="左移" />' +
      '<input id="single_scrollbar_down" type="button" class="button orange underButtonNew" value="右移" />' +
      '</div>' +
      '<div id="btnContainerOuter" width="100%">' +
      '<div id="btnContainerInner" style="text-align: center;   font-size:3pt;" width="100%">' +
      '<input id="btnOK" type="button" class="button orange underButtonNew" value="确 定" onclick="sign_confirm();" />' +
      '<input id="btnClear" type="button" class="button orange underButtonNew" value="清 屏" onclick="javascript:clear_canvas();">' +
      '<input id="btnCancel" type="button" class="button orange underButtonNew" value="取 消" onclick="cancelSign();">' +
      '</div>' +
      '</div>' +
      '</div>';
    return <div dangerouslySetInnerHTML={{ __html: dialogStr }} />;
  };
  renderCommentDialog = () => {
    let commentDialogStr =
      '<div id="comment_dialog" style="display:none;">' +
      '<div id="leftView">' +
      '<p id="comment_title"></p>' +
      '<div id="signImage" class="signImagecss"></div>' +
      '</div>' +
      '<div id="tmpcanvascss" class="tmpcanvascss">' +
      '<div id="signTitle"></div>' +
      '<canvas id="comment_canvas"></canvas>' +
      '</div>' +
      '<div id="comment_btnContainerInner" class="comment_btncontainer">' +
      '<input id="comment_ok" type="button" class="btn_orange underButtonNew" value="确 定">' +
      '<input id="comment_back" type="button" class="btn_orange underButtonNew" value="后退">' +
      '<input id="comment_cancel" type="button" class="btn_orange underButtonNew" value="取 消">' +
      '</div>' +
      '</div>';
    return <div dangerouslySetInnerHTML={{ __html: commentDialogStr }} />;
  };
  /**
   * SDK 初始化接口
   * @param templateData html文本模板
   * @param channel 默认 999999
   * @param orderId 订单ID
   */
  initAnySignSDK = (opt) => {
    let self = this;
    let templateData = opt.model || '<div></div>';
    let channel = opt.no;
    let orderId = opt.proposalCode || '0';
    //投被保人风险语录签名配置 add by guYY
    let signCfg = opt.signCfg || [null, null, null];
    let res;
    //设置签名算法，设置成SM2
    window.EncAlgType.EncAlg = process.env.REACT_APP_ANYSIGN_ALGORITHM;
    self.apiInstance = new window.AnySignApi();
    //初始化签名接口
    res = self.apiInstance.initAnySignApi(self.initAnySignSDKCallBack, channel);
    if (!res) {
      Alert.message('初始化接口错误!');
      return;
    }
    /**
     * 设置表单数据
     * @param template_type 签名所用的模板类型
     * @param contentUtf8Str 表单数据，类型为Utf8字符串
     * @param businessId 业务工单号
     * @param template_serial 模板序列号
     * @returns {*} 是否设置成功
     */
    res = self.apiInstance.setTemplate(
      window.TemplateType.HTML,
      templateData,
      orderId,
      orderId
    );
    if (res) {
      if (signCfg[0]) {
        self.addSignatureObj(
          '25',
          signCfg[0].name,
          signCfg[0].certiCode,
          signCfg[0].keyWord,
          parseInt(signCfg[0].direction, 10),
          parseInt(signCfg[0].directionNo, 10)
        );
        // var signInsure = self.addSignatureObj("25",signCfg[0].name, signCfg[0].certiCode, signCfg[0].keyWord, parseInt(signCfg[0].direction), parseInt(signCfg[0].directionNo));
      }
      if (signCfg[1]) {
        self.addSignatureObj(
          '26',
          signCfg[1].name,
          signCfg[1].certiCode,
          signCfg[1].keyWord,
          parseInt(signCfg[1].direction, 10),
          parseInt(signCfg[1].directionNo, 10)
        );
        // var signInsured = self.addSignatureObj("26", signCfg[1].name, signCfg[1].certiCode, signCfg[1].keyWord, parseInt(signCfg[1].direction), parseInt(signCfg[1].directionNo));
      }

      //var signComment = self.addCommentObj("30","马金龙","XXXXXXXXXXXXXXXXXX","投保人确认栏:",2,50,"本人已阅读保险条款、产品说明书和投保提示书，了解本产品的特点和保单利益的不确定性。");
      if (signCfg[2]) {
        self.addCommentObj(
          '30',
          signCfg[2].name,
          signCfg[2].certiCode,
          signCfg[2].keyWord,
          parseInt(signCfg[2].direction, 10),
          parseInt(signCfg[2].directionNo, 10),
          signCfg[2].commmentMsg
        );
        // var signComment = self.addCommentObj("30",  signCfg[2].name,  signCfg[2].certiCode, signCfg[2].keyWord, parseInt(signCfg[2].direction), parseInt(signCfg[2].directionNo), signCfg[2].commmentMsg);
      }
      if (signCfg[3]) {
        self.addSignatureObj(
          '27',
          signCfg[3].name,
          signCfg[3].certiCode,
          signCfg[3].keyWord,
          parseInt(signCfg[3].direction, 10),
          parseInt(signCfg[3].directionNo, 10)
        );
        // var signAgent = self.addSignatureObj("27", signCfg[3].name, signCfg[3].certiCode, signCfg[3].keyWord, parseInt(signCfg[3].direction), parseInt(signCfg[3].directionNo));
      }
      res = self.apiInstance.commitConfig();
      if (res) {
        this.props &&
          this.props.initialSdkCall &&
          this.props.initialSdkCall({ status: true });
      } else {
        this.props &&
          this.props.initialSdkCall &&
          this.props.initialSdkCall({ status: true });
      }
    }
  };
  //sdk初始化回调函数
  initAnySignSDKCallBack = (context_id, context_type, val) => {
    // let self = this;
    /**
     * context_id =25 为投保人签名
     * context_id =26 为被保人签名
     * context_id =27 为代理人签名
     * context_id =30 为批注
     **/
    if (
      context_type === window.CALLBACK_TYPE_START_RECORDING ||
      context_type === window.CALLBACK_TYPE_STOP_RECORDING
    ) {
      return;
    }
    if (context_type === window.CALLBACK_TYPE_SIGNATURE) {
      if (context_id === '25') {
        this.props &&
          this.props.policySignCall &&
          this.props.policySignCall({ camera: '', base: val });
      } else if (context_id === '26') {
        this.props &&
          this.props.insuredSignCall &&
          this.props.insuredSignCall({ base: val });
      } else if (context_id === '27') {
        this.props &&
          this.props.agentSignCall &&
          this.props.agentSignCall({ base: val });
      }
    } else if (context_type === window.CALLBACK_TYPE_COMMENTSIGN) {
      if (context_id === '30') {
        // app.triggerMethod("common:add:commmentSign",{base:val});  ////todo wendy 11.3
      }
    } else if (context_type === window.CALLBACK_TYPE_ON_PICTURE_TAKEN) {
    } else if (context_type === window.CALLBACK_TYPE_ON_MEDIA_DATA) {
    }
  };
  /**
   *新增签字对象
   *@param contextId   上下文对象
   *@param name        投保人姓名
   *@param idNo        证件号码
   *@param keyWord     关键字
   *@param xOffset     偏移方向  1:center，2:lower，3:right，4:lower-right
   *@param yOffset     偏移量
   */
  addSignatureObj = (contextId, name, idNo, keyWord, xOffset, yOffset) => {
    let self = this;
    let context_id = contextId;
    let signer = new window.Signer(name, idNo);
    /**
     *根据关键字定位签名位置
     * @param keyWord 关键字字面值
     * @param xOffset X轴偏移量，适配关键字和规则
     * @param yOffset Y轴偏移量，适配关键字和规则
     * @param pageNo 签名在PDF中的页码，第几页查找关键字，正数为正序，当是负数为倒序
     * @param KWIndex KWIndex 第几个关键字
     * @constructor
     */
    let signerRule = new window.SignRule_KeyWord(
      keyWord,
      xOffset,
      yOffset,
      0,
      1
    );
    let signatureConfig = new window.SignatureConfig(signer, signerRule);
    //签字框标题
    if (contextId === '25') {
      signatureConfig.title = '请签名';
    } else if (contextId === '26') {
      signatureConfig.title = '请被保人' + name + '签名';
    } else if (contextId === '27') {
      signatureConfig.title = '请代理人' + name + '签名';
    }
    //设置签名图片宽度
    signatureConfig.singleWidth = 70;
    //设置签名图片高度
    signatureConfig.singleHeight = 70;
    //设置签名笔迹颜色，默认为黑色
    //signatureConfig.penColor = "#FF0000";
    //是否开始时间戳服务
    signatureConfig.isTSS = false;
    signatureConfig.signatureImgRatio = 3.0;
    //签名是否必须,设置为true时必须进行签名
    signatureConfig.nessesary = false;
    let res = self.apiInstance.addSignatureObj(context_id, signatureConfig);
    return res;
  };
  /**
   *新增批注对象
   *@param contextId  上下文对象
   *@param name       投保人姓名
   *@param idNo       证件号码
   *@param keyWord    关键字
   *@param xOffset    偏移方向  1:center，2:lower，3:right，4:lower-right
   *@param yOffset    偏移量
   *@param commentStr 批注信息
   */
  addCommentObj = (
    contextId,
    name,
    idNo,
    keyWord,
    xOffset,
    yOffset,
    commentStr
  ) => {
    let self = this;
    let context_id = contextId;
    let signer = new window.Signer(name, idNo);
    /**
     * 关键字定位方式，寻找PDF中的关键字，根据关键字位置放置签名图片
     * @param keyword 关键字
     * @param keyWordAlignMethod 签字图片和关键字位置关系：等于1时，签字图片和关键字矩形重心重合
     *                           等于2时，签字图片位于关键字正下方，中心线对齐；等于3时，签字图片位于关键字正右方，中心线对齐；
     *                           等于4时，签字图片左上角和关键字右下角重合，可能额外附加偏移量，详见构造函数的offset参数
     * @param keyWordOffset 当keyWordAlignMethod非零时，额外附加的偏移量，单位pt
     * @param pageNo 寻找关键字的PDF起始页码
     * @param KWIndex KWIndex 第几个关键字
     * @constructor
     */
    let signerRule = new window.SignRule_KeyWord(
      keyWord,
      xOffset,
      yOffset,
      1,
      1
    );
    let commentConfig = new window.CommentConfig(signer, signerRule);
    //是否开始时间戳服务
    commentConfig.isTSS = false;
    //批注内容
    commentConfig.commitment = commentStr;
    //生成的签名图片中单个字的高
    commentConfig.mass_word_height = 50; //140; update by guYY
    //生成的签名图片中单个字的宽
    commentConfig.mass_word_width = 50; //140; update by guYY
    //生成的签名图片中单行显示的字数
    commentConfig.mass_words_in_single_line = 22; //20; update by guYY
    //设置笔迹颜色，默认为黑色
    //commentConfig.penColor = "#FF0000";
    //是否为必签项
    commentConfig.nessesary = false;
    let res = self.apiInstance.addCommentObj(context_id, commentConfig);
    return res;
  };
  /**
   *签字弹出框
   * 投保人签名 contextId 25
   */
  showSignatureDialog = (contextId) => {
    let self = this;
    if (!self.apiInstance) {
      Alert.message('接口没有初始化!');
      return;
    }
    switch (self.apiInstance.showSignatureDialog(contextId)) {
      case window.RESULT_OK:
        break;
      case window.EC_API_NOT_INITED:
        Alert.message('接口没有初始化!');
        break;
      case window.EC_WRONG_CONTEXT_ID:
        Alert.message('没有配置相应contextId的签字对象!');
        break;
      default:
        break;
    }
  };
  /**
   *批注弹出框
   */
  showCommentDialog = (contextId) => {
    let self = this;
    if (!self.apiInstance) {
      Alert.message('信手书接口没有初始化');
      return;
    }
    switch (self.apiInstance.showCommentDialog(contextId)) {
      case window.RESULT_OK:
        break;
      case window.EC_API_NOT_INITED:
        Alert.message('信手书接口没有初始化');
        break;
      case window.EC_WRONG_CONTEXT_ID:
        Alert.message('没有配置相应contextId的签字对象');
        break;
      case window.EC_COMMENT_ALREADY_SHOW:
        Alert.message('批注签名框已弹出，请勿重复操作！');
        break;
      default:
        break;
    }
  };
  genEncData = () => {
    let self = this;
    try {
      let resValue = self.apiInstance.getUploadDataGram();
      resValue = utils.doEncode(resValue);
      this.props &&
        this.props.jsonCall &&
        this.props.jsonCall({ jsonStr: resValue });
    } catch (err) {
      //update by guYY 2017 Jun.19
      this.props && this.props.jsonCall && this.props.jsonCall({ jsonStr: 0 });
    }
  };
  //拍照添加图片证据
  addEvidenceObj = (contextId, data) => {
    let self = this;
    self.apiInstance.addEvidence(
      contextId,
      data,
      window.DataFormat.IMAGE_JPEG,
      window.BioType.PHOTO_SIGNER_IDENTITY_CARD_BACK,
      0
    );
  };
  //图片(base64) 转canvas压缩
  compressImg = (camera, callBack) => {
    let self = this;
    let maxSize = 200 * 1024; //200K
    if (camera.length < maxSize) {
      //如果图片小于200K 直接上传
      callBack && callBack(camera);
    }
    //var agoimg = self.$el.find("#testImg").get(0);
    let img = new Image();
    img.src = camera;
    //agoimg.src = camera;
    img.onload = function () {
      let data = self.compress(img); //图片首先进行压缩
      callBack && callBack(data);
    };
  };
  compress = (img) => {
    try {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.height = img.height; //该值影响缩放后图片的大小
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      //将原来图片的质量压缩到原先的0.1倍。
      let data = canvas.toDataURL('image/jpeg', 0.1); //data url的形式
      return data;
    } catch (e) {
      return '';
    }
  };
}
export default AnysignView;
