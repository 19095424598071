import { React } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import Product from './product';

import './product.css';
import hotImage from '../../../images/hot-product.png';

/**
 * hot product
 */
class HotProduct extends Product {
  render() {
    return (
      <div
        className={this.getWidgetClassName('product hot')}
        onClick={this.onProductClicked}
        ref="me"
      >
        <span data-id={this.getProductUniqueKey()} />
        <div className="img">
          <img src={this.getImageUrl('image')} alt="" />
        </div>
        <div className="content">
          <div className="title">
            <span>{this.getProductName()}</span>
          </div>
          <div className="slogan">
            <span>
              {this.getSlogan1()} {this.getSlogan2()}
            </span>
          </div>
          <div className="price">
            <span>{this.getCurrencySign()}</span>
            <span>{this.getMinPrice()}</span>
            <span>{Lang.messages.product.priceStarts}</span>
          </div>
        </div>
      </div>
    );
  }
  getImageUrl(key) {
    let image = super.getImageUrl(key);
    return image ? image : hotImage;
  }
}

export default HotProduct;
