import { React } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';

// 02/01/2018 - TAG: by yanzhi.mo - NULL
const NULL = '无';

const addDeclaration = [
  '姓(英文或拼音)',
  '名(英文或拼音)',
  '出生日期',
  '现居住地址：国家(中文)',
  '现居住地址：省(中文)',
  '现居住地址：市(中文)',
  '现居住地址：国家(英文或拼音)',
  '现居住地址：省(英文或拼音)',
  '现居住地址：市(英文或拼音)',
  '出生地址：国家(中文)',
  '出生地址：省(中文)',
  '出生地址：市(中文)',
  '出生地址：国家(英文或拼音)',
  '出生地址：省(英文或拼音)',
  '出生地址：市(英文或拼音)',
  '税收居民国(地区)纳税人识别号1',
  '税收居民国(地区)纳税人识别号2(如有)',
  '税收居民国(地区)纳税人识别号3(如有)',
];

class DeclaraShow extends AtomicComponentThing {
  renderDeclaraLabel() {
    const declaraLabel = this.getGlobalDeclarations(this.getModel().code);

    // 2018/01/25 add by susan  需要显示特殊告知
    // if ('_0003' === this.getModel().code) {
    //     return null
    // }

    return <div className="sth-declara-show-label">{declaraLabel}</div>;
  }

  renderDeclaraConfirm() {
    if (this.getModel().noToggle) {
      return null;
    }

    // 02/01/2018 - TAG: by yanzhi.mo - 特殊告知
    // 2018/01/25 add by susan  需要显示特殊告知
    if ('_0003' === this.getModel().code) {
      return null;
    }
    if (['_0001', '_0002', '_0004'].includes(this.getModel().code)) {
      const confirm = this.getModel().remark !== NULL ? '' : '';
      return <div className="sth-declara-show-confirm">{confirm}</div>;
    }

    const confirm = this.getModel().confirm ? '是' : '否';
    return <div className="sth-declara-show-confirm">{confirm}</div>;
  }

  renderDeclaraRemark() {
    // 02/01/2018 - TAG: by yanzhi.mo - 特殊告知
    // 2018/01/25 add by susan  需要显示特殊告知
    if ('_0003' === this.getModel().code) {
      // return null
      if (this.getModel().remark !== NULL) {
        let contents = this.getModel().remark.split('/');
        return (
          <div className="sth-declare-special-content">
            {contents.map((contentItem, index) => {
              return (
                <div className="sth-declare-special-item" key={index}>
                  <span className="sth-declare-special-label">
                    {addDeclaration[index]}：
                  </span>
                  <span className="sth-declare-special-remark">
                    {contentItem}
                  </span>
                </div>
              );
            })}
          </div>
        );
      }
    }
    if (['_0001', '_0002', '_0004'].includes(this.getModel().code)) {
      if (this.getModel().remark !== NULL) {
        return (
          <div className="sth-declara-show-remark">
            {this.getModel().remark}
          </div>
        );
      }
    }

    if (this.getModel().confirm) {
      return (
        <div className="sth-declara-show-remark">{this.getModel().remark}</div>
      );
    } else {
      return null;
    }
  }

  render() {
    // console.log(this.getModel(),this.getLayout());
    return (
      <div {...this.getPrototypes()} ref="me">
        <div className="sth-declara-show-warp">
          {this.renderDeclaraLabel()}
          {this.renderDeclaraConfirm()}
        </div>
        {this.renderDeclaraRemark()}
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-declara-show';
  }
}

export default DeclaraShow;
