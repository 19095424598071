import { $ } from '../../../common/3rd';
import { Consts, Lang, Utils } from '../../../common/common';
import pageLoading from '../loading/page-loading';
import { Alert } from '../../components';

// 格式化支持
const PATTERN_MATCHER = /(\$\{.+\})/;
/* eslint-disable */
const PATTERNS = {
  '${c}': Utils.formatAmount.bind(Utils),
};
/* eslint-disable */

/**
 * 使用指定的格式, 格式化指定的值.
 * 如果是null, undefined或是任何会被判定为false的值, 直接返回.
 * 如果没有指定格式, 直接返回.
 *
 * 格式需要是类似'${c}元', 那么例如'1234', 会被格式化为'1,234元'. 其中${c}是在PATTERNS中被预定义的方法
 *
 * @param {any} value
 * @param {string} format
 */
const format = function (value, format) {
  if (!value || !format) {
    // 没有值或者没有指定格式, 直接返回传入的值
    return value;
  } else {
    const pattern = format.match(PATTERN_MATCHER)[0];
    const formattedValue = PATTERNS[pattern].call(this, value);
    return format.replace(pattern, formattedValue);
  }
};

/**
 * 获取代码表.
 * 如果给定的参数是一个字符串,
 * 1. 到Consts中使用全大写查找对应的代码表定义, 并且翻译为{v, l}的形式.
 * 2. 同时显示标签会使用全小写去common中查找国际化字符串.
 *
 * 否则就认为参数是一个合法的代码表直接返回, 不做任何国际化.
 *
 * @param {string|array} codes 代码表|代码表名称
 * @param {object} globalCodesRepo 全局代码表库. 这里的全部表示当前产品的全局, 并非系统全局.
 */
const codes = function (codes, globalCodesRepo) {
  if (typeof codes === 'string') {
    // 使用全局库
    let values = globalCodesRepo[codes.toUpperCase()];
    if (!values) {
      // 没有在全局库中找到
      // 在标准代码表中查找
      values = Consts[codes.toUpperCase()];
      if (!values) {
        throw `Cannot find codes[${codes.toUpperCase()}] on system or product global.`;
      } else {
        let i18n =
          Lang.messages.common[codes.toLowerCase()] ||
          Lang.messages.common[Utils.toCamelCase(codes)];
        // 标准代码库, 需要编织label
        return Object.keys(values).map((key) => {
          return {
            v: values[key],
            l: i18n[key],
          };
        });
      }
    } else {
      return values;
    }
  } else {
    return codes;
  }
};

// 属性过滤
const PROPERTY_FILTER_PATTERN = /\[.+\]/;
const PROPERTY_FILTER_UNWRAPPER = /[\[|\]]/g;
const SIMPLE_ID = 'simple';
const INDEX_ID = 'index';
const EXPRESSION_ID = 'expression';
// 表达式过滤器匹配,
// 两端匹配字母|数字|中括号|空格|小数点
// 操作符匹配=, ==, !=, <>, >, >=, <, <=
const FILTER_PATTERN =
  /^([\w|\.|\[|\]|\s|\/|=|!]+?)(=+|>=?|<=?|<>|!=)([\w|\.|\[|\]|\s]+)$/;

/**
 * 解析过滤器
 * @param {string} filter
 */
const parseFilter = function (filter) {
  const match = filter.match(FILTER_PATTERN);
  return {
    prop: match[1].trim(),
    operator: match[2],
    value: match[3].trim(),
  };
};

/**
 * 解析id成为一个id数组
 *
 * @param {string} id
 */
// a[0].b[!c=1].c
const parseId = function (id) {
  // const ids = id.split('.');
  var currentId = '';
  var inExpression = false;

  var ids = [];
  id.split('').forEach((ch) => {
    if (ch !== '.') {
      currentId += ch;
    }
    if (ch === '[') {
      inExpression = true;
    } else if (ch === ']') {
      inExpression = false;
    } else if (ch === '.' && inExpression) {
      currentId += ch;
    } else if (ch === '.' && !inExpression) {
      ids.push(currentId);
      currentId = '';
    }
  });
  ids.push(currentId);

  return ids.map((id) => {
    const matcher = id.match(PROPERTY_FILTER_PATTERN);
    if (matcher) {
      // 去掉过滤器的方括号
      let filter = matcher[0].replace(PROPERTY_FILTER_UNWRAPPER, '');
      // 获取真正的属性名称
      const realId = id.substring(0, matcher.index);
      if (!isNaN(filter)) {
        // 如果过滤器是数字
        return {
          id: realId,
          type: INDEX_ID,
          index: filter * 1,
        };
      } else {
        let exact = false;
        if (filter.startsWith('!')) {
          exact = true;
          filter = filter.substring(1).trim();
        }
        return {
          id: realId,
          type: EXPRESSION_ID,
          filter: parseFilter(filter),
          exact: exact,
        };
      }
    } else {
      // 没有匹配到过滤条件
      return {
        id: id,
        type: SIMPLE_ID,
      };
    }
  });
};

/**
 * 判断是否相等
 * @param {*} a
 * @param {*} b
 */
const equals = function (a, b) {
  const v = b === 'true' ? true : b === 'false' ? false : b;
  if (v === true) {
    return a === true;
  } else if (v === false) {
    return a == null || a === false;
  } else {
    return a == b;
  }
};

/**
 * 匹配过滤器.
 * 过滤目前仅支持:
 * 1. =和==: 值可以是字符串, 数字和boolean
 * 2. !=和<>: 值可以是字符串, 数字和boolean
 * 3. >: 值必须是数字
 * 4. >=: 值必须是数字
 * 5. <: 值必须是数字
 * 6. <=: 值必须是数字
 *
 * @param {object} model
 * @param {filter} filter
 */
const matchFilter = function (model, filter) {
  const value = getValueFromModel(model, filter.prop);
  const op = filter.operator;
  if (op === '=' || op === '==') {
    return equals(value, filter.value);
  } else if (op === '<>' || op === '!=') {
    return !equals(value, filter.value);
  } else if (op === '>') {
    return value > filter.value * 1;
  } else if (op === '<') {
    return value < filter.value * 1;
  } else if (op === '>=') {
    return value >= filter.value * 1;
  } else if (op === '<=') {
    return value <= filter.value * 1;
  }
  return true;
};

/**
 * 从模型中取值, 属性名不会做任何分析
 *
 * @param {any} model
 * @param {string} propName
 */
const getFromModel = function (model, propName) {
  return model ? model[propName] : null;
};

/**
 * 从模型中取数组, 属性名不会做任何分析
 * 如果数组不存在, 创建一个空数组填充
 *
 * @param {any} model
 * @param {string} propName
 */
const getOrCreateArrayFromModel = function (model, propName) {
  let array = getFromModel(model, propName);
  if (array == null) {
    array = [];
    setToModel(model, propName, array);
  }
  return array;
};

/**
 * 将值设置到模型中, 属性名不会做任何分析.
 * 只有在模型存在的时候设置.
 *
 * @param {any} model
 * @param {string} propName
 * @param {any} value
 */
const setToModel = function (model, propName, value) {
  if (model) {
    model[propName] = value;
  }
};

/**
 * 从模型中获取值
 *
 * @param {object} model
 * @param {string} id
 */
const getValueFromModel = function (model, id) {
  if (!model && !id) {
    return null;
  }
  const ids = parseId(id);
  return ids.reduce((model, idPart) => {
    if (model) {
      switch (idPart.type) {
        case INDEX_ID:
          const array = getOrCreateArrayFromModel(model, idPart.id);
          // 如果filter是一个数字, 直接获取相对位置的模型, 没有的话直接抛错.
          const length = array.length;
          const index = idPart.index;
          if (index < 0 || index > length - 1) {
            // 越界
            throw `Index out of bounds when get value by [${id}]`;
          } else {
            // 返回指定位置的对象
            // 注意已经不是数组, 而是对象本身
            return array[index];
          }
        case EXPRESSION_ID:
          // filter是一段语法
          const arrayData = getOrCreateArrayFromModel(model, idPart.id).filter(
            (item) => {
              return matchFilter(item, idPart.filter);
            }
          );
          if (idPart.exact) {
            if (arrayData.length !== 1) {
              // 越界
              throw `Index out of bounds when get value by [${id}]`;
            } else {
              return arrayData[0];
            }
          } else {
            return arrayData;
          }
        case SIMPLE_ID:
        default:
          // 没有指定过滤器
          return getFromModel(model, idPart.id);
      }
    } else {
      // 模型没有, 返回null
      return null;
    }
  }, model);
};

/**
 * 设置值到模型当中
 *
 * @param {*} model
 * @param {*} id
 * @param {*} value
 * @param {*} force
 */
const setValueToModel = function (model, id, value, force) {
  // eslint-disable-next-line
  if (!force && value == getValueFromModel(model, id)) {
    // 如果值相等, 则忽略设置步骤
    return false;
  }

  const ids = parseId(id);
  const lastIndex = ids.length - 1;

  ids.reduce((model, idPart, idPartIndex) => {
    switch (idPart.type) {
      case INDEX_ID:
        const array = getOrCreateArrayFromModel(model, idPart.id);
        const length = array.length;
        const index = idPart.index;
        if (index < 0 || index > length - 1) {
          // 越界
          throw `Index out of bounds when get value by [${id}]`;
        } else {
          // 返回指定位置的对象
          // 注意已经不是数组, 而是对象本身
          return array[index];
        }
      case EXPRESSION_ID:
        const arrayData = getOrCreateArrayFromModel(model, idPart.id).filter(
          (item) => {
            return matchFilter(item, idPart.filter);
          }
        );
        if (idPart.exact) {
          if (arrayData.length !== 1) {
            // 越界
            throw `Index out of bounds when get value by [${id}]`;
          } else {
            return arrayData[0];
          }
        } else {
          throw `Expression[${id}] is not supported.`;
        }
      case SIMPLE_ID:
      default:
        if (idPartIndex === lastIndex) {
          // 最后一个位置, 直接赋值
          setToModel(model, idPart.id, value);
        } else {
          // 不是最后一个位置, 等于原始值; 或当原始值为null时, 新建空对象
          let object = getFromModel(model, idPart.id);
          if (object == null) {
            object = {};
            setToModel(model, idPart.id, object);
          }
          return object;
        }
    }
  }, model);
};

/**
 * 根据指定的id, 装载需要被监听的id
 *
 * @param {string} id
 */
const findEmittingId = function (id) {
  const ids = parseId(id);
  return ids
    .map((id) => {
      switch (id.type) {
        case INDEX_ID:
          // 仅监听指定的
          return `${id.id}[${id.index}]`;
        case EXPRESSION_ID:
          // 监听所有
          return id.id;
        case SIMPLE_ID:
        default:
          return id.id;
      }
    })
    .join('.');
};

/**
 * 根据指定的id, 装载最近的需要被监听的索引id.
 *
 * 例如"propA[0].propB", 那么认为"propA"是最近的.
 * 目前只有索引类型的id, 才会进行处理, 而且只会去掉最后一个索引.
 *
 * @param {string} id
 */
const findClosestIndexedEmittingId = function (id) {
  const ids = parseId(id);
  if (ids.findIndex((id) => id.type === INDEX_ID) === -1) {
    return null;
  }

  let indexFound = false;
  return ids
    .reverse()
    .slice(1)
    .map((id) => {
      switch (id.type) {
        case INDEX_ID:
          if (!indexFound) {
            // 只有最后一个去掉索引
            indexFound = true;
            return id.id;
          } else {
            return `${id.id}[${id.index}]`;
          }
        case EXPRESSION_ID:
        case SIMPLE_ID:
        default:
          return id.id;
      }
    })
    .reverse()
    .join('.');
};

/**
 * 查找指定id的父数据模型
 *
 * @param {string} id
 */
const findParentModel = function (root, id) {
  const parentId = id.split('.').reverse().slice(1).reverse().join('.');
  if (parentId) {
    return getValueFromModel(root, parentId);
  } else {
    return root;
  }
};

/**
 * 使用templateJSON作为基础, specialJSON作为额外指定, 产品新的JSON返回.
 *
 * @param {object} templateJSON
 * @param {object} specialJSON
 */
const mergeJson = function (templateJSON, specialJSON) {
  // 为避免影响到template, 首先需要深复制
  return mergeJson2(
    $.extend(true, {}, specialJSON),
    $.extend(true, {}, templateJSON)
  );
};

/**
 * 将sourceJSON的值深复制到targetJSON. 最终放回targetJSON
 *
 * @param {object} sourceJSON
 * @param {object} targetJSON
 */
const mergeJson2 = function (sourceJSON, targetJSON) {
  Object.keys(sourceJSON).forEach((key) => {
    const srcValue = sourceJSON[key];
    if (srcValue == null) {
      // 源JSON有属性, 但是值是null, 忽略
    } else {
      const tgtValue = targetJSON[key];
      if (tgtValue == null) {
        // 目标JSON没有这个属性, 或者对应的属性值是null
        targetJSON[key] = srcValue;
      } else {
        // 目标JSON对应的属性也有不为null的值
        if (Array.isArray(srcValue)) {
          // 源JSON的属性值是一个数组, 则直接覆盖原有目标属性值
          targetJSON[key] = srcValue;
        } else if ($.isPlainObject(srcValue)) {
          // 源JSON的属性值是一个Object
          if (srcValue._replace === true) {
            // 指定要覆盖目标属性的值, 则直接覆盖
            targetJSON[key] = srcValue;
          } else if (!$.isPlainObject(tgtValue)) {
            // 目标JSON的属性值不是一个对象, 则直接覆盖
            targetJSON[key] = srcValue;
          } else {
            // 目标JSON的属性值也是一个对象, 则进行深复制
            targetJSON[key] = mergeJson2(srcValue, tgtValue);
          }
        } else {
          // 源JSON的值不是数组, 也不是object, 则直接覆盖原有值
          targetJSON[key] = srcValue;
        }
      }
    }
  });

  return targetJSON;
};

/**
 * 判断过滤规则是否适用.
 *
 * @param {{root: {any}, model: {any}, rule: {rule}}} options
 */
const isFilterRulePositive = function (options) {
  const root = options.root,
    model = options.model,
    rule = options.rule;
  if (rule === '--d') {
    // 关键字匹配, 一定会匹配上
    return true;
  } else if (typeof rule === 'string') {
    const exp = parseFilter(rule);
    if (exp.prop.startsWith('/')) {
      // 从根模型开始匹配
      return matchFilter(root, {
        // 去掉第一个字符, 即/符号
        prop: exp.prop.substring(1),
        operator: exp.operator,
        value: exp.value,
      });
    } else {
      // 从当前模型开始匹配
      return matchFilter(model, exp);
    }
  } else {
    const key = Object.keys(rule)[0];
    return shouldApplyFilter({
      root: root,
      model: model,
      rules: Array.isArray(rule[key]) ? rule[key] : [rule[key]],
      relation: key,
    });
  }
};

/**
 * 指定的过滤器是否可以适配
 *
 * @param {{root: any, model: any, rules: array}} options
 */
const shouldApplyFilter = function (options) {
  const root = options.root,
    model = options.model,
    rules = options.rules,
    relation = options.relation || 'and';

  // 关系如果是and, 每个都要是true, 也就是遇到第一个false就返回
  // 关系如果是or, 遇到第一个true就返回
  if (relation === 'and') {
    return (
      rules.find((rule) => {
        // 找到第一个false
        const positive = isFilterRulePositive({
          root: root,
          model: model,
          rule: rule,
        });
        // console.log(`rule[${rule}] [${positive}]`)
        return !positive;
      }) == null
    );
  } else if (relation === 'or') {
    return (
      rules.find((rule) => {
        // 找到第一个true
        const positive = isFilterRulePositive({
          root: root,
          model: model,
          rule: rule,
        });
        // console.log(`rule[${rule}] [${positive}]`)
        return positive;
      }) != null
    );
  } else {
    throw `Unsupported relationship[${relation}] found, check you definition please.`;
  }
};

/**
 * 查找匹配的过滤器
 *
 * @param {filters: {filter|[filter]}, model: {any}, root: {any}} options
 */
const findMatchedFilter = function (options) {
  const filters = options.filters
    ? Array.isArray(options.filters)
      ? options.filters
      : [options.filters]
    : [];
  return filters.find((filter) => {
    const rules = filter.on;
    if (!rules) {
      // 没有定义过滤器适用的规则, 报错
      // eslint-disable-next-line
      throw `There is no rule defined of filter on property[${id}]`;
    } else {
      // 判断
      return shouldApplyFilter({
        root: options.root,
        model: options.model,
        rules: Array.isArray(rules) ? rules : [rules],
      });
    }
  });
};

// const model = {
// 	a: 1,
// 	b: 1,
// 	c: '1',
// 	d: 1,
// 	e: {
// 		f: 'test'
// 	}
// };

// const ret = shouldApplyFilter({
// 	root: model,
// 	model: model,
// 	rules: ['a=1', 'b=1', { or: ['c!= 1', 'd <>1', { and: ['e.f==test'] }] }]
// });
// console.log(`should [${ret}]`);

/**
 * 根据指定的过滤器过滤数据
 *
 * @param {[any]]} array
 * @param {string} filter
 */
const filterArray = function (array, filter) {
  return array.filter((item) => {
    return matchFilter(item, parseFilter(filter));
  });
};

/**
 * 获取关心的id
 *
 * @param {{root: {any}, model: {any}, rules: {string|[string]}}} options
 */
const getConcernedIdsOfRules = function (options) {
  const root = options.root,
    model = options.model,
    rules = options.rules;

  return rules.reduce((all, rule) => {
    if (rule === '--d') {
      // 适配所有, 不需要关心
    } else if (typeof rule === 'string') {
      const exp = parseFilter(rule);
      all.push({
        prop: exp.prop.startsWith('/') ? exp.prop.substring(1) : exp.prop,
        model: exp.prop.startsWith('/') ? root : model,
      });
    } else {
      const sub = getConcernedIdsOfRules({
        root: root,
        model: model,
        rules: rule[Object.keys(rule)[0]],
      });
      Array.prototype.push.apply(all, sub);
    }
    return all;
  }, []);
};

/**
 * 获取关心的id
 *
 * @param {{root: {any}, model: {any}, filters: {filter|[filter]}}} options
 */
const getConcernedIdsOfFilters = function (options) {
  const filters = options.filters
    ? Array.isArray(options.filters)
      ? options.filters
      : [options.filters]
    : [];

  return (
    filters.reduce((ids, filter) => {
      const rules = filter.on;
      if (!rules) {
        // 没有定义过滤器适用的规则, 报错
        // eslint-disable-next-line
        throw `There is no rule defined of filter on property[${id}]`;
      } else {
        // 判断
        const filterIds = getConcernedIdsOfRules({
          root: options.root,
          model: options.model,
          rules: Array.isArray(rules) ? rules : [rules],
        });
        Array.prototype.push.apply(ids, filterIds);
      }
      return ids;
    }, []) || []
  );
};

// console.log(getConcernedIdsOfFilters({
// 	root: {},
// 	model: {},
// 	filters: {
// 		on: ['a=1', 'b=1', { or: ['c!= 1', 'd <>1', { and: ['e.f==test'] }] }]
// 	}
// }));

// TODO 这里添加新的校验规则
const CHECK_RULES = {
  //检查是否为大陆税收居民
  checkPayDuty: function (options) {
    let value = options.value;
    if (value == 1 && value == true) {
      return true;
    } else {
      return false;
    }
  },

  required: function (options) {
    // 02/01/2018 - TAG: by yanzhi.mo - 电子邮箱校验规则 #artf443124
    if (options.rule.msg === '请填写投保人电子邮箱') {
      if (options.model.policyHolder.extensionData.deliveryMethod !== '1') {
        return true;
      }
    }

    const value = options.value;
    return !(
      value == null ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  },

  //校验受益人同一受益类型受益顺序下的受益比例之和是否为100%
  checkBeneficiary: function (options) {
    const REG_ORDER = /^[1-9]\d*$/;
    const REG_RATIO = /^((0\.\d\d?)|(1\.00))$/;
    let keys = [];
    let beneficiarys = options.root.policyCustomers.filter(
      (policyCustomer) => policyCustomer.customerType == '3'
    );
    for (let i = 0; i < beneficiarys.length; i++) {
      if (
        !beneficiarys[i].extensionData ||
        !beneficiarys[i].extensionData.benefitRatio ||
        !beneficiarys[i].extensionData.benefitOrder ||
        !beneficiarys[i].extensionData.beneficiaryType
      ) {
        return false;
      }
      let beneficiaryType = beneficiarys[i].extensionData.beneficiaryType;
      let benefitOrder = beneficiarys[i].extensionData.benefitOrder;
      let benefitRatio = beneficiarys[i].extensionData.benefitRatio;
      if (!REG_ORDER.test(benefitOrder) || !REG_RATIO.test(benefitRatio)) {
        return false;
      }
      benefitRatio *= 100;
      let key = beneficiaryType + '-' + benefitOrder;
      if (keys[key]) {
        keys[key] += benefitRatio;
      } else {
        keys[key] = benefitRatio;
      }
    }
    for (let key in keys) {
      if (keys[key] != 100) {
        return false;
      }
    }
    return true;
  },
  //受益人受益顺序 必须从1开始 add by wendy.gu
  checkBeneficiaryOrder: function (options) {
    const REG_ORDER = /^[1-9]\d*$/;
    let maxOrder = 1; //最大受益顺序
    let orderArr = []; //受益顺序数组
    let beneficiarys = options.root.policyCustomers.filter(
      (policyCustomer) => policyCustomer.customerType == '3'
    );
    for (let i = 0; i < beneficiarys.length; i++) {
      if (
        !beneficiarys[i].extensionData ||
        !beneficiarys[i].extensionData.benefitRatio ||
        !beneficiarys[i].extensionData.benefitOrder ||
        !beneficiarys[i].extensionData.beneficiaryType
      ) {
        return false;
      }
      let benefitOrder = beneficiarys[i].extensionData.benefitOrder;
      if (!REG_ORDER.test(benefitOrder)) {
        return false;
      }
      if (orderArr.indexOf(benefitOrder) < 0) {
        orderArr.push(benefitOrder);
        maxOrder = Math.max(maxOrder, benefitOrder);
      }
    }
    if (maxOrder > orderArr.length) {
      return false;
    }
    return true;
  },
  //校验详细地址长度
  checkAddressLength: function (options) {
    if (options.value.length >= 6 && options.value.length <= 100) {
      return true;
    } else {
      return false;
    }
  },
  //校验至少需要一个受益人
  checkBeneficiaryLength: function (options) {
    let beneficiarys = options.root.policyCustomers.filter(
      (policyCustomer) => policyCustomer.customerType == '3'
    );
    if (beneficiarys && beneficiarys.length > 0) {
      return true;
    } else {
      return false;
    }
  },

  requiredDeclara: function (options) {
    let value = options.value.remark;
    return !(
      value == null ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  },

  requiredDeclaraInfant: function (options) {
    let value = options.value.remark;
    const valid = value.split('/').every((v) => v.trim() !== '');
    return !(
      value == null ||
      (typeof value === 'string' && value.trim().length === 0) ||
      !valid
    );
  },

  //投保人告知页 验证税收声明
  declaraChecked: function (options) {
    let value = options.value.remark;
    let declaration =
      options.root.policyHolder.extensionData.declarations.filter(
        (declarationItem) => declarationItem.code == '_0002'
      );
    let taxDeclaration =
      options.root.policyHolder.extensionData.declarations.filter(
        (declarationItem) => declarationItem.code == '_0004'
      );
    if (
      declaration &&
      declaration.length > 0 &&
      declaration[0].remark !== '仅为中国税收居民'
    ) {
      value = value.split('/');
      if (
        value[0].trim() === '' ||
        value[1].trim() === '' ||
        value[2].trim() === '' ||
        value[3].trim() === '' ||
        value[4].trim() === '' ||
        value[5].trim() === '' ||
        value[6].trim() === '' ||
        value[7].trim() === '' ||
        value[8].trim() === '' ||
        value[12].trim() === '' ||
        value[13].trim() === '' ||
        value[14].trim() === '' ||
        (value[15].trim() === '' && !taxDeclaration[0].remark)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  },
  //代理人信息完整性验证
  agentDeclaraChecked: function (options) {
    console.log(options);
    let value = options.value.remark;
    if (value) {
      let incomeData = value.split('/');
      if (
        incomeData[0].trim() === '' ||
        incomeData[1].trim() === '' ||
        incomeData[2].trim() === ''
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },

  //校验受益顺序
  checkBenefitOrder: function (options) {
    const REG = /^[1-9]\d*$/;
    const value = options.value;
    return REG.test(value);
  },

  // 证件有效期校验
  // 27/12/2017 - TAG: by yanzhi.mo
  checkValidityPeriod: function ({ value }) {
    const val_time = new Date(value).getTime();
    const curr_time = new Date().getTime();
    return val_time >= curr_time;
  },

  //校验受益比例
  checkBenefitRatio: function (options) {
    const REG = /^(([1-9]\d?)|(100))$/;
    const value = options.value;
    return REG.test(value);
  },

  checkIdentityCard: function () {},
  //校验身份证年龄范围
  checkIdAge: function (options) {
    let idNo = options.value + '';
    let idYear = idNo.substring(6, 10);
    let idMounth = idNo.substring(10, 12);
    let idDay = idNo.substring(12, 14);
    let birthDay = idYear + '/' + idMounth + '/' + idDay;
    let birthDate = new Date(birthDay);
    let nowDate = new Date();
    let age = nowDate.getFullYear() - birthDate.getFullYear();
    if (
      nowDate.getMonth() < birthDate.getMonth() ||
      (nowDate.getMonth() == birthDate.getMonth() &&
        nowDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    if (age < 19) {
      return true;
    } else {
      return false;
    }
  },
  //校验年龄是否大于60
  checkIdAgeUp: function (options) {
    let age = $('.sth-age').val();
    console.log('age:');
    console.log(age);
    if (age < 60) {
      return true;
    } else {
      return false;
    }
  },
  //校验出生日期与身份证是否匹配
  // 17/01/2018 - TAG: by yanzhi.mo - 修复一些错误，校验尽量使用unix time
  checkBirthWithIdNo: function (options) {
    let idNo = options.value + '';
    let modelData = options.model;
    if (options.rule.with === 'policyHolder') {
      modelData = options.root.policyHolder;
    }
    //!modelData.dateOfBirth添加判断 udpate by wendy.gu 2018 Jan.19
    if (
      modelData.idType !== '0' ||
      idNo.length != 18 ||
      !modelData.dateOfBirth
    ) {
      return true;
    }
    let idYear = idNo.substring(6, 10);
    let idMounth = idNo.substring(10, 12);
    let idDay = idNo.substring(12, 14);
    let birthDay = idYear + '/' + idMounth + '/' + idDay + ' 00:00:00';
    return (
      new Date(birthDay).getTime() === new Date(modelData.dateOfBirth).getTime()
    );
  },
  //校验性别与身份证是否匹配
  checkGenderWithIdNo: function (options) {
    let idNo = options.value;
    let modelData = options.model;
    if (options.rule.with === 'policyHolder') {
      modelData = options.root.policyHolder;
    }
    if (modelData.idType !== '0' || idNo.length != 18) {
      return true;
    }
    let idGender = parseInt(idNo.substr(16, 1));
    if (
      (idGender % 2 && modelData.gender === 'M') ||
      (!(idGender % 2) && modelData.gender === 'F')
    ) {
      return true;
    }
    return false;
  },

  //校验证件号码
  checkIdNo: function (options) {
    let city = {
      11: '北京',
      12: '天津',
      13: '河北省',
      14: '山西省',
      15: '内蒙古自治区',
      21: '辽宁省',
      22: '吉林省',
      23: '黑龙江省',
      31: '上海市',
      32: '江苏省',
      33: '浙江省',
      34: '安徽省',
      35: '福建省',
      36: '江西省',
      37: '山东省',
      41: '河南省',
      42: '湖北省',
      43: '湖南省',
      44: '广东省',
      45: '广西壮族自治区',
      46: '海南省',
      50: '重庆市',
      51: '四川省',
      52: '贵州省',
      53: '云南省',
      61: '陕西省',
      62: '甘肃省',
      63: '青海省',
      64: '宁夏回族自治区',
      65: '新疆维吾尔自治区',
      71: '台湾省',
      81: '香港特别行政区',
      82: '澳门特别行政区',
      91: '国外',
    };
    options.value = options.value.toUpperCase();
    let idNo = options.value;
    const idType = options.comp.getValueFromModel(options.rule.with);
    if (idType === '' || idType === undefined || idType === null) {
      return true;
    }
    //校验身份证号码
    if (idType == '0') {
      // prettier-ignore
      if (!/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(idNo)) {
        return false;
      }
      if (!city[idNo.substr(0, 2)]) {
        return false;
      }
      if (idNo.length !== 18) {
        return true;
      }
      //加权因子
      let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      let sum = 0;
      let ai = 0;
      let wi = 0;
      for (let i = 0; i < 17; i++) {
        ai = idNo[i];
        wi = factor[i];
        sum += ai * wi;
      }
      let last = parity[sum % 11];
      if (parity[sum % 11] != idNo[17]) {
        return false;
      }
    }
    return true;
  },

  //校验身高
  checkHeight: function (options) {
    const REG = /^(1|2)?\d{2}$/;
    const value = options.value;
    return REG.test(value);
  },

  //校验体重
  checkWeight: function (options) {
    const REG = /^[1-9]\d?\d?$/;
    const value = options.value;
    return REG.test(value);
  },

  //校验手机号码
  checkPhoneNum: function (options) {
    const value = options.value;
    return Utils.checkPhone(value);
  },

  //校验年收入
  checkIncome: function (options) {
    // const REG = /^\d+(\.\d\d?)?$/;
    const REG = /^\d+$/;
    const value = options.value;
    return REG.test(value);
  },

  //校验电子邮箱
  checkEmail: function (options) {
    // 02/01/2018 - TAG: by yanzhi.mo - 电子邮箱校验规则 #artf443124
    //非空判断 update by wendy.gu 2018 Jan.6
    if (
      !options.model.policyHolder ||
      !options.model.policyHolder.extensionData ||
      options.model.policyHolder.extensionData.deliveryMethod !== '1'
    ) {
      return true;
    }
    return Utils.checkEmail(options.value);
  },

  checkemail: function (options) {
    return Utils.checkEmail(options.value);
  },

  //校验邮政编码
  checkPostCode: function (options) {
    const REG = /^\d{6}$/;
    const value = options.value;
    return REG.test(value);
  },

  //支付信息页 是否已阅读转账声明
  checked: function (options) {
    const value = options.value;
    return value;
  },
  //人脸识别
  faceRecChecked: function (options) {
    const value = options.value;
    return value;
  },
  //拍照验证
  photoCheck: function (options) {
    const value = options.value;
    return value;
  },
  //短信验证是否通过
  messageCheck: function (options) {
    const value = options.value;
    return value;
  },
  //资料上传页 是否已上传影像
  uploadChecked: function (options) {
    const value = options.value;
    return value;
  },
  //交费期间、保障期限必须选中
  optionChecked: function (options) {
    if (options.value === -1 || options.value === '-1') return false;
    else return true;
  },
  //支付信息－银行下拉框
  bankChecked: function (options) {
    if (options.value && options.value !== '') return true;
    else return false;
  },
  //支付信息－银行卡号校验
  bankNumChecked: function (options) {
    var bankNum = options.value;
    return Utils.checkBank(bankNum);
  },
  //电子签名页 单证是否签完
  signChecked: function (options) {
    const value = options.value;
    return value;
  },
  //电子签名页 是否已勾选签名提示
  signPromptConfirm: function (options) {
    const value = options.value;
    return value;
  },
  //电子签名页 代理人/投保人/被保人是否已签字
  signatureChecked: function (options) {
    console.log('signatureChecked......');
    const value = options.value;
    return value;
  },
};
/**
 *
 * @param {{rule: rule, value: any, model: any, root: any, comp: component}} options
 */
const checkRule = function (options) {
  // const rule = options.rule;
  // const value = options.value;
  // const name = rule.do;
  // const params = rule.with;
  return CHECK_RULES[options.rule.do].call(this, options);
};

const AJAX_LATCH = {
  CALC: [],
};

const EVENT_RULES = {
  //B2C国家切换事件
  changeNation: function (options) {
    console.log(this);
    console.log(options);
    let idType = [
      { v: '0', l: '身份证' },
      { v: '4', l: '户口本' },
      { v: '7', l: '出生证' },
      { v: '1', l: '外国护照' },
    ];
    let aHtml = [];
    let countryCode = options.model.extensionData.nationality;
    $('select.nationtype option').remove();
    $.each(idType, function (key, value) {
      if (countryCode !== 'CHN' && key === 3) {
        aHtml.push(
          '<option value="' +
            value['v'] +
            '" data-value-type="string">' +
            value['l'] +
            '</option>'
        );
      } else if (countryCode === 'CHN' && key < 3) {
        aHtml.push(
          '<option value="' +
            value['v'] +
            '" data-value-type="string">' +
            value['l'] +
            '</option>'
        );
      }
    });
    $('select.nationtype').html(aHtml.join(''));
    $('select.nationtype').trigger('change');
  },
  //银行卡号input事件
  checkBankCard: function (options) {
    let val = options.value;
    // val = val.replace(/(\d{4})(?=[^\s])/g, '$1 ');
    val = val.replace(/\s/g, '');
    let model = options.root;
    model.extensionData.paymentInfo.bankCard = val;
    options.comp.getRootComponent().forceUpdate();
  },
  //建议书计算
  calculateAdvice: function (options) {
    let that = options.comp.getRootComponent();
    that.forceUpdate();
    let url = '/rest/v1/policy/calculate';
    let model = options.root;
    let flag = false;
    /* 缴费频率 */
    if (model.insureds[0].coverages && model.insureds[0].coverages.length > 0) {
      model.insureds[0].coverages.forEach((item) => {
        if (item.extensionData) {
          if (item.extensionData.installment == 'Y1') {
            item.extensionData.paymentFreq = '5';
          } else {
            item.extensionData.paymentFreq = '1';
          }
        }
      });
    }
    /* ---------- */
    if (!model.policyCustomers[0].dateOfBirth) return;
    model.insureds[0].coverages.forEach((coverage, index) => {
      let { coveragePeriod, installment, sumInsured, displayPremIndi } =
        coverage.extensionData;
      if (displayPremIndi) {
        if (coveragePeriod != '-1' && installment != '-1') {
          flag = true;
        } else {
          flag = false;
        }
      }
    });
    if (flag) {
      pageLoading.start(Lang.messages.sth.pleaseWaitWhileCalculatingPremiums);
      this.doPost(url, model)
        .done((data) => {
          if (data.body.insureds[0].coverages.length > 1) {
            data.body.insureds[0].coverages.sort(
              (a, b) => a.extensionData.sequence > b.extensionData.sequence
            );
          }
          $.extend(true, options.root, data.body);
          that.forceUpdate();
        })
        .always(() => {
          pageLoading.end();
        });
    }
  },
  //投保计算 暂时无用，统一使用建议书计划保费接口
  calculatePolicy: function (options) {
    setTimeout(() => {
      let that = options.comp.getRootComponent();
      if (options.root.insureds[0].insuredName !== '京康护宝少儿重大疾病保险') {
        //京康护宝不重置交费期间
        if (options.comp.props.layout.type === 'birthAge') {
          if (Array.isArray(that.props.model.insureds[0].coverages)) {
            that.props.model.insureds[0].coverages.forEach(
              (coverage, index) => {
                coverage.extensionData.installment = '-1';
              }
            );
          }
          that.forceUpdate();
        }
      }
      let url = '/rest/v1/policy/calculate';
      let model = options.root;
      let flag = false;
      let stop = false;
      model.insureds[0].coverages.forEach((coverage, index) => {
        let { coveragePeriod, installment, sumInsured, displayPremIndi } =
          coverage.extensionData;
        if (displayPremIndi && !stop) {
          if (coveragePeriod == '-1' || installment == '-1') {
            flag = false;
            stop = true;
          } else {
            flag = true;
          }
        }
      });
      if (!flag) return;
      /* 缴费频率 */
      if (
        model.insureds[0].coverages &&
        model.insureds[0].coverages.length > 0
      ) {
        model.insureds[0].coverages.forEach((item) => {
          if (item.extensionData) {
            if (item.extensionData.installment == 'Y1') {
              item.extensionData.paymentFreq = '5';
            } else {
              item.extensionData.paymentFreq = '1';
            }
          }
        });
      }
      /* ---------- */
      pageLoading.start(Lang.messages.sth.pleaseWaitWhileCalculatingPremiums);
      this.doPost(url, model)
        .done((data) => {
          if (data.body.insureds[0].coverages.length > 1) {
            data.body.insureds[0].coverages.sort(
              (a, b) => a.extensionData.sequence > b.extensionData.sequence
            );
          }
          $.extend(true, options.root, data.body);
          that.forceUpdate();
          if (
            model.insureds[0] &&
            model.insureds[0].insuredName == '京康护宝少儿重大疾病保险'
          ) {
            if (options.comp.getRootComponent().props.parent) {
              options.comp.getRootComponent().props.parent.forceUpdate();
            }
          }
        })
        .always(() => {
          pageLoading.end();
        });
    }, 1);
  },

  // B2C 保费试算  产品详情页面使用
  B2CcalculateAdvice: function (options) {
    let MODEL_DATA = options.root;
    let url = '/rest/v1/policy/calculate';
    pageLoading.start();
    this.doPost(url, MODEL_DATA)
      .done((data) => {
        $.extend(true, options.root, data.body);
        options.comp.getRootComponent().props.parent.forceUpdate();
      })
      .always(() => {
        pageLoading.end();
      });
  },

  //B2A 京安顺计算保费
  B2AcalculateJAS: function (options) {
    let model = options.root;
    if (options.comp.props.layout.id.includes('benefitLevel')) {
      MODEL_DATA.insureds[0].coverages[0].extensionData.coveragePeriod = '-1';
      options.comp.getRootComponent().forceUpdate();
    }
    console.log(options);
    let { benefitLevel, unit, installment, displayPremIndi, coveragePeriod } =
      model.insureds[0].coverages[0].extensionData;
    // eslint-disable-next-line
    if (
      benefitLevel == '-1' ||
      installment == '-1' ||
      unit == '-1' ||
      coveragePeriod == '-1' ||
      !coveragePeriod
    ) {
      return;
    }
    /* 缴费频率 */
    if (model.insureds[0].coverages && model.insureds[0].coverages.length > 0) {
      model.insureds[0].coverages.forEach((item) => {
        if (item.extensionData) {
          if (item.extensionData.installment == 'Y1') {
            item.extensionData.paymentFreq = '5';
          } else {
            item.extensionData.paymentFreq = '1';
          }
        }
      });
    }
    /* ---------- */
    let url = '/rest/v1/policy/calculate';
    pageLoading.start();
    this.doPost(url, model)
      .done((data) => {
        $.extend(true, options.root, data.body);
        options.comp.getRootComponent().forceUpdate();
      })
      .always(() => {
        pageLoading.end();
      });
  },

  /**
   * @description B2A 京康源
   * @param {any} options
   */
  B2AcalculateJKY: function (options) {
    setTimeout(() => {
      let model = options.root;
      let that = options.comp.getRootComponent();
      let policyCustomer = model.policyCustomers.find(
        (item) => item.customerType == '2'
      );
      let policyHolder = model.policyHolder;
      console.log(options);
      if (options.comp.props.layout.type === 'birthAge') {
        model.premium = 0;
        that.props.parent && that.props.parent.forceUpdate();
        if (Array.isArray(model.insureds[0].coverages)) {
          model.insureds[0].coverages
            .filter((t) => t.coverageCode != 1070007)
            .forEach((coverage, index) => {
              coverage.extensionData.installment = '-1';
              coverage.extensionData.coveragePeriod = '-1';
            });
        }
        that.forceUpdate();
      }
      /* 同步主副险交费期间 */
      if (
        options.model.productCode == 1070006 &&
        options.comp.props.layout.id &&
        options.comp.props.layout.id.includes('installment')
      ) {
        model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1030001
        ).extensionData.installment = options.value;
        let val =
          options.value.toString().match(/^Y[0-9]*$/) &&
          options.value.toString() != 'Y1'
            ? 'Y' + (options.value.replace('Y', '') - 1)
            : options.value;
        model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1070007
        ).extensionData.installment = val;
        that.forceUpdate();
      }
      /* 同步两全险保额 */
      if (
        options.model.productCode == 1070006 &&
        options.comp.props.layout.id &&
        options.comp.props.layout.id.includes('sumInsured')
      ) {
        let sumInsured = model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1070006
        ).extensionData.sumInsured;
        model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1030001
        ).extensionData.sumInsured = sumInsured;
        that.forceUpdate();
      }
      if (!policyCustomer.extensionData.age || !policyHolder.extensionData.age)
        return;

      /* 添加豁免险投被保人不能为同一人 且[18,60] */
      if (
        model.insureds[0].coverages.find((ins) => ins.coverageCode == 1070007)
          .extensionData.displayPremIndi &&
        policyCustomer.extensionData.relationWithPH == '00'
      ) {
        if (
          policyHolder.extensionData.age < 18 ||
          policyHolder.extensionData.age > 60
        ) {
          Alert.message(
            '附加投保人豁免保险费重大疾病保险投保人年龄须在18-60岁之间'
          );
          return;
        }
        Alert.message('附加投保人豁免保险费重大疾病保险投被保人不能为同一人');
        model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1070007
        ).extensionData.displayPremIndi = false;
        that.forceUpdate();
        return;
      }
      /* 主险为趸交时不可选豁免险 */
      if (
        model.insureds[0].coverages.find((ins) => ins.coverageCode == 1070006)
          .extensionData.installment == 'Y1' &&
        model.insureds[0].coverages.find((ins) => ins.coverageCode == 1070007)
          .extensionData.displayPremIndi === true
      ) {
        Alert.message('主险交费期间为趸交时豁免险不可用');
        model.insureds[0].coverages.find(
          (ins) => ins.coverageCode == 1070007
        ).extensionData.displayPremIndi = false;
        that.forceUpdate();
        return;
      }

      let flag = false;
      model.insureds[0].coverages.forEach((coverage, index) => {
        if ([1070006, 1030001].some((v) => v == coverage.coverageCode)) {
          console.log(coverage);
          let { coveragePeriod, installment, sumInsured, displayPremIndi } =
            coverage.extensionData;
          if (displayPremIndi) {
            if (
              coveragePeriod != '-1' &&
              installment != '-1' &&
              sumInsured > 0
            ) {
              flag = true;
            } else {
              flag = false;
            }
          }
        }
      });
      if (!flag) return;

      if (
        model.insureds[0].coverages &&
        model.insureds[0].coverages.length > 0
      ) {
        model.insureds[0].coverages.forEach((item) => {
          /* 缴费频率 */
          if (item.extensionData) {
            if (item.extensionData.installment == 'Y1') {
              item.extensionData.paymentFreq = '5';
            } else {
              item.extensionData.paymentFreq = '1';
            }
          }
        });
      }
      /* ---------- */
      let url = '/rest/v1/policy/calculate';
      pageLoading.start();
      this.doPost(url, model)
        .done((data) => {
          if (data.body.insureds[0].coverages.length > 1) {
            data.body.insureds[0].coverages.sort(
              (a, b) => a.extensionData.sequence > b.extensionData.sequence
            );
          }
          $.extend(true, options.root, data.body);

          /* 同步豁免险保额 */
          if (
            model.insureds[0].coverages.find(
              (ins) => ins.coverageCode == 1070007
            ).extensionData.displayPremIndi
          ) {
            const sumInsured = model.insureds[0].coverages.find(
              (ins) => ins.coverageCode == 1070007
            ).extensionData.sumInsured;
            const installment = model.insureds[0].coverages.find(
              (ins) => ins.coverageCode == 1070007
            ).extensionData.installment;
            model.insureds[0].coverages.find(
              (ins) => ins.coverageCode == 1070007
            ).extensionData.sumInsured =
              sumInsured * installment.replace('Y', '');
          }

          that.forceUpdate();
        })
        .always(() => {
          pageLoading.end();
        });
    }, 1);
  },

  // B2C 计算保费
  B2CcalculatePolicy: function (options) {
    setTimeout(() => {
      let that = options.comp.getRootComponent();
      console.log(options);
      let model = options.root;
      let flag = false;
      let policyCustomer = model.policyCustomers.find(
        (item) => item.customerType == '2'
      );
      /**
       * 被保人选择子女，清空同步信息
       */
      let relationWithPH = policyCustomer.extensionData.relationWithPH;
      if (
        options.value === '23' &&
        options.comp.props.layout.id.includes('relationWithPH')
      ) {
        policyCustomer.firstName = '';
        policyCustomer.idNo = '';
        policyCustomer.dateOfBirth = '';
        policyCustomer.gender = '';
        policyCustomer.extensionData.age = '';
        policyCustomer.extensionData.job1 = '2'; //学生
        policyCustomer.extensionData.job2 = '209';
        policyCustomer.extensionData.job3 = '209990';
        policyCustomer.extensionData.job4 = '2099907';
        policyCustomer.extensionData.jobCategory = '1';
        that.forceUpdate();
      }

      if (
        policyCustomer.extensionData.relationWithPH == '00' &&
        !window.location.href.includes('/introduction')
      ) {
        /* 被保人默认选择本人，关键信息同步 */
        policyCustomer.extensionData.job1 =
          model.policyHolder.extensionData.job1;
        policyCustomer.extensionData.job2 =
          model.policyHolder.extensionData.job2;
        policyCustomer.extensionData.job3 =
          model.policyHolder.extensionData.job3;
        policyCustomer.extensionData.job4 =
          model.policyHolder.extensionData.job4;
        policyCustomer.extensionData.jobCategory =
          model.policyHolder.extensionData.jobCategory;
      }
      let { job1, job2, job3, job4, jobCategory } =
        policyCustomer.extensionData;
      if (!(job1 && job2 && job3 && job4 && jobCategory)) return;
      if (!job4.startsWith(job1)) return;
      if (
        policyCustomer.extensionData.relationWithPH == '00' &&
        model.policyHolder.dateOfBirth &&
        !window.location.href.includes('/introduction')
      ) {
        /* 被保人默认选择本人，关键信息同步 */
        policyCustomer.dateOfBirth = model.policyHolder.dateOfBirth;
        policyCustomer.gender = model.policyHolder.gender;
        policyCustomer.extensionData.age = model.policyHolder.extensionData.age;
      }
      if (!policyCustomer.extensionData.age) return;
      model.insureds[0].coverages.forEach((coverage, index) => {
        let { coveragePeriod, installment, sumInsured, displayPremIndi } =
          coverage.extensionData;
        if (displayPremIndi) {
          if (coveragePeriod != '-1' && installment != '-1') {
            flag = true;
          } else {
            flag = false;
          }
        }
      });
      if (!flag) return;
      /* 缴费频率 */
      if (
        model.insureds[0].coverages &&
        model.insureds[0].coverages.length > 0
      ) {
        model.insureds[0].coverages.forEach((item) => {
          if (item.extensionData) {
            if (item.extensionData.installment == 'Y1') {
              item.extensionData.paymentFreq = '5';
            } else {
              item.extensionData.paymentFreq = '1';
            }
          }
        });
      }
      /* ---------- */
      pageLoading.start(Lang.messages.sth.pleaseWaitWhileCalculatingPremiums);
      let url = '/rest/v1/policy/calculate';
      this.doPost(url, model)
        .done((data) => {
          if (data.body.insureds[0].coverages.length > 1) {
            data.body.insureds[0].coverages.sort(
              (a, b) => a.extensionData.sequence > b.extensionData.sequence
            );
          }
          $.extend(true, options.root, data.body);
          if (that.props.parent) {
            that.props.parent.forceUpdate();
          } else {
            that.forceUpdate();
          }
        })
        .always(() => {
          pageLoading.end();
        });
    }, 1);
  },
  //B2C京彩年华计算保费
  B2CcalculatePolicyJCNH: function (options) {
    setTimeout(() => {
      let that = options.comp.getRootComponent();
      let model = options.root;
      let flag = false;
      let policyCustomer = model.policyCustomers.find(
        (item) => item.customerType == '2'
      );
      if (options.comp.props.layout.type === 'birthAge') {
        model.premium = 0;
        that.props.parent && that.props.parent.forceUpdate();
        if (Array.isArray(model.insureds[0].coverages)) {
          model.insureds[0].coverages.forEach((coverage, index) => {
            coverage.extensionData.installment = '-1';
            coverage.extensionData.coveragePeriod = '-1';
          });
        }
        that.forceUpdate();
      }
      //limit
      if (policyCustomer.age > 60) {
        Alert.message('被保人年龄超出最大年龄限制');
        return;
      }
      if (policyCustomer.extensionData.jobCategory > 4) {
        Alert.message('该险种只限1-4类职业投保');
        return;
      }
      if (policyCustomer.extensionData.relationWithPH == '00') {
        /* 被保人默认选择本人，关键信息同步 */
        model.policyHolder.extensionData.age =
          policyCustomer.extensionData.age || '';
        model.policyHolder.dateOfBirth = policyCustomer.dateOfBirth || '';
        model.policyHolder.gender = policyCustomer.gender || '';

        model.policyHolder.provinceCode = policyCustomer.provinceCode || '';
        model.policyHolder.cityCode = policyCustomer.cityCode || '';
        model.policyHolder.regionCode = policyCustomer.regionCode || '';

        model.policyHolder.extensionData.job1 =
          policyCustomer.extensionData.job1 || '';
        model.policyHolder.extensionData.job2 =
          policyCustomer.extensionData.job2 || '';
        model.policyHolder.extensionData.job3 =
          policyCustomer.extensionData.job3 || '';
        model.policyHolder.extensionData.job4 =
          policyCustomer.extensionData.job4 || '';
        model.policyHolder.extensionData.jobCategory =
          policyCustomer.extensionData.jobCategory || '';
        model.policyHolder.extensionData.comflexJobFlag =
          policyCustomer.extensionData.comflexJobFlag;
        // mergeJson(insured, policyCustomer)
      }
      const { job1, job2, job3, job4 } = policyCustomer.extensionData;
      if (!job4) return;
      const { dateOfBirth, gender } = policyCustomer;
      if (!dateOfBirth || !gender) return;
      if (!policyCustomer.extensionData.age) return;
      const { provinceCode, cityCode, regionCode } = policyCustomer;
      if (!provinceCode || !cityCode || !regionCode) return;
      model.insureds[0].coverages.forEach((coverage, index) => {
        let { coveragePeriod, installment, sumInsured, displayPremIndi } =
          coverage.extensionData;
        if (displayPremIndi) {
          if (
            coveragePeriod != '-1' &&
            installment != '-1' &&
            coveragePeriod &&
            installment
          ) {
            flag = true;
          } else {
            flag = false;
          }
        }
      });
      if (!flag) return;
      /* 缴费频率 */
      if (
        model.insureds[0].coverages &&
        model.insureds[0].coverages.length > 0
      ) {
        model.insureds[0].coverages.forEach((item) => {
          if (item.extensionData) {
            if (item.extensionData.installment == 'Y1') {
              item.extensionData.paymentFreq = '5';
            } else {
              item.extensionData.paymentFreq = '1';
            }
          }
        });
      }
      /* ---------- */
      //保额限定
      this.doPost('/rest/v1/policy/sa/limit', model)
        .done((data) => {
          if (data.body) {
            if (data.body.resultCode === '99999') {
              model.premium = 0;
              model.policyFee.app = 0;
              Alert.message(
                '因您所选保额超过选择的地区、职业对应的保额限制，请重新选择。'
              );
              if (that.props.parent) {
                that.props.parent.forceUpdate();
              } else {
                that.forceUpdate();
              }
              return;
            } else {
              //计算保费
              pageLoading.start(
                Lang.messages.sth.pleaseWaitWhileCalculatingPremiums
              );
              this.doPost('/rest/v1/policy/calculate', model)
                .done((data) => {
                  pageLoading.end();
                  $.extend(true, options.root, data.body);
                  if (that.props.parent) {
                    that.props.parent.forceUpdate();
                  } else {
                    that.forceUpdate();
                  }
                })
                .fail((data) => {});
            }
          }
        })
        .always(() => {});
    }, 1);
  },
};
const eventRule = function (options) {
  return EVENT_RULES[options.event].call(options.comp, options);
};

export {
  format,
  codes,
  getValueFromModel,
  setValueToModel,
  parseId,
  findEmittingId,
  findClosestIndexedEmittingId,
  findParentModel,
  mergeJson,
  findMatchedFilter,
  shouldApplyFilter,
  filterArray,
  getConcernedIdsOfRules,
  getConcernedIdsOfFilters,
  checkRule,
  eventRule,
};
