import { React } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';
// import {provsData} from '../../../../common/larea-data';
// import {occupationMapClass} from '../../../../common/occupation-map-class';

class DeclaraParent extends AtomicComponentThing {
  render() {
    return (
      <div {...this.getPrototypes()} ref="me">
        <div className="sth-label-name">{this.getLayout().label}</div>
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-declara-parent';
  }
}

export default DeclaraParent;
