import { React } from '@/common/3rd';
import { Router, Route, Switch } from '@/3rd';
import { appHistory } from '@/router';
import { Envs, Lang, Storage, Utils } from '@/common/common';
import { TRUST_KEY_KEY } from '@/common/consts';
import { isAndroid, isIIMP, isIOS, isJingBaoTong } from '@/util/precondition';
import { AsyncComponent } from '@/component/components';
// import '@/svg';
import '@/app.css';

const Sign = AsyncComponent(() => import('@/both/sign/sign'));
const MobileIndex = AsyncComponent(() => import('@/mobile/mobile-index'));
const DeskHome = AsyncComponent(() => import('@/desk/desk-home/desk-home'));
const ApiDocs = AsyncComponent(() => import('@/api-docs/api-docs'));
const Feedback = AsyncComponent(() => import('@/both/feedback/feedback'));
const Captcha = AsyncComponent(() => import('@/both/captcha/captcha'));
const JBTApp = AsyncComponent(() => import('@/app/jbt'));
const SituvisionApp = AsyncComponent(() => import('@/app/situvision'));

Lang.switchLanguage(Lang.getCurrentLanguage());

class App extends React.Component {
  componentDidMount() {
    Envs.application(this);
    Utils.installToEventEmitter({
      event: 'getLoginDataCallback',
      func: this.getLoginDataCallback,
    });
    if (isJingBaoTong() || isIIMP()) {
      const options = {
        action: 'getLoginData',
        success: 'getLoginDataCallback',
      };
      const msg = JSON.stringify(options);
      if (isAndroid()) {
        // Android
        window.__EBAO_JSSDK.callNative(msg);
        return;
      }
      if (isIOS()) {
        // iOS
        window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(msg);
      }
    }
  }

  getLoginDataCallback = (result) => {
    let message;
    if (typeof result === 'string') {
      message = JSON.parse(result);
    }
    if (isIOS() && result.success) {
      message = JSON.parse(result.msg);
    }
    let authorization = message.authorization;
    if (authorization) {
      localStorage.setItem('Authorization', authorization);
      Storage.Auth.writeToSession('Authorization', authorization);
    }
    let returnAccount = message.returnAccount;
    let token = (returnAccount && returnAccount.token) || '';
    localStorage.setItem('ntoken', token);
    Envs.holdAccount(null, returnAccount);
    // remove trust key from session storage
    Storage.Auth.removeFromSession(TRUST_KEY_KEY);
  };

  componentWillUnmount() {
    Utils.uninstallFromEventEmitter({
      event: 'getLoginDataCallback',
      func: this.getLoginDataCallback,
    });
    Envs.application(null);
  }

  renderApiDocs() {
    if (process.env.REACT_APP_API_DOCS_ENABLED) {
      return <Route path={Envs.PATH.API_DOCS} component={ApiDocs} />;
    }
  }

  render() {
    return (
      <Router history={appHistory}>
        <Switch>
          <Route path={Envs.PATH.SIGN} component={Sign} />
          <Route path={Envs.PATH.MOBILE_CAPTCHA} component={Captcha} />
          <Route path={Envs.PATH.MOBILE} component={MobileIndex} />
          <Route path={Envs.PATH.DESK_HOME} component={DeskHome} />
          <Route path={Envs.PATH.FEEDBACK} component={Feedback} />
          {this.renderApiDocs()}

          <Route path={Envs.PATH.JBT} component={JBTApp} />
          <Route path={Envs.PATH.SITUVISION} component={SituvisionApp} />
          <Route path="/*" component={Sign} />
        </Switch>
      </Router>
    );
  }
}

export default App;
