import { React, ClassNames, $, ReactDOM } from '../../../../common/3rd';
import ContainerThing from '../container';
import Button from '../component/button';
import { Envs, Utils, Storage } from '../../../../common/common';
import Lang from '../../../../common/lang';
import { ReactRouterContextWidget } from '@/component/components';
import messagePay from '../../../../mobile/policy/messagesPay.json';
import messagesAdvice from '../../../../mobile/policy/messages.json';
import { occupationMapClass } from '../../../../common/occupation-map-class';
// import PageLoading from '../../loading/page-loading'
import Share from '../../../../mobile/share/share';
import {
  PageHeader,
  PageFooter,
  TopBar,
  PageLoading,
  Alert,
  Something,
} from '../../../components';
import SmsVerified from '../../../both/smsVerified/smsVerified';
// install i18n messages
Lang.installMessages(messagePay, 'payment');
Lang.installMessages(messagesAdvice, 'advice');
// eslint-disable-next-line
const R = require('ramda');
// eslint-disable-next-line
// const { supplement } = require('../../../../util/FieldsSupplemented')
const AsyncManager = require('../../../../util/AsyncManager');
const currEnv = window.location.href.includes('/wechat/') ? 'wechat' : 'mobile';
const WECHAT = 'wechat';
// eslint-disable-next-line
const MOBILE = 'mobile';
const { XBack } = window;

class ButtonJump extends ReactRouterContextWidget {
  getModel() {
    return this.props.model;
  }
  onClickButtoned = () => {
    // 通过路由携带的关键字识别当前环境，规避对JSON的依赖。
    // modified by yanzhi.mo
    const params = Utils.getUrlProps();
    if (currEnv === WECHAT) {
      XBack.unLockRoute()(false);
      this.pushRedirect(Envs.PATH.WECHAT_PRODUCTSTORE);
    } else {
      if (!params.thirdPartyReturnUrl) {
        this.pushRedirect(Envs.PATH.MOBILE_LIFE_HOME);
      } else {
        window.location.href = Utils.doDecode(params.thirdPartyReturnUrl);
      }
    }
  };
  render() {
    return (
      <div
        className="sth-button wizard-step-bottom-button"
        onClick={this.onClickButtoned}
      >
        {this.getModel().label}
      </div>
    );
  }
}

/**
 * // 09/01/2018 - TAG: by yanzhi.mo
 *
 * @class SmartButtonJump
 * @extends {ReactRouterContextWidget}
 */
class SmartButtonJump extends ReactRouterContextWidget {
  onClickButtoned = () => {
    if (this.props.params) {
      this.replaceRedirect({ ...this.props.params, pathname: this.props.path });
    } else {
      this.replaceRedirect(this.props.path);
    }
  };

  render() {
    return (
      <div
        className="sth-button wizard-step-bottom-button"
        onClick={this.onClickButtoned}
      >
        {this.props.model.label}
      </div>
    );
  }
}

class WizardStep extends ContainerThing {
  onBackAction(e) {
    let stepName = this.props.parent.getLayout().backTo;
    let on = this.props.parent.getLayout().on;
    const rootParent = this.props.parent.getRootComponent();
    if (on) {
      let modelValue = this.props.parent.getValueFromModel(on.byId);
      if (on.value === modelValue) {
        stepName = on.backTo;
      }
    }

    if (stepName) {
      if (
        this.props.parent.getLayout().currentStepName === 'autoUnderwriting'
      ) {
        if (
          this.props.parent.getModel().policyStatus === '79' ||
          this.props.parent.getModel().policyStatus === '88'
        ) {
          //自核通过，点击物理返回键，退出投保流程
          this.historyBack();
        } else {
          rootParent.jumpTo(stepName);
        }
      } else if (this.props.parent.getLayout().currentStepName === 'step8') {
        //提示清空签名
        let alertMsg = this.props.parent.getLayout().bottom[0].alertMsg;
        let self = this;
        Alert.ask(alertMsg, function (type) {
          if (type === '2' && stepName) {
            //2表示点击确认
            self.props.parent.getModel().extensionData.currentStep = '6';
            PageLoading && PageLoading.start();
            self
              .doPost('/rest/v1/policy/update', self.props.parent.getModel())
              .done((data) => {
                let model = data.body;
                self.props.parent.props.parent.setModel(model);
                rootParent.jumpTo(stepName);
              })
              .always(() => {
                PageLoading.end();
              });
          }
        });
      } else if (
        this.props.parent.getLayout().currentStepName === 'documentList'
      ) {
        const accountId = Envs.findAccount().accountId;
        const policyId = this.props.parent.getModel().policyId;
        const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
        //判断是否在签字页面
        let me = $(ReactDOM.findDOMNode(this.props.parent.refs.me));
        if (
          me.find('#dialog') &&
          me.find('#dialog').css('display') === 'block'
        ) {
          me.find('#dialog').css('display', 'none');
          me.find('#comment_dialog')
            .parents('.page')
            .find('.page-footer')
            .css('display', 'flex');
        } else {
          PageLoading && PageLoading.start();
          this.doGet(url)
            .done((data) => {
              let {
                quoteInitData,
                quoteJson,
                styleName,
                templateInitData,
                templateJson,
              } = data.body;
              let policyModel = Something.merge(
                templateInitData,
                quoteInitData
              );
              this.props.parent.props.parent.setModel(policyModel);
              rootParent.jumpTo(stepName);
            })
            .always(() => {
              PageLoading.end();
            });
        }
      } else if (this.props.parent.getLayout().currentStepName === 'step7') {
        console.log('上传资料');
      } else {
        rootParent.jumpTo(stepName);
      }
    }
    // else if(rootParent.props.location.from === "adviceCreation"){
    //     const url = rootParent.buildPolicyUrl(Envs.PATH.MOBILE_POLICY_ADVICE_CREATION);
    //     this.pushRedirect({ pathname: url, policyId:rootParent.props.match.params.policyId, from: 'creatAdvice' });
    // }
    else {
      this.historyBack();
    }
  }
  onRightClick() {
    let username = Envs.findAccount().accountName; //用户名
    let userreg = /^B2E.*$/;
    if (username && userreg.test(username)) {
      //B2E跳转B2E主页
      this.replaceRedirect(Envs.PATH.MOBILE);
    } else {
      this.replaceRedirect(Envs.PATH.MOBILE_HOME);
    }
  }
  renderHeader() {
    if (window.location.href.includes('/wechat/')) {
      return null;
    }
    let isShare = Utils.getParameter(window.location.href, 'isShare');
    let back = true;
    let params = Utils.getUrlProps();
    let right = params.thirdPartyReturnUrl
      ? [{ value: '' }]
      : [{ value: '主页', action: this.onRightClick }];
    if (
      isShare &&
      (this.getLayout().currentStepName === 'documentList' ||
        this.getLayout().currentStepName === 'submitSignSuccess')
    ) {
      back = false;
      right = [];
    }
    if (
      isShare &&
      (this.getLayout().currentStepName === 'personalInsuranceTips' ||
        this.getLayout().currentStepName === 'insuranceConfirm' ||
        this.getLayout().currentStepName === 'agentReport' ||
        this.getLayout().currentStepName === 'personalTaxPaper' ||
        this.getLayout().currentStepName === 'insureOrder')
    ) {
      back = true;
      right = [];
    }
    return (
      <PageHeader>
        <TopBar
          title={this.getLayout().title}
          backAction={this.onBackAction}
          back={back}
          right={right}
          parent={this}
        />
      </PageHeader>
    );
    /*return <PageHeader>
            <TopBar title={this.getLayout().title}
                backAction={this.onBackAction}
                back={true}
                right={[{
                    value:"主页",
                    action: this.onRightClick
                }]}
                parent={this}/>
        </PageHeader>;*/
  }
  /**
   * 是否要画产品标题
   */
  isProductHeaderPaint() {
    return this.getLayout().productHeader === true;
  }
  /**
   * 将投保人数据同步到被保人
   */
  mapPolicyHolderToInsured() {
    const policyHolder = this.getModel().policyHolder;
    let insured = this.getModel().policyCustomers.filter((insured) => {
      return insured.customerType == 2;
    })[0];
    if (!insured.extensionData) {
      insured.extensionData = {};
    }
    insured.firstName = policyHolder.firstName;
    insured.extensionData.age = policyHolder.extensionData.age;
    insured.dateOfBirth = policyHolder.dateOfBirth;
    insured.gender = policyHolder.gender;
    insured.idType = policyHolder.idType;
    insured.idNo = policyHolder.idNo;
    insured.extensionData.idLongTermIndi =
      policyHolder.extensionData.idLongTermIndi;
    insured.extensionData.idValidTill = policyHolder.extensionData.idValidTill;
    insured.extensionData.maritalStatus =
      policyHolder.extensionData.maritalStatus;
    insured.extensionData.job1 = policyHolder.extensionData.job1;
    insured.extensionData.job2 = policyHolder.extensionData.job2;
    insured.extensionData.job3 = policyHolder.extensionData.job3;
    insured.extensionData.job4 = policyHolder.extensionData.job4;
    insured.extensionData.jobCategory = policyHolder.extensionData.jobCategory;
    insured.extensionData.height = policyHolder.extensionData.height;
    insured.extensionData.weight = policyHolder.extensionData.weight;
    insured.extensionData.annualIncome =
      policyHolder.extensionData.annualIncome;
    insured.extensionData.declarations =
      policyHolder.extensionData.declarations;
    insured.extensionData.workUnit = policyHolder.extensionData.workUnit;
    insured.extensionData.documents = [];
    insured.mobile = policyHolder.mobile;
    insured.email = policyHolder.email;
    insured.provinceCode = policyHolder.provinceCode;
    insured.cityCode = policyHolder.cityCode;
    insured.regionCode = policyHolder.regionCode;
    insured.addressLine1 = policyHolder.addressLine1;
    insured.postCode = policyHolder.postCode;
  }
  /**
   * 将被保人数据同步到受益人
   * @param {受益人} beneficiary
   */
  mapInsuredToBeneficiary(beneficiary) {
    const insured = this.getModel().policyCustomers.filter((insured) => {
      // eslint-disable-next-line
      return insured.customerType == 2;
    })[0];
    if (!beneficiary.extensionData) {
      beneficiary.extensionData = {};
    }
    beneficiary.firstName = insured.firstName;
    beneficiary.extensionData.age = insured.extensionData.age;
    beneficiary.dateOfBirth = insured.dateOfBirth;
    beneficiary.gender = insured.gender;
    beneficiary.idType = insured.idType;
    beneficiary.idNo = insured.idNo;
  }
  synchroData() {
    let policyHolder = this.getModel().policyHolder;
    let insured = this.getModel().policyCustomers.filter((insured) => {
      // eslint-disable-next-line
      return insured.customerType == 2;
    })[0];
    let beneficiarys = this.getModel().policyCustomers.filter((beneficiary) => {
      // eslint-disable-next-line
      return beneficiary.customerType == 3;
    });
    if (
      policyHolder.extensionData.job4 &&
      occupationMapClass[policyHolder.extensionData.job4].class
    ) {
      policyHolder.extensionData.jobCategory =
        occupationMapClass[policyHolder.extensionData.job4].class;
    }
    if (
      policyHolder.extensionData &&
      policyHolder.extensionData.idLongTermIndi
    ) {
      policyHolder.extensionData.idValidTill = '2200/12/31 00:00:00';
    }

    //若被保人与投保人关系为本人，则将投保人数据同步到被保人
    if (
      insured.extensionData &&
      insured.extensionData.relationWithPH &&
      // eslint-disable-next-line
      insured.extensionData.relationWithPH == '00'
    ) {
      this.mapPolicyHolderToInsured();
    } else if (
      insured.extensionData &&
      insured.extensionData.relationWithPH &&
      // eslint-disable-next-line
      insured.extensionData.relationWithPH == '23'
    ) {
      let insured = this.getModel().policyCustomers.find((insured) => {
        // eslint-disable-next-line
        return insured.customerType == 2;
      });
      insured.mobile = this.getModel().policyHolder.mobile;
    }
    if (insured.extensionData && insured.extensionData.job4) {
      insured.extensionData.jobCategory =
        occupationMapClass[insured.extensionData.job4].class;
    }
    if (insured.extensionData.idLongTermIndi) {
      insured.extensionData.idValidTill = '2200/12/31 00:00:00';
    }
    beneficiarys.forEach((beneficiary) => {
      //若受益人与被保人关系为本人，则将被保人数据同步到受益人
      if (
        beneficiary.extensionData &&
        beneficiary.extensionData.relationWithInsured &&
        // eslint-disable-next-line
        beneficiary.extensionData.relationWithInsured == '00'
      ) {
        this.mapInsuredToBeneficiary(beneficiary);
      }
    });
  }

  /**
   * 保存图片影像
   */
  isDeleteOrNot(documentItem) {
    let typeArray = [
      '0.1',
      '0.2',
      '4.1',
      '7.1',
      '2.1',
      '69.1',
      '66.1',
      '66.2',
      '1.1',
    ];
    let isDelete = true;
    typeArray.map((item) => {
      if (item == documentItem.documentType) {
        isDelete = false;
      }
    });
    return isDelete;
  }

  /**
   * 点击上一步下一步
   * @param {button对象} button
   */
  async onButtonClick(button) {
    if (button.validateLimit === true || button.validateCoverage === true) {
      let errMsg = [];
      errMsg = this.props.parent.validateCoverage(this.getModel());
      if (errMsg.length > 0) {
        Alert.message(errMsg);
        return;
      }
    }

    /**
     * add by wang.jie on 8/22/2018
     * 资料上传页面增加逻辑处理
     */
    if (
      (button.phase === '6' && button.label == '下一步') ||
      (button.phase === '06' && button.label == '下一步')
    ) {
      let model = this.getModel();
      let customers = model.policyCustomers;
      customers.map((customer) => {
        if (
          customer.extensionData.documents &&
          customer.extensionData.documents.length > 0 &&
          customer.extensionData.documents[0].uri
        ) {
          let docType =
            customer.extensionData.documents[0].documentType.split('.')[0];
          let cusType = customer.idType;
          if (cusType == 'E') {
            cusType = '69';
          } else if (cusType == 'B') {
            cusType = '66';
          }
          if (docType != cusType) {
            customer.extensionData.documents = [];
            customer.extensionData.documents.push({
              documentType: cusType + '.1',
              coverageCode: '',
              uri: '',
              front: 'Y',
              uploadTime: '',
            });
            if (cusType == '66' || cusType == '0') {
              customer.extensionData.documents.push({
                documentType: cusType + '.2',
                coverageCode: '',
                uri: '',
                front: 'N',
                uploadTime: '',
              });
            }
          }
          return;
        }
        if (customer.customerType != 2) {
          customer.extensionData.documents = [];
          let sIdType = customer.idType;
          if (customer.idNo == model.policyHolder.idNo) {
            return;
          }
          if (sIdType == 'E') {
            sIdType = '69';
          } else if (sIdType == 'B') {
            sIdType = '66';
          }
          customer.extensionData.documents.push({
            documentType: sIdType + '.1',
            coverageCode: '',
            uri: '',
            front: 'Y',
            uploadTime: '',
          });
          if (sIdType == '66' || sIdType == '0') {
            customer.extensionData.documents.push({
              documentType: sIdType + '.2',
              coverageCode: '',
              uri: '',
              front: 'N',
              uploadTime: '',
            });
          }
        }
      });
    }

    /**
     * add by cheng.fan on Jun.8,2018
     * 法定受益人时清除受益人模型
     */
    if (button.check === true) {
      let model = this.getModel();
      let insured =
        model.policyCustomers.find(
          (customer) => customer.customerType == '2'
        ) || {};
      if (insured.extensionData.legalIndi === true) {
        model.policyCustomers = model.policyCustomers.filter(
          (customer) => customer.customerType == '2'
        );
      }
    }

    const parent = this.props.parent;
    //保存下一步投保步骤 update by wendy.gu 2018 Jan.9
    let saveCurrentStep = button.saveStep || null;
    if (button.on) {
      let modelValue = this.getValueFromModel(button.on.byId);
      // eslint-disable-next-line
      if (modelValue == button.on.value) {
        if (!button.toStepBuff) {
          button.toStepBuff = button.toStep;
        }
        button.toStep = button.on.toStep;
      } else if (button.toStepBuff) {
        button.toStep = button.toStepBuff;
        button.toStepBuff = null;
      }
    }
    // eslint-disable-next-line
    if (button.synchroData === true) {
      this.synchroData();
    }
    //add by wendy.gu 2018 Jan.9
    // eslint-disable-next-line
    if (saveCurrentStep == 4) {
      let insured = this.getModel().policyCustomers.filter((insured) => {
        // eslint-disable-next-line
        return insured.customerType == 2;
      })[0];
      //若被保人与投保人关系为本人 没有被保人告知页
      if (
        insured.extensionData &&
        insured.extensionData.relationWithPH &&
        // eslint-disable-next-line
        insured.extensionData.relationWithPH == '00'
      ) {
        saveCurrentStep = '5';
      }
    }
    if (button.request === true) {
      if (this.getModel().policyId) {
        let errMsg = [];
        if (button.c === true) {
          errMsg = parent.validateCoverage(this.getModel());
        }
        if (errMsg.length > 0) {
          Alert.message(errMsg);
          return;
        }

        if (button.phase === '24') {
          //分享时验证电子签名页能否提交
          window.eventEmitter.emit('genJsonData'); //点击提交出发监听事件
        } else if (button.phase === '7') {
          //资料上传下一步清空签名信息
          let interdict = false;
          let model = this.getModel();
          model.policyCustomers.map((customer) => {
            let documents = customer.extensionData.documents;
            if (documents && documents.length > 0) {
              documents.map((document) => {
                if (!document.uri) {
                  interdict = true;
                  return;
                }
              });
            }
          });
          if (interdict) {
            Alert.message('影像资料缺失，请返回首页重新投保');
            return;
          }
          let documents = [];
          // eslint-disable-next-line
          this.getModel().extensionData.documents.map((documentItem, index) => {
            if (parseFloat(documentItem.documentType) < -99) {
              documents.push(documentItem);
            }
            let isDelete = this.isDeleteOrNot(documentItem);
            if (!isDelete) {
              documents.push(documentItem);
            }
          });
          this.getModel().extensionData.documents = documents;
          let policyDocuments = [];
          // eslint-disable-next-line
          this.getModel().policyHolder.extensionData.documents.map(
            (documentItem, index) => {
              if (parseFloat(documentItem.documentType) > -2) {
                policyDocuments.push(documentItem);
              }
            }
          );
          this.getModel().policyHolder.extensionData.documents =
            policyDocuments;
          let insuredDocuments = [];
          // eslint-disable-next-line
          this.getModel().policyCustomers.map((item, index, arr) => {
            if (item.customerType === '2') {
              if (item.extensionData && item.extensionData.documents) {
                // eslint-disable-next-line
                item.extensionData.documents.map((documentItem, index) => {
                  if (parseFloat(documentItem.documentType) > -2) {
                    insuredDocuments.push(documentItem);
                  }
                });
                item.extensionData.documents = insuredDocuments;
              }
            }
          });
          //删除分享数据
          let params = {
            shareIds: [
              'shareSignature1' + this.getModel().policyId,
              'shareSignature2' + this.getModel().policyId,
            ],
          };
          PageLoading && PageLoading.start();
          this.doPost('/rest/v1/share/delete', params)
            .done((data) => {
              if (data.resultCode === '0') {
                //保存当前步骤 update by wendy.gu 2018 Jan.9
                if (saveCurrentStep) {
                  this.getModel().extensionData.currentStep = saveCurrentStep;
                }
                PageLoading && PageLoading.start();
                let odata = this.getModel();
                this.doPost('/rest/v1/policy/update', odata)
                  .done((data) => {
                    let model = data.body;
                    parent.setModel(model);
                    if (button.toStep) {
                      parent.jumpTo(button.toStep);
                    }
                  })
                  .always(() => {
                    PageLoading.end();
                  });
              }
            })
            .always(() => {
              PageLoading.end();
            });
        } else {
          //保存当前步骤 update by wendy.gu 2018 Jan.9
          if (saveCurrentStep) {
            this.getModel().extensionData.currentStep = saveCurrentStep;
          }
          let policyModel = this.getModel();
          PageLoading && PageLoading.start();
          if (button.calc === 'calculatePolicy') {
            let url = '/rest/v1/policy/calculate';
            await this.doPost(url, policyModel).done((data) => {
              //$.extend(true, policyModel, data.body);
              if (policyModel.insureds[0].coverages.length > 1) {
                let cov = policyModel.insureds[0].coverages;
                cov.sort(
                  (a, b) => a.extensionData.sequence > b.extensionData.sequence
                );
              }
            });
          }
          let self = this;
          let odata = this.getModel();
          let bankCard = odata.extensionData.paymentInfo.bankCard;
          if (bankCard) {
            //修改银行卡号空格问题
            let arr = bankCard.split(' ');
            bankCard = arr.join('');
            odata.extensionData.paymentInfo.bankCard = bankCard;
          }
          //上传数据update方法
          this.doPost('/rest/v1/policy/update', odata)
            .done((data) => {
              let model = data.body;
              if (saveCurrentStep == 3) {
                let bankList = {
                  工商银行: 'ICBC',
                  农业银行: 'ABC',
                  中国银行: 'BOC',
                  建设银行: 'CCB',
                  交通银行: 'BCOM',
                  邮储银行: 'CPSRB',
                  兴业银行: 'CIB',
                  中信银行: 'CITIC',
                  光大银行: 'CEB',
                  广发银行: 'CGB',
                  浦发银行: 'SPDB',
                  宁波银行: 'NBBK',
                  北京银行: 'BOB',
                  渤海银行: 'BHBK',
                  北京农商行: 'BRCB',
                  平安银行: 'PINGAN',
                };
                let oIdenAuthDirect = {};
                let res = data.body;
                oIdenAuthDirect['bankCardName'] = res.policyHolder.fullName;
                oIdenAuthDirect['bankCardNo'] =
                  res.extensionData.paymentInfo.bankCard;
                oIdenAuthDirect['bankCode'] =
                  bankList[res.extensionData.paymentInfo.bankName];
                oIdenAuthDirect['mobile'] =
                  res.extensionData.paymentInfo.mobile;
                oIdenAuthDirect['certId'] = res.policyHolder.idNo;
                oIdenAuthDirect['orderNo'] = res.quotationNo;
                self
                  .doPost('/rest/v1/payment/idenAuthDirect', oIdenAuthDirect)
                  .done((data) => {
                    if (data.code != '00000') {
                      PageLoading.end();
                      Alert.askInsure(data.desc, function (type) {
                        if (type == 2) {
                          PageLoading.end();
                          if (model.insureds[0].coverages.length > 1) {
                            let cov = model.insureds[0].coverages;
                            cov.sort(
                              (a, b) =>
                                a.extensionData.sequence >
                                b.extensionData.sequence
                            );
                          }
                          parent.setModel(model);
                          if (button.toStep) {
                            parent.jumpTo(button.toStep);
                          }
                        } else {
                          PageLoading.end();
                        }
                      });
                    } else {
                      PageLoading.end();
                      if (model.insureds[0].coverages.length > 1) {
                        let cov = model.insureds[0].coverages;
                        cov.sort(
                          (a, b) =>
                            a.extensionData.sequence > b.extensionData.sequence
                        );
                      }
                      parent.setModel(model);
                      if (button.toStep) {
                        parent.jumpTo(button.toStep);
                      }
                    }
                  })
                  .fail((data) => {
                    PageLoading.end();
                  });
              } else {
                PageLoading.end();
                if (model.insureds[0].coverages.length > 1) {
                  let cov = model.insureds[0].coverages;
                  cov.sort(
                    (a, b) =>
                      a.extensionData.sequence > b.extensionData.sequence
                  );
                }
                parent.setModel(model);
                if (button.toStep) {
                  parent.jumpTo(button.toStep);
                }
              }
            })
            .always(() => {
              //异步处理，影像下一步骤的loading.start() udpate by wendy.gu 2018 Jan.12
              // PageLoading.end();
            });
        }
      } else {
        let policyModel = this.getModel();
        //保存当前步骤 update by wendy.gu 2018 Jan.9
        if (saveCurrentStep) {
          policyModel.extensionData.currentStep = saveCurrentStep;
        }
        PageLoading && PageLoading.start();
        if (button.calc === 'calculatePolicy') {
          let url = '/rest/v1/policy/calculate';
          await this.doPost(url, policyModel).done((data) => {
            //$.extend(true, policyModel, data.body);
            if (policyModel.insureds[0].coverages.length > 1) {
              let cov = policyModel.insureds[0].coverages;
              cov.sort(
                (a, b) => a.extensionData.sequence > b.extensionData.sequence
              );
            }
          });
        }
        policyModel.insureds[0].coverages.forEach((insured) => {
          delete insured.coverageId;
        });
        this.doPost('/rest/v1/policy/create', policyModel)
          .done((data) => {
            let model = data.body;
            if (model.insureds[0].coverages.length > 1) {
              let cov = model.insureds[0].coverages;
              cov.sort(
                (a, b) => a.extensionData.sequence > b.extensionData.sequence
              );
            }
            parent.setModel(model);
            if (button.toStep) {
              parent.jumpTo(button.toStep);
            }
          })
          .always(() => {
            PageLoading && PageLoading.end();
          });
      }
    } else {
      if (button.alertMsg) {
        //核对订单上一步提示会清空签名
        let self = this;
        Alert.ask(button.alertMsg, function (type) {
          if (type === '2' && button.toStep) {
            //2表示点击确认
            if (saveCurrentStep) {
              self.getModel().extensionData.currentStep = saveCurrentStep;
            }
            PageLoading && PageLoading.start();
            self
              .doPost('/rest/v1/policy/update', self.getModel())
              .done((data) => {
                let model = data.body;
                parent.setModel(model);
                if (button.toStep) {
                  parent.jumpTo(button.toStep);
                }
              })
              .always(() => {
                PageLoading.end();
              });
          }
        });
        return;
      }
      if (button.toStep) {
        parent.jumpTo(button.toStep);
      }
      if (button.goPay === true) {
        //去支付  add by wendy.gu
        if (currEnv === WECHAT) {
          const asyncManager = new AsyncManager(this);
          SmsVerified.init(this, { loading: PageLoading }, (status, msg) => {
            if (status === SmsVerified.SUCCESS) {
              asyncManager.issue(this.getModel(), { loading: PageLoading });
            } else if (status === SmsVerified.FAILED) {
              parent.props.model.extensionData.paymentInfo.paymentMsg = msg;
              parent.jumpTo('paymentFailure');
            } else {
              parent.jumpTo('paymentIng');
            }
          }); // 回调函数获取结果
          // asyncManager.paidByBankCard(this.getModel(), { loading: PageLoading })
        } else {
          // eslint-disable-next-line
          const asyncManager = new AsyncManager(this);
          SmsVerified.init(this, { loading: PageLoading }, (status, msg) => {
            if (status === SmsVerified.SUCCESS) {
              setTimeout(() => {
                this.issue();
              }, 500);
            } else if (status === SmsVerified.FAILED) {
              parent.props.model.extensionData.paymentInfo.paymentMsg = msg;
              parent.jumpTo('paymentFailure');
            } else {
              parent.jumpTo('paymentIng');
            }
          });
        }
      }

      // added by yanzhi.mo
      if (button.goAuto && currEnv === WECHAT) {
        const asyncManager = new AsyncManager(this);
        asyncManager.automatic(this.props.model, {
          loading: PageLoading,
          something: Something,
          alert: Alert,
          currEnv,
        });
      }

      //分享回执 add by wendy.gu 2017 Dec.12
      if (button.shareReceipt === true) {
        //let accountId = Envs.findAccount();//.accountId;
        //获取5项基本信息
        let { fullName, idType, idNo, gender, dateOfBirth, mobile } =
          this.getModel().policyHolder;
        let policyNo = this.getModel().policyNo || '';
        fullName = Utils.encodeURI(fullName);
        let url;
        if (Envs.is_dev()) {
          url = 'https://dev-b2c.beijinglife.com.cn';
        } else if (Envs.is_uat()) {
          url = 'https://uat-b2c.beijinglife.com.cn';
        } else if (Envs.is_prod()) {
          url = 'https://b2c.beijinglife.com.cn';
        }
        let shareUrl = `${url}/bjlife${Envs.PATH.WECHAT_SAHRE_RECEIPT}?`;
        let title = '邀请回执签收';
        let desc = '回执签收';
        shareUrl = `${shareUrl}fullName=${fullName}&idType=${idType}&idNo=${idNo}&gender=${gender}&dateOfBirth=${dateOfBirth}&phone=${mobile}&policyNo=${policyNo}`;
        if (Envs.is_dev()) {
          let appId = Utils.getAppId();
          shareUrl = Utils.encodeURI(shareUrl);
          shareUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${shareUrl}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        } else if (Envs.is_uat()) {
          let appId = Utils.getAppIdByUAT();
          shareUrl = Utils.encodeURI(shareUrl);
          shareUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${shareUrl}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        } else if (Envs.is_prod()) {
          let appId = Utils.getAppIdByProd();
          shareUrl = Utils.encodeURI(shareUrl);
          shareUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${shareUrl}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        }
        try {
          // if (Utils.isThirdParty()) {
          //   Utils.weixinClick(shareUrl);
          // } else {
          Share.share(title, desc, shareUrl, '');
          // }
        } catch (e) {
          //Alert.message(e);
        }
      }
    }
  }

  //出单 add by wendy.gu
  issue() {
    if (currEnv === WECHAT) {
      const asyncManager = new AsyncManager(this);
      asyncManager.issue(this.props.model, {
        loading: PageLoading,
        something: Something,
        alert: Alert,
        currEnv,
      });
    } else {
      PageLoading && PageLoading.start();
      const parent = this.props.parent;
      let oModel = this.getModel();
      let policyCustomersJson = localStorage.getItem('policyCustomers');
      if (policyCustomersJson) {
        //oModel.policyCustomers = ;
        $.each(oModel.policyCustomers, function (key, value) {
          if (value.customerType == 2) {
            oModel.policyCustomers.splice(key, 1);
            oModel.policyCustomers.unshift(value);
          }
        });
        let oPolicyCustomers = JSON.parse(policyCustomersJson);
        oPolicyCustomers.map((currentValue, index) => {
          if (index > 0) {
            oModel.policyCustomers[index] = currentValue;
          }
        });
      }
      this.doPost('/rest/v1/policy/issue', oModel)
        .done((data) => {
          // eslint-disable-next-line
          if (data.resultCode == '1') {
            //成功
            parent.setModel(data.body);
            parent.jumpTo('successfulInsurance'); //投保成功
            localStorage.removeItem('policyCustomers');
          } else {
            //赋值投保失败原因
            parent.props.model.extensionData.paymentInfo.insuranceMsg =
              data.resultMsg;
            parent.jumpTo('failureForInsurance'); //投保失败
          }
        })
        .always(() => {
          PageLoading && PageLoading.end();
        });
    }
  }
  renderBottomButton() {
    let bottoms = this.getLayout().bottom;
    if (!bottoms || bottoms.lenght) {
      return;
    }
    if (
      Utils.getParameter(window.location.href, 'isShare') &&
      this.getLayout().currentStepName === 'signatureList'
    ) {
      bottoms = this.getLayout().bottomShare;
    }
    return bottoms.map((button, index) => {
      //update by wendy.gu 2018 Jan.17
      if (Utils.isThirdParty() && button.thirdPartyHidden === true) return '';
      if (button.goHome && currEnv === WECHAT) {
        // return <ButtonJump key={`button-${index}`} model={button} />
        return (
          <SmartButtonJump
            key={`button-${index}`}
            model={button}
            path={Envs.PATH.WECHAT_MY_SERVICE}
            params={{
              policyNo: this.getModel().policyNo,
              userName: this.getModel().policyHolder.mobile,
            }}
          />
        );
      }
      if (button.goHome && currEnv !== WECHAT) {
        return <ButtonJump key={`button-${index}`} model={button} />;
      }
      // 08/01/2018 - TAG: by yanzhi.mo - 保单详情
      if (button.policyDetail) {
        return (
          <SmartButtonJump
            key={`button-${index}`}
            model={button}
            path={Envs.PATH.WECHAT_MY_POLICY_DETAIL}
            params={{ policyNo: this.getModel().policyNo }}
          />
        );
      }
      return (
        <Button
          {...{
            key: `button-${index}`,
            layout: $.extend({}, button, {
              action: this.onButtonClick.bind(this, button),
              class: 'wizard-step-bottom-button',
            }),
            parent: this,
            model: this.getModel(),
          }}
        />
      );
    });
  }
  renderFooter() {
    if (!this.getLayout().bottom) {
      return null;
    }
    return <PageFooter>{this.renderBottomButton()}</PageFooter>;
  }
  getBodyClassName() {
    if (window.location.href.includes('/wechat/')) {
      return 'page-body noheader';
    } else {
      return 'page-body';
    }
  }
  getDefaultClassName() {
    return 'sth-wizard-step page';
  }
  onBackClick = () => {
    const params = $.deparam(window.location.search.substring(1));
    window.location.replace(params.from);
  };
  getInternalLayout() {
    const layout = super.getInternalLayout();
    if (this.isProductHeaderPaint()) {
      layout['--header'] = {
        type: 'productInfoHeader',
        pos: { row: -9999 },
      };
    }
    return layout;
  }
}
class Wizard extends ContainerThing {
  constructor(props, context) {
    super(props, context);
    let currentStepIndex =
      this.getRootComponent().props.match.params.toStep ||
      Utils.getUrlProps().toStep;

    if (currentStepIndex) {
      currentStepIndex = parseInt(currentStepIndex, 10);
    }
    /**
     * 3 - 投保人告知
     * 4 - 被保人告知
     * 7 - 核对订单
     * 19- 业务员告知书
     */
    this.state.currentIndex = currentStepIndex || 0;
    this.state.stepNames = [];
    let shareType = null;
    if (
      Utils.getParameter &&
      Utils.getParameter(window.location.href, 'isShare') &&
      Utils.getParameter(window.location.href, 'shareType')
    ) {
      shareType = parseInt(
        Utils.getParameter(window.location.href, 'shareType'),
        10
      );
    }
    this.state.currentSignaturePerson = shareType || 3; //当前进入签名人1投保人  2被保人  3代理人  5第二被保人
  }
  renderCell(model, layout, index) {
    let stepClassName = 'hide-page';

    if (index === this.state.currentIndex) {
      stepClassName = 'current-page';
    } else if (index < this.state.currentIndex) {
      stepClassName = 'prev-page';
    } else if (index > this.state.currentIndex) {
      stepClassName = 'next-page';
    }
    if (index != 24) {
      stepClassName = stepClassName + ' scroll-touch';
    }

    return (
      <WizardStep
        {...{
          model: model,
          layout: $.extend({}, layout, {
            class: ClassNames(layout.class, stepClassName),
          }),
          parent: this,
          key: layout.id,
          ref: layout.id,
          index: index,
        }}
      />
    );
  }

  render() {
    const model = this.getModel();
    this.state.stepNames = [];
    return (
      <div {...this.getPrototypes()} ref="me">
        {this.getSortedLayout(this.getInternalLayout()).map((cell, index) => {
          this.state.stepNames.push(cell.currentStepName);
          return this.renderCell(model, cell, index);
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-wizard';
  }
  jumpTo(stepName) {
    this.setState({
      currentIndex: this.state.stepNames.indexOf(stepName),
      prevIndex: this.state.currentIndex,
    });
  }
  setModel(model) {
    this.props.parent.setModel(model);
  }
  //险种信息‘下一步’时　校验投保规则
  validateCoverage(model) {
    // eslint-disable-next-line
    const policyCustomers = model.policyCustomers.filter(
      (item) => item.customerType == '2'
    );
    const dateOfBirth1 = policyCustomers[0].dateOfBirth;
    const dateOfBirth2 = model.policyHolder.dateOfBirth;
    const relationWithPH = policyCustomers[0].extensionData.relationWithPH;
    const mainCov = model.insureds[0].coverages.filter(
      (cov) => cov.extensionData.coverageType === 'M'
    );
    const { installment, coveragePeriod, sumInsured } =
      mainCov[0].extensionData;
    const premium = model.premium;
    let LIMIT = this.props.parent.getLimit() || {};

    let erros = [];

    //职业
    const jobCategory = policyCustomers[0].extensionData.jobCategory;
    if (
      LIMIT.declinature &&
      LIMIT.declinature.jobCategory.includes(jobCategory)
    ) {
      erros.push('被保人职业等级不符合投保条件');
    }

    //复杂情况
    if (LIMIT.multiple) {
      let multiple = LIMIT.multiple;
      //被保人职业
      let JobLimit = multiple.declinature.jobCategory.includes(jobCategory);
      //被保人年龄
      let insuredAgeLimit = false;
      if (
        multiple.insuredMinAge &&
        multiple.insuredMinAge.type === 'days' &&
        Utils.getDaysFromBirthday(dateOfBirth1) < multiple.insuredMinAge.value
      ) {
        insuredAgeLimit = true;
      }
      if (
        multiple.insuredMinAge &&
        multiple.insuredMinAge.type === 'years' &&
        Utils.age(dateOfBirth1) < multiple.insuredMinAge.value
      ) {
        insuredAgeLimit = true;
      }
      if (
        multiple.insuredMaxAge &&
        multiple.insuredMaxAge.type === 'years' &&
        Utils.age(dateOfBirth1) > multiple.insuredMaxAge.value
      ) {
        insuredAgeLimit = true;
      }
      //投保人年龄
      let holderAgeLimit = false;
      if (
        multiple.holderMaxAge &&
        multiple.holderMaxAge.type === 'days' &&
        Utils.getDaysFromBirthday(dateOfBirth1) < multiple.holderMaxAge.value
      ) {
        holderAgeLimit = true;
      }
      if (
        multiple.holderMaxAge &&
        multiple.holderMaxAge.type === 'years' &&
        Utils.age(dateOfBirth1) < multiple.holderMaxAge.value
      ) {
        holderAgeLimit = true;
      }
      if (
        multiple.holderMaxAge &&
        multiple.holderMaxAge.type === 'years' &&
        Utils.age(dateOfBirth1) > multiple.holderMaxAge.value
      ) {
        holderAgeLimit = true;
      }
      if (JobLimit && insuredAgeLimit && holderAgeLimit) {
        erros.push(
          '被保人年龄小于18岁，投保人年龄大于60岁，且被保人职业等级不符合投保条件'
        );
      }
    }

    //基础信息
    if (!dateOfBirth1) {
      erros.push(Lang.messages.advice.pleaseChooseTheInsuredPersonsBirthday);
    } else {
      //若是数组，则有多个条件
      if (Array.isArray(LIMIT.insuredMaxAge)) {
        LIMIT.insuredMaxAge.forEach((insuredMaxAge, index) => {
          if (
            insuredMaxAge.type === 'years' &&
            Utils.age(dateOfBirth1) > insuredMaxAge.value
          ) {
            erros.push(
              Lang.messages.advice.cannotbelowerthantheminiage3.replace(
                '$',
                insuredMaxAge.value + Lang.messages.advice.year
              )
            );
          }
          if (
            insuredMaxAge.type === 'relaWith' &&
            Utils.age(dateOfBirth1) > insuredMaxAge.value &&
            insuredMaxAge.relaValue === relationWithPH
          ) {
            erros.push(
              '当被保人为子女时，' +
                Lang.messages.advice.cannotbelowerthantheminiage3.replace(
                  '$',
                  insuredMaxAge.value + Lang.messages.advice.year
                )
            );
          }
        });
      } else {
        if (
          LIMIT.insuredMaxAge.type === 'years' &&
          Utils.age(dateOfBirth1) > LIMIT.insuredMaxAge.value
        ) {
          erros.push(
            Lang.messages.advice.cannotbelowerthantheminiage3.replace(
              '$',
              LIMIT.insuredMaxAge.value + Lang.messages.advice.year
            )
          );
        }
      }
      if (Array.isArray(LIMIT.insuredMinAge)) {
        LIMIT.insuredMinAge.forEach((insuredMinAge, index) => {
          if (
            insuredMinAge.type === 'days' &&
            Utils.getDaysFromBirthday(dateOfBirth1) < insuredMinAge.value
          ) {
            erros.push(
              Lang.messages.advice.cannotbelowerthantheminiage.replace(
                '$',
                insuredMinAge.value + Lang.messages.advice.day
              )
            );
          }
          if (
            insuredMinAge.type === 'years' &&
            Utils.age(dateOfBirth1) < insuredMinAge.value
          ) {
            erros.push(
              Lang.messages.advice.cannotbelowerthantheminiage.replace(
                '$',
                insuredMinAge.value + Lang.messages.advice.year
              )
            );
          }
        });
      } else {
        if (
          LIMIT.insuredMinAge.type === 'days' &&
          Utils.getDaysFromBirthday(dateOfBirth1) < LIMIT.insuredMinAge.value
        ) {
          erros.push(
            Lang.messages.advice.cannotbelowerthantheminiage.replace(
              '$',
              LIMIT.insuredMinAge.value + Lang.messages.advice.day
            )
          );
        }
        if (
          LIMIT.insuredMinAge.type === 'years' &&
          Utils.age(dateOfBirth1) < LIMIT.insuredMinAge.value
        ) {
          erros.push(
            Lang.messages.advice.cannotbelowerthantheminiage.replace(
              '$',
              LIMIT.insuredMinAge.value + Lang.messages.advice.year
            )
          );
        }
      }
    }

    if (!dateOfBirth2) {
      erros.push(Lang.messages.advice.pleasechoosetheinsuredpersonsBirthday);
    } else {
      if (
        LIMIT.holderMinAge.type === 'years' &&
        Utils.age(dateOfBirth2) < LIMIT.holderMinAge.value
      ) {
        erros.push(
          Lang.messages.advice.cannotbelowerthantheminiage2.replace(
            '$',
            LIMIT.holderMinAge.value + Lang.messages.advice.year
          )
        );
      }
    }

    //保额、缴费期间
    // eslint-disable-next-line
    if (installment == '-1')
      erros.push(Lang.messages.advice.pleasechoosethepaymentperiod);
    // eslint-disable-next-line
    if (coveragePeriod == '-1')
      erros.push(Lang.messages.advice.pleaseselectthewarrantyperiod);
    if (LIMIT.minSumInsured && sumInsured < LIMIT.minSumInsured)
      erros.push(
        Lang.messages.advice.cannotbelowerthantheminimumamount.replace(
          '$',
          LIMIT.minSumInsured
        )
      );
    //附加险
    const addCoverage = LIMIT.addCoverage;
    const coverages = model.insureds[0].coverages;
    const mainCoverages0 = coverages.filter(
      (cov) => cov.extensionData.coverageType === 'M'
    );
    const mainCoverages = mainCoverages0[0];
    if (addCoverage) {
      addCoverage.forEach((cov, index) => {
        const currentAddCov0 = coverages.filter(
          (item) => item.coverageName === cov.coverageName
        );
        const currentAddCov = currentAddCov0[0];
        if (!currentAddCov.extensionData.displayPremIndi) return; //如果没有选择，则不校验
        //必选校验
        const currentInstallment = currentAddCov.extensionData.installment;
        // eslint-disable-next-line
        if (currentInstallment == -1) {
          erros.push('请选择' + currentAddCov.coverageName + '的交费期间');
        }
        const currentPeriod = currentAddCov.extensionData.coveragePeriod;
        // eslint-disable-next-line
        if (currentPeriod == -1) {
          erros.push('请选择' + currentAddCov.coverageName + '的保障期限');
        }

        const mainCovSumInsured = mainCoverages.extensionData.sumInsured * 1; //最大保额校验
        if (cov.maxSumInsured) {
          const currentAddCovSumInsured =
            currentAddCov.extensionData.sumInsured * 1;
          if (cov.maxSumInsured.type === 'dependOnMain') {
            //依赖主险
            if (
              currentAddCovSumInsured >
              mainCovSumInsured * cov.maxSumInsured.value
            ) {
              erros.push(
                currentAddCov.coverageName +
                  '的保额不能大于主险保额的' +
                  cov.maxSumInsured.value * 100 +
                  '%'
              );
            }
          }
          if (cov.maxSumInsured.type === 'rangeOfMainCoverageFee') {
            //依赖主险区间
            if (cov.maxSumInsured.value) {
              cov.maxSumInsured.value.forEach((range, index) => {
                let rangMax;
                let rangMin;
                if (range.on.indexOf('+') > -1) {
                  let rangLimit = range.on.split('+');
                  rangMin = rangLimit[0] * 1;
                } else {
                  let rangLimit = range.on.split('-');
                  rangMax = rangLimit[1] * 1;
                  rangMin = rangLimit[0] * 1;
                }
                if (
                  mainCovSumInsured <= rangMax &&
                  mainCovSumInsured > rangMin &&
                  currentAddCovSumInsured > range.max * 1
                ) {
                  // eslint-disable-next-line
                  erros.push(
                    '主险保额在' +
                      rangMin +
                      '-' +
                      rangMax +
                      '之间' +
                      '时' +
                      currentAddCov.coverageName +
                      '的保额不能超过' +
                      range.max
                  );
                }
                if (
                  mainCovSumInsured > rangMin &&
                  currentAddCovSumInsured > range.max * 1 &&
                  range.on.indexOf('+') > -1
                ) {
                  erros.push(
                    '主险保额大于' +
                      rangMin +
                      '时' +
                      currentAddCov.coverageName +
                      '的保额不能超过' +
                      range.max
                  );
                }
              });
            }
          }
        }
        //最小保额校验
        if (cov.minSumInsured) {
          const currentAddCovSumInsured =
            currentAddCov.extensionData.sumInsured * 1;
          if (currentAddCovSumInsured < cov.minSumInsured) {
            erros.push(
              currentAddCov.coverageName +
                '的最低保额不能小于' +
                cov.minSumInsured
            );
          }
        }
      });
    }
    if (!premium) erros.push(Lang.messages.advice.pleasecalculatepremiums);
    return erros;
  }
}
export default Wizard;
