import Text from './text';

/**
 * integer input
 */
class Integer extends Text {
  static PATTERN = /^[0-9]\d*$/;
  getDefaultValidators() {
    return () => {
      let value = this.getValue();
      if (!Integer.PATTERN.test(value)) {
        this.raiseValidationFailure('notNum');
      }
    };
  }
}

export default Integer;
