import { Lang } from '../../../../common/common';
import { Spread } from '../component/spread';

/**
 * 费用展示
 */
class PremiumSpread extends Spread {
  getColumnIds() {
    return ['name', 'sumInsured', 'period', 'installment', 'premium'];
  }

  getColumnNames() {
    return [
      '',
      Lang.messages.sth.sumInsured,
      Lang.messages.sth.period,
      Lang.messages.sth.installment,
      Lang.messages.sth.premium,
    ];
  }

  getColumnWidth(columnIndex) {
    return [100, 25, 25, 25, 25][columnIndex] + '%';
  }
}

export { PremiumSpread };
