import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 利益演示
 */
const ILLUS_LABEL = [
  {
    label: '身故保险金',
    code: 22,
    value: 0,
  },
  {
    label: '轻症保险金',
    code: 45,
    value: 0,
  },
  {
    label: '重疾',
    code: 53,
    value: 0,
  },
  {
    label: '特定疾病保险金',
    code: 56,
    value: 0,
  },
  {
    label: '全残保险金',
    code: 170,
    value: 0,
  },
  {
    label: '豁免保费',
    code: 226,
    value: 0,
  },
  {
    label: '生存金',
    code: 20,
    value: 0,
  },
  {
    label: '现金价值',
    code: 17,
    value: 0,
  },
  {
    label: '养老保险金',
    code: 980,
    value: 0,
  },
  {
    label: '教育金',
    code: 981,
    value: 0,
  },
  {
    label: '投保人豁免',
    code: 982,
    value: 0,
  },
  {
    label: '祝寿金',
    code: 228,
    value: 0,
  },
  {
    label: '航空意外身故额外保险金',
    code: 240,
    value: 0,
  },
  {
    label: '高度残疾保险金',
    code: 241,
    value: 0,
  },
  {
    label: '疾病终末期保险金',
    code: 47,
    value: 0,
  },
  {
    label: '中症疾病保险金',
    code: 997,
    value: 0,
  },
  {
    label: '被保人豁免',
    code: 998,
    value: 0,
  },
  {
    label: '重疾保险金',
    code: 46,
    value: 0,
  },
];
const TEMP_ILLUS_LABEL = [];
let BENEFICIARY_ILLUS = null;

class BenefitPresentation extends AtomicComponentThing {
  state = {
    value: 1,
    age: 0,
  };

  componentDidMount() {}

  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    let policyId = nextProps.model.policyId;
    let age = nextProps.model.policyCustomers[0].extensionData.age;
    this.setState({ age });
    if (policyId) {
      this.getBeneficiary(policyId);
    }
  }

  async getBeneficiary(id) {
    let policyId = id;
    let performanceType = 2;
    let url = `/rest/v1/policy/illus?policyId=${policyId}&performanceType=${performanceType}`;
    await this.doGet(url).done((res) => {
      BENEFICIARY_ILLUS = res.body;
      this.filterDataByAge(1);
      this.forceUpdate();
      // console.log(BENEFICIARY_ILLUS)
    });
  }

  renderSelection() {
    return (
      <div className="selection">
        <span>当被保人</span>
        <select
          value={this.state.value}
          onChange={(e) => this.onInsuredAgeChange(e.target.value)}
        >
          {BENEFICIARY_ILLUS &&
            BENEFICIARY_ILLUS.pts.length > 0 &&
            BENEFICIARY_ILLUS.pts[0].pys.map((item, index) => {
              return (
                <option key={index} value={item.py}>
                  {item.py * 1 + this.state.age * 1}
                </option>
              );
            })}
        </select>
        <span>岁时</span>
      </div>
    );
  }

  onInsuredAgeChange(value) {
    let min = BENEFICIARY_ILLUS.pts[0].pys[0].py * 1 || 1;
    let max = BENEFICIARY_ILLUS.pts[0].pys.length * 1 || 105;
    this.refs.range.min = min;
    this.refs.range.max = max;
    this.filterDataByAge(value);
    this.setState({ value });
  }

  filterDataByAge(age) {
    if (!BENEFICIARY_ILLUS.pts || !BENEFICIARY_ILLUS.pts.length > 0) {
      return;
    }
    let data = BENEFICIARY_ILLUS.pts[0].pys;
    ILLUS_LABEL.forEach((item, index) => {
      let sample = data[age - 1].lcs.filter((c) => c.lc === item.code)[0];
      item.value = sample && sample.lvs.length > 0 ? sample.lvs[0].v : 0;
    });
    this.forceUpdate();
  }

  renderConditions() {
    return (
      <div className="rang-container">
        <div
          className="handle"
          onClick={() => this.onHandleClick('sub')}
          ref="handle"
        >
          <i className="icon icon-minus"></i>
        </div>
        <input
          type="range"
          ref="range"
          value={this.state.value}
          onChange={(e) => this.onRangeMove(e.target)}
          min={this.getParams().min}
          max={this.getParams().max}
          step={this.getParams().step}
        />
        <div
          className="handle"
          onClick={() => this.onHandleClick('add')}
          ref="handle"
        >
          <i className="icon icon-plus"></i>
        </div>
        {/*<div className="tip" ref='tip'>{this.state.value}</div>*/}
      </div>
    );
  }

  renderContent() {
    return (
      <div className="content">
        {ILLUS_LABEL.map((v, i) => {
          if (v.value) {
            return (
              <div key={i}>
                {v.label}
                <span>￥{v.value}</span>
              </div>
            );
          }
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="sth-benefit-presentation" {...this.getPrototypes()}>
        {this.renderSelection()}
        {this.renderConditions()}
        {this.renderContent()}
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-benefit-presentation';
  }

  getParams() {
    const params = this.getLayout().params;
    return params
      ? params
      : {
          min: 1,
          max: 105,
          step: 1,
        };
  }

  getData() {
    const data = this.getLayout();
    return data ? data : {};
  }

  onRangeMove(val) {
    /*let left = val.offsetWidth / this.getParams().max * val.value - this.refs.tip.offsetWidth / 2;
         this.refs.tip.style.display = 'block'
         this.refs.tip.style.left = this.refs.handle.offsetWidth + left + 'px'*/
    let min = BENEFICIARY_ILLUS.pts[0].pys[0].py * 1 || 1;
    let max = BENEFICIARY_ILLUS.pts[0].pys.length * 1 || 105;
    this.refs.range.min = min;
    this.refs.range.max = max;
    this.filterDataByAge(val.value);
    this.setState({ value: val.value });
  }

  onHandleClick(type) {
    if (!BENEFICIARY_ILLUS.pts || !BENEFICIARY_ILLUS.pts.length > 0) {
      return;
    }
    let value = parseInt(this.state.value, 10);
    let min = BENEFICIARY_ILLUS.pts[0].pys[0].py * 1 || 1;
    let max = BENEFICIARY_ILLUS.pts[0].pys.length * 1 || 105;
    this.refs.range.min = min;
    this.refs.range.max = max;
    if (type === 'sub') {
      if (value === min) {
        value = min;
      } else {
        value -= 1;
      }
    } else if (type === 'add') {
      if (value === max) {
        value = max;
      } else {
        value += 1;
      }
    }
    this.filterDataByAge(value);
    /*let val = this.refs.range
         let left = val.offsetWidth / this.getParams().max * val.value - this.refs.tip.offsetWidth / 2;
         this.refs.tip.style.display = 'block'
         this.refs.tip.style.left = this.refs.handle.offsetWidth + left + 'px'*/
    this.setState({ value });
  }
}

export default BenefitPresentation;
