import { React, ReactDOM, ClassNames, $ } from '../../../../common/3rd';
import { Envs, Utils } from '../../../../common/common';
import ContainerThing from '../container';
import { getValueFromModel as sthGetValueFromModel } from '../something-utils';

import { Toggle } from '../component/toggle';
import { Label } from '../component/label';
//import { StepInRow, StepInRows, SingleStepInRow } from '../component/step-in-row';
import ImportCustomer from '../biz/import-customer';
import B2aOcrImport from '../biz/scan-id-card'; // 04/01/2018 - TAG: by yanzhi.mo - B2A OCR
import OCRImport from '../biz/OCR';
import TopBar from '../../../mobile/top-bar/top-bar';

/**
 * 面板, 自带标题.
 * 1. 可以折叠.
 * 2. 可以带一个toggle
 * 3. 可以带一个标题右边的标签
 * 3. 折叠/toggle/标题右标签不能同时存在
 */
class Panel extends ContainerThing {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expanded: this.isInitExpanded(),
    };
  }

  doDidMount() {
    if (!this.isInitExpanded()) {
      $(ReactDOM.findDOMNode(this.refs.body)).css({ display: 'none' });
    }
  }

  /**
   * didMount最后增加事件监听
   */
  postDidMount() {
    super.postDidMount();
    this.installHeaderToEmitter();
  }

  /**
   * willUpdate最前去掉事件监听
   */
  preWillUpdate(nextProps, nextState, nextContext) {
    super.preWillUpdate(nextProps, nextState, nextContext);
    this.uninstallHeaderFromEmitter();
  }

  /**
   * didUpdate最后增加事件监听
   */
  postDidUpdate(prevProps, prevState, prevContext) {
    super.postDidUpdate(prevProps, prevState, prevContext);
    this.installHeaderToEmitter();
  }

  /**
   * willUnmount最前去掉事件监听
   */
  preWillUnmount() {
    super.preWillUnmount();
    this.uninstallHeaderFromEmitter();
  }

  /**
   * 添加对于标题栏toggleId和rightId的监听
   */
  installHeaderToEmitter() {
    this.installToEventEmitter({
      event: this.getHeaderToggleId(),
      func: this.onHeaderToggleChange,
    });
    this.installToEventEmitter({ event: this.getHeaderRightId() });
  }

  /**
   * 取消对于标题栏toggleId和rightId的监听
   */
  uninstallHeaderFromEmitter() {
    this.uninstallFromEventEmitter({
      event: this.getHeaderToggleId(),
      func: this.onHeaderToggleChange,
    });
    this.uninstallFromEventEmitter({ event: this.getHeaderRightId() });
  }

  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getHeaderToggleId()) {
      sthGetValueFromModel(nextProps.model, this.getHeaderToggleId())
        ? $(ReactDOM.findDOMNode(this.refs.body)).css({ display: 'block' })
        : $(ReactDOM.findDOMNode(this.refs.body)).css({ display: 'none' });

      this.setState({
        expanded: this.getValueFromModel(this.getHeaderToggleId()),
      });
    }
  }

  renderHeaderIcon() {
    const icon = this.getTitleIcon();
    if (icon) {
      if (icon.startsWith('i:')) {
        // 使用icon font
        const className = ClassNames(
          this.getTitleIconClassName(),
          icon.substring(2),
          'sth-panel-header-icon'
        );
        return <span className={className} />;
      } else {
        // 外链
        const className = ClassNames(
          this.getTitleIconClassName(),
          'sth-panel-header-icon'
        );
        return (
          <span className={className}>
            <img src={icon} alt="" />
          </span>
        );
      }
    }
  }

  renderHeaderToggle() {
    const toggleId = this.getHeaderToggleId();
    if (toggleId) {
      return (
        <Toggle
          model={this.getModel()}
          layout={{ id: toggleId }}
          parent={this}
        />
      );
    }
  }

  renderHeaderRight() {
    const rightId = this.getHeaderRightId();
    if (rightId) {
      return (
        <Label
          model={this.getModel()}
          layout={{
            id: rightId,
            format: this.getHeaderRightFormat(),
          }}
          parent={this}
        />
      );
    }
  }

  //加载条款按钮 by dick 2017/10/20
  renderClauseBtn() {
    return (
      <ClausePanel
        model={this.getModel()}
        layout={this.getClause()}
        parent={this}
      />
    );
  }

  renderRightLabel() {
    const rightLabel = this.getHeaderRightLabel();
    return rightLabel && <span className="right-label">{rightLabel}</span>;
  }

  renderHeader() {
    const className = this.getCurrentClassName(
      ClassNames('sth-panel-header', this.getHeaderClassName())
    );
    return (
      <div className={className} onClick={this.onHeaderClick}>
        {this.renderHeaderIcon()}
        <span>{this.getLabel()}</span>
        {this.getLayout().hasUnderline ? null : <hr />}
        {/*<span className='clause-btn' onClick={this.onClauseClick}>条款</span>*/}
        {this.renderClauseBtn()}
        <span className="collapsible-btn fa fa-fw fa-angle-up" />
        {this.renderHeaderToggle()}
        {this.renderHeaderRight()}
        {this.renderRightLabel()}
      </div>
    );
  }

  getCurrentClassName(className) {
    return ClassNames(className, {
      collapsible: this.isCollapsible(),
      clause: this.isClause(),
      expanded: this.isExpanded(),
    });
  }

  getDefaultClassName() {
    return 'sth-panel';
  }

  getHeaderClassName() {
    return this.getLayout().headerClass;
  }

  getBodyClassName() {
    return this.getCurrentClassName('sth-panel-body');
  }

  isClause() {
    return this.getLayout().clause;
  }

  isCollapsible() {
    return this.getLayout().collapsible || this.getHeaderToggleId();
  }

  isInitExpanded() {
    if (this.getHeaderToggleId()) {
      return this.getValueFromModel(this.getHeaderToggleId());
    } else {
      return this.getLayout().expanded !== false;
    }
  }

  isExpanded() {
    return this.state.expanded;
  }

  getTitleIcon() {
    return this.getLayout().titleIcon;
  }

  getTitleIconClassName() {
    return this.getLayout().titleIconClass;
  }

  getHeaderToggleId() {
    return this.getLayout().headerToggleId;
  }

  getHeaderRightId() {
    return this.getLayout().headerRightId;
  }

  getHeaderRightFormat() {
    return this.getLayout().headerRightFormat;
  }

  getHeaderRightLabel() {
    return this.getLayout().rightLabel;
  }

  /**
   * 获取动画时间, 默认400毫秒
   */
  getAnimationTime() {
    return Envs.getMobilePageTransitionTimeout();
  }

  onHeaderClick = (evt) => {
    if (!this.isCollapsible() || this.getHeaderToggleId()) {
      return;
    }

    if (this.isExpanded()) {
      this.collapse();
    } else {
      this.expand();
    }
  };
  onHeaderToggleChange = () => {
    this.getValueFromModel(this.getHeaderToggleId())
      ? this.expand()
      : this.collapse();
    this.fireEvent('toggleChanged', this.getHeaderToggleId());
  };

  collapse() {
    const body = $(ReactDOM.findDOMNode(this.refs.body));
    this.setState({ expanded: false }, () => {
      body.slideUp(this.getAnimationTime());
    });
  }

  expand() {
    const body = $(ReactDOM.findDOMNode(this.refs.body));
    this.setState({ expanded: true }, () => {
      body.slideDown(this.getAnimationTime());
    });
  }
}

//条款按钮stepInRow组件 dick 2017/10/20
class ClausePanel extends ContainerThing {
  render() {
    const contentClassName = ClassNames(
      'sth-step-in-content sth-single-step-in-row',
      {}
    );
    return (
      <div
        {...this.getPrototypes()}
        className="clause-btn sth-step-in-row"
        ref="me"
      >
        <span onClick={this.onClick}>{this.getLabel()}</span>
        <div className={contentClassName} ref="content">
          <TopBar
            title={this.getStepInContentTitle()}
            back={true}
            backAction={this.onBackClicked}
          />
          {this.renderBody()}
        </div>
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-step-in-row';
  }

  onClick = (evt) => {
    // 从左边移入
    evt.stopPropagation();
    $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: 0 }, 400);
  };
  onBackClicked = (e) => {
    // 从右边移出
    e.stopPropagation();
    $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: '100vw' }, 400);
  };

  getStepInContentTitle() {
    return this.getLayout().title || this.getLabel();
  }
}

class LeftPanel extends Panel {
  getHeaderClassName() {
    return ClassNames('left', this.getLayout().headerClass);
  }
}

class LeftPanel80 extends Panel {
  getHeaderClassName() {
    return ClassNames('left resize-80', this.getLayout().headerClass);
  }
}

class LeftPanel80Currency extends LeftPanel80 {
  getHeaderClassName() {
    return ClassNames(
      'left resize-80 currency-header',
      this.getLayout().headerClass
    );
  }
}

class LeftPanel80ImportInfor extends LeftPanel80 {
  getOcrImportId() {
    return this.getLayout().ocrImportId;
  }

  getCustomerImportId() {
    return this.getLayout().customerImportId;
  }

  getlegalIndiId() {
    return this.getLayout().legalIndiId;
  }

  renderOcrImport() {
    const legalIndiId = this.getlegalIndiId();
    const ocrImportId = this.getOcrImportId();
    const b2aOcrImportId = this.getLayout().b2aOcrImportId;

    /**
     * 被保人为本人隐藏身份证OCR, 导入客户按钮 update cheng.fan 2018 Jan.26
     */
    const insured = this.getRootModel().policyCustomers.find(
      (item) => item.customerType == '2'
    );
    const relationWithPH = insured.extensionData.relationWithPH;
    if (ocrImportId === 'policyInsured' && relationWithPH === '00') {
      return;
    } else if (b2aOcrImportId === 'policyInsured' && relationWithPH === '00') {
      return;
    }

    if (legalIndiId) {
      return null;
    }
    //第三方对接 隐藏身份证OCR, 导入客户按钮  update wendy.gu 2018 Jan.18
    if (b2aOcrImportId && !Utils.isThirdParty()) {
      return (
        <B2aOcrImport
          parent={this}
          id={b2aOcrImportId}
          callBack={this.onOCRImportCallBack}
        />
      );
    }
    if (ocrImportId) {
      return (
        <OCRImport
          parent={this}
          id={ocrImportId}
          callBack={this.onOCRImportCallBack}
        />
      );
    }
  }

  onOCRImportCallBack = (data) => {
    console.log(data);
  };

  renderCustomerImport() {
    const legalIndiId = this.getlegalIndiId();
    const customerImportId = this.getCustomerImportId();

    /**
     * 被保人为本人隐藏身份证OCR, 导入客户按钮 update cheng.fan 2018 Jan.26
     */
    const insured = this.getRootModel().policyCustomers.find(
      (item) => item.customerType == '2'
    );
    const relationWithPH = insured.extensionData.relationWithPH;
    if (customerImportId === 'policyInsured' && relationWithPH === '00') {
      return;
    }

    if (legalIndiId) {
      return null;
    }
    if (customerImportId && !Utils.isThirdParty()) {
      //第三方对接 隐藏导入客户按钮  update wendy.gu 2018 Jan.18
      return <ImportCustomer id={customerImportId} parent={this} />;
    }
  }

  renderlegalIndiToggle() {
    const legalIndiId = this.getlegalIndiId();
    if (legalIndiId) {
      return (
        <div className="sth-legalIndiId-toggle">
          <div className="sth-legalIndiId-toggle-label">是否法定</div>
          <Toggle
            model={this.getRootModel()}
            layout={{ id: legalIndiId }}
            parent={this}
          />
        </div>
      );
    }
  }

  renderDeletedButton() {
    if (this.getLayout().deletedButton === true) {
      return <i className="icon icon-close" onClick={this.onDeleteClicked}></i>;
    }
  }

  renderHeader() {
    const className = this.getCurrentClassName(
      ClassNames('sth-panel-header', this.getHeaderClassName())
    );
    return (
      <div className={className} onClick={this.onHeaderClick}>
        {this.renderHeaderIcon()}
        <span>{this.getLabel()}</span>
        {this.getLayout().hasUnderline ? null : <hr />}
        {this.renderCustomerImport()}
        {this.renderOcrImport()}
        {this.renderDeletedButton()}
        {this.renderlegalIndiToggle()}
      </div>
    );
  }

  onDeleteClicked = () => {
    let model = this.getModel();
    let policyCustomers = this.getRootModel().policyCustomers;
    for (
      let deleteIndex = 0;
      deleteIndex < policyCustomers.length;
      deleteIndex++
    ) {
      if (
        policyCustomers[deleteIndex] === model ||
        (policyCustomers[deleteIndex].customerId &&
          policyCustomers[deleteIndex].customerId == model.customerId)
      ) {
        policyCustomers.splice(deleteIndex, 1);
        this.getRootComponent().forceUpdate();
        break;
      }
    }
  };
}

class ShiftPanel extends Panel {
  getHeaderClassName() {
    return ClassNames('left shift-panel', this.getLayout().headerClass);
  }

  renderHeader() {
    const className = this.getCurrentClassName(
      ClassNames('sth-panel-header', this.getHeaderClassName())
    );
    return (
      <div className={className}>
        {this.renderHeaderIcon()}
        {Array.isArray(this.getLabel()) ? (
          this.getLabel().map((item, index) => (
            <span
              className={
                this.getShiftValue() === item.value ? 'title active' : 'title'
              }
              onClick={() => this.onShiftheaderClick(item.value)}
              key={`panel${index}`}
            >
              {item.label}
            </span>
          ))
        ) : (
          <span>{this.getLabel()}</span>
        )}
      </div>
    );
  }

  onShiftheaderClick(value) {
    this.setValueToModel(value, null, false, this.getHeaderShiftId());
    this.getRootComponent().forceUpdate();
    this.fireEvent('click', this.getHeaderShiftId());
  }

  getHeaderShiftId() {
    return this.getLayout().headerShiftId;
  }

  getShiftValue() {
    return this.getValueFromModel(this.getHeaderShiftId());
  }
}

class DeclarationPanel extends Panel {
  getHeaderClassName() {
    return ClassNames('left declaration-panel', this.getLayout().headerClass);
  }

  getBodyClassName() {
    return 'sth-body-none introduction-declaration';
  }

  renderHeader() {
    const className = this.getCurrentClassName(
      ClassNames('sth-panel-header', this.getHeaderClassName())
    );
    let iconClass = this.getSelectValue() ? 'icon-select2' : 'icon-select1';
    let label1 = this.getLabel()
      .replace('{{{', '<span id="label1">')
      .replace('}}}', '</span>');
    let label2 = label1
      .replace('{{{', '<span  id="label2">')
      .replace('}}}', '</span>');
    let label3 = label2
      .replace('{{{', '<span  id="label3">')
      .replace('}}}', '</span>');
    let label4 = label3
      .replace('{{{', '<span  id="label4">')
      .replace('}}}', '</span>');
    return (
      <div className={className} onClick={() => this.onHeaderSelectClick()}>
        <i className={`icon ${iconClass}`} />
        <div
          onClick={this.onDeclaClick}
          dangerouslySetInnerHTML={{ __html: label4 }}
        />
      </div>
    );
  }

  onDeclaClick = (e) => {
    e.stopPropagation();
    if (e.target.id === 'label1') {
      $('.introduction-declaration .sth-step-in-content')
        .eq(0)
        .animate({ left: 0 }, 400);
    } else if (e.target.id === 'label2') {
      $('.introduction-declaration .sth-step-in-content')
        .eq(1)
        .animate({ left: 0 }, 400);
    } else if (e.target.id === 'label3') {
      $('.introduction-declaration .sth-step-in-content')
        .eq(2)
        .animate({ left: 0 }, 400);
    } else if (e.target.id === 'label4') {
      $('.introduction-declaration .sth-step-in-content')
        .eq(3)
        .animate({ left: 0 }, 400);
    } else {
      this.onHeaderSelectClick();
    }
  };

  getHeaderSelectId() {
    return this.getLayout().headerSelectId;
  }

  onHeaderSelectClick() {
    this.setValueToModel(
      this.getSelectValue() ? false : true,
      null,
      false,
      this.getHeaderSelectId()
    );
    this.getRootComponent().forceUpdate();
  }

  getSelectValue() {
    return this.getValueFromModel(this.getHeaderSelectId());
  }
}

export {
  Panel,
  LeftPanel,
  LeftPanel80,
  LeftPanel80Currency,
  LeftPanel80ImportInfor,
  ClausePanel,
  ShiftPanel,
  DeclarationPanel,
};
