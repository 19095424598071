import { React } from '../../../../common/3rd';
import { Envs } from '../../../../common/common';
import AtomicComponentThing from '../atomic-component';
import Alert from '../../../../component/both/alert/alert';
import PageLoading from '../../../../component/both/loading/page-loading';

const AsyncManager = require('../../../../util/AsyncManager');

// 图片对象
const logo = require('../../../../images/wechat/logo.png');
const arrow_right = require('../../../../images/wechat/arrow_right.png');
const logo_wechat = require('../../../../images/wechat/logo_wechat.png');
const pic_unionpay = require('../../../../images/wechat/pic_unionpay.png');
// 支付计时
const Timer = require('../../../../util/PaymentTimer');
// 微信支付
const WxPayManager = require('../../../../util/WxPayManager');
// 路由锁
// const BackLock = require('../../../../util/BackLock');
const { XBack } = window;

// [{ "87": "支付失败" },
// { "85": "已付款已承保" },
// { "89": "等待支付结果" },
// { "90": "已支付未承保" },
// { "91": "承保失败" }]
const FAIL_STATUS = ['87', '89', '90', '91'];

/**
 * 支付信息
 * addded by yanzhi.mo
 *
 * @class Payment
 * @extends {AtomicComponentThing}
 */
class Payment extends AtomicComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.restTime = null; //支付计时
    this.state.premium = this.getModel().premium || 0; // 保费
    this.state.quotationNo = this.getModel().quotationNo || null; // 订单号
    this.state.hideBody = this.getLayout().hideBody || false; // 是否隐藏支付入口，默认不隐藏
    this.wxpayManager = null; // 微信支付管理
    this.timer = null; // 支付计时
    this.timerKey = null; //

    XBack.lockRoute(() => {
      console.log(this);
      if (this.props.model.policyStatus == '85') {
        XBack.unLockRoute()(true);
      } else {
        Alert.ask('是否退出投保流程？', (type) => {
          if (type === '2') {
            XBack.unLockRoute()(true); // 返回一个函数，会询问是否需要进行历史栈回退。
          }
        });
      }
    });
  }

  componentWillUnmount() {
    this.timer && this.timer.stop(this.timerKey);
  }

  componentDidMount() {
    this.setPaymentTimer();
  }

  componentDidUpdate() {
    // 支付计时
    this.setPaymentTimer();
    // 设置页面标题
    this.setTitle();
  }

  /**
   * 支付计时
   *
   * @memberof Payment
   */
  setPaymentTimer() {
    let currentIndex = this.props.parent.props.parent.state.currentIndex;
    let model = this.getModel();
    if ([2, 3].includes(currentIndex)) {
      if (model.policyStatus === '80') {
        if (model.extensionData.expirationTime) {
          this.setRestTime(model.extensionData.expirationTime);
        } else {
          // Alert.message('expirationTime没有设置，请联系后端人员进行排查。');
        }
      }
    } else {
      this.timer && this.timer.stop(this.timerKey);
    }
  }

  /**
   * 设置页面标题
   *
   * @memberof Payment
   */
  setTitle() {
    // 更换标题
    // TODO: 这个应该放在当前模块的核心组件，但是实在不想看框架的设计，就索性放这里解决，后面再说。
    let currentIndex = this.props.parent.props.parent.state.currentIndex;
    if (currentIndex === 0) {
      document.title = '信息录入';
    } else if (currentIndex === 1) {
      document.title = '订单确认';
    } else if (currentIndex === 2) {
      document.title = '选择支付方式';
    } else if (currentIndex === 3 && document.title !== '银行代扣') {
      document.title = '银行代扣';
    } else {
    }
  }

  /**
   * 支付计时
   *
   * @param {any} expiration
   * @memberof Payment
   */
  setRestTime(expiration) {
    const self = this;
    const model = this.getModel();
    if (!this.timer) {
      const timer = (this.timer = new Timer(expiration));
      const timerKey = (this.timerKey = this.timer.run(
        ({ minsRest, secsRest, done }) => {
          self.setState({ restTime: `请在${minsRest}分${secsRest}内完成支付` });

          if (done) {
            // 订单作废
            if (model.policyStatus === '88') {
              return;
            } else {
              model.policyStatus = '88';
            }
            PageLoading && PageLoading.start();
            /* self.doGet(`/rest/v1/policy/deleteCore/${model.quotationNo}`, model).done((data) => {
                     PageLoading && PageLoading.start();
                     if (data.body.resultCode === 'Y') {
                     self.doPost('/rest/v1/policy/update', model).done((data) => {
                     }).always(() => {
                     PageLoading.end();
                     });
                     } else if (data.body.resultCode === 'N') {
                     Alert.message([data.body.resultReason]);
                     }
                     }).always(() => {
                     PageLoading.end();
                     }); */
            Alert.ask('订单支付超时！', (type) => {
              window.location.href = window.makeupUrl('product-store');
            });
          }
        }
      ));
    }
  }

  /**
   * 银行卡支付
   *
   * @memberof Payment
   */
  async emitUnionpay() {
    let model = this.getModel();
    //自动填充支付信息
    await this.doPost('/rest/v1/policy/purchaseRecord', model).done((data) => {
      let { bankName, bankCard, mobile } = data.body;
      if (bankName) {
        model.extensionData.paymentInfo.bankName = data.body.bankName;
      }
      if (bankCard) {
        model.extensionData.paymentInfo.bankCard = bankCard;
      }
      if (mobile) {
        model.extensionData.paymentInfo.mobile = mobile;
      }
    });
    this.props.parent.props.parent.jumpTo('bankWay');
  }

  /**
   * 微信支付
   *  add by cheng.fan on 2018-05-05
   * @memberof Payment
   */

  async emitWxpay() {
    // let asyncManager = new AsyncManager(this.props.parent)
    const model = this.getModel();
    const policyId = model.policyId;
    const openId = localStorage.getItem('openId');
    const url = `/rest/v1/payment/vbaoWeixinPayConfig?openId=${openId}`;
    const that = this;
    await this.doPost(url, model).done((data) => {
      if (data.code === '00000') {
        //请求成功
        let config = JSON.parse(JSON.stringify(data.body));
        config.package = data.body.packageStr;
        delete config.packageStr;
        // WxPayManager.pay(config);
        if (typeof WeixinJSBridge == 'undefined') {
          if (document.addEventListener) {
            document.addEventListener(
              'WeixinJSBridgeReady',
              () => that.onBridgeReady(config, policyId),
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', () =>
              that.onBridgeReady(config, policyId)
            );
            document.attachEvent('onWeixinJSBridgeReady', () =>
              that.onBridgeReady(config, policyId)
            );
          }
        } else {
          that.onBridgeReady(config, policyId);
        }
      } else {
        Alert.message(data.desc);
      }
    });
  }

  onBridgeReady = (config, policyId) => {
    const that = this;
    // eslint-disable-next-line
    WeixinJSBridge.invoke('getBrandWCPayRequest', config, (res) => {
      if (res.err_msg === 'get_brand_wcpay_request:ok') {
        // return { hasError: false, message: '支付成功' };
        // asyncManager.issue(model, { loading: PageLoading }); //承保接口
        Alert.message('支付成功', async () => {
          //拿到policyStatus跳转，否则启动定时器继续请求
          await that.queryPolicyStatus(policyId, (status) => {
            if (status == 'goOn') {
              that.setTimeoutTocheckPolicyStatus(policyId);
            }
          });
        });
      } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
        // return { hasError: true, message: '支付取消' };
        // Alert.message("支付取消");
      } else {
        // return { hasError: true, message: '支付失败' };
        // Alert.message("支付失败");
      }
    });
  };

  /**
   * add by cheng.fan on 2018-05-07
   * 支付状态延迟跟进
   * @param {string} policyId
   */
  setTimeoutTocheckPolicyStatus = async (policyId) => {
    PageLoading.start('承保中，请稍候');
    const clock = () => {
      let timer = setTimeout(async () => {
        await this.queryPolicyStatus(policyId, (status) => {
          if (status === 'success' || status === 'fail') {
            PageLoading.end();
          } else if (status === 'goOn') {
            clock();
          }
        });
      }, 2000);
    };
    clock();
  };
  /**
   * add by cheng.fan on 2018-05-07
   * check 支付状态
   *
   * @param {string} policyId
   * @returns
   */
  queryPolicyStatus = async (policyId, callback) => {
    const accountId = Envs.findAccount().accountId;
    const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
    let policyStatus;
    await this.doGet(url).done((data) => {
      policyStatus = data.body.quoteInitData.policyStatus;
      //拿到policyStatus跳转，否则启动定时器继续请求
      if (policyStatus == '85') {
        // "85 - 已承保"
        this.props.parent.props.parent.setModel(data.body.quoteInitData);
        this.props.parent.props.parent.jumpTo('successfulInsurance');
        callback && callback('success');
      } else if (FAIL_STATUS.includes(policyStatus)) {
        this.props.parent.props.parent.setModel(data.body.quoteInitData);
        this.props.parent.props.parent.jumpTo('failureForInsurance');
        callback && callback('fail');
      } else {
        callback && callback('goOn');
      }
    });
    return policyStatus;
  };

  render() {
    return (
      <div className="payment">
        <div className="header">
          <div className="list">
            <div className="item">
              <div className="title">
                <img src={logo} alt="" className="logo" />
                <div className="timer">{this.state.restTime}</div>
              </div>
            </div>
            <div className="item">
              <div className="order-single-info">
                <div className="label">订单金额：</div>
                <div className="datum red">{this.getModel().premium}元</div>
              </div>
              <div className="order-single-info">
                <div className="label">订单编号：</div>
                <div className="datum">{this.state.quotationNo}</div>
              </div>
            </div>
          </div>
        </div>
        {!this.state.hideBody ? (
          <div className="body">
            <div className="instruction">请选择支付方式：</div>
            <div className="list">
              <div className="item">
                {
                  <div
                    className="bank-selection"
                    onClick={this.emitWxpay.bind(this)}
                  >
                    <img className="icon" src={logo_wechat} alt="" />
                    <ul>
                      <li>微信</li>
                      <li>微信便捷支付</li>
                    </ul>
                    <img className="arrow-right" src={arrow_right} alt="" />
                  </div>
                }
              </div>
              <div className="item">
                <div
                  className="bank-selection"
                  onClick={this.emitUnionpay.bind(this)}
                >
                  <img className="icon" src={pic_unionpay} alt="" />
                  <ul>
                    <li>银行卡</li>
                    <li>银行卡支付</li>
                  </ul>
                  <img className="arrow-right" src={arrow_right} alt="" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: '0.1rem' }} />
        )}
        <div className="footer"></div>
      </div>
    );
  }
}

export default Payment;
