import { React, ClassNames } from '../../../../common/3rd';
import { Envs, Lang } from '../../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';
import n22 from '@/util/n22';

import './life-bottom-menu.css';
import messages from './messages.json';

Lang.installMessages(messages, 'life-bottom-menu');

/**
 * bottom menu
 */
class LifeBottomMenu extends ReactRouterContextWidget {
  renderItem(item, index) {
    const className = ClassNames('item', item.class, {
      active: item.class === this.getActiveKey(),
    });
    return (
      <div
        className={className}
        key={`item-${index}`}
        onClick={this.onItemClicked.bind(this, item, index)}
      >
        <div
          className={`icon ${item.icon} ${
            item.class === this.getActiveKey() ? 'active' : ''
          }`}
        />
        <div className="label">
          <span>{item.label}</span>
        </div>
      </div>
    );
  }

  renderItems() {
    const items = Lang.messages['life-bottom-menu'].items;
    return items.map((item, index) => {
      return this.renderItem(item, index);
    });
  }

  render() {
    const className = ClassNames(this.getWidgetClassName('life-bottom-menu'));
    return <div className={className}>{this.renderItems()}</div>;
  }

  getActiveKey() {
    return this.getPropsValue('active');
  }

  onItemClicked(item, index) {
    if (this.getActiveKey() === item.class) {
      return;
    }
    if (item.class === 'order') {
      //首页导航点击为订单是跳转到n22链接
      let sFrom = window.location.href;
      let serialNo = Envs.findAccount().accountName;
      let serialNo_n22 = null;
      if (serialNo) {
        if (serialNo) {
          let aSerialNo = serialNo.split('.');
          if (aSerialNo.length > 0) {
            serialNo_n22 = aSerialNo[1];
          }
        }
      }
      let url =
        `${n22.getHostByEnv()}proposal/#/account/accountOrderList` +
        `?serialNo=${serialNo_n22}&fromAppOrderUrl=${sFrom}`;
      window.location.href = url;
      return;
    }
    const to = item.to;
    this.pushRedirect(to);
  }
}

export default LifeBottomMenu;
