import { React } from '../../../../common/3rd';

import Dropdown from '../component/dropdown';

const CODES = [
  { v: 'ABW', l: '阿鲁巴' },
  { v: 'AFG', l: '阿富汗', '': '返回' },
  { v: 'AGO', l: '安哥拉' },
  { v: 'AIA', l: '安圭拉' },
  { v: 'ALB', l: '阿尔巴尼亚' },
  { v: 'AND', l: '安道尔' },
  { v: 'ANT', l: '荷属安的列斯' },
  { v: 'ARE', l: '阿联酋' },
  { v: 'ARG', l: '阿根廷' },
  { v: 'ARM', l: '亚美尼亚' },
  { v: 'ASM', l: '美属萨摩亚' },
  { v: 'ATA', l: '南极洲' },
  { v: 'ATF', l: '法属南部领土' },
  { v: 'ATG', l: '安提瓜和巴布达' },
  { v: 'AUS', l: '澳大利亚' },
  { v: 'AUT', l: '奥地利' },
  { v: 'AZE', l: '阿塞拜疆' },
  { v: 'BDI', l: '布隆迪' },
  { v: 'BEL', l: '比利时' },
  { v: 'BEN', l: '贝宁' },
  { v: 'BFA', l: '布基纳法索' },
  { v: 'BGD', l: '孟加拉国' },
  { v: 'BGR', l: '保加利亚' },
  { v: 'BHR', l: '巴林' },
  { v: 'BHS', l: '巴哈马' },
  { v: 'BIH', l: '波斯尼亚和黑塞哥维那' },
  { v: 'BLR', l: '白俄罗斯' },
  { v: 'BLZ', l: '伯利兹' },
  { v: 'BMU', l: '百慕大' },
  { v: 'BOL', l: '玻利维亚' },
  { v: 'BRA', l: '巴西' },
  { v: 'BRB', l: '巴巴多斯' },
  { v: 'BRN', l: '文莱' },
  { v: 'BTN', l: '不丹' },
  { v: 'BVT', l: '布维岛' },
  { v: 'BWA', l: '博茨瓦纳' },
  { v: 'CAF', l: '中非' },
  { v: 'CAN', l: '加拿大' },
  { v: 'CCK', l: '科科斯(基林)群岛' },
  { v: 'CHE', l: '瑞士' },
  { v: 'CHL', l: '智利' },
  { v: 'CHN', l: '中国' },
  { v: 'CIV', l: '科特迪瓦' },
  { v: 'CMR', l: '喀麦隆' },
  { v: 'COD', l: '刚果（金）' },
  { v: 'COG', l: '刚果（布）' },
  { v: 'COK', l: '库克群岛' },
  { v: 'COL', l: '哥伦比亚' },
  { v: 'COM', l: '科摩罗' },
  { v: 'CPV', l: '佛得角' },
  { v: 'CRI', l: '哥斯达黎加' },
  { v: 'CUB', l: '古巴' },
  { v: 'CXR', l: '圣诞岛' },
  { v: 'CYM', l: '开曼群岛' },
  { v: 'CYP', l: '塞浦路斯' },
  { v: 'CZE', l: '捷克' },
  { v: 'DEU', l: '德国' },
  { v: 'DJI', l: '吉布提' },
  { v: 'DMA', l: '多米尼克' },
  { v: 'DNK', l: '丹麦' },
  { v: 'DOM', l: '多米尼加共和国' },
  { v: 'DZA', l: '阿尔及利亚' },
  { v: 'ECU', l: '厄瓜多尔' },
  { v: 'EGY', l: '埃及' },
  { v: 'ERI', l: '厄立特里亚' },
  { v: 'ESH', l: '西撒哈拉' },
  { v: 'ESP', l: '西班牙' },
  { v: 'EST', l: '爱沙尼亚' },
  { v: 'ETH', l: '埃塞俄比亚' },
  { v: 'FIN', l: '芬兰' },
  { v: 'FJI', l: '斐济' },
  { v: 'FLK', l: '马尔维纳斯群岛(福克兰群岛)' },
  { v: 'FRA', l: '法国' },
  { v: 'FRO', l: '法罗群岛' },
  { v: 'FSM', l: '密克罗尼西亚' },
  { v: 'GAB', l: '加蓬' },
  { v: 'GBR', l: '英国' },
  { v: 'GEO', l: '格鲁吉亚' },
  { v: 'GHA', l: '加纳' },
  { v: 'GIB', l: '直布罗陀' },
  { v: 'GIN', l: '几内亚' },
  { v: 'GLP', l: '瓜德罗普' },
  { v: 'GMB', l: '冈比亚' },
  { v: 'GNB', l: '几内亚比绍' },
  { v: 'GNQ', l: '赤道几内亚' },
  { v: 'GRC', l: '希腊' },
  { v: 'GRD', l: '格林纳达' },
  { v: 'GRL', l: '格陵兰' },
  { v: 'GTM', l: '危地马拉' },
  { v: 'GUF', l: '法属圭亚那' },
  { v: 'GUM', l: '关岛' },
  { v: 'GUY', l: '圭亚那' },
  { v: 'HKG', l: '中国香港' },
  { v: 'HMD', l: '赫德岛和麦克唐纳岛' },
  { v: 'HND', l: '洪都拉斯' },
  { v: 'HRV', l: '克罗地亚' },
  { v: 'HTI', l: '海地' },
  { v: 'HUN', l: '匈牙利' },
  { v: 'IDN', l: '印度尼西亚' },
  { v: 'IND', l: '印度' },
  { v: 'IOT', l: '英属印度洋领土' },
  { v: 'IRL', l: '爱尔兰' },
  { v: 'IRN', l: '伊朗' },
  { v: 'IRQ', l: '伊拉克' },
  { v: 'ISL', l: '冰岛' },
  { v: 'ISR', l: '以色列' },
  { v: 'ITA', l: '意大利' },
  { v: 'JAM', l: '牙买加' },
  { v: 'JOR', l: '约旦' },
  { v: 'JPN', l: '日本' },
  { v: 'KAZ', l: '哈萨克斯坦' },
  { v: 'KEN', l: '肯尼亚' },
  { v: 'KGZ', l: '吉尔吉斯斯坦' },
  { v: 'KHM', l: '柬埔寨' },
  { v: 'KIR', l: '基里巴斯' },
  { v: 'KNA', l: '圣基茨和尼维斯' },
  { v: 'KOR', l: '韩国' },
  { v: 'KWT', l: '科威特' },
  { v: 'LAO', l: '老挝' },
  { v: 'LBN', l: '黎巴嫩' },
  { v: 'LBR', l: '利比里亚' },
  { v: 'LBY', l: '利比亚' },
  { v: 'LCA', l: '圣卢西亚' },
  { v: 'LIE', l: '列支敦士登' },
  { v: 'LKA', l: '斯里兰卡' },
  { v: 'LSO', l: '莱索托' },
  { v: 'LTU', l: '立陶宛' },
  { v: 'LUX', l: '卢森堡' },
  { v: 'LVA', l: '拉脱维亚' },
  { v: 'MAC', l: '澳门' },
  { v: 'MAR', l: '摩洛哥' },
  { v: 'MCO', l: '摩纳哥' },
  { v: 'MDA', l: '摩尔多瓦' },
  { v: 'MDG', l: '马达加斯加' },
  { v: 'MDV', l: '马尔代夫' },
  { v: 'MEX', l: '墨西哥' },
  { v: 'MHL', l: '马绍尔群岛' },
  { v: 'MKD', l: '马斯顿' },
  { v: 'MLI', l: '马里' },
  { v: 'MLT', l: '马耳他' },
  { v: 'MMR', l: '缅甸' },
  { v: 'MNG', l: '蒙古' },
  { v: 'MNP', l: '北马里亚纳' },
  { v: 'MOZ', l: '莫桑比克' },
  { v: 'MRT', l: '毛里塔尼亚' },
  { v: 'MSR', l: '蒙特塞拉特' },
  { v: 'MTQ', l: '马提尼克' },
  { v: 'MUS', l: '毛里求斯' },
  { v: 'MWI', l: '马拉维' },
  { v: 'MYS', l: '马来西亚' },
  { v: 'MYT', l: '马约特' },
  { v: 'NAM', l: '纳米比亚' },
  { v: 'NCL', l: '新喀里多尼亚' },
  { v: 'NER', l: '尼日尔' },
  { v: 'NFK', l: '诺福克岛' },
  { v: 'NGA', l: '尼日利亚' },
  { v: 'NIC', l: '尼加拉瓜' },
  { v: 'NIU', l: '纽埃' },
  { v: 'NLD', l: '荷兰' },
  { v: 'NOR', l: '挪威' },
  { v: 'NPL', l: '尼泊尔' },
  { v: 'NRU', l: '瑙鲁' },
  { v: 'NZL', l: '新西兰' },
  { v: 'OMN', l: '阿曼' },
  { v: 'PAK', l: '巴基斯坦' },
  { v: 'PAN', l: '巴拿马' },
  { v: 'PCN', l: '皮特凯恩群岛' },
  { v: 'PER', l: '秘鲁' },
  { v: 'PHL', l: '菲律宾' },
  { v: 'PLW', l: '帕劳' },
  { v: 'PNG', l: '巴布亚新几内亚' },
  { v: 'POL', l: '波兰' },
  { v: 'PRI', l: '波多黎各' },
  { v: 'PRK', l: '朝鲜' },
  { v: 'PRT', l: '葡萄牙' },
  { v: 'PRY', l: '巴拉圭' },
  { v: 'PSE', l: '巴勒斯坦' },
  { v: 'PYF', l: '法属波利尼西亚' },
  { v: 'QAT', l: '卡塔尔' },
  { v: 'REU', l: '留尼汪' },
  { v: 'ROM', l: '罗马尼亚' },
  { v: 'RUS', l: '俄罗斯' },
  { v: 'RWA', l: '卢旺达' },
  { v: 'SAU', l: '沙特阿拉伯' },
  { v: 'SCG', l: '塞尔维亚和黑山' },
  { v: 'SDN', l: '苏丹' },
  { v: 'SEN', l: '塞内加尔' },
  { v: 'SGP', l: '新加坡' },
  { v: 'SGS', l: '南乔治亚岛和南桑德韦奇岛' },
  { v: 'SHN', l: '圣赫勒拿' },
  { v: 'SJM', l: '斯瓦尔巴群岛和扬马群岛' },
  { v: 'SLB', l: '所罗门群岛' },
  { v: 'SLE', l: '塞拉利昂' },
  { v: 'SLV', l: '萨尔瓦多' },
  { v: 'SMR', l: '圣马力诺' },
  { v: 'SOM', l: '索马里' },
  { v: 'SPM', l: '圣皮埃尔和密克隆' },
  { v: 'STP', l: '圣多美和普林西比' },
  { v: 'SUR', l: '苏里南' },
  { v: 'SVK', l: '斯洛伐克' },
  { v: 'SVN', l: '斯洛文尼亚' },
  { v: 'SWE', l: '瑞典' },
  { v: 'SWZ', l: '斯威士兰' },
  { v: 'SYC', l: '塞舌尔' },
  { v: 'SYR', l: '叙利亚' },
  { v: 'TCA', l: '特克斯和凯科斯群岛' },
  { v: 'TCD', l: '乍得' },
  { v: 'TGO', l: '多哥' },
  { v: 'THA', l: '泰国' },
  { v: 'TJK', l: '塔吉克斯坦' },
  { v: 'TKL', l: '托克劳' },
  { v: 'TKM', l: '土库曼斯坦' },
  { v: 'TMP', l: '东帝汶' },
  { v: 'TON', l: '汤加' },
  { v: 'TTO', l: '特立尼达和多巴哥' },
  { v: 'TUN', l: '突尼斯' },
  { v: 'TUR', l: '土耳其' },
  { v: 'TUV', l: '图瓦卢' },
  { v: 'TWN', l: '中国台湾' },
  { v: 'TZA', l: '坦桑尼亚' },
  { v: 'UGA', l: '乌干达' },
  { v: 'UKR', l: '乌克兰' },
  { v: 'UMI', l: '美属本土外小岛屿' },
  { v: 'URY', l: '乌拉圭' },
  { v: 'USA', l: '美国' },
  { v: 'UZB', l: '乌兹别克斯坦' },
  { v: 'VAT', l: '梵蒂冈' },
  { v: 'VCT', l: '圣文森特和格林纳丁斯' },
  { v: 'VEN', l: '委内瑞拉' },
  { v: 'VGB', l: '英属维尔京群岛' },
  { v: 'VIR', l: '美属维尔京群岛' },
  { v: 'VNM', l: '越南' },
  { v: 'VUT', l: '瓦努阿图' },
  { v: 'WLF', l: '瓦利斯和富图纳群岛' },
  { v: 'WSM', l: '西萨摩亚' },
  { v: 'YEM', l: '也门' },
  { v: 'ZAF', l: '南非' },
  { v: 'ZAR', l: '扎伊尔' },
  { v: 'ZMB', l: '赞比亚' },
  { v: 'ZWE', l: '津巴布韦' },
];

export default class Nationality extends Dropdown {
  getCodes() {
    return CODES;
  }
}
