import { React, ClassNames } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import Product from './product';

import './product.css';
import plainImage from '../../../images/plain-product.png';

/**
 * fav product
 */
class ProductAdd extends Product {
  getImageUrl(key) {
    let image = super.getImageUrl(key);
    return image ? image : plainImage;
  }
  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount();
    this.state.selected = false;
  }
  render() {
    let classNames = ClassNames('checked');
    return (
      <div
        className={this.getWidgetClassName('product product-add')}
        onClick={this.onProductClicked}
      >
        <span data-id={this.getProductUniqueKey()} />
        <div className="img">
          <img src={this.getImageUrl('image3')} alt="" />
        </div>
        <div className="title">
          <span>{this.getProductName()}</span>
          <div className={classNames}>
            <span
              className={ClassNames('fa fa-circle-thin', {
                notSelected: !this.isSelected(),
              })}
            />
            <span
              className={ClassNames('fa fa-check-circle-o', {
                selected: this.isSelected(),
              })}
            />
          </div>
        </div>
        <div className="slogan">
          <span>{this.getSlogan1()}</span>
          <span>{this.getSlogan2()}</span>
        </div>
        <div className="price">
          <span>{this.getCurrencySign()}</span>
          <span>{this.getMinPrice()}</span>
          <span>{Lang.messages.product.priceStarts}</span>
        </div>
      </div>
    );
  }
  onProductClicked = () => {
    this.setSelected(!this.isSelected());
  };
}

export default ProductAdd;
