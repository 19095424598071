import { React, ClassNames } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';
import Toast from '../../both/toast/toast';

import './pagination.css';
import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'pagination');

const ELLIPSIS = '...';
// pages button shown in one slide, be odd please
const SLIDE_PAGES = 5;
const SLIDE_PAGES_HALF = Math.ceil(SLIDE_PAGES / 2);
/**
 * pagination
 */
class Pagination extends ReactRouterContextWidget {
  renderButtons(btns) {
    let currentPageIndex = this.getPageIndex();
    return btns.map((label, index) => {
      const className = ClassNames('pagination-btn', {
        ellipsis: label === ELLIPSIS,
        current: currentPageIndex === label,
      });
      const click =
        label === ELLIPSIS ? null : this.onPageClicked.bind(this, label);
      return (
        <div className={className} onClick={click} key={`${label}-${index}`}>
          <span>{label}</span>
        </div>
      );
    });
  }
  renderPreviousButtons() {
    let btns = [];
    const pageIndex = this.getPageIndex();
    if (pageIndex > SLIDE_PAGES_HALF) {
      btns.push(ELLIPSIS, pageIndex - 2, pageIndex - 1);
    } else {
      let index = 1;
      for (; index < pageIndex; index++) {
        btns.push(index);
      }
    }
    return this.renderButtons(btns);
  }
  renderNextButtons() {
    let btns = [];
    const pageIndex = this.getPageIndex();
    const pageCount = this.getPageCount();
    if (pageCount - pageIndex >= SLIDE_PAGES_HALF) {
      btns.push(pageIndex + 1, pageIndex + 2, ELLIPSIS);
    } else {
      let index = pageIndex + 1;
      let count = pageCount - pageIndex;
      while (count > 0) {
        btns.push(index);
        index++;
        count--;
      }
    }
    return this.renderButtons(btns);
  }
  renderPageButtons() {
    const pageIndex = this.getPageIndex();
    const pageCount = this.getPageCount();
    if (pageCount <= SLIDE_PAGES) {
      // render all pages
      let btns = [];
      let index = 1;
      while (index <= pageCount) {
        btns.push(index);
        index++;
      }
      return this.renderButtons(btns);
    } else {
      return [
        this.renderPreviousButtons(),
        <div className="pagination-btn current" key="current-page">
          <span>{pageIndex}</span>
        </div>,
        this.renderNextButtons(),
      ];
    }
  }
  render() {
    return (
      <div className={this.getWidgetClassName('pagination')}>
        <div className="pagination-btn" onClick={this.onFirstClicked}>
          <span className="fa fa-fw fa-step-backward" />
        </div>
        <div className="pagination-btn" onClick={this.onPrevClicked}>
          <span className="fa fa-fw fa-angle-double-left" />
        </div>
        {this.renderPageButtons()}
        <div className="pagination-btn" onClick={this.onNextClicked}>
          <span className="fa fa-fw fa-angle-double-right" />
        </div>
        <div className="pagination-btn" onClick={this.onLastClicked}>
          <span className="fa fa-fw fa-step-forward" />
        </div>
      </div>
    );
  }
  getPageCount() {
    return this.getPropsValue('count', 1) * 1;
  }
  getPageIndex() {
    return this.getPropsValue('current', 1) * 1;
  }
  onPrevClicked = (evt) => {
    const pageIndex = this.getPageIndex();
    if (pageIndex <= SLIDE_PAGES) {
      this.onPageClicked(1);
    } else {
      this.onPageClicked(pageIndex - SLIDE_PAGES);
    }
  };
  onNextClicked = (evt) => {
    const pageIndex = this.getPageIndex();
    const pageCount = this.getPageCount();
    if (pageCount - pageIndex <= SLIDE_PAGES) {
      this.onPageClicked(pageCount);
    } else {
      this.onPageClicked(pageIndex + SLIDE_PAGES);
    }
  };
  onFirstClicked = (evt) => {
    if (this.getPageIndex() === 1) {
      // do nothing
    } else {
      this.onPageClicked(1);
    }
  };
  onLastClicked = (evt) => {
    if (this.getPageIndex() === this.getPageCount()) {
      // do nothing
    } else {
      this.onPageClicked(this.getPageCount());
    }
  };
  onPageClicked(pageIndex) {
    const pageChanged = this.props.pageChanged;
    if (pageChanged) {
      pageChanged(pageIndex, this);
    } else {
      Toast.showNotImpl();
    }
  }
}

export default Pagination;
