import { Utils, Storage, Lang, Consts, Envs } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';
import n22 from '@/util/n22';

import './product.css';

import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'product');

class Product extends ReactRouterContextWidget {
  getProductName() {
    return this.getProduct().productName || this.getProduct().name;
  }
  getAbbrName() {
    return this.getProduct().advertisementName || this.getProduct().abbrName;
  }
  getSlogan1() {
    return this.getProduct().slogan1;
  }
  getSlogan2() {
    return this.getProduct().slogan2;
  }
  getImageUrl(key) {
    return this.getProduct()[key] || this.getProduct().img;
  }
  getCurrencySign() {
    return Utils.getCurrencySign(this.getProduct().currency);
  }
  getMinPrice() {
    return Utils.formatAmount(this.getProduct().minPrice || 0);
  }
  getVendorCode() {
    return this.getProduct().vendorCode;
  }
  getProductCode() {
    return this.getProduct().productCode;
  }
  getProductVersion() {
    return this.getProduct().productVersion;
  }
  getQuoteUrl() {
    return this.getProduct().quoteUrl;
  }
  getProductId() {
    return this.getProduct().productId;
  }
  getStoreProductId() {
    return this.getProduct().storeProductId;
  }
  getProductUniqueKey() {
    return `${this.getVendorCode()}--${this.getProductCode()}--${this.getProductVersion()}`;
  }
  isLiked() {
    return this.getProduct().liked === true;
  }
  setLiked(liked) {
    this.getProduct().liked = liked;
    return this;
  }
  getLikeCount() {
    return this.getProduct().likeCount || 0;
  }
  setLikeCount(likeCount) {
    this.getProduct().likeCount = likeCount;
    return this;
  }
  getProduct() {
    return this.getPropsValue('product');
  }
  isSelected() {
    return this.state.selected;
  }
  setSelected(selected) {
    this.setState({ selected: selected });
  }
  /**
   * 是否正在可选择的状态
   */
  isSelectable() {
    return this.getPropsValue('selectable', false);
  }
  onProductClicked = (evt) => {
    let url = '';
    let host = window.location.host;
    if (host.includes('b2c.beijinglife')) {
      //b2c产品跳转
      // let json = {
      // 	"1061002": "proposal_2c/#/insure/CALCULATION?params=eyJwcm9kdWN0Q29kZSI6IjEwNjEwMDIiLCJjaGFubmVsQ29kZSI6IkJKX0pJTkdBTlNIVU5fMkMifQo= ",
      // 	"1010001": "proposal_2c/#/insure/CALCULATION?params=eyJwcm9kdWN0Q29kZSI6IjEwMTAwMDEiLCJjaGFubmVsQ29kZSI6IkJKX0pJTkdBTlNIVU5fMkMifQo= ",
      // 	"1070004": "proposal_2c/#/insure/CALCULATION?params=eyJwcm9kdWN0Q29kZSI6IjEwNzAwMDQiLCJjaGFubmVsQ29kZSI6IkJKX0pJTkdBTlNIVU5fMkMifQo= ",
      // 	"1061001": "proposal_2c/#/FY/homePageOne",
      // }
      let productCode = this.getProductCode();
      // url = n22.getHostByEnv() + json[productCode];

      if (productCode == '1061001') {
        // 京安泰
        url = n22.getHostByEnv() + 'proposal_2c/#/FY/homePageOne';
      } else {
        let params = `{"productCode":"${productCode}","channelCode":"BJ_JINGANSHUN_2C","token":"${localStorage.getItem(
          'token'
        )}"}`;
        params = Utils.doEncode(params);
        url =
          n22.getHostByEnv() +
          `proposal_2c/#/insure/CALCULATION?params=${params} `;
      }
      window.location.href = url;
    }
    if (host.includes('b2a.beijinglife')) {
      //b2a产品跳转
      let sFrom = window.location.href;
      let hostname = n22.getHostByEnv();
      let serialNo = Envs.findAccount().accountName;
      let serialNo_n22 = null;
      if (serialNo) {
        let aSerialNo = serialNo.split('.');
        if (aSerialNo.length > 0) {
          serialNo_n22 = aSerialNo[1];
        }
      }
      url = `${hostname}proposal/#/insure/CALCULATION?productCode=${this.getProductCode()}&orderId=&isShare=2&serialNo=${serialNo_n22}&fromAppUrl=${sFrom}`;
    }

    // TODO 删掉下面这段, 测试用
    // url = Consts.PATH.MOBILE_POLICY_CREATE //
    // 	.replace(':vendorCode', this.getVendorCode()) //
    // 	.replace(':productCode', this.getProductCode()) //
    // 	.replace(':productVersion', this.getProductVersion());
    // url += `?from=${Utils.encodeCurrentURI()}`

    // 记录跳转的操作
    Storage.ToDetail.lastAction({
      vendor: this.getVendorCode(),
      product: this.getProductCode(),
      version: this.getProductVersion(),
      action: 'create',
    });

    // 记录跳转的明细数据ID
    Storage.ToDetail.lastDetailId(this.getProductId());
    // 向上播送跳转
    Utils.notifyParentShouldGoToDetail(this);
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.location.replace(url);
    } else {
      if (window.location.pathname.indexOf('/wechat/') > -1) {
        const vendorCode = this.getVendorCode();
        const productCode = this.getProductCode();
        const productVersion = this.getProductVersion();
        const productId = this.getProductId();
        url = `${Consts.PATH.WECHAT_POLICY_INTRODUCTION}?vendorCode=${vendorCode}&productCode=${productCode}&productVersion=${productVersion}&productId=${productId}`; //
        // url = Consts.PATH.WECHAT_POLICY_INTRODUCTION  //
        // 	.replace(':vendorCode', this.getVendorCode()) //
        // 	.replace(':productCode', this.getProductCode()) //
        // 	.replace(':productVersion', this.getProductVersion())
        // 	.replace(':productId', this.getProductId());
        //url += `?from=${Utils.encodeCurrentURI()}`
        this.pushRedirect(url);
      } else {
        this.pushRedirect(url);
      }
    }
  };
}

export default Product;
