import { React } from '../../../common/3rd';
import './breadCrumb.css';
class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {
    let process = this.props.process;
    if (process) {
      this.setState({
        process,
      });
    }
  }
  state = {
    process: [
      { name: '1', active: false },
      { name: '2', active: false },
      { name: '3', active: false },
      { name: '4', active: false },
    ],
  };
  render() {
    let classArr = '';
    let activeIndex = this.props.active;
    let item = this.state.process.map((item, index) => {
      if (index <= activeIndex) {
        classArr = `bread_item active`;
        item.active = true;
      } else {
        classArr = `bread_item`;
      }
      return (
        <div className={classArr} key={index}>
          <div className="cc">{index + 1} </div>
          <div className="title"> {item.name} </div>
        </div>
      );
    });
    return <div className="custom-breadCrumb">{item}</div>;
  }
}

export default BreadCrumb;
