import { React } from '../../../common/3rd';
import { Lang } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';

import './desk-bottom.css';
import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'desk-bottom');

class DeskBottom extends ReactRouterContextWidget {
  render() {
    return (
      <div className={this.getWidgetClassName('desk-bottom')}>
        <div className="copyright">
          {Lang.messages['desk-bottom'].copyright}
        </div>
      </div>
    );
  }
}

export default DeskBottom;
