var htmlStr =
  '<div>' +
  '    <div id="hintPage" width="100%" style="background: #FFFFFF;">' +
  '        <div id="hintContent" class="fontSize14" width="100%" style="background: #FFFFFF; font-size: 14px">' +
  '           <div>' +
  '                <table width="100%">' +
  '                    <tr>' +
  '                        <td valign="middle" width="25%" >' +
  '                            <img id="logImage" width="100%" src="" />' +
  '                        </td>' +
  '                        <td valign="middle" width="15%">' +
  '                        </td>' +
  '                        <td valign="middle" align="right"  width="45%">' +
  '                            <div id="policy_id_t" style="font-size: 20px;"></div>' +
  '                        </td>' +
  '                    </tr>' +
  '                </table>' +
  '            </div>' +
  '            <div id="top-title" align="center">' +
  '                <b>人身保险投保单</b>' +
  '            </div>' +
  '            <div style="font-size: 15px; color: #231815; font-weight: bold; margin-top: 20px; overflow: hidden;">' +
  '               <div class="fontSize15" style="float: left;">投保须知</div>' +
  '               <div class="fontSize15" style="float: right; margin-right: 30px;">单证代码：1001</div>' +
  '            </div>' +
  '            <div class="fontSize15" style="border: solid 1px #000000; max-height: 999999px; line-height: 25px; font-weight: bold;">' +
  '                1、投保单为保险合同的重要组成部分。在填写投保单前，请您认真阅读保险产品说明书、保险条款和人身保险投保提示书，' +
  '在确认已充分理解保险责任、责任免除、保障范围、退保等条款后，再做出投保决定，并根据您的实际情况选择适合的保险金额和保险期间。' +
  '投保单必须由投保人、被保险人亲笔签名 (若被保险人为未成年人，则由其监护人亲笔签名)。<br />' +
  '                2、未成年被保险人的累计身故保险金金额以中国银行保险监督管理委员会的有关规定为最高限额。<br />' +
  '                3、本公司采集客户信息特别是联系电话和联系地址的用途，包括但不限于计算保费、核保、寄送保单和客户回访等；本公司承诺未经客户同意，' +
  '不会将客户信息用于人身保险公司和第三方机构的销售活动。' +
  '            </div>' +
  '            <div style="margin-top: 10px;">' +
  '                <table class="fontSize14" class="fontSize14" style="border: solid 1px #000000; font-size: 14px; width: 100%; border-collapse: collapse; text-align: center;" rules="all">' +
  '                    <tr>' +
  '                        <td style="width: 20%; height: 30px;"><b>销售渠道</b></td>' +
  '                        <td style="width: 10%;"><b>机构/渠道名称</b></td>' +
  '                        <td style="width: 30%;" id="manageComName"></td>' +
  '                        <td style="width: 10%;"><b>业务人员姓名</b></td>' +
  '                        <td style="width: 30%;" id="agent_name"></td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td height="30px" style="color: #e6454a;">' +
  '                           <input id="sale_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px" /><b>个人营销</b>' +
  '                           <input id="sale_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px" /><b>中介代理</b>' +
  '                        </td>' +
  '                        <td><b>机构/渠道代码</b></td>' +
  '                        <td id="manageCom"></td>' +
  '                        <td><b>业务人员代码</b></td>' +
  '                        <td id="agent_code"></td>' +
  '                    </tr>' +
  '                </table>' +
  '            </div>' +
  '            <div>' +
  '                <p><span class="fontSize15" style="font-size: 15px;"><b>第一部分&nbsp;&nbsp;基本资料</b></span>' +
  '                    <span style="color: #e6454a;">（请端正笔体，清晰填写）</span>' +
  '                </p>' +
  '                <div style="color: #e6454a;">' +
  '                    <span class="fontSize15" style="font-size: 15px;">A. 投保人资料</span>' +
  '                    <span>（投保人是被保险人的：</span>' +
  '                    <input id="app_rel_0" type="checkbox" name="" value="" style="margin-bottom: 3px" />本人' +
  '                    <input id="app_rel_1" type="checkbox" name="" value="" style="margin-bottom: 3px" />配偶' +
  '                    <input id="app_rel_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />父母' +
  '                    <input id="app_rel_3" type="checkbox" name="" value="" style="margin-bottom: 3px" />子女' +
  '                    <input id="app_rel_other" type="checkbox" name="" value="" style="margin-bottom: 3px" />其他<span style="text-decoration: underline;" id="app_rel_other_text">&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                    <span>）</span>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000;" cellspacing="0" width="100%" rules="all" >' +
  '                        <colgroup>' +
  '                            <col width="6%"></col>' +
  '                            <col width="4%"></col>' +
  '                            <col width="4%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="12%"></col>' +
  '                            <col width="2%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                        </colgroup>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" rowspan="2" style="border-right: none;">' +
  '                                <b>姓名:</b>' +
  '                            </td>' +
  '                            <td valign="middle" id="app_name" colspan="2" rowspan="2" style="border-left: none;">' +
  '                            </td>' +
  '                            <td valign="middle" rowspan="2" style="border-right: none;"><b>出生日期：</b></td>' +
  '                            <td valign="middle" colspan="2" rowspan="2" style="border-left: none;">' +
  '                                <span id="app_birth_year">&nbsp;&nbsp;</span><b>年</b>' +
  '                                <span id="app_birth_month">&nbsp;&nbsp;</span><b>月</b>' +
  '                                <span id="app_birth_day">&nbsp;&nbsp;</span><b>日</b>' +
  '                            </td>' +
  '                            <td valign="middle" rowspan="2">' +
  '                                <b>年龄：</b><span id="app_age"></span>' +
  '                            </td>' +
  '                            <td valign="middle" colspan="19" height="30px">' +
  '                                <b>证件类型：</b>' +
  '                                <input id="app_shenfen" type="checkbox" style="margin-bottom: 3px" />身份证&nbsp;&nbsp;' +
  '                                <input id="app_card_s" type="checkbox" style="margin-bottom: 3px" />其他<span' +
  '                                    id="app_card_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                                <b>国籍：</b><span id="app_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px">' +
  '                                <b>证件号码：</b>' +
  '                            </td>' +
  '                            <td id="ac_0" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_1" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_2" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_3" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_4" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_5" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_6" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_7" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_8" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_9" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_10" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_11" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_12" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_13" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_14" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_15" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_16" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ac_17" valign="middle" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" style="border-right: none;"><b>性别：</b></td>' +
  '                            <td style="border-left: none;border-right: none;" ><input type="checkbox" id="app_sex_man"/>男</td>' +
  '                            <td style="border-left: none;"><input type="checkbox" id="app_sex_woman"/>女</td>' +
  '                            <td valign="middle" style="border-left: none;border-right: none;"><b>婚姻状态：</b></td>' +
  '                            <td valign="middle" id="app_marriage" colspan="3" style="border-left: none;">' +
  '                                <input type="checkbox" id="app_mal_y" name="" value="" />已婚' +
  '                                <input type="checkbox" id="app_mal_n" name="" value="" />未婚' +
  '                                <input type="checkbox" id="app_mal_d" name="" value="" />离异' +
  '                                <input type="checkbox" id="app_mal_l" name="" value="" />丧偶' +
  '                            </td>' +
  '                            <td colspan="2" style="border-right: none;"><b>证件有效期：</b></td>' +
  '                            <td valign="middle" colspan="17" style="border-left: none;">' +
  '                                <input type="checkbox" id="app_card_time" name="" value="" style="margin-bottom: 3px" />至' +
  '                                <span id="app_card_end_year">&nbsp;&nbsp;</span>年<span id="app_card_end_moth">&nbsp;&nbsp;</span>月<span' +
  '                                    id="app_card_end_day">&nbsp;&nbsp;</span>日' +
  '                                <input type="checkbox" id="app_card_isLong" name="" value="" style="margin-bottom: 3px" />' +
  '                                长期' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>工作单位：</b></td>' +
  '                            <td valign="middle" id="app_workUnit" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="3" style="border-left: none;border-right: none;"><b>职业：</b><span id="app_job"></span></td>' +
  '                            <td valign="middle" colspan="5" style="border-left: none;border-right: none;"><b>兼职：</b><span></span></td>' +
  '                            <td valign="middle" colspan="7" style="border-left: none;border-right: none;text-align:right;"><b>最高职业代码：</b></td>' +
  '                            <td valign="middle" id="app_job_c0" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c1" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c2" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c3" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c4" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c5" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_job_c6" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>年收入：</b></td>' +
  '                            <td valign="middle" id="app_income" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td colspan="22" style="border-left: none;">' +
  '                                <b>主要来源：</b>' +
  '                                <input id="app_income_resource_0" type="checkbox" style="margin-bottom: 3px" /><b>工资</b>' +
  '                                <input id="app_income_resource_1" type="checkbox" style="margin-bottom: 3px" /><b>私营</b>' +
  '                                <input id="app_income_resource_2" type="checkbox" style="margin-bottom: 3px" /><b>务农</b>' +
  '                                <input id="app_income_resource_3" type="checkbox" style="margin-bottom: 3px" /><b>其他</b>' +
  '                                <span id="app_income_resource_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>；' +
  '                                <b>负债</b><span id="app_loan" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>万元，</b>' +
  '                                <b>原因：</b><span id="app_loan_resource" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>学历：</b></td>' +
  '                            <td valign="middle" id="app_level" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="4" style="border-left: none;border-right: none;">' +
  '                                <b>身高（CM）：</b><span id="app_height"></span>' +
  '                            </td>' +
  '                            <td valign="middle" colspan="20" style="border-left: none;border-right: none;">' +
  '                                <b>体重（KG）：</b><span id="app_weight"></span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>联系地址：</b></td>' +
  '                            <td valign="middle" align="right" id="app_address_province" colspan="2" style="border-left: none;border-right: none;">省</td>' +
  '                            <td valign="middle" align="right" colspan="2" id="app_address_city" style="border-left: none;border-right: none;">' +
  '                                市' +
  '                            </td>' +
  '                            <td valign="middle" align="right" id="app_address_region" colspan="2" style="border-left: none;border-right: none;">' +
  '                                区/县' +
  '                            </td>' +
  '                            <td valign="middle" id="app_address_area" colspan="20" style="border-left: none;border-right: none;">' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" style="border-right: none;"><b>邮编：</b></td>' +
  '                            <td valign="middle" id="app_postCode" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="9" style="border-left: none;border-right: none;"><b>固定电话：</b>' +
  '                                <span>&nbsp;&nbsp;&nbsp;&nbsp;— </span></td>' +
  '                            <td valign="middle" colspan="3" align="right" style="border-left: none;border-right: none;"><b>移动电话：</b></td>' +
  '                            <td valign="middle" id="app_mobile_0" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_1" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_2" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_3" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_4" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_5" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_6" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_7" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_8" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_9" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="app_mobile_10" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" colspan="3" style="border-right: none;"><b>首选回访电话：</b></td>' +
  '                            <td valign="middle" id="app_first_mobile" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="9" style="border-left: none;border-right: none;"><b>E-mail：</b>' +
  '                                <span id="app_email" >&nbsp;&nbsp;&nbsp;&nbsp;</span></td>' +
  '                            <td valign="middle" colspan="3" align="right" style="border-left: none;border-right: none;"><b>微信号：</b></td>' +
  '                            <td valign="middle" id="app_wx" colspan="11" style="border-left: none;border-right: none;"></td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div style="color: #e6454a; margin-top: 15px;">' +
  '                    <span class="fontSize15" style="font-size: 15px;">B. 被保险人资料</span>' +
  '                    <span>（被保险人是投保人的：</span>' +
  '                    <input id="ins_rel_0" type="checkbox" name="" value="" style="margin-bottom: 3px" />本人' +
  '                    <input id="ins_rel_1" type="checkbox" name="" value="" style="margin-bottom: 3px" />配偶' +
  '                    <input id="ins_rel_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />父母' +
  '                    <input id="ins_rel_3" type="checkbox" name="" value="" style="margin-bottom: 3px" />子女' +
  '                    <input id="ins_rel_other" type="checkbox" name="" value="" style="margin-bottom: 3px" />其他<span style="text-decoration: underline;" id="ins_rel_other_text">&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                    <span>,★若是投保人本人，免填此栏）</span>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000;" cellspacing="0" width="100%" rules="all" >' +
  '                        <colgroup>' +
  '                            <col width="6%"></col>' +
  '                            <col width="4%"></col>' +
  '                            <col width="4%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="12%"></col>' +
  '                            <col width="2%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="9%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                            <col width="2.5%"></col>' +
  '                        </colgroup>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" rowspan="2" style="border-right: none;">' +
  '                                <b>姓名:</b>' +
  '                            </td>' +
  '                            <td valign="middle" id="ins_name"  colspan="2" rowspan="2" style="border-left: none;">' +
  '                            </td>' +
  '                            <td valign="middle" rowspan="2" style="border-right: none;"><b>出生日期：</b></td>' +
  '                            <td valign="middle" colspan="2" rowspan="2" style="border-left: none;">' +
  '                                <span id="ins_birth_year">&nbsp;&nbsp;</span><b>年</b>' +
  '                                <span id="ins_birth_month">&nbsp;&nbsp;</span><b>月</b>' +
  '                                <span id="ins_birth_day">&nbsp;&nbsp;</span><b>日</b>' +
  '                            </td>' +
  '                            <td valign="middle" rowspan="2">' +
  '                                <b>年龄：</b><span id="ins_age"></span>' +
  '                            </td>' +
  '                            <td valign="middle" height="30px"><b>证件类型：</b></td>' +
  '                            <td valign="middle" colspan="18" height="30px">' +
  '                                <input id="ins_shenfen" type="checkbox" style="margin-bottom: 3px" />身份证&nbsp;&nbsp;' +
  '                                <input id="ins_card_s" type="checkbox" style="margin-bottom: 3px" />其他<span' +
  '                                    id="ins_card_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                                <b>国籍：</b><span id="ins_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px">' +
  '                                <b>证件号码：</b>' +
  '                            </td>' +
  '                            <td id="ic_0" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_1" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_2" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_3" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_4" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_5" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_6" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_7" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_8" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_9" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_10" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_11" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_12" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_13" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_14" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_15" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_16" valign="middle" align="center">&nbsp;</td>' +
  '                            <td id="ic_17" valign="middle" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" style="border-right: none;"><b>性别：</b></td>' +
  '                            <td style="border-left: none;border-right: none;" ><input type="checkbox" id="ins_sex_man"/>男</td>' +
  '                            <td style="border-left: none;"><input type="checkbox" id="ins_sex_woman"/>女</td>' +
  '                            <td valign="middle" style="border-left: none;border-right: none;"><b>婚姻状态：</b></td>' +
  '                            <td valign="middle" is="ins_marriage" colspan="3" style="border-left: none;border-right: none;">' +
  '                                <input type="checkbox" id="ins_mal_y" name="" value="" />已婚' +
  '                                <input type="checkbox" id="ins_mal_n" name="" value="" />未婚' +
  '                                <input type="checkbox" id="ins_mal_d" name="" value="" />离异' +
  '                                <input type="checkbox" id="ins_mal_l" name="" value="" />丧偶' +
  '                            </td>' +
  '                            <td colspan="2" style="border-right: none;"><b>证件有效期：</b></td>' +
  '                            <td valign="middle" colspan="17" style="border-left: none;">' +
  '                                <input type="checkbox" id="ins_card_time" name="" value="" style="margin-bottom: 3px" />至' +
  '                                <span id="ins_card_end_year">&nbsp;&nbsp;</span>年<span id="ins_card_end_moth">&nbsp;&nbsp;</span>月<span' +
  '                                    id="ins_card_end_day">&nbsp;&nbsp;</span>日' +
  '                                <input type="checkbox" id="ins_card_isLong" name="" value="" style="margin-bottom: 3px" />' +
  '                                长期' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>工作单位：</b></td>' +
  '                            <td valign="middle" id="ins_workUnit" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="3" style="border-left: none;border-right: none;"><b>职业：</b><span id="ins_job"></span></td>' +
  '                            <td valign="middle" colspan="5" style="border-left: none;border-right: none;"><b>兼职：</b><span></span></td>' +
  '                            <td valign="middle" colspan="7" style="border-left: none;border-right: none;text-align:right;"><b>最高职业代码：</b></td>' +
  '                            <td valign="middle" id="ins_job_c0" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c1" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c2" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c3" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c4" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c5" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_job_c6" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>年收入：</b></td>' +
  '                            <td valign="middle" id="ins_income" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td colspan="22" style="border-left: none;">' +
  '                                <b>主要来源：</b>' +
  '                                <input id="ins_income_resource_0" type="checkbox" style="margin-bottom: 3px" /><b>工资</b>' +
  '                                <input id="ins_income_resource_1" type="checkbox" style="margin-bottom: 3px" /><b>私营</b>' +
  '                                <input id="ins_income_resource_2" type="checkbox" style="margin-bottom: 3px" /><b>务农</b>' +
  '                                <input id="ins_income_resource_3" type="checkbox" style="margin-bottom: 3px" /><b>其他</b>' +
  '                                <span id="ins_income_resource_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>；' +
  '                                <b>负债</b><span id="ins_loan" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>万元，</b>' +
  '                                <b>原因：</b><span id="ins_loan_resource" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>学历：</b></td>' +
  '                            <td valign="middle" id="ins_level" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="4" style="border-left: none;border-right: none;">' +
  '                                <b>身高（CM）：</b><span id="ins_height"></span>' +
  '                            </td>' +
  '                            <td valign="middle" colspan="20" style="border-left: none;border-right: none;">' +
  '                                <b>体重（KG）：</b><span id="ins_weight"></span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" colspan="2" height="30px" style="border-right: none;"><b>联系地址：</b></td>' +
  '                            <td valign="middle" align="right" id="ins_address_province" colspan="2" style="border-left: none;border-right: none;">省</td>' +
  '                            <td valign="middle" align="right" colspan="2" id="ins_address_city" style="border-left: none;border-right: none;">' +
  '                                市' +
  '                            </td>' +
  '                            <td valign="middle" align="right" id="ins_address_region" colspan="2" style="border-left: none;border-right: none;">' +
  '                                区/县' +
  '                            </td>' +
  '                            <td valign="middle" id="ins_address_area" colspan="20" style="border-left: none;border-right: none;">' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" style="border-right: none;"><b>邮编：</b></td>' +
  '                            <td valign="middle" id="ins_postCode" colspan="2" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="9" style="border-left: none;border-right: none;"><b>固定电话：</b>' +
  '                                <span>&nbsp;&nbsp;&nbsp;&nbsp;— </span></td>' +
  '                            <td valign="middle" colspan="3" align="right" style="border-left: none;border-right: none;"><b>移动电话：</b></td>' +
  '                            <td valign="middle" id="ins_mobile_0" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_1" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_2" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_3" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_4" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_5" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_6" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_7" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_8" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_9" align="center">&nbsp;</td>' +
  '                            <td valign="middle" id="ins_mobile_10" align="center">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td valign="middle" height="30px" colspan="3" style="border-right: none;"><b>首选回访电话：</b></td>' +
  '                            <td valign="middle" colspan="2" id="ins_first_mobile" style="border-left: none;border-right: none;"></td>' +
  '                            <td valign="middle" colspan="9" style="border-left: none;border-right: none;"><b>E-mail：</b>' +
  '                                <span id="ins_email">&nbsp;&nbsp;&nbsp;&nbsp;— </span></td>' +
  '                            <td valign="middle" colspan="3" align="right" style="border-left: none;border-right: none;"><b>微信号：</b></td>' +
  '                            <td valign="middle" id="ins_wx" colspan="11" style="border-left: none;border-right: none;"></td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div style="color: #e6454a; margin-top: 15px;">' +
  '                    <span class="fontSize15" style="font-size: 15px;">C. 身故受益人资料</span>' +
  '                    <span>（法定受益人以外的指定受益人，请按照下表补充完整信息）</span>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table id="beneficiary_table" class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000;" cellspacing="0" width="100%" rules="all" >' +
  '                        <tr id="bene-first-tr" style="font-weight: bold;">' +
  '                            <td height="30px">姓名</td>' +
  '                            <td>性别</td>' +
  '                            <td>出生日期</td>' +
  '                            <td>证件类型</td>' +
  '                            <td>证件号码</td>' +
  '                            <td>是被保险人的（关系）</td>' +
  '                            <td>受益顺序</td>' +
  '                            <td>受益比例</td>' +
  '                            <td>联系方式</td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div style="color: #e6454a; margin-top: 15px;">' +
  '                    <span class="fontSize15" style="font-size: 15px;">D. 保险计划</span>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table id="product_table" class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000;" cellspacing="0" width="100%" rules="all">' +
  '                        <tr>' +
  '                            <td rowspan="2"><b>主险</b></td>' +
  '                            <td height="30px"><b>代码</b></td>' +
  '                            <td><b>险种名称</b></td>' +
  '                            <td><b>保险金额或份数</b></td>' +
  '                            <td><b>保障期限</b></td>' +
  '                            <td><b>保险费（元）</b></td>' +
  '                            <td><b>交费期限</b></td>' +
  '                        </tr>' +
  '                        <tr id="main-product-tr">' +
  '                            <td id="main_product_code" height="30px"></td>' +
  '                            <td id="main_product_name"></td>' +
  '                            <td id="main_product_sa"></td>' +
  '                            <td id="main_product_coverage"></td>' +
  '                            <td id="main_product_prem"></td>' +
  '                            <td id="main_product_charge"></td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td colspan="5" height="30px">' +
  '                                <b>合计保险费：</b>（大写）' +
  '                                <span id="total_prem_big"></span>' +
  '                            </td>' +
  '                            <td colspan="2">' +
  '                                <b>（小写）</b>¥ <span id="total_prem_small"></span>元' +
  '                            </td>' +
  '                        </tr>' +
  '                    </table>' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: none;" cellspacing="0" width="100%" rules="all">' +
  '                        <tr>' +
  '                            <td width="50%" style="border-top: none;" height="30px">' +
  '                                <b>交费频率：</b>' +
  '                                <input id="charge_once"  type="checkbox"/>一次交清' +
  '                                <input id="charge_year"  type="checkbox"/>年交' +
  '                                <input id="charge_other"  type="checkbox"/>其他' +
  '                                <span id="charge_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                            <td width="50%" style="border-top: none;">' +
  '                                <b>交费方式：</b>' +
  '                                <input id="charge_type_0" type="checkbox"/>银行自动转账' +
  '                                <input id="charge_type_1" type="checkbox"/>自交' +
  '                                <input id="charge_type_other" type="checkbox"/>其他' +
  '                                <span id="charge_type__text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                    </table>' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: none;" cellspacing="0" width="100%" rules="all">' +
  '                        <tr>' +
  '                            <td width="70%" style="border-top: none;" height="30px">' +
  '                                <b>现金价值自动垫交保费：</b>' +
  '                                <input id="auto_pay_yes"  type="checkbox"/>是' +
  '                                <input id="auto_pay_no"  type="checkbox"/>否' +
  '                                &nbsp;&nbsp;&nbsp;&nbsp;<b>账户价值自动抵交保费：</b>' +
  '                                <input id="provide_pay_yes"  type="checkbox"/>是' +
  '                                <input id="provide_pay_no"  type="checkbox"/>否' +
  '                            </td>' +
  '                            <td width="30%" style="border-top: none;" height="30px">' +
  '                                <b>一年期产品自动续保：</b>' +
  '                                <input id="continue_pay_yes"  type="checkbox"/>是' +
  '                                <input id="continue_pay_no"  type="checkbox"/>否' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td colspan="2" height="30px">' +
  '                                <b>选择电子保单服务：</b><input id="send_service"  type="checkbox"/>' +
  '                                （若勾选，保险合同及保全变更批单以数据电文的形式展现在我司官方网站上，不再递送纸质合同）' +
  '                            </td>' +
  '                        </tr>' +
  '                    </table>' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000; border-top: none;" cellspacing="0" width="100%" rules="all">' +
  '                        <colgroup>' +
  '                            <col width="7%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                            <col width="13%"></col>' +
  '                        </colgroup>' +
  '                        <tr>' +
  '                            <td rowspan="6" style="border-top: none;"><b>特殊险种填写栏</b></td>' +
  '                            <td style="border-top: none;" height="30px">红利领取方式</td>' +
  '                            <td colspan="6" style="border-top: none;">' +
  '                                <input id="divType_0"  type="checkbox"/>累积生息' +
  '                                <input id="divType_1"  type="checkbox"/>现金领取' +
  '                                <input id="divType_2"  type="checkbox"/>交清增额' +
  '                                <input id="divType_3"  type="checkbox"/>其他' +
  '                                <span id="divType_other_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td rowspan="2">年金领取</td>' +
  '                            <td colspan="6" height="30px">' +
  '                                年金开始领取日：被保险人年满' +
  '                                <span id="couponType_no" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                                周岁后的首个保单周年日' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td colspan="6" height="30px">' +
  '                                <input id="couponType_0"  type="checkbox"/>领取十年' +
  '                                <input id="couponType_1"  type="checkbox"/>领取二十年' +
  '                                <input id="couponType_2"  type="checkbox"/>领取三十年' +
  '                                <input id="couponType_3"  type="checkbox"/>其他' +
  '                                <span id="couponType_other_text" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td height="30px">万能产品保险费</td>' +
  '                            <td colspan="3">' +
  '                                期交保险费：人民币<span id="usual_prem" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>元' +
  '                            </td>' +
  '                            <td colspan="3">' +
  '                                追加保险费：人民币<span id="add_prem" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>元' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td rowspan="2">投连险账户</td>' +
  '                            <td height="30px">投资账户类型</td>' +
  '                            <td></td>' +
  '                            <td></td>' +
  '                            <td></td>' +
  '                            <td></td>' +
  '                            <td>投资比例总和</td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td height="30px">投资分配比例</td>' +
  '                            <td align="right">%</td>' +
  '                            <td align="right">%</td>' +
  '                            <td align="right">%</td>' +
  '                            <td align="right">%</td>' +
  '                            <td>100%</td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div style="color: #e6454a; margin-top: 15px;">' +
  '                    <span class="fontSize15" style="font-size: 15px;">E. 转账信息</span>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table class="fontSize14" style="border-collapse: collapse; font-size: 14px; border: solid 1px #000000;" cellspacing="0" width="100%" rules="all" >' +
  '                        <tr>' +
  '                            <td colspan="12" height="30px">' +
  '                                投保人账户名：<span id="account_name"></span>' +
  '                            </td>' +
  '                            <td colspan="13">' +
  '                                开户银行：<span id="bank_name"></span>' +
  '                            </td>' +
  '                        </tr>' +
  '                        <tr>' +
  '                            <td width="8.8%" height="30px">银行账号</td>' +
  '                            <td width="3.8%" id="bank_card_0"></td>' +
  '                            <td width="3.8%" id="bank_card_1"></td>' +
  '                            <td width="3.8%" id="bank_card_2"></td>' +
  '                            <td width="3.8%" id="bank_card_3"></td>' +
  '                            <td width="3.8%" id="bank_card_4"></td>' +
  '                            <td width="3.8%" id="bank_card_5"></td>' +
  '                            <td width="3.8%" id="bank_card_6"></td>' +
  '                            <td width="3.8%" id="bank_card_7"></td>' +
  '                            <td width="3.8%" id="bank_card_8"></td>' +
  '                            <td width="3.8%" id="bank_card_9"></td>' +
  '                            <td width="3.8%" id="bank_card_10"></td>' +
  '                            <td width="3.8%" id="bank_card_11"></td>' +
  '                            <td width="3.8%" id="bank_card_12"></td>' +
  '                            <td width="3.8%" id="bank_card_13"></td>' +
  '                            <td width="3.8%" id="bank_card_14"></td>' +
  '                            <td width="3.8%" id="bank_card_15"></td>' +
  '                            <td width="3.8%" id="bank_card_16"></td>' +
  '                            <td width="3.8%" id="bank_card_17"></td>' +
  '                            <td width="3.8%" id="bank_card_18"></td>' +
  '                            <td width="3.8%" id="bank_card_19"></td>' +
  '                            <td width="3.8%" id="bank_card_20"></td>' +
  '                            <td width="3.8%" id="bank_card_21"></td>' +
  '                            <td width="3.8%" id="bank_card_22"></td>' +
  '                            <td width="3.8%" id="bank_card_23"></td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '            </div>' +
  '            <div>' +
  '                <p class="fontSize15" style="font-size: 15px;"><b>第二部分&nbsp;&nbsp;&nbsp;&nbsp;健康告知与其他告知</b></p>' +
  '                <table id="tell" class="fontSize14" border="1" width="100%" cellspacing="0" bordercolor="#000000" rules="all"	style="border-collapse: collapse; font-size: 14px;line-height:1.5; border-right: none;">' +
  '                    <tr>' +
  '                        <td valign="middle" align="center" width="80%" rowspan="2">' +
  '                            <b class="fontSize16" style="font-size: 16px;">告 知 事 项</b><br />（若险种无投保人保险责任的，可免填投保人栏）' +
  '                        </td>' +
  '                        <td valign="middle" align="center" style="font-weight: bold;" height="30px"width="8%">投保人</td>' +
  '                        <td valign="middle" align="center" style="font-weight: bold;" width="8%">被保人</td>' +
  '                    </tr>' +
  '                    <tr style="font-weight: bold;">' +
  '                        <td valign="middle" align="center" style="font-weight: bold;" height="30px" width="8%">是   否</td>' +
  '                        <td valign="middle" align="center" style="font-weight: bold;" width="8%">是   否</td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            1.您是否每日吸烟超过10支，每日饮酒（白酒）超过100毫升？若有饮酒史，请在说明栏告知酒的种类、饮酒量、饮酒年限。' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_1_y" name="" value=""  />' +
  '                            <input type="checkbox" id="app_inform_1_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_1_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_1_n" class="ins_inform" name="" value=""  />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">2.您最近两年内是否有过门诊诊疗？是否存在医学检查（包括健康体检）结果异常？是否接受过药物治疗？或被医师建议需身体检查或治疗？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_2_y" name="" value=""  />' +
  '                            <input type="checkbox" id="app_inform_2_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_2_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_2_n" class="ins_inform" name="" value=""  />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">3.您最近两年内是否有过头晕、头痛、胸闷、咯血、呕血、黄疸、听力下降、耳鸣、复视、视力明显下降、腹痛、血尿、便血、消瘦（3个月内超过5公斤）、乏力、长期发热、不明原因皮下出血等不适？是否曾有过身体检查结果异常？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_3_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_3_n" class="app_inform" name="" value=""  />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_3_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_3_n" class="ins_inform" name="" value=""  />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">4.您是否患有或被怀疑患有癌症、肿瘤、包块或尚未证实为良恶性的肿瘤、息肉、囊肿、赘生物等？是否接受过器官移植？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_4_y" name="" value=""  />' +
  '                            <input type="checkbox" id="app_inform_4_n" class="app_inform" name="" value=""  />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_4_y"  name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_4_n"  class="ins_inform" name="" value=""  />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" style="line-height: 30px;">5.您过去是否曾经住院？是否做过手术？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_5_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_5_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_5_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_5_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">6.您或您的配偶是否接受或试图接受与艾滋病有关的医疗咨询、检验或治疗，或者被检出HIV阳性？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_6_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_6_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_6_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_6_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">7.您是否患有或被怀疑患有先天性疾病、遗传性疾病或职业病？是否有智力发育异常，肢体、重要器官残疾、畸形或功能障碍？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_7_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_7_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_7_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_7_n" class="ins_inform" name="" value=""  />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">8.您是否患有或被怀疑患有以下疾病？若“是”请在说明栏告知<br />' +
  '                            A、心血管系统疾病：高血压（收缩压140mmHg或舒张压90mmHg以上）、冠心病、心肌梗塞、先心病、风心病、' +
  '肺心病、心肌病、心肌炎、心力衰竭、心律失常、室壁瘤、动脉瘤、心脏瓣膜病、主动脉疾病等；' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8a_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8a_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8a_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8a_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            B、脑、神经、精神系统疾病;脑梗塞、脑出血、脑炎、脑膜炎、癫痫、重症肌无力、多发性硬化、帕金森氏综合症' +
  '、运动神经元病、阿尔茨海默病、脊髓疾病、脑血管瘤、精神病、脑部手术史、抑郁症等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8b_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8b_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8b_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8b_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            C、消化道疾病：慢性酒精中毒、肝炎、肝硬化、胰腺疾病、消化道溃疡、出血穿孔、胰腺炎、溃疡性结肠炎、' +
  '腹部手术史等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8c_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8c_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8c_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8c_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            D、呼吸系统疾病：慢性支气管炎、哮喘、支气管扩张、肺气肿、肺结核、呼吸衰竭、尘肺、矽肺、肺心病、' +
  '肺栓塞、肺纤维化等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8d_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8d_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8d_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8d_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            E、内分泌、代谢疾病：甲状腺疾病、甲状旁腺疾病、糖尿病、痛风、垂体机能亢进或减退、肾上腺机能亢进或减退、' +
  '高脂血症等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8e_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8e_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8e_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8e_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            F、泌尿系统疾病：血尿、肾炎、肾功能异常、肾病综合症、肾功能衰竭、多囊肾、肾囊肿、肾盂积水、尿路' +
  '畸形、前列腺疾病等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8f_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8f_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8f_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8f_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            G、血液系统疾病：血友病、白血病、各类贫血、紫癜、被建议不宜献血等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8g_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8g_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8g_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8g_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            H、结缔组织病：风湿性关节炎、类风湿疾病、系统性红斑狼疮、强直性脊柱炎、硬皮病、干燥综合征等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8h_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8h_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8h_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8h_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            I、运动系统疾病：骨关节畸形、人工装置物、脊椎疾病等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8i_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8i_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8i_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8i_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            J、恶性肿瘤：是否罹患恶性肿瘤等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8j_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8j_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8j_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8j_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            K、五官疾病：青光眼、白内障、视神经病变、视网膜出血或剥离、神经性耳聋、高度近视800度以上等' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8k_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8k_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8k_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8k_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">' +
  '                            L、是否患有遗传性疾病？' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_8l_y" name="" value=""/>' +
  '                            <input type="checkbox" id="app_inform_8l_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_8l_y" name="" value=""  />' +
  '                            <input type="checkbox" id="ins_inform_8l_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">9.您是否患有上述第8条所列举的器官系统疾病之外的疾病？若“是”请在说明栏告知</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_9_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_9_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_9_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_9_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">10.您是否曾经或正在使用毒品或违禁药物？若“是”请在说明栏告知</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_10_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_10_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_10_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_10_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">11.您的父母、子女、兄弟姐妹中，是否有人患有心脏病、脑中风、高血压、多囊肾、癌症、血友病、糖尿病、精神疾病、肺结核、病毒性肝炎、肝硬化、肝豆状核变性、艾滋病以及其它遗传性或传染性疾病？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_11_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_11_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_11_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_11_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr class="justFemale">' +
  '                        <td valign="middle" align="left" height="30px"><b class="fontSize15" style="color: #e6454a; font-size: 15px;">12.女性补充告知（16周岁及以上填写）</b>：目前是否怀孕？若“是”，请说明：怀孕____周；<br />' +
  '                            A 您怀孕及生产期间是否有合并症？<br />' +
  '                            B 您是否因患乳腺疾病、妇科疾病而接受过医师的诊查、治疗、用药或住院手术？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_12_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_12_n" class="app_inform" name="" value="" /><br/>' +
  '                            A<input type="checkbox" id="app_inform_12a_y" name="" value="" />' +
  '                            A<input type="checkbox" id="app_inform_12a_n" class="app_inform" name="" value="" /><br/>' +
  '                            B<input type="checkbox" id="app_inform_12b_y" name="" value="" />' +
  '                            B<input type="checkbox" id="app_inform_12b_n" class="app_inform" name="" value="" /><br/>' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_12_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_12_n" class="ins_inform" name="" value="" /><br/>' +
  '                            A<input type="checkbox" id="ins_inform_12a_y" name="" value="" />' +
  '                            A<input type="checkbox" id="ins_inform_12a_n" class="app_inform" name="" value="" /><br/>' +
  '                            B<input type="checkbox" id="ins_inform_12b_y" name="" value="" />' +
  '                            B<input type="checkbox" id="ins_inform_12b_n" class="app_inform" name="" value="" /><br/>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr class="justFemale">' +
  '                        <td valign="middle" align="left" height="30px"><b class="fontSize15" style="color: #e6454a; font-size: 15px;">13.婴幼儿告知（2周岁及以下填写）</b>：出生时身高____厘米，体重____公斤；<br />' +
  '                            是否早产、多胎、难产、过期产、产伤、窒息、惊厥、抽搐、脑瘫、先天性疾病、遗传性疾病或畸形？' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_13_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_13_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_13_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_13_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">14.您是否有飞行、潜水、跳伞、拳击、赛车、滑雪、特技表演等高风险运动嗜好？若是，请说明名称、地点、频率、受伤记录。</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_14_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_14_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_14_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_14_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">15.您是否已经拥有或正在申请本公司或其他保险公司的人身保险合同？若“是”,请告知公司及险种名称、保险金额、投保日期</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_15_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_15_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_15_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_15_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">16.<br>A、目前您是否有公费医疗、社会医疗保险、农村合作医疗保险、商业医疗保险或其他医疗费用报销方式？若“是”请告知参保机构。</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_16_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_16_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_16_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_16_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">B、目前您是否有商业医疗保险或其他医疗费用报销方式？若“是”请告知参保机构。</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_16_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_16_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_16_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_16_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">17.您是否曾被保险公司解除合同或投保、复效时被拒保、延期或附加条件承保？是否有过保险索赔？</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_17_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_17_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_17_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_17_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">18.您是否正计划前往其他国家或海外地区旅行，工作或居住? 若“是”, 请详述时间及具体前往的国家/海外地区。</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_18_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_18_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_18_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_18_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">19. <br>A、您是否有机动车驾驶执照？若“是”，请告知驾照类型<br />' +
  '                            B、您是否曾违章驾车并发生交通事故，若“是”，请在说明栏告知次数、时间、违章类型</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <div align="left" style="border-bottom: 1px solid #000000">类型<span id="app_inform_19_text"></span></div>' +
  '                            <input type="checkbox" id="app_inform_19_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_19_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <div align="left" style="border-bottom: 1px solid #000000">类型<span id="ins_inform_19_text"></span></div>' +
  '                            <input type="checkbox" id="ins_inform_19_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_19_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td  valign="middle" align="left" height="30px">20.您目前或既往是否有申请除本公司以外的人身保险？若“是”，请在说明栏告知承保公司、承保险种名称、保险金额、日期</td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="app_inform_20_y" name="" value="" />' +
  '                            <input type="checkbox" id="app_inform_20_n" class="app_inform" name="" value="" />' +
  '                        </td>' +
  '                        <td valign="middle"  align="center">' +
  '                            <input type="checkbox" id="ins_inform_20_y" name="" value="" />' +
  '                            <input type="checkbox" id="ins_inform_20_n" class="ins_inform" name="" value="" />' +
  '                        </td>' +
  '                    </tr>' +
  '                </table>' +
  '                <div align="center" class="fontSize18" style="font-size: 18px; margin-top: 15px;">' +
  '                    <div><b>说 明 栏</b></div>' +
  '                    <div class="fontSize13" style="font-size: 13px;">（上述问题中如有任何答案为“是”，请注明序号并具体说明，有疾病史提供相关病历资料、检查报告；若填写不下时，请另附纸张填写，并需投保人/被保人签字确认）</div>' +
  '                </div>' +
  '                <div class="table-container">' +
  '                    <table id="comment" class="fontSize14" cellspacing="0" width="100%" rules="all" style="font-size: 14px; border-collapse: collapse; border: 1px solid #000000">' +
  '                        <tbody align="center" style="border-collapse: collapse;">' +
  '                        <tr style="text-align: center;">' +
  '                            <td valign="middle" width="3%" height="30px"><b>告知序号</b></td>' +
  '                            <td valign="middle" width="3%"><b>说明对象</b></td>' +
  '                            <td valign="middle" width="20%"><b>说明内容</b></td>' +
  '                        </tr>' +
  '                        </tbody>' +
  '                    </table>' +
  '                </div>' +
  '                <div align="left" class="fontSize14" style="padding-left:10px; border: 1px solid #000000; font-size: 14px;line-height: 25px; margin-top: 5px; height: 100px;">' +
  '                    <b>备注：</b><span id="comment"></span>' +
  '                </div>' +
  '            </div>' +
  '            <div>' +
  '                <p class="fontSize15" style="font-size: 15px;"><b>第三部分 投保人、被保人或法定监护人声明和授权</b></p>' +
  '                <p style="color: #e6454a;">A．授权与声明</p>' +
  '                <div class="fontSize14" align="left"style="padding-left:10px; border: 1px solid #000000; font-size: 14px;line-height: 25px;">' +
  '                    <b>一、投保人、被保险人授权与声明</b><br/>' +
  '                    1、本人是在北京人寿保险股份有限公司（以下简称“贵公司”）业务人员的指导下亲自填写，若投保单内容由他人代为填写时，在投保人、被保险人或其法定监护人签名栏由本人亲笔签名。' +
  '                    <br/>2、本人已认真阅读保险产品说明书、保险条款和人身保险投保提示书，已充分理解保险责任、<b>责任免除</b>、保障范围、退保等条款，且对本人所不能理解的内容已经向贵公司提出询问，<b>贵公司对本人所询问的问题均已解答清楚，而且对保险条款中的免责条款，以及其他可能导致贵公司不承担保险责任的条款，业务人员均已向本人作出了明确说明。</b>' +
  '                    <br/> 3、本人已知晓应履行如实告知义务，在投保书中的健康、财务及其他告知内容均属实，与本投保书有关的问卷、体检报告及对体检医生的各项陈述均确实无误，如未履行如实告知义务，足以影响贵公司核保结论的，贵公司有权依法解除保险合同，并依法不承担给付保险金的责任。所有告知事项以书面告知为准，口头告知无效。' +
  '                    <br/>4、本人同意并授权贵公司查阅、复印任何与被保险人健康、财务等情况有关的体检报告、诊断报告、病历、收入证明等资料；贵公司有权对本人进行医疗评估及测试（体检、血液检查及其他医疗检查），并作为审核本投保单及评估与本投保单有关的理赔申请依据。本授权书影印件与原件同样有效。在中国法律允许或要求范围内，同意授权保险人将保单信息提供给监管允许的信息平台。' +
  '                    <br/>5、本人已知贵公司所负保险责任以所签发保险合同为准，与本投保单各事项及保险条款相违背的说明或解释均属无效；除由贵公司经正式程序修改或批注的内容外，其它任何人的口头及书面陈述、报告或合约，贵公司无需负责。' +
  '                    <br/>6、本人将认真核对贵公司在合同承保后出具送达的正式保险合同，并将在保险合同回执上亲笔签字。' +
  '                    <br/>7、本人同意贵公司通过电话、手机、E-mail、微信等适时提供保险信息服务。' +
  '                    <br/>8、本人确认所填的《个人税收居民身份声明文件》真实、完整，当信息发生变更时，将在30 日内通知贵机构，否则由本人承担由此造成的不利后果。' +
  '                    <br/>9、本人知悉贵公司不接受除被保险人本人之外的生存金受益人作为本合同生存金受益人，具体生存金受益人以条款约定为准。' +
  '                    <br/><b>二、投保人转账授权声明</b>' +
  '                    <br/>1、本投保单中所填开户银行及账号是本人的开户银行及账号，本人授权贵公司从该账户转账支付与贵公司约定的各期保险费。' +
  '                    <br/>2、本人同意，如果因授权账户错误、账户注销、账户金额不足或授权账户不符合贵公司对授权账户要求而导致转账不成功，致使合同不能成立或不能持续生效，贵公司无须承担责任。' +
  '                    <br/>3、本人同意，贵公司将多收的保险费及拒保、延期、撤单、退保等引起的退费通过该账户返还给本人。' +
  '                    <br/>4、本人同意，若投保的万能险有万能账户抵交保费功能，当续期保费欠缴时，贵公司可优先从该万能账户价值中抵交保费。' +
  '                </div>' +
  '                <p style="color: #e6454a;">B．投保人确认栏（若您投保投资连结、万能、分红保险以及中国保险监督管理委员会认定的其他产品，请抄录以下内容）</p>' +
  '                <div class="table-container" id="tb10" style="margin-left:-1%">' +
  '                    <table class="fontSize16" cellspacing="0" width="100%" rules="all" style="font-size: 16px; border-collapse: collapse; border: 1px solid #000000; color: #e6454a; font-weight: bold;">' +
  '                        <tr align="center">' +
  '                            <td valign="middle" width="44">本</td>' +
  '                            <td valign="middle" width="44">人</td>' +
  '                            <td valign="middle" width="44">已</td>' +
  '                            <td valign="middle" width="44">阅</td>' +
  '                            <td valign="middle" width="44">读</td>' +
  '                            <td valign="middle" width="44">保</td>' +
  '                            <td valign="middle" width="44">险</td>' +
  '                            <td valign="middle" width="44">条</td>' +
  '                            <td valign="middle" width="44">款</td>' +
  '                            <td valign="middle" width="44">、</td>' +
  '                            <td valign="middle" width="44">产</td>' +
  '                            <td valign="middle" width="44">品</td>' +
  '                            <td valign="middle" width="44">说</td>' +
  '                            <td valign="middle" width="44">明</td>' +
  '                            <td valign="middle" width="44">和</td>' +
  '                            <td valign="middle" width="44">投</td>' +
  '                            <td valign="middle" width="44">保</td>' +
  '                            <td valign="middle" width="44">提</td>' +
  '                            <td valign="middle" width="44">示</td>' +
  '                            <td valign="middle" width="44">书</td>' +
  '                            <td valign="middle" width="44">，</td>' +
  '                            <td valign="middle" width="44">了</td>' +
  '                            <td valign="middle" width="44">解</td>' +
  '                            <td valign="middle" width="44">本</td>' +
  '                            <td valign="middle" width="44">产</td>' +
  '                        </tr>' +
  '                        <tr align="center">' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                            <td valign="middle" width="44">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr align="center">' +
  '                            <td valign="middle">品</td>' +
  '                            <td valign="middle">的</td>' +
  '                            <td valign="middle">特</td>' +
  '                            <td valign="middle">点</td>' +
  '                            <td valign="middle">和</td>' +
  '                            <td valign="middle">保</td>' +
  '                            <td valign="middle">单</td>' +
  '                            <td valign="middle">利</td>' +
  '                            <td valign="middle">益</td>' +
  '                            <td valign="middle">的</td>' +
  '                            <td valign="middle">不</td>' +
  '                            <td valign="middle">确</td>' +
  '                            <td valign="middle">定</td>' +
  '                            <td valign="middle">性</td>' +
  '                            <td valign="middle">。</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                        </tr>' +
  '                        <tr align="center">' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                            <td valign="middle">&nbsp;</td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div class="fontSize14" align="left" style="padding-left:10px; border: 1px solid #000000; font-size: 14px;line-height: 25px; margin-top: 15px;">' +
  '                    指定保险合同生效日（非必填内容）<br/>' +
  '                    本人（投保人）希望本保险合同自&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日零时（即指定的合同生效时间）开始生效。<br/>' +
  '                    同时本人（投保人）同意：<br/>' +
  '                    1. 如指定的合同生效时间晚于贵公司同意承保时间，本保险合同将以指定的时间开始生效。<br/>' +
  '                    2. 如本投保申请经贵公司审核不同意承保，指定的合同生效时间无效，贵公司自始不承担保险责任。' +
  '                </div>' +
  '                <br/>' +
  '                <div>' +
  '                    <div style="width: 100%;">' +
  '                        <span >投保人签名：</span><img  id="imageApp1" style="width:15%;"/>' +
  '                        <span >被保险人/ 其法定监护人签名：</span><img  id="imageApp2" style="width:15%;"/>' +
  '                        <span >其他被保人/ 法定监护人签名：</span><img  id="imageApp3" style="width:15%;"/>' +
  '                    </div>' +
  '                    <div>' +
  '                        投保申请日期：<span class="sign_year">&nbsp;&nbsp;&nbsp;&nbsp;</span>年' +
  '                        <span class="sign_month">&nbsp;&nbsp;&nbsp;&nbsp;</span>月' +
  '                        <span class="sign_day">&nbsp;&nbsp;&nbsp;&nbsp;</span>日' +
  '                    </div>' +
  '                </div>' +
  '                <div class="fontSize14" align="left" style="padding-left:10px; border: 1px solid #000000; font-size: 14px;line-height: 25px; margin-top: 15px;">' +
  '                    业务人员声明：本人已面晤投保人、被保人并就《人身保险投保单》列明的所有告知逐一向投保人、被保人当面询问，就责任免除条款进行提示和说明，并亲自见证投保人、被保人在本投保单上签字。如有不实见证或报告、本人愿承担相应法律责任。<br/>' +
  '                    <b>第一及第二业务人员签字：</b><img id="imageAgentApp" style="width:15%;"/>' +
  '                    <b>业务人员电话：</b><span id="agent_mobile"></span><br/>' +
  '                    <b>日期：<span class="sign_year">&nbsp;&nbsp;&nbsp;&nbsp;</span>年' +
  '                        <span class="sign_month">&nbsp;&nbsp;&nbsp;&nbsp;</span>月' +
  '                        <span class="sign_day">&nbsp;&nbsp;&nbsp;&nbsp;</span>日</b>' +
  '                </div>' +
  '            </div>' +
  '            <br />' +
  '            <br />' +
  '        </div>' +
  '    </div>' +
  '</div>';
export default htmlStr;
