import { React, ReactDOM, $ } from '../../../../common/3rd';

import AtomicMutableComponentThing from '../atomic-mutable-component';
import AtomicComponentThing from '../atomic-component';
import { PageLoading, Alert } from '../../../components';
import messages from '../messages.json';
import Lang from '../../../../common/lang';
Lang.installMessages(messages, 'VerifiMessage');

let sendTimer;
class SendVerifiCodeButton extends AtomicComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.operable = true; //按钮是否可触发
    this.state.second = 0; //倒计时时间
  }
  componentWillUnmount() {
    clearInterval(sendTimer);
  }
  render() {
    var classNames =
      this.state.operable === true
        ? 'send-verificode-btn'
        : 'send-verificode-btn disable';
    var msg =
      this.state.operable === true
        ? '发送验证码'
        : this.state.second + 's后重新获取';
    return (
      <div className={classNames} onClick={this.send}>
        {msg}
      </div>
    );
  }
  send = () => {
    if (!this.state.operable) return;
    let sendSuc = this.props.callSend && this.props.callSend();
    if (!sendSuc) return;
    this.setState({
      operable: false,
      second: 60,
    });
    sendTimer = setInterval(this.timeStart, 1000);
  };
  timeStart = () => {
    if (this.state.second <= 0) {
      clearInterval(sendTimer);
      this.setState({
        operable: true,
        second: 0,
      });
    } else {
      this.setState({
        second: this.state.second - 1,
      });
    }
  };
}
/**
 * 短信验证
 * add by wendy.gu 2017 Oct.25
 *
 */
class verifiMessage extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.verifySuccess = false; //身份校验成功
  }
  render() {
    let proposerPhone = this.props.model.policyHolder.mobile || '';
    return (
      <div
        id="message-check-container"
        {...this.getPrototypes()}
        ref="checkContainer"
      >
        <div className="phone verifiItem">
          <label>手机号码</label>
          <input
            ref="mobile"
            className="variInput"
            value={proposerPhone}
            placeholder="请输入手机号码"
          />
          <SendVerifiCodeButton parent={this} callSend={this.callSend} />
        </div>
        <div className="verifiItem">
          <label>验证码&nbsp;</label>
          <input ref="code" className="variInput" placeholder="请输入验证码" />
          <div className="send-verificode-btn" onClick={this.verification}>
            验证
          </div>
        </div>
        <div className="successPrompt" ref={'successPrompt'}>
          <span className="sucImg"></span>短信验证成功，请继续操作
        </div>
      </div>
    );
  }
  callSend = () => {
    let mobile = ReactDOM.findDOMNode(this.refs.mobile).value;
    if (mobile == '') {
      Alert.message(Lang.messages.VerifiMessage.InputPhone);
      return false;
    }
    let options = {
      sendDate: '',
      data: '',
      smsSources: this.getLayout().smsSources || 2,
      mobile: mobile,
      templateName: 'selfuw-suc-check',
    };
    PageLoading.start();
    //发送验证码
    this.doPost('/rest/v1/sms/send', options)
      .done((data) => {
        if (data && data.resultCode === 'suc') {
          Alert.message(Lang.messages.VerifiMessage.SendSuccess);
        } else if (data && data.resultCode === 'manytimes') {
          Alert.message(Lang.messages.VerifiMessage.verifyTimes);
        } else if (data && data.resultCode === 'often') {
          Alert.message('您操作过于频繁，请稍后再试！');
          return;
        } else {
          Alert.message(Lang.messages.VerifiMessage.SendFailure);
        }
      })
      .always(() => {
        PageLoading.end();
      });
    return true;
  };
  //验证
  verification = () => {
    let mobile = ReactDOM.findDOMNode(this.refs.mobile).value;
    let code = ReactDOM.findDOMNode(this.refs.code).value;
    if (!mobile || mobile === '') {
      Alert.message(Lang.messages.VerifiMessage.verifilyMsg1);
      return;
    }
    if (code === '') {
      Alert.message(Lang.messages.VerifiMessage.verifilyMsg2);
      return;
    }
    var options = {
      smsSources: this.getLayout().smsSources || 2,
      phoneNo: mobile,
      smsCode: code,
    };
    PageLoading.start();
    this.doPost('/rest/v1/sms/check', options)
      .done((data) => {
        if (data && data.resultCode === 'timeout') {
          Alert.message(Lang.messages.VerifiMessage.verifilyMsg3);
        } else if (data && data.resultCode === 'suc') {
          this.setState(
            {
              verifySuccess: true,
            },
            () => {
              this.renderSucMsg();
            }
          );
        } else {
          Alert.message(Lang.messages.VerifiMessage.verifilyMsg4);
        }
      })
      .always(() => {
        PageLoading.end();
      });
  };
  renderSucMsg = () => {
    //this.props.model.policyStatus = "80"; //身份验证成功 改变当前状态为‘待支付’
    this.refs.successPrompt.style.display = 'block';
    this.refs.successPrompt.scrollIntoView();
    // $(".successPrompt").slideDown();
    // let height = $("#message-check-container").parent().height();
    // $("#message-check-container").parent().animate({scrollTop:height+45},600);
  };
  getValueFromModel() {
    return this.state.verifySuccess;
  }
  getDefaultClassName() {
    return 'message-check-container';
  }
  componentDidMount() {}
}

export default verifiMessage;
