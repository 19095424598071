var htmlStr =
  '<div>' +
  '    <div id="hintPage" width="100%" style="background: #FFFFFF;">' +
  '        <div id="hintContent" width="100%" style="background: #FFFFFF;">' +
  '            <div style="position: relative; " class="page-content" >' +
  '               <div>' +
  '                    <table width="100%">' +
  '                        <tr>' +
  '                            <td valign="middle" width="25%" >' +
  '                                <img id="logImage" width="100%" src="" />' +
  '                            </td>' +
  '                            <td valign="middle" width="15%">' +
  '                            </td>' +
  '                            <td valign="middle" align="right"  width="45%">' +
  '                                <div id="agentReport_policy_id" style="font-size: 20px;"></div>' +
  '                            </td>' +
  '                        </tr>' +
  '                    </table>' +
  '                </div>' +
  '                <div align="center"' +
  '                     style="width: 100%; font-size: 17px; height: 17px; line-height: 17px; position: absolute;margin-top: 15px">业务人员报告书</div>' +
  '                <br />' +
  '                <br />' +
  '                <!-- 保单正文 queryForm-->' +
  '                <div style="margin-top: 15px;"><b>重要提示：请业务人员确认在投保时已向客户提供投保险种的条款，并对条款中的内容进行详细的讲解，尤其  是条款中的责任免除部分。</b></div>' +
  '                <table id="tell" style="border: solid 1px #000000; width: 100%; margin-top: 15px; border-collapse: collapse;" rules="all">' +
  '                    <tr style="border-bottom-color: white">' +
  '                        <td style="border-bottom-color: black" rowspan="3" style="width: 40%; line-height: 30px;">1、本合同您是经由以下何种方式而签订：</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 40%; float: left;">' +
  '                                <input data-remark="陌生拜访" id="sign_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" />陌生拜访' +
  '                            </span>' +
  '                            <input data-remark="原已相识" id="sign_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px" /><span>原已相识 认识时间</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr style="border-bottom-color: white">' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 40%;">' +
  '                                <input data-remark="朋友或保户介绍" id="sign_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />朋友／保户介绍' +
  '                            </span>' +
  '                            <input data-remark="投保人或被保险人主动要求" id="sign_type_3" type="checkbox" name="" value="" style="margin-bottom: 3px" />投保人／被保险人主动要求' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 40%;">' +
  '                                <input data-remark="亲属" id="sign_type_4" type="checkbox" name="" value="" style="margin-bottom: 3px" />亲属请说明关系' +
  '                            </span>' +
  '                            <input data-remark="其他" id="sign_type_5" type="checkbox" name="" value="" style="margin-bottom: 3px" />其他，请说明' +
  '                            <span name="description" id="sign_other_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">2、您是否未亲眼见过被保险人？</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input data-remark="是" id="see_insured_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" />是' +
  '                            </span>' +
  '                            <input data-remark="否" id="see_insured_no" type="checkbox" name="" value="" style="margin-bottom: 3px" />否，若“是”请说明' +
  '                            <span name="description" id="see_insured_no_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="border-bottom: none;">3、您是否未亲见被保险人的居住环境和条件？</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input data-remark="是" id="see_place_yes" type="checkbox" name="" value="" style="margin-bottom: 3px;" />是' +
  '                            </span>' +
  '                            <input data-remark="否" id="see_place_no" type="checkbox" name="" value="" style="margin-bottom: 3px" />否，若“是”请说明' +
  '                            <span name="description" id="see_place_no_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="border-top: none; border-bottom: none;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;住房性质为</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input data-remark="自有" id="place_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px" />自有' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input data-remark="租借" id="place_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px" />租借' +
  '                            </span>' +
  '                            <input data-remark="其他" id="place_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />其他' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="border-top: none;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收入与居住环境和条件不相符？</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input data-remark="是" id="match_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" />是' +
  '                            </span>' +
  '                            <input data-remark="否" id="match_no" type="checkbox" name="" value="" style="margin-bottom: 3px" />否' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td rowspan="2">4、本投保计划是：</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 40%;">' +
  '                                <input data-remark="您替投保人设计的" id="plan_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" />您替投保人设计的' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 60%;">' +
  '                               <input data-remark="您与投保人共同设计" id="plan_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px" />您与投保人共同设计' +
  '                            </span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 40%;">' +
  '                                <input data-remark="投保人设计的" id="plan_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />投保人设计的' +
  '                            </span>' +
  '                            <input data-remark="其他" id="plan_type_3" type="checkbox" name="" value="" style="margin-bottom: 3px" />其他，请说明' +
  '                            <span id="plan_type_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">5、投保人投保的目的：</td>' +
  '                        <td>' +
  '                            <input id="purpose_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="人身保障" />人身保障' +
  '                            <input id="purpose_1" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="储蓄" />储蓄' +
  '                            <input id="purpose_2" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="子女教育经费" />子女教育经费' +
  '                            <input id="purpose_3" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="您与投保人共同设计" />您与投保人共同设计' +
  '                            <input id="purpose_4" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="其他" />其他，请说明' +
  '                            <span name="description"  id="purpose_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">6、投保人、被保险人是否有身体缺陷或智力障碍或其他疾病？</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input  id="disease_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="是" />是' +
  '                            </span>' +
  '                            <input id="disease_no" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="否" />否 若“是”，请详述' +
  '                            <span  id="disease_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">7、是否听说被保险人曾生病或曾就医？</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="hospital_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="是" />是' +
  '                            </span>' +
  '                            <input id="hospital_no" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="否" />否 若“是”，请说明' +
  '                            <span id="hospital_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">8、投保人、被保险人是否有危险运动爱好或从事危险运动或其他特殊嗜好?</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="danger_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="是" />是' +
  '                            </span>' +
  '                            <input id="danger_no" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="否" />否 若“是”，请说明' +
  '                            <span id="danger_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">9、您估计投保人年收入：</td>' +
  '                        <td>' +
  '                            <span>今年</span>' +
  '                            <span id="policy_this_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元，去年</span>' +
  '                            <span id="policy_last_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元，前年</span>' +
  '                            <span id="policy_ex_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td rowspan="2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收入来源：</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="policy_income_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="工薪" />工薪' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="policy_income_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="个体" />个体' +
  '                            </span>' +
  '                            <input id="policy_income_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="私营" />私营' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="policy_income_type_3" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="房屋租赁" />房屋租赁' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="policy_income_type_4" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="投资" />投资' +
  '                            </span>' +
  '                            <input id="policy_income_type_5" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="其他" />其他，请说明' +
  '                            <span name="description" id="policy_income_type_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">10、您估计被保险人年收入：</td>' +
  '                        <td>' +
  '                            <span>今年</span>' +
  '                            <span id="insured_this_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元，去年</span>' +
  '                            <span id="insured_last_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元，前年</span>' +
  '                            <span id="insured_ex_income" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                            <span>万元</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td rowspan="2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;收入来源：</td>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="insured_income_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="工薪" />工薪' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="insured_income_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="个体" />个体' +
  '                            </span>' +
  '                            <input id="insured_income_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="私营" />私营' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="insured_income_type_3" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="房屋租赁" />房屋租赁' +
  '                            </span>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="insured_income_type_4" type="checkbox" name="" value="" style="margin-bottom: 3px;" data-remark="投资" />投资' +
  '                            </span>' +
  '                            <input id="insured_income_type_5" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="其他" />其他，请说明' +
  '                            <span name="description" id="insured_income_type_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">11、据您了解被保险人的职业实际情况和其投保单上所作的申报是否存在出入？</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="difference_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="是" />是' +
  '                            </span>' +
  '                            <input id="difference_no" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="否" />否 若“是”，请详述' +
  '                            <span id="difference_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                    <tr>' +
  '                        <td style="line-height: 30px;">12、客户是否在近1年内向本公司或其它保险公司投保？</td>' +
  '                        <td>' +
  '                            <span style="display: inline-block; width: 20%;">' +
  '                                <input id="insure_other_yes" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="是" />是' +
  '                            </span>' +
  '                            <input id="insure_other_no" type="checkbox" name="" value="" style="margin-bottom: 3px" data-remark="否" />否 若“是”，请详细告知' +
  '                            <span id="insure_other_text" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        </td>' +
  '                    </tr>' +
  '                </table>' +
  '                <div style="overflow: hidden; margin-top: 15px;">' +
  '                    <div style="float: left; width: 8%;">业务人员声明：</div>' +
  '                    <div style="float: right; width: 92%; max-height: 999999px;">' +
  '                        本人在此声明，本人已向投保人' +
  '                        <span id="proposer_name" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        提交了<span id="product_name" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                        保险产品的条款；本人已就投保单上的所有问题对投保人/被保险人进行当面询问，对于投保人的各' +
  '                        项告知（含口头告知）均已如实体现在投保单上，并由投保人/被保险人/未成年人的法定监护' +
  '                        人在投保单上签名证实；本人在此确认已见过被保险人，并证实其健康良好。如本人有不实见' +
  '                        证或报告，给公司造成经济或名誉损失，本人愿负法律责任。' +
  '                    </div>' +
  '                </div>' +
  '                <br />' +
  '                <br />' +
  '                <div style="width: 100%;">' +
  '                    <span >业务人员签名：</span><img id="imageApp1" style="width:15%;"/>' +
  '                    <span>日期：' +
  '                        <span id="signYear" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>年' +
  '                        <span id="signMonth" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>月' +
  '                        <span id="signDay" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>日' +
  '                    </span>' +
  '                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
  '                    <span>联系电话：' +
  '                        <span id="telephone" style="text-decoration: underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
  '                    </span>' +
  '                </div>' +
  '                <br />' +
  '                <br />' +
  '            </div>' +
  '        </div>' +
  '    </div>' +
  '</div>';
export default htmlStr;
