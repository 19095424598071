import { ReactDOM, $ } from '../../../../common/3rd';
import { Envs } from '../../../../common/common';
// import { format } from '../something-utils'
// import { PageLoading, Alert } from '../../../components'
import BaseSignatureOneToMoreView from './baseSignatureOneToMoreView';
import tpl from '../../signatureHTML/personalTaxPaperHtml';

const R = require('ramda');
// const mock = require('../../../../mock/mockData_dev.json')

/**
 *  个人税收居民身份声明文件 签名单证
 * add by susan.wu 2017 Nov.12
 *
 */
class PersonalTaxPaper extends BaseSignatureOneToMoreView {
  constructor(props, context) {
    super(props, context);
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.currentIndex = 0;
    this.state.myIndex = 20;
    this.documentListIndex = 23;
  }
  UNSAFE_componentWillMount() {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.policyHolderMsg = [
      self.policyHolderType,
      '-18.1',
      true,
      '0',
      '签名：',
      '3',
      '10',
      '',
      ['-18.5'],
    ];
    self.caContent = [
      {
        keyWord: '签名：',
        keyWordIndex: '1',
        offsetX: '30',
        offsetY: '0',
        key: '1',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.policyHolderMsg = [
      self.policyHolderType,
      '-18.1',
      true,
      '0',
      '签名：',
      '3',
      '10',
      '',
      ['-18.5'],
    ];
    self.caContent = [
      {
        keyWord: '签名：',
        keyWordIndex: '1',
        offsetX: '30',
        offsetY: '0',
        key: '1',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }
  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidUpdate() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidUpdate();
  }
  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidMount() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidMount();
  }
  //重置数据
  resetData() {
    let self = this;
    super.resetData(self.model);
  }
  //初始化页面数据
  resetUI() {
    let self = this;
    self.parent = $(ReactDOM.findDOMNode(self.refs['signatureHtml']));
    self.policyHolderImageApp = self.parent.find('#imageApp1');
    self.agentImageApp = self.parent.find('#imageApp2');
    self.parent
      .find('#logImage')
      .attr(
        'src',
        this.props.parent.props.parent.props.parent.state.policyLayout._logImage
          .bjlife
      );
    window
      .jQuery('#personalTaxPaper_policy_id')
      .barcode(self.proposalCode, 'code128', {
        barWidth: 1,
        barHeight: 50,
        showHRI: true,
      });
    //设置单证日期
    let timeSpan = self.parent.find('#signDate');
    let date = new Date();
    let month = parseInt(date.getMonth(), 10) + 1;
    let time = date.getFullYear() + '年' + month + '月' + date.getDate() + '日';
    timeSpan.html(time);
    //设置代理人代码
    let agentMobile = Envs.findAccount().mobile || '';
    self.parent.find('#agent_mobile').html(agentMobile);
    //默认本人签名
    self.parent.find('#self_sign').attr('checked', 'checked');
    self.resetBtn();
    self.dataEntry();
    super.resetUI();
  }

  /**
   * 数据录入
   *
   * @memberof PersonalTaxPaper
   */
  dataEntry() {
    const self = this;
    const dom = self.parent;
    const hintPage = dom.find('#hintPage');
    const policyHolder = self.model.policyHolder;
    // const policyHolder = mock.model.policyHolder

    // 禁用页面checkbox
    // $(hintPage).find('input[type=checkbox]').attr('disabled', 'true')
    // $(hintPage).find('input[name=agent]').removeAttr('checked')
    // $(hintPage).find('input[name=self]').removeAttr('checked')

    // 深度克隆
    const pure = (options) => {
      return R.clone(options);
    };

    const setName = (options) => {
      let { declarations, hintPage } = options;
      let _0001 = R.find(R.propEq('code', '_0001'), declarations);

      if (_0001) {
        $(hintPage).find('#name').html(_0001.remark);
      }

      return options;
    };

    const setDeclare = (options) => {
      let { declarations, hintPage } = options;
      let _0002 = R.find(R.propEq('code', '_0002'), declarations);
      let hasToWrite = false;

      if (_0002) {
        if (_0002.remark === '仅为中国税收居民') {
          $(hintPage).find('#tax_type_0').attr('checked', true);
        } else if (_0002.remark === '仅为非居民') {
          $(hintPage).find('#tax_type_1').attr('checked', true);
          hasToWrite = true;
        } else {
          $(hintPage).find('#tax_type_2').attr('checked', true);
          hasToWrite = true;
        }
      }

      return { ...options, hasToWrite };
    };

    const setIdentifierNumber = (options) => {
      // 如果没有居民国纳税人识别号，需要选择原因
      let { declarations, hintPage, hasToWrite } = options;
      let _0003 = R.find(R.propEq('code', '_0003'), declarations);

      if (_0003 && hasToWrite) {
        let contents = _0003.remark.split('/');
        if (contents) {
          $(hintPage).find('#name_eng_0').html(contents[0]);
          $(hintPage).find('#name_eng_1').html(contents[1]);
          $(hintPage).find('#birth_date').html(contents[2]);
          $(hintPage).find('#address_ch_country').html(contents[3]);
          $(hintPage).find('#address_ch_province').html(contents[4]);
          $(hintPage).find('#address_ch_city').html(contents[5]);
          $(hintPage).find('#address_eng_country').html(contents[6]);
          $(hintPage).find('#address_eng_province').html(contents[7]);
          $(hintPage).find('#address_eng_city').html(contents[8]);
          $(hintPage).find('#birthPlace_ch_country').html(contents[9]);
          $(hintPage).find('#birthPlace_ch_province').html(contents[10]);
          $(hintPage).find('#birthPlace_ch_city').html(contents[11]);
          $(hintPage).find('#birthPlace_eng_country').html(contents[12]);
          $(hintPage).find('#birthPlace_eng_province').html(contents[13]);
          $(hintPage).find('#birthPlace_eng_city').html(contents[14]);
          $(hintPage).find('#taxPayerCode0').html(contents[15]);
          $(hintPage).find('#taxPayerCode1').html(contents[16]);
          $(hintPage).find('#taxPayerCode2').html(contents[17]);
        }
      }

      return options;
    };

    const setIdentity = (options) => {
      let { declarations, hintPage, hasToWrite } = options;
      let _0004 = R.find(R.propEq('code', '_0004'), declarations);

      if (_0004 && hasToWrite) {
        if (_0004.remark.startsWith('账户持有人未能取得纳税人识别号')) {
          $(hintPage).find('#not_provide_reason_1').attr('checked', 'true');
          $(hintPage)
            .find('#not_provide_reason_detail')
            .html(_0004.remark.split(':')[1]);
        } else if (_0004.remark.startsWith('居民国(地区)不发放纳税人识别号')) {
          $(hintPage).find('#not_provide_reason_0').attr('checked', 'true');
        }
      }

      return options;
    };

    const getContent = (options) => {
      return options.hintPage;
    };

    const f1 = R.pipe(
      // pure,
      setName,
      setDeclare,
      setIdentifierNumber,
      setIdentity,
      getContent
    );

    $(hintPage).replaceWith(
      f1({
        declarations: policyHolder.extensionData.declarations,
        hintPage: $(hintPage),
      })
    );
  }

  resetBtn() {
    let self = this;
    self.parent.find('#sig-button').attr('class', 'onlyOneButton');
    self.policySigBtn = self.parent.find('.sig-button1');
  }
  addEvent() {
    let self = this;
    self.parent.find('.sig-button1').on('click', self.clickPolicySigBtnHandler);
  }
  //各单证根据各自签名完成情况自定义 docType
  getPdfType() {
    let self = this;
    let pdfType = null;
    if (self.currentSignaturePerson === self.policyHolderType) {
      pdfType = '-18.5';
    }
    return pdfType;
  }
  getDefaultClassName() {
    return '';
  }
}

export default PersonalTaxPaper;
