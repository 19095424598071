const TRUST_KEY_KEY = 'TrustKey';
const ACCOUNT_SIGN_KEY = 'Signin';
const ACCOUNT_KEY = 'Account';

const LAST_CRITERIA = 'LastCriteria';
const LAST_SNAPSHOT = 'LastSnapshot';
const LAST_DETAIL_ID = 'LastDetailId';
const LAST_SCROLL_POS = 'LastScrollPos';
const LAST_OPERATION_TYPE = 'LastOperationType';
const LAST_ACTION = 'LastAction';

const TENANT_IDS = {
  SYSTEM_OWNER: '1',
  DEMO_BROKER: '2',
  DEMO_AGENT: '3',
  DEMO_INSURANCE_COMPANY: '4',
  VIRTUAL: '5',
};
const TENANT_TYPE = {
  INSURANCE_COMPANY: '1',
  BROKER: '2',
  AGENT: '3',
  VIRTUAL: '4',
  SYSTEM_OWNER: '5',
  SIDELINE_INSURANCE_AGENT: '6',
};

const ACCOUNT_TYPE = {
  ADMIN: '1',
  USER: '2',
};
const PRODUCT_TAG_TYPE = {
  CATEGORY: '1',
  NORMAL: '2',
};

const CURRENCY_SIGNS = {
  CNY: '¥',
  USD: '$', // US Dollar
  EUR: '€', // Euro
  HKD: 'HK$',
  GBP: '£',
  JPY: '¥',
  TWD: 'NT$',
  RP: 'Rp', // Indonesia Rupiah
  IDR: 'Rp', // Indonesia Rupiah
};

const POLICY_STATUS = {
  ISSUED: '2',
  WAIT_FOR_PAYMENT: '3',
  ADVISE: '5',
  DELETED_ADVISE: '6',
  PAID: '7',
  ISSUING: '8',
  CANCELLED: '98',
  TERMINATED: '99',
  WAITING_FOR_UNDERWRITING: '31',
  ACCEPTED: '79',
  UNDERWRITING_FAILURE: '82',
  POLICY_TO_BE_PAID: '80',
  PAYMENT_FAILURE: '87',
  PAID_AND_INSURED: '85',
  POLICY_CANCELLED: '88',
  WAITING_FOR_PAYMENT_RESULT: '89',
  PAID_NOT_INSURED: '90',
  INFORCE_FAIL: '91',
  UNDERWRITING_IN_PROGRESS: '81',
  CORE_STATUS_INSURED: '0',
  CORE_STATUS_INFORCE: '1',
  CORE_STATUS_TERMINATE: '4',
  WAITING_FOR_UW_DOCUMENTS: '83',
};

const GENDER = {
  MALE: 'M',
  FEMALE: 'F',
};

const GENDER2 = {
  MALE: '0',
  FEMALE: '1',
};

const SHEBAO = {
  NULL: '0',
  COUNTRYSIDE: '1',
  CITY: '2',
  SERVANT: '3',
};

const COUNTRY = {
  ABW: '阿鲁巴',
  AFG: '阿富汗',
  AGO: '安哥拉',
  AIA: '安圭拉',
  ALB: '阿尔巴尼亚',
  AND: '安道尔',
  ANT: '荷属安的列斯',
  ARE: '阿联酋',
  ARG: '阿根廷',
  ARM: '亚美尼亚',
  ASM: '美属萨摩亚',
  ATA: '南极洲',
  ATF: '法属南部领土',
  ATG: '安提瓜和巴布达',
  AUS: '澳大利亚',
  AUT: '奥地利',
  AZE: '阿塞拜疆',
  BDI: '布隆迪',
  BEL: '比利时',
  BEN: '贝宁',
  BFA: '布基纳法索',
  BGD: '孟加拉国',
  BGR: '保加利亚',
  BHR: '巴林',
  BHS: '巴哈马',
  BIH: '波斯尼亚和黑塞哥维那',
  BLR: '白俄罗斯',
  BLZ: '伯利兹',
  BMU: '百慕大',
  BOL: '玻利维亚',
  BRA: '巴西',
  BRB: '巴巴多斯',
  BRN: '文莱',
  BTN: '不丹',
  BVT: '布维岛',
  BWA: '博茨瓦纳',
  CAF: '中非',
  CAN: '加拿大',
  CCK: '科科斯(基林)群岛',
  CHE: '瑞士',
  CHL: '智利',
  CHN: '中国',
  CIV: '科特迪瓦',
  CMR: '喀麦隆',
  COD: '刚果（金）',
  COG: '刚果（布）',
  COK: '库克群岛',
  COL: '哥伦比亚',
  COM: '科摩罗',
  CPV: '佛得角',
  CRI: '哥斯达黎加',
  CUB: '古巴',
  CXR: '圣诞岛',
  CYM: '开曼群岛',
  CYP: '塞浦路斯',
  CZE: '捷克',
  DEU: '德国',
  DJI: '吉布提',
  DMA: '多米尼克',
  DNK: '丹麦',
  DOM: '多米尼加共和国',
  DZA: '阿尔及利亚',
  ECU: '厄瓜多尔',
  EGY: '埃及',
  ERI: '厄立特里亚',
  ESH: '西撒哈拉',
  ESP: '西班牙',
  EST: '爱沙尼亚',
  ETH: '埃塞俄比亚',
  FIN: '芬兰',
  FJI: '斐济',
  FLK: '马尔维纳斯群岛(福克兰群岛)',
  FRA: '法国',
  FRO: '法罗群岛',
  FSM: '密克罗尼西亚',
  GAB: '加蓬',
  GBR: '英国',
  GEO: '格鲁吉亚',
  GHA: '加纳',
  GIB: '直布罗陀',
  GIN: '几内亚',
  GLP: '瓜德罗普',
  GMB: '冈比亚',
  GNB: '几内亚比绍',
  GNQ: '赤道几内亚',
  GRC: '希腊',
  GRD: '格林纳达',
  GRL: '格陵兰',
  GTM: '危地马拉',
  GUF: '法属圭亚那',
  GUM: '关岛',
  GUY: '圭亚那',
  HKG: '中国香港',
  HMD: '赫德岛和麦克唐纳岛',
  HND: '洪都拉斯',
  HRV: '克罗地亚',
  HTI: '海地',
  HUN: '匈牙利',
  IDN: '印度尼西亚',
  IND: '印度',
  IOT: '英属印度洋领土',
  IRL: '爱尔兰',
  IRN: '伊朗',
  IRQ: '伊拉克',
  ISL: '冰岛',
  ISR: '以色列',
  ITA: '意大利',
  JAM: '牙买加',
  JOR: '约旦',
  JPN: '日本',
  KAZ: '哈萨克斯坦',
  KEN: '肯尼亚',
  KGZ: '吉尔吉斯斯坦',
  KHM: '柬埔寨',
  KIR: '基里巴斯',
  KNA: '圣基茨和尼维斯',
  KOR: '韩国',
  KWT: '科威特',
  LAO: '老挝',
  LBN: '黎巴嫩',
  LBR: '利比里亚',
  LBY: '利比亚',
  LCA: '圣卢西亚',
  LIE: '列支敦士登',
  LKA: '斯里兰卡',
  LSO: '莱索托',
  LTU: '立陶宛',
  LUX: '卢森堡',
  LVA: '拉脱维亚',
  MAC: '澳门',
  MAR: '摩洛哥',
  MCO: '摩纳哥',
  MDA: '摩尔多瓦',
  MDG: '马达加斯加',
  MDV: '马尔代夫',
  MEX: '墨西哥',
  MHL: '马绍尔群岛',
  MKD: '马斯顿',
  MLI: '马里',
  MLT: '马耳他',
  MMR: '缅甸',
  MNG: '蒙古',
  MNP: '北马里亚纳',
  MOZ: '莫桑比克',
  MRT: '毛里塔尼亚',
  MSR: '蒙特塞拉特',
  MTQ: '马提尼克',
  MUS: '毛里求斯',
  MWI: '马拉维',
  MYS: '马来西亚',
  MYT: '马约特',
  NAM: '纳米比亚',
  NCL: '新喀里多尼亚',
  NER: '尼日尔',
  NFK: '诺福克岛',
  NGA: '尼日利亚',
  NIC: '尼加拉瓜',
  NIU: '纽埃',
  NLD: '荷兰',
  NOR: '挪威',
  NPL: '尼泊尔',
  NRU: '瑙鲁',
  NZL: '新西兰',
  OMN: '阿曼',
  PAK: '巴基斯坦',
  PAN: '巴拿马',
  PCN: '皮特凯恩群岛',
  PER: '秘鲁',
  PHL: '菲律宾',
  PLW: '帕劳',
  PNG: '巴布亚新几内亚',
  POL: '波兰',
  PRI: '波多黎各',
  PRK: '朝鲜',
  PRT: '葡萄牙',
  PRY: '巴拉圭',
  PSE: '巴勒斯坦',
  PYF: '法属波利尼西亚',
  QAT: '卡塔尔',
  REU: '留尼汪',
  ROM: '罗马尼亚',
  RUS: '俄罗斯',
  RWA: '卢旺达',
  SAU: '沙特阿拉伯',
  SCG: '塞尔维亚和黑山',
  SDN: '苏丹',
  SEN: '塞内加尔',
  SGP: '新加坡',
  SGS: '南乔治亚岛和南桑德韦奇岛',
  SHN: '圣赫勒拿',
  SJM: '斯瓦尔巴群岛和扬马群岛',
  SLB: '所罗门群岛',
  SLE: '塞拉利昂',
  SLV: '萨尔瓦多',
  SMR: '圣马力诺',
  SOM: '索马里',
  SPM: '圣皮埃尔和密克隆',
  STP: '圣多美和普林西比',
  SUR: '苏里南',
  SVK: '斯洛伐克',
  SVN: '斯洛文尼亚',
  SWE: '瑞典',
  SWZ: '斯威士兰',
  SYC: '塞舌尔',
  SYR: '叙利亚',
  TCA: '特克斯和凯科斯群岛',
  TCD: '乍得',
  TGO: '多哥',
  THA: '泰国',
  TJK: '塔吉克斯坦',
  TKL: '托克劳',
  TKM: '土库曼斯坦',
  TMP: '东帝汶',
  TON: '汤加',
  TTO: '特立尼达和多巴哥',
  TUN: '突尼斯',
  TUR: '土耳其',
  TUV: '图瓦卢',
  TWN: '中国台湾',
  TZA: '坦桑尼亚',
  UGA: '乌干达',
  UKR: '乌克兰',
  UMI: '美属本土外小岛屿',
  URY: '乌拉圭',
  USA: '美国',
  UZB: '乌兹别克斯坦',
  VAT: '梵蒂冈',
  VCT: '圣文森特和格林纳丁斯',
  VEN: '委内瑞拉',
  VGB: '英属维尔京群岛',
  VIR: '美属维尔京群岛',
  VNM: '越南',
  VUT: '瓦努阿图',
  WLF: '瓦利斯和富图纳群岛',
  WSM: '西萨摩亚',
  YEM: '也门',
  ZAF: '南非',
  ZAR: '扎伊尔',
  ZMB: '赞比亚',
  ZWE: '津巴布韦',
};

const HUMAN_TITLE = {
  MALE: 'M',
  FEMALE: 'F',
};

const IDTYPE = {
  IDCARD: '0',
  PASSPORT: '1',
  MILITARY_ID: '2',
  DRIVING_LICENSE: '3',
  HOUSEHOLD_REGISTER: '4',
  STUDENT_ID_CARD: '5',
  EMPLOYEESS_CARD: '6',
  BIRTH_CERTIFICATE: '7',
  OTHERS: '8',
  UNDOCUMENTED: '9',
  SOLDIER_CARD: 'A',
  RETURNING_HOME_CERTIFICATE: 'B',
  INTERIM_IDENTITY_CARD: 'C',
  POLICE_CERTIFICATE: 'D',
  MTP: 'E',
};
const GROUPIDTYPE = {
  ORGANIZATION_CODE_CERTIFICATE: '1',
  CREDIT_CODE_CERTIFICATE: '2',
};
const BANK = {
  bank1: '工商银行',
  bank2: '建设银行',
  bank3: '农业银行',
  bank4: '招商银行',
};

const EDUCATION = {
  BOSHI: '0',
  SHUOSHI: '1',
  DABEN: '2',
  DAZHUAN: '3',
  ZHONGZHUAN: '4',
  GAOZHONG: '5',
  CHUZHONG: '6',
  QITA: '7',
};

const EDUCATIONDN = {
  BOSHI: '0',
  SHUOSHI: '1',
  BENKE: '2',
  DAZHUAN: '3',
  ZHONGZHUAN: '4',
  GAOZHONG: '5',
  CHUZHONG: '6',
  OTHERS: '7',
};

//专员
const ATTACHE = {};
//机构
const AGENTGROUP = {};

const INCOMESRC = {
  WAGES: '0',
  PRIVATE: '1',
  FARMING: '2',
  OTHER: '3',
};

const INSURED_TYPE = {
  VEHICLE: '1',
  MARINE: '2',
  ADDRESS: '3',
  CONTRACT: '4',
  PERSON: '5',
};

const COMMISSION_TYPE = {
  FROMVENDOR: '1',
  TODIRECTAGENT: '2',
  TOINDIRECTAGENT: '3',
};

const CUSTOMER_TYPE = {
  POLICY_HOLDER: '1',
  INSURED: '2',
  BENEFICIARY: '3',
};

const PRODUCT_EXPLORE_TEMPLATE_TYPE = {
  STRIP: '1',
  ROTATION: '2',
};

const POLICY_LOG_STATUS = {
  READY: '0',
  PROCESSING: '1',
  SUCCESS: '2',
  FAIL: '3',
  TIMEOUT: '4',
};

const DEVICE_LIST = {
  IP5: 'ip5',
  IP6: 'ip6',
  IP6P: 'ip6p',
};

const JSON_TYPE = {
  UIJSON: 'ui',
  DATAJSON: 'data',
};

const MARRIAGE = {
  1: '0',
  2: '1',
  3: '2',
  4: '3',
};
const MARRIAGE2 = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
};
const MARRIAGEBoolean = {
  1: '0',
  2: '1',
};

const JOB_TYPE = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
};

const JOB_DN = {
  one: '01',
  two: '02',
  three: '03',
  four: '04',
  five: '05',
  six: '06',
  seven: '07',
};

const BANKLIST = {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  six: '6',
  seven: '7',
};

const BANKLIST_DN = {
  one: '105',
  two: '102',
  three: '103',
  four: '301',
  five: '308',
  six: '303',
  seven: '403',
};

const SERVICE_TYPE = {
  COUNTER: '1',
  AGENT_AGENCY: '2',
  OTHERS_AGENCY: '3',
  LETTER_APPLY: '4',
  PHONE_APPLY: '5',
  INTERNAL_TRANSFER: '6',
  WEB_APPLY: '7',
  WECHAT_APPLY: '8',
  BANK_COUNTER_APPLY: '9',
};

const REQUEST_TYPE = {
  BASEINFO_CHANGE: 'BB',
  ADDRESS_CHANGE: 'AM',
  PAYMENT_ACCOUNT_CHANGE: 'PC',
  FREELOOK_WITHDRAWAL: 'WT',
  SURRENDER: 'CT',
  REAL_NAME_AUTH: 'EI',
};

const CHANGE_STATUS = {
  CONFIRM_EFFECTIVE: '0',
  INPUT_COMPLETED: '1',
  PENDING_ACCEPT: '10',
  APPLY_CONFIRM: '2',
  PENDING_INPUT: '3',
  PENDING_REVIEW: '30',
  OVERDUE_TERMINATION: '4',
  PENDING_UNDERWRITING: '40',
  REVIEW_MODIFY: '5',
  PENDING_AUDIT: '50',
  AUDIT_TERMINATION: '51',
  CONFIRM_INEFFECTIVE: '6',
  PENDING_SEND: '60',
  WITHDRAWAL: '7',
  UNDERWRITING_END: '8',
  REVIEW_END: '9',
  APPROVED: 'a',
  BACK: 'b',
  END: 'c',
  FORCED_TERMINATION: 'd',
};

const CHARGE_PERIOD = {
  CHARGE_ONE_TIME: 'Y1',
  CHARGE_TWO_YEARS: 'Y2',
  CHARGE_THREE_YEARS: 'Y3',
  CHARGE_FOUR_YEARS: 'Y4',
  CHARGE_FIVE_YEARS: 'Y5',
  CHARGE_SIX_YEARS: 'Y6',
  CHARGE_SEVEN_YEARS: 'Y7',
  CHARGE_EIGHT_YEARS: 'Y8',
  CHARGE_NINE_YEARS: 'Y9',
  CHARGE_TEN_YEARS: 'Y10',
  CHARGE_ELEVEN_YEARS: 'Y11',
  CHARGE_TWELVE_YEARS: 'Y12',
  CHARGE_THIRTEEN_YEARS: 'Y13',
  CHARGE_FOURTEEN_YEARS: 'Y14',
  CHARGE_FIFTEEN_YEARS: 'Y15',
  CHARGE_SIXTEEN_YEARS: 'Y16',
  CHARGE_SEVENTEEN_YEARS: 'Y17',
  CHARGE_EIGHTEEN_YEARS: 'Y18',
  CHARGE_NINETEEN_YEARS: 'Y19',
  CHARGE_TWENTY_YEARS: 'Y20',
  CHARGE_TWENTY_ONE_YEARS: 'Y21',
  CHARGE_TWENTY_TWO_YEARS: 'Y22',
  CHARGE_TWENTY_THREE_YEARS: 'Y23',
  CHARGE_TWENTY_FOUR_YEARS: 'Y24',
  CHARGE_TWENTY_FIVE_YEARS: 'Y25',
  CHARGE_TWENTY_SIX_YEARS: 'Y26',
  CHARGE_TWENTY_SEVEN_YEARS: 'Y27',
  CHARGE_TWENTY_EIGHT_YEARS: 'Y28',
  CHARGE_TWENTY_NINE_YEARS: 'Y29',
  CHARGE_THIRTY_YEARS: 'Y30',
  CHARGE_THIRTY_FIVE_YEARS: 'Y35',
  CHARGE_FORTY_YEARS: 'Y40',
  CHARGE_TO_EIGHTEEN: 'A18',
  CHARGE_TO_TWENTY: 'A20',
  CHARGE_TO_THIRTY: 'A30',
  CHARGE_TO_FORTY: 'A40',
  CHARGE_TO_FORTY_FIVE: 'A45',
  CHARGE_TO_FIFTY: 'A50',
  CHARGE_TO_FIFTY_FIVE: 'A55',
  CHARGE_TO_SIXTY: 'A60',
  CHARGE_TO_SIXTY_FIVE: 'A65',
  CHARGE_TO_SEVENTY: 'A70',
};

const COVERAGE_PERIOD = {
  COVERAGE_ONE_MONTH: 'M1',
  COVERAGE_THREE_MONTH: 'M3',
  COVERAGE_SIX_MONTH: 'M6',
  COVERAGE_ONE_YAER: 'Y1',
  COVERAGE_TWO_YEARS: 'Y2',
  COVERAGE_THREE_YEARS: 'Y3',
  COVERAGE_FIVE_YEARS: 'Y5',
  COVERAGE_TEN_YEARS: 'Y10',
  COVERAGE_FIFTEEN_YEARS: 'Y15',
  COVERAGE_TWENTY_YEARS: 'Y20',
  COVERAGE_TWENTY_FIVE_YEARS: 'Y25',
  COVERAGE_THIRTY_YEARS: 'Y30',
  COVERAGE_THIRTY_FIVE_YEARS: 'Y35',
  COVERAGE_FORTY_YEARS: 'Y40',
  COVERAGE_TO_EIGHTEEN: 'A18',
  COVERAGE_TO_TWENTY: 'A20',
  COVERAGE_TO_RWENTY_EIGHT: 'A28',
  COVERAGE_TO_THIRTY: 'A30',
  COVERAGE_TO_FORTY: 'A40',
  COVERAGE_TO_FORTY_FIVE: 'A45',
  COVERAGE_TO_FIFTY: 'A50',
  COVERAGE_TO_FIFTY_FIVE: 'A55',
  COVERAGE_TO_SIXTY: 'A60',
  COVERAGE_TO_SIXTY_FIVE: 'A65',
  COVERAGE_TO_SEVENTY: 'A70',
  COVERAGE_TO_SEVENTY_FIVE: 'A75',
  COVERAGE_TO_EIGHTY: 'A80',
  COVERAGE_TO_EIGHTY_FIVE: 'A85',
  COVERAGE_ALL_TIME: 'WL',
};
const CLAIM_SERVICE_TYPE = {
  ONLINE: '1',
  COUNTER: '2',
};
const CLAIM_STATUS = {
  REPORT: '1',
  IN_CASE: '2',
  CALCULATE: '3',
  CLOSED: '4',
};

const CHANNEL = {
  CH1: 'B2A',
  CH2: 'B2C',
  CH3: 'B2E',
};

const PAYMENT_STATUS = {
  PRE: '3',
  FAIL: '87',
  WAIT: '89',
  PAID: '90',
};

const COURSE_TYPE = {
  COMPULSORY: '1',
  OPTIONAL: '2',
  OFFLINE: '3',
  VIDEO: '4',
};

const VIDEO_TYPE = {
  LIVE: '1',
  VIDEO: '2',
};

const COURSE_STATUS = {
  ENABLED: '1',
  DISABLED: '2',
  EXPIRED: '3',
  ENROLLED: '4',
  UNENROLED: '5',
  DELETED: '99',
};

const GOODS_STATUS = {
  DRAFT: '0',
  VALID: '1',
};

const GOODS_ORDER_STATUS = {
  VALID: '0',
  UNVALID: '1',
};
const MY_COURSE_STATUS = {
  NOTSTART: '1',
  ONGOING: '2',
  COMPLETED: '3',
  REGISTER: '4',
  NOTREGISTER: '5',
};

const RELATION = {
  MYSELF: '00',
  FATHERANDSON: '01',
  FATHERANDDAUGHTER: '02',
  MOTHERANDDSON: '03',
  MOTHERANDDAUGHTER: '04',
  GRANDCHILDREN: '05',
  SPOUSE: '06',
  BROTHERS: '07',
  BROTHERANDSISTER: '08',
  SISTERANDBROTHER: '09',
  SISTERS: '10',
  UNCLENEPHEW: '11',
  AUNTIENEPHEW: '12',
  NEPHEW: '13',
  DAUGHTERINLOW: '14',
  SONINLOW: '15',
  BROTHERINLOW: '16',
  FRIEND: '17',
  COLLEAGUE: '18',
  TEACHERSANDSTUDENTS: '19',
  HIRE: '20',
  OTHER: '21',
  PARENTS: '22',
  CHILD: '23',
};

const ACCIDENT_CAUSE = {
  ACCIDENT: '1',
  DISEASE: '2',
};

const ACCIDENT_CAUSE_CLAIMS = {
  ACCIDENT: '1',
};

const CASE_TYPE = {
  AccidentalMedicalTreatment: '100',
  AccidentalDisability: '101',
  AccidentalDeath: '102',
  AccidentalHighDisabled: '103',
  AccidentalIllness: '104',
  AccidentalSpecialDisease: '105',
  AccidentalException: '109',
  DiseaseMedicalTreatment: '200',
  Disability: '201',
  DiseaseDied: '202',
  HighDisabilityDisease: '203',
  SevereIllness: '204',
  SpecialDiseaseOfDisease: '205',
  DiseaseExemption: '209',
};

const GROUP_CASE_TYPE = {
  AccidentalMedicalTreatment: '100',
  AccidentalDisability: '101',
  AccidentalDeath: '102',
  AccidentalIllness: '104',
  AccidentalDisabled: '105',
  AccidentalScald: '106',
  AccidentalSpecialDisease: '107',
  AccidentalAllowance: '10A',
  DiseaseMedicalTreatment: '200',
  Disability: '201',
  DiseaseDied: '202',
  SevereIllness: '204',
  Disabled: '205',
  Scald: '206',
  SpecialDiseaseOfDisease: '207',
  Allowance: '20A',
};

const TREATMENT_TYPE = {
  OUTPATIENT_CLINIC: '01',
  HOSPITALIZED: '02',
  NONE: '03',
};
const DELIVERY_WAY = {
  ONESELF_DELIVERY: '01',
  DELIVERY_TO_COMPANY: '02',
  COME_HOME: '03',
};

const CLAIM_BANK = {
  bank1: '中国银行',
  bank2: '建设银行',
  bank3: '工商银行',
  bank4: '农业银行',
  bank5: '招商银行',
  bank6: '邮储银行',
  bank7: '光大银行',
  bank8: '交通银行',
  bank9: '北京银行',
  bank10: '中信银行',
  bank11: '北京农商行',
  bank12: '渤海银行',
  bank13: '上海浦发银行',
  bank14: '广东发展银行',
  bank15: '宁波银行',
  // TODO: B2A不包含以下
  bank16: '民生银行',
  bank17: '兴业银行',
  bank18: '平安银行',
};

const SMALL_CLAIM_BANK = {
  bank1: '工商银行',
  bank2: '农业银行',
  bank3: '中国银行',
  bank4: '建设银行',
  bank5: '交通银行',
  bank6: '邮储银行',
  bank7: '兴业银行',
  bank8: '中信银行',
  bank9: '光大银行',
  bank10: '广东发展银行',
  bank11: '上海浦发银行',
  bank12: '宁波银行',
  bank13: '北京银行',
  bank14: '渤海银行',
  bank15: '北京农商行',
  bank16: '平安银行',
  bank17: '招商银行',
  bank18: '上海银行',
  // TODO: B2A不包含以下
  bank19: '华夏银行',
};

const PATH = {
  /** 京保通 */
  JBT: '/jbt',
  /** 双录 */
  SITUVISION: '/situvision',
  SIGN: '/sign',
  SIGN_IN: '/sign/signin',
  SIGN_UP: '/sign/signup',
  WECHAT: '/wechat',
  FEEDBACK: '/feedback',

  WECHAT_SAHRE_RECEIPT: '/wechat/share-receipt',

  WECHAT_SIGN: '/wechat/sign',
  WECHAT_SIGN_IN: '/wechat/sign/signin',
  WECHAT_SIGN_UP: '/wechat/sign/signup',
  WECHAT_SIGN_UP_CODE: '/wechat/sign/signup/inputCode',
  WECHAT_SIGN_UP_PASS: '/wechat/sign/signup/inputPass',
  WECHAT_SIGN_UP_SURE: '/wechat/sign/signup/sure',
  WECHAT_SIGN_UP_TEXT: '/wechat/sign/signup/text',
  WECHAT_FORGET_PASSWORD: '/wechat/sign/forget-pwd',
  WECHAT_FORGET_PASSWORD_CODE: '/wechat/sign/forget-pwd/inputCode', //忘记密码验证码填写
  WECHAT_FORGET_PASSWORD_PASS: '/wechat/sign/forget-pwd/inputPass', //忘记密码验证码填写
  WECHAT_FORGET_PASSWORD_SURE: '/wechat/sign/forget-pwd/sure', //忘记密码验证码确认
  WECHAT_PRODUCTSTORE: '/wechat/product-store',

  WECHAT_POLICY_INTRODUCTION: '/wechat/product-store/quote/introduction',
  // WECHAT_POLICY_INTRODUCTION: '/wechat/product-store/quote/introduction/:vendorCode/:productCode/:productVersion/:productId',
  WECHAT_POLICY_ANNOUCEMENT: '/wechat/product-store/quote/annoucenment',
  // WECHAT_POLICY_ANNOUCEMENT: '/wechat/product-store/quote/annoucenment/:vendorCode/:productCode/:productVersion',
  WECHAT_POLICY_QUOTE: '/wechat/product-store/quote/cover',
  // WECHAT_POLICY_QUOTE: '/wechat/product-store/quote/cover/:vendorCode/:productCode/:productVersion',
  // WECHAT_POLICY_QUOTE_EDIT_STEP: '/wechat/product-store/:vendorCode/:productCode/:productVersion/quote/edit/:policyId/:toStep',
  WECHAT_POLICY_QUOTE_EDIT_STEP: '/wechat/product-store/quote/edit',

  WECHAT_MY: '/wechat/my',
  WECHAT_OAUTH: '/wechat/oauth',
  WECHAT_MINI_OAUTH: '/wechat/mini-oauth',
  WECHAT_MY_HEALTH_ENQUIRY: '/wechat/my/hospital-enquiry',
  WECHAT_MY_HEALTH_MANAGEMENT: '/wechat/my/health-management',
  WECHAT_MY_HEALTH_MANAGEMENT_SERVICE_ORDER:
    '/wechat/my/health-management/service-order',
  WECHAT_MY_HEALTH_MANAGEMENT_APPLICATION_SERVICE:
    '/wechat/my/health-management/application-service',
  WECHAT_MY_HEALTH_MANAGEMENT_SERVICE_INTERESTS:
    '/wechat/my/health-management/application-service/interests',
  WECHAT_MY_HEALTH_MANAGEMENT_SERVICE_APPLY:
    '/wechat/my/health-management/application-service/apply',
  WECHAT_MY_HEALTH_MANAGEMENT_PROGRESS_QUERY:
    '/wechat/my/health-management/application-service/progress-query',
  WECHAT_MY_HEALTH_MANAGEMENT_HISTORY_RESERVATION:
    '/wechat/my/health-management/application-service/history',
  WECHAT_MY_HEALTH_MANAGEMENT_SERVICE_APPLY_END:
    '/wechat/my/health-management/application-service/end',
  WECHAT_MY_HEALTH_MANAGEMENT_BALL_CHECK:
    '/wechat/my/health-management/service-ballcheck',
  WECHAT_MY_HEALTH_MANAGEMENT_BALL_NOTICE:
    '/wechat/my/health-management/service-ballnotice',
  WECHAT_MY_HEALTH_MANAGEMENT_BALL_NOTICE_DETAIL:
    '/wechat/my/health-management/service-notice/detail/:exploreId',
  WECHAT_MY_HEALTH_MANAGEMENT_CLAIM: '/wechat/my/health-management/claim',
  WECHAT_MY_HEALTH_MANAGEMENT_ANNOUCE: '/wechat/my/health-management/annouce',
  WECHAT_MY_HEALTH_MANAGEMENT_APPOINTMENT:
    '/wechat/my/health-management/appointment',
  WECHAT_MY_HEALTH_MANAGEMENT_OVERSEA: '/wechat/my/health-management/oversea',
  WECHAT_MY_HEALTH_MANAGEMENT_SICKNESS: '/wechat/my/health-management/sickness',
  WECHAT_MY_HEALTH_MANAGEMENT_RESULT: '/wechat/my/health-management/result',
  WECHAT_MY_HEALTH_MANAGEMENT_RESULTFAIL:
    '/wechat/my/health-management/resultfail',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_INFO:
    '/wechat/my/health-management/health-info',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_INFO_DETAIL:
    '/wechat/my/health-management/health-info/detail/:exploreId',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_PHYSICAL_EXAMINATION:
    '/wechat/my/health-management/health-physical-examination',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_APPOINTMENT:
    '/wechat/my/health-management/health-physical-examination/appointment',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_APPOINTMENT_COMMIT:
    '/wechat/my/health-management/health-physical-examination/appointment-commit',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_APPOINTMENT_COMMIT_RESULT:
    '/wechat/my/health-management/health-physical-examination/appointment-commit-result',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_SERVICE_EVALUATION:
    '/wechat/my/health-management/health-physical-examination/service-evaluation',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_MY_APPOINTMENT:
    '/wechat/my/health-management/health-physical-examination/my-appointment',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_REPOST_LIST:
    '/wechat/my/health-management/health-physical-examination/report-list',
  WECHAT_MY_HEALTH_MANAGEMENT_HEALTH_NOTICE:
    '/wechat/my/health-management/health-physical-examination/notice',
  WECHAT_MY_HEALTH_MANAGEMENT_BRITHDAY_GIFT:
    '/wechat/my/health-management/birthday-gift',
  WECHAT_MY_HEALTH_MANAGEMENT_VIEW_DETAILS:
    '/wechat/my/health-management/viewDetails',
  WECHAT_MY_HEALTH_MANAGEMENT_INQUIRY: '/wechat/my/health-management/inquiry',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION:
    '/wechat/my/health-management/presentation',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION_GUIDE:
    '/wechat/my/health-management/presentation/guide',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION_APPLY:
    '/wechat/my/health-management/presentation/apply',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION_RECOMMEND:
    '/wechat/my/health-management/presentation/recommend',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION_FLOW:
    '/wechat/my/health-management/presentation/flow',
  WECHAT_MY_HEALTH_MANAGEMENT_PRESENTATION_GUARANTEE:
    '/wechat/my/health-management/presentation/guarantee',
  /** 全部保单 */
  WECHAT_MY_GUARANTEE: '/wechat/my/my-guarantee',
  WECHAT_MY_E_INVOICE: '/wechat/my/e-invoice',
  WECHAT_MY_E_INVOICE_EMAIL: '/wechat/my/e-invoice/email',
  WECHAT_MY_E_INVOICE_RESULT: '/wechat/my/e-invoice/result',
  WECHAT_MY_ORDER: '/wechat/my/my-order',
  WECHAT_MY_ORDER_DETAIL: '/wechat/my/my-order-detail',
  WECHAT_MY_POLICY_DETAIL: '/wechat/my/my-guarantee/my-policy-detail',
  WECHAT_MY_PERSONAL: '/wechat/my/personal',
  WECHAT_MY_MODIFY_PWD: '/wechat/my/modify-password',
  WECHAT_MY_INFO: '/wechat/my/info',
  WECHAT_MY_INFO_FACE_RESULT: '/wechat/my/info/result',
  /** 个人纳税身份声明 */
  WECHAT_MY_TAXPAYER_STATUS: '/wechat/my/taxpayer-status',
  WECHAT_MY_BANK_CARD_INFO: '/wechat/my/bank-card-info',
  WECHAT_MY_BANK: '/wechat/my/my-bank',
  /** 基本资料变更 */
  WECHAT_MY_INFO_CHANGE: '/wechat/my/info-change',
  WECHAT_MY_INFO_CHANGE_RESULT: '/wechat/my/info-change/result',
  WECHAT_MY_INFO_CHANGE_SECURITY: '/wechat/my/info-change/security',
  WECHAT_MY_CONTACT_CHANGE: '/wechat/my/contact-change',
  WECHAT_MY_CONTACT_CHANGE_RESULT: '/wechat/my/contact-change/result',
  WECHAT_MY_CONTACT_CHANGE_END: '/wechat/my/contact-change/end',
  WECHAT_MY_PAY_CHANGE: '/wechat/my/pay-change',
  WECHAT_MY_PAY_CHANGE_RESULT: '/wechat/my/pay-change/result',
  WECHAT_MY_PAY_CHANGE_END: '/wechat/my/pay-change/end',
  //保单借款身份证上传
  WECHAT_MY_BORROWING_AUTHENTICATION: '/wechat/my/borrowing/authentication',
  //保单借款人脸识别结果页
  WECHAT_MY_BORROWING_AUTHENTICATION_RESULT:
    '/wechat/my/borrowing/authentication/result',
  //保单借款分享
  WECHAT_MY_BORROWING_SHARE: '/wechat/my/borrowing/share',
  //保单借款分享确认页面
  WECHAT_MY_BORROWING_SHARE_CONFIRM: '/wechat/my/borrowing/share-confirm',
  WECHAT_MY_BORROWING_END: '/wechat/my/borrowing/borrowing-end',
  WECHAT_MY_BORROWING_INFO: '/wechat/my/borrowing/borrowing-info',
  WECHAT_MY_BORROWING_CONFIRM: '/wechat/my/borrowing/borrow-confirm',
  WECHAT_MY_BORROWING_SIG: '/wechat/my/borrowing/borrow-sig',
  WECHAT_MY_BORROWING: '/wechat/my/borrowing',
  WECHAT_MY_REPAYMENT: '/wechat/my/repayment',
  WECHAT_MY_REPAYMENT_INFO: '/wechat/my/repayment/repayment-info',
  WECHAT_MY_REPAYMENT_CONFIRM: '/wechat/my/repayment/repayment-confirm',
  WECHAT_MY_REPAYMENT_END: '/wechat/my/repayment/repayment-end',
  WECHAT_MY_WITHDRAWAL: '/wechat/my/withdrawal',
  WECHAT_MY_WITHDRAWAL_SURE: '/wechat/my/withdrawal/sure',
  WECHAT_MY_WITHDRAWAL_SIG: '/wechat/my/withdrawal/sig',
  WECHAT_MY_WITHDRAWAL_STATEMENT_LOST_SIG:
    '/wechat/my/withdrawal/statement-lost-sig',
  WECHAT_MY_WITHDRAWAL_END: '/wechat/my/withdrawal/end',
  WECHAT_MY_SURRENDER: '/wechat/my/surrender',
  WECHAT_MY_SURRENDER_SURE: '/wechat/my/surrender/sure',
  WECHAT_MY_SURRENDER_SIG: '/wechat/my/surrender/sig',
  WECHAT_MY_SURRENDER_STATEMENT_LOST_SIG:
    '/wechat/my/surrender/statement-lost-sig',
  WECHAT_MY_SURRENDER_END: '/wechat/my/surrender/end',
  WECHAT_MY_SURRENDER_COMPUTATION_RULE: '/wechat/my/surrender/computation-rule',
  WECHAT_MY_AUTHENTICATION: '/wechat/my/authentication',
  WECHAT_MY_AUTHENTICATION_RESULT: '/wechat/my/authentication/result',
  WECHAT_MY_AUTHENTICATION_B2C: '/wechat/my/authenticationB2c',
  WECHAT_MY_AUTHENTICATION_B2C_RESULT: '/wechat/my/authenticationB2c/result',
  WECHAT_MY_INFO_CHANGE_END: '/wechat/my/info-change/end',
  WECHAT_MY_CERTIFICATION: '/wechat/my/certification',
  WECHAT_MY_RENEWAL_MODE_CHANGE: '/wechat/my/renewal-change',
  WECHAT_MY_RENEWAL_MODE_CHANGE_CONFIRM: '/wechat/my/renewal-change/confirm',
  WECHAT_MY_RENEWAL_MODE_CHANGE_END: '/wechat/my/renewal-change/end',
  WECHAT_MY_POLICY_REPLACEMENT: '/wechat/my/policy-replacement',
  WECHAT_MY_POLICY_REPLACEMENT_END: '/wechat/my/policy-replacement/end',
  //保单复效
  WECHAT_MY_POLICY_REINSTATEMENT: '/wechat/my/policy-reinstatement',
  //保单复效投被保人身份验证
  WECHAT_MY_POLICY_REINSTATEMENT_INSURED_AUTHENTICATION:
    '/wechat/my/policy-reinstatement/insured-authentication',
  //保单复效身份验证结果页
  WECHAT_MY_POLICY_REINSTATEMENT_INSURED_AUTHENTICATION_RESULT:
    '/wechat/my/policy-reinstatement/insured-authentication/result',
  //保单复效确认页面
  WECHAT_MY_POLICY_REINSTATEMENT_CONFIRM:
    '/wechat/my/policy-reinstatement/confirm',
  //复效申请书签字
  WECHAT_MY_POLICY_REINSTATEMENT_APPLY_SIG:
    '/wechat/my/policy-reinstatement/sig',
  //保单复效问卷
  WECHAT_MY_POLICY_REINSTATEMENT_VISIT: '/wechat/my/policy-reinstatement/visit',
  //保单复效问卷签名
  WECHAT_MY_POLICY_REINSTATEMENT_VISIT_SIG:
    '/wechat/my/policy-reinstatement/visit/sig',
  //保单复效分享页
  WECHAT_MY_POLICY_REINSTATEMENT_SHARE: '/wechat/my/policy-reinstatement/share',
  //保单复效结果页面
  WECHAT_MY_POLICY_REINSTATEMENT_END: '/wechat/my/policy-reinstatement/end',
  //保单复效分享信息确认页
  WECHAT_MY_POLICY_REINSTATEMENT_CONFIRM_SHARE:
    '/wechat/my/policy-reinstatement/share-confirm',

  /** 智能保全首页 */
  WECHAT_MY_INTELLIGENT_SECURITY: '/wechat/my/intelligent-security',
  /** 智能保全列表 */
  WECHAT_MY_INTELLIGENT_SECURITY_LIST:
    '/wechat/my/intelligent-security/list/:securityType',
  //智能保全业务须知
  WECHAT_MY_INTELLIGENT_SECURITY_NOTICE:
    '/wechat/my/intelligent-security/notice/:requestData',
  //智能保全下载
  WECHAT_MY_INTELLIGENT_SECURITY_DOWN:
    '/wechat/my/intelligent-security/down/:fileNameData',

  //续期缴费
  WECHAT_MY_RENEWAL_MODE_PAYMENT: '/wechat/my/renewal-payment', //续期缴费列表页
  WECHAT_MY_RENEWAL_MODE_PAYMENT_INFO: '/wechat/my/renewal-payment/info', //续期缴费详情页
  WECHAT_MY_RENEWAL_MODE_PAYMENT_AUTHENTICATION:
    '/wechat/my/renewal-payment/authentication', //续期缴费认证页面
  WECHAT_MY_RENEWAL_MODE_PAYMENT_END: '/wechat/my/renewal-payment/end', //续期缴费完成页面
  WECHAT_MY_RENEWAL_MODE_PAYMENT_RESULT: '/wechat/my/renewal-payment/result', //续期缴费缴费结果页面
  WECHAT_MY_RENEWAL_MODE_PAYMENT_RESULT_WCHAT:
    '/wechat/my/renewal-payment/result-wchat', //续期缴费微信支付结果页面

  WECHAT_MY_SERVICE: '/wechat/my/service',
  WECHAT_MY_VISIT: '/wechat/my/service/visit',
  WECHAT_MY_APPOINTMENT_VISIT: '/wechat/my/service/visit/appointment-visit',
  WECHAT_MY_APPOINTMENT_VISIT_END:
    '/wechat/my/service/visit/appointment-visit/end',
  WECHAT_MY_SCHEDULE_QUERY: '/wechat/my/schedule-query',
  WECHAT_MY_APPLICATION: '/wechat/my/application',
  WECHAT_MY_PRESERVATION_TRACK: '/wechat/my/preservation-track',
  WECHAT_MY_PRESERVATION_TRACK_B2C: '/wechat/my/preservation-track-b2c',
  WECHAT_MY_PRESERVATION_TRACK_EP:
    '/wechat/my/preservation-track-b2c/endorsement',
  WECHAT_MY_ONLINE_VISIT: '/wechat/my/online-visit',
  WECHAT_MY_ONLINE_VISIT_QUESTION: '/wechat/my/online-visit/question',
  WECHAT_MY_ONLINE_VISIT_PAPER: '/wechat/my/online-visit/paper',
  WECHAT_MY_ONLINE_VISIT_END: '/wechat/my/online-visit/end',
  WECHAT_MY_ONLINE_VISIT_SMS: '/wechat/my/online-visit-sms',
  WECHAT_MY_ONLINE_VISIT_SMS_QUESTION: '/wechat/my/online-visit-sms/question',
  WECHAT_MY_ONLINE_VISIT_SMS_PAPER: '/wechat/my/online-visit-sms/paper',
  WECHAT_MY_ONLINE_VISIT_SMS_END: '/wechat/my/online-visit-sms/end',
  WECHAT_MY_SERVICE_RECEIPT: '/wechat/my/service/receipt',
  WECHAT_MY_SERVICE_DEGITAL_RECEIPT:
    '/wechat/my/service/receipt/degital-receipt',
  WECHAT_MY_SERVICE_ONLINE_RECEIPT: '/wechat/my/service/receipt/online-receipt',
  WECHAT_MY_SERVICE_RECEIPT_SUCCESS: '/wechat/my/service/receipt/success',
  //多主险在线回执
  WECHAT_MY_SERVICE_DEGITAL_RECEIPT_MORE:
    '/wechat/my/service/receipt/online-receipt-more',

  WECHAT_MY_EXPLORE: '/wechat/my/explore',
  WECHAT_MY_EXPLORE_LIST: '/wechat/my/explore/list',
  WECHAT_MY_EXPLORE_DETAIL: '/wechat/my/explore/detail/:exploreId',

  //红利领取
  WECHAT_MY_DIVIDEND: '/wechat/my/dividend',
  WECHAT_MY_DIVIDEND_SURE: '/wechat/my/dividend/sure', //红利领取确认
  WECHAT_MY_DIVIDEND_SIG: '/wechat/my/dividend/sig',
  WECHAT_MY_DIVIDEND_END: '/wechat/my/dividend/end', //红利领取方式变更结束

  //红利领取方式变更
  WECHAT_MY_DIVIDENDCHANGE: '/wechat/my/dividendChange',
  WECHAT_MY_DIVIDENDCHANGE_SURE: '/wechat/my/dividendChange/sure', //红利领取方式变更确认
  WECHAT_MY_DIVIDENDCHANGE_SIG: '/wechat/my/dividendChange/sig', //签名
  WECHAT_MY_DIVIDENDCHANGE_END: '/wechat/my/dividendChange/end', //红利领取方式变更结束

  //投诉意见
  WECHAT_MY_COMPLAINTS: '/wechat/my/complaints',
  WECHAT_MY_COMPLAINTS_LIST: '/wechat/my/complaints/list',
  WECHAT_MY_COMPLAINTS_FEEDBACK: '/wechat/my/complaints/feedback:comData',
  WECHAT_MY_COMPLAINTSNEW: '/wechat/my/complaintsNew',
  WECHAT_MY_COMPLAINTSNEW_FEEDBACK: '/wechat/my/complaintsNew/feedbackNew',

  WECHAT_MY_TRANSPARENT_CLAIM: '/wechat/my/transparent-claim',

  WECHAT_MY_CLAIM_CASE_SELECT: '/wechat/my/claim-case-select',
  WECHAT_MY_CLAIM_CASE_INQUIRY: '/wechat/my/claim-case-inquiry',
  WECHAT_MY_CASE_LIST: '/wechat/my/case-list',
  WECHAT_MY_REMINDERS_QUIRY: '/wechat/my/reminders-quiry',
  WECHAT_MY_CASE_DETAIL: '/wechat/my/case-detail',
  WECHAT_MY_CASE_TRACK: '/wechat/my/case-track',
  WECHAT_MY_CLAIM_SUPPLEMENT: '/wechat/my/claim-supplement',
  WECHAT_MY_CLAIM_FILE: '/wechat/my/claim-file',
  WECHAT_MY_APPLYMENT_CLAIM: '/wechat/my/applyment-claim',
  WECHAT_MY_CLAIM_REPORT: '/wechat/my/claim-report',
  WECHAT_MY_CLAIM_REPORT_SECOND: '/wechat/my/claim-report/second',
  WECHAT_MY_CLAIM_REPORT_THIRD: '/wechat/my/claim-report/third',
  WECHAT_MY_CLAIM_REPORT_END: '/wechat/my/claim-report/end',
  WECHAT_MY_CLAIM_REPORT_KNOWLEDGE: '/wechat/my/claim-report/knowledge',

  WECHAT_MY_SMALL_CLAIM: '/wechat/my/small-claim',
  WECHAT_MY_SMALL_CLAIM_SECOND: '/wechat/my/small-claim/second',
  WECHAT_MY_SMALL_CLAIM_THIRD: '/wechat/my/small-claim/third',
  WECHAT_MY_SMALL_CLAIM_FORTH: '/wechat/my/small-claim/forth',
  WECHAT_MY_SMALL_CLAIM_SIG: '/wechat/my/small-claim/sig',
  WECHAT_MY_SMALL_CLAIM_END: '/wechat/my/small-claim/end',
  WECHAT_MY_SMALL_CLAIM_KNOWLEDGE: '/wechat/my/small-claim/knowledge',
  WECHAT_MY_GROUP_CLAIMS: '/wechat/my/group-claims',
  WECHAT_MY_GROUP_CLAIMS_SECOND: '/wechat/my/group-claims/second',
  WECHAT_MY_GROUP_CLAIMS_THIRD: '/wechat/my/group-claims/third',
  WECHAT_MY_GROUP_CLAIMS_FORTH: '/wechat/my/group-claims/forth',
  WECHAT_MY_GROUP_CLAIMS_END: '/wechat/my/group-claims/end',
  WECHAT_MY_GROUP_CLAIMS_KNOWLEDGE: '/wechat/my/group-claims/knowledge',
  //智能理赔
  WECHAT_MY_SMART_CLAIMS: '/wechat/my/smart-claims',
  //团险医疗理赔
  WECHAT_MY_GROUP_INSURANCE_CLAIMS: '/wechat/my/group-insurance-claims',
  WECHAT_MY_GROUP_INSURANCE_CLAIMS_SECOND:
    '/wechat/my/group-insurance-claims/second',
  WECHAT_MY_GROUP_INSURANCE_CLAIMS_THIRD:
    '/wechat/my/group-insurance-claims/third',
  WECHAT_MY_GROUP_INSURANCE_CLAIMS_FOURTH:
    '/wechat/my/group-insurance-claims/fourth',
  WECHAT_MY_GROUP_INSURANCE_CLAIMS_END: '/wechat/my/group-insurance-claims/end',
  WECHAT_MY_GROUP_INSURANCE_CLAIMS_KNOWLEDGE:
    '/wechat/my/group-insurance-claims/knowledge',
  WECHAT_MY_SMART_CLAIMS_SECOND: '/wechat/my/smart-claims/second',
  WECHAT_MY_SMART_CLAIMS_THIRD: '/wechat/my/smart-claims/third',
  WECHAT_MY_SMART_CLAIMS_FORTH: '/wechat/my/smart-claims/forth',
  WECHAT_MY_SMART_CLAIMS_END: '/wechat/my/smart-claims/end',
  WECHAT_MY_SMART_CLAIMS_KNOWLEDGE: '/wechat/my/smart-claims/knowledge',
  // 团险理赔报案-身份验证
  WECHAT_MY_GROUP_CLAIM_REPORT: '/wechat/my/group-claim-report',
  // 团险理赔报案-保单选择
  WECHAT_MY_GROUP_CLAIM_REPORT_SELECT: '/wechat/my/group-claim-report/select',
  // 团险理赔报案-报案信息
  WECHAT_MY_GROUP_CLAIM_REPORT_SECOND: '/wechat/my/group-claim-report/second',
  // 团险理赔报案-报案信息确认
  WECHAT_MY_GROUP_CLAIM_REPORT_THIRD: '/wechat/my/group-claim-report/third',
  // 团险理赔报案-报案完成
  WECHAT_MY_GROUP_CLAIM_REPORT_END: '/wechat/my/group-claim-report/end',
  // 团险理赔报案-理赔须知
  WECHAT_MY_GROUP_CLAIM_REPORT_KNOWLEDGE:
    '/wechat/my/group-claim-report/knowledge',
  WECHAT_MY_BENEFICIARY_CHANGE: '/wechat/my/beneficiary-change',
  WECHAT_MY_BENEFICIARY_CHANGE_UPLOAD: '/wechat/my/beneficiary-change/upload',
  WECHAT_MY_BENEFICIARY_CHANGE_NEW: '/wechat/my/beneficiary-change/new',
  WECHAT_MY_BENEFICIARY_CHANGE_SURE: '/wechat/my/beneficiary-change/sure',
  WECHAT_MY_BENEFICIARY_CHANGE_END: '/wechat/my/beneficiary-change/end',

  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS: '/wechat/my/personal-insurance-claims',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS_SECOND:
    '/wechat/my/personal-insurance-claims/second',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS_THIRD:
    '/wechat/my/personal-insurance-claims/third',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS_FOUR:
    '/wechat/my/personal-insurance-claims/four',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIM_END:
    '/wechat/my/personal-insurance-claims/end',
  WECHAT_MY_PERSONAL_INSURANCE_KNOWLEDGE:
    '/wechat/my/personal-insurance-claims/knowledge',
  WECHAT_MY_PERSONAL_INSURANCE_SIG: '/wechat/my/personal-insurance-claims/sig',
  WECHAT_MY_TAX_DECLARATION_SIG: '/wechat/my/personal-insurance-claims/tax-sig',
  WECHAT_MY_TO_SIG: '/wechat/my/personal-insurance-claims/to-sig',
  WECHAT_MY_PERSONAL_SHARE_SIG:
    '/wechat/my/personal-insurance-claims/share-sig',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS_THIRD_DOWN:
    '/wechat/my/personal-insurance-claims/third/down',
  WECHAT_MY_PERSONAL_INSURANCE_CLAIMS_INFORMATION_DOWN:
    '/wechat/my/personal-insurance-claims/down',

  //个险报案
  WECHAT_MY_PERSONAL_INSURANCE_REPORT: '/wechat/my/personal-insurance-report',
  WECHAT_MY_PERSONAL_INSURANCE_REPORT_SECOND:
    '/wechat/my/personal-insurance-report/second',
  WECHAT_MY_PERSONAL_INSURANCE_REPORT_FOUR:
    '/wechat/my/personal-insurance-report/third',
  WECHAT_MY_PERSONAL_INSURANCE_REPORT_END:
    '/wechat/my/personal-insurance-report/end',
  WECHAT_MY_PERSONAL_INSURANCE_REPORT_KNOWLEDGE:
    '/wechat/my/personal-insurance-report/knowledge',

  //万能利率查看home
  WECHAT_MY_UNIVERSAL_INTEREST_RATE_HOME:
    '/wechat/my/universal-interest-rate-home',
  //万能利率查看页面
  WECHAT_MY_UNIVERSAL_INTEREST_RATE_VIEW:
    '/wechat/my/universal-interest-rate-home/view',
  //红利累积生息
  WECHAT_MY_DIVIDEND_ACCUMULATION:
    '/wechat/my/universal-interest-rate-home/dividend',
  //生存保险金利率
  WECHAT_MY_SURVIVAL_INSURANCE:
    '/wechat/my/universal-interest-rate-home/survival',
  //保单借款年利率
  WECHAT_MY_POLICY_BORROW_RATE:
    '/wechat/my/universal-interest-rate-home/policy-borrow',
  //保单补打
  WECHAT_MY_POLICY_REISSUE: '/wechat/my/policy-reissue',
  //保单补打确认页
  WECHAT_MY_POLICY_REISSUE_CONFIRM: '/wechat/my/policy-reissue/confirm',
  //保单补打
  WECHAT_MY_POLICY_REISSUE_INFO: '/wechat/my/policy-reissue',
  //保单补打结果页
  WECHAT_MY_REISSUE_END: '/wechat/my/policy-reissue/end',
  //保单补打支付结果页
  WECHAT_MY_RENEWAL_REISSUE_PAYMENT_RESULT:
    '/wechat/my/policy-reissue/reissue-payment-end',
  //妙健康服务
  WECHAT_MY_MIAO_HEALTH_SERVICE: '/wechat/my/miao-health-service',
  //疫情服务
  WECHAT_MY_OUTBREAKSERVICE: '/wechat/my/miao-health-service/outbreak-service',
  //客户签约
  WECHAT_MY_CUSTOMER_SIGNING: '/wechat/my/customer-signing',
  WECHAT_MY_CUSTOMER_SIGNING_FILL: '/wechat/my/customer-signing/fill',
  //电子信函
  WECHAT_MY_WIEW_EMAIL: '/wechat/my/view-email',
  //电子信函查看
  WECHAT_MY_WIEW_EMAIL_CHECK: '/wechat/my/view-email/check',
  //电子信函保单列表
  WECHAT_MY_WIEW_EMAIL_CHECK_LIST: '/wechat/my/view-email/check/list',
  //电子信函年度列表
  WECHAT_MY_WIEW_EMAIL_CHECK_YEAR_LIST: '/wechat/my/view-email/check/year-list',
  //电子信函年度展示
  WECHAT_MY_WIEW_EMAIL_CHECK_SHOW: '/wechat/my/view-email/check/show',
  //电子信函发送方式变更列表
  WECHAT_MY_WIEW_EMAIL_CHANGE_LIST: '/wechat/my/view-email/change/list',
  //电子信函发送方式变更详情
  WECHAT_MY_WIEW_EMAIL_CHANGE_INFO: '/wechat/my/view-email/change/info',
  //电子信函发送方式变更结果
  WECHAT_MY_WIEW_EMAIL_CHANGE_END: '/wechat/my/view-email/change/end',
  //垫交方式
  WECHAT_MY_MATTING_CHANGE: '/wechat/my/matting',
  WECHAT_MY_MATTING_CHANGE_END: '/wechat/my/matting/end',
  //扫码回访
  WECHAT_MY_ONLINE_VISIT_QR: '/wechat/my/online-visit-qr',
  WECHAT_MY_ONLINE_VISIT_QR_LIST: '/wechat/my/online-visit-qr/list',
  WECHAT_MY_ONLINE_VISIT_QR_AUTH: '/wechat/my/online-visit-qr/auth',
  WECHAT_MY_ONLINE_VISIT_QR_AUTH_RESULT:
    '/wechat/my/online-visit-qr/auth/result',
  WECHAT_MY_ONLINE_VISIT_QR_PAPER: '/wechat/my/online-visit-qr/paper',
  WECHAT_MY_ONLINE_VISIT_QR_DETAILS: '/wechat/my/online-visit-qr/details',
  WECHAT_MY_ONLINE_VISIT_QR_END: '/wechat/my/online-visit-qr/end',
  WECHAT_MY_ONLINE_VISIT_QR_LOCK: '/wechat/my/online-visit-qr/lock',
  //服务下线
  WECHAT_MY_DEPRECATED: '/wechat/my/deprecated',
  //pdf预览前置
  WECHAT_MY_PDF_VIEW: '/wechat/my/pdfView',
  //身份验证
  WECHAT_MY_IDCHECK: '/wechat/my/idcheck',
  WECHAT_MY_IDCHECK_RESULT: '/wechat/my/idcheck/result',
  WECHAT_MY_IMG_SUP: '/wechat/my/supplement',
  WECHAT_MY_HELATH_SHOP: '/wechat/my/health-shop',
  WECHAT_MY_HEALTH_MANAGEMENT_DEVELOPMENT:
    '/wechat/my/health-management-development',

  MOBILE: '/mobile',

  MOBILE_LIFE_HOME: '/mobile/life/home',
  MOBILE_LIFE_HOME_SEARCH: '/mobile/life/home/search',

  MOBILE_AGENT_APPLY: '/mobile/part-time-agent/agent-apply', //兼业代理人申请兼职跳转
  MOBILE_LINK_SIGN: '/mobile/part-time-agent/link-sign',
  MOBILE_LINK_SIGN_APPLICANT: '/mobile/part-time-agent/link-sign-applicant',
  MOBILE_LINK_SIGN_INSURED: '/mobile/part-time-agent/link-sign-insured',
  MOBILE_LINK_SIGN_INSURED2: '/mobile/part-time-agent/link-sign-insured2',
  MOBILE_LINK_SIGN_TAX: '/mobile/part-time-agent/link-sign-tax',
  MOBILE_TEST: '/mobile/part-time-agent/test',
  MOBILE_RESULT_APPLY: '/mobile/part-time-agent/result-apply',
  MOBILE_ATTACHE_HOME: '/mobile/attache/attache-home',
  MOBILE_ATTACHE_CARD: '/mobile/attache/attache-card',
  MOBILE_ATTACHE_MIEN: '/mobile/attache/attache-mien', //专员风采
  MOBILE_ATTACHE_MIEN_INFO: '/mobile/attache/attache-mien-info', //专员风采详情
  MOBILE_ATTACHE_RECRUIT: '/mobile/attache/attache-recruit', //专员招聘
  MOBILE_ATTACHE_RECRUIT_APPLY: '/mobile/attache/attache-recruit-apply', //专员招聘申请
  MOBILE_ATTACHE_AUDIT: '/mobile/attache/audit',
  MOBILE_ATTACHE_AUDIT_LIST: '/mobile/attache/audit/list',
  MOBILE_ATTACHE_AUDIT_DETAIL: '/mobile/attache/audit/detail/:exploreId',
  MOBILE_ACTIVITY_HOME: '/mobile/activity/activity-home',
  MOBILE_ACTIVITY_JOINED: '/mobile/activity/activity-joined',
  MOBILE_ACTIVITY_CATEGORY: '/mobile/activity/activity-category',
  MOBILE_ACTIVITY_CATEGORY_LIST:
    '/mobile/activity/activity-category/list/:categoryId',
  MOBILE_ACTIVITY_CATEGORY_DETAIL:
    '/mobile/activity/activity-category/detail/:activityId',
  MOBILE_ACTIVITY_CATEGORY_JOIN:
    '/mobile/activity/activity-category/join/:activityId',
  MOBILE_HOME: '/mobile/home',
  MOBILE_HOME_SEARCH: '/mobile/home/search',

  MOBILE_ORDER: '/mobile/order',
  MOBILE_SERVICE_PROTOCOL: '/mobile/service/protocol',
  MOBILE_ERROR_DETAIL: '/mobile/error',
  MOBILE_INSURANCE_ADVISOR: '/mobile/advisor',

  MOBILE_PRODUCT_CENTER: '/mobile/product-center/list',
  MOBILE_PRODUCT_CATEGORY: '/mobile/product-center/list/category',
  MOBILE_THIRD_PARTY_DOCKING: '/mobile/me/docking',

  MOBILE_CATEGORY: '/mobile/category',
  MOBILE_CATEGORY_COVERAGE: '/mobile/category/coverage',
  MOBILE_CATEGORY_COVERAGE_SEARCH: '/mobile/category/coverage/search',
  MOBILE_CATEGORY_VENDOR: '/mobile/category/vendor',
  MOBILE_CATEGORY_VENDOR_SEARCH: '/mobile/category/vendor/search',

  MOBILE_EXPLORE: '/mobile/explore',
  MOBILE_EXPLORE_NEWSDETAILS: '/mobile/explore/newsdetails',

  MOBILE_ONLINE_TRAINNING: '/mobile/online/trainning',
  MOBILE_ONLINE_TRAINNING_LIST: '/mobile/online/trainning/list',
  MOBILE_ONLINE_TRAINNING_QUIZ: '/mobile/online/trainning/quiz',
  MOBILE_ONLINE_TRAINNING_QUIZ_DETAIL:
    '/mobile/online/trainning/quiz-detail/:quizId',
  MOBILE_ONLINE_TRAINNING_VIDEO: '/mobile/online/trainning/video',
  MOBILE_ONLINE_TRAINNING_VIDEO_DETAIL:
    '/mobile/online/trainning/video-detail/:courseId',
  MOBILE_ONLINE_TRAINNING_OFFLINE: '/mobile/online/trainning/offline',
  MOBILE_ONLINE_TRAINNING_OFFLINE_DETAIL:
    '/mobile/online/trainning/offline-detail/:courseId/:status',
  MOBILE_ONLINE_TRAINNING_COMPULSORY: '/mobile/online/trainning/complusory',
  MOBILE_ONLINE_TRAINNING_OPTIONAL: '/mobile/online/trainning/optional',
  MOBILE_ONLINE_TRAINNING_COMPLETED: '/mobile/online/trainning/completed',
  MOBILE_ONLINE_TRAINNING_COURSE_DETAIL:
    '/mobile/online/trainning/detail/:courseId/:status',
  MOBILE_ONLINE_TRAINNING_COURSE_LEARN:
    '/mobile/online/trainning/learn/:courseId/:status',

  //B2E
  MOBILE_ONLINEDN_TRAINNING: '/mobile/onlinedn/trainning',
  MOBILE_ONLINEDN_TRAINNING_LIST: '/mobile/onlinedn/trainning/list',
  MOBILE_ONLINEDN_TRAINNING_QUIZ: '/mobile/onlinedn/trainning/quiz',
  MOBILE_ONLINEDN_TRAINNING_QUIZ_DETAIL:
    '/mobile/onlinedn/trainning/quiz-detail/:quizId',
  MOBILE_ONLINEDN_TRAINNING_VIDEO: '/mobile/onlinedn/trainning/video',
  MOBILE_ONLINEDN_TRAINNING_OFFLINE: '/mobile/onlinedn/trainning/offline',
  MOBILE_ONLINEDN_TRAINNING_OFFLINE_DETAIL:
    '/mobile/onlinedn/trainning/offline-detail/:courseId/:status',
  MOBILE_ONLINEDN_TRAINNING_COMPULSORY: '/mobile/onlinedn/trainning/complusory',
  MOBILE_ONLINEDN_TRAINNING_OPTIONAL: '/mobile/onlinedn/trainning/optional',
  MOBILE_ONLINEDN_TRAINNING_COMPLETED: '/mobile/onlinedn/trainning/completed',
  MOBILE_ONLINEDN_TRAINNING_COURSE_DETAIL:
    '/mobile/onlinedn/trainning/detail/:courseId/:status',
  MOBILE_ONLINEDN_TRAINNING_COURSE_LEARN:
    '/mobile/onlinedn/trainning/learn/:courseId',
  MOBILE_AGENT_APPLY_INFO: '/mobile/part-time-agent/agent-info/:agent', //B2E签字详情

  MOBILE_MEDN: '/mobile/medn',

  MOBILE_MY_CLAIM_CASE_SELECT: '/mobile/me/claim-case-select',
  MOBILE_MY_CLAIM_CASE_INQUIRY: '/mobile/me/claim-case-inquiry',
  MOBILE_MY_CASE_LIST: '/mobile/me/case-list',
  MOBILE_MY_REMINDERS_QUIRY: '/mobile/me/reminders-quiry',
  MOBILE_MY_CASE_DETAIL: '/mobile/me/case-detail',
  MOBILE_MY_CASE_TRACK: '/mobile/me/case-track',
  MOBILE_MY_CLAIM_SUPPLEMENT: '/mobile/me/claim-supplement',

  MOBILE_ME: '/mobile/me',
  MOBILE_ME_CUTOMER_MANAGEMENT: '/mobile/me/customer-management',
  MOBILE_ME_LANG: '/mobile/me/lang',
  MOBILE_ME_PWD: '/mobile/me/pwd',
  MOBILE_ME_PROFILE: '/mobile/me/profile',
  MOBILE_ME_CENTER: '/mobile/me/center',
  MOBILE_ME_SETTING: '/mobile/me/setting',
  MOBILE_FORGETPWD: '/mobile/me/setting/forget-Pwd',
  MOBILE_FORGETPWD_DN: '/mobile/me/setting/forget-Pwd-Dn',
  MOBILE_FORGETPAYPWD: '/mobile/forget-PayPwd',
  MOBILE_ME_SETTING_ABOUT: '/mobile/me/setting/about',
  MOBILE_ME_POLICY_QUERY: '/mobile/me/policy-query',
  MOBILE_ME_POLICY_QUERY_RESULT: '/mobile/me/policy-query/result',
  MOBILE_ME_MY_FAV: '/mobile/me/my-fav',
  MOBILE_ME_MY_QUOTE: '/mobile/me/my-quote',
  MOBILE_ME_MY_POLICY: '/mobile/me/my-policy',
  MOBILE_ME_MY_RENEW: '/mobile/me/my-renew',
  MOBILE_ME_MY_CUSTOMER: '/mobile/me/my-customer',
  MOBILE_ME_MY_CUSTOMER_INFO: '/mobile/me/my-customer/customer-info',
  MOBILE_ME_MY_CUSTOMER_INFO_NEW:'/mobile/me/my-customer/customer-info-new',
  MOBILE_ME_MY_NEW_CUSTOMER: '/mobile/me/my-new-customer',
  MOBILE_ME_MY_SELF_SETTLEMENT: '/mobile/me/my-self-settlement',
  MOBILE_ME_MY_SECOND_SETTLEMENT: '/mobile/me/my-self-settlement/second',
  MOBILE_ME_MY_END_SETTLEMENT: '/mobile/me/my-self-settlement/end',
  MOBILE_ME_MY_SEARCH_SETTLEMENT: '/mobile/me/my-self-settlement/search',
  MOBILE_ME_MY_SEARCH_SETTLEMENT_RESULT:
    '/mobile/me/my-self-settlement/search/result',
  MOBILE_ME_MY_M_CARD: '/mobile/me/my-micro-card',
  MOBILE_ME_MY_MESSAGE_CENTER: '/mobile/me/my-message-center',
  MOBILE_ME_MY_WALLET: '/mobile/me/my-wallet',
  MOBILE_ME_LAST_MONTH_DETAIL: '/mobile/me/last-month-detail',
  MOBILE_ME_PAYROLL: '/mobile/me/payroll',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT: '/mobile/me/salesman-management',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_RECRUITMENT:
    '/mobile/me/salesman-management/recruitment',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_ACTIVITY:
    '/mobile/me/salesman-management/activity',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_CREAT_ACTIVITY:
    '/mobile/me/salesman-management/activity/creat',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_TEAM: '/mobile/me/salesman-management/team',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_TEAM_MEMBERSHIP:
    '/mobile/me/salesman-management/team/membership',
  MOBILE_ME_MY_SALESMAN_MANAGEMENT_TEAM_PERFORMANCE:
    '/mobile/me/salesman-management/team/performance',
  MOBILE_ME_MY_WITHDRAWALS: '/mobile/me/my-withdrawals',
  MOBILE_ME_MY_CUMULATIVE_WITHDRAWALS: '/mobile/me/my-cumulative-withdrawals',
  MOBILE_ME_MY_ORDER: '/mobile/me/my-order',
  MOBILE_ME_MY_ORDER_DETAIL: '/mobile/me/my-order-detail',
  MOBILE_ME_CORRESPONDENCE_PROCESSING: '/mobile/me/correspondence-processing',
  MOBILE_ME_DATA_SUPPLEMENT: '/mobile/me/data-supplement',
  MOBILE_ME_MY_POLICY_DETAIL: '/mobile/me/my-policy-detail',
  MOBILE_ME_MY_M_CARD_SHARE: '/mobile/me/my-micro-card/share',
  MOBILE_ME_MY_M_CARD_AUTHEN: '/mobile/me/my-micro-card/certify',
  MOBILE_ME_MY_M_CARD_HONOR: '/mobile/me/my-micro-card/honor/:qId',
  MOBILE_ME_MY_PRODUCT_MEET: '/mobile/me/my-product-meet',
  MOBILE_ME_MY_PRODUCT_MEET_DEL: '/mobile/me/my-product-meet/details',
  MOBILE_ME_MY_PRODUCT_MEET_DEL_SHARE:
    '/mobile/me/my-product-meet/details/share',
  MOBILE_ME_MY_PRODUCT_MEET_DEL_SHARE_RESULT:
    '/mobile/me/my-product-meet/details/share/result',
  MOBILE_ME_MY_PRODUCT_MEET_DEL_SHARE_FAIL:
    '/mobile/me/my-product-meet/details/share/fail',
  MOBILE_ME_MY_IMPORT_CUSTOMER: '/mobile/me/import-customer',
  MOBILE_ME_MY_CUSTOMER_POLICY: '/mobile/me/my-customer/policy',
  MOBILE_ME_MY_STORE: '/mobile/me/my-store',
  MOBILE_ME_MY_STORE_CREATE: '/mobile/me/my-store/create',
  MOBILE_ME_MY_STORE_EDIT: '/mobile/me/my-store/edit',
  MOBILE_ME_MY_STORE_PRODUCT: '/mobile/me/my-store/product',
  MOBILE_ME_MY_STORE_PRODUCT_ADD: '/mobile/me/my-store/product-add',
  MOBILE_ME_PRESERVE_QUERY: '/mobile/me/preserve-query',
  MOBILE_ME_PRESERVE_QUERY_RESULT: '/mobile/me/preserve-query/result',
  MOBILE_ME_PROPOSAL_QUERY: '/mobile/me/proposal-query',
  MOBILE_ME_PROPOSAL_QUERY_RESULT: '/mobile/me/proposal-query/result',
  MOBILE_ME_CLAIM_REPORT: '/mobile/me/claim-report',
  MOBILE_ME_CLAIM_REPORT_SECOND: '/mobile/me/claim-report/second',
  MOBILE_ME_CLAIM_REPORT_THIRD: '/mobile/me/claim-report/third',
  MOBILE_ME_CLAIM_REPORT_END: '/mobile/me/claim-report/end',
  MOBILE_ME_CLAIM_REPORT_KNOWLEDGE: '/mobile/me/claim-report/knowledge',

  MOBILE_ME_MY_TRAINNING: '/mobile/me/my-trainning',
  MOBILE_ME_MY_TRAINNINGDN: '/mobile/medn/my-trainning', //B2E我的培训
  MOBILE_ME_MY_TRAINNING_LIST: '/mobile/me/my-trainning/list',
  MOBILE_ME_MY_TRAINNING_COMPULSORY: '/mobile/me/my-trainning/compulsory',
  MOBILE_ME_MY_TRAINNING_OPTIONAL: '/mobile/me/my-trainning/optional',
  MOBILE_ME_MY_TRAINNING_COMPLETED: '/mobile/me/my-trainning/completed',
  MOBILE_ME_MY_TRAINNING_COURSE_DETAIL:
    '/mobile/me/my-trainning/course/detail/:courseId/:status',
  MOBILE_ME_MY_TRAINNING_COURSE_LEARN:
    '/mobile/me/my-trainning/course/learn/:courseId/:status',

  MOBILE_POLICY: '/mobile/policy',
  MOBILE_POLICY_CREATE:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/create',
  MOBILE_POLICY_VIEW:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/view',
  MOBILE_POLICY_ADVICE_CREATE:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/advice/create',
  MOBILE_POLICY_ADVICE_CREATION:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/advice/creation/:policyId',
  MOBILE_POLICY_QUOTE_CREATE:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/quote/create',
  MOBILE_POLICY_QUOTE_EDIT:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/quote/edit/:policyId',
  MOBILE_POLICY_QUOTE_EDIT_STEP:
    '/mobile/policy/:vendorCode/:productCode/:productVersion/quote/edit/:policyId/:toStep',
  MOBILE_POLICY_SHARE_FAIL: '/mobile/policy/shareFail/:failType',

  MOBILE_PLANNING: '/mobile/me/planning',
  MOBILE_PLANNING_FAMILY_INCOME: '/mobile/me/planning/family-income',
  MOBILE_PLANNING_HOUSEHOLD_EXPENSE: '/mobile/me/planning/household-expense',
  MOBILE_PLANNING_FAMILY_ASSETS: '/mobile/me/planning/family-assets',
  MOBILE_PLANNING_HAS_INSURANCE: '/mobile/me/planning/has-insurance',
  MOBILE_PLANNING_CAL_RESULT: '/mobile/me/planning/cal-result',

  MOBILE_FINANCIAL: '/mobile/me/financial',
  MOBILE_FINANCIAL_RESULT: '/mobile/me/financial/result',
  MOBILE_FINANCIAL_CHART: '/mobile/me/financial/chart',
  MOBILE_FINANCIAL_PRODUCT: '/mobile/me/financial/product',
  MOBILE_CAPTCHA: '/mobile/captcha',

  DESK_HOME: '/desk',

  DESK_PRODUCT: '/desk/product',
  DESK_PRODUCT_LIST: '/desk/product/list',
  DESK_PRODUCT_LIST_EDIT: '/desk/product/list/edit/:productId',
  DESK_PRODUCT_LIST_CREATE: '/desk/product/list/create',

  DESK_PRODUCT_TAG: '/desk/product-tag',
  DESK_PRODUCT_TAG_LIST: '/desk/product-tag/list',
  DESK_PRODUCT_TAG_CREATE: '/desk/product-tag/create',
  DESK_PRODUCT_TAG_EDIT: '/desk/product-tag/edit/:tagId',

  DESK_PRODUCT_UI: '/desk/maintain/ui',
  DESK_PRODUCT_TEMP: '/desk/maintain/ui/temp',
  DESK_PRODUCT_TEMP_CREATE: '/desk/maintain/ui/create',
  DESK_PRODUCT_TEMP_EDIT: '/desk/maintain/ui/edit',

  DESK_PRODUCT_HOT: '/desk/product-hot',
  DESK_PRODUCT_HOT_LIST: '/desk/product-hot/list',
  DESK_PRODUCT_HOT_EDIT: '/desk/product-hot/edit/:productId',

  DESK_PRODUCT_RECOMMEND: '/desk/product-recommend',
  DESK_PRODUCT_RECOMMEND_LIST: '/desk/product-recommend/list',
  DESK_PRODUCT_RECOMMEND_EDIT: '/desk/product-recommend/edit/:productId',

  DESK_PRODUCT_TAG_JOINT: '/desk/product-tag-joint',
  DESK_PRODUCT_TAG_JOINT_LIST: '/desk/product-tag-joint/list',
  DESK_PRODUCT_TAG_JOINT_EDIT: '/desk/product-tag-joint/edit/:productId',

  DESK_AGREEMENT: '/desk/agreement',
  DESK_AGREEMENT_LIST: '/desk/agreement/list',
  DESK_AGREEMENT_CREATE: '/desk/agreement/create',
  DESK_AGREEMENT_EDIT: '/desk/agreement/edit/:agreementId',

  DESK_TENANT: '/desk/tenant',
  DESK_TENANT_CURRENT: '/desk/tenant/current',
  DESK_TENANT_VIEW: '/desk/tenant/view',
  DESK_TENANT_AUTHORIZATION: '/desk/tenant/menus',
  DESK_TENANT_LIST: '/desk/tenant/list',
  DESK_TENANT_CREATE: '/desk/tenant/create',
  DESK_TENANT_EDIT: '/desk/tenant/edit/:tenantId',

  DESK_ORGAN: '/desk/organ',
  DESK_ORGAN_CURRENT: '/desk/organ/current',
  DESK_ORGAN_VIEW: '/desk/organ/view',
  DESK_SUB_ORGAN_LIST: '/desk/organ/sub/list',
  DESK_ORGAN_LIST: '/desk/organ/list',
  DESK_ORGAN_CREATE: '/desk/organ/create',
  DESK_ORGAN_EDIT: '/desk/organ/edit/:organId',

  DESK_ACCOUNT: '/desk/account',
  DESK_ACCOUNT_CURRENT: '/desk/account/current',
  DESK_SUB_ACCOUNT_LIST: '/desk/account/sub/list',
  DESK_ACCOUNT_LIST: '/desk/account/list',
  DESK_ACCOUNT_CREATE: '/desk/account/create',
  DESK_ACCOUNT_EDIT: '/desk/account/edit/:accountId',
  DESK_ACCOUNT_MENUS: '/desk/account/menus/:accountId',

  DESK_API_KEY: '/desk/apikey',

  DESK_CHANGE_PWD: '/desk/change-pwd',
  DESK_CHANGE_LANG: '/desk/change-lang',

  DESK_POLICY_DATA_ENTRY: '/desk/policy/input',
  DESK_POLICY_STATISTICS: '/desk/policy/stat',

  DESK_POLICY_QUERY: '/desk/policy/query',
  DESK_POLICY_QUERY_VIEW: '/desk/policy/query/view/:policyId',

  DESK_CUSTOMER: '/desk/customer',
  DESK_CUSTOMER_QUERY: '/desk/customer/query',
  DESK_CUSTOMER_DETAIL: '/desk/customer/detail',

  DESK_PRODUCT_EXPLORE: '/desk/product-explore',
  DESK_PRODUCT_EXPLORE_QUERY: '/desk/product-explore/list',
  DESK_PRODUCT_EXPLORE_EDIT: '/desk/product-explore/edit/:exploreId',
  DESK_PRODUCT_EXPLORE_CREATE: '/desk/product-explore/create',

  DESK_PRODUCT_MEETING: '/desk/product-meeting',
  DESK_PRODUCT_MEETING_CREATE: '/desk/product-meeting/create',
  DESK_PRODUCT_MEETING_AGENT: '/desk/product-meeting/agent',
  DESK_PRODUCT_MEETING_AGENT_CUSTOMER: '/desk/product-meeting/agent/customer',

  API_DOCS: '/api-docs',

  DESK_POLICY_LOG_QUERY: '/desk/policyLog/query',
  DESK_POLICY_LOG_QUERY_VIEW: '/desk/policyLog/query/view/:eventLogId',

  DESK_PAYMENT_QUERY: '/desk/payment/query',
  DESK_PAYMENT_QUERY_VIEW: '/desk/payment/query/view',

  DESK_ONLINE_COURSE: '/desk/online-course',
  DESK_ONLINE_COURSE_QUERY: '/desk/online-course/query',
  DESK_ONLINE_COURSE_CREATE: '/desk/online-course/create',
  DESK_ONLINE_COURSE_EDIT: '/desk/online-course/edit/:courseId',

  DESK_QUIZ: '/desk/quiz',
  DESK_QUIZ_QUERY: '/desk/quiz/query',
  DESK_QUIZ_CREATE: '/desk/quiz/create',

  DESK_QUIZ_EDIT: '/desk/quiz/edit/:quizId',
  DESK_ONLINE_COURSE_TRAIN_QUERY: '/desk/online-course-train/query',
  DESK_ACTIVITY: '/desk/activity',
  DESK_ACTIVITY_CATEGORY: '/desk/activity-category',
  DESK_ACTIVITY_CATEGORY_QUERY: '/desk/activity-category/query',
  DESK_ACTIVITY_CATEGORY_EDIT: '/desk/activity-category/edit/:categoryId',
  DESK_ACTIVITY_CATEGORY_CREATE: '/desk/activity-category/create',
  DESK_ACTIVITY_CONTENT: '/desk/activity-content',
  DESK_ACTIVITY_CONTENT_QUERY: '/desk/activity-content/query',
  DESK_ACTIVITY_CONTENT_EDIT: '/desk/activity-content/edit/:contentId',
  DESK_ACTIVITY_CONTENT_CREATE: '/desk/activity-content/create',
  DESK_ATTACHE_RECRUIT_LIST: '/desk/attache-recruit/list', //专员招聘列表
  DESK_ATTACHE_RECRUIT_INFO: '/desk/attache-recruit/info', //专员招聘详情
  DESK_ATTACHE_MIEN_LIST: '/desk/attache-mien/list', //专员风采列表
  DESK_ATTACHE_MIEN_INFO: '/desk/attache-mien/info/:mienId', //专员风采详情

  DESK_MEMBER_QUERY: '/desk/member-query/query',
  DESK_ONLINE_QUIZ_QUERY: '/desk/online-quiz/query',
  DESK_ACHIEVEMENT_QUERY: '/desk/achievement/query',
  DESK_UPLOAD_UPLOAD: '/desk/upload/upload', //上传文件
  DESK_AGENT_REPORT: '/desk/agent-report/query',
  DESK_ACCOUNT_REPORT: '/desk/account-report/query',
  DESK_PRODUCT_REPORT: '/desk/product-report/query',
};

export {
  ACCOUNT_SIGN_KEY,
  ACCOUNT_KEY,
  TRUST_KEY_KEY,
  LAST_CRITERIA,
  LAST_SNAPSHOT,
  LAST_DETAIL_ID,
  LAST_SCROLL_POS,
  LAST_OPERATION_TYPE,
  LAST_ACTION,
  TENANT_IDS,
  CURRENCY_SIGNS,
  PATH,
  GENDER,
  GENDER2,
  SHEBAO,
  COUNTRY,
  EDUCATION,
  EDUCATIONDN,
  ATTACHE, //专员
  AGENTGROUP, //机构
  HUMAN_TITLE,
  IDTYPE,
  // 证件类型
  GROUPIDTYPE,
  BANK,
  CUSTOMER_TYPE,
  ACCOUNT_TYPE,
  TENANT_TYPE,
  PRODUCT_TAG_TYPE,
  POLICY_STATUS,
  PRODUCT_EXPLORE_TEMPLATE_TYPE,
  POLICY_LOG_STATUS,
  INSURED_TYPE,
  COMMISSION_TYPE,
  INCOMESRC,
  DEVICE_LIST,
  JSON_TYPE,
  MARRIAGE,
  MARRIAGE2,
  MARRIAGEBoolean,
  JOB_TYPE,
  JOB_DN,
  BANKLIST,
  BANKLIST_DN,
  SERVICE_TYPE,
  REQUEST_TYPE,
  CHANGE_STATUS,
  CHARGE_PERIOD,
  COVERAGE_PERIOD,
  CLAIM_SERVICE_TYPE,
  CLAIM_STATUS,
  CHANNEL,
  PAYMENT_STATUS,
  COURSE_TYPE,
  COURSE_STATUS,
  GOODS_STATUS,
  GOODS_ORDER_STATUS,
  RELATION,
  ACCIDENT_CAUSE,
  ACCIDENT_CAUSE_CLAIMS,
  CASE_TYPE,
  GROUP_CASE_TYPE,
  TREATMENT_TYPE,
  DELIVERY_WAY,
  CLAIM_BANK,
  SMALL_CLAIM_BANK,
  VIDEO_TYPE,
  MY_COURSE_STATUS,
};
