/**
 * Created by naiting.lei on 2/6/2017.
 */
let provsData = [
  {
    v: 110000,
    l: '北京市',
  },
  {
    v: 120000,
    l: '天津市',
  },
  {
    v: 130000,
    l: '河北省',
  },
  {
    v: 140000,
    l: '山西省',
  },
  {
    v: 150000,
    l: '内蒙古自治区',
  },
  {
    v: 210000,
    l: '辽宁省',
  },
  {
    v: 220000,
    l: '吉林省',
  },
  {
    v: 230000,
    l: '黑龙江省',
  },
  {
    v: 310000,
    l: '上海市',
  },
  {
    v: 320000,
    l: '江苏省',
  },
  {
    v: 330000,
    l: '浙江省',
  },
  {
    v: 340000,
    l: '安徽省',
  },
  {
    v: 350000,
    l: '福建省',
  },
  {
    v: 360000,
    l: '江西省',
  },
  {
    v: 370000,
    l: '山东省',
  },
  {
    v: 410000,
    l: '河南省',
  },
  {
    v: 420000,
    l: '湖北省',
  },
  {
    v: 430000,
    l: '湖南省',
  },
  {
    v: 440000,
    l: '广东省',
  },
  {
    v: 450000,
    l: '广西壮族自治区',
  },
  {
    v: 460000,
    l: '海南省',
  },
  {
    v: 500000,
    l: '重庆市',
  },
  {
    v: 510000,
    l: '四川省',
  },
  {
    v: 520000,
    l: '贵州省',
  },
  {
    v: 530000,
    l: '云南省',
  },
  {
    v: 540000,
    l: '西藏自治区',
  },
  {
    v: 610000,
    l: '陕西省',
  },
  {
    v: 620000,
    l: '甘肃省',
  },
  {
    v: 630000,
    l: '青海省',
  },
  {
    v: 640000,
    l: '宁夏回族自治区',
  },
  {
    v: 650000,
    l: '新疆维吾尔自治区',
  },
  {
    v: 710000,
    l: '台湾省',
  },
  {
    v: 810000,
    l: '香港特别行政区',
  },
  {
    v: 820000,
    l: '澳门特别行政区',
  },
];
let citysData = {
  110000: [
    {
      v: 110100,
      l: '市辖区',
    },
  ],
  120000: [
    {
      v: 120100,
      l: '市辖区',
    },
  ],
  130000: [
    {
      v: 130100,
      l: '石家庄市',
    },
    {
      v: 130200,
      l: '唐山市',
    },
    {
      v: 130300,
      l: '秦皇岛市',
    },
    {
      v: 130400,
      l: '邯郸市',
    },
    {
      v: 130500,
      l: '邢台市',
    },
    {
      v: 130600,
      l: '保定市',
    },
    {
      v: 130700,
      l: '张家口市',
    },
    {
      v: 130800,
      l: '承德市',
    },
    {
      v: 130900,
      l: '沧州市',
    },
    {
      v: 131000,
      l: '廊坊市',
    },
    {
      v: 131100,
      l: '衡水市',
    },
  ],
  140000: [
    {
      v: 140100,
      l: '太原市',
    },
    {
      v: 140200,
      l: '大同市',
    },
    {
      v: 140300,
      l: '阳泉市',
    },
    {
      v: 140400,
      l: '长治市',
    },
    {
      v: 140500,
      l: '晋城市',
    },
    {
      v: 140600,
      l: '朔州市',
    },
    {
      v: 140700,
      l: '晋中市',
    },
    {
      v: 140800,
      l: '运城市',
    },
    {
      v: 140900,
      l: '忻州市',
    },
    {
      v: 141000,
      l: '临汾市',
    },
    {
      v: 141100,
      l: '吕梁市',
    },
  ],
  150000: [
    {
      v: 150100,
      l: '呼和浩特市',
    },
    {
      v: 150200,
      l: '包头市',
    },
    {
      v: 150300,
      l: '乌海市',
    },
    {
      v: 150400,
      l: '赤峰市',
    },
    {
      v: 150500,
      l: '通辽市',
    },
    {
      v: 150600,
      l: '鄂尔多斯市',
    },
    {
      v: 150700,
      l: '呼伦贝尔市',
    },
    {
      v: 150800,
      l: '巴彦淖尔市',
    },
    {
      v: 150900,
      l: '乌兰察布市',
    },
    {
      v: 152200,
      l: '兴安盟',
    },
    {
      v: 152500,
      l: '锡林郭勒盟',
    },
    {
      v: 152900,
      l: '阿拉善盟',
    },
  ],
  210000: [
    {
      v: 210100,
      l: '沈阳市',
    },
    {
      v: 210200,
      l: '大连市',
    },
    {
      v: 210300,
      l: '鞍山市',
    },
    {
      v: 210400,
      l: '抚顺市',
    },
    {
      v: 210500,
      l: '本溪市',
    },
    {
      v: 210600,
      l: '丹东市',
    },
    {
      v: 210700,
      l: '锦州市',
    },
    {
      v: 210800,
      l: '营口市',
    },
    {
      v: 210900,
      l: '阜新市',
    },
    {
      v: 211000,
      l: '辽阳市',
    },
    {
      v: 211100,
      l: '盘锦市',
    },
    {
      v: 211200,
      l: '铁岭市',
    },
    {
      v: 211300,
      l: '朝阳市',
    },
    {
      v: 211400,
      l: '葫芦岛市',
    },
  ],
  220000: [
    {
      v: 220100,
      l: '长春市',
    },
    {
      v: 220200,
      l: '吉林市',
    },
    {
      v: 220300,
      l: '四平市',
    },
    {
      v: 220400,
      l: '辽源市',
    },
    {
      v: 220500,
      l: '通化市',
    },
    {
      v: 220600,
      l: '白山市',
    },
    {
      v: 220700,
      l: '松原市',
    },
    {
      v: 220800,
      l: '白城市',
    },
    {
      v: 222400,
      l: '延边朝鲜族自治州',
    },
  ],
  230000: [
    {
      v: 230100,
      l: '哈尔滨市',
    },
    {
      v: 230200,
      l: '齐齐哈尔市',
    },
    {
      v: 230300,
      l: '鸡西市',
    },
    {
      v: 230400,
      l: '鹤岗市',
    },
    {
      v: 230500,
      l: '双鸭山市',
    },
    {
      v: 230600,
      l: '大庆市',
    },
    {
      v: 230700,
      l: '伊春市',
    },
    {
      v: 230800,
      l: '佳木斯市',
    },
    {
      v: 230900,
      l: '七台河市',
    },
    {
      v: 231000,
      l: '牡丹江市',
    },
    {
      v: 231100,
      l: '黑河市',
    },
    {
      v: 231200,
      l: '绥化市',
    },
    {
      v: 232700,
      l: '大兴安岭地区',
    },
  ],
  310000: [
    {
      v: 310100,
      l: '市辖区',
    },
  ],
  320000: [
    {
      v: 320100,
      l: '南京市',
    },
    {
      v: 320200,
      l: '无锡市',
    },
    {
      v: 320300,
      l: '徐州市',
    },
    {
      v: 320400,
      l: '常州市',
    },
    {
      v: 320500,
      l: '苏州市',
    },
    {
      v: 320600,
      l: '南通市',
    },
    {
      v: 320700,
      l: '连云港市',
    },
    {
      v: 320800,
      l: '淮安市',
    },
    {
      v: 320900,
      l: '盐城市',
    },
    {
      v: 321000,
      l: '扬州市',
    },
    {
      v: 321100,
      l: '镇江市',
    },
    {
      v: 321200,
      l: '泰州市',
    },
    {
      v: 321300,
      l: '宿迁市',
    },
  ],
  330000: [
    {
      v: 330100,
      l: '杭州市',
    },
    {
      v: 330200,
      l: '宁波市',
    },
    {
      v: 330300,
      l: '温州市',
    },
    {
      v: 330400,
      l: '嘉兴市',
    },
    {
      v: 330500,
      l: '湖州市',
    },
    {
      v: 330600,
      l: '绍兴市',
    },
    {
      v: 330700,
      l: '金华市',
    },
    {
      v: 330800,
      l: '衢州市',
    },
    {
      v: 330900,
      l: '舟山市',
    },
    {
      v: 331000,
      l: '台州市',
    },
    {
      v: 331100,
      l: '丽水市',
    },
  ],
  340000: [
    {
      v: 340100,
      l: '合肥市',
    },
    {
      v: 340200,
      l: '芜湖市',
    },
    {
      v: 340300,
      l: '蚌埠市',
    },
    {
      v: 340400,
      l: '淮南市',
    },
    {
      v: 340500,
      l: '马鞍山市',
    },
    {
      v: 340600,
      l: '淮北市',
    },
    {
      v: 340700,
      l: '铜陵市',
    },
    {
      v: 340800,
      l: '安庆市',
    },
    {
      v: 341000,
      l: '黄山市',
    },
    {
      v: 341100,
      l: '滁州市',
    },
    {
      v: 341200,
      l: '阜阳市',
    },
    {
      v: 341300,
      l: '宿州市',
    },
    {
      v: 341500,
      l: '六安市',
    },
    {
      v: 341600,
      l: '亳州市',
    },
    {
      v: 341700,
      l: '池州市',
    },
    {
      v: 341800,
      l: '宣城市',
    },
  ],
  350000: [
    {
      v: 350100,
      l: '福州市',
    },
    {
      v: 350200,
      l: '厦门市',
    },
    {
      v: 350300,
      l: '莆田市',
    },
    {
      v: 350400,
      l: '三明市',
    },
    {
      v: 350500,
      l: '泉州市',
    },
    {
      v: 350600,
      l: '漳州市',
    },
    {
      v: 350700,
      l: '南平市',
    },
    {
      v: 350800,
      l: '龙岩市',
    },
    {
      v: 350900,
      l: '宁德市',
    },
  ],
  360000: [
    {
      v: 360100,
      l: '南昌市',
    },
    {
      v: 360200,
      l: '景德镇市',
    },
    {
      v: 360300,
      l: '萍乡市',
    },
    {
      v: 360400,
      l: '九江市',
    },
    {
      v: 360500,
      l: '新余市',
    },
    {
      v: 360600,
      l: '鹰潭市',
    },
    {
      v: 360700,
      l: '赣州市',
    },
    {
      v: 360800,
      l: '吉安市',
    },
    {
      v: 360900,
      l: '宜春市',
    },
    {
      v: 361000,
      l: '抚州市',
    },
    {
      v: 361100,
      l: '上饶市',
    },
  ],
  370000: [
    {
      v: 370100,
      l: '济南市',
    },
    {
      v: 370200,
      l: '青岛市',
    },
    {
      v: 370300,
      l: '淄博市',
    },
    {
      v: 370400,
      l: '枣庄市',
    },
    {
      v: 370500,
      l: '东营市',
    },
    {
      v: 370600,
      l: '烟台市',
    },
    {
      v: 370700,
      l: '潍坊市',
    },
    {
      v: 370800,
      l: '济宁市',
    },
    {
      v: 370900,
      l: '泰安市',
    },
    {
      v: 371000,
      l: '威海市',
    },
    {
      v: 371100,
      l: '日照市',
    },
    {
      v: 371200,
      l: '莱芜市',
    },
    {
      v: 371300,
      l: '临沂市',
    },
    {
      v: 371400,
      l: '德州市',
    },
    {
      v: 371500,
      l: '聊城市',
    },
    {
      v: 371600,
      l: '滨州市',
    },
    {
      v: 371700,
      l: '菏泽市',
    },
  ],
  410000: [
    {
      v: 410100,
      l: '郑州市',
    },
    {
      v: 410200,
      l: '开封市',
    },
    {
      v: 410300,
      l: '洛阳市',
    },
    {
      v: 410400,
      l: '平顶山市',
    },
    {
      v: 410500,
      l: '安阳市',
    },
    {
      v: 410600,
      l: '鹤壁市',
    },
    {
      v: 410700,
      l: '新乡市',
    },
    {
      v: 410800,
      l: '焦作市',
    },
    {
      v: 410900,
      l: '濮阳市',
    },
    {
      v: 411000,
      l: '许昌市',
    },
    {
      v: 411100,
      l: '漯河市',
    },
    {
      v: 411200,
      l: '三门峡市',
    },
    {
      v: 411300,
      l: '南阳市',
    },
    {
      v: 411400,
      l: '商丘市',
    },
    {
      v: 411500,
      l: '信阳市',
    },
    {
      v: 411600,
      l: '周口市',
    },
    {
      v: 411700,
      l: '驻马店市',
    },
    {
      v: 419000,
      l: '省直辖',
    },
  ],
  420000: [
    {
      v: 420100,
      l: '武汉市',
    },
    {
      v: 420200,
      l: '黄石市',
    },
    {
      v: 420300,
      l: '十堰市',
    },
    {
      v: 420500,
      l: '宜昌市',
    },
    {
      v: 420600,
      l: '襄阳市',
    },
    {
      v: 420700,
      l: '鄂州市',
    },
    {
      v: 420800,
      l: '荆门市',
    },
    {
      v: 420900,
      l: '孝感市',
    },
    {
      v: 421000,
      l: '荆州市',
    },
    {
      v: 421100,
      l: '黄冈市',
    },
    {
      v: 421200,
      l: '咸宁市',
    },
    {
      v: 421300,
      l: '随州市',
    },
    {
      v: 422800,
      l: '恩施土家族苗族自治州',
    },
    {
      v: 429000,
      l: '省直辖',
    },
  ],
  430000: [
    {
      v: 430100,
      l: '长沙市',
    },
    {
      v: 430200,
      l: '株洲市',
    },
    {
      v: 430300,
      l: '湘潭市',
    },
    {
      v: 430400,
      l: '衡阳市',
    },
    {
      v: 430500,
      l: '邵阳市',
    },
    {
      v: 430600,
      l: '岳阳市',
    },
    {
      v: 430700,
      l: '常德市',
    },
    {
      v: 430800,
      l: '张家界市',
    },
    {
      v: 430900,
      l: '益阳市',
    },
    {
      v: 431000,
      l: '郴州市',
    },
    {
      v: 431100,
      l: '永州市',
    },
    {
      v: 431200,
      l: '怀化市',
    },
    {
      v: 431300,
      l: '娄底市',
    },
    {
      v: 433100,
      l: '湘西土家族苗族自治州',
    },
  ],
  440000: [
    {
      v: 440100,
      l: '广州市',
    },
    {
      v: 440200,
      l: '韶关市',
    },
    {
      v: 440300,
      l: '深圳市',
    },
    {
      v: 440400,
      l: '珠海市',
    },
    {
      v: 440500,
      l: '汕头市',
    },
    {
      v: 440600,
      l: '佛山市',
    },
    {
      v: 440700,
      l: '江门市',
    },
    {
      v: 440800,
      l: '湛江市',
    },
    {
      v: 440900,
      l: '茂名市',
    },
    {
      v: 441200,
      l: '肇庆市',
    },
    {
      v: 441300,
      l: '惠州市',
    },
    {
      v: 441400,
      l: '梅州市',
    },
    {
      v: 441500,
      l: '汕尾市',
    },
    {
      v: 441600,
      l: '河源市',
    },
    {
      v: 441700,
      l: '阳江市',
    },
    {
      v: 441800,
      l: '清远市',
    },
    {
      v: 441900,
      l: '东莞市',
    },
    {
      v: 442000,
      l: '中山市',
    },
    {
      v: 445100,
      l: '潮州市',
    },
    {
      v: 445200,
      l: '揭阳市',
    },
    {
      v: 445300,
      l: '云浮市',
    },
  ],
  450000: [
    {
      v: 450100,
      l: '南宁市',
    },
    {
      v: 450200,
      l: '柳州市',
    },
    {
      v: 450300,
      l: '桂林市',
    },
    {
      v: 450400,
      l: '梧州市',
    },
    {
      v: 450500,
      l: '北海市',
    },
    {
      v: 450600,
      l: '防城港市',
    },
    {
      v: 450700,
      l: '钦州市',
    },
    {
      v: 450800,
      l: '贵港市',
    },
    {
      v: 450900,
      l: '玉林市',
    },
    {
      v: 451000,
      l: '百色市',
    },
    {
      v: 451100,
      l: '贺州市',
    },
    {
      v: 451200,
      l: '河池市',
    },
    {
      v: 451300,
      l: '来宾市',
    },
    {
      v: 451400,
      l: '崇左市',
    },
  ],
  460000: [
    {
      v: 460100,
      l: '海口市',
    },
    {
      v: 460200,
      l: '三亚市',
    },
    {
      v: 460300,
      l: '三沙市',
    },
    {
      v: 460400,
      l: '儋州市',
    },
    {
      v: 469000,
      l: '省直辖',
    },
  ],
  500000: [
    {
      v: 500100,
      l: '市辖区',
    },
    {
      v: 500200,
      l: '市辖县',
    },
  ],
  510000: [
    {
      v: 510100,
      l: '成都市',
    },
    {
      v: 510300,
      l: '自贡市',
    },
    {
      v: 510400,
      l: '攀枝花市',
    },
    {
      v: 510500,
      l: '泸州市',
    },
    {
      v: 510600,
      l: '德阳市',
    },
    {
      v: 510700,
      l: '绵阳市',
    },
    {
      v: 510800,
      l: '广元市',
    },
    {
      v: 510900,
      l: '遂宁市',
    },
    {
      v: 511000,
      l: '内江市',
    },
    {
      v: 511100,
      l: '乐山市',
    },
    {
      v: 511300,
      l: '南充市',
    },
    {
      v: 511400,
      l: '眉山市',
    },
    {
      v: 511500,
      l: '宜宾市',
    },
    {
      v: 511600,
      l: '广安市',
    },
    {
      v: 511700,
      l: '达州市',
    },
    {
      v: 511800,
      l: '雅安市',
    },
    {
      v: 511900,
      l: '巴中市',
    },
    {
      v: 512000,
      l: '资阳市',
    },
    {
      v: 513200,
      l: '阿坝藏族羌族自治州',
    },
    {
      v: 513300,
      l: '甘孜藏族自治州',
    },
    {
      v: 513400,
      l: '凉山彝族自治州',
    },
  ],
  520000: [
    {
      v: 520100,
      l: '贵阳市',
    },
    {
      v: 520200,
      l: '六盘水市',
    },
    {
      v: 520300,
      l: '遵义市',
    },
    {
      v: 520400,
      l: '安顺市',
    },
    {
      v: 520500,
      l: '毕节市',
    },
    {
      v: 520600,
      l: '铜仁市',
    },
    {
      v: 522300,
      l: '黔西南布依族苗族自治州',
    },
    {
      v: 522600,
      l: '黔东南苗族侗族自治州',
    },
    {
      v: 522700,
      l: '黔南布依族苗族自治州',
    },
  ],
  530000: [
    {
      v: 530100,
      l: '昆明市',
    },
    {
      v: 530300,
      l: '曲靖市',
    },
    {
      v: 530400,
      l: '玉溪市',
    },
    {
      v: 530500,
      l: '保山市',
    },
    {
      v: 530600,
      l: '昭通市',
    },
    {
      v: 530700,
      l: '丽江市',
    },
    {
      v: 530800,
      l: '普洱市',
    },
    {
      v: 530900,
      l: '临沧市',
    },
    {
      v: 532300,
      l: '楚雄彝族自治州',
    },
    {
      v: 532500,
      l: '红河哈尼族彝族自治州',
    },
    {
      v: 532600,
      l: '文山壮族苗族自治州',
    },
    {
      v: 532800,
      l: '西双版纳傣族自治州',
    },
    {
      v: 532900,
      l: '大理白族自治州',
    },
    {
      v: 533100,
      l: '德宏傣族景颇族自治州',
    },
    {
      v: 533300,
      l: '怒江傈僳族自治州',
    },
    {
      v: 533400,
      l: '迪庆藏族自治州',
    },
  ],
  540000: [
    {
      v: 540100,
      l: '拉萨市',
    },
    {
      v: 540200,
      l: '日喀则市',
    },
    {
      v: 540300,
      l: '昌都市',
    },
    {
      v: 540400,
      l: '林芝市',
    },
    {
      v: 540500,
      l: '山南市',
    },
    {
      v: 542400,
      l: '那曲地区',
    },
    {
      v: 542500,
      l: '阿里地区',
    },
  ],
  610000: [
    {
      v: 610100,
      l: '西安市',
    },
    {
      v: 610200,
      l: '铜川市',
    },
    {
      v: 610300,
      l: '宝鸡市',
    },
    {
      v: 610400,
      l: '咸阳市',
    },
    {
      v: 610500,
      l: '渭南市',
    },
    {
      v: 610600,
      l: '延安市',
    },
    {
      v: 610700,
      l: '汉中市',
    },
    {
      v: 610800,
      l: '榆林市',
    },
    {
      v: 610900,
      l: '安康市',
    },
    {
      v: 611000,
      l: '商洛市',
    },
  ],
  620000: [
    {
      v: 620100,
      l: '兰州市',
    },
    {
      v: 620200,
      l: '嘉峪关市',
    },
    {
      v: 620300,
      l: '金昌市',
    },
    {
      v: 620400,
      l: '白银市',
    },
    {
      v: 620500,
      l: '天水市',
    },
    {
      v: 620600,
      l: '武威市',
    },
    {
      v: 620700,
      l: '张掖市',
    },
    {
      v: 620800,
      l: '平凉市',
    },
    {
      v: 620900,
      l: '酒泉市',
    },
    {
      v: 621000,
      l: '庆阳市',
    },
    {
      v: 621100,
      l: '定西市',
    },
    {
      v: 621200,
      l: '陇南市',
    },
    {
      v: 622900,
      l: '临夏回族自治州',
    },
    {
      v: 623000,
      l: '甘南藏族自治州',
    },
  ],
  630000: [
    {
      v: 630100,
      l: '西宁市',
    },
    {
      v: 630200,
      l: '海东市',
    },
    {
      v: 632200,
      l: '海北藏族自治州',
    },
    {
      v: 632300,
      l: '黄南藏族自治州',
    },
    {
      v: 632500,
      l: '海南藏族自治州',
    },
    {
      v: 632600,
      l: '果洛藏族自治州',
    },
    {
      v: 632700,
      l: '玉树藏族自治州',
    },
    {
      v: 632800,
      l: '海西蒙古族藏族自治州',
    },
  ],
  640000: [
    {
      v: 640100,
      l: '银川市',
    },
    {
      v: 640200,
      l: '石嘴山市',
    },
    {
      v: 640300,
      l: '吴忠市',
    },
    {
      v: 640400,
      l: '固原市',
    },
    {
      v: 640500,
      l: '中卫市',
    },
  ],
  650000: [
    {
      v: 650100,
      l: '乌鲁木齐市',
    },
    {
      v: 650200,
      l: '克拉玛依市',
    },
    {
      v: 650400,
      l: '吐鲁番市',
    },
    {
      v: 652300,
      l: '昌吉回族自治州',
    },
    {
      v: 652700,
      l: '博尔塔拉蒙古自治州',
    },
    {
      v: 652800,
      l: '巴音郭楞蒙古自治州',
    },
    {
      v: 652900,
      l: '阿克苏地区',
    },
    {
      v: 653000,
      l: '克孜勒苏柯尔克孜自治州',
    },
    {
      v: 653100,
      l: '喀什地区',
    },
    {
      v: 653200,
      l: '和田地区',
    },
    {
      v: 654000,
      l: '伊犁哈萨克自治州',
    },
    {
      v: 654200,
      l: '塔城地区',
    },
    {
      v: 654300,
      l: '阿勒泰地区',
    },
    {
      v: 659000,
      l: '自治区直辖',
    },
  ],
  710000: [
    {
      v: 710100,
      l: '台北市',
    },
    {
      v: 710200,
      l: '高雄市',
    },
    {
      v: 710300,
      l: '基隆市',
    },
    {
      v: 710400,
      l: '台中市',
    },
    {
      v: 710500,
      l: '台南市',
    },
    {
      v: 710600,
      l: '新竹市',
    },
    {
      v: 710700,
      l: '嘉义市',
    },
    {
      v: 710800,
      l: '台北县(板桥市)',
    },
    {
      v: 710900,
      l: '宜兰县(宜兰市)',
    },
    {
      v: 711000,
      l: '新竹县(竹北市)',
    },
    {
      v: 711100,
      l: '桃园县(桃园市)',
    },
    {
      v: 711200,
      l: '苗栗县(苗栗市)',
    },
    {
      v: 711300,
      l: '台中县(丰原市)',
    },
    {
      v: 711400,
      l: '彰化县(彰化市)',
    },
    {
      v: 711500,
      l: '南投县(南投市)',
    },
    {
      v: 711600,
      l: '嘉义县(太保市)',
    },
    {
      v: 711700,
      l: '云林县(斗六市)',
    },
    {
      v: 711800,
      l: '台南县(新营市)',
    },
    {
      v: 711900,
      l: '高雄县(凤山市)',
    },
    {
      v: 712000,
      l: '屏东县(屏东市)',
    },
    {
      v: 712100,
      l: '台东县(台东市)',
    },
    {
      v: 712200,
      l: '花莲县(花莲市)',
    },
    {
      v: 712300,
      l: '澎湖县(马公市)',
    },
  ],
  810000: [
    {
      v: 810100,
      l: '市辖区',
    },
  ],
  820000: [
    {
      v: 820000,
      l: '澳门特别行政区',
    },
  ],
};
let distsData = {
  110100: [
    {
      v: 110101,
      l: '东城区',
    },
    {
      v: 110102,
      l: '西城区',
    },
    {
      v: 110105,
      l: '朝阳区',
    },
    {
      v: 110106,
      l: '丰台区',
    },
    {
      v: 110107,
      l: '石景山区',
    },
    {
      v: 110108,
      l: '海淀区',
    },
    {
      v: 110109,
      l: '门头沟区',
    },
    {
      v: 110111,
      l: '房山区',
    },
    {
      v: 110112,
      l: '通州区',
    },
    {
      v: 110113,
      l: '顺义区',
    },
    {
      v: 110114,
      l: '昌平区',
    },
    {
      v: 110115,
      l: '大兴区',
    },
    {
      v: 110116,
      l: '怀柔区',
    },
    {
      v: 110117,
      l: '平谷区',
    },
    {
      v: 110118,
      l: '密云区',
    },
    {
      v: 110119,
      l: '延庆区',
    },
  ],
  120100: [
    {
      v: 120101,
      l: '和平区',
    },
    {
      v: 120102,
      l: '河东区',
    },
    {
      v: 120103,
      l: '河西区',
    },
    {
      v: 120104,
      l: '南开区',
    },
    {
      v: 120105,
      l: '河北区',
    },
    {
      v: 120106,
      l: '红桥区',
    },
    {
      v: 120110,
      l: '东丽区',
    },
    {
      v: 120111,
      l: '西青区',
    },
    {
      v: 120112,
      l: '津南区',
    },
    {
      v: 120113,
      l: '北辰区',
    },
    {
      v: 120114,
      l: '武清区',
    },
    {
      v: 120115,
      l: '宝坻区',
    },
    {
      v: 120116,
      l: '滨海新区',
    },
    {
      v: 120117,
      l: '宁河区',
    },
    {
      v: 120118,
      l: '静海区',
    },
    {
      v: 120119,
      l: '蓟州区',
    },
  ],
  130100: [
    {
      v: 130102,
      l: '长安区',
    },
    {
      v: 130104,
      l: '桥西区',
    },
    {
      v: 130105,
      l: '新华区',
    },
    {
      v: 130107,
      l: '井陉矿区',
    },
    {
      v: 130108,
      l: '裕华区',
    },
    {
      v: 130109,
      l: '藁城区',
    },
    {
      v: 130110,
      l: '鹿泉区',
    },
    {
      v: 130111,
      l: '栾城区',
    },
    {
      v: 130121,
      l: '井陉县',
    },
    {
      v: 130123,
      l: '正定县',
    },
    {
      v: 130125,
      l: '行唐县',
    },
    {
      v: 130126,
      l: '灵寿县',
    },
    {
      v: 130127,
      l: '高邑县',
    },
    {
      v: 130128,
      l: '深泽县',
    },
    {
      v: 130129,
      l: '赞皇县',
    },
    {
      v: 130130,
      l: '无极县',
    },
    {
      v: 130131,
      l: '平山县',
    },
    {
      v: 130132,
      l: '元氏县',
    },
    {
      v: 130133,
      l: '赵县',
    },
    {
      v: 130181,
      l: '辛集市',
    },
    {
      v: 130183,
      l: '晋州市',
    },
    {
      v: 130184,
      l: '新乐市',
    },
  ],
  130200: [
    {
      v: 130202,
      l: '路南区',
    },
    {
      v: 130203,
      l: '路北区',
    },
    {
      v: 130204,
      l: '古冶区',
    },
    {
      v: 130205,
      l: '开平区',
    },
    {
      v: 130207,
      l: '丰南区',
    },
    {
      v: 130208,
      l: '丰润区',
    },
    {
      v: 130209,
      l: '曹妃甸区',
    },
    {
      v: 130223,
      l: '滦县',
    },
    {
      v: 130224,
      l: '滦南县',
    },
    {
      v: 130225,
      l: '乐亭县',
    },
    {
      v: 130227,
      l: '迁西县',
    },
    {
      v: 130229,
      l: '玉田县',
    },
    {
      v: 130281,
      l: '遵化市',
    },
    {
      v: 130283,
      l: '迁安市',
    },
  ],
  130300: [
    {
      v: 130302,
      l: '海港区',
    },
    {
      v: 130303,
      l: '山海关区',
    },
    {
      v: 130304,
      l: '北戴河区',
    },
    {
      v: 130306,
      l: '抚宁区',
    },
    {
      v: 130321,
      l: '青龙满族自治县',
    },
    {
      v: 130322,
      l: '昌黎县',
    },
    {
      v: 130324,
      l: '卢龙县',
    },
  ],
  130400: [
    {
      v: 130402,
      l: '邯山区',
    },
    {
      v: 130403,
      l: '丛台区',
    },
    {
      v: 130404,
      l: '复兴区',
    },
    {
      v: 130406,
      l: '峰峰矿区',
    },
    {
      v: 130407,
      l: '肥乡区',
    },
    {
      v: 130408,
      l: '永年区',
    },
    {
      v: 130423,
      l: '临漳县',
    },
    {
      v: 130424,
      l: '成安县',
    },
    {
      v: 130425,
      l: '大名县',
    },
    {
      v: 130426,
      l: '涉县',
    },
    {
      v: 130427,
      l: '磁县',
    },
    {
      v: 130430,
      l: '邱县',
    },
    {
      v: 130431,
      l: '鸡泽县',
    },
    {
      v: 130432,
      l: '广平县',
    },
    {
      v: 130433,
      l: '馆陶县',
    },
    {
      v: 130434,
      l: '魏县',
    },
    {
      v: 130435,
      l: '曲周县',
    },
    {
      v: 130481,
      l: '武安市',
    },
  ],
  130500: [
    {
      v: 130502,
      l: '桥东区',
    },
    {
      v: 130503,
      l: '桥西区',
    },
    {
      v: 130521,
      l: '邢台县',
    },
    {
      v: 130522,
      l: '临城县',
    },
    {
      v: 130523,
      l: '内丘县',
    },
    {
      v: 130524,
      l: '柏乡县',
    },
    {
      v: 130525,
      l: '隆尧县',
    },
    {
      v: 130526,
      l: '任县',
    },
    {
      v: 130527,
      l: '南和县',
    },
    {
      v: 130528,
      l: '宁晋县',
    },
    {
      v: 130529,
      l: '巨鹿县',
    },
    {
      v: 130530,
      l: '新河县',
    },
    {
      v: 130531,
      l: '广宗县',
    },
    {
      v: 130532,
      l: '平乡县',
    },
    {
      v: 130533,
      l: '威县',
    },
    {
      v: 130534,
      l: '清河县',
    },
    {
      v: 130535,
      l: '临西县',
    },
    {
      v: 130581,
      l: '南宫市',
    },
    {
      v: 130582,
      l: '沙河市',
    },
  ],
  130600: [
    {
      v: 130602,
      l: '竞秀区',
    },
    {
      v: 130606,
      l: '莲池区',
    },
    {
      v: 130607,
      l: '满城区',
    },
    {
      v: 130608,
      l: '清苑区',
    },
    {
      v: 130609,
      l: '徐水区',
    },
    {
      v: 130623,
      l: '涞水县',
    },
    {
      v: 130624,
      l: '阜平县',
    },
    {
      v: 130626,
      l: '定兴县',
    },
    {
      v: 130627,
      l: '唐县',
    },
    {
      v: 130628,
      l: '高阳县',
    },
    {
      v: 130629,
      l: '容城县',
    },
    {
      v: 130630,
      l: '涞源县',
    },
    {
      v: 130631,
      l: '望都县',
    },
    {
      v: 130632,
      l: '安新县',
    },
    {
      v: 130633,
      l: '易县',
    },
    {
      v: 130634,
      l: '曲阳县',
    },
    {
      v: 130635,
      l: '蠡县',
    },
    {
      v: 130636,
      l: '顺平县',
    },
    {
      v: 130637,
      l: '博野县',
    },
    {
      v: 130638,
      l: '雄县',
    },
    {
      v: 130681,
      l: '涿州市',
    },
    {
      v: 130682,
      l: '定州市',
    },
    {
      v: 130683,
      l: '安国市',
    },
    {
      v: 130684,
      l: '高碑店市',
    },
  ],
  130700: [
    {
      v: 130702,
      l: '桥东区',
    },
    {
      v: 130703,
      l: '桥西区',
    },
    {
      v: 130705,
      l: '宣化区',
    },
    {
      v: 130706,
      l: '下花园区',
    },
    {
      v: 130708,
      l: '万全区',
    },
    {
      v: 130709,
      l: '崇礼区',
    },
    {
      v: 130722,
      l: '张北县',
    },
    {
      v: 130723,
      l: '康保县',
    },
    {
      v: 130724,
      l: '沽源县',
    },
    {
      v: 130725,
      l: '尚义县',
    },
    {
      v: 130726,
      l: '蔚县',
    },
    {
      v: 130727,
      l: '阳原县',
    },
    {
      v: 130728,
      l: '怀安县',
    },
    {
      v: 130730,
      l: '怀来县',
    },
    {
      v: 130731,
      l: '涿鹿县',
    },
    {
      v: 130732,
      l: '赤城县',
    },
  ],
  130800: [
    {
      v: 130802,
      l: '双桥区',
    },
    {
      v: 130803,
      l: '双滦区',
    },
    {
      v: 130804,
      l: '鹰手营子矿区',
    },
    {
      v: 130821,
      l: '承德县',
    },
    {
      v: 130822,
      l: '兴隆县',
    },
    {
      v: 130881,
      l: '平泉市',
    },
    {
      v: 130824,
      l: '滦平县',
    },
    {
      v: 130825,
      l: '隆化县',
    },
    {
      v: 130826,
      l: '丰宁满族自治县',
    },
    {
      v: 130827,
      l: '宽城满族自治县',
    },
    {
      v: 130828,
      l: '围场满族蒙古族自治县',
    },
  ],
  130900: [
    {
      v: 130902,
      l: '新华区',
    },
    {
      v: 130903,
      l: '运河区',
    },
    {
      v: 130921,
      l: '沧县',
    },
    {
      v: 130922,
      l: '青县',
    },
    {
      v: 130923,
      l: '东光县',
    },
    {
      v: 130924,
      l: '海兴县',
    },
    {
      v: 130925,
      l: '盐山县',
    },
    {
      v: 130926,
      l: '肃宁县',
    },
    {
      v: 130927,
      l: '南皮县',
    },
    {
      v: 130928,
      l: '吴桥县',
    },
    {
      v: 130929,
      l: '献县',
    },
    {
      v: 130930,
      l: '孟村回族自治县',
    },
    {
      v: 130981,
      l: '泊头市',
    },
    {
      v: 130982,
      l: '任丘市',
    },
    {
      v: 130983,
      l: '黄骅市',
    },
    {
      v: 130984,
      l: '河间市',
    },
  ],
  131000: [
    {
      v: 131002,
      l: '安次区',
    },
    {
      v: 131003,
      l: '广阳区',
    },
    {
      v: 131022,
      l: '固安县',
    },
    {
      v: 131023,
      l: '永清县',
    },
    {
      v: 131024,
      l: '香河县',
    },
    {
      v: 131025,
      l: '大城县',
    },
    {
      v: 131026,
      l: '文安县',
    },
    {
      v: 131028,
      l: '大厂回族自治县',
    },
    {
      v: 131081,
      l: '霸州市',
    },
    {
      v: 131082,
      l: '三河市',
    },
  ],
  131100: [
    {
      v: 131102,
      l: '桃城区',
    },
    {
      v: 131103,
      l: '冀州区',
    },
    {
      v: 131121,
      l: '枣强县',
    },
    {
      v: 131122,
      l: '武邑县',
    },
    {
      v: 131123,
      l: '武强县',
    },
    {
      v: 131124,
      l: '饶阳县',
    },
    {
      v: 131125,
      l: '安平县',
    },
    {
      v: 131126,
      l: '故城县',
    },
    {
      v: 131127,
      l: '景县',
    },
    {
      v: 131128,
      l: '阜城县',
    },
    {
      v: 131182,
      l: '深州市',
    },
  ],
  140100: [
    {
      v: 140105,
      l: '小店区',
    },
    {
      v: 140106,
      l: '迎泽区',
    },
    {
      v: 140107,
      l: '杏花岭区',
    },
    {
      v: 140108,
      l: '尖草坪区',
    },
    {
      v: 140109,
      l: '万柏林区',
    },
    {
      v: 140110,
      l: '晋源区',
    },
    {
      v: 140121,
      l: '清徐县',
    },
    {
      v: 140122,
      l: '阳曲县',
    },
    {
      v: 140123,
      l: '娄烦县',
    },
    {
      v: 140181,
      l: '古交市',
    },
  ],
  140200: [
    {
      v: 140202,
      l: '城区',
    },
    {
      v: 140203,
      l: '矿区',
    },
    {
      v: 140211,
      l: '南郊区',
    },
    {
      v: 140212,
      l: '新荣区',
    },
    {
      v: 140221,
      l: '阳高县',
    },
    {
      v: 140222,
      l: '天镇县',
    },
    {
      v: 140223,
      l: '广灵县',
    },
    {
      v: 140224,
      l: '灵丘县',
    },
    {
      v: 140225,
      l: '浑源县',
    },
    {
      v: 140226,
      l: '左云县',
    },
    {
      v: 140227,
      l: '大同县',
    },
  ],
  140300: [
    {
      v: 140302,
      l: '城区',
    },
    {
      v: 140303,
      l: '矿区',
    },
    {
      v: 140311,
      l: '郊区',
    },
    {
      v: 140321,
      l: '平定县',
    },
    {
      v: 140322,
      l: '盂县',
    },
  ],
  140400: [
    {
      v: 140402,
      l: '城区',
    },
    {
      v: 140411,
      l: '郊区',
    },
    {
      v: 140421,
      l: '长治县',
    },
    {
      v: 140423,
      l: '襄垣县',
    },
    {
      v: 140424,
      l: '屯留县',
    },
    {
      v: 140425,
      l: '平顺县',
    },
    {
      v: 140426,
      l: '黎城县',
    },
    {
      v: 140427,
      l: '壶关县',
    },
    {
      v: 140428,
      l: '长子县',
    },
    {
      v: 140429,
      l: '武乡县',
    },
    {
      v: 140430,
      l: '沁县',
    },
    {
      v: 140431,
      l: '沁源县',
    },
    {
      v: 140481,
      l: '潞城市',
    },
  ],
  140500: [
    {
      v: 140502,
      l: '城区',
    },
    {
      v: 140521,
      l: '沁水县',
    },
    {
      v: 140522,
      l: '阳城县',
    },
    {
      v: 140524,
      l: '陵川县',
    },
    {
      v: 140525,
      l: '泽州县',
    },
    {
      v: 140581,
      l: '高平市',
    },
  ],
  140600: [
    {
      v: 140602,
      l: '朔城区',
    },
    {
      v: 140603,
      l: '平鲁区',
    },
    {
      v: 140621,
      l: '山阴县',
    },
    {
      v: 140622,
      l: '应县',
    },
    {
      v: 140623,
      l: '右玉县',
    },
    {
      v: 140624,
      l: '怀仁县',
    },
  ],
  140700: [
    {
      v: 140702,
      l: '榆次区',
    },
    {
      v: 140721,
      l: '榆社县',
    },
    {
      v: 140722,
      l: '左权县',
    },
    {
      v: 140723,
      l: '和顺县',
    },
    {
      v: 140724,
      l: '昔阳县',
    },
    {
      v: 140725,
      l: '寿阳县',
    },
    {
      v: 140726,
      l: '太谷县',
    },
    {
      v: 140727,
      l: '祁县',
    },
    {
      v: 140728,
      l: '平遥县',
    },
    {
      v: 140729,
      l: '灵石县',
    },
    {
      v: 140781,
      l: '介休市',
    },
  ],
  140800: [
    {
      v: 140802,
      l: '盐湖区',
    },
    {
      v: 140821,
      l: '临猗县',
    },
    {
      v: 140822,
      l: '万荣县',
    },
    {
      v: 140823,
      l: '闻喜县',
    },
    {
      v: 140824,
      l: '稷山县',
    },
    {
      v: 140825,
      l: '新绛县',
    },
    {
      v: 140826,
      l: '绛县',
    },
    {
      v: 140827,
      l: '垣曲县',
    },
    {
      v: 140828,
      l: '夏县',
    },
    {
      v: 140829,
      l: '平陆县',
    },
    {
      v: 140830,
      l: '芮城县',
    },
    {
      v: 140881,
      l: '永济市',
    },
    {
      v: 140882,
      l: '河津市',
    },
  ],
  140900: [
    {
      v: 140902,
      l: '忻府区',
    },
    {
      v: 140921,
      l: '定襄县',
    },
    {
      v: 140922,
      l: '五台县',
    },
    {
      v: 140923,
      l: '代县',
    },
    {
      v: 140924,
      l: '繁峙县',
    },
    {
      v: 140925,
      l: '宁武县',
    },
    {
      v: 140926,
      l: '静乐县',
    },
    {
      v: 140927,
      l: '神池县',
    },
    {
      v: 140928,
      l: '五寨县',
    },
    {
      v: 140929,
      l: '岢岚县',
    },
    {
      v: 140930,
      l: '河曲县',
    },
    {
      v: 140931,
      l: '保德县',
    },
    {
      v: 140932,
      l: '偏关县',
    },
    {
      v: 140981,
      l: '原平市',
    },
  ],
  141000: [
    {
      v: 141002,
      l: '尧都区',
    },
    {
      v: 141021,
      l: '曲沃县',
    },
    {
      v: 141022,
      l: '翼城县',
    },
    {
      v: 141023,
      l: '襄汾县',
    },
    {
      v: 141024,
      l: '洪洞县',
    },
    {
      v: 141025,
      l: '古县',
    },
    {
      v: 141026,
      l: '安泽县',
    },
    {
      v: 141027,
      l: '浮山县',
    },
    {
      v: 141028,
      l: '吉县',
    },
    {
      v: 141029,
      l: '乡宁县',
    },
    {
      v: 141030,
      l: '大宁县',
    },
    {
      v: 141031,
      l: '隰县',
    },
    {
      v: 141032,
      l: '永和县',
    },
    {
      v: 141033,
      l: '蒲县',
    },
    {
      v: 141034,
      l: '汾西县',
    },
    {
      v: 141081,
      l: '侯马市',
    },
    {
      v: 141082,
      l: '霍州市',
    },
  ],
  141100: [
    {
      v: 141102,
      l: '离石区',
    },
    {
      v: 141121,
      l: '文水县',
    },
    {
      v: 141122,
      l: '交城县',
    },
    {
      v: 141123,
      l: '兴县',
    },
    {
      v: 141124,
      l: '临县',
    },
    {
      v: 141125,
      l: '柳林县',
    },
    {
      v: 141126,
      l: '石楼县',
    },
    {
      v: 141127,
      l: '岚县',
    },
    {
      v: 141128,
      l: '方山县',
    },
    {
      v: 141129,
      l: '中阳县',
    },
    {
      v: 141130,
      l: '交口县',
    },
    {
      v: 141181,
      l: '孝义市',
    },
    {
      v: 141182,
      l: '汾阳市',
    },
  ],
  150100: [
    {
      v: 150102,
      l: '新城区',
    },
    {
      v: 150103,
      l: '回民区',
    },
    {
      v: 150104,
      l: '玉泉区',
    },
    {
      v: 150105,
      l: '赛罕区',
    },
    {
      v: 150121,
      l: '土默特左旗',
    },
    {
      v: 150122,
      l: '托克托县',
    },
    {
      v: 150123,
      l: '和林格尔县',
    },
    {
      v: 150124,
      l: '清水河县',
    },
    {
      v: 150125,
      l: '武川县',
    },
  ],
  150200: [
    {
      v: 150202,
      l: '东河区',
    },
    {
      v: 150203,
      l: '昆都仑区',
    },
    {
      v: 150204,
      l: '青山区',
    },
    {
      v: 150205,
      l: '石拐区',
    },
    {
      v: 150206,
      l: '白云鄂博矿区',
    },
    {
      v: 150207,
      l: '九原区',
    },
    {
      v: 150221,
      l: '土默特右旗',
    },
    {
      v: 150222,
      l: '固阳县',
    },
    {
      v: 150223,
      l: '达尔罕茂明安联合旗',
    },
  ],
  150300: [
    {
      v: 150302,
      l: '海勃湾区',
    },
    {
      v: 150303,
      l: '海南区',
    },
    {
      v: 150304,
      l: '乌达区',
    },
  ],
  150400: [
    {
      v: 150402,
      l: '红山区',
    },
    {
      v: 150403,
      l: '元宝山区',
    },
    {
      v: 150404,
      l: '松山区',
    },
    {
      v: 150421,
      l: '阿鲁科尔沁旗',
    },
    {
      v: 150422,
      l: '巴林左旗',
    },
    {
      v: 150423,
      l: '巴林右旗',
    },
    {
      v: 150424,
      l: '林西县',
    },
    {
      v: 150425,
      l: '克什克腾旗',
    },
    {
      v: 150426,
      l: '翁牛特旗',
    },
    {
      v: 150428,
      l: '喀喇沁旗',
    },
    {
      v: 150429,
      l: '宁城县',
    },
    {
      v: 150430,
      l: '敖汉旗',
    },
  ],
  150500: [
    {
      v: 150502,
      l: '科尔沁区',
    },
    {
      v: 150521,
      l: '科尔沁左翼中旗',
    },
    {
      v: 150522,
      l: '科尔沁左翼后旗',
    },
    {
      v: 150523,
      l: '开鲁县',
    },
    {
      v: 150524,
      l: '库伦旗',
    },
    {
      v: 150525,
      l: '奈曼旗',
    },
    {
      v: 150526,
      l: '扎鲁特旗',
    },
    {
      v: 150581,
      l: '霍林郭勒市',
    },
  ],
  150600: [
    {
      v: 150602,
      l: '东胜区',
    },
    {
      v: 150603,
      l: '康巴什区',
    },
    {
      v: 150621,
      l: '达拉特旗',
    },
    {
      v: 150622,
      l: '准格尔旗',
    },
    {
      v: 150623,
      l: '鄂托克前旗',
    },
    {
      v: 150624,
      l: '鄂托克旗',
    },
    {
      v: 150625,
      l: '杭锦旗',
    },
    {
      v: 150626,
      l: '乌审旗',
    },
    {
      v: 150627,
      l: '伊金霍洛旗',
    },
  ],
  150700: [
    {
      v: 150702,
      l: '海拉尔区',
    },
    {
      v: 150703,
      l: '扎赉诺尔区',
    },
    {
      v: 150721,
      l: '阿荣旗',
    },
    {
      v: 150722,
      l: '莫力达瓦达斡尔族自治旗',
    },
    {
      v: 150723,
      l: '鄂伦春自治旗',
    },
    {
      v: 150724,
      l: '鄂温克族自治旗',
    },
    {
      v: 150725,
      l: '陈巴尔虎旗',
    },
    {
      v: 150726,
      l: '新巴尔虎左旗',
    },
    {
      v: 150727,
      l: '新巴尔虎右旗',
    },
    {
      v: 150781,
      l: '满洲里市',
    },
    {
      v: 150782,
      l: '牙克石市',
    },
    {
      v: 150783,
      l: '扎兰屯市',
    },
    {
      v: 150784,
      l: '额尔古纳市',
    },
    {
      v: 150785,
      l: '根河市',
    },
  ],
  150800: [
    {
      v: 150802,
      l: '临河区',
    },
    {
      v: 150821,
      l: '五原县',
    },
    {
      v: 150822,
      l: '磴口县',
    },
    {
      v: 150823,
      l: '乌拉特前旗',
    },
    {
      v: 150824,
      l: '乌拉特中旗',
    },
    {
      v: 150825,
      l: '乌拉特后旗',
    },
    {
      v: 150826,
      l: '杭锦后旗',
    },
  ],
  150900: [
    {
      v: 150902,
      l: '集宁区',
    },
    {
      v: 150921,
      l: '卓资县',
    },
    {
      v: 150922,
      l: '化德县',
    },
    {
      v: 150923,
      l: '商都县',
    },
    {
      v: 150924,
      l: '兴和县',
    },
    {
      v: 150925,
      l: '凉城县',
    },
    {
      v: 150926,
      l: '察哈尔右翼前旗',
    },
    {
      v: 150927,
      l: '察哈尔右翼中旗',
    },
    {
      v: 150928,
      l: '察哈尔右翼后旗',
    },
    {
      v: 150929,
      l: '四子王旗',
    },
    {
      v: 150981,
      l: '丰镇市',
    },
  ],
  152200: [
    {
      v: 152201,
      l: '乌兰浩特市',
    },
    {
      v: 152202,
      l: '阿尔山市',
    },
    {
      v: 152221,
      l: '科尔沁右翼前旗',
    },
    {
      v: 152222,
      l: '科尔沁右翼中旗',
    },
    {
      v: 152223,
      l: '扎赉特旗',
    },
    {
      v: 152224,
      l: '突泉县',
    },
  ],
  152500: [
    {
      v: 152501,
      l: '二连浩特市',
    },
    {
      v: 152502,
      l: '锡林浩特市',
    },
    {
      v: 152522,
      l: '阿巴嘎旗',
    },
    {
      v: 152523,
      l: '苏尼特左旗',
    },
    {
      v: 152524,
      l: '苏尼特右旗',
    },
    {
      v: 152525,
      l: '东乌珠穆沁旗',
    },
    {
      v: 152526,
      l: '西乌珠穆沁旗',
    },
    {
      v: 152527,
      l: '太仆寺旗',
    },
    {
      v: 152528,
      l: '镶黄旗',
    },
    {
      v: 152529,
      l: '正镶白旗',
    },
    {
      v: 152530,
      l: '正蓝旗',
    },
    {
      v: 152531,
      l: '多伦县',
    },
  ],
  152900: [
    {
      v: 152921,
      l: '阿拉善左旗',
    },
    {
      v: 152922,
      l: '阿拉善右旗',
    },
    {
      v: 152923,
      l: '额济纳旗',
    },
  ],
  210100: [
    {
      v: 210102,
      l: '和平区',
    },
    {
      v: 210103,
      l: '沈河区',
    },
    {
      v: 210104,
      l: '大东区',
    },
    {
      v: 210105,
      l: '皇姑区',
    },
    {
      v: 210106,
      l: '铁西区',
    },
    {
      v: 210111,
      l: '苏家屯区',
    },
    {
      v: 210112,
      l: '浑南区',
    },
    {
      v: 210113,
      l: '沈北新区',
    },
    {
      v: 210114,
      l: '于洪区',
    },
    {
      v: 210115,
      l: '辽中区',
    },
    {
      v: 210123,
      l: '康平县',
    },
    {
      v: 210124,
      l: '法库县',
    },
    {
      v: 210181,
      l: '新民市',
    },
  ],
  210200: [
    {
      v: 210202,
      l: '中山区',
    },
    {
      v: 210203,
      l: '西岗区',
    },
    {
      v: 210204,
      l: '沙河口区',
    },
    {
      v: 210211,
      l: '甘井子区',
    },
    {
      v: 210212,
      l: '旅顺口区',
    },
    {
      v: 210213,
      l: '金州区',
    },
    {
      v: 210224,
      l: '长海县',
    },
    {
      v: 210281,
      l: '瓦房店市',
    },
    {
      v: 210214,
      l: '普兰店区',
    },
    {
      v: 210283,
      l: '庄河市',
    },
  ],
  210300: [
    {
      v: 210302,
      l: '铁东区',
    },
    {
      v: 210303,
      l: '铁西区',
    },
    {
      v: 210304,
      l: '立山区',
    },
    {
      v: 210311,
      l: '千山区',
    },
    {
      v: 210321,
      l: '台安县',
    },
    {
      v: 210323,
      l: '岫岩满族自治县',
    },
    {
      v: 210381,
      l: '海城市',
    },
  ],
  210400: [
    {
      v: 210402,
      l: '新抚区',
    },
    {
      v: 210403,
      l: '东洲区',
    },
    {
      v: 210404,
      l: '望花区',
    },
    {
      v: 210411,
      l: '顺城区',
    },
    {
      v: 210421,
      l: '抚顺县',
    },
    {
      v: 210422,
      l: '新宾满族自治县',
    },
    {
      v: 210423,
      l: '清原满族自治县',
    },
  ],
  210500: [
    {
      v: 210502,
      l: '平山区',
    },
    {
      v: 210503,
      l: '溪湖区',
    },
    {
      v: 210504,
      l: '明山区',
    },
    {
      v: 210505,
      l: '南芬区',
    },
    {
      v: 210521,
      l: '本溪满族自治县',
    },
    {
      v: 210522,
      l: '桓仁满族自治县',
    },
  ],
  210600: [
    {
      v: 210602,
      l: '元宝区',
    },
    {
      v: 210603,
      l: '振兴区',
    },
    {
      v: 210604,
      l: '振安区',
    },
    {
      v: 210624,
      l: '宽甸满族自治县',
    },
    {
      v: 210681,
      l: '东港市',
    },
    {
      v: 210682,
      l: '凤城市',
    },
  ],
  210700: [
    {
      v: 210702,
      l: '古塔区',
    },
    {
      v: 210703,
      l: '凌河区',
    },
    {
      v: 210711,
      l: '太和区',
    },
    {
      v: 210726,
      l: '黑山县',
    },
    {
      v: 210727,
      l: '义县',
    },
    {
      v: 210781,
      l: '凌海市',
    },
    {
      v: 210782,
      l: '北镇市',
    },
  ],
  210800: [
    {
      v: 210802,
      l: '站前区',
    },
    {
      v: 210803,
      l: '西市区',
    },
    {
      v: 210804,
      l: '鲅鱼圈区',
    },
    {
      v: 210811,
      l: '老边区',
    },
    {
      v: 210881,
      l: '盖州市',
    },
    {
      v: 210882,
      l: '大石桥市',
    },
  ],
  210900: [
    {
      v: 210902,
      l: '海州区',
    },
    {
      v: 210903,
      l: '新邱区',
    },
    {
      v: 210904,
      l: '太平区',
    },
    {
      v: 210905,
      l: '清河门区',
    },
    {
      v: 210911,
      l: '细河区',
    },
    {
      v: 210921,
      l: '阜新蒙古族自治县',
    },
    {
      v: 210922,
      l: '彰武县',
    },
  ],
  211000: [
    {
      v: 211002,
      l: '白塔区',
    },
    {
      v: 211003,
      l: '文圣区',
    },
    {
      v: 211004,
      l: '宏伟区',
    },
    {
      v: 211005,
      l: '弓长岭区',
    },
    {
      v: 211011,
      l: '太子河区',
    },
    {
      v: 211021,
      l: '辽阳县',
    },
    {
      v: 211081,
      l: '灯塔市',
    },
  ],
  211100: [
    {
      v: 211102,
      l: '双台子区',
    },
    {
      v: 211103,
      l: '兴隆台区',
    },
    {
      v: 211104,
      l: '大洼区',
    },
    {
      v: 211122,
      l: '盘山县',
    },
  ],
  211200: [
    {
      v: 211202,
      l: '银州区',
    },
    {
      v: 211204,
      l: '清河区',
    },
    {
      v: 211221,
      l: '铁岭县',
    },
    {
      v: 211223,
      l: '西丰县',
    },
    {
      v: 211224,
      l: '昌图县',
    },
    {
      v: 211281,
      l: '调兵山市',
    },
    {
      v: 211282,
      l: '开原市',
    },
  ],
  211300: [
    {
      v: 211302,
      l: '双塔区',
    },
    {
      v: 211303,
      l: '龙城区',
    },
    {
      v: 211321,
      l: '朝阳县',
    },
    {
      v: 211322,
      l: '建平县',
    },
    {
      v: 211324,
      l: '喀喇沁左翼蒙古族自治县',
    },
    {
      v: 211381,
      l: '北票市',
    },
    {
      v: 211382,
      l: '凌源市',
    },
  ],
  211400: [
    {
      v: 211402,
      l: '连山区',
    },
    {
      v: 211403,
      l: '龙港区',
    },
    {
      v: 211404,
      l: '南票区',
    },
    {
      v: 211421,
      l: '绥中县',
    },
    {
      v: 211422,
      l: '建昌县',
    },
    {
      v: 211481,
      l: '兴城市',
    },
  ],
  220100: [
    {
      v: 220102,
      l: '南关区',
    },
    {
      v: 220103,
      l: '宽城区',
    },
    {
      v: 220104,
      l: '朝阳区',
    },
    {
      v: 220105,
      l: '二道区',
    },
    {
      v: 220106,
      l: '绿园区',
    },
    {
      v: 220112,
      l: '双阳区',
    },
    {
      v: 220113,
      l: '九台区',
    },
    {
      v: 220122,
      l: '农安县',
    },
    {
      v: 220182,
      l: '榆树市',
    },
    {
      v: 220183,
      l: '德惠市',
    },
  ],
  220200: [
    {
      v: 220202,
      l: '昌邑区',
    },
    {
      v: 220203,
      l: '龙潭区',
    },
    {
      v: 220204,
      l: '船营区',
    },
    {
      v: 220211,
      l: '丰满区',
    },
    {
      v: 220221,
      l: '永吉县',
    },
    {
      v: 220281,
      l: '蛟河市',
    },
    {
      v: 220282,
      l: '桦甸市',
    },
    {
      v: 220283,
      l: '舒兰市',
    },
    {
      v: 220284,
      l: '磐石市',
    },
  ],
  220300: [
    {
      v: 220302,
      l: '铁西区',
    },
    {
      v: 220303,
      l: '铁东区',
    },
    {
      v: 220322,
      l: '梨树县',
    },
    {
      v: 220323,
      l: '伊通满族自治县',
    },
    {
      v: 220381,
      l: '公主岭市',
    },
    {
      v: 220382,
      l: '双辽市',
    },
  ],
  220400: [
    {
      v: 220402,
      l: '龙山区',
    },
    {
      v: 220403,
      l: '西安区',
    },
    {
      v: 220421,
      l: '东丰县',
    },
    {
      v: 220422,
      l: '东辽县',
    },
  ],
  220500: [
    {
      v: 220502,
      l: '东昌区',
    },
    {
      v: 220503,
      l: '二道江区',
    },
    {
      v: 220521,
      l: '通化县',
    },
    {
      v: 220523,
      l: '辉南县',
    },
    {
      v: 220524,
      l: '柳河县',
    },
    {
      v: 220581,
      l: '梅河口市',
    },
    {
      v: 220582,
      l: '集安市',
    },
  ],
  220600: [
    {
      v: 220602,
      l: '浑江区',
    },
    {
      v: 220605,
      l: '江源区',
    },
    {
      v: 220621,
      l: '抚松县',
    },
    {
      v: 220622,
      l: '靖宇县',
    },
    {
      v: 220623,
      l: '长白朝鲜族自治县',
    },
    {
      v: 220681,
      l: '临江市',
    },
  ],
  220700: [
    {
      v: 220702,
      l: '宁江区',
    },
    {
      v: 220721,
      l: '前郭尔罗斯蒙古族自治县',
    },
    {
      v: 220722,
      l: '长岭县',
    },
    {
      v: 220723,
      l: '乾安县',
    },
    {
      v: 220781,
      l: '扶余市',
    },
  ],
  220800: [
    {
      v: 220802,
      l: '洮北区',
    },
    {
      v: 220821,
      l: '镇赉县',
    },
    {
      v: 220822,
      l: '通榆县',
    },
    {
      v: 220881,
      l: '洮南市',
    },
    {
      v: 220882,
      l: '大安市',
    },
  ],
  222400: [
    {
      v: 222401,
      l: '延吉市',
    },
    {
      v: 222402,
      l: '图们市',
    },
    {
      v: 222403,
      l: '敦化市',
    },
    {
      v: 222404,
      l: '珲春市',
    },
    {
      v: 222405,
      l: '龙井市',
    },
    {
      v: 222406,
      l: '和龙市',
    },
    {
      v: 222424,
      l: '汪清县',
    },
    {
      v: 222426,
      l: '安图县',
    },
  ],
  230100: [
    {
      v: 230102,
      l: '道里区',
    },
    {
      v: 230103,
      l: '南岗区',
    },
    {
      v: 230104,
      l: '道外区',
    },
    {
      v: 230108,
      l: '平房区',
    },
    {
      v: 230109,
      l: '松北区',
    },
    {
      v: 230110,
      l: '香坊区',
    },
    {
      v: 230111,
      l: '呼兰区',
    },
    {
      v: 230112,
      l: '阿城区',
    },
    {
      v: 230113,
      l: '双城区',
    },
    {
      v: 230123,
      l: '依兰县',
    },
    {
      v: 230124,
      l: '方正县',
    },
    {
      v: 230125,
      l: '宾县',
    },
    {
      v: 230126,
      l: '巴彦县',
    },
    {
      v: 230127,
      l: '木兰县',
    },
    {
      v: 230128,
      l: '通河县',
    },
    {
      v: 230129,
      l: '延寿县',
    },
    {
      v: 230183,
      l: '尚志市',
    },
    {
      v: 230184,
      l: '五常市',
    },
  ],
  230200: [
    {
      v: 230202,
      l: '龙沙区',
    },
    {
      v: 230203,
      l: '建华区',
    },
    {
      v: 230204,
      l: '铁锋区',
    },
    {
      v: 230205,
      l: '昂昂溪区',
    },
    {
      v: 230206,
      l: '富拉尔基区',
    },
    {
      v: 230207,
      l: '碾子山区',
    },
    {
      v: 230208,
      l: '梅里斯达斡尔族区',
    },
    {
      v: 230221,
      l: '龙江县',
    },
    {
      v: 230223,
      l: '依安县',
    },
    {
      v: 230224,
      l: '泰来县',
    },
    {
      v: 230225,
      l: '甘南县',
    },
    {
      v: 230227,
      l: '富裕县',
    },
    {
      v: 230229,
      l: '克山县',
    },
    {
      v: 230230,
      l: '克东县',
    },
    {
      v: 230231,
      l: '拜泉县',
    },
    {
      v: 230281,
      l: '讷河市',
    },
  ],
  230300: [
    {
      v: 230302,
      l: '鸡冠区',
    },
    {
      v: 230303,
      l: '恒山区',
    },
    {
      v: 230304,
      l: '滴道区',
    },
    {
      v: 230305,
      l: '梨树区',
    },
    {
      v: 230306,
      l: '城子河区',
    },
    {
      v: 230307,
      l: '麻山区',
    },
    {
      v: 230321,
      l: '鸡东县',
    },
    {
      v: 230381,
      l: '虎林市',
    },
    {
      v: 230382,
      l: '密山市',
    },
  ],
  230400: [
    {
      v: 230402,
      l: '向阳区',
    },
    {
      v: 230403,
      l: '工农区',
    },
    {
      v: 230404,
      l: '南山区',
    },
    {
      v: 230405,
      l: '兴安区',
    },
    {
      v: 230406,
      l: '东山区',
    },
    {
      v: 230407,
      l: '兴山区',
    },
    {
      v: 230421,
      l: '萝北县',
    },
    {
      v: 230422,
      l: '绥滨县',
    },
  ],
  230500: [
    {
      v: 230502,
      l: '尖山区',
    },
    {
      v: 230503,
      l: '岭东区',
    },
    {
      v: 230505,
      l: '四方台区',
    },
    {
      v: 230506,
      l: '宝山区',
    },
    {
      v: 230521,
      l: '集贤县',
    },
    {
      v: 230522,
      l: '友谊县',
    },
    {
      v: 230523,
      l: '宝清县',
    },
    {
      v: 230524,
      l: '饶河县',
    },
  ],
  230600: [
    {
      v: 230602,
      l: '萨尔图区',
    },
    {
      v: 230603,
      l: '龙凤区',
    },
    {
      v: 230604,
      l: '让胡路区',
    },
    {
      v: 230605,
      l: '红岗区',
    },
    {
      v: 230606,
      l: '大同区',
    },
    {
      v: 230621,
      l: '肇州县',
    },
    {
      v: 230622,
      l: '肇源县',
    },
    {
      v: 230623,
      l: '林甸县',
    },
    {
      v: 230624,
      l: '杜尔伯特蒙古族自治县',
    },
  ],
  230700: [
    {
      v: 230702,
      l: '伊春区',
    },
    {
      v: 230703,
      l: '南岔区',
    },
    {
      v: 230704,
      l: '友好区',
    },
    {
      v: 230705,
      l: '西林区',
    },
    {
      v: 230706,
      l: '翠峦区',
    },
    {
      v: 230707,
      l: '新青区',
    },
    {
      v: 230708,
      l: '美溪区',
    },
    {
      v: 230709,
      l: '金山屯区',
    },
    {
      v: 230710,
      l: '五营区',
    },
    {
      v: 230711,
      l: '乌马河区',
    },
    {
      v: 230712,
      l: '汤旺河区',
    },
    {
      v: 230713,
      l: '带岭区',
    },
    {
      v: 230714,
      l: '乌伊岭区',
    },
    {
      v: 230715,
      l: '红星区',
    },
    {
      v: 230716,
      l: '上甘岭区',
    },
    {
      v: 230722,
      l: '嘉荫县',
    },
    {
      v: 230781,
      l: '铁力市',
    },
  ],
  230800: [
    {
      v: 230803,
      l: '向阳区',
    },
    {
      v: 230804,
      l: '前进区',
    },
    {
      v: 230805,
      l: '东风区',
    },
    {
      v: 230811,
      l: '郊区',
    },
    {
      v: 230822,
      l: '桦南县',
    },
    {
      v: 230826,
      l: '桦川县',
    },
    {
      v: 230828,
      l: '汤原县',
    },
    {
      v: 230881,
      l: '同江市',
    },
    {
      v: 230882,
      l: '富锦市',
    },
    {
      v: 230883,
      l: '抚远市',
    },
  ],
  230900: [
    {
      v: 230902,
      l: '新兴区',
    },
    {
      v: 230903,
      l: '桃山区',
    },
    {
      v: 230904,
      l: '茄子河区',
    },
    {
      v: 230921,
      l: '勃利县',
    },
  ],
  231000: [
    {
      v: 231002,
      l: '东安区',
    },
    {
      v: 231003,
      l: '阳明区',
    },
    {
      v: 231004,
      l: '爱民区',
    },
    {
      v: 231005,
      l: '西安区',
    },
    {
      v: 231025,
      l: '林口县',
    },
    {
      v: 231081,
      l: '绥芬河市',
    },
    {
      v: 231083,
      l: '海林市',
    },
    {
      v: 231084,
      l: '宁安市',
    },
    {
      v: 231085,
      l: '穆棱市',
    },
    {
      v: 231086,
      l: '东宁市',
    },
  ],
  231100: [
    {
      v: 231102,
      l: '爱辉区',
    },
    {
      v: 231121,
      l: '嫩江县',
    },
    {
      v: 231123,
      l: '逊克县',
    },
    {
      v: 231124,
      l: '孙吴县',
    },
    {
      v: 231181,
      l: '北安市',
    },
    {
      v: 231182,
      l: '五大连池市',
    },
  ],
  231200: [
    {
      v: 231202,
      l: '北林区',
    },
    {
      v: 231221,
      l: '望奎县',
    },
    {
      v: 231222,
      l: '兰西县',
    },
    {
      v: 231223,
      l: '青冈县',
    },
    {
      v: 231224,
      l: '庆安县',
    },
    {
      v: 231225,
      l: '明水县',
    },
    {
      v: 231226,
      l: '绥棱县',
    },
    {
      v: 231281,
      l: '安达市',
    },
    {
      v: 231282,
      l: '肇东市',
    },
    {
      v: 231283,
      l: '海伦市',
    },
  ],
  232700: [
    {
      v: 232721,
      l: '呼玛县',
    },
    {
      v: 232722,
      l: '塔河县',
    },
    {
      v: 232723,
      l: '漠河县',
    },
  ],
  310100: [
    {
      v: 310101,
      l: '黄浦区',
    },
    {
      v: 310104,
      l: '徐汇区',
    },
    {
      v: 310105,
      l: '长宁区',
    },
    {
      v: 310106,
      l: '静安区',
    },
    {
      v: 310107,
      l: '普陀区',
    },
    {
      v: 310109,
      l: '虹口区',
    },
    {
      v: 310110,
      l: '杨浦区',
    },
    {
      v: 310112,
      l: '闵行区',
    },
    {
      v: 310113,
      l: '宝山区',
    },
    {
      v: 310114,
      l: '嘉定区',
    },
    {
      v: 310115,
      l: '浦东新区',
    },
    {
      v: 310116,
      l: '金山区',
    },
    {
      v: 310117,
      l: '松江区',
    },
    {
      v: 310118,
      l: '青浦区',
    },
    {
      v: 310120,
      l: '奉贤区',
    },
    {
      v: 310151,
      l: '崇明区',
    },
  ],
  320100: [
    {
      v: 320102,
      l: '玄武区',
    },
    {
      v: 320104,
      l: '秦淮区',
    },
    {
      v: 320105,
      l: '建邺区',
    },
    {
      v: 320106,
      l: '鼓楼区',
    },
    {
      v: 320111,
      l: '浦口区',
    },
    {
      v: 320113,
      l: '栖霞区',
    },
    {
      v: 320114,
      l: '雨花台区',
    },
    {
      v: 320115,
      l: '江宁区',
    },
    {
      v: 320116,
      l: '六合区',
    },
    {
      v: 320117,
      l: '溧水区',
    },
    {
      v: 320118,
      l: '高淳区',
    },
  ],
  320200: [
    {
      v: 320205,
      l: '锡山区',
    },
    {
      v: 320206,
      l: '惠山区',
    },
    {
      v: 320211,
      l: '滨湖区',
    },
    {
      v: 320213,
      l: '梁溪区',
    },
    {
      v: 320214,
      l: '新吴区',
    },
    {
      v: 320281,
      l: '江阴市',
    },
    {
      v: 320282,
      l: '宜兴市',
    },
  ],
  320300: [
    {
      v: 320302,
      l: '鼓楼区',
    },
    {
      v: 320303,
      l: '云龙区',
    },
    {
      v: 320305,
      l: '贾汪区',
    },
    {
      v: 320311,
      l: '泉山区',
    },
    {
      v: 320312,
      l: '铜山区',
    },
    {
      v: 320321,
      l: '丰县',
    },
    {
      v: 320322,
      l: '沛县',
    },
    {
      v: 320324,
      l: '睢宁县',
    },
    {
      v: 320381,
      l: '新沂市',
    },
    {
      v: 320382,
      l: '邳州市',
    },
  ],
  320400: [
    {
      v: 320402,
      l: '天宁区',
    },
    {
      v: 320404,
      l: '钟楼区',
    },
    {
      v: 320411,
      l: '新北区',
    },
    {
      v: 320412,
      l: '武进区',
    },
    {
      v: 320413,
      l: '金坛区',
    },
    {
      v: 320481,
      l: '溧阳市',
    },
  ],
  320500: [
    {
      v: 320505,
      l: '虎丘区',
    },
    {
      v: 320506,
      l: '吴中区',
    },
    {
      v: 320507,
      l: '相城区',
    },
    {
      v: 320508,
      l: '姑苏区',
    },
    {
      v: 320509,
      l: '吴江区',
    },
    {
      v: 320581,
      l: '常熟市',
    },
    {
      v: 320582,
      l: '张家港市',
    },
    {
      v: 320583,
      l: '昆山市',
    },
    {
      v: 320585,
      l: '太仓市',
    },
  ],
  320600: [
    {
      v: 320602,
      l: '崇川区',
    },
    {
      v: 320611,
      l: '港闸区',
    },
    {
      v: 320612,
      l: '通州区',
    },
    {
      v: 320621,
      l: '海安县',
    },
    {
      v: 320623,
      l: '如东县',
    },
    {
      v: 320681,
      l: '启东市',
    },
    {
      v: 320682,
      l: '如皋市',
    },
    {
      v: 320684,
      l: '海门市',
    },
  ],
  320700: [
    {
      v: 320703,
      l: '连云区',
    },
    {
      v: 320706,
      l: '海州区',
    },
    {
      v: 320707,
      l: '赣榆区',
    },
    {
      v: 320722,
      l: '东海县',
    },
    {
      v: 320723,
      l: '灌云县',
    },
    {
      v: 320724,
      l: '灌南县',
    },
  ],
  320800: [
    {
      v: 320803,
      l: '淮安区',
    },
    {
      v: 320804,
      l: '淮阴区',
    },
    {
      v: 320812,
      l: '清江浦区',
    },
    {
      v: 320813,
      l: '洪泽区',
    },
    {
      v: 320826,
      l: '涟水县',
    },
    {
      v: 320830,
      l: '盱眙县',
    },
    {
      v: 320831,
      l: '金湖县',
    },
  ],
  320900: [
    {
      v: 320902,
      l: '亭湖区',
    },
    {
      v: 320903,
      l: '盐都区',
    },
    {
      v: 320904,
      l: '大丰区',
    },
    {
      v: 320921,
      l: '响水县',
    },
    {
      v: 320922,
      l: '滨海县',
    },
    {
      v: 320923,
      l: '阜宁县',
    },
    {
      v: 320924,
      l: '射阳县',
    },
    {
      v: 320925,
      l: '建湖县',
    },
    {
      v: 320981,
      l: '东台市',
    },
  ],
  321000: [
    {
      v: 321002,
      l: '广陵区',
    },
    {
      v: 321003,
      l: '邗江区',
    },
    {
      v: 321012,
      l: '江都区',
    },
    {
      v: 321023,
      l: '宝应县',
    },
    {
      v: 321081,
      l: '仪征市',
    },
    {
      v: 321084,
      l: '高邮市',
    },
  ],
  321100: [
    {
      v: 321102,
      l: '京口区',
    },
    {
      v: 321111,
      l: '润州区',
    },
    {
      v: 321112,
      l: '丹徒区',
    },
    {
      v: 321181,
      l: '丹阳市',
    },
    {
      v: 321182,
      l: '扬中市',
    },
    {
      v: 321183,
      l: '句容市',
    },
  ],
  321200: [
    {
      v: 321202,
      l: '海陵区',
    },
    {
      v: 321203,
      l: '高港区',
    },
    {
      v: 321204,
      l: '姜堰区',
    },
    {
      v: 321281,
      l: '兴化市',
    },
    {
      v: 321282,
      l: '靖江市',
    },
    {
      v: 321283,
      l: '泰兴市',
    },
  ],
  321300: [
    {
      v: 321302,
      l: '宿城区',
    },
    {
      v: 321311,
      l: '宿豫区',
    },
    {
      v: 321322,
      l: '沭阳县',
    },
    {
      v: 321323,
      l: '泗阳县',
    },
    {
      v: 321324,
      l: '泗洪县',
    },
  ],
  330100: [
    {
      v: 330102,
      l: '上城区',
    },
    {
      v: 330103,
      l: '下城区',
    },
    {
      v: 330104,
      l: '江干区',
    },
    {
      v: 330105,
      l: '拱墅区',
    },
    {
      v: 330106,
      l: '西湖区',
    },
    {
      v: 330108,
      l: '滨江区',
    },
    {
      v: 330109,
      l: '萧山区',
    },
    {
      v: 330110,
      l: '余杭区',
    },
    {
      v: 330111,
      l: '富阳区',
    },
    {
      v: 330122,
      l: '桐庐县',
    },
    {
      v: 330127,
      l: '淳安县',
    },
    {
      v: 330182,
      l: '建德市',
    },
    {
      v: 330185,
      l: '临安市',
    },
  ],
  330200: [
    {
      v: 330203,
      l: '海曙区',
    },
    {
      v: 330205,
      l: '江北区',
    },
    {
      v: 330206,
      l: '北仑区',
    },
    {
      v: 330211,
      l: '镇海区',
    },
    {
      v: 330212,
      l: '鄞州区',
    },
    {
      v: 330213,
      l: '奉化区',
    },
    {
      v: 330225,
      l: '象山县',
    },
    {
      v: 330226,
      l: '宁海县',
    },
    {
      v: 330281,
      l: '余姚市',
    },
    {
      v: 330282,
      l: '慈溪市',
    },
  ],
  330300: [
    {
      v: 330302,
      l: '鹿城区',
    },
    {
      v: 330303,
      l: '龙湾区',
    },
    {
      v: 330304,
      l: '瓯海区',
    },
    {
      v: 330305,
      l: '洞头区',
    },
    {
      v: 330324,
      l: '永嘉县',
    },
    {
      v: 330326,
      l: '平阳县',
    },
    {
      v: 330327,
      l: '苍南县',
    },
    {
      v: 330328,
      l: '文成县',
    },
    {
      v: 330329,
      l: '泰顺县',
    },
    {
      v: 330381,
      l: '瑞安市',
    },
    {
      v: 330382,
      l: '乐清市',
    },
  ],
  330400: [
    {
      v: 330402,
      l: '南湖区',
    },
    {
      v: 330411,
      l: '秀洲区',
    },
    {
      v: 330421,
      l: '嘉善县',
    },
    {
      v: 330424,
      l: '海盐县',
    },
    {
      v: 330481,
      l: '海宁市',
    },
    {
      v: 330482,
      l: '平湖市',
    },
    {
      v: 330483,
      l: '桐乡市',
    },
  ],
  330500: [
    {
      v: 330502,
      l: '吴兴区',
    },
    {
      v: 330503,
      l: '南浔区',
    },
    {
      v: 330521,
      l: '德清县',
    },
    {
      v: 330522,
      l: '长兴县',
    },
    {
      v: 330523,
      l: '安吉县',
    },
  ],
  330600: [
    {
      v: 330602,
      l: '越城区',
    },
    {
      v: 330603,
      l: '柯桥区',
    },
    {
      v: 330604,
      l: '上虞区',
    },
    {
      v: 330624,
      l: '新昌县',
    },
    {
      v: 330681,
      l: '诸暨市',
    },
    {
      v: 330683,
      l: '嵊州市',
    },
  ],
  330700: [
    {
      v: 330702,
      l: '婺城区',
    },
    {
      v: 330703,
      l: '金东区',
    },
    {
      v: 330723,
      l: '武义县',
    },
    {
      v: 330726,
      l: '浦江县',
    },
    {
      v: 330727,
      l: '磐安县',
    },
    {
      v: 330781,
      l: '兰溪市',
    },
    {
      v: 330782,
      l: '义乌市',
    },
    {
      v: 330783,
      l: '东阳市',
    },
    {
      v: 330784,
      l: '永康市',
    },
  ],
  330800: [
    {
      v: 330802,
      l: '柯城区',
    },
    {
      v: 330803,
      l: '衢江区',
    },
    {
      v: 330822,
      l: '常山县',
    },
    {
      v: 330824,
      l: '开化县',
    },
    {
      v: 330825,
      l: '龙游县',
    },
    {
      v: 330881,
      l: '江山市',
    },
  ],
  330900: [
    {
      v: 330902,
      l: '定海区',
    },
    {
      v: 330903,
      l: '普陀区',
    },
    {
      v: 330921,
      l: '岱山县',
    },
    {
      v: 330922,
      l: '嵊泗县',
    },
  ],
  331000: [
    {
      v: 331002,
      l: '椒江区',
    },
    {
      v: 331003,
      l: '黄岩区',
    },
    {
      v: 331004,
      l: '路桥区',
    },
    {
      v: 331021,
      l: '玉环县',
    },
    {
      v: 331022,
      l: '三门县',
    },
    {
      v: 331023,
      l: '天台县',
    },
    {
      v: 331024,
      l: '仙居县',
    },
    {
      v: 331081,
      l: '温岭市',
    },
    {
      v: 331082,
      l: '临海市',
    },
  ],
  331100: [
    {
      v: 331102,
      l: '莲都区',
    },
    {
      v: 331121,
      l: '青田县',
    },
    {
      v: 331122,
      l: '缙云县',
    },
    {
      v: 331123,
      l: '遂昌县',
    },
    {
      v: 331124,
      l: '松阳县',
    },
    {
      v: 331125,
      l: '云和县',
    },
    {
      v: 331126,
      l: '庆元县',
    },
    {
      v: 331127,
      l: '景宁畲族自治县',
    },
    {
      v: 331181,
      l: '龙泉市',
    },
  ],
  340100: [
    {
      v: 340102,
      l: '瑶海区',
    },
    {
      v: 340103,
      l: '庐阳区',
    },
    {
      v: 340104,
      l: '蜀山区',
    },
    {
      v: 340111,
      l: '包河区',
    },
    {
      v: 340121,
      l: '长丰县',
    },
    {
      v: 340122,
      l: '肥东县',
    },
    {
      v: 340123,
      l: '肥西县',
    },
    {
      v: 340124,
      l: '庐江县',
    },
    {
      v: 340181,
      l: '巢湖市',
    },
  ],
  340200: [
    {
      v: 340202,
      l: '镜湖区',
    },
    {
      v: 340203,
      l: '弋江区',
    },
    {
      v: 340207,
      l: '鸠江区',
    },
    {
      v: 340208,
      l: '三山区',
    },
    {
      v: 340221,
      l: '芜湖县',
    },
    {
      v: 340222,
      l: '繁昌县',
    },
    {
      v: 340223,
      l: '南陵县',
    },
    {
      v: 340225,
      l: '无为县',
    },
  ],
  340300: [
    {
      v: 340302,
      l: '龙子湖区',
    },
    {
      v: 340303,
      l: '蚌山区',
    },
    {
      v: 340304,
      l: '禹会区',
    },
    {
      v: 340311,
      l: '淮上区',
    },
    {
      v: 340321,
      l: '怀远县',
    },
    {
      v: 340322,
      l: '五河县',
    },
    {
      v: 340323,
      l: '固镇县',
    },
  ],
  340400: [
    {
      v: 340402,
      l: '大通区',
    },
    {
      v: 340403,
      l: '田家庵区',
    },
    {
      v: 340404,
      l: '谢家集区',
    },
    {
      v: 340405,
      l: '八公山区',
    },
    {
      v: 340406,
      l: '潘集区',
    },
    {
      v: 340421,
      l: '凤台县',
    },
    {
      v: 340422,
      l: '寿县',
    },
  ],
  340500: [
    {
      v: 340503,
      l: '花山区',
    },
    {
      v: 340504,
      l: '雨山区',
    },
    {
      v: 340506,
      l: '博望区',
    },
    {
      v: 340521,
      l: '当涂县',
    },
    {
      v: 340522,
      l: '含山县',
    },
    {
      v: 340523,
      l: '和县',
    },
  ],
  340600: [
    {
      v: 340602,
      l: '杜集区',
    },
    {
      v: 340603,
      l: '相山区',
    },
    {
      v: 340604,
      l: '烈山区',
    },
    {
      v: 340621,
      l: '濉溪县',
    },
  ],
  340700: [
    {
      v: 340705,
      l: '铜官区',
    },
    {
      v: 340706,
      l: '义安区',
    },
    {
      v: 340711,
      l: '郊区',
    },
    {
      v: 340722,
      l: '枞阳县',
    },
  ],
  340800: [
    {
      v: 340802,
      l: '迎江区',
    },
    {
      v: 340803,
      l: '大观区',
    },
    {
      v: 340811,
      l: '宜秀区',
    },
    {
      v: 340822,
      l: '怀宁县',
    },
    {
      v: 340824,
      l: '潜山县',
    },
    {
      v: 340825,
      l: '太湖县',
    },
    {
      v: 340826,
      l: '宿松县',
    },
    {
      v: 340827,
      l: '望江县',
    },
    {
      v: 340828,
      l: '岳西县',
    },
    {
      v: 340881,
      l: '桐城市',
    },
  ],
  341000: [
    {
      v: 341002,
      l: '屯溪区',
    },
    {
      v: 341003,
      l: '黄山区',
    },
    {
      v: 341004,
      l: '徽州区',
    },
    {
      v: 341021,
      l: '歙县',
    },
    {
      v: 341022,
      l: '休宁县',
    },
    {
      v: 341023,
      l: '黟县',
    },
    {
      v: 341024,
      l: '祁门县',
    },
  ],
  341100: [
    {
      v: 341102,
      l: '琅琊区',
    },
    {
      v: 341103,
      l: '南谯区',
    },
    {
      v: 341122,
      l: '来安县',
    },
    {
      v: 341124,
      l: '全椒县',
    },
    {
      v: 341125,
      l: '定远县',
    },
    {
      v: 341126,
      l: '凤阳县',
    },
    {
      v: 341181,
      l: '天长市',
    },
    {
      v: 341182,
      l: '明光市',
    },
  ],
  341200: [
    {
      v: 341202,
      l: '颍州区',
    },
    {
      v: 341203,
      l: '颍东区',
    },
    {
      v: 341204,
      l: '颍泉区',
    },
    {
      v: 341221,
      l: '临泉县',
    },
    {
      v: 341222,
      l: '太和县',
    },
    {
      v: 341225,
      l: '阜南县',
    },
    {
      v: 341226,
      l: '颍上县',
    },
    {
      v: 341282,
      l: '界首市',
    },
  ],
  341300: [
    {
      v: 341302,
      l: '埇桥区',
    },
    {
      v: 341321,
      l: '砀山县',
    },
    {
      v: 341322,
      l: '萧县',
    },
    {
      v: 341323,
      l: '灵璧县',
    },
    {
      v: 341324,
      l: '泗县',
    },
  ],
  341500: [
    {
      v: 341502,
      l: '金安区',
    },
    {
      v: 341503,
      l: '裕安区',
    },
    {
      v: 341504,
      l: '叶集区',
    },
    {
      v: 341522,
      l: '霍邱县',
    },
    {
      v: 341523,
      l: '舒城县',
    },
    {
      v: 341524,
      l: '金寨县',
    },
    {
      v: 341525,
      l: '霍山县',
    },
  ],
  341600: [
    {
      v: 341602,
      l: '谯城区',
    },
    {
      v: 341621,
      l: '涡阳县',
    },
    {
      v: 341622,
      l: '蒙城县',
    },
    {
      v: 341623,
      l: '利辛县',
    },
  ],
  341700: [
    {
      v: 341702,
      l: '贵池区',
    },
    {
      v: 341721,
      l: '东至县',
    },
    {
      v: 341722,
      l: '石台县',
    },
    {
      v: 341723,
      l: '青阳县',
    },
  ],
  341800: [
    {
      v: 341802,
      l: '宣州区',
    },
    {
      v: 341821,
      l: '郎溪县',
    },
    {
      v: 341822,
      l: '广德县',
    },
    {
      v: 341823,
      l: '泾县',
    },
    {
      v: 341824,
      l: '绩溪县',
    },
    {
      v: 341825,
      l: '旌德县',
    },
    {
      v: 341881,
      l: '宁国市',
    },
  ],
  350100: [
    {
      v: 350102,
      l: '鼓楼区',
    },
    {
      v: 350103,
      l: '台江区',
    },
    {
      v: 350104,
      l: '仓山区',
    },
    {
      v: 350105,
      l: '马尾区',
    },
    {
      v: 350111,
      l: '晋安区',
    },
    {
      v: 350121,
      l: '闽侯县',
    },
    {
      v: 350122,
      l: '连江县',
    },
    {
      v: 350123,
      l: '罗源县',
    },
    {
      v: 350124,
      l: '闽清县',
    },
    {
      v: 350125,
      l: '永泰县',
    },
    {
      v: 350128,
      l: '平潭县',
    },
    {
      v: 350181,
      l: '福清市',
    },
    {
      v: 350182,
      l: '长乐市',
    },
  ],
  350200: [
    {
      v: 350203,
      l: '思明区',
    },
    {
      v: 350205,
      l: '海沧区',
    },
    {
      v: 350206,
      l: '湖里区',
    },
    {
      v: 350211,
      l: '集美区',
    },
    {
      v: 350212,
      l: '同安区',
    },
    {
      v: 350213,
      l: '翔安区',
    },
  ],
  350300: [
    {
      v: 350302,
      l: '城厢区',
    },
    {
      v: 350303,
      l: '涵江区',
    },
    {
      v: 350304,
      l: '荔城区',
    },
    {
      v: 350305,
      l: '秀屿区',
    },
    {
      v: 350322,
      l: '仙游县',
    },
  ],
  350400: [
    {
      v: 350402,
      l: '梅列区',
    },
    {
      v: 350403,
      l: '三元区',
    },
    {
      v: 350421,
      l: '明溪县',
    },
    {
      v: 350423,
      l: '清流县',
    },
    {
      v: 350424,
      l: '宁化县',
    },
    {
      v: 350425,
      l: '大田县',
    },
    {
      v: 350426,
      l: '尤溪县',
    },
    {
      v: 350427,
      l: '沙县',
    },
    {
      v: 350428,
      l: '将乐县',
    },
    {
      v: 350429,
      l: '泰宁县',
    },
    {
      v: 350430,
      l: '建宁县',
    },
    {
      v: 350481,
      l: '永安市',
    },
  ],
  350500: [
    {
      v: 350502,
      l: '鲤城区',
    },
    {
      v: 350503,
      l: '丰泽区',
    },
    {
      v: 350504,
      l: '洛江区',
    },
    {
      v: 350505,
      l: '泉港区',
    },
    {
      v: 350521,
      l: '惠安县',
    },
    {
      v: 350524,
      l: '安溪县',
    },
    {
      v: 350525,
      l: '永春县',
    },
    {
      v: 350526,
      l: '德化县',
    },
    {
      v: 350527,
      l: '金门县',
    },
    {
      v: 350581,
      l: '石狮市',
    },
    {
      v: 350582,
      l: '晋江市',
    },
    {
      v: 350583,
      l: '南安市',
    },
  ],
  350600: [
    {
      v: 350602,
      l: '芗城区',
    },
    {
      v: 350603,
      l: '龙文区',
    },
    {
      v: 350622,
      l: '云霄县',
    },
    {
      v: 350623,
      l: '漳浦县',
    },
    {
      v: 350624,
      l: '诏安县',
    },
    {
      v: 350625,
      l: '长泰县',
    },
    {
      v: 350626,
      l: '东山县',
    },
    {
      v: 350627,
      l: '南靖县',
    },
    {
      v: 350628,
      l: '平和县',
    },
    {
      v: 350629,
      l: '华安县',
    },
    {
      v: 350681,
      l: '龙海市',
    },
  ],
  350700: [
    {
      v: 350702,
      l: '延平区',
    },
    {
      v: 350703,
      l: '建阳区',
    },
    {
      v: 350721,
      l: '顺昌县',
    },
    {
      v: 350722,
      l: '浦城县',
    },
    {
      v: 350723,
      l: '光泽县',
    },
    {
      v: 350724,
      l: '松溪县',
    },
    {
      v: 350725,
      l: '政和县',
    },
    {
      v: 350781,
      l: '邵武市',
    },
    {
      v: 350782,
      l: '武夷山市',
    },
    {
      v: 350783,
      l: '建瓯市',
    },
  ],
  350800: [
    {
      v: 350802,
      l: '新罗区',
    },
    {
      v: 350803,
      l: '永定区',
    },
    {
      v: 350821,
      l: '长汀县',
    },
    {
      v: 350823,
      l: '上杭县',
    },
    {
      v: 350824,
      l: '武平县',
    },
    {
      v: 350825,
      l: '连城县',
    },
    {
      v: 350881,
      l: '漳平市',
    },
  ],
  350900: [
    {
      v: 350902,
      l: '蕉城区',
    },
    {
      v: 350921,
      l: '霞浦县',
    },
    {
      v: 350922,
      l: '古田县',
    },
    {
      v: 350923,
      l: '屏南县',
    },
    {
      v: 350924,
      l: '寿宁县',
    },
    {
      v: 350925,
      l: '周宁县',
    },
    {
      v: 350926,
      l: '柘荣县',
    },
    {
      v: 350981,
      l: '福安市',
    },
    {
      v: 350982,
      l: '福鼎市',
    },
  ],
  360100: [
    {
      v: 360102,
      l: '东湖区',
    },
    {
      v: 360103,
      l: '西湖区',
    },
    {
      v: 360104,
      l: '青云谱区',
    },
    {
      v: 360105,
      l: '湾里区',
    },
    {
      v: 360111,
      l: '青山湖区',
    },
    {
      v: 360112,
      l: '新建区',
    },
    {
      v: 360121,
      l: '南昌县',
    },
    {
      v: 360123,
      l: '安义县',
    },
    {
      v: 360124,
      l: '进贤县',
    },
  ],
  360200: [
    {
      v: 360202,
      l: '昌江区',
    },
    {
      v: 360203,
      l: '珠山区',
    },
    {
      v: 360222,
      l: '浮梁县',
    },
    {
      v: 360281,
      l: '乐平市',
    },
  ],
  360300: [
    {
      v: 360302,
      l: '安源区',
    },
    {
      v: 360313,
      l: '湘东区',
    },
    {
      v: 360321,
      l: '莲花县',
    },
    {
      v: 360322,
      l: '上栗县',
    },
    {
      v: 360323,
      l: '芦溪县',
    },
  ],
  360400: [
    {
      v: 360402,
      l: '濂溪区',
    },
    {
      v: 360403,
      l: '浔阳区',
    },
    {
      v: 360421,
      l: '九江县',
    },
    {
      v: 360423,
      l: '武宁县',
    },
    {
      v: 360424,
      l: '修水县',
    },
    {
      v: 360425,
      l: '永修县',
    },
    {
      v: 360426,
      l: '德安县',
    },
    {
      v: 360483,
      l: '庐山市',
    },
    {
      v: 360428,
      l: '都昌县',
    },
    {
      v: 360429,
      l: '湖口县',
    },
    {
      v: 360430,
      l: '彭泽县',
    },
    {
      v: 360481,
      l: '瑞昌市',
    },
    {
      v: 360482,
      l: '共青城市',
    },
  ],
  360500: [
    {
      v: 360502,
      l: '渝水区',
    },
    {
      v: 360521,
      l: '分宜县',
    },
  ],
  360600: [
    {
      v: 360602,
      l: '月湖区',
    },
    {
      v: 360622,
      l: '余江县',
    },
    {
      v: 360681,
      l: '贵溪市',
    },
  ],
  360700: [
    {
      v: 360702,
      l: '章贡区',
    },
    {
      v: 360703,
      l: '南康区',
    },
    {
      v: 360704,
      l: '赣县区',
    },
    {
      v: 360722,
      l: '信丰县',
    },
    {
      v: 360723,
      l: '大余县',
    },
    {
      v: 360724,
      l: '上犹县',
    },
    {
      v: 360725,
      l: '崇义县',
    },
    {
      v: 360726,
      l: '安远县',
    },
    {
      v: 360727,
      l: '龙南县',
    },
    {
      v: 360728,
      l: '定南县',
    },
    {
      v: 360729,
      l: '全南县',
    },
    {
      v: 360730,
      l: '宁都县',
    },
    {
      v: 360731,
      l: '于都县',
    },
    {
      v: 360732,
      l: '兴国县',
    },
    {
      v: 360733,
      l: '会昌县',
    },
    {
      v: 360734,
      l: '寻乌县',
    },
    {
      v: 360735,
      l: '石城县',
    },
    {
      v: 360781,
      l: '瑞金市',
    },
  ],
  360800: [
    {
      v: 360802,
      l: '吉州区',
    },
    {
      v: 360803,
      l: '青原区',
    },
    {
      v: 360821,
      l: '吉安县',
    },
    {
      v: 360822,
      l: '吉水县',
    },
    {
      v: 360823,
      l: '峡江县',
    },
    {
      v: 360824,
      l: '新干县',
    },
    {
      v: 360825,
      l: '永丰县',
    },
    {
      v: 360826,
      l: '泰和县',
    },
    {
      v: 360827,
      l: '遂川县',
    },
    {
      v: 360828,
      l: '万安县',
    },
    {
      v: 360829,
      l: '安福县',
    },
    {
      v: 360830,
      l: '永新县',
    },
    {
      v: 360881,
      l: '井冈山市',
    },
  ],
  360900: [
    {
      v: 360902,
      l: '袁州区',
    },
    {
      v: 360921,
      l: '奉新县',
    },
    {
      v: 360922,
      l: '万载县',
    },
    {
      v: 360923,
      l: '上高县',
    },
    {
      v: 360924,
      l: '宜丰县',
    },
    {
      v: 360925,
      l: '靖安县',
    },
    {
      v: 360926,
      l: '铜鼓县',
    },
    {
      v: 360981,
      l: '丰城市',
    },
    {
      v: 360982,
      l: '樟树市',
    },
    {
      v: 360983,
      l: '高安市',
    },
  ],
  361000: [
    {
      v: 361002,
      l: '临川区',
    },
    {
      v: 361021,
      l: '南城县',
    },
    {
      v: 361022,
      l: '黎川县',
    },
    {
      v: 361023,
      l: '南丰县',
    },
    {
      v: 361024,
      l: '崇仁县',
    },
    {
      v: 361025,
      l: '乐安县',
    },
    {
      v: 361026,
      l: '宜黄县',
    },
    {
      v: 361027,
      l: '金溪县',
    },
    {
      v: 361028,
      l: '资溪县',
    },
    {
      v: 361029,
      l: '东乡区',
    },
    {
      v: 361030,
      l: '广昌县',
    },
  ],
  361100: [
    {
      v: 361102,
      l: '信州区',
    },
    {
      v: 361121,
      l: '上饶县',
    },
    {
      v: 361103,
      l: '广丰区',
    },
    {
      v: 361123,
      l: '玉山县',
    },
    {
      v: 361124,
      l: '铅山县',
    },
    {
      v: 361125,
      l: '横峰县',
    },
    {
      v: 361126,
      l: '弋阳县',
    },
    {
      v: 361127,
      l: '余干县',
    },
    {
      v: 361128,
      l: '鄱阳县',
    },
    {
      v: 361129,
      l: '万年县',
    },
    {
      v: 361130,
      l: '婺源县',
    },
    {
      v: 361181,
      l: '德兴市',
    },
  ],
  370100: [
    {
      v: 370102,
      l: '历下区',
    },
    {
      v: 370103,
      l: '市中区',
    },
    {
      v: 370104,
      l: '槐荫区',
    },
    {
      v: 370105,
      l: '天桥区',
    },
    {
      v: 370112,
      l: '历城区',
    },
    {
      v: 370113,
      l: '长清区',
    },
    {
      v: 370124,
      l: '平阴县',
    },
    {
      v: 370125,
      l: '济阳县',
    },
    {
      v: 370126,
      l: '商河县',
    },
    {
      v: 370114,
      l: '章丘区',
    },
  ],
  370200: [
    {
      v: 370202,
      l: '市南区',
    },
    {
      v: 370203,
      l: '市北区',
    },
    {
      v: 370211,
      l: '黄岛区',
    },
    {
      v: 370212,
      l: '崂山区',
    },
    {
      v: 370213,
      l: '李沧区',
    },
    {
      v: 370214,
      l: '城阳区',
    },
    {
      v: 370281,
      l: '胶州市',
    },
    {
      v: 370282,
      l: '即墨市',
    },
    {
      v: 370283,
      l: '平度市',
    },
    {
      v: 370285,
      l: '莱西市',
    },
  ],
  370300: [
    {
      v: 370302,
      l: '淄川区',
    },
    {
      v: 370303,
      l: '张店区',
    },
    {
      v: 370304,
      l: '博山区',
    },
    {
      v: 370305,
      l: '临淄区',
    },
    {
      v: 370306,
      l: '周村区',
    },
    {
      v: 370321,
      l: '桓台县',
    },
    {
      v: 370322,
      l: '高青县',
    },
    {
      v: 370323,
      l: '沂源县',
    },
  ],
  370400: [
    {
      v: 370402,
      l: '市中区',
    },
    {
      v: 370403,
      l: '薛城区',
    },
    {
      v: 370404,
      l: '峄城区',
    },
    {
      v: 370405,
      l: '台儿庄区',
    },
    {
      v: 370406,
      l: '山亭区',
    },
    {
      v: 370481,
      l: '滕州市',
    },
  ],
  370500: [
    {
      v: 370502,
      l: '东营区',
    },
    {
      v: 370503,
      l: '河口区',
    },
    {
      v: 370505,
      l: '垦利区',
    },
    {
      v: 370522,
      l: '利津县',
    },
    {
      v: 370523,
      l: '广饶县',
    },
  ],
  370600: [
    {
      v: 370602,
      l: '芝罘区',
    },
    {
      v: 370611,
      l: '福山区',
    },
    {
      v: 370612,
      l: '牟平区',
    },
    {
      v: 370613,
      l: '莱山区',
    },
    {
      v: 370634,
      l: '长岛县',
    },
    {
      v: 370681,
      l: '龙口市',
    },
    {
      v: 370682,
      l: '莱阳市',
    },
    {
      v: 370683,
      l: '莱州市',
    },
    {
      v: 370684,
      l: '蓬莱市',
    },
    {
      v: 370685,
      l: '招远市',
    },
    {
      v: 370686,
      l: '栖霞市',
    },
    {
      v: 370687,
      l: '海阳市',
    },
  ],
  370700: [
    {
      v: 370702,
      l: '潍城区',
    },
    {
      v: 370703,
      l: '寒亭区',
    },
    {
      v: 370704,
      l: '坊子区',
    },
    {
      v: 370705,
      l: '奎文区',
    },
    {
      v: 370724,
      l: '临朐县',
    },
    {
      v: 370725,
      l: '昌乐县',
    },
    {
      v: 370781,
      l: '青州市',
    },
    {
      v: 370782,
      l: '诸城市',
    },
    {
      v: 370783,
      l: '寿光市',
    },
    {
      v: 370784,
      l: '安丘市',
    },
    {
      v: 370785,
      l: '高密市',
    },
    {
      v: 370786,
      l: '昌邑市',
    },
  ],
  370800: [
    {
      v: 370811,
      l: '任城区',
    },
    {
      v: 370812,
      l: '兖州区',
    },
    {
      v: 370826,
      l: '微山县',
    },
    {
      v: 370827,
      l: '鱼台县',
    },
    {
      v: 370828,
      l: '金乡县',
    },
    {
      v: 370829,
      l: '嘉祥县',
    },
    {
      v: 370830,
      l: '汶上县',
    },
    {
      v: 370831,
      l: '泗水县',
    },
    {
      v: 370832,
      l: '梁山县',
    },
    {
      v: 370881,
      l: '曲阜市',
    },
    {
      v: 370883,
      l: '邹城市',
    },
  ],
  370900: [
    {
      v: 370902,
      l: '泰山区',
    },
    {
      v: 370911,
      l: '岱岳区',
    },
    {
      v: 370921,
      l: '宁阳县',
    },
    {
      v: 370923,
      l: '东平县',
    },
    {
      v: 370982,
      l: '新泰市',
    },
    {
      v: 370983,
      l: '肥城市',
    },
  ],
  371000: [
    {
      v: 371002,
      l: '环翠区',
    },
    {
      v: 371003,
      l: '文登区',
    },
    {
      v: 371082,
      l: '荣成市',
    },
    {
      v: 371083,
      l: '乳山市',
    },
  ],
  371100: [
    {
      v: 371102,
      l: '东港区',
    },
    {
      v: 371103,
      l: '岚山区',
    },
    {
      v: 371121,
      l: '五莲县',
    },
    {
      v: 371122,
      l: '莒县',
    },
  ],
  371200: [
    {
      v: 371202,
      l: '莱城区',
    },
    {
      v: 371203,
      l: '钢城区',
    },
  ],
  371300: [
    {
      v: 371302,
      l: '兰山区',
    },
    {
      v: 371311,
      l: '罗庄区',
    },
    {
      v: 371312,
      l: '河东区',
    },
    {
      v: 371321,
      l: '沂南县',
    },
    {
      v: 371322,
      l: '郯城县',
    },
    {
      v: 371323,
      l: '沂水县',
    },
    {
      v: 371324,
      l: '兰陵县',
    },
    {
      v: 371325,
      l: '费县',
    },
    {
      v: 371326,
      l: '平邑县',
    },
    {
      v: 371327,
      l: '莒南县',
    },
    {
      v: 371328,
      l: '蒙阴县',
    },
    {
      v: 371329,
      l: '临沭县',
    },
  ],
  371400: [
    {
      v: 371402,
      l: '德城区',
    },
    {
      v: 371403,
      l: '陵城区',
    },
    {
      v: 371422,
      l: '宁津县',
    },
    {
      v: 371423,
      l: '庆云县',
    },
    {
      v: 371424,
      l: '临邑县',
    },
    {
      v: 371425,
      l: '齐河县',
    },
    {
      v: 371426,
      l: '平原县',
    },
    {
      v: 371427,
      l: '夏津县',
    },
    {
      v: 371428,
      l: '武城县',
    },
    {
      v: 371481,
      l: '乐陵市',
    },
    {
      v: 371482,
      l: '禹城市',
    },
  ],
  371500: [
    {
      v: 371502,
      l: '东昌府区',
    },
    {
      v: 371521,
      l: '阳谷县',
    },
    {
      v: 371522,
      l: '莘县',
    },
    {
      v: 371523,
      l: '茌平县',
    },
    {
      v: 371524,
      l: '东阿县',
    },
    {
      v: 371525,
      l: '冠县',
    },
    {
      v: 371526,
      l: '高唐县',
    },
    {
      v: 371581,
      l: '临清市',
    },
  ],
  371600: [
    {
      v: 371602,
      l: '滨城区',
    },
    {
      v: 371603,
      l: '沾化区',
    },
    {
      v: 371621,
      l: '惠民县',
    },
    {
      v: 371622,
      l: '阳信县',
    },
    {
      v: 371623,
      l: '无棣县',
    },
    {
      v: 371625,
      l: '博兴县',
    },
    {
      v: 371626,
      l: '邹平县',
    },
  ],
  371700: [
    {
      v: 371702,
      l: '牡丹区',
    },
    {
      v: 371703,
      l: '定陶区',
    },
    {
      v: 371721,
      l: '曹县',
    },
    {
      v: 371722,
      l: '单县',
    },
    {
      v: 371723,
      l: '成武县',
    },
    {
      v: 371724,
      l: '巨野县',
    },
    {
      v: 371725,
      l: '郓城县',
    },
    {
      v: 371726,
      l: '鄄城县',
    },
    {
      v: 371728,
      l: '东明县',
    },
  ],
  410100: [
    {
      v: 410102,
      l: '中原区',
    },
    {
      v: 410103,
      l: '二七区',
    },
    {
      v: 410104,
      l: '管城回族区',
    },
    {
      v: 410105,
      l: '金水区',
    },
    {
      v: 410106,
      l: '上街区',
    },
    {
      v: 410108,
      l: '惠济区',
    },
    {
      v: 410122,
      l: '中牟县',
    },
    {
      v: 410181,
      l: '巩义市',
    },
    {
      v: 410182,
      l: '荥阳市',
    },
    {
      v: 410183,
      l: '新密市',
    },
    {
      v: 410184,
      l: '新郑市',
    },
    {
      v: 410185,
      l: '登封市',
    },
  ],
  410200: [
    {
      v: 410202,
      l: '龙亭区',
    },
    {
      v: 410203,
      l: '顺河回族区',
    },
    {
      v: 410204,
      l: '鼓楼区',
    },
    {
      v: 410205,
      l: '禹王台区',
    },
    {
      v: 410212,
      l: '祥符区',
    },
    {
      v: 410221,
      l: '杞县',
    },
    {
      v: 410222,
      l: '通许县',
    },
    {
      v: 410223,
      l: '尉氏县',
    },
    {
      v: 410225,
      l: '兰考县',
    },
  ],
  410300: [
    {
      v: 410302,
      l: '老城区',
    },
    {
      v: 410303,
      l: '西工区',
    },
    {
      v: 410304,
      l: '瀍河回族区',
    },
    {
      v: 410305,
      l: '涧西区',
    },
    {
      v: 410306,
      l: '吉利区',
    },
    {
      v: 410311,
      l: '洛龙区',
    },
    {
      v: 410322,
      l: '孟津县',
    },
    {
      v: 410323,
      l: '新安县',
    },
    {
      v: 410324,
      l: '栾川县',
    },
    {
      v: 410325,
      l: '嵩县',
    },
    {
      v: 410326,
      l: '汝阳县',
    },
    {
      v: 410327,
      l: '宜阳县',
    },
    {
      v: 410328,
      l: '洛宁县',
    },
    {
      v: 410329,
      l: '伊川县',
    },
    {
      v: 410381,
      l: '偃师市',
    },
  ],
  410400: [
    {
      v: 410402,
      l: '新华区',
    },
    {
      v: 410403,
      l: '卫东区',
    },
    {
      v: 410404,
      l: '石龙区',
    },
    {
      v: 410411,
      l: '湛河区',
    },
    {
      v: 410421,
      l: '宝丰县',
    },
    {
      v: 410422,
      l: '叶县',
    },
    {
      v: 410423,
      l: '鲁山县',
    },
    {
      v: 410425,
      l: '郏县',
    },
    {
      v: 410481,
      l: '舞钢市',
    },
    {
      v: 410482,
      l: '汝州市',
    },
  ],
  410500: [
    {
      v: 410502,
      l: '文峰区',
    },
    {
      v: 410503,
      l: '北关区',
    },
    {
      v: 410505,
      l: '殷都区',
    },
    {
      v: 410506,
      l: '龙安区',
    },
    {
      v: 410522,
      l: '安阳县',
    },
    {
      v: 410523,
      l: '汤阴县',
    },
    {
      v: 410526,
      l: '滑县',
    },
    {
      v: 410527,
      l: '内黄县',
    },
    {
      v: 410581,
      l: '林州市',
    },
  ],
  410600: [
    {
      v: 410602,
      l: '鹤山区',
    },
    {
      v: 410603,
      l: '山城区',
    },
    {
      v: 410611,
      l: '淇滨区',
    },
    {
      v: 410621,
      l: '浚县',
    },
    {
      v: 410622,
      l: '淇县',
    },
  ],
  410700: [
    {
      v: 410702,
      l: '红旗区',
    },
    {
      v: 410703,
      l: '卫滨区',
    },
    {
      v: 410704,
      l: '凤泉区',
    },
    {
      v: 410711,
      l: '牧野区',
    },
    {
      v: 410721,
      l: '新乡县',
    },
    {
      v: 410724,
      l: '获嘉县',
    },
    {
      v: 410725,
      l: '原阳县',
    },
    {
      v: 410726,
      l: '延津县',
    },
    {
      v: 410727,
      l: '封丘县',
    },
    {
      v: 410728,
      l: '长垣县',
    },
    {
      v: 410781,
      l: '卫辉市',
    },
    {
      v: 410782,
      l: '辉县市',
    },
  ],
  410800: [
    {
      v: 410802,
      l: '解放区',
    },
    {
      v: 410803,
      l: '中站区',
    },
    {
      v: 410804,
      l: '马村区',
    },
    {
      v: 410811,
      l: '山阳区',
    },
    {
      v: 410821,
      l: '修武县',
    },
    {
      v: 410822,
      l: '博爱县',
    },
    {
      v: 410823,
      l: '武陟县',
    },
    {
      v: 410825,
      l: '温县',
    },
    {
      v: 410882,
      l: '沁阳市',
    },
    {
      v: 410883,
      l: '孟州市',
    },
  ],
  410900: [
    {
      v: 410902,
      l: '华龙区',
    },
    {
      v: 410922,
      l: '清丰县',
    },
    {
      v: 410923,
      l: '南乐县',
    },
    {
      v: 410926,
      l: '范县',
    },
    {
      v: 410927,
      l: '台前县',
    },
    {
      v: 410928,
      l: '濮阳县',
    },
  ],
  411000: [
    {
      v: 411002,
      l: '魏都区',
    },
    {
      v: 411003,
      l: '建安区',
    },
    {
      v: 411024,
      l: '鄢陵县',
    },
    {
      v: 411025,
      l: '襄城县',
    },
    {
      v: 411081,
      l: '禹州市',
    },
    {
      v: 411082,
      l: '长葛市',
    },
  ],
  411100: [
    {
      v: 411102,
      l: '源汇区',
    },
    {
      v: 411103,
      l: '郾城区',
    },
    {
      v: 411104,
      l: '召陵区',
    },
    {
      v: 411121,
      l: '舞阳县',
    },
    {
      v: 411122,
      l: '临颍县',
    },
  ],
  411200: [
    {
      v: 411202,
      l: '湖滨区',
    },
    {
      v: 411203,
      l: '陕州区',
    },
    {
      v: 411221,
      l: '渑池县',
    },
    {
      v: 411224,
      l: '卢氏县',
    },
    {
      v: 411281,
      l: '义马市',
    },
    {
      v: 411282,
      l: '灵宝市',
    },
  ],
  411300: [
    {
      v: 411302,
      l: '宛城区',
    },
    {
      v: 411303,
      l: '卧龙区',
    },
    {
      v: 411321,
      l: '南召县',
    },
    {
      v: 411322,
      l: '方城县',
    },
    {
      v: 411323,
      l: '西峡县',
    },
    {
      v: 411324,
      l: '镇平县',
    },
    {
      v: 411325,
      l: '内乡县',
    },
    {
      v: 411326,
      l: '淅川县',
    },
    {
      v: 411327,
      l: '社旗县',
    },
    {
      v: 411328,
      l: '唐河县',
    },
    {
      v: 411329,
      l: '新野县',
    },
    {
      v: 411330,
      l: '桐柏县',
    },
    {
      v: 411381,
      l: '邓州市',
    },
  ],
  411400: [
    {
      v: 411402,
      l: '梁园区',
    },
    {
      v: 411403,
      l: '睢阳区',
    },
    {
      v: 411421,
      l: '民权县',
    },
    {
      v: 411422,
      l: '睢县',
    },
    {
      v: 411423,
      l: '宁陵县',
    },
    {
      v: 411424,
      l: '柘城县',
    },
    {
      v: 411425,
      l: '虞城县',
    },
    {
      v: 411426,
      l: '夏邑县',
    },
    {
      v: 411481,
      l: '永城市',
    },
  ],
  411500: [
    {
      v: 411502,
      l: '浉河区',
    },
    {
      v: 411503,
      l: '平桥区',
    },
    {
      v: 411521,
      l: '罗山县',
    },
    {
      v: 411522,
      l: '光山县',
    },
    {
      v: 411523,
      l: '新县',
    },
    {
      v: 411524,
      l: '商城县',
    },
    {
      v: 411525,
      l: '固始县',
    },
    {
      v: 411526,
      l: '潢川县',
    },
    {
      v: 411527,
      l: '淮滨县',
    },
    {
      v: 411528,
      l: '息县',
    },
  ],
  411600: [
    {
      v: 411602,
      l: '川汇区',
    },
    {
      v: 411621,
      l: '扶沟县',
    },
    {
      v: 411622,
      l: '西华县',
    },
    {
      v: 411623,
      l: '商水县',
    },
    {
      v: 411624,
      l: '沈丘县',
    },
    {
      v: 411625,
      l: '郸城县',
    },
    {
      v: 411626,
      l: '淮阳县',
    },
    {
      v: 411627,
      l: '太康县',
    },
    {
      v: 411628,
      l: '鹿邑县',
    },
    {
      v: 411681,
      l: '项城市',
    },
  ],
  411700: [
    {
      v: 411702,
      l: '驿城区',
    },
    {
      v: 411721,
      l: '西平县',
    },
    {
      v: 411722,
      l: '上蔡县',
    },
    {
      v: 411723,
      l: '平舆县',
    },
    {
      v: 411724,
      l: '正阳县',
    },
    {
      v: 411725,
      l: '确山县',
    },
    {
      v: 411726,
      l: '泌阳县',
    },
    {
      v: 411727,
      l: '汝南县',
    },
    {
      v: 411728,
      l: '遂平县',
    },
    {
      v: 411729,
      l: '新蔡县',
    },
  ],
  419000: [
    {
      v: 419001,
      l: '济源市',
    },
  ],
  420100: [
    {
      v: 420102,
      l: '江岸区',
    },
    {
      v: 420103,
      l: '江汉区',
    },
    {
      v: 420104,
      l: '硚口区',
    },
    {
      v: 420105,
      l: '汉阳区',
    },
    {
      v: 420106,
      l: '武昌区',
    },
    {
      v: 420107,
      l: '青山区',
    },
    {
      v: 420111,
      l: '洪山区',
    },
    {
      v: 420112,
      l: '东西湖区',
    },
    {
      v: 420113,
      l: '汉南区',
    },
    {
      v: 420114,
      l: '蔡甸区',
    },
    {
      v: 420115,
      l: '江夏区',
    },
    {
      v: 420116,
      l: '黄陂区',
    },
    {
      v: 420117,
      l: '新洲区',
    },
  ],
  420200: [
    {
      v: 420202,
      l: '黄石港区',
    },
    {
      v: 420203,
      l: '西塞山区',
    },
    {
      v: 420204,
      l: '下陆区',
    },
    {
      v: 420205,
      l: '铁山区',
    },
    {
      v: 420222,
      l: '阳新县',
    },
    {
      v: 420281,
      l: '大冶市',
    },
  ],
  420300: [
    {
      v: 420302,
      l: '茅箭区',
    },
    {
      v: 420303,
      l: '张湾区',
    },
    {
      v: 420304,
      l: '郧阳区',
    },
    {
      v: 420322,
      l: '郧西县',
    },
    {
      v: 420323,
      l: '竹山县',
    },
    {
      v: 420324,
      l: '竹溪县',
    },
    {
      v: 420325,
      l: '房县',
    },
    {
      v: 420381,
      l: '丹江口市',
    },
  ],
  420500: [
    {
      v: 420502,
      l: '西陵区',
    },
    {
      v: 420503,
      l: '伍家岗区',
    },
    {
      v: 420504,
      l: '点军区',
    },
    {
      v: 420505,
      l: '猇亭区',
    },
    {
      v: 420506,
      l: '夷陵区',
    },
    {
      v: 420525,
      l: '远安县',
    },
    {
      v: 420526,
      l: '兴山县',
    },
    {
      v: 420527,
      l: '秭归县',
    },
    {
      v: 420528,
      l: '长阳土家族自治县',
    },
    {
      v: 420529,
      l: '五峰土家族自治县',
    },
    {
      v: 420581,
      l: '宜都市',
    },
    {
      v: 420582,
      l: '当阳市',
    },
    {
      v: 420583,
      l: '枝江市',
    },
  ],
  420600: [
    {
      v: 420602,
      l: '襄城区',
    },
    {
      v: 420606,
      l: '樊城区',
    },
    {
      v: 420607,
      l: '襄州区',
    },
    {
      v: 420624,
      l: '南漳县',
    },
    {
      v: 420625,
      l: '谷城县',
    },
    {
      v: 420626,
      l: '保康县',
    },
    {
      v: 420682,
      l: '老河口市',
    },
    {
      v: 420683,
      l: '枣阳市',
    },
    {
      v: 420684,
      l: '宜城市',
    },
  ],
  420700: [
    {
      v: 420702,
      l: '梁子湖区',
    },
    {
      v: 420703,
      l: '华容区',
    },
    {
      v: 420704,
      l: '鄂城区',
    },
  ],
  420800: [
    {
      v: 420802,
      l: '东宝区',
    },
    {
      v: 420804,
      l: '掇刀区',
    },
    {
      v: 420821,
      l: '京山县',
    },
    {
      v: 420822,
      l: '沙洋县',
    },
    {
      v: 420881,
      l: '钟祥市',
    },
  ],
  420900: [
    {
      v: 420902,
      l: '孝南区',
    },
    {
      v: 420921,
      l: '孝昌县',
    },
    {
      v: 420922,
      l: '大悟县',
    },
    {
      v: 420923,
      l: '云梦县',
    },
    {
      v: 420981,
      l: '应城市',
    },
    {
      v: 420982,
      l: '安陆市',
    },
    {
      v: 420984,
      l: '汉川市',
    },
  ],
  421000: [
    {
      v: 421002,
      l: '沙市区',
    },
    {
      v: 421003,
      l: '荆州区',
    },
    {
      v: 421022,
      l: '公安县',
    },
    {
      v: 421023,
      l: '监利县',
    },
    {
      v: 421024,
      l: '江陵县',
    },
    {
      v: 421081,
      l: '石首市',
    },
    {
      v: 421083,
      l: '洪湖市',
    },
    {
      v: 421087,
      l: '松滋市',
    },
  ],
  421100: [
    {
      v: 421102,
      l: '黄州区',
    },
    {
      v: 421121,
      l: '团风县',
    },
    {
      v: 421122,
      l: '红安县',
    },
    {
      v: 421123,
      l: '罗田县',
    },
    {
      v: 421124,
      l: '英山县',
    },
    {
      v: 421125,
      l: '浠水县',
    },
    {
      v: 421126,
      l: '蕲春县',
    },
    {
      v: 421127,
      l: '黄梅县',
    },
    {
      v: 421181,
      l: '麻城市',
    },
    {
      v: 421182,
      l: '武穴市',
    },
  ],
  421200: [
    {
      v: 421202,
      l: '咸安区',
    },
    {
      v: 421221,
      l: '嘉鱼县',
    },
    {
      v: 421222,
      l: '通城县',
    },
    {
      v: 421223,
      l: '崇阳县',
    },
    {
      v: 421224,
      l: '通山县',
    },
    {
      v: 421281,
      l: '赤壁市',
    },
  ],
  421300: [
    {
      v: 421303,
      l: '曾都区',
    },
    {
      v: 421321,
      l: '随县',
    },
    {
      v: 421381,
      l: '广水市',
    },
  ],
  422800: [
    {
      v: 422801,
      l: '恩施市',
    },
    {
      v: 422802,
      l: '利川市',
    },
    {
      v: 422822,
      l: '建始县',
    },
    {
      v: 422823,
      l: '巴东县',
    },
    {
      v: 422825,
      l: '宣恩县',
    },
    {
      v: 422826,
      l: '咸丰县',
    },
    {
      v: 422827,
      l: '来凤县',
    },
    {
      v: 422828,
      l: '鹤峰县',
    },
  ],
  429000: [
    {
      v: 429004,
      l: '仙桃市',
    },
    {
      v: 429005,
      l: '潜江市',
    },
    {
      v: 429006,
      l: '天门市',
    },
    {
      v: 429021,
      l: '神农架林区',
    },
  ],
  430100: [
    {
      v: 430102,
      l: '芙蓉区',
    },
    {
      v: 430103,
      l: '天心区',
    },
    {
      v: 430104,
      l: '岳麓区',
    },
    {
      v: 430105,
      l: '开福区',
    },
    {
      v: 430111,
      l: '雨花区',
    },
    {
      v: 430112,
      l: '望城区',
    },
    {
      v: 430121,
      l: '长沙县',
    },
    {
      v: 430124,
      l: '宁乡县',
    },
    {
      v: 430181,
      l: '浏阳市',
    },
  ],
  430200: [
    {
      v: 430202,
      l: '荷塘区',
    },
    {
      v: 430203,
      l: '芦淞区',
    },
    {
      v: 430204,
      l: '石峰区',
    },
    {
      v: 430211,
      l: '天元区',
    },
    {
      v: 430221,
      l: '株洲县',
    },
    {
      v: 430223,
      l: '攸县',
    },
    {
      v: 430224,
      l: '茶陵县',
    },
    {
      v: 430225,
      l: '炎陵县',
    },
    {
      v: 430281,
      l: '醴陵市',
    },
  ],
  430300: [
    {
      v: 430302,
      l: '雨湖区',
    },
    {
      v: 430304,
      l: '岳塘区',
    },
    {
      v: 430321,
      l: '湘潭县',
    },
    {
      v: 430381,
      l: '湘乡市',
    },
    {
      v: 430382,
      l: '韶山市',
    },
  ],
  430400: [
    {
      v: 430405,
      l: '珠晖区',
    },
    {
      v: 430406,
      l: '雁峰区',
    },
    {
      v: 430407,
      l: '石鼓区',
    },
    {
      v: 430408,
      l: '蒸湘区',
    },
    {
      v: 430412,
      l: '南岳区',
    },
    {
      v: 430421,
      l: '衡阳县',
    },
    {
      v: 430422,
      l: '衡南县',
    },
    {
      v: 430423,
      l: '衡山县',
    },
    {
      v: 430424,
      l: '衡东县',
    },
    {
      v: 430426,
      l: '祁东县',
    },
    {
      v: 430481,
      l: '耒阳市',
    },
    {
      v: 430482,
      l: '常宁市',
    },
  ],
  430500: [
    {
      v: 430502,
      l: '双清区',
    },
    {
      v: 430503,
      l: '大祥区',
    },
    {
      v: 430511,
      l: '北塔区',
    },
    {
      v: 430521,
      l: '邵东县',
    },
    {
      v: 430522,
      l: '新邵县',
    },
    {
      v: 430523,
      l: '邵阳县',
    },
    {
      v: 430524,
      l: '隆回县',
    },
    {
      v: 430525,
      l: '洞口县',
    },
    {
      v: 430527,
      l: '绥宁县',
    },
    {
      v: 430528,
      l: '新宁县',
    },
    {
      v: 430529,
      l: '城步苗族自治县',
    },
    {
      v: 430581,
      l: '武冈市',
    },
  ],
  430600: [
    {
      v: 430602,
      l: '岳阳楼区',
    },
    {
      v: 430603,
      l: '云溪区',
    },
    {
      v: 430611,
      l: '君山区',
    },
    {
      v: 430621,
      l: '岳阳县',
    },
    {
      v: 430623,
      l: '华容县',
    },
    {
      v: 430624,
      l: '湘阴县',
    },
    {
      v: 430626,
      l: '平江县',
    },
    {
      v: 430681,
      l: '汨罗市',
    },
    {
      v: 430682,
      l: '临湘市',
    },
  ],
  430700: [
    {
      v: 430702,
      l: '武陵区',
    },
    {
      v: 430703,
      l: '鼎城区',
    },
    {
      v: 430721,
      l: '安乡县',
    },
    {
      v: 430722,
      l: '汉寿县',
    },
    {
      v: 430723,
      l: '澧县',
    },
    {
      v: 430724,
      l: '临澧县',
    },
    {
      v: 430725,
      l: '桃源县',
    },
    {
      v: 430726,
      l: '石门县',
    },
    {
      v: 430781,
      l: '津市市',
    },
  ],
  430800: [
    {
      v: 430802,
      l: '永定区',
    },
    {
      v: 430811,
      l: '武陵源区',
    },
    {
      v: 430821,
      l: '慈利县',
    },
    {
      v: 430822,
      l: '桑植县',
    },
  ],
  430900: [
    {
      v: 430902,
      l: '资阳区',
    },
    {
      v: 430903,
      l: '赫山区',
    },
    {
      v: 430921,
      l: '南县',
    },
    {
      v: 430922,
      l: '桃江县',
    },
    {
      v: 430923,
      l: '安化县',
    },
    {
      v: 430981,
      l: '沅江市',
    },
  ],
  431000: [
    {
      v: 431002,
      l: '北湖区',
    },
    {
      v: 431003,
      l: '苏仙区',
    },
    {
      v: 431021,
      l: '桂阳县',
    },
    {
      v: 431022,
      l: '宜章县',
    },
    {
      v: 431023,
      l: '永兴县',
    },
    {
      v: 431024,
      l: '嘉禾县',
    },
    {
      v: 431025,
      l: '临武县',
    },
    {
      v: 431026,
      l: '汝城县',
    },
    {
      v: 431027,
      l: '桂东县',
    },
    {
      v: 431028,
      l: '安仁县',
    },
    {
      v: 431081,
      l: '资兴市',
    },
  ],
  431100: [
    {
      v: 431102,
      l: '零陵区',
    },
    {
      v: 431103,
      l: '冷水滩区',
    },
    {
      v: 431121,
      l: '祁阳县',
    },
    {
      v: 431122,
      l: '东安县',
    },
    {
      v: 431123,
      l: '双牌县',
    },
    {
      v: 431124,
      l: '道县',
    },
    {
      v: 431125,
      l: '江永县',
    },
    {
      v: 431126,
      l: '宁远县',
    },
    {
      v: 431127,
      l: '蓝山县',
    },
    {
      v: 431128,
      l: '新田县',
    },
    {
      v: 431129,
      l: '江华瑶族自治县',
    },
  ],
  431200: [
    {
      v: 431202,
      l: '鹤城区',
    },
    {
      v: 431221,
      l: '中方县',
    },
    {
      v: 431222,
      l: '沅陵县',
    },
    {
      v: 431223,
      l: '辰溪县',
    },
    {
      v: 431224,
      l: '溆浦县',
    },
    {
      v: 431225,
      l: '会同县',
    },
    {
      v: 431226,
      l: '麻阳苗族自治县',
    },
    {
      v: 431227,
      l: '新晃侗族自治县',
    },
    {
      v: 431228,
      l: '芷江侗族自治县',
    },
    {
      v: 431229,
      l: '靖州苗族侗族自治县',
    },
    {
      v: 431230,
      l: '通道侗族自治县',
    },
    {
      v: 431281,
      l: '洪江市',
    },
  ],
  431300: [
    {
      v: 431302,
      l: '娄星区',
    },
    {
      v: 431321,
      l: '双峰县',
    },
    {
      v: 431322,
      l: '新化县',
    },
    {
      v: 431381,
      l: '冷水江市',
    },
    {
      v: 431382,
      l: '涟源市',
    },
  ],
  433100: [
    {
      v: 433101,
      l: '吉首市',
    },
    {
      v: 433122,
      l: '泸溪县',
    },
    {
      v: 433123,
      l: '凤凰县',
    },
    {
      v: 433124,
      l: '花垣县',
    },
    {
      v: 433125,
      l: '保靖县',
    },
    {
      v: 433126,
      l: '古丈县',
    },
    {
      v: 433127,
      l: '永顺县',
    },
    {
      v: 433130,
      l: '龙山县',
    },
  ],
  440100: [
    {
      v: 440103,
      l: '荔湾区',
    },
    {
      v: 440104,
      l: '越秀区',
    },
    {
      v: 440105,
      l: '海珠区',
    },
    {
      v: 440106,
      l: '天河区',
    },
    {
      v: 440111,
      l: '白云区',
    },
    {
      v: 440112,
      l: '黄埔区',
    },
    {
      v: 440113,
      l: '番禺区',
    },
    {
      v: 440114,
      l: '花都区',
    },
    {
      v: 440115,
      l: '南沙区',
    },
    {
      v: 440117,
      l: '从化区',
    },
    {
      v: 440118,
      l: '增城区',
    },
  ],
  440200: [
    {
      v: 440203,
      l: '武江区',
    },
    {
      v: 440204,
      l: '浈江区',
    },
    {
      v: 440205,
      l: '曲江区',
    },
    {
      v: 440222,
      l: '始兴县',
    },
    {
      v: 440224,
      l: '仁化县',
    },
    {
      v: 440229,
      l: '翁源县',
    },
    {
      v: 440232,
      l: '乳源瑶族自治县',
    },
    {
      v: 440233,
      l: '新丰县',
    },
    {
      v: 440281,
      l: '乐昌市',
    },
    {
      v: 440282,
      l: '南雄市',
    },
  ],
  440300: [
    {
      v: 440303,
      l: '罗湖区',
    },
    {
      v: 440304,
      l: '福田区',
    },
    {
      v: 440305,
      l: '南山区',
    },
    {
      v: 440306,
      l: '宝安区',
    },
    {
      v: 440307,
      l: '龙岗区',
    },
    {
      v: 440308,
      l: '盐田区',
    },
    {
      v: 440309,
      l: '龙华区',
    },
    {
      v: 440310,
      l: '坪山区',
    },
  ],
  440400: [
    {
      v: 440402,
      l: '香洲区',
    },
    {
      v: 440403,
      l: '斗门区',
    },
    {
      v: 440404,
      l: '金湾区',
    },
  ],
  440500: [
    {
      v: 440507,
      l: '龙湖区',
    },
    {
      v: 440511,
      l: '金平区',
    },
    {
      v: 440512,
      l: '濠江区',
    },
    {
      v: 440513,
      l: '潮阳区',
    },
    {
      v: 440514,
      l: '潮南区',
    },
    {
      v: 440515,
      l: '澄海区',
    },
    {
      v: 440523,
      l: '南澳县',
    },
  ],
  440600: [
    {
      v: 440604,
      l: '禅城区',
    },
    {
      v: 440605,
      l: '南海区',
    },
    {
      v: 440606,
      l: '顺德区',
    },
    {
      v: 440607,
      l: '三水区',
    },
    {
      v: 440608,
      l: '高明区',
    },
  ],
  440700: [
    {
      v: 440703,
      l: '蓬江区',
    },
    {
      v: 440704,
      l: '江海区',
    },
    {
      v: 440705,
      l: '新会区',
    },
    {
      v: 440781,
      l: '台山市',
    },
    {
      v: 440783,
      l: '开平市',
    },
    {
      v: 440784,
      l: '鹤山市',
    },
    {
      v: 440785,
      l: '恩平市',
    },
  ],
  440800: [
    {
      v: 440802,
      l: '赤坎区',
    },
    {
      v: 440803,
      l: '霞山区',
    },
    {
      v: 440804,
      l: '坡头区',
    },
    {
      v: 440811,
      l: '麻章区',
    },
    {
      v: 440823,
      l: '遂溪县',
    },
    {
      v: 440825,
      l: '徐闻县',
    },
    {
      v: 440881,
      l: '廉江市',
    },
    {
      v: 440882,
      l: '雷州市',
    },
    {
      v: 440883,
      l: '吴川市',
    },
  ],
  440900: [
    {
      v: 440902,
      l: '茂南区',
    },
    {
      v: 440904,
      l: '电白区',
    },
    {
      v: 440981,
      l: '高州市',
    },
    {
      v: 440982,
      l: '化州市',
    },
    {
      v: 440983,
      l: '信宜市',
    },
  ],
  441200: [
    {
      v: 441202,
      l: '端州区',
    },
    {
      v: 441203,
      l: '鼎湖区',
    },
    {
      v: 441204,
      l: '高要区',
    },
    {
      v: 441223,
      l: '广宁县',
    },
    {
      v: 441224,
      l: '怀集县',
    },
    {
      v: 441225,
      l: '封开县',
    },
    {
      v: 441226,
      l: '德庆县',
    },
    {
      v: 441284,
      l: '四会市',
    },
  ],
  441300: [
    {
      v: 441302,
      l: '惠城区',
    },
    {
      v: 441303,
      l: '惠阳区',
    },
    {
      v: 441322,
      l: '博罗县',
    },
    {
      v: 441323,
      l: '惠东县',
    },
    {
      v: 441324,
      l: '龙门县',
    },
  ],
  441400: [
    {
      v: 441402,
      l: '梅江区',
    },
    {
      v: 441403,
      l: '梅县区',
    },
    {
      v: 441422,
      l: '大埔县',
    },
    {
      v: 441423,
      l: '丰顺县',
    },
    {
      v: 441424,
      l: '五华县',
    },
    {
      v: 441426,
      l: '平远县',
    },
    {
      v: 441427,
      l: '蕉岭县',
    },
    {
      v: 441481,
      l: '兴宁市',
    },
  ],
  441500: [
    {
      v: 441502,
      l: '城区',
    },
    {
      v: 441521,
      l: '海丰县',
    },
    {
      v: 441523,
      l: '陆河县',
    },
    {
      v: 441581,
      l: '陆丰市',
    },
  ],
  441600: [
    {
      v: 441602,
      l: '源城区',
    },
    {
      v: 441621,
      l: '紫金县',
    },
    {
      v: 441622,
      l: '龙川县',
    },
    {
      v: 441623,
      l: '连平县',
    },
    {
      v: 441624,
      l: '和平县',
    },
    {
      v: 441625,
      l: '东源县',
    },
  ],
  441700: [
    {
      v: 441702,
      l: '江城区',
    },
    {
      v: 441704,
      l: '阳东区',
    },
    {
      v: 441721,
      l: '阳西县',
    },
    {
      v: 441781,
      l: '阳春市',
    },
  ],
  441800: [
    {
      v: 441802,
      l: '清城区',
    },
    {
      v: 441803,
      l: '清新区',
    },
    {
      v: 441821,
      l: '佛冈县',
    },
    {
      v: 441823,
      l: '阳山县',
    },
    {
      v: 441825,
      l: '连山壮族瑶族自治县',
    },
    {
      v: 441826,
      l: '连南瑶族自治县',
    },
    {
      v: 441881,
      l: '英德市',
    },
    {
      v: 441882,
      l: '连州市',
    },
  ],
  441900: [
    {
      v: 441910,
      l: '市辖区',
    },
  ],
  442000: [
    {
      v: 442010,
      l: '市辖区',
    },
  ],
  445100: [
    {
      v: 445102,
      l: '湘桥区',
    },
    {
      v: 445103,
      l: '潮安区',
    },
    {
      v: 445122,
      l: '饶平县',
    },
  ],
  445200: [
    {
      v: 445202,
      l: '榕城区',
    },
    {
      v: 445203,
      l: '揭东区',
    },
    {
      v: 445222,
      l: '揭西县',
    },
    {
      v: 445224,
      l: '惠来县',
    },
    {
      v: 445281,
      l: '普宁市',
    },
  ],
  445300: [
    {
      v: 445302,
      l: '云城区',
    },
    {
      v: 445303,
      l: '云安区',
    },
    {
      v: 445321,
      l: '新兴县',
    },
    {
      v: 445322,
      l: '郁南县',
    },
    {
      v: 445381,
      l: '罗定市',
    },
  ],
  450100: [
    {
      v: 450102,
      l: '兴宁区',
    },
    {
      v: 450103,
      l: '青秀区',
    },
    {
      v: 450105,
      l: '江南区',
    },
    {
      v: 450107,
      l: '西乡塘区',
    },
    {
      v: 450108,
      l: '良庆区',
    },
    {
      v: 450109,
      l: '邕宁区',
    },
    {
      v: 450110,
      l: '武鸣区',
    },
    {
      v: 450123,
      l: '隆安县',
    },
    {
      v: 450124,
      l: '马山县',
    },
    {
      v: 450125,
      l: '上林县',
    },
    {
      v: 450126,
      l: '宾阳县',
    },
    {
      v: 450127,
      l: '横县',
    },
  ],
  450200: [
    {
      v: 450202,
      l: '城中区',
    },
    {
      v: 450203,
      l: '鱼峰区',
    },
    {
      v: 450204,
      l: '柳南区',
    },
    {
      v: 450205,
      l: '柳北区',
    },
    {
      v: 450206,
      l: '柳江区',
    },
    {
      v: 450222,
      l: '柳城县',
    },
    {
      v: 450223,
      l: '鹿寨县',
    },
    {
      v: 450224,
      l: '融安县',
    },
    {
      v: 450225,
      l: '融水苗族自治县',
    },
    {
      v: 450226,
      l: '三江侗族自治县',
    },
  ],
  450300: [
    {
      v: 450302,
      l: '秀峰区',
    },
    {
      v: 450303,
      l: '叠彩区',
    },
    {
      v: 450304,
      l: '象山区',
    },
    {
      v: 450305,
      l: '七星区',
    },
    {
      v: 450311,
      l: '雁山区',
    },
    {
      v: 450312,
      l: '临桂区',
    },
    {
      v: 450321,
      l: '阳朔县',
    },
    {
      v: 450323,
      l: '灵川县',
    },
    {
      v: 450324,
      l: '全州县',
    },
    {
      v: 450325,
      l: '兴安县',
    },
    {
      v: 450326,
      l: '永福县',
    },
    {
      v: 450327,
      l: '灌阳县',
    },
    {
      v: 450328,
      l: '龙胜各族自治县',
    },
    {
      v: 450329,
      l: '资源县',
    },
    {
      v: 450330,
      l: '平乐县',
    },
    {
      v: 450331,
      l: '荔浦县',
    },
    {
      v: 450332,
      l: '恭城瑶族自治县',
    },
  ],
  450400: [
    {
      v: 450403,
      l: '万秀区',
    },
    {
      v: 450405,
      l: '长洲区',
    },
    {
      v: 450406,
      l: '龙圩区',
    },
    {
      v: 450421,
      l: '苍梧县',
    },
    {
      v: 450422,
      l: '藤县',
    },
    {
      v: 450423,
      l: '蒙山县',
    },
    {
      v: 450481,
      l: '岑溪市',
    },
  ],
  450500: [
    {
      v: 450502,
      l: '海城区',
    },
    {
      v: 450503,
      l: '银海区',
    },
    {
      v: 450512,
      l: '铁山港区',
    },
    {
      v: 450521,
      l: '合浦县',
    },
  ],
  450600: [
    {
      v: 450602,
      l: '港口区',
    },
    {
      v: 450603,
      l: '防城区',
    },
    {
      v: 450621,
      l: '上思县',
    },
    {
      v: 450681,
      l: '东兴市',
    },
  ],
  450700: [
    {
      v: 450702,
      l: '钦南区',
    },
    {
      v: 450703,
      l: '钦北区',
    },
    {
      v: 450721,
      l: '灵山县',
    },
    {
      v: 450722,
      l: '浦北县',
    },
  ],
  450800: [
    {
      v: 450802,
      l: '港北区',
    },
    {
      v: 450803,
      l: '港南区',
    },
    {
      v: 450804,
      l: '覃塘区',
    },
    {
      v: 450821,
      l: '平南县',
    },
    {
      v: 450881,
      l: '桂平市',
    },
  ],
  450900: [
    {
      v: 450902,
      l: '玉州区',
    },
    {
      v: 450903,
      l: '福绵区',
    },
    {
      v: 450921,
      l: '容县',
    },
    {
      v: 450922,
      l: '陆川县',
    },
    {
      v: 450923,
      l: '博白县',
    },
    {
      v: 450924,
      l: '兴业县',
    },
    {
      v: 450981,
      l: '北流市',
    },
  ],
  451000: [
    {
      v: 451002,
      l: '右江区',
    },
    {
      v: 451021,
      l: '田阳县',
    },
    {
      v: 451022,
      l: '田东县',
    },
    {
      v: 451023,
      l: '平果县',
    },
    {
      v: 451024,
      l: '德保县',
    },
    {
      v: 451026,
      l: '那坡县',
    },
    {
      v: 451027,
      l: '凌云县',
    },
    {
      v: 451028,
      l: '乐业县',
    },
    {
      v: 451029,
      l: '田林县',
    },
    {
      v: 451030,
      l: '西林县',
    },
    {
      v: 451031,
      l: '隆林各族自治县',
    },
    {
      v: 451081,
      l: '靖西市',
    },
  ],
  451100: [
    {
      v: 451102,
      l: '八步区',
    },
    {
      v: 451103,
      l: '平桂区',
    },
    {
      v: 451121,
      l: '昭平县',
    },
    {
      v: 451122,
      l: '钟山县',
    },
    {
      v: 451123,
      l: '富川瑶族自治县',
    },
  ],
  451200: [
    {
      v: 451202,
      l: '金城江区',
    },
    {
      v: 451221,
      l: '南丹县',
    },
    {
      v: 451222,
      l: '天峨县',
    },
    {
      v: 451223,
      l: '凤山县',
    },
    {
      v: 451224,
      l: '东兰县',
    },
    {
      v: 451225,
      l: '罗城仫佬族自治县',
    },
    {
      v: 451226,
      l: '环江毛南族自治县',
    },
    {
      v: 451227,
      l: '巴马瑶族自治县',
    },
    {
      v: 451228,
      l: '都安瑶族自治县',
    },
    {
      v: 451229,
      l: '大化瑶族自治县',
    },
    {
      v: 451203,
      l: '宜州区',
    },
  ],
  451300: [
    {
      v: 451302,
      l: '兴宾区',
    },
    {
      v: 451321,
      l: '忻城县',
    },
    {
      v: 451322,
      l: '象州县',
    },
    {
      v: 451323,
      l: '武宣县',
    },
    {
      v: 451324,
      l: '金秀瑶族自治县',
    },
    {
      v: 451381,
      l: '合山市',
    },
  ],
  451400: [
    {
      v: 451402,
      l: '江州区',
    },
    {
      v: 451421,
      l: '扶绥县',
    },
    {
      v: 451422,
      l: '宁明县',
    },
    {
      v: 451423,
      l: '龙州县',
    },
    {
      v: 451424,
      l: '大新县',
    },
    {
      v: 451425,
      l: '天等县',
    },
    {
      v: 451481,
      l: '凭祥市',
    },
  ],
  460100: [
    {
      v: 460105,
      l: '秀英区',
    },
    {
      v: 460106,
      l: '龙华区',
    },
    {
      v: 460107,
      l: '琼山区',
    },
    {
      v: 460108,
      l: '美兰区',
    },
  ],
  460200: [
    {
      v: 460202,
      l: '海棠区',
    },
    {
      v: 460203,
      l: '吉阳区',
    },
    {
      v: 460204,
      l: '天涯区',
    },
    {
      v: 460205,
      l: '崖州区',
    },
  ],
  460300: [],
  460400: [],
  469000: [
    {
      v: 469001,
      l: '五指山市',
    },
    {
      v: 469002,
      l: '琼海市',
    },
    {
      v: 469005,
      l: '文昌市',
    },
    {
      v: 469006,
      l: '万宁市',
    },
    {
      v: 469007,
      l: '东方市',
    },
    {
      v: 469021,
      l: '定安县',
    },
    {
      v: 469022,
      l: '屯昌县',
    },
    {
      v: 469023,
      l: '澄迈县',
    },
    {
      v: 469024,
      l: '临高县',
    },
    {
      v: 469025,
      l: '白沙黎族自治县',
    },
    {
      v: 469026,
      l: '昌江黎族自治县',
    },
    {
      v: 469027,
      l: '乐东黎族自治县',
    },
    {
      v: 469028,
      l: '陵水黎族自治县',
    },
    {
      v: 469029,
      l: '保亭黎族苗族自治县',
    },
    {
      v: 469030,
      l: '琼中黎族苗族自治县',
    },
  ],
  500100: [
    {
      v: 500101,
      l: '万州区',
    },
    {
      v: 500102,
      l: '涪陵区',
    },
    {
      v: 500103,
      l: '渝中区',
    },
    {
      v: 500104,
      l: '大渡口区',
    },
    {
      v: 500105,
      l: '江北区',
    },
    {
      v: 500106,
      l: '沙坪坝区',
    },
    {
      v: 500107,
      l: '九龙坡区',
    },
    {
      v: 500108,
      l: '南岸区',
    },
    {
      v: 500109,
      l: '北碚区',
    },
    {
      v: 500110,
      l: '綦江区',
    },
    {
      v: 500111,
      l: '大足区',
    },
    {
      v: 500112,
      l: '渝北区',
    },
    {
      v: 500113,
      l: '巴南区',
    },
    {
      v: 500114,
      l: '黔江区',
    },
    {
      v: 500115,
      l: '长寿区',
    },
    {
      v: 500116,
      l: '江津区',
    },
    {
      v: 500117,
      l: '合川区',
    },
    {
      v: 500118,
      l: '永川区',
    },
    {
      v: 500119,
      l: '南川区',
    },
    {
      v: 500120,
      l: '璧山区',
    },
    {
      v: 500151,
      l: '铜梁区',
    },
    {
      v: 500152,
      l: '潼南区',
    },
    {
      v: 500153,
      l: '荣昌区',
    },
    {
      v: 500154,
      l: '开州区',
    },
    {
      v: 500155,
      l: '梁平区',
    },
    {
      v: 500156,
      l: '武隆区',
    },
  ],
  500200: [
    {
      v: 500229,
      l: '城口县',
    },
    {
      v: 500230,
      l: '丰都县',
    },
    {
      v: 500231,
      l: '垫江县',
    },
    {
      v: 500233,
      l: '忠县',
    },
    {
      v: 500235,
      l: '云阳县',
    },
    {
      v: 500236,
      l: '奉节县',
    },
    {
      v: 500237,
      l: '巫山县',
    },
    {
      v: 500238,
      l: '巫溪县',
    },
    {
      v: 500240,
      l: '石柱土家族自治县',
    },
    {
      v: 500241,
      l: '秀山土家族苗族自治县',
    },
    {
      v: 500242,
      l: '酉阳土家族苗族自治县',
    },
    {
      v: 500243,
      l: '彭水苗族土家族自治县',
    },
  ],
  510100: [
    {
      v: 510104,
      l: '锦江区',
    },
    {
      v: 510105,
      l: '青羊区',
    },
    {
      v: 510106,
      l: '金牛区',
    },
    {
      v: 510107,
      l: '武侯区',
    },
    {
      v: 510108,
      l: '成华区',
    },
    {
      v: 510112,
      l: '龙泉驿区',
    },
    {
      v: 510113,
      l: '青白江区',
    },
    {
      v: 510114,
      l: '新都区',
    },
    {
      v: 510115,
      l: '温江区',
    },
    {
      v: 510116,
      l: '双流区',
    },
    {
      v: 510121,
      l: '金堂县',
    },
    {
      v: 510117,
      l: '郫都区',
    },
    {
      v: 510129,
      l: '大邑县',
    },
    {
      v: 510131,
      l: '蒲江县',
    },
    {
      v: 510132,
      l: '新津县',
    },
    {
      v: 510181,
      l: '都江堰市',
    },
    {
      v: 510182,
      l: '彭州市',
    },
    {
      v: 510183,
      l: '邛崃市',
    },
    {
      v: 510184,
      l: '崇州市',
    },
    {
      v: 510185,
      l: '简阳市',
    },
  ],
  510300: [
    {
      v: 510302,
      l: '自流井区',
    },
    {
      v: 510303,
      l: '贡井区',
    },
    {
      v: 510304,
      l: '大安区',
    },
    {
      v: 510311,
      l: '沿滩区',
    },
    {
      v: 510321,
      l: '荣县',
    },
    {
      v: 510322,
      l: '富顺县',
    },
  ],
  510400: [
    {
      v: 510402,
      l: '东区',
    },
    {
      v: 510403,
      l: '西区',
    },
    {
      v: 510411,
      l: '仁和区',
    },
    {
      v: 510421,
      l: '米易县',
    },
    {
      v: 510422,
      l: '盐边县',
    },
  ],
  510500: [
    {
      v: 510502,
      l: '江阳区',
    },
    {
      v: 510503,
      l: '纳溪区',
    },
    {
      v: 510504,
      l: '龙马潭区',
    },
    {
      v: 510521,
      l: '泸县',
    },
    {
      v: 510522,
      l: '合江县',
    },
    {
      v: 510524,
      l: '叙永县',
    },
    {
      v: 510525,
      l: '古蔺县',
    },
  ],
  510600: [
    {
      v: 510603,
      l: '旌阳区',
    },
    {
      v: 510623,
      l: '中江县',
    },
    {
      v: 510626,
      l: '罗江县',
    },
    {
      v: 510681,
      l: '广汉市',
    },
    {
      v: 510682,
      l: '什邡市',
    },
    {
      v: 510683,
      l: '绵竹市',
    },
  ],
  510700: [
    {
      v: 510703,
      l: '涪城区',
    },
    {
      v: 510704,
      l: '游仙区',
    },
    {
      v: 510705,
      l: '安州区',
    },
    {
      v: 510722,
      l: '三台县',
    },
    {
      v: 510723,
      l: '盐亭县',
    },
    {
      v: 510725,
      l: '梓潼县',
    },
    {
      v: 510726,
      l: '北川羌族自治县',
    },
    {
      v: 510727,
      l: '平武县',
    },
    {
      v: 510781,
      l: '江油市',
    },
  ],
  510800: [
    {
      v: 510802,
      l: '利州区',
    },
    {
      v: 510811,
      l: '昭化区',
    },
    {
      v: 510812,
      l: '朝天区',
    },
    {
      v: 510821,
      l: '旺苍县',
    },
    {
      v: 510822,
      l: '青川县',
    },
    {
      v: 510823,
      l: '剑阁县',
    },
    {
      v: 510824,
      l: '苍溪县',
    },
  ],
  510900: [
    {
      v: 510903,
      l: '船山区',
    },
    {
      v: 510904,
      l: '安居区',
    },
    {
      v: 510921,
      l: '蓬溪县',
    },
    {
      v: 510922,
      l: '射洪县',
    },
    {
      v: 510923,
      l: '大英县',
    },
  ],
  511000: [
    {
      v: 511002,
      l: '市中区',
    },
    {
      v: 511011,
      l: '东兴区',
    },
    {
      v: 511024,
      l: '威远县',
    },
    {
      v: 511025,
      l: '资中县',
    },
    {
      v: 511083,
      l: '隆昌市',
    },
  ],
  511100: [
    {
      v: 511102,
      l: '市中区',
    },
    {
      v: 511111,
      l: '沙湾区',
    },
    {
      v: 511112,
      l: '五通桥区',
    },
    {
      v: 511113,
      l: '金口河区',
    },
    {
      v: 511123,
      l: '犍为县',
    },
    {
      v: 511124,
      l: '井研县',
    },
    {
      v: 511126,
      l: '夹江县',
    },
    {
      v: 511129,
      l: '沐川县',
    },
    {
      v: 511132,
      l: '峨边彝族自治县',
    },
    {
      v: 511133,
      l: '马边彝族自治县',
    },
    {
      v: 511181,
      l: '峨眉山市',
    },
  ],
  511300: [
    {
      v: 511302,
      l: '顺庆区',
    },
    {
      v: 511303,
      l: '高坪区',
    },
    {
      v: 511304,
      l: '嘉陵区',
    },
    {
      v: 511321,
      l: '南部县',
    },
    {
      v: 511322,
      l: '营山县',
    },
    {
      v: 511323,
      l: '蓬安县',
    },
    {
      v: 511324,
      l: '仪陇县',
    },
    {
      v: 511325,
      l: '西充县',
    },
    {
      v: 511381,
      l: '阆中市',
    },
  ],
  511400: [
    {
      v: 511402,
      l: '东坡区',
    },
    {
      v: 511403,
      l: '彭山区',
    },
    {
      v: 511421,
      l: '仁寿县',
    },
    {
      v: 511423,
      l: '洪雅县',
    },
    {
      v: 511424,
      l: '丹棱县',
    },
    {
      v: 511425,
      l: '青神县',
    },
  ],
  511500: [
    {
      v: 511502,
      l: '翠屏区',
    },
    {
      v: 511503,
      l: '南溪区',
    },
    {
      v: 511521,
      l: '宜宾县',
    },
    {
      v: 511523,
      l: '江安县',
    },
    {
      v: 511524,
      l: '长宁县',
    },
    {
      v: 511525,
      l: '高县',
    },
    {
      v: 511526,
      l: '珙县',
    },
    {
      v: 511527,
      l: '筠连县',
    },
    {
      v: 511528,
      l: '兴文县',
    },
    {
      v: 511529,
      l: '屏山县',
    },
  ],
  511600: [
    {
      v: 511602,
      l: '广安区',
    },
    {
      v: 511603,
      l: '前锋区',
    },
    {
      v: 511621,
      l: '岳池县',
    },
    {
      v: 511622,
      l: '武胜县',
    },
    {
      v: 511623,
      l: '邻水县',
    },
    {
      v: 511681,
      l: '华蓥市',
    },
  ],
  511700: [
    {
      v: 511702,
      l: '通川区',
    },
    {
      v: 511703,
      l: '达川区',
    },
    {
      v: 511722,
      l: '宣汉县',
    },
    {
      v: 511723,
      l: '开江县',
    },
    {
      v: 511724,
      l: '大竹县',
    },
    {
      v: 511725,
      l: '渠县',
    },
    {
      v: 511781,
      l: '万源市',
    },
  ],
  511800: [
    {
      v: 511802,
      l: '雨城区',
    },
    {
      v: 511803,
      l: '名山区',
    },
    {
      v: 511822,
      l: '荥经县',
    },
    {
      v: 511823,
      l: '汉源县',
    },
    {
      v: 511824,
      l: '石棉县',
    },
    {
      v: 511825,
      l: '天全县',
    },
    {
      v: 511826,
      l: '芦山县',
    },
    {
      v: 511827,
      l: '宝兴县',
    },
  ],
  511900: [
    {
      v: 511902,
      l: '巴州区',
    },
    {
      v: 511903,
      l: '恩阳区',
    },
    {
      v: 511921,
      l: '通江县',
    },
    {
      v: 511922,
      l: '南江县',
    },
    {
      v: 511923,
      l: '平昌县',
    },
  ],
  512000: [
    {
      v: 512002,
      l: '雁江区',
    },
    {
      v: 512021,
      l: '安岳县',
    },
    {
      v: 512022,
      l: '乐至县',
    },
  ],
  513200: [
    {
      v: 513201,
      l: '马尔康市',
    },
    {
      v: 513221,
      l: '汶川县',
    },
    {
      v: 513222,
      l: '理县',
    },
    {
      v: 513223,
      l: '茂县',
    },
    {
      v: 513224,
      l: '松潘县',
    },
    {
      v: 513225,
      l: '九寨沟县',
    },
    {
      v: 513226,
      l: '金川县',
    },
    {
      v: 513227,
      l: '小金县',
    },
    {
      v: 513228,
      l: '黑水县',
    },
    {
      v: 513230,
      l: '壤塘县',
    },
    {
      v: 513231,
      l: '阿坝县',
    },
    {
      v: 513232,
      l: '若尔盖县',
    },
    {
      v: 513233,
      l: '红原县',
    },
  ],
  513300: [
    {
      v: 513301,
      l: '康定市',
    },
    {
      v: 513322,
      l: '泸定县',
    },
    {
      v: 513323,
      l: '丹巴县',
    },
    {
      v: 513324,
      l: '九龙县',
    },
    {
      v: 513325,
      l: '雅江县',
    },
    {
      v: 513326,
      l: '道孚县',
    },
    {
      v: 513327,
      l: '炉霍县',
    },
    {
      v: 513328,
      l: '甘孜县',
    },
    {
      v: 513329,
      l: '新龙县',
    },
    {
      v: 513330,
      l: '德格县',
    },
    {
      v: 513331,
      l: '白玉县',
    },
    {
      v: 513332,
      l: '石渠县',
    },
    {
      v: 513333,
      l: '色达县',
    },
    {
      v: 513334,
      l: '理塘县',
    },
    {
      v: 513335,
      l: '巴塘县',
    },
    {
      v: 513336,
      l: '乡城县',
    },
    {
      v: 513337,
      l: '稻城县',
    },
    {
      v: 513338,
      l: '得荣县',
    },
  ],
  513400: [
    {
      v: 513401,
      l: '西昌市',
    },
    {
      v: 513422,
      l: '木里藏族自治县',
    },
    {
      v: 513423,
      l: '盐源县',
    },
    {
      v: 513424,
      l: '德昌县',
    },
    {
      v: 513425,
      l: '会理县',
    },
    {
      v: 513426,
      l: '会东县',
    },
    {
      v: 513427,
      l: '宁南县',
    },
    {
      v: 513428,
      l: '普格县',
    },
    {
      v: 513429,
      l: '布拖县',
    },
    {
      v: 513430,
      l: '金阳县',
    },
    {
      v: 513431,
      l: '昭觉县',
    },
    {
      v: 513432,
      l: '喜德县',
    },
    {
      v: 513433,
      l: '冕宁县',
    },
    {
      v: 513434,
      l: '越西县',
    },
    {
      v: 513435,
      l: '甘洛县',
    },
    {
      v: 513436,
      l: '美姑县',
    },
    {
      v: 513437,
      l: '雷波县',
    },
  ],
  520100: [
    {
      v: 520102,
      l: '南明区',
    },
    {
      v: 520103,
      l: '云岩区',
    },
    {
      v: 520111,
      l: '花溪区',
    },
    {
      v: 520112,
      l: '乌当区',
    },
    {
      v: 520113,
      l: '白云区',
    },
    {
      v: 520115,
      l: '观山湖区',
    },
    {
      v: 520121,
      l: '开阳县',
    },
    {
      v: 520122,
      l: '息烽县',
    },
    {
      v: 520123,
      l: '修文县',
    },
    {
      v: 520181,
      l: '清镇市',
    },
  ],
  520200: [
    {
      v: 520201,
      l: '钟山区',
    },
    {
      v: 520203,
      l: '六枝特区',
    },
    {
      v: 520221,
      l: '水城县',
    },
    {
      v: 520281,
      l: '盘州市',
    },
  ],
  520300: [
    {
      v: 520302,
      l: '红花岗区',
    },
    {
      v: 520303,
      l: '汇川区',
    },
    {
      v: 520304,
      l: '播州区',
    },
    {
      v: 520322,
      l: '桐梓县',
    },
    {
      v: 520323,
      l: '绥阳县',
    },
    {
      v: 520324,
      l: '正安县',
    },
    {
      v: 520325,
      l: '道真仡佬族苗族自治县',
    },
    {
      v: 520326,
      l: '务川仡佬族苗族自治县',
    },
    {
      v: 520327,
      l: '凤冈县',
    },
    {
      v: 520328,
      l: '湄潭县',
    },
    {
      v: 520329,
      l: '余庆县',
    },
    {
      v: 520330,
      l: '习水县',
    },
    {
      v: 520381,
      l: '赤水市',
    },
    {
      v: 520382,
      l: '仁怀市',
    },
  ],
  520400: [
    {
      v: 520402,
      l: '西秀区',
    },
    {
      v: 520403,
      l: '平坝区',
    },
    {
      v: 520422,
      l: '普定县',
    },
    {
      v: 520423,
      l: '镇宁布依族苗族自治县',
    },
    {
      v: 520424,
      l: '关岭布依族苗族自治县',
    },
    {
      v: 520425,
      l: '紫云苗族布依族自治县',
    },
  ],
  520500: [
    {
      v: 520502,
      l: '七星关区',
    },
    {
      v: 520521,
      l: '大方县',
    },
    {
      v: 520522,
      l: '黔西县',
    },
    {
      v: 520523,
      l: '金沙县',
    },
    {
      v: 520524,
      l: '织金县',
    },
    {
      v: 520525,
      l: '纳雍县',
    },
    {
      v: 520526,
      l: '威宁彝族回族苗族自治县',
    },
    {
      v: 520527,
      l: '赫章县',
    },
  ],
  520600: [
    {
      v: 520602,
      l: '碧江区',
    },
    {
      v: 520603,
      l: '万山区',
    },
    {
      v: 520621,
      l: '江口县',
    },
    {
      v: 520622,
      l: '玉屏侗族自治县',
    },
    {
      v: 520623,
      l: '石阡县',
    },
    {
      v: 520624,
      l: '思南县',
    },
    {
      v: 520625,
      l: '印江土家族苗族自治县',
    },
    {
      v: 520626,
      l: '德江县',
    },
    {
      v: 520627,
      l: '沿河土家族自治县',
    },
    {
      v: 520628,
      l: '松桃苗族自治县',
    },
  ],
  522300: [
    {
      v: 522301,
      l: '兴义市',
    },
    {
      v: 522322,
      l: '兴仁县',
    },
    {
      v: 522323,
      l: '普安县',
    },
    {
      v: 522324,
      l: '晴隆县',
    },
    {
      v: 522325,
      l: '贞丰县',
    },
    {
      v: 522326,
      l: '望谟县',
    },
    {
      v: 522327,
      l: '册亨县',
    },
    {
      v: 522328,
      l: '安龙县',
    },
  ],
  522600: [
    {
      v: 522601,
      l: '凯里市',
    },
    {
      v: 522622,
      l: '黄平县',
    },
    {
      v: 522623,
      l: '施秉县',
    },
    {
      v: 522624,
      l: '三穗县',
    },
    {
      v: 522625,
      l: '镇远县',
    },
    {
      v: 522626,
      l: '岑巩县',
    },
    {
      v: 522627,
      l: '天柱县',
    },
    {
      v: 522628,
      l: '锦屏县',
    },
    {
      v: 522629,
      l: '剑河县',
    },
    {
      v: 522630,
      l: '台江县',
    },
    {
      v: 522631,
      l: '黎平县',
    },
    {
      v: 522632,
      l: '榕江县',
    },
    {
      v: 522633,
      l: '从江县',
    },
    {
      v: 522634,
      l: '雷山县',
    },
    {
      v: 522635,
      l: '麻江县',
    },
    {
      v: 522636,
      l: '丹寨县',
    },
  ],
  522700: [
    {
      v: 522701,
      l: '都匀市',
    },
    {
      v: 522702,
      l: '福泉市',
    },
    {
      v: 522722,
      l: '荔波县',
    },
    {
      v: 522723,
      l: '贵定县',
    },
    {
      v: 522725,
      l: '瓮安县',
    },
    {
      v: 522726,
      l: '独山县',
    },
    {
      v: 522727,
      l: '平塘县',
    },
    {
      v: 522728,
      l: '罗甸县',
    },
    {
      v: 522729,
      l: '长顺县',
    },
    {
      v: 522730,
      l: '龙里县',
    },
    {
      v: 522731,
      l: '惠水县',
    },
    {
      v: 522732,
      l: '三都水族自治县',
    },
  ],
  530100: [
    {
      v: 530102,
      l: '五华区',
    },
    {
      v: 530103,
      l: '盘龙区',
    },
    {
      v: 530111,
      l: '官渡区',
    },
    {
      v: 530112,
      l: '西山区',
    },
    {
      v: 530113,
      l: '东川区',
    },
    {
      v: 530114,
      l: '呈贡区',
    },
    {
      v: 530115,
      l: '晋宁区',
    },
    {
      v: 530124,
      l: '富民县',
    },
    {
      v: 530125,
      l: '宜良县',
    },
    {
      v: 530126,
      l: '石林彝族自治县',
    },
    {
      v: 530127,
      l: '嵩明县',
    },
    {
      v: 530128,
      l: '禄劝彝族苗族自治县',
    },
    {
      v: 530129,
      l: '寻甸回族彝族自治县',
    },
    {
      v: 530181,
      l: '安宁市',
    },
  ],
  530300: [
    {
      v: 530302,
      l: '麒麟区',
    },
    {
      v: 530303,
      l: '沾益区',
    },
    {
      v: 530321,
      l: '马龙县',
    },
    {
      v: 530322,
      l: '陆良县',
    },
    {
      v: 530323,
      l: '师宗县',
    },
    {
      v: 530324,
      l: '罗平县',
    },
    {
      v: 530325,
      l: '富源县',
    },
    {
      v: 530326,
      l: '会泽县',
    },
    {
      v: 530381,
      l: '宣威市',
    },
  ],
  530400: [
    {
      v: 530402,
      l: '红塔区',
    },
    {
      v: 530403,
      l: '江川区',
    },
    {
      v: 530422,
      l: '澄江县',
    },
    {
      v: 530423,
      l: '通海县',
    },
    {
      v: 530424,
      l: '华宁县',
    },
    {
      v: 530425,
      l: '易门县',
    },
    {
      v: 530426,
      l: '峨山彝族自治县',
    },
    {
      v: 530427,
      l: '新平彝族傣族自治县',
    },
    {
      v: 530428,
      l: '元江哈尼族彝族傣族自治县',
    },
  ],
  530500: [
    {
      v: 530502,
      l: '隆阳区',
    },
    {
      v: 530521,
      l: '施甸县',
    },
    {
      v: 530581,
      l: '腾冲市',
    },
    {
      v: 530523,
      l: '龙陵县',
    },
    {
      v: 530524,
      l: '昌宁县',
    },
  ],
  530600: [
    {
      v: 530602,
      l: '昭阳区',
    },
    {
      v: 530621,
      l: '鲁甸县',
    },
    {
      v: 530622,
      l: '巧家县',
    },
    {
      v: 530623,
      l: '盐津县',
    },
    {
      v: 530624,
      l: '大关县',
    },
    {
      v: 530625,
      l: '永善县',
    },
    {
      v: 530626,
      l: '绥江县',
    },
    {
      v: 530627,
      l: '镇雄县',
    },
    {
      v: 530628,
      l: '彝良县',
    },
    {
      v: 530629,
      l: '威信县',
    },
    {
      v: 530630,
      l: '水富县',
    },
  ],
  530700: [
    {
      v: 530702,
      l: '古城区',
    },
    {
      v: 530721,
      l: '玉龙纳西族自治县',
    },
    {
      v: 530722,
      l: '永胜县',
    },
    {
      v: 530723,
      l: '华坪县',
    },
    {
      v: 530724,
      l: '宁蒗彝族自治县',
    },
  ],
  530800: [
    {
      v: 530802,
      l: '思茅区',
    },
    {
      v: 530821,
      l: '宁洱哈尼族彝族自治县',
    },
    {
      v: 530822,
      l: '墨江哈尼族自治县',
    },
    {
      v: 530823,
      l: '景东彝族自治县',
    },
    {
      v: 530824,
      l: '景谷傣族彝族自治县',
    },
    {
      v: 530825,
      l: '镇沅彝族哈尼族拉祜族自治县',
    },
    {
      v: 530826,
      l: '江城哈尼族彝族自治县',
    },
    {
      v: 530827,
      l: '孟连傣族拉祜族佤族自治县',
    },
    {
      v: 530828,
      l: '澜沧拉祜族自治县',
    },
    {
      v: 530829,
      l: '西盟佤族自治县',
    },
  ],
  530900: [
    {
      v: 530902,
      l: '临翔区',
    },
    {
      v: 530921,
      l: '凤庆县',
    },
    {
      v: 530922,
      l: '云县',
    },
    {
      v: 530923,
      l: '永德县',
    },
    {
      v: 530924,
      l: '镇康县',
    },
    {
      v: 530925,
      l: '双江拉祜族佤族布朗族傣族自治县',
    },
    {
      v: 530926,
      l: '耿马傣族佤族自治县',
    },
    {
      v: 530927,
      l: '沧源佤族自治县',
    },
  ],
  532300: [
    {
      v: 532301,
      l: '楚雄市',
    },
    {
      v: 532322,
      l: '双柏县',
    },
    {
      v: 532323,
      l: '牟定县',
    },
    {
      v: 532324,
      l: '南华县',
    },
    {
      v: 532325,
      l: '姚安县',
    },
    {
      v: 532326,
      l: '大姚县',
    },
    {
      v: 532327,
      l: '永仁县',
    },
    {
      v: 532328,
      l: '元谋县',
    },
    {
      v: 532329,
      l: '武定县',
    },
    {
      v: 532331,
      l: '禄丰县',
    },
  ],
  532500: [
    {
      v: 532501,
      l: '个旧市',
    },
    {
      v: 532502,
      l: '开远市',
    },
    {
      v: 532503,
      l: '蒙自市',
    },
    {
      v: 532504,
      l: '弥勒市',
    },
    {
      v: 532523,
      l: '屏边苗族自治县',
    },
    {
      v: 532524,
      l: '建水县',
    },
    {
      v: 532525,
      l: '石屏县',
    },
    {
      v: 532527,
      l: '泸西县',
    },
    {
      v: 532528,
      l: '元阳县',
    },
    {
      v: 532529,
      l: '红河县',
    },
    {
      v: 532530,
      l: '金平苗族瑶族傣族自治县',
    },
    {
      v: 532531,
      l: '绿春县',
    },
    {
      v: 532532,
      l: '河口瑶族自治县',
    },
  ],
  532600: [
    {
      v: 532601,
      l: '文山市',
    },
    {
      v: 532622,
      l: '砚山县',
    },
    {
      v: 532623,
      l: '西畴县',
    },
    {
      v: 532624,
      l: '麻栗坡县',
    },
    {
      v: 532625,
      l: '马关县',
    },
    {
      v: 532626,
      l: '丘北县',
    },
    {
      v: 532627,
      l: '广南县',
    },
    {
      v: 532628,
      l: '富宁县',
    },
  ],
  532800: [
    {
      v: 532801,
      l: '景洪市',
    },
    {
      v: 532822,
      l: '勐海县',
    },
    {
      v: 532823,
      l: '勐腊县',
    },
  ],
  532900: [
    {
      v: 532901,
      l: '大理市',
    },
    {
      v: 532922,
      l: '漾濞彝族自治县',
    },
    {
      v: 532923,
      l: '祥云县',
    },
    {
      v: 532924,
      l: '宾川县',
    },
    {
      v: 532925,
      l: '弥渡县',
    },
    {
      v: 532926,
      l: '南涧彝族自治县',
    },
    {
      v: 532927,
      l: '巍山彝族回族自治县',
    },
    {
      v: 532928,
      l: '永平县',
    },
    {
      v: 532929,
      l: '云龙县',
    },
    {
      v: 532930,
      l: '洱源县',
    },
    {
      v: 532931,
      l: '剑川县',
    },
    {
      v: 532932,
      l: '鹤庆县',
    },
  ],
  533100: [
    {
      v: 533102,
      l: '瑞丽市',
    },
    {
      v: 533103,
      l: '芒市',
    },
    {
      v: 533122,
      l: '梁河县',
    },
    {
      v: 533123,
      l: '盈江县',
    },
    {
      v: 533124,
      l: '陇川县',
    },
  ],
  533300: [
    {
      v: 533301,
      l: '泸水市',
    },
    {
      v: 533323,
      l: '福贡县',
    },
    {
      v: 533324,
      l: '贡山独龙族怒族自治县',
    },
    {
      v: 533325,
      l: '兰坪白族普米族自治县',
    },
  ],
  533400: [
    {
      v: 533401,
      l: '香格里拉市',
    },
    {
      v: 533422,
      l: '德钦县',
    },
    {
      v: 533423,
      l: '维西傈僳族自治县',
    },
  ],
  540100: [
    {
      v: 540102,
      l: '城关区',
    },
    {
      v: 540103,
      l: '堆龙德庆区',
    },
    {
      v: 540121,
      l: '林周县',
    },
    {
      v: 540122,
      l: '当雄县',
    },
    {
      v: 540123,
      l: '尼木县',
    },
    {
      v: 540124,
      l: '曲水县',
    },
    {
      v: 540126,
      l: '达孜县',
    },
    {
      v: 540127,
      l: '墨竹工卡县',
    },
  ],
  540200: [
    {
      v: 540200,
      l: '日喀则市',
    },
    {
      v: 540202,
      l: '桑珠孜区',
    },
    {
      v: 540221,
      l: '南木林县',
    },
    {
      v: 540222,
      l: '江孜县',
    },
    {
      v: 540223,
      l: '定日县',
    },
    {
      v: 540224,
      l: '萨迦县',
    },
    {
      v: 540225,
      l: '拉孜县',
    },
    {
      v: 540226,
      l: '昂仁县',
    },
    {
      v: 540227,
      l: '谢通门县',
    },
    {
      v: 540228,
      l: '白朗县',
    },
    {
      v: 540229,
      l: '仁布县',
    },
    {
      v: 540230,
      l: '康马县',
    },
    {
      v: 540231,
      l: '定结县',
    },
    {
      v: 540232,
      l: '仲巴县',
    },
    {
      v: 540233,
      l: '亚东县',
    },
    {
      v: 540234,
      l: '吉隆县',
    },
    {
      v: 540235,
      l: '聂拉木县',
    },
    {
      v: 540236,
      l: '萨嘎县',
    },
    {
      v: 540237,
      l: '岗巴县',
    },
  ],
  540300: [
    {
      v: 540300,
      l: '昌都市',
    },
    {
      v: 540302,
      l: '卡若区',
    },
    {
      v: 540321,
      l: '江达县',
    },
    {
      v: 540322,
      l: '贡觉县',
    },
    {
      v: 540323,
      l: '类乌齐县',
    },
    {
      v: 540324,
      l: '丁青县',
    },
    {
      v: 540325,
      l: '察雅县',
    },
    {
      v: 540326,
      l: '八宿县',
    },
    {
      v: 540327,
      l: '左贡县',
    },
    {
      v: 540328,
      l: '芒康县',
    },
    {
      v: 540329,
      l: '洛隆县',
    },
    {
      v: 540330,
      l: '边坝县',
    },
  ],
  540400: [
    {
      v: 540400,
      l: '林芝市',
    },
    {
      v: 540402,
      l: '巴宜区',
    },
    {
      v: 540421,
      l: '工布江达县',
    },
    {
      v: 540422,
      l: '米林县',
    },
    {
      v: 540423,
      l: '墨脱县',
    },
    {
      v: 540424,
      l: '波密县',
    },
    {
      v: 540425,
      l: '察隅县',
    },
    {
      v: 540426,
      l: '朗县',
    },
  ],
  540500: [
    {
      v: 540500,
      l: '山南市',
    },
    {
      v: 540502,
      l: '乃东区',
    },
    {
      v: 540521,
      l: '扎囊县',
    },
    {
      v: 540522,
      l: '贡嘎县',
    },
    {
      v: 540523,
      l: '桑日县',
    },
    {
      v: 540524,
      l: '琼结县',
    },
    {
      v: 540525,
      l: '曲松县',
    },
    {
      v: 540526,
      l: '措美县',
    },
    {
      v: 540527,
      l: '洛扎县',
    },
    {
      v: 540528,
      l: '加查县',
    },
    {
      v: 540529,
      l: '隆子县',
    },
    {
      v: 540530,
      l: '错那县',
    },
    {
      v: 540531,
      l: '浪卡子县',
    },
  ],
  542400: [
    {
      v: 542421,
      l: '那曲县',
    },
    {
      v: 542422,
      l: '嘉黎县',
    },
    {
      v: 542423,
      l: '比如县',
    },
    {
      v: 542424,
      l: '聂荣县',
    },
    {
      v: 542425,
      l: '安多县',
    },
    {
      v: 542426,
      l: '申扎县',
    },
    {
      v: 542427,
      l: '索县',
    },
    {
      v: 542428,
      l: '班戈县',
    },
    {
      v: 542429,
      l: '巴青县',
    },
    {
      v: 542430,
      l: '尼玛县',
    },
    {
      v: 542431,
      l: '双湖县',
    },
  ],
  542500: [
    {
      v: 542521,
      l: '普兰县',
    },
    {
      v: 542522,
      l: '札达县',
    },
    {
      v: 542523,
      l: '噶尔县',
    },
    {
      v: 542524,
      l: '日土县',
    },
    {
      v: 542525,
      l: '革吉县',
    },
    {
      v: 542526,
      l: '改则县',
    },
    {
      v: 542527,
      l: '措勤县',
    },
  ],
  610100: [
    {
      v: 610102,
      l: '新城区',
    },
    {
      v: 610103,
      l: '碑林区',
    },
    {
      v: 610104,
      l: '莲湖区',
    },
    {
      v: 610111,
      l: '灞桥区',
    },
    {
      v: 610112,
      l: '未央区',
    },
    {
      v: 610113,
      l: '雁塔区',
    },
    {
      v: 610114,
      l: '阎良区',
    },
    {
      v: 610115,
      l: '临潼区',
    },
    {
      v: 610116,
      l: '长安区',
    },
    {
      v: 610117,
      l: '高陵区',
    },
    {
      v: 610122,
      l: '蓝田县',
    },
    {
      v: 610124,
      l: '周至县',
    },
    {
      v: 610118,
      l: '鄠邑区',
    },
  ],
  610200: [
    {
      v: 610202,
      l: '王益区',
    },
    {
      v: 610203,
      l: '印台区',
    },
    {
      v: 610204,
      l: '耀州区',
    },
    {
      v: 610222,
      l: '宜君县',
    },
  ],
  610300: [
    {
      v: 610302,
      l: '渭滨区',
    },
    {
      v: 610303,
      l: '金台区',
    },
    {
      v: 610304,
      l: '陈仓区',
    },
    {
      v: 610322,
      l: '凤翔县',
    },
    {
      v: 610323,
      l: '岐山县',
    },
    {
      v: 610324,
      l: '扶风县',
    },
    {
      v: 610326,
      l: '眉县',
    },
    {
      v: 610327,
      l: '陇县',
    },
    {
      v: 610328,
      l: '千阳县',
    },
    {
      v: 610329,
      l: '麟游县',
    },
    {
      v: 610330,
      l: '凤县',
    },
    {
      v: 610331,
      l: '太白县',
    },
  ],
  610400: [
    {
      v: 610402,
      l: '秦都区',
    },
    {
      v: 610403,
      l: '杨陵区',
    },
    {
      v: 610404,
      l: '渭城区',
    },
    {
      v: 610422,
      l: '三原县',
    },
    {
      v: 610423,
      l: '泾阳县',
    },
    {
      v: 610424,
      l: '乾县',
    },
    {
      v: 610425,
      l: '礼泉县',
    },
    {
      v: 610426,
      l: '永寿县',
    },
    {
      v: 610427,
      l: '彬县',
    },
    {
      v: 610428,
      l: '长武县',
    },
    {
      v: 610429,
      l: '旬邑县',
    },
    {
      v: 610430,
      l: '淳化县',
    },
    {
      v: 610431,
      l: '武功县',
    },
    {
      v: 610481,
      l: '兴平市',
    },
  ],
  610500: [
    {
      v: 610502,
      l: '临渭区',
    },
    {
      v: 610503,
      l: '华州区',
    },
    {
      v: 610522,
      l: '潼关县',
    },
    {
      v: 610523,
      l: '大荔县',
    },
    {
      v: 610524,
      l: '合阳县',
    },
    {
      v: 610525,
      l: '澄城县',
    },
    {
      v: 610526,
      l: '蒲城县',
    },
    {
      v: 610527,
      l: '白水县',
    },
    {
      v: 610528,
      l: '富平县',
    },
    {
      v: 610581,
      l: '韩城市',
    },
    {
      v: 610582,
      l: '华阴市',
    },
  ],
  610600: [
    {
      v: 610602,
      l: '宝塔区',
    },
    {
      v: 610603,
      l: '安塞区',
    },
    {
      v: 610621,
      l: '延长县',
    },
    {
      v: 610622,
      l: '延川县',
    },
    {
      v: 610623,
      l: '子长县',
    },
    {
      v: 610625,
      l: '志丹县',
    },
    {
      v: 610626,
      l: '吴起县',
    },
    {
      v: 610627,
      l: '甘泉县',
    },
    {
      v: 610628,
      l: '富县',
    },
    {
      v: 610629,
      l: '洛川县',
    },
    {
      v: 610630,
      l: '宜川县',
    },
    {
      v: 610631,
      l: '黄龙县',
    },
    {
      v: 610632,
      l: '黄陵县',
    },
  ],
  610700: [
    {
      v: 610702,
      l: '汉台区',
    },
    {
      v: 610721,
      l: '南郑县',
    },
    {
      v: 610722,
      l: '城固县',
    },
    {
      v: 610723,
      l: '洋县',
    },
    {
      v: 610724,
      l: '西乡县',
    },
    {
      v: 610725,
      l: '勉县',
    },
    {
      v: 610726,
      l: '宁强县',
    },
    {
      v: 610727,
      l: '略阳县',
    },
    {
      v: 610728,
      l: '镇巴县',
    },
    {
      v: 610729,
      l: '留坝县',
    },
    {
      v: 610730,
      l: '佛坪县',
    },
  ],
  610800: [
    {
      v: 610802,
      l: '榆阳区',
    },
    {
      v: 610803,
      l: '横山区',
    },
    {
      v: 610881,
      l: '神木市',
    },
    {
      v: 610822,
      l: '府谷县',
    },
    {
      v: 610824,
      l: '靖边县',
    },
    {
      v: 610825,
      l: '定边县',
    },
    {
      v: 610826,
      l: '绥德县',
    },
    {
      v: 610827,
      l: '米脂县',
    },
    {
      v: 610828,
      l: '佳县',
    },
    {
      v: 610829,
      l: '吴堡县',
    },
    {
      v: 610830,
      l: '清涧县',
    },
    {
      v: 610831,
      l: '子洲县',
    },
  ],
  610900: [
    {
      v: 610902,
      l: '汉滨区',
    },
    {
      v: 610921,
      l: '汉阴县',
    },
    {
      v: 610922,
      l: '石泉县',
    },
    {
      v: 610923,
      l: '宁陕县',
    },
    {
      v: 610924,
      l: '紫阳县',
    },
    {
      v: 610925,
      l: '岚皋县',
    },
    {
      v: 610926,
      l: '平利县',
    },
    {
      v: 610927,
      l: '镇坪县',
    },
    {
      v: 610928,
      l: '旬阳县',
    },
    {
      v: 610929,
      l: '白河县',
    },
  ],
  611000: [
    {
      v: 611002,
      l: '商州区',
    },
    {
      v: 611021,
      l: '洛南县',
    },
    {
      v: 611022,
      l: '丹凤县',
    },
    {
      v: 611023,
      l: '商南县',
    },
    {
      v: 611024,
      l: '山阳县',
    },
    {
      v: 611025,
      l: '镇安县',
    },
    {
      v: 611026,
      l: '柞水县',
    },
  ],
  620100: [
    {
      v: 620102,
      l: '城关区',
    },
    {
      v: 620103,
      l: '七里河区',
    },
    {
      v: 620104,
      l: '西固区',
    },
    {
      v: 620105,
      l: '安宁区',
    },
    {
      v: 620111,
      l: '红古区',
    },
    {
      v: 620121,
      l: '永登县',
    },
    {
      v: 620122,
      l: '皋兰县',
    },
    {
      v: 620123,
      l: '榆中县',
    },
  ],
  620200: [
    {
      v: 620201,
      l: '市辖区',
    },
  ],
  620300: [
    {
      v: 620302,
      l: '金川区',
    },
    {
      v: 620321,
      l: '永昌县',
    },
  ],
  620400: [
    {
      v: 620402,
      l: '白银区',
    },
    {
      v: 620403,
      l: '平川区',
    },
    {
      v: 620421,
      l: '靖远县',
    },
    {
      v: 620422,
      l: '会宁县',
    },
    {
      v: 620423,
      l: '景泰县',
    },
  ],
  620500: [
    {
      v: 620502,
      l: '秦州区',
    },
    {
      v: 620503,
      l: '麦积区',
    },
    {
      v: 620521,
      l: '清水县',
    },
    {
      v: 620522,
      l: '秦安县',
    },
    {
      v: 620523,
      l: '甘谷县',
    },
    {
      v: 620524,
      l: '武山县',
    },
    {
      v: 620525,
      l: '张家川回族自治县',
    },
  ],
  620600: [
    {
      v: 620602,
      l: '凉州区',
    },
    {
      v: 620621,
      l: '民勤县',
    },
    {
      v: 620622,
      l: '古浪县',
    },
    {
      v: 620623,
      l: '天祝藏族自治县',
    },
  ],
  620700: [
    {
      v: 620702,
      l: '甘州区',
    },
    {
      v: 620721,
      l: '肃南裕固族自治县',
    },
    {
      v: 620722,
      l: '民乐县',
    },
    {
      v: 620723,
      l: '临泽县',
    },
    {
      v: 620724,
      l: '高台县',
    },
    {
      v: 620725,
      l: '山丹县',
    },
  ],
  620800: [
    {
      v: 620802,
      l: '崆峒区',
    },
    {
      v: 620821,
      l: '泾川县',
    },
    {
      v: 620822,
      l: '灵台县',
    },
    {
      v: 620823,
      l: '崇信县',
    },
    {
      v: 620824,
      l: '华亭县',
    },
    {
      v: 620825,
      l: '庄浪县',
    },
    {
      v: 620826,
      l: '静宁县',
    },
  ],
  620900: [
    {
      v: 620902,
      l: '肃州区',
    },
    {
      v: 620921,
      l: '金塔县',
    },
    {
      v: 620922,
      l: '瓜州县',
    },
    {
      v: 620923,
      l: '肃北蒙古族自治县',
    },
    {
      v: 620924,
      l: '阿克塞哈萨克族自治县',
    },
    {
      v: 620981,
      l: '玉门市',
    },
    {
      v: 620982,
      l: '敦煌市',
    },
  ],
  621000: [
    {
      v: 621002,
      l: '西峰区',
    },
    {
      v: 621021,
      l: '庆城县',
    },
    {
      v: 621022,
      l: '环县',
    },
    {
      v: 621023,
      l: '华池县',
    },
    {
      v: 621024,
      l: '合水县',
    },
    {
      v: 621025,
      l: '正宁县',
    },
    {
      v: 621026,
      l: '宁县',
    },
    {
      v: 621027,
      l: '镇原县',
    },
  ],
  621100: [
    {
      v: 621102,
      l: '安定区',
    },
    {
      v: 621121,
      l: '通渭县',
    },
    {
      v: 621122,
      l: '陇西县',
    },
    {
      v: 621123,
      l: '渭源县',
    },
    {
      v: 621124,
      l: '临洮县',
    },
    {
      v: 621125,
      l: '漳县',
    },
    {
      v: 621126,
      l: '岷县',
    },
  ],
  621200: [
    {
      v: 621202,
      l: '武都区',
    },
    {
      v: 621221,
      l: '成县',
    },
    {
      v: 621222,
      l: '文县',
    },
    {
      v: 621223,
      l: '宕昌县',
    },
    {
      v: 621224,
      l: '康县',
    },
    {
      v: 621225,
      l: '西和县',
    },
    {
      v: 621226,
      l: '礼县',
    },
    {
      v: 621227,
      l: '徽县',
    },
    {
      v: 621228,
      l: '两当县',
    },
  ],
  622900: [
    {
      v: 622901,
      l: '临夏市',
    },
    {
      v: 622921,
      l: '临夏县',
    },
    {
      v: 622922,
      l: '康乐县',
    },
    {
      v: 622923,
      l: '永靖县',
    },
    {
      v: 622924,
      l: '广河县',
    },
    {
      v: 622925,
      l: '和政县',
    },
    {
      v: 622926,
      l: '东乡族自治县',
    },
    {
      v: 622927,
      l: '积石山保安族东乡族撒拉族自治县',
    },
  ],
  623000: [
    {
      v: 623001,
      l: '合作市',
    },
    {
      v: 623021,
      l: '临潭县',
    },
    {
      v: 623022,
      l: '卓尼县',
    },
    {
      v: 623023,
      l: '舟曲县',
    },
    {
      v: 623024,
      l: '迭部县',
    },
    {
      v: 623025,
      l: '玛曲县',
    },
    {
      v: 623026,
      l: '碌曲县',
    },
    {
      v: 623027,
      l: '夏河县',
    },
  ],
  630100: [
    {
      v: 630102,
      l: '城东区',
    },
    {
      v: 630103,
      l: '城中区',
    },
    {
      v: 630104,
      l: '城西区',
    },
    {
      v: 630105,
      l: '城北区',
    },
    {
      v: 630121,
      l: '大通回族土族自治县',
    },
    {
      v: 630122,
      l: '湟中县',
    },
    {
      v: 630123,
      l: '湟源县',
    },
  ],
  630200: [
    {
      v: 630200,
      l: '海东市',
    },
    {
      v: 630202,
      l: '乐都区',
    },
    {
      v: 630203,
      l: '平安区',
    },
    {
      v: 630222,
      l: '民和回族土族自治县',
    },
    {
      v: 630223,
      l: '互助土族自治县',
    },
    {
      v: 630224,
      l: '化隆回族自治县',
    },
    {
      v: 630225,
      l: '循化撒拉族自治县',
    },
  ],
  632200: [
    {
      v: 632221,
      l: '门源回族自治县',
    },
    {
      v: 632222,
      l: '祁连县',
    },
    {
      v: 632223,
      l: '海晏县',
    },
    {
      v: 632224,
      l: '刚察县',
    },
  ],
  632300: [
    {
      v: 632321,
      l: '同仁县',
    },
    {
      v: 632322,
      l: '尖扎县',
    },
    {
      v: 632323,
      l: '泽库县',
    },
    {
      v: 632324,
      l: '河南蒙古族自治县',
    },
  ],
  632500: [
    {
      v: 632521,
      l: '共和县',
    },
    {
      v: 632522,
      l: '同德县',
    },
    {
      v: 632523,
      l: '贵德县',
    },
    {
      v: 632524,
      l: '兴海县',
    },
    {
      v: 632525,
      l: '贵南县',
    },
  ],
  632600: [
    {
      v: 632621,
      l: '玛沁县',
    },
    {
      v: 632622,
      l: '班玛县',
    },
    {
      v: 632623,
      l: '甘德县',
    },
    {
      v: 632624,
      l: '达日县',
    },
    {
      v: 632625,
      l: '久治县',
    },
    {
      v: 632626,
      l: '玛多县',
    },
  ],
  632700: [
    {
      v: 632701,
      l: '玉树市',
    },
    {
      v: 632722,
      l: '杂多县',
    },
    {
      v: 632723,
      l: '称多县',
    },
    {
      v: 632724,
      l: '治多县',
    },
    {
      v: 632725,
      l: '囊谦县',
    },
    {
      v: 632726,
      l: '曲麻莱县',
    },
  ],
  632800: [
    {
      v: 632801,
      l: '格尔木市',
    },
    {
      v: 632802,
      l: '德令哈市',
    },
    {
      v: 632821,
      l: '乌兰县',
    },
    {
      v: 632822,
      l: '都兰县',
    },
    {
      v: 632823,
      l: '天峻县',
    },
  ],
  640100: [
    {
      v: 640104,
      l: '兴庆区',
    },
    {
      v: 640105,
      l: '西夏区',
    },
    {
      v: 640106,
      l: '金凤区',
    },
    {
      v: 640121,
      l: '永宁县',
    },
    {
      v: 640122,
      l: '贺兰县',
    },
    {
      v: 640181,
      l: '灵武市',
    },
  ],
  640200: [
    {
      v: 640202,
      l: '大武口区',
    },
    {
      v: 640205,
      l: '惠农区',
    },
    {
      v: 640221,
      l: '平罗县',
    },
  ],
  640300: [
    {
      v: 640302,
      l: '利通区',
    },
    {
      v: 640303,
      l: '红寺堡区',
    },
    {
      v: 640323,
      l: '盐池县',
    },
    {
      v: 640324,
      l: '同心县',
    },
    {
      v: 640381,
      l: '青铜峡市',
    },
  ],
  640400: [
    {
      v: 640402,
      l: '原州区',
    },
    {
      v: 640422,
      l: '西吉县',
    },
    {
      v: 640423,
      l: '隆德县',
    },
    {
      v: 640424,
      l: '泾源县',
    },
    {
      v: 640425,
      l: '彭阳县',
    },
  ],
  640500: [
    {
      v: 640502,
      l: '沙坡头区',
    },
    {
      v: 640521,
      l: '中宁县',
    },
    {
      v: 640522,
      l: '海原县',
    },
  ],
  650100: [
    {
      v: 650102,
      l: '天山区',
    },
    {
      v: 650103,
      l: '沙依巴克区',
    },
    {
      v: 650104,
      l: '新市区',
    },
    {
      v: 650105,
      l: '水磨沟区',
    },
    {
      v: 650106,
      l: '头屯河区',
    },
    {
      v: 650107,
      l: '达坂城区',
    },
    {
      v: 650109,
      l: '米东区',
    },
    {
      v: 650121,
      l: '乌鲁木齐县',
    },
  ],
  650200: [
    {
      v: 650202,
      l: '独山子区',
    },
    {
      v: 650203,
      l: '克拉玛依区',
    },
    {
      v: 650204,
      l: '白碱滩区',
    },
    {
      v: 650205,
      l: '乌尔禾区',
    },
  ],
  650400: [
    {
      v: 650400,
      l: '吐鲁番市',
    },
    {
      v: 650402,
      l: '高昌区',
    },
    {
      v: 650421,
      l: '鄯善县',
    },
    {
      v: 650422,
      l: '托克逊县',
    },
  ],
  652300: [
    {
      v: 652301,
      l: '昌吉市',
    },
    {
      v: 652302,
      l: '阜康市',
    },
    {
      v: 652323,
      l: '呼图壁县',
    },
    {
      v: 652324,
      l: '玛纳斯县',
    },
    {
      v: 652325,
      l: '奇台县',
    },
    {
      v: 652327,
      l: '吉木萨尔县',
    },
    {
      v: 652328,
      l: '木垒哈萨克自治县',
    },
  ],
  652700: [
    {
      v: 652701,
      l: '博乐市',
    },
    {
      v: 652702,
      l: '阿拉山口市',
    },
    {
      v: 652722,
      l: '精河县',
    },
    {
      v: 652723,
      l: '温泉县',
    },
  ],
  652800: [
    {
      v: 652801,
      l: '库尔勒市',
    },
    {
      v: 652822,
      l: '轮台县',
    },
    {
      v: 652823,
      l: '尉犁县',
    },
    {
      v: 652824,
      l: '若羌县',
    },
    {
      v: 652825,
      l: '且末县',
    },
    {
      v: 652826,
      l: '焉耆回族自治县',
    },
    {
      v: 652827,
      l: '和静县',
    },
    {
      v: 652828,
      l: '和硕县',
    },
    {
      v: 652829,
      l: '博湖县',
    },
  ],
  652900: [
    {
      v: 652901,
      l: '阿克苏市',
    },
    {
      v: 652922,
      l: '温宿县',
    },
    {
      v: 652923,
      l: '库车县',
    },
    {
      v: 652924,
      l: '沙雅县',
    },
    {
      v: 652925,
      l: '新和县',
    },
    {
      v: 652926,
      l: '拜城县',
    },
    {
      v: 652927,
      l: '乌什县',
    },
    {
      v: 652928,
      l: '阿瓦提县',
    },
    {
      v: 652929,
      l: '柯坪县',
    },
  ],
  653000: [
    {
      v: 653001,
      l: '阿图什市',
    },
    {
      v: 653022,
      l: '阿克陶县',
    },
    {
      v: 653023,
      l: '阿合奇县',
    },
    {
      v: 653024,
      l: '乌恰县',
    },
  ],
  653100: [
    {
      v: 653101,
      l: '喀什市',
    },
    {
      v: 653121,
      l: '疏附县',
    },
    {
      v: 653122,
      l: '疏勒县',
    },
    {
      v: 653123,
      l: '英吉沙县',
    },
    {
      v: 653124,
      l: '泽普县',
    },
    {
      v: 653125,
      l: '莎车县',
    },
    {
      v: 653126,
      l: '叶城县',
    },
    {
      v: 653127,
      l: '麦盖提县',
    },
    {
      v: 653128,
      l: '岳普湖县',
    },
    {
      v: 653129,
      l: '伽师县',
    },
    {
      v: 653130,
      l: '巴楚县',
    },
    {
      v: 653131,
      l: '塔什库尔干塔吉克自治县',
    },
  ],
  653200: [
    {
      v: 653201,
      l: '和田市',
    },
    {
      v: 653221,
      l: '和田县',
    },
    {
      v: 653222,
      l: '墨玉县',
    },
    {
      v: 653223,
      l: '皮山县',
    },
    {
      v: 653224,
      l: '洛浦县',
    },
    {
      v: 653225,
      l: '策勒县',
    },
    {
      v: 653226,
      l: '于田县',
    },
    {
      v: 653227,
      l: '民丰县',
    },
  ],
  654000: [
    {
      v: 654002,
      l: '伊宁市',
    },
    {
      v: 654003,
      l: '奎屯市',
    },
    {
      v: 654004,
      l: '霍尔果斯市',
    },
    {
      v: 654021,
      l: '伊宁县',
    },
    {
      v: 654022,
      l: '察布查尔锡伯自治县',
    },
    {
      v: 654023,
      l: '霍城县',
    },
    {
      v: 654024,
      l: '巩留县',
    },
    {
      v: 654025,
      l: '新源县',
    },
    {
      v: 654026,
      l: '昭苏县',
    },
    {
      v: 654027,
      l: '特克斯县',
    },
    {
      v: 654028,
      l: '尼勒克县',
    },
  ],
  654200: [
    {
      v: 654201,
      l: '塔城市',
    },
    {
      v: 654202,
      l: '乌苏市',
    },
    {
      v: 654221,
      l: '额敏县',
    },
    {
      v: 654223,
      l: '沙湾县',
    },
    {
      v: 654224,
      l: '托里县',
    },
    {
      v: 654225,
      l: '裕民县',
    },
    {
      v: 654226,
      l: '和布克赛尔蒙古自治县',
    },
  ],
  654300: [
    {
      v: 654301,
      l: '阿勒泰市',
    },
    {
      v: 654321,
      l: '布尔津县',
    },
    {
      v: 654322,
      l: '富蕴县',
    },
    {
      v: 654323,
      l: '福海县',
    },
    {
      v: 654324,
      l: '哈巴河县',
    },
    {
      v: 654325,
      l: '青河县',
    },
    {
      v: 654326,
      l: '吉木乃县',
    },
  ],
  659000: [
    {
      v: 659001,
      l: '石河子市',
    },
    {
      v: 659002,
      l: '阿拉尔市',
    },
    {
      v: 659003,
      l: '图木舒克市',
    },
    {
      v: 659004,
      l: '五家渠市',
    },
    {
      v: 659005,
      l: '北屯市',
    },
    {
      v: 659006,
      l: '铁门关市',
    },
    {
      v: 659007,
      l: '双河市',
    },
    {
      v: 659008,
      l: '可克达拉市',
    },
    {
      v: 659009,
      l: '昆玉市',
    },
  ],
  710100: [
    {
      v: 710101,
      l: '中正区',
    },
    {
      v: 710102,
      l: '大同区',
    },
    {
      v: 710103,
      l: '中山区',
    },
    {
      v: 710104,
      l: '松山区',
    },
    {
      v: 710105,
      l: '大安区',
    },
    {
      v: 710106,
      l: '万华区',
    },
    {
      v: 710107,
      l: '信义区',
    },
    {
      v: 710108,
      l: '士林区',
    },
    {
      v: 710109,
      l: '北投区',
    },
    {
      v: 710110,
      l: '内湖区',
    },
    {
      v: 710111,
      l: '南港区',
    },
    {
      v: 710112,
      l: '文山区',
    },
  ],
  710200: [
    {
      v: 710201,
      l: '新兴区',
    },
    {
      v: 710202,
      l: '前金区',
    },
    {
      v: 710203,
      l: '芩雅区',
    },
    {
      v: 710204,
      l: '盐埕区',
    },
    {
      v: 710205,
      l: '鼓山区',
    },
    {
      v: 710206,
      l: '旗津区',
    },
    {
      v: 710207,
      l: '前镇区',
    },
    {
      v: 710208,
      l: '三民区',
    },
    {
      v: 710209,
      l: '左营区',
    },
    {
      v: 710210,
      l: '楠梓区',
    },
    {
      v: 710211,
      l: '小港区',
    },
  ],
  710300: [
    {
      v: 710301,
      l: '仁爱区',
    },
    {
      v: 710302,
      l: '信义区',
    },
    {
      v: 710303,
      l: '中正区',
    },
    {
      v: 710304,
      l: '中山区',
    },
    {
      v: 710305,
      l: '安乐区',
    },
    {
      v: 710306,
      l: '暖暖区',
    },
    {
      v: 710307,
      l: '七堵区',
    },
  ],
  710400: [
    {
      v: 710401,
      l: '中区',
    },
    {
      v: 710402,
      l: '东区',
    },
    {
      v: 710403,
      l: '南区',
    },
    {
      v: 710404,
      l: '西区',
    },
    {
      v: 710405,
      l: '北区',
    },
    {
      v: 710406,
      l: '北屯区',
    },
    {
      v: 710407,
      l: '西屯区',
    },
    {
      v: 710408,
      l: '南屯区',
    },
  ],
  710500: [
    {
      v: 710501,
      l: '中西区',
    },
    {
      v: 710502,
      l: '东区',
    },
    {
      v: 710503,
      l: '南区',
    },
    {
      v: 710504,
      l: '北区',
    },
    {
      v: 710505,
      l: '安平区',
    },
    {
      v: 710506,
      l: '安南区',
    },
  ],
  710600: [
    {
      v: 710601,
      l: '东区',
    },
    {
      v: 710602,
      l: '北区',
    },
    {
      v: 710603,
      l: '香山区',
    },
  ],
  710700: [
    {
      v: 710701,
      l: '东区',
    },
    {
      v: 710702,
      l: '西区',
    },
  ],
  710800: [
    {
      v: 710801,
      l: '台北县(板桥市)',
    },
  ],
  710900: [
    {
      v: 710901,
      l: '宜兰县(宜兰市)',
    },
  ],
  711000: [
    {
      v: 711001,
      l: '新竹县(竹北市)',
    },
  ],
  711100: [
    {
      v: 711101,
      l: '桃园县(桃园市)',
    },
  ],
  711200: [
    {
      v: 711201,
      l: '苗栗县(苗栗市)',
    },
  ],
  711300: [
    {
      v: 711301,
      l: '台中县(丰原市)',
    },
  ],
  711400: [
    {
      v: 711401,
      l: '彰化县(彰化市)',
    },
  ],
  711500: [
    {
      v: 711501,
      l: '南投县(南投市)',
    },
  ],
  711600: [
    {
      v: 711601,
      l: '嘉义县(太保市)',
    },
  ],
  711700: [
    {
      v: 711701,
      l: '云林县(斗六市)',
    },
  ],
  711800: [
    {
      v: 711801,
      l: '台南县(新营市)',
    },
  ],
  711900: [
    {
      v: 711901,
      l: '高雄县(凤山市)',
    },
  ],
  712000: [
    {
      v: 712001,
      l: '屏东县(屏东市)',
    },
  ],
  712100: [
    {
      v: 712101,
      l: '台东县(台东市)',
    },
  ],
  712200: [
    {
      v: 712201,
      l: '花莲县(花莲市)',
    },
  ],
  712300: [
    {
      v: 712301,
      l: '澎湖县(马公市)',
    },
  ],
  810100: [
    {
      v: 810101,
      l: '中西区',
    },
    {
      v: 810102,
      l: '东区',
    },
    {
      v: 810103,
      l: '九龙城区',
    },
    {
      v: 810104,
      l: '观塘区',
    },
    {
      v: 810105,
      l: '南区',
    },
    {
      v: 810106,
      l: '深水埗区',
    },
    {
      v: 810107,
      l: '黄大仙区',
    },
    {
      v: 810108,
      l: '湾仔区',
    },
    {
      v: 810109,
      l: '油尖旺区',
    },
    {
      v: 810110,
      l: '离岛区',
    },
    {
      v: 810111,
      l: '葵青区',
    },
    {
      v: 810112,
      l: '北区',
    },
    {
      v: 810113,
      l: '西贡区',
    },
    {
      v: 810114,
      l: '沙田区',
    },
    {
      v: 810115,
      l: '屯门区',
    },
    {
      v: 810116,
      l: '大埔区',
    },
    {
      v: 810117,
      l: '荃湾区',
    },
    {
      v: 810118,
      l: '元朗区',
    },
  ],
  820000: [
    {
      v: 820000,
      l: '澳门特别行政区',
    },
  ],
};

export { provsData, citysData, distsData };
