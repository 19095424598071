import { React, $, ReactDOM } from '../../../../common/3rd';

import AtomicMutableComponentThing from '../atomic-mutable-component';

/**
 * 电子签名单证项
 * 例：电子签名页
 * add by susan.wu 2017 Nov.03
 *
 */
class DocumentSignatureItem extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.signStatus = false;
    this.showStatus = true;
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.sameWithProposer = false;
  }
  render() {
    this.currentSignaturePerson =
      this.props.parent.props.parent.state.currentSignaturePerson || 3;
    this.signStatus = this.getSignStatus();
    let className = this.signStatus ? 'signature signature-ck' : 'signature';
    className = this.getLayout().showInput
      ? className + ' showInput'
      : ' hideInput';
    return (
      <div {...this.getPrototypes()} ref="me" onClick={this.onJumpToDocument}>
        <div className={className}></div>
        <span>{this.getLayout().label}</span>
        <div className="to-document"></div>
      </div>
    );
  }
  getSignStatus() {
    //判断投被保人是否为同一人
    this.sameWithProposer = false;
    this.getModel().policyCustomers.map((item, index, arr) => {
      if (item.customerType === '2') {
        if (item.extensionData.relationWithPH === '00') {
          this.sameWithProposer = true;
        }
      }
      return null;
    });
    let signedDocuments = this.getModel().extensionData.documents;
    let checked = false;
    signedDocuments.map((signedItem) => {
      // if(this.getLayout().signTarget === 1 && this.sameWithProposer && this.getLayout().sameType) {
      // 	if(signedItem.documentType+"" === this.getLayout().sameType+"") {
      // 		checked = true;
      // 	}
      // }else {
      // 	if(signedItem.documentType+"" === this.getLayout().documentType+"") {
      // 		checked = true;
      // 	}
      // }
      if (signedItem.documentType + '' === this.getLayout().documentType + '') {
        checked = true;
      }
      return null;
    });
    return checked;
  }
  onJumpToDocument = () => {
    const me = $(ReactDOM.findDOMNode(this.refs.me));
    //若该单证已经签过，则不能再进入签名,并且只有对应的人才能进入单证
    if (!me.find('.signature').hasClass('signature-ck')) {
      if (
        this.getLayout().nextStep &&
        this.currentSignaturePerson === this.getLayout().signTarget
      ) {
        this.getRootComponent().jumpTo(this.getLayout().nextStep);
        // this.props.parent.props.parent.jumpTo(this.getLayout().nextStep);
      }
    }
  };
  getDefaultClassName() {
    let className = 'sth-document-signature-item';
    if (this.sameWithProposer && this.getLayout().signTarget === 2) {
      className = className + ' signature-item-hidden';
      this.showStatus = false;
    } else {
      if (
        this.getLayout().canToDocument &&
        this.currentSignaturePerson !== this.getLayout().signTarget
      ) {
        className = className + ' signature-item-hidden';
        this.showStatus = false;
      }
    }
    //判断京安顺的单证显示
    if (this.getModel().productId === 327 && !this.getLayout().includeToJAS) {
      className = className + ' signature-item-hidden';
      this.showStatus = false;
    }
    return className;
  }
  getValueFromModel() {
    if (!this.showStatus) {
      return true;
    } else {
      return this.signStatus;
    }
  }
}

export default DocumentSignatureItem;
