import { React } from '../../../../common/3rd';
import { Envs, Utils } from '../../../../common/common';
import { PageLoading, Alert } from '../../../components';
import AtomicMutableComponentThing from '../atomic-mutable-component';

/**
 * create by cheng.fan on Jan.4 2018
 */

export default class PhotoVerifivation extends AtomicMutableComponentThing {
  state = {
    imgSrc1: '',
    imgSrc1Origin: '',
  };

  componentDidMount() {}

  render() {
    return <div className="photo-verifivation">{this.renderUpload()}</div>;
  }

  renderUpload() {
    return (
      <div className="upload">
        <div className="upload-container">
          <i
            onClick={() => this.onDeleteImgClick('imgSrc1')}
            className="delete"
          ></i>
          <div className="img-container">
            点击拍摄上传图片
            <img src={this.state.imgSrc1} alt="" />
            <input
              onChange={(e) => this.onUploadImgChange(e.target, 1)}
              type="file"
              accept="image/*"
            />
          </div>
          <p>(自拍照)</p>
        </div>
      </div>
    );
  }

  onDeleteImgClick(type) {
    type === 'imgSrc1' && this.setState({ imgSrc1: '' });
  }

  onUploadImgChange(node, which) {
    var reader = new FileReader();
    let imgFile = node.files[0];
    if (!imgFile) {
      return;
    }
    let oFReader = new FileReader();
    oFReader.readAsDataURL(imgFile);
    oFReader.onload = (evt) => {
      let formData = new FormData();
      formData.append('file', imgFile);
      let result = evt.target.result;
      let img = new Image();
      img.src = result;
      img.onload = () => {
        let data = Utils.compress(img);
        if (which === 1) {
          this.setState({ imgSrc1: data });
          this.uploadImage(data);
        }
      };
    };
  }

  async uploadImage(document) {
    // console.log(this.getModel())
    let url = '/rest/v1/policy/uploadImage';
    let requestData = {
      businessNo: this.getModel().quotationNo,
      customerType: '0',
      document: document,
      documentType: '-21',
      isFront: 'Y',
      uplaodType: '2',
      vendorCode: 'BJLIFE',
    };
    PageLoading.start();
    await this.doPost(url, requestData)
      .done((data) => {
        this.setState({ imgSrc1Origin: data.body }, () => {
          this.getModel().policyHolder.extensionData.documents.push(data.body);
          this.getModel().policyStatus = '80'; //身份验证成功 改变当前状态为‘待支付’ update by wendy.gu 2018 Jan.9
          // console.log(this.getModel());
        });
      })
      .always(() => {
        PageLoading.end();
      });
  }

  getValueFromModel() {
    return this.state.imgSrc1;
  }
}
