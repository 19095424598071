/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 校验
 * @author LiuH
 */
import { AppError } from '@/error';
import {
  getMaxTouchPoints,
  getPlatform,
  getUserAgent,
} from '@/util/compatibility';
import { wx } from '@/3rd';

/**
 * 是否营销作业平台App
 * @return {boolean} true：营销作业平台App
 */
function isIIMP() {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/IIMP/i) == 'iimp';
}

/**
 * 是否京保通App
 * @returns {boolean} true：京保通App
 */
function isJingBaoTong() {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/JingBaoTong/i) == 'jingbaotong';
}

/**
 * 是否京保通App(UserAgent不包含JingBaoTong版本)
 * @returns {boolean} true：京保通App
 */
function isEBao() {
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/EBao/i) == 'ebao';
}

function isAndroid() {
  let ua = getUserAgent();
  // return /Android/.test(ua);
  return ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
}

function isIOS() {
  // return !!getUserAgent().match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // return /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(getUserAgent())
  return (
    /\b(iPad|iPhone|iPod)(?=;)/.test(getUserAgent()) ||
    /*
     * iPad OS 13之后，UserAgent中iPad被替换为Macintosh
     * 详见https://developer.apple.com/forums/thread/119186
     */
    (getPlatform() === 'MacIntel' && getMaxTouchPoints() > 1)
  );
}

function isIOSChrome() {
  return /CriOS/.test(getUserAgent());
}

function isIE() {
  return /Trident/.test(getUserAgent());
}

/**
 * 检测是否微信
 */
function isWechat() {
  return /MicroMessenger/i.test(getUserAgent());
}

/**
 * 检测是否微信小程序（微信Android/iOS 7.0.0版本开始可以通过UA直接判断是否为小程序)
 */
function isWechatMini() {
  return /miniProgram/.test(getUserAgent());
}

/**
 * 检测微信小程序SDK是否就绪
 */
function isWechatMiniSDKReady() {
  return wx && 'miniProgram' in wx;
}

function isBlobSupport() {
  const _isIE = isIE();
  const _isIOSChrome = isIOSChrome();

  // 检查是否支持URL.createObjectURL()
  return !(_isIE || _isIOSChrome);
}

/**
 * 校验是否为AppError
 * @param {any} param
 * @returns {boolean}
 */
function isAppError(param) {
  return isError(param) && param instanceof AppError;
}

/**
 * 校验是否为Error
 * @param {any} param
 * @returns {boolean}
 */
function isError(param) {
  return toString.call(param) === '[object Error]';
}

/**
 * 校验是否为正则表达式
 * @param {any} param
 * @return {boolean}
 */
function isRegExp(param) {
  return toString.call(param) === '[object RegExp]';
}

/**
 * 校验是否为undefined
 * @param {any} param
 * @returns {boolean}
 */
function isUndefined(param) {
  return toString.call(param) === '[object Undefined]';
}

/**
 * 校验是否为null
 * @param {any} param
 * @returns {boolean}
 */
function isNull(param) {
  return toString.call(param) === '[object Null]';
}

/**
 * 校验是否为null或undefined
 * @param {any} param
 * @return {boolean}
 */
function isNullOrUndefined(param) {
  return isUndefined(param) || isNull(param);
}

/**
 * 校验是否为object
 * @param {any} param
 * @returns {boolean}
 */
function isObject(param) {
  return toString.call(param) === '[object Object]';
}

/**
 * 校验是否为function
 * @param {any} func
 * @returns {boolean}
 */
function isFunction(func) {
  return toString.call(func) === '[object Function]';
}

/**
 * 校验是否为array
 * @param {any} param
 * @returns {boolean}
 */
function isArray(param) {
  return toString.call(param) === '[object Array]';
}

/**
 * 校验是否为string
 * @param {any} param
 * @returns {boolean}
 */
function isString(param) {
  return toString.call(param) === '[object String]';
}

/**
 * 校验是否为undefined/null/空字符串
 * @param {undefined|null|string} param
 * @returns {boolean}
 * @throws {Error}
 */
function isEmptyString(param) {
  if (isUndefined(param) || isNull(param)) {
    return true;
  }
  // checkArgument(
  //   isString(param),
  //   "Expected 'undefined/null/string' but '" + typeof param + "' was found "
  // );
  return param.trim().length === 0;
}

/**
 * 校验是否为非空字符串
 * @param {any} param
 * @returns {boolean}
 */
function isNotEmptyString(param) {
  return isString(param) && param.trim().length > 0;
}

/**
 * 校验是否为json字符串
 * @param {string} str
 * @returns
 */
function isJSONString(str) {
  try {
    if (!isNotEmptyString(str)) {
      return false;
    }
    const obj = JSON.parse(str);
    return isObject(obj);
  } catch (error) {
    return false;
  }
}

/**
 * 校验表达式
 * @param {boolean} expression
 * @param {string|Error} error
 * @throws {Error}
 */
function checkArgument(expression, error) {
  if (!expression) {
    if (isString(error)) {
      throw new Error(error);
    }
    if (isError(error)) {
      throw error;
    }
    throw new Error('Illegal argument exception');
  }
}

/**
 * 校验是否为合法手机号
 * @param {string} phone 手机号
 * @return {boolean}
 */
function checkPhone(phone) {
  if (isEmptyString(phone)) {
    return false;
  }
  phone = phone.trim();
  return /(^1[3|4|5|6|7|8|9]\d{9}$)|(^(92)\d{9}$)|(^(98)\d{9}$)/.test(phone);
}

export {
  isIIMP,
  isJingBaoTong,
  isEBao,
  isAndroid,
  isIOS,
  isIOSChrome,
  isIE,
  isWechat,
  isWechatMini,
  isWechatMiniSDKReady,
  isBlobSupport,
  isAppError,
  isError,
  isRegExp,
  isUndefined,
  isNull,
  isNullOrUndefined,
  isObject,
  isFunction,
  isArray,
  isString,
  isEmptyString,
  isNotEmptyString,
  isJSONString,
  checkArgument,
  checkPhone,
};
