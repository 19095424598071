import { React } from '../../../../common/3rd';
import Dropdown from '../component/dropdown';

import { occupationMapClass } from '../../../../common/occupation-map-class';

export default class SimpleJob extends Dropdown {
  /* constructor(props) {
     super(props)
     this.state = { isComplexJob: false }
     } */

  render() {
    let isComplexJob = this.getValueFromModel(this.getLayout().complexJobId);
    return (
      <div style={{ position: 'relative' }}>
        {isComplexJob && (
          <div className="sth-simple-job-mask">
            <span>其他职业&emsp;&emsp;&emsp;&emsp;</span>
          </div>
        )}
        <select
          style={{ width: '100%' }}
          {...this.getPrototypes()}
          onChange={this.changeHandler}
          onBlur={this.doCheckAndAlert}
          ref="me"
        >
          {this.renderPlaceholder()}
          {this.getCodes().map((code, codeIndex) => {
            return (
              <option
                value={code.v}
                data-value-type={typeof code.v}
                key={codeIndex}
              >
                {code.l}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  onChange = (evt) => {
    const value = this.getValueFromDOM();
    const job1Id = this.getLayout().jobCategoryId.replace(
      'jobCategory',
      'job1'
    );
    const job2Id = this.getLayout().jobCategoryId.replace(
      'jobCategory',
      'job2'
    );
    const job3Id = this.getLayout().jobCategoryId.replace(
      'jobCategory',
      'job3'
    );
    if (value === '-1') {
      this.setValueToModel(
        true,
        function () {},
        false,
        this.getLayout().complexJobId
      );
      this.setValueToModel('');
      this.setValueToModel('', function () {}, false, job1Id);
      this.setValueToModel('', function () {}, false, job2Id);
      this.setValueToModel('', function () {}, false, job3Id);
      this.setValueToModel(
        '',
        function () {},
        false,
        this.getLayout().jobCategoryId
      );
      return;
    } else {
      this.setValueToModel(
        false,
        function () {},
        false,
        this.getLayout().complexJobId
      );
    }
    const job1 = value.substr(0, 1);
    const job2 = value.substr(0, 3);
    const job3 = value.substr(0, 5);
    const jobCategory = occupationMapClass[value]['class'];
    const delay = this.getChangeHandlingDelay();
    if (delay > 0) {
      if (this.state.changeDelay) {
        clearTimeout(this.state.changeDelay);
        delete this.state.changeDelay;
      }

      this.state.changeDelay = setTimeout(() => {
        this.setValueToModel(value);
        this.setValueToModel(job1, function () {}, false, job1Id);
        this.setValueToModel(job2, function () {}, false, job2Id);
        this.setValueToModel(job3, function () {}, false, job3Id);
        this.setValueToModel(
          jobCategory,
          function () {},
          false,
          this.getLayout().jobCategoryId
        );
        this.fireEvent('change');
      }, delay);
    } else {
      this.setValueToModel(value);
      this.setValueToModel(job1, function () {}, false, job1Id);
      this.setValueToModel(job2, function () {}, false, job2Id);
      this.setValueToModel(job3, function () {}, false, job3Id);
      this.setValueToModel(
        jobCategory,
        function () {},
        false,
        this.getLayout().jobCategoryId
      );
      this.fireEvent('change');
    }
  };
}
