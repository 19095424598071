import { React, PropTypes, ClassNames, $ } from '../../../common/3rd';
import { Envs, Lang, Utils } from '../../../common/common';
// import EventEmitter from '../../both/something/event-emitter'
import {
  Alert,
  PageLoading,
  ReactRouterContextWidget,
} from '@/component/components';
import './page.css';
import messages from './messages.json';
import notfound from '../../../images/404.png';
import homeCover from '../../../images/desk-home-cover.png';

// install i18n messages
Lang.installMessages(messages, 'page');

/**
 * Page header component
 */
class PageHeader extends React.Component {
  render() {
    return (
      <div className={ClassNames('page-header', this.getPageHeaderClassName())}>
        {this.props.children}
      </div>
    );
  }

  getPageHeaderClassName() {
    return this.props.className;
  }
}

/**
 * Page body component
 */
class PageBody extends React.Component {
  render() {
    return (
      <div className={ClassNames('page-body', this.getPageHeaderClassName())}>
        {this.props.children}
      </div>
    );
  }

  getPageHeaderClassName() {
    return this.props.className;
  }

  componentDidMount() {
    if (Envs.isIOS()) {
      // $("input").blur(function(){
      // 	document.body.scrollTop = document.documentElement.scrollTop
      // });
      // $("select").blur(function(){
      // 	document.body.scrollTop = document.documentElement.scrollTop
      // });
      // $("textarea").blur(function(){
      // 	document.body.scrollTop = document.documentElement.scrollTop
      // });
      $('input').blur(function () {
        let dom = document.getElementsByClassName('page-body')[0];
        dom.scrollTop = dom.scrollTop + 100;
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {}
}

/**
 * Page footer component
 */
class PageFooter extends React.Component {
  render() {
    return (
      <div className={ClassNames('page-footer', this.getPageHeaderClassName())}>
        {this.props.children}
      </div>
    );
  }

  getPageHeaderClassName() {
    return this.props.className;
  }
}

/**
 * page body container
 */
class PageBodyContainer extends ReactRouterContextWidget {
  renderPageLocation() {
    const locations = this.getPageLocations();
    if (locations == null || locations.length === 0) {
      return;
    }
    return (
      <div className="page-body-location">
        <span className="path" onClick={this.onHomeClicked}>
          {Lang.messages.page.home}
        </span>
        {locations.map((loc, locIndex) => {
          return (
            <span
              className="path"
              onClick={this.onLocationClicked.bind(this, loc)}
              key={`path-${locIndex}`}
            >
              {loc.label}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div
        className={ClassNames(
          'page-body-container',
          this.getContainerClassName()
        )}
      >
        {this.renderPageLocation()}
        {this.doRender()}
      </div>
    );
  }

  doRender() {
    return null;
  }

  getContainerClassName() {
    return this.props.className;
  }

  onHomeClicked = () => {
    this.pushRedirect(Envs.PATH.DESK_HOME);
  };

  getPageLocations() {
    return [];
  }

  onLocationClicked(location) {
    if (location.path) {
      this.pushRedirect(location.path);
    }
  }
}

class NotImpl extends PageBodyContainer {
  doRender() {
    return (
      <div style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
        <img src={notfound} alt="404 Not Found" />
      </div>
    );
  }

  getPageLocations() {
    return [
      {
        label: Lang.messages.common['not-impl'],
      },
    ];
  }
}

class HomeCover extends PageBodyContainer {
  doRender() {
    return (
      <div style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
        <img
          src={homeCover}
          alt="Welcome to eBaoCloud."
          style={{
            width: '50vw',
            height: '50vh',
            marginTop: '10vh',
            marginLeft: '-5vw',
          }}
        />
      </div>
    );
  }
}

/**
 * Page component which contains header, body and footer
 */
class Page extends ReactRouterContextWidget {
  /**
   * switch language
   */
  UNSAFE_componentWillMount() {
    Lang.switchLanguage(Lang.getCurrentLanguage());
  }

  /**
   * 新增更新用户信息方法
   * 以便同步小程序与官微用户状态
   */
  updateUserAccountInfo(callBack) {
    let code = Utils.getUrlProps().code;
    let openId = localStorage.openId;
    if (!openId) {
      if (code) {
        this.doGet(
          `/rest/v1/weixin/getWxUser/${code}`,
          {},
          { ignoreAuth: true }
        )
          .done((data) => {
            openId = data.openId;
            if (data.openId) {
              Utils.openId = data.openId;
              localStorage.openId = data.openId;
              this.getUpdateInfo();
            } else {
              Alert.message('请求微信数据异常 001');
            }
          })
          .fail((err) => {
            console.log(JSON.parse(err.responseText));
            Alert.message('请求微信数据异常 002');
          });
      }
      return;
    }
    this.getUpdateInfo();
  }

  getUpdateInfo() {
    let url = `/rest/v1/account/currentInfo?isGetVipInfo=1&openId=${localStorage.openId}`;
    this.doGet(url)
      .done((res) => {
        if (res.returnCode == 'RC-00001' && res.body) {
          this.modifyUserStorage(res.body);
        }
      })
      .fail((e) => {
        if (e.responseText) {
          if (JSON.parse(e.responseText).errors) {
            let eRes = JSON.parse(e.responseText).errors[0].code;
            if (eRes == 'ACCOUNT_NOT_BIND_WECHAT') {
              Alert.message('当前账号已解绑或未绑定微信号', () => {
                this.replaceRedirect(Envs.PATH.WECHAT_SIGN);
              });
              return;
            }
            if (eRes == 'ACCOUNT_OPENID_UNIQUE') {
              Alert.message(
                '亲，如您需要解除会员帐号与微信的绑定，请您直接拨打400-81-96677服务热线。',
                () => {
                  this.replaceRedirect(Envs.PATH.WECHAT_SIGN);
                }
              );
              return;
            }
          }
        }
      });
  }

  //更新用户Storage
  modifyUserStorage(data) {
    let account = Envs.findAccount() || {};
    account = Object.assign(account, data.account);
    account['vipCustomerInfo'] = data.vipCustomerInfo;
    localStorage.setItem('CSMS-Account', JSON.stringify({ Signin: account }));
    sessionStorage.setItem('CSMS-Account', JSON.stringify({ Signin: account }));
    localStorage.setItem('token', data.token);
  }

  renderPageHeader() {
    return <PageHeader />;
  }

  renderPageBody() {
    return <PageBody />;
  }

  renderPageFooter() {
    return <PageFooter />;
  }

  render() {
    return (
      <div className={ClassNames('page', this.getPageClassName())}>
        {this.renderPageHeader()}
        {this.renderPageBody()}
        {this.renderPageFooter()}
      </div>
    );
  }

  /**
   * get page class name, default return null
   */
  getPageClassName() {
    return null;
  }

  /**
   *引导进入登陆页面
   */

  toSignPage = () => {
    const openId = localStorage.getItem('openId');
    // openId !=="undefined" 避免特殊情况openId为 undefined字符串的问题
    if (openId && openId !== 'undefined') {
      //comeIn为true 允许登陆后回退
      this.pushRedirect({ pathname: Envs.PATH.WECHAT_SIGN, comeIn: true });
    } else {
      let protocol = window.document.location.protocol;
      // if (Envs.is_b2c_dev_test()) {
      // 	protocol ="http"
      // }
      let url = Utils.encodeURI(
        `${protocol}//${window.location.host}/bjlife${Envs.PATH.WECHAT_SIGN}?comeIn=true`
      );
      let appId = Utils.getWeChatAppId();
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    }
  };
}

export {
  PageHeader,
  PageBody,
  PageFooter,
  Page,
  PageBodyContainer,
  NotImpl,
  HomeCover,
};
